import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Card,
  CardContent,
} from "@mui/material";
import { DuplicateDocumentFileError } from "_resources/document";
import { businessObjectToRoute } from "../_helpers";

interface DuplicateDocumentFilesProps {
  duplicateDocumentFiles?: DuplicateDocumentFileError["duplicateDocumentFiles"];
}

export const DuplicateDocumentFiles = ({
  duplicateDocumentFiles,
}: DuplicateDocumentFilesProps) =>
  !duplicateDocumentFiles?.length ? null : (
    <Grid item xs={12}>
      <Card>
        <CardContent>
          <h3>Duplicate Files (sample)</h3>
          <p>
            The following duplicate files were found. To permit saving this file
            as a duplicate, enable the "Allow duplicate file" option.
          </p>
          <Table data-cy="duplicate-files">
            <TableHead>
              <TableRow>
                <TableCell>Document Name</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Owner Type</TableCell>
                <TableCell>Owner Name</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {duplicateDocumentFiles.map(doc => (
                <TableRow key={doc.id}>
                  <TableCell>
                    <a
                      href={`/document/${doc.id}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {doc.name}
                    </a>
                  </TableCell>
                  <TableCell>
                    {doc.date && new Date(doc.date).toISOString().slice(0, 10)}
                  </TableCell>
                  <TableCell>
                    {businessObjectToRoute(doc.businessObject.name)}
                  </TableCell>
                  <TableCell>
                    <a
                      href={`/${businessObjectToRoute(
                        doc.businessObject.name
                      )}/${
                        doc.company?.id ??
                        doc.deal?.id ??
                        doc.firm?.id ??
                        doc.investment?.id
                      }`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {doc.company?.name ??
                        doc.deal?.name ??
                        doc.firm?.name ??
                        doc.investment?.name}
                    </a>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    </Grid>
  );
