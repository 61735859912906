import React, { useState } from "react";
import { Navigation } from "../Navigator";
import { Button } from "@mui/material";

export function PerformanceList() {
  const [performanceType, changePerformanceType] = useState<
    "performance" | "performancePrivate"
  >("performance");
  return (
    <>
      <Navigation pathName={performanceType} hasChanges={false} />
      <div style={{ display: "flex", flexDirection: "row" }}>
        <Button
          color="primary"
          style={{ margin: "5px" }}
          variant={performanceType === "performance" ? "contained" : "outlined"}
          onClick={() => changePerformanceType("performance")}
        >
          TWRR
        </Button>
        <Button
          color="primary"
          style={{ margin: "5px" }}
          variant={
            performanceType === "performancePrivate" ? "contained" : "outlined"
          }
          onClick={() => changePerformanceType("performancePrivate")}
        >
          IRR
        </Button>
      </div>
    </>
  );
}
