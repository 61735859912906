import React from "react";
import { useContext } from "react";
import { AuthContext } from "./AuthProvider.component";
import { useOktaAuth } from "@okta/okta-react";
import { buildOktaAuth, getOktaAuth } from "./Auth";
import { Security } from "@okta/okta-react";
import { useNavigate } from "react-router";
import { OktaAuth } from "@okta/okta-auth-js";

function LoginCallbackInternal() {
  const { oktaAuth, authState } = useOktaAuth();
  const { configure } = useContext(AuthContext);
  const [callbackError, setCallbackError] = React.useState<Error | null>(null);

  React.useEffect(() => {
    oktaAuth
      .handleLoginRedirect()
      .then(async () => {
        oktaAuth.start();
        const auth = await getOktaAuth();
        if (auth) configure(auth);
      })
      .catch((e: Error) => {
        setCallbackError(e);
      });
  }, [oktaAuth, configure]);

  const authError = authState?.error;
  const displayError = callbackError || authError;
  if (displayError) {
    return <div> {displayError.message} </div>;
  }

  return null;
}

// logincallback component require security component.
export default function LoginCallback() {
  const navigate = useNavigate();

  const restoreOriginalUri = (_oktaAuth: OktaAuth, originalUri: string) => {
    navigate("/");
  };

  return (
    <Security
      oktaAuth={buildOktaAuth()}
      restoreOriginalUri={restoreOriginalUri}
    >
      <LoginCallbackInternal />
    </Security>
  );
}
