import { DateInput, Filter, TextInput } from "react-admin";

export const DealFilter = (props: any) => {
  return (
    <Filter {...props} variant="outlined" style={{ marginTop: "10px" }}>
      <TextInput label="Name Search" source="q" alwaysOn resettable />
      <TextInput
        variant="outlined"
        label="Company Name"
        source="companyName"
        alwaysOn
      />
      <TextInput label="Source" source="source" alwaysOn />
      <DateInput
        label="Close Date - From"
        source="closeDateRangeFrom"
        alwaysOn
      />
      <DateInput label="Close Date - To" source="closeDateRangeTo" alwaysOn />
    </Filter>
  );
};
