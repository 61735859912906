import { Button } from "ra-ui-materialui";
import {
  ReferenceManyField,
  TextInput,
  useGetOne,
  useGetRecordId,
  useRecordContext,
} from "react-admin";
import { Link } from "react-router-dom";
import * as client from "_graphql-types";
import { ShareClassDataGrid } from "../shareClass/DataGrid";
import { CustomEdit } from "../CustomEdit";
import { Grid, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useCanAccessMutation } from "../../util/useCanAccessMutation";
import { PrivateFields } from "./PrivateFields";

type IRecord = NonNullable<client.GetOneInvestmentTermsQuery["investment"]>;

const CreateShareClassButton = () => {
  const record = useRecordContext();
  return record ? (
    <Button
      component={Link}
      to={{
        pathname: "/shareClass/create",
        search: `?source=${JSON.stringify({
          investment: {
            id: record?.id,
            name: encodeURIComponent(record?.name),
            marketEnumId: record?.marketEnumId,
          },
        })}`,
      }}
      label="Create Share Class"
    >
      <AddIcon />
    </Button>
  ) : null;
};

export const InvestmentTermsEdit = () => {
  const recordId = useGetRecordId();
  const { data } = useGetOne<client.GetOneInvestmentQuery["investment"]>(
    "investment",
    { id: Number(recordId) }
  );
  const mutationArgs = JSON.stringify({
    id: recordId,
    input: {
      firmId: data?.firmId,
    },
  });

  const {
    canEdit,
    canEditField: fieldAccessMap,
    loading,
  } = useCanAccessMutation("updateInvestment", mutationArgs);

  function canEditField(field: string) {
    return canEdit && fieldAccessMap[field];
  }
  return (
    <CustomEdit<IRecord>
      title={record => `Terms - ${record?.name}`}
      customFormProps={{
        simpleFormProps: {
          warnWhenUnsavedChanges: true,
        },
        customToolbarProps: {
          canAccessMutation: canEdit,
        },
        canEditField: fieldAccessMap,
        loading,
      }}
    >
      <Grid container spacing={2}>
        <PrivateFields canEditField={canEditField} />
        <Grid item xs={12}>
          <TextInput
            multiline
            source="shareClassNote"
            label="Share Class Comments"
            rows={5}
            disabled={!canEditField("shareClassNote")}
          />
        </Grid>
      </Grid>
      <Grid alignItems="baseline" container spacing={1}>
        <Grid item xs={1}>
          <Typography variant="h6">Share Classes</Typography>
        </Grid>
        <Grid item xs={2}>
          <CreateShareClassButton />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <ReferenceManyField
            reference="shareClass"
            target="investmentId"
            sort={{ field: "name", order: "ASC" }}
            fullWidth
          >
            <ShareClassDataGrid />
          </ReferenceManyField>
        </Grid>
      </Grid>
    </CustomEdit>
  );
};
