import { Edit, useRecordContext } from "react-admin";
import Form, { Record } from "./Form";

const ServiceProviderFirmTitle = () => {
  const record = useRecordContext<Record>();
  return <>{record && `Service Provider Firm: ${record.name || ""}`} </>;
};

export const ServiceProviderFirmEdit = () => {
  return (
    <Edit
      title={<ServiceProviderFirmTitle />}
      redirect={false}
      mutationMode={"pessimistic"}
    >
      <Form />
    </Edit>
  );
};
