import TTLCache from "@isaacs/ttlcache";
import { getSdk, SdkFunctionWrapper, SortInput } from "_graphql-types";
import { GraphQLClient } from "graphql-request";
import stringify, { stringify as stringifyKey } from "safe-stable-stringify";
import { Auth } from "../components/Auth/AuthProvider.component";
import config from "../config";
import { agency } from "./resources/agency";
import { assetClassEnum } from "./resources/assetClass2Enum";
import { marketEnum } from "./resources/assetClassEnum";
import { asyncTaskStatus } from "./resources/asyncTaskStatus";
import { auditContactTypeEnum } from "./resources/auditContactTypeEnum";
import { aum } from "./resources/aum";
import { businessObjectEnum } from "./resources/businessObjectEnum";
import { client } from "./resources/client";
import { closeDate } from "./resources/closeDate";
import { company } from "./resources/company";
import { companyDocument } from "./resources/companyDocument";
import { companyEnum } from "./resources/companyEnum";
import { companyTypeEnum } from "./resources/companyTypeEnum";
import { contactTypeEnum } from "./resources/contactTypeEnum";
import { countryEnum } from "./resources/countryEnum";
import { currencyDenominationEnum } from "./resources/currencyDenominationEnum";
import { currentUser } from "./resources/currentUser";
import { deal } from "./resources/deal";
import { dealDocument } from "./resources/dealDocument";
import { dealNotes } from "./resources/dealNotes";
// APPEND RESOURCE IMPORTS
import { application } from "./resources/application";
import { adEntriesXApplicationFlags } from "./resources/adEntriesXApplicationFlags";
import { adEntriesXApplicationsXSets } from "./resources/adEntriesXApplicationsXSets";
import { usersXGroups } from "./resources/usersXGroups";
import { set } from "./resources/set";
import { portfolioConfig } from "./resources/portfolioConfig";
import { portfolioConfig as portfolioSettings } from "./resources/portfolioConfig";
import { calendarUnitEnum } from "./resources/calendarUnitEnum";
import { lockupEndValueEnum } from "./resources/lockupEndValueEnum";
import { feeCalcEnum } from "./resources/feeCalcEnum";
import { financialPeriodEnum } from "./resources/financialPeriodEnum";
import { workoutEnum } from "./resources/workoutEnum";
import { peInctFeeCalcEnum } from "./resources/peInctFeeCalcEnum";
import { rightOfExtensionEnum } from "./resources/rightOfExtensionEnum";
import { compoundingPeriodEnum } from "./resources/compoundingPeriodEnum";
import { feeFreqEnum } from "./resources/feeFreqEnum";
import { peMgmtFeeCalcEnum } from "./resources/peMgmtFeeCalcEnum";
import { documentAccessLevel } from "./resources/documentAccessLevel";
import { fdpLoginAuthenticatorEnum } from "./resources/fdpLoginAuthenticatorEnum";
import { fdpWebsites } from "./resources/fdpWebsites";
import { financialDataProvider } from "./resources/financialDataProvider";
import { documentTypeSuggestions } from "./resources/documentTypeSuggestions";
import { investmentFDPLogins } from "_resources/investmentFDPLogins";
import { portfolioFDPLogins } from "_resources/portfolioFDPLogins";
import { firmFDPLogins } from "./resources/firmFDPLogins";
import { fdpLogin } from "./resources/fdpLogin";
import { accountTypeEnum } from "./resources/accountTypeEnum";
import { advisorTypeEnum } from "./resources/advisorTypeEnum";
import { applicationFlag } from "_resources/applicationFlag";
import { userActivity } from "_resources/userActivity";
import { officeLocation } from "_resources/officeLocation";
import { RaRecord } from "ra-core";
import { axysCode } from "./resources/axysCode";
import { companyValuation } from "./resources/companyValuation";
import { customAnalytics } from "./resources/customAnalytics";
import { customIndexInvestment } from "./resources/customIndexInvestment";
import { customIndexPreview } from "./resources/customIndexPreview";
import { dataLinks } from "./resources/dataLinks";
import { dealMarketingEnum } from "./resources/dealMarketingEnum";
import { dealStructureEnum } from "./resources/dealStructureEnum";
import { dealFilterOptions } from "./resources/dealFilterOptions";
import { degreeEnum } from "./resources/degreeEnum";
import { deiCategory1Enum } from "./resources/deiCategory1Enum";
import { deiCategory2Enum } from "./resources/deiCategory2Enum";
import { diversityStatsEnum } from "./resources/diversityStatsEnum";
import { document } from "./resources/document";
import { documentMulti } from "./resources/documentMulti";
import { draftDocument } from "./resources/draftDocument";
import { draftDocumentProcessing } from "./resources/draftDocumentProcessing";
import { documentTypeEnum } from "./resources/documentTypeEnum";
import { emailDomainXOrganization } from "./resources/emailDomainXOrganization";
import { employeeRoleEnum } from "./resources/employeeRoleEnum";
import { family } from "./resources/family";
import { firm } from "./resources/firm";
import { firmDEI } from "./resources/firmDEI";
import { firmDocument } from "./resources/firmDocument";
import { firmEmployment } from "./resources/firmEmployment";
import { firmFirmDEI } from "./resources/firmFirmDEI";
import { firmInstitutions } from "./resources/firmInstitutions";
import { fundingTypeEnum } from "./resources/fundingTypeEnum";
import { geographicFocusEnum } from "./resources/geographicFocusEnum";
import { geographyEnum } from "./resources/geographyEnum";
import { globalProviderNoteTypeEnum } from "./resources/globalProviderNoteTypeEnum";
import { iconEnum } from "./resources/iconEnum";
import { impactEnums } from "./resources/impactEnums";
import { indexCalcTypeEnum } from "./resources/indexCalcTypeEnum";
import { institutionEmployment } from "./resources/institutionEmployment";
import { institutionInstitutionTypeEnum } from "./resources/institutionInstitutionTypeEnum";
import { institutionSelectionStatusEnum } from "./resources/institutionSelectionStatusEnum";
import { institutionTypeEnum } from "./resources/institutionTypeEnum";
import { investment } from "./resources/investment";
import { investmentAssignmentOption } from "./resources/investmentAssignmentOption";
import { investmentAxysCode } from "./resources/investmentAxysCode";
import { investmentBulk } from "./resources/investmentBulk";
import { investmentCustomAnalytics } from "./resources/investmentCustomAnalytics";
import { investmentDocument } from "./resources/investmentDocument";
import { investmentFirmEmployment } from "./resources/investmentFirmEmployment";
import { investmentFirmEmploymentBulk } from "./resources/investmentFirmEmploymentBulk";
import { investmentFirmEmploymentRoleEnum } from "./resources/investmentFirmEmploymentRoleEnum";
import { investmentInstitutions } from "./resources/investmentInstitutions";
import { investmentListEnum } from "./resources/investmentListEnum";
import { investmentPeers } from "./resources/investmentPeers";
import { investmentRisk } from "./resources/investmentRisk";
import { investmentRiskCategory } from "./resources/investmentRisk.category";
import { investmentRiskInvestment } from "./resources/investmentRisk.investment";
import { investmentsFamily } from "./resources/investmentsFamily";
import { investmentStructureEnum } from "./resources/investmentStructureEnum";
import { investmentTags } from "./resources/investmentTags";
import { legalStructureEnum } from "./resources/legalStructureEnum";
import { measureEnum } from "./resources/measureEnum";
import { measureTypeEnum } from "./resources/measureTypeEnum";
import { navClassificationEnum } from "./resources/navClassificationEnum";
import { noteMetaEnum } from "./resources/noteMetaEnum";
import { notes } from "./resources/notes";
import { operatingStatusEnum } from "./resources/operatingStatusEnum";
import { organization } from "./resources/organization";
import { organizationStructureEnum } from "./resources/organizationStructureEnum";
import { orgLinks } from "./resources/orgLinks";
import { links } from "./resources/links";
import { peFundNumberEnum } from "./resources/peFundNumberEnum";
import { performance } from "./resources/performance";
import { performanceBackfill } from "./resources/performanceBackfill";
import { performanceBackfillInvestment } from "./resources/performanceBackfill.investment";
import { performanceMultiInvestment } from "./resources/performanceMultiInvestment";
import { performancePrivate } from "./resources/performancePrivate";
import { performanceSourceEnum } from "./resources/performanceSourceEnum";
import { performanceStatistics } from "./resources/performanceStatistics";
import { person } from "./resources/person";
import { personEducation } from "./resources/personEducation";
import { personWorkExperience } from "./resources/personWorkExperience";
import { peStrategyEnum } from "./resources/peStrategyEnum";
import { portalEmailDomainOrganization } from "./resources/portalEmailDomainOrganization";
import { portalFirm } from "./resources/portalFirm";
import { portalFirmTypeEnum } from "./resources/portalFirmTypeEnum";
import { portalOrganization } from "./resources/portalOrganization";
import { portalServiceProviderFirms } from "./resources/portalServiceProviderFirms";
import { portalSubmitted } from "./resources/portalSubmitted";
import { portalUser } from "./resources/portalUser";
import { pricingFrequencyEnum } from "./resources/pricingFrequencyEnum";
import { resultEnum } from "./resources/resultEnum";
import { riskRefreshList } from "./resources/riskRefreshList";
import { schoolEnum } from "./resources/schoolEnum";
import { serviceProviderDocument } from "./resources/serviceProviderDocument";
import { serviceProviderDocumentType } from "./resources/serviceProviderDocumentType";
import { serviceProviderFirm } from "./resources/serviceProviderFirm";
import { serviceProviderNotes } from "./resources/serviceProviderNotes";
import { stageEnum } from "./resources/stageEnum";
import { stateEnum } from "./resources/stateEnum";
import { strategyEnum } from "./resources/strategyEnum";
import { subStrategyEnum } from "./resources/subStrategyEnum";
import { tagClasses } from "./resources/tagClasses";
import { tag } from "./resources/tags";
import { titleEnum } from "./resources/titleEnum";
import * as types from "./resources/types";
import { user } from "./resources/user";
import { vehicleTypeEnum } from "./resources/vehicleTypeEnum";
import { getChangedFields } from "./helpers/diff";
import { shareClass } from "_resources/shareClass";
import { peLiquidity } from "_resources/peLiquidity";
import { liquidity } from "_resources/liquidity";
import { investmentFees } from "_resources/investmentFees";
import { investmentTerms } from "_resources/investmentTerms";
import { softLockups } from "_resources/softLockups";

export type Provided<T> = T extends (...args: any[]) => Promise<infer U>
  ? U extends { data: infer T }
    ? T
    : never
  : never;

const providers: Record<string, types.DataProvider> = {
  // APPEND PROVIDER RESOURCE
  application,
  adEntriesXApplicationFlags,
  adEntriesXApplicationsXSets,
  usersXGroups,
  set,
  portfolioConfig,
  portfolioSettings,
  calendarUnitEnum,
  lockupEndValueEnum,
  feeCalcEnum,
  financialPeriodEnum,
  workoutEnum,
  peInctFeeCalcEnum,
  rightOfExtensionEnum,
  compoundingPeriodEnum,
  feeFreqEnum,
  peMgmtFeeCalcEnum,
  investmentTerms,
  shareClass,
  peLiquidity,
  liquidity,
  investmentFees,
  documentAccessLevel,
  fdpLoginAuthenticatorEnum,
  fdpWebsites,
  financialDataProvider,
  documentTypeSuggestions,
  investmentFDPLogins,
  portfolioFDPLogins,
  firmFDPLogins,
  fdpLogin,
  accountTypeEnum,
  advisorTypeEnum,
  applicationFlag,
  userActivity,
  agency,
  assetClassEnum,
  assumeUser: currentUser,
  asyncTaskStatus,
  auditContactTypeEnum,
  aum,
  axysCode,
  businessObjectEnum,
  client,
  closeDate,
  company,
  companyDocument,
  companyEnum,
  companyTypeEnum,
  companyValuation,
  contactTypeEnum,
  countryEnum,
  currencyDenominationEnum,
  currentUser,
  customAnalytics,
  customIndexInvestment,
  customIndexPreview,
  dataLinks,
  deal,
  dealFilterOptions,
  dealDocument,
  dealMarketingEnum,
  dealNotes,
  dealStructureEnum,
  degreeEnum,
  deiCategory1Enum,
  deiCategory2Enum,
  diversityStatsEnum,
  document,
  documentMulti,
  draftDocument,
  draftDocumentProcessing,
  documentTypeEnum,
  emailDomainXOrganization,
  employeeRoleEnum,
  family,
  firm,
  firmDEI,
  firmDocument,
  firmEmployment,
  firmFirmDEI,
  firmInstitutions,
  fundingTypeEnum,
  geographicFocusEnum,
  geographyEnum,
  globalProviderNoteTypeEnum,
  iconEnum,
  impactEnums,
  indexCalcTypeEnum,
  institutionEmployment,
  institutionInstitutionTypeEnum,
  institutionSelectionStatusEnum,
  institutionTypeEnum,
  investment,
  investmentAssignmentOption,
  investmentAxysCode,
  investmentBulk,
  investmentCustomAnalytics,
  investmentDocument,
  investmentFirmEmployment,
  investmentFirmEmploymentBulk,
  investmentFirmEmploymentRoleEnum,
  investmentInstitutions,
  investmentListEnum,
  investmentPeers,
  investmentRisk,
  investmentRiskCategory,
  investmentRiskInvestment,
  investmentsFamily,
  investmentStructureEnum,
  investmentTags,
  legalStructureEnum,
  marketEnum,
  measureEnum,
  measureTypeEnum,
  navClassificationEnum,
  noteMetaEnum,
  notes,
  officeLocation,
  operatingStatusEnum,
  organization,
  organizationStructureEnum,
  orgLinks,
  links,
  peFundNumberEnum,
  performance,
  performanceBackfill,
  performanceBackfillInvestment,
  performanceMultiInvestment,
  performancePrivate,
  performanceStatistics,
  performanceSourceEnum,
  person,
  personEducation,
  personWorkExperience,
  peStrategyEnum,
  portalEmailDomainOrganization,
  portalFirm,
  portalFirmTypeEnum,
  portalOrganization,
  portalServiceProviderFirms,
  portalSubmitted,
  portalUser,
  pricingFrequencyEnum,
  resultEnum,
  riskRefreshList,
  schoolEnum,
  serviceProviderDocument,
  serviceProviderDocumentType,
  serviceProviderFirm,
  serviceProviderNotes,
  softLockups,
  stageEnum,
  stateEnum,
  strategyEnum,
  subStrategyEnum,
  tag,
  tagClasses,
  titleEnum,
  user,
  vehicleTypeEnum,
};

const cache = new TTLCache({ max: 10_000, ttl: 1000 });

const bind = <T, U, F extends (...args: any[]) => U>(
  obj: T,
  get: (t: T) => F,
  decorate?: (f: F) => F
) => {
  const f = get(obj).bind(obj) as F;
  return decorate ? decorate(f) : f;
};

export default class DataProvider {
  constructor(private readonly auth: Auth) {}

  private async getGqlClient() {
    const idToken = await this.auth.getIdToken();
    const client = new GraphQLClient(config.GRAPHQL_API(), {
      headers: {
        authorization: `Bearer ${idToken}`,
        "apollo-require-preflight": "true",
      },
    });
    const clientProxy = new Proxy(client, {
      get(target, prop, receiver) {
        if (prop === "request") {
          // put info about the request into the error object for logging
          // typescript is just awful for handling overloaded function args
          return (...args: any[]) => {
            try {
              return (target.request as Function)(...args);
            } catch (e: any) {
              const requestArgs = stringify(args, null, 2);
              let error: Error;
              if (e?.response?.errors) {
                const { errors } = e.response;
                if (Array.isArray(errors)) {
                  const message = errors
                    .map(({ message }) => message)
                    .filter(Boolean)
                    .join("\n");
                  // messages will probably be stripped out in apollo server
                  if (message) {
                    error = Error(message);
                  }
                }
              }
              error ??= e instanceof Error ? e : Error(String(e));
              error.message = [
                error.message || "Unknown error",
                "Request args:",
                requestArgs,
              ].join("\n");
              throw error;
            }
          };
        }
        return Reflect.get(target, prop, receiver);
      },
    });
    return clientProxy;
  }

  private async getSdkClient() {
    const gqlClient = await this.getGqlClient();
    return getSdk(gqlClient);
  }

  private getProvider(resource: keyof typeof providers) {
    const provider = providers[resource] as types.DataProvider;
    if (!provider) {
      throw new Error(`Undefined provider for resource [${resource}]`);
    }
    return provider;
  }

  readonly request = (document: string, variables?: Record<string, any>) =>
    this.getGqlClient().then(client =>
      client.rawRequest(document, variables).then(
        ok => ({ errors: undefined, ...ok }),
        errors => ({ errors })
      )
    );

  private readonly provide =
    <TVerb extends keyof Omit<types.DataProvider, "ttl">>(verb: TVerb) =>
    async <T extends RaRecord>(
      resource: keyof typeof providers,
      params: Parameters<types.DataProvider[TVerb]>[1]
    ): Promise<Awaited<ReturnType<types.DataProvider[TVerb]>>> => {
      console.log(
        `Calling method [${verb}] on resource [${resource}] with params`,
        params
      );
      const provider = this.getProvider(resource);
      const ttl = provider.ttl?.[verb as never];
      const key = stringifyKey([verb, resource, params]);
      let json: string | undefined;
      if (ttl) {
        json = cache.get(key);
      }
      if (!json) {
        const client = await this.getSdkClient();
        const result = await provider[verb](client, params as any);
        json = JSON.stringify(result);
        if (ttl) {
          cache.set(key, json, { ttl });
        }
      }
      const result = JSON.parse(json);
      console.log(result);
      return JSON.parse(json);
    };

  readonly getList = bind(this, t => t.provide("getList"));

  readonly getManyReference = bind(this, t => t.provide("getManyReference"));

  readonly getOne = bind(this, t => t.provide("getOne"));

  readonly getMany = bind(this, t => t.provide("getMany"));

  readonly create = bind(
    this,
    t => t.provide("create"),
    create => (resource, params) =>
      create(resource, {
        ...params,
        data: getChangedFields(params.data, null, params.meta),
      })
  );

  readonly update = bind(
    this,
    t => t.provide("update"),
    update => (resource, params) =>
      update(resource, {
        ...params,
        data: getChangedFields(params.data, params.previousData, params.meta),
      })
  );

  readonly updateMany = bind(this, t => t.provide("updateMany"));

  readonly delete = bind(this, t => t.provide("delete"));

  readonly deleteMany = bind(this, t => t.provide("deleteMany"));
}

interface FilterSortPage<TFilter, TField extends string> {
  filter?: TFilter;
  sort?: { field: TField; order: SortInput };
  nameField?: TField;
  pagination?: { page: number; perPage: number };
}

export function filterSortPage<TFilter, TField extends string>({
  filter,
  sort,
  nameField,
  pagination,
}: FilterSortPage<TFilter, TField>) {
  return {
    filter,
    page: pagination && {
      offset: (pagination.page - 1) * pagination.perPage,
      limit: pagination.perPage,
    },
    sort: [
      sort && {
        field: sort.field,
        order: sort.order,
      },
      nameField && {
        field: nameField,
        order: SortInput.Asc,
      },
    ].filter(Boolean) as {
      field: TField;
      order: SortInput;
    }[],
  };
}
