import { DeleteButton, DeleteButtonProps } from "react-admin";

function CustomDeleteButton(props: DeleteButtonProps) {
  return (
    <DeleteButton
      disabled={props.disabled ?? !!props.record?.deleteDate}
      {...props}
    />
  );
}

export default CustomDeleteButton;
