import * as client from "_graphql-types";
import * as types from "./types";

type Sdk = ReturnType<typeof client.getSdk>;

/*********************************************************************/
// Data Provider Functions
/*********************************************************************/

/*********************************************************************/
async function getList(
  sdk: Sdk,
  params: types.GetListParams<client.IndexCalcTypeEnumSortEnum>
) {
  const {
    pagination: { page, perPage },
    sort: { field, order },
  } = params;

  const {
    indexCalcTypeEnumList: { items: data, total },
  } = await sdk.getListIndexCalcTypeEnum({
    page: {
      offset: (page - 1) * perPage,
      limit: perPage,
    },
    sort: [{ field, order }],
  });

  return {
    data,
    total,
  };
}

/*********************************************************************/
async function getOne(sdk: Sdk, params: types.GetOneParams) {
  const id = Number(params.id);
  const { indexCalcTypeEnum: data } = await sdk.getOneIndexCalcTypeEnum({
    id,
  });
  return {
    data,
  };
}

/*********************************************************************/
async function getMany(sdk: Sdk, params: types.GetManyParams) {
  const ids = params.ids.map(id => Number(id));

  const { indexCalcTypeEnumMany: data } = await sdk.getManyIndexCalcTypeEnum({
    ids,
  });

  return {
    data,
  };
}

/*********************************************************************/
export const indexCalcTypeEnum = types.dataProvider({
  getList,
  getOne,
  getMany,
});
