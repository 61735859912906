import AddIcon from "@mui/icons-material/Add";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { QtdRec, YearRange, range } from "../Edit";
import { Year } from "./year";

export const Headers: string[] = ["Year", "Q1", "Q2", "Q3", "Q4"];

interface Props {
  data?: QtdRec[];
  loading: boolean;
  yearRange: YearRange;
  setYearRange: (range: YearRange) => void;
  addChangedRecord: (count: number, year: number, value: string) => void;
  canEdit: boolean;
}

export const Quarterly = (props: Props) => {
  const { data, loading, yearRange, setYearRange, addChangedRecord, canEdit } =
    props;

  if (loading || !data) {
    return <div></div>;
  }

  return (
    <Table data-id="performanceTableQuarterly">
      <TableHead>
        <TableRow>
          {Headers.map(name => (
            <TableCell key={name}>{name}</TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {canEdit &&
          (yearRange.newMax ?? yearRange.oldMax) < new Date().getFullYear() && (
            <TableRow>
              <TableCell>
                <IconButton
                  size="small"
                  color="primary"
                  title="Add Next Year"
                  onClick={() => {
                    setYearRange({
                      ...yearRange,
                      newMax: (yearRange.newMax ?? yearRange.oldMax) + 1,
                    });
                  }}
                >
                  <AddIcon />
                </IconButton>
              </TableCell>
              <TableCell colSpan={Headers.length}></TableCell>
            </TableRow>
          )}
        {range(
          yearRange.newMin ?? yearRange.oldMin,
          yearRange.newMax ?? yearRange.oldMax
        ).map((year: number) => (
          <Year
            year={year}
            filteredQuarters={data.filter(
              (month: any) => month.returnYear === year
            )}
            addChangedRecord={addChangedRecord}
            key={year}
            canEdit={canEdit}
          />
        ))}
        {canEdit && (
          <TableRow>
            <TableCell>
              <IconButton
                size="small"
                color="primary"
                title="Add Previous Year"
                onClick={() => {
                  setYearRange({
                    ...yearRange,
                    newMin: (yearRange.newMin ?? yearRange.oldMin) - 1,
                  });
                }}
              >
                <AddIcon />
              </IconButton>
            </TableCell>
            <TableCell colSpan={Headers.length}></TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};
