import * as client from "_graphql-types";
import * as types from "./types";

import { filterSortPage } from "../DataProvider";

type Sdk = ReturnType<typeof client.getSdk>;

/*********************************************************************/
// Data Provider Functions
/*********************************************************************/

/*********************************************************************/
async function getList(
  sdk: Sdk,
  params: types.GetListParams<client.StateEnumSortEnum>
) {
  const { sort, filter } = params;

  const {
    stateEnumList: { items: data, total },
  } = await sdk.getListStateEnum(
    filterSortPage({ sort, pagination: { page: 1, perPage: 60 } })
  );

  return {
    data: data.filter(({ name }) => {
      return name
        .toLowerCase()
        .includes(filter.q ? filter.q.toLowerCase() : "");
    }),
    total,
  };
}

/*********************************************************************/
async function getOne(sdk: Sdk, params: types.GetOneParams) {
  const id = Number(params.id);
  const { stateEnum: data } = await sdk.getOneStateEnum({
    id,
  });
  return {
    data,
  };
}

/*********************************************************************/
async function getMany(sdk: Sdk, params: types.GetManyParams) {
  const ids = params.ids.map(id => Number(id));

  const { stateEnumMany: data } = await sdk.getManyStateEnum({
    ids,
  });

  return {
    data,
  };
}

/*********************************************************************/
export const stateEnum = types.dataProvider({
  getList,
  getOne,
  getMany,
  ttl: types.defaultEnumTtl,
});
