import { Button } from "ra-ui-materialui";
import { BooleanField, Datagrid, FunctionField, TextField } from "react-admin";
import { Link } from "react-router-dom";

export const FirmDEIDataGrid = () => {
  return (
    <Datagrid data-cy="firmDEI-datagrid" bulkActionButtons={false}>
      <FunctionField
        label=""
        render={(rec: any) =>
          rec && (
            <Button
              label="Edit"
              component={Link}
              variant="contained"
              data-id="firmDEI-edit"
              to={{
                pathname: `/firmDEI/${rec.id}`,
                search: `?source=${JSON.stringify({
                  ...(rec && {
                    firm: {
                      id: rec.firm.id,
                      name: encodeURIComponent(rec.firm.name),
                    },
                  }),
                })}`,
              }}
            />
          )
        }
      />
      <TextField label="Firm" source="firm.name" />
      <TextField label="As Of Date" source="asOfDate" />
      <TextField label="Geography" source="geographyEnum.description" />
      <BooleanField label="Completed" source="isCompleted" />
    </Datagrid>
  );
};
