import * as client from "_graphql-types";
import { filterSortPage } from "../DataProvider";
import * as types from "./types";

type Sdk = ReturnType<typeof client.getSdk>;

/*********************************************************************/
// Data Provider Functions
/*********************************************************************/

/*********************************************************************/
async function getList(
  sdk: Sdk,
  params: types.GetListParams<client.InvestmentFirmEmploymentSortEnum>
) {
  const { pagination, sort, filter } = params;

  const {
    investmentFirmEmploymentList: { items: data, total },
  } = await sdk.getListInvestmentFirmEmployment(
    filterSortPage({
      filter,
      sort,
      pagination,
    })
  );

  return {
    data,
    total,
  };
}

/*********************************************************************/
async function getOne(sdk: Sdk, params: types.GetOneParams) {
  const id = Number(params.id);
  const { investmentFirmEmployment: data } =
    await sdk.getOneInvestmentFirmEmployment({
      id,
    });
  return {
    data,
  };
}

/*********************************************************************/
async function getMany(sdk: Sdk, params: types.GetManyParams) {
  const ids = params.ids.map(id => Number(id));

  const { investmentFirmEmploymentMany: data } =
    await sdk.getManyInvestmentFirmEmployment({
      ids,
    });

  return {
    data,
  };
}

/*********************************************************************/
async function getManyReference(
  sdk: Sdk,
  params: types.GetManyReferenceParams<client.InvestmentFirmEmploymentSortEnum>
) {
  const id = Number(params.id);
  const {
    pagination: { page, perPage },
    sort: { field, order },
    filter,
    target,
  } = params;

  const {
    investmentFirmEmploymentList: { items: data, total },
  } = await sdk.getManyReferenceInvestmentFirmEmployment({
    page: {
      offset: (page - 1) * perPage,
      limit: perPage,
    },
    sort: [{ field, order }],
    filter: { ...filter, [target]: id },
  });

  return {
    data,
    total,
  };
}

/*********************************************************************/
async function create(
  sdk: Sdk,
  params: types.CreateParams<
    NonNullable<
      client.GetOneInvestmentFirmEmploymentQuery["investmentFirmEmployment"]
    >
  >
) {
  const { data } = params;
  const input: client.CreateInvestmentFirmEmploymentMutationVariables["input"] =
    {
      investmentId: data.investment?.id,
      firmEmploymentId: data.firmEmployment?.id,
      employeeRoleEnumId: data.employeeRoleEnumId,
      faceOfInvestment: data.faceOfInvestment,
      startDate: data.startDate || undefined,
      leftDate: data.leftDate || undefined,
    };

  const {
    addInvestmentFirmEmployment: { id },
  } = await sdk.createInvestmentFirmEmployment({
    input,
  });

  return await getOne(sdk, { id: id! });
}

/*********************************************************************/
async function update(
  sdk: Sdk,
  params: types.UpdateParams<
    NonNullable<
      client.GetOneInvestmentFirmEmploymentQuery["investmentFirmEmployment"]
    >
  >
) {
  const id = Number(params.id);
  const { data } = params;
  const input: client.UpdateInvestmentFirmEmploymentMutationVariables["input"] =
    {
      employeeRoleEnumId: data.employeeRoleEnumId,
      startDate: data.startDate,
      leftDate: data.leftDate,
      faceOfInvestment: data.faceOfInvestment,
    };

  await sdk.updateInvestmentFirmEmployment({
    id,
    input,
  });

  return await getOne(sdk, { id });
}

/*********************************************************************/
async function del(sdk: Sdk, params: types.DeleteParams): types.DeleteOut<any> {
  const id = Number(params.id);

  await sdk.deleteInvestmentFirmEmployment({ id });

  return { data: { id } };
}

/*********************************************************************/
export const investmentFirmEmployment = types.dataProvider({
  getList,
  getOne,
  getMany,
  getManyReference,
  create,
  update,
  delete: del,
});
