import {
  useGetOne,
  MenuItemLink,
  UserMenu,
  WithPermissions,
  Logout,
  useUserMenu,
} from "react-admin";
import SettingsIcon from "@mui/icons-material/Settings";
import { Permissions } from "./CustomAdmin";

const UserSettingsMenuItem = (props: any) => {
  const { onClose } = useUserMenu()!;
  return (
    <WithPermissions
      render={({ permissions }: { permissions: Permissions }) =>
        permissions?.flags.canAssumeUser ? (
          <MenuItemLink
            to="assumeUser"
            primaryText="Assume User"
            leftIcon={<SettingsIcon />}
            onClick={onClose}
          />
        ) : (
          <></>
        )
      }
    />
  );
};

export const CustomUserMenu = () => {
  const { isLoading, error } = useGetOne(
    "currentUser",
    { id: null },
    { refetchOnMount: true }
  );

  if (isLoading) return null;
  if (error) return <>Something went wrong loading user</>;

  return (
    <UserMenu>
      <UserSettingsMenuItem />
      <Logout />
    </UserMenu>
  );
};
