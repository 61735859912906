import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { Stack } from "@mui/system";
import {
  EditableDatagrid,
  EditableDatagridProps,
  RowForm,
} from "@react-admin/ra-editable-datagrid";
import * as client from "_graphql-types";
import {
  BooleanField,
  BooleanInput,
  ChipField,
  DateField,
  DateInput,
  FileField,
  FileInput,
  FunctionField,
  ImageField,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  TextField,
  TextInput,
  WrapperField,
  required,
  useCreate,
  useNotify,
  useRecordContext,
} from "react-admin";
import { useCanAccessMutation } from "../../util/useCanAccessMutation";
import { Placeholder } from "./Placeholder";
import {
  CLIENT_DOC_CATEGORIES,
  NON_CLIENT_DOC_CATEGORIES,
  capitalizedName,
  distinguishClientDocTypes,
} from "./helpers";

import { WarningOutlined } from "@ant-design/icons";
import { format, parse } from "date-fns";
import { useEffect, useMemo, useRef, useState } from "react";
import {
  DeepPartial,
  FormProvider,
  useForm,
  useFormContext,
  useWatch,
} from "react-hook-form";
import { findDate } from "./helpers";

import { useApolloClient } from "@apollo/client";
import { captureException } from "@sentry/react";
import { ChatMarkdown } from "frontend/src/components/Chat/ChatMarkdown";
import { DocumentProcessButton } from "frontend/src/components/DocumentProcess";
import { DuplicateDocumentFileError } from "../../dataProvider/resources/document";
import { DuplicateDocumentFiles } from "./DuplicateDocumentFiles";

const PARSE_PAGES = 2;

type Record = NonNullable<client.GetOneDocumentQuery["document"]>;

type EntityOwner =
  | { investmentId: number }
  | { firmId: number }
  | { companyId: number }
  | { dealId: number };

const validateRequired = [required()];

const DocumentThumbnail = () => {
  const record = useRecordContext<Record>();

  const thumbnail = record?.signedThumbnailUrl ? (
    <ImageField
      label="Thumbnail"
      source="signedThumbnailUrl"
      sx={{ "& img": { maxWidth: 240 } }}
    />
  ) : (
    <Placeholder width={240} height={160} />
  );
  return record?.signedUrl ? (
    <a href={`${record?.signedUrl}`} download={record?.name} target="_blank">
      {thumbnail}
    </a>
  ) : (
    thumbnail
  );
};

const ClientDocFields = ({
  canEditField,
}: {
  canEditField: (key: string) => boolean;
}) => {
  const [accessLevel] = useWatch({
    name: ["accessLevel"],
  });

  return (
    <>
      {accessLevel === 4 && (
        <WrapperField source="isApproved_notifyUsers" label="Approvals">
          <Stack direction="column" spacing={2}>
            <BooleanInput
              source="isApproved"
              readOnly={!canEditField("isApproved")}
            />
            <BooleanInput
              source="notifyUsers"
              readOnly={!canEditField("notifyUsers")}
            />
          </Stack>
        </WrapperField>
      )}
    </>
  );
};

const DocTypeInput = ({
  suggestedDocumentType,
  canEditField,
}: {
  suggestedDocumentType: number | null;
  canEditField: (key: string) => boolean;
}) => {
  const [accessLevel] = useWatch({ name: ["accessLevel"] });

  const documentTypeFilter = useMemo(() => {
    return {
      documentCategoryEnumIds:
        accessLevel === 4 ? CLIENT_DOC_CATEGORIES : NON_CLIENT_DOC_CATEGORIES,
    };
  }, [accessLevel]);

  const { setValue } = useFormContext();
  useEffect(() => {
    // weird react admin design decision - https://github.com/marmelab/react-admin/issues/5022
    // we have to manully remove invalid selected items from lists when the filter changes
    setValue("documentTypeEnumId", null);
  }, [String(documentTypeFilter.documentCategoryEnumIds)]);

  return (
    <ReferenceInput
      key={String(documentTypeFilter.documentCategoryEnumIds)}
      source="documentTypeEnumId"
      reference="documentTypeEnum"
      sort={{ field: "name", order: "ASC" }}
      filter={documentTypeFilter}
    >
      <SelectInput
        optionText={record => distinguishClientDocTypes(record)}
        label="Type"
        validate={validateRequired}
        defaultValue={suggestedDocumentType}
        helperText={
          suggestedDocumentType ? (
            <DocTypeHelperText {...{ suggestedDocumentType }} />
          ) : (
            ""
          )
        }
        readOnly={!canEditField("documentTypeEnumId")}
      />
    </ReferenceInput>
  );
};

const DocTypeHelperText = ({
  suggestedDocumentType,
}: {
  suggestedDocumentType: number | null;
}) => {
  const [documentTypeEnumId] = useWatch({ name: ["documentTypeEnumId"] });

  return documentTypeEnumId === suggestedDocumentType ? (
    <>Suggested type based on document content</>
  ) : null;
};

const DateHelperText = ({
  suggestedDate,
  dateSuggestionError,
  date,
}: {
  suggestedDate: Date | null;
  dateSuggestionError: boolean;
  date: string | null;
}) => {
  if (dateSuggestionError) {
    return (
      <p style={{ color: "darkorange" }}>
        <WarningOutlined /> Error getting date suggestion
      </p>
    );
  }

  return [
    suggestedDate &&
      date &&
      parse(date, "yyyy-MM-dd", new Date())?.getTime() ===
        suggestedDate.getTime() &&
      "Suggested date based on document content",
  ]
    .filter(Boolean)
    .join(", ");
};

const DocumentForm = ({
  isEdit,
  entityOwner,
  referenceKey,
  defaultValues,
}: {
  isEdit: boolean;
  entityOwner?: EntityOwner;
  referenceKey: string;
  defaultValues?: DeepPartial<Record>;
}) => {
  const docRecord = useRecordContext<Record>();

  const { canEdit, canEditField: _canEditField } = useCanAccessMutation(
    isEdit ? "updateDocument" : "addDocument",
    JSON.stringify(
      isEdit
        ? { id: docRecord?.id, input: entityOwner }
        : { input: entityOwner }
    )
  );

  function canEditField(key: string) {
    return !!(canEdit && _canEditField[key]);
  }

  const [getSuggestions] = useCreate();
  const [suggestedDocumentType, setSuggestedDocumentType] = useState<
    number | null
  >(null);
  const [suggestedDate, setSuggestedDate] = useState<Date | null>(null);
  const [dateSuggestionError, setDateSuggestionError] =
    useState<boolean>(false);
  const [docName, setDocName] = useState<string | null>(null);

  const [openDialog, setOpenDialog] = useState(false);

  const [duplicateDocumentFiles, setDuplicateDocumentFiles] = useState<
    DuplicateDocumentFileError["duplicateDocumentFiles"]
  >([]);
  const notify = useNotify();
  const bottom = useRef<HTMLDivElement>(null);

  const { setValue } = useFormContext();

  const [date] = useWatch<{ date: string }>({ name: ["date"] });

  return (
    <RowForm
      defaultValues={defaultValues}
      data-cy={`document-rowForm__${referenceKey}`}
      mutationOptions={{
        // Would be nice if RA used a decorator pattern instead of an override pattern,
        // so we can easily ADD side effects to the default behavior.
        onError: (error, variables, context) => {
          if (error instanceof DuplicateDocumentFileError) {
            const { duplicateDocumentFiles } = error;
            console.log(duplicateDocumentFiles);
            setDuplicateDocumentFiles(duplicateDocumentFiles);
            setOpenDialog(true);
            setTimeout(() => {
              bottom.current?.scrollIntoView({ behavior: "smooth" });
            });
          } else {
            console.log(error);
            captureException(error);
          }
          const message = String(error);
          notify(message, { type: "error" });
        },
      }}
    >
      <WrapperField source="file" label="File">
        <Stack direction="column" spacing={2}>
          {isEdit && <DocumentThumbnail />}
          {!isEdit && (
            <FileInput
              source="file"
              onChange={async (file: File | object) => {
                if (!(file instanceof File)) return;
                console.log("FILE INPUT CHANGE", file);

                setDateSuggestionError(false);
                setDocName(file.name);
                console.log("NAME ->", file.name);

                //get the extension from e.name
                if (file.name.split(".").at(-1) === "pdf") {
                  const pdfjsLib = await import("pdfjs-dist");
                  const _buffer = await file.arrayBuffer();
                  console.log(_buffer);

                  pdfjsLib.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.js`;

                  const doc = await pdfjsLib.getDocument(_buffer).promise;

                  const content = await Promise.all(
                    Array.from({
                      length: Math.min(PARSE_PAGES, doc.numPages),
                    }).map(async (_, i) => {
                      const page = await doc.getPage(i + 1);
                      return await page.getTextContent();
                    })
                  );

                  const text = content
                    .map(pageContent =>
                      pageContent.items
                        .map(item => ("str" in item ? item.str : item))
                        .join(" ")
                    )
                    .flat()
                    .join(" ");
                  if (text.length > 0) {
                    getSuggestions(
                      "documentTypeSuggestions",
                      {
                        data: { text: text },
                      },
                      {
                        onSettled: (data, error) => {
                          if (error) {
                            throw Error(`error getting doc type suggestions`);
                          }
                          console.log(data);
                          setValue(
                            "documentTypeEnumId",
                            data.documentTypeSuggestion
                          );
                          setSuggestedDocumentType(data.documentTypeSuggestion);
                        },
                      }
                    );
                    try {
                      const _suggestedDate = findDate(file.name + " " + text);

                      if (_suggestedDate) {
                        setValue("date", format(_suggestedDate, "yyyy-MM-dd"));
                        setSuggestedDate(_suggestedDate || null);
                      }
                    } catch (error) {
                      setDateSuggestionError(true);
                    }
                  }
                }
              }}
            >
              <FileField source="src" title="title" />
            </FileInput>
          )}
        </Stack>
      </WrapperField>

      <TextInput
        source="name"
        label="Name"
        validate={validateRequired}
        readOnly={!canEditField("name")}
        defaultValue={docName}
      />

      <DateInput
        source="date"
        validate={validateRequired}
        defaultValue={date}
        helperText={
          suggestedDate || dateSuggestionError ? (
            <DateHelperText {...{ date, suggestedDate, dateSuggestionError }} />
          ) : (
            ""
          )
        }
        onChange={() => {
          setDateSuggestionError(false);
          setSuggestedDate(null);
        }}
        readOnly={!canEditField("date")}
      />

      <ReferenceInput
        source="accessLevel"
        reference="documentAccessLevel"
        sort={{ field: "id", order: "ASC" }}
      >
        <SelectInput
          optionText={capitalizedName}
          label="Access Level"
          validate={validateRequired}
          readOnly={!canEditField("accessLevel")}
        />
      </ReferenceInput>

      <DocTypeInput
        canEditField={canEditField}
        suggestedDocumentType={suggestedDocumentType}
      />

      <ClientDocFields canEditField={canEditField} />

      <WrapperField source="isGallery_allowDuplicateFile" label="Gallery">
        <Stack direction="column" spacing={2}>
          <BooleanInput
            source="isGallery"
            label="Show in image gallery"
            readOnly={!canEditField("isGallery")}
          />
          <BooleanInput
            source="allowDuplicateFile"
            readOnly={!canEditField("allowDuplicateFile")}
          />
        </Stack>
      </WrapperField>

      <DuplicateDocumentFiles duplicateDocumentFiles={duplicateDocumentFiles} />
    </RowForm>
  );
};

export const DocumentEditableDataGrid = ({
  referenceKey,
  size = "medium",
  bulkActionButtons,
  rowClick,
  noDelete = true,
  defaultRowValues,
  entityOwner,
  mutationMode = "pessimistic",
  actions,
}: {
  referenceKey: string;
  defaultRowValues?: DeepPartial<Record>;
  entityOwner?: EntityOwner;
} & Pick<
  EditableDatagridProps,
  | "size"
  | "bulkActionButtons"
  | "rowClick"
  | "noDelete"
  | "mutationMode"
  | "actions"
>) => {
  const apolloClient = useApolloClient();

  return (
    <EditableDatagrid
      actions={actions}
      mutationMode={mutationMode}
      data-cy={referenceKey}
      size={size}
      editForm={
        <FormProvider {...useForm()}>
          <DocumentForm
            isEdit={true}
            entityOwner={entityOwner}
            referenceKey={referenceKey}
            defaultValues={defaultRowValues}
          />
        </FormProvider>
      }
      noDelete={noDelete}
      bulkActionButtons={bulkActionButtons}
      createForm={
        <FormProvider {...useForm()}>
          <DocumentForm
            isEdit={false}
            entityOwner={entityOwner}
            referenceKey={referenceKey}
            defaultValues={defaultRowValues}
          />
        </FormProvider>
      }
      rowClick={rowClick}
      sx={{
        "& .MuiTableRow-root": {
          verticalAlign: "top",
        },
        "& .RaDatagrid-rowCell": {
          width: "fit-content",
        },
        "& .MuiStack-root": {
          width: "fit-content",
        },
      }}
      data-id="document-grid"
    >
      <WrapperField source="file" label="File" sortable={false}>
        <Stack direction="column" spacing={2}>
          <DocumentThumbnail />
        </Stack>
      </WrapperField>

      <TextField label="Name" source="name" data-id="document-grid__name" />

      <DateField
        label="Date"
        source="date"
        options={{ timeZone: "UTC" }}
        data-id="document-grid__date"
      />

      <ReferenceField
        label="Access"
        source="accessLevel"
        reference="documentAccessLevel"
        sortBy="accessLevelId"
      >
        <FunctionField
          render={(rec: any) => capitalizedName(rec)}
          data-id="document-grid__accessLevel"
        />
      </ReferenceField>

      <ChipField
        label="Type"
        source="documentTypeEnum.name"
        sortBy="documentTypeEnumName"
        data-id="document-grid__documentTypeEnum"
      />

      <BooleanField
        source="isApproved"
        label="Approved"
        data-id="document-grid__isApproved"
      />

      <FunctionField
        label=""
        render={(rec: any) =>
          rec && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <a href={`${rec.signedUrl}`} download={rec.name} target="_blank">
                <CloudDownloadIcon />
              </a>
              <DocumentProcessButton
                documentId={rec.id}
                apolloClient={apolloClient}
                markdownComponent={ChatMarkdown}
              />
            </div>
          )
        }
        sortable={false}
      />
    </EditableDatagrid>
  );
};
