import {
  FormControl,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { endOfMonth, format, startOfMonth, parse } from "date-fns";
import React, { useState } from "react";
import { useGetList } from "react-admin";
import * as client from "_graphql-types";
import { MtdRec } from "../Edit";
import { getDefaultAsOf } from "../helpers";

export type MtdRecDetail = MtdRec & { asOfDateUpdated: boolean };

interface Props {
  monthDetail: MtdRecDetail;
  addChangedRecordDetail: (
    returnMonth: number,
    returnYear: number,
    detailType: "asOfDate" | "performanceCommentary" | "returnValue",
    value: string,
    monthDetail: MtdRecDetail
  ) => void;
  isReturnEntered: boolean;
  canEdit: boolean;
}

const MonthDetail = (props: Props) => {
  const { monthDetail, addChangedRecordDetail, isReturnEntered, canEdit } =
    props;
  const {
    return: returnValue,
    currencyId,
    returnYear,
    returnMonth,
    asOfDate,
    asOfDateUpdated,
    modifyUser,
    modifyDate,
    performanceCommentary,
  } = monthDetail;

  const [commentaryFocus, changeCommentaryFocus] = useState(false);

  const {
    data: currencyDenominationEnumData,
  }: {
    data?: client.GetListCurrencyDenominationEnumQuery["currencyDenominationEnumList"]["items"];
  } = useGetList("currencyDenominationEnum", {
    pagination: { page: 1, perPage: 999 },
    sort: { field: "name", order: "ASC" },
    filter: { isPerformanceCurrency: true },
  });

  const UpdatedIcon = () => {
    return asOfDateUpdated ? (
      <div>
        As of Date{" "}
        {asOfDateUpdated && (
          <CheckCircleOutlineIcon
            color="primary"
            style={{ fontSize: "1rem" }}
          />
        )}
      </div>
    ) : (
      <div>As of Date</div>
    );
  };

  if (!returnYear) {
    return null;
  }

  const asOfDateValue = asOfDate
    ? asOfDate.split("T", 2)[0]
    : getDefaultAsOf(new Date(returnYear, returnMonth));

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h6">
          Monthly Detail{" - "}
          {format(new Date(returnYear, returnMonth - 1), "MMM yyyy")}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <FormControl fullWidth variant="outlined" size="small">
          <InputLabel>Return</InputLabel>
          <OutlinedInput
            data-id="return-input"
            value={returnValue ?? ""}
            onChange={e => {
              addChangedRecordDetail(
                returnMonth,
                returnYear,
                "returnValue",
                e.target.value,
                monthDetail
              );
            }}
            type="number"
            inputProps={{
              style: {
                textAlign: "right",
              },
            }}
            fullWidth
            readOnly={!canEdit}
            label="Return"
            endAdornment={<InputAdornment position="end">%</InputAdornment>}
          />
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        <FormControl variant="outlined" size="small" fullWidth>
          <InputLabel>Currency</InputLabel>
          <Select
            variant="standard"
            data-id="currencyId-input"
            value={currencyId}
            label="Currency"
            fullWidth
            disabled
          >
            {currencyDenominationEnumData?.map(({ id, name }) => (
              <MenuItem value={id} key={id}>
                {name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <TextField
          data-id="asOfDate-input"
          value={asOfDateValue}
          InputProps={{
            inputProps: {
              min: format(
                startOfMonth(parse(asOfDateValue, "yyyy-MM-dd", new Date())),
                "yyyy-MM-dd"
              ),
              max: format(
                endOfMonth(parse(asOfDateValue, "yyyy-MM-dd", new Date())),
                "yyyy-MM-dd"
              ),
            },
          }}
          onChange={e => {
            addChangedRecordDetail(
              returnMonth,
              returnYear,
              "asOfDate",
              e.target.value,
              monthDetail
            );
          }}
          label={<UpdatedIcon />}
          type="date"
          disabled={!canEdit || !isReturnEntered}
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          size="small"
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          label="Monthly Performance Commentary"
          value={performanceCommentary || ""}
          onChange={e => {
            addChangedRecordDetail(
              returnMonth,
              returnYear,
              "performanceCommentary",
              e.target.value,
              monthDetail
            );
          }}
          onFocus={() => changeCommentaryFocus(true)}
          onBlur={() => changeCommentaryFocus(false)}
          maxRows={commentaryFocus ? 8 : 4}
          rows={4}
          variant="outlined"
          size="small"
          fullWidth
          multiline
          disabled={!canEdit}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="caption" color="textSecondary">
          {modifyUser && modifyDate ? (
            <span>
              Modified by {modifyUser} on{" "}
              {format(new Date(modifyDate), "MM/dd/yyyy")}
            </span>
          ) : (
            <span>New record</span>
          )}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default MonthDetail;
