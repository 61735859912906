import { BooleanInput, Filter, TextInput } from "react-admin";

export const PortalUserFilter = () => (
  <Filter>
    <TextInput
      label="Email Search"
      source="name"
      alwaysOn
      style={{ width: 500 }}
      resettable
    />
    <BooleanInput label="Include Deleted" source="includeDeleted" alwaysOn />
  </Filter>
);
