import { Grid } from "@mui/material";
import * as client from "_graphql-types";
import { CustomEdit } from "../CustomEdit";

import { BooleanInput, TextInput } from "react-admin";
import { EntityInput } from "../UI/EntityInput";
import { useWatch, useFormContext } from "react-hook-form";
import { useEffect } from "react";

const doNothing = () => {}; // Define a placeholder function that does nothing

type Record = NonNullable<client.GetOnePortalSubmittedQuery["portalSubmitted"]>;

const PortalSubmittedForm = () => {
  const rcgFundId = useWatch({ name: "rcgFundId" });
  const { setValue } = useFormContext();

  useEffect(() => {
    if (!rcgFundId) {
      setValue("shouldUpdate", false);
    }
  }, [rcgFundId]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <TextInput label="Name" source="name" disabled />
      </Grid>
      <Grid item xs={6}></Grid>
      <Grid item xs={4}>
        <EntityInput<NonNullable<Record>>
          resource="portalFirm"
          recordKey="firm"
          sourceKey="firmId"
          label={"Portal Firm"}
          allowChange={true}
          isRequired
        />
      </Grid>
      <Grid item xs={4}>
        <EntityInput<NonNullable<Record>>
          resource="investment"
          recordKey="rcgFund"
          sourceKey="rcgFundId"
          label={"RCG Fund"}
          allowChange={true}
          filters={{ active: true }}
        />
      </Grid>

      <Grid item xs={4}>
        <BooleanInput
          source="shouldUpdate"
          label="Sync to Diligence"
          inputProps={{ ...(!rcgFundId ? { onChange: doNothing } : {}) }}
          // effectively disabling, the value wouldn't be sent to provider if we use the default disabled prop.
        />
      </Grid>
    </Grid>
  );
};
export const PortalSubmittedEdit = () => {
  return (
    <CustomEdit<Record>
      title={record => record && `PortalSubmitted ${record.name || ""}`}
    >
      <PortalSubmittedForm />
    </CustomEdit>
  );
};
