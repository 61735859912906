import React, { useState } from "react";
import { Button, Grid, Checkbox, FormControlLabel } from "@mui/material";

export const BusinessApplicantV1 = ({ accept }: { accept: () => void }) => {
  const [read, setRead] = useState(false);

  return (
    <Grid container spacing={2} style={{ margin: "auto" }}>
      <Grid item xs={12}>
        <h2>RockCreek Community Impact Capital Fund</h2>
      </Grid>
      <Grid item xs={12}>
        <div style={{ maxWidth: "50em" }}>
          <br />
          <h4>
            RockCreek Community Impact Capital Fund Notice To Prospective
            Candidates For Investments
          </h4>
          <br />
          <p>
            Please be advised that if your company is the recipient of an equity
            investment from the RockCreek Community Impact Capital Fund or if an
            Exelon company officer or director becomes and officer or director
            of your company, such investment or common officer/director status
            could result in your company being deemed an affiliate of Exelon
            Corporation and/or one or more of its operating utility subsidiaries
            for purposes of the regulations that govern public utilities at the
            federal level or in one or more of the states in which Exelon
            utilities operate. If your company is deemed an affiliate of one of
            Exelon’s utilities and currently contracts with, or intends to seek
            to contract with, that utility to provide services and/or products,
            any such transactions may become subject to laws, regulations, and
            other requirements regarding affiliate transactions, including
            affiliate pricing rules and public utility commission access to
            affiliate records. In some cases, regulatory approval may be
            required from the state utility or public service commission prior
            to the parties executing a contract.
          </p>
          <br />
          <p>
            By agreeing to receive an equity investment from the Racial Equity
            Capital Fund, you acknowledge and agree that, in the event that your
            company is deemed to be an affiliate of Exelon Corporation and/or
            one or more of its operating utilities, you will comply with all
            rules and regulations regarding affiliate transactions and cooperate
            fully with any such utility, as may be requested, in compliance with
            its regulatory obligations. You further agree that when bidding on a
            utility contract you will provide written e-mail notice to Exelon’s
            Supply organization (through the Director of Diverse Business
            Empowerment) that your company is a recipient of an equity
            investment from the RockCreek Community Impact Capital Fund for
            purposes of ensuring compliance with affiliate requirements. You
            also will provide Supply with a list of your company’s directors and
            officers to assist in assessing the applicability of affiliate
            rules. Failure to comply with the foregoing conditions may result in
            your investment application being denied or investment award being
            rescinded.
          </p>
          <br />
        </div>
      </Grid>

      <Grid item xs={12}>
        <Button
          onClick={() => accept()}
          variant="contained"
          color="primary"
          data-cy="TOS_ACCEPT"
          disabled={!read}
          style={{ marginRight: "1em" }}
        >
          Agree
        </Button>
        <FormControlLabel
          label="I have read the notice"
          control={
            <Checkbox
              checked={read}
              data-cy="READ_ACCEPT"
              onChange={() => setRead(!read)}
            />
          }
        />
      </Grid>
    </Grid>
  );
};
