import { CreateParams, Middleware, RaRecord, UpdateParams } from "ra-core";
import { useCallback } from "react";
import { useRegisterMutationMiddleware } from "react-admin";

type RecordType = RaRecord;
type TMiddleware = (
  resource?: string,
  params?: Partial<UpdateParams<RecordType>> | Partial<CreateParams<RecordType>>
) => Promise<boolean extends true ? RecordType : void>;

/**
 * Populates the dataProvider meta parameter.
 * Currently used as a work-around for a react admin bug but could be used for other things in the future
 * ----------------------
 * Until react-admin stops including disabled fields in the submit payload
 * we are populating it with the list of disabled inputs for use in our diffing function.
 * Diffing with previousValue does not play well with disabled inputs that have a default value (usually boolean fields since they aren't nullable).
 * Diffing with dirtyFields from formState does not play well with any default values whether or not the field is disabled, so using previousValue is the best option.
 * We could use fieldAccess but if a user doesn't have access to a field but it is not disabled it should clearly error instead of silently omitting user entered data.
 * Field access is still being passed to useCallback since disabled fields will change based on user access.
 * There are also fields that are disabled for reasons other than user access that we don't want to be including in the submit payload.
 */
export function RegisterFormMeta({
  fieldAccess,
}: {
  fieldAccess?: Record<string, boolean>;
}) {
  const middleware: Middleware<TMiddleware> = useCallback(
    async (resource, params, next) => {
      let disabledFields: string[] = [];
      for (let el of document.getElementsByTagName("input")) {
        if (el.disabled) {
          disabledFields.push(el.name);
          continue;
        }
        continue;
      }

      return next(resource, {
        ...params,
        meta: { ...params?.meta, disabledFields },
      });
    },
    [fieldAccess]
  );

  if (!useRegisterMutationMiddleware) {
    /* If using save context outside of Edit/Create forms or controllers you need to manually pass in the registration functions 
      from useMutationMiddlewares to the save context provider. See FirmDEI/Edit.tsx for example */
    throw new Error("Missing registerMutationMiddleware in Save Context.");
  }

  useRegisterMutationMiddleware(middleware);

  return null;
}
