import LoadingButton from "@mui/lab/LoadingButton";
import Autocomplete from "@mui/material/Autocomplete";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { format } from "date-fns";
import { useEffect, useState } from "react";
import { Confirm, useDataProvider } from "react-admin";
import { useDebouncedCallback } from "use-debounce";
import { IdName, setInvestmentOptions } from "./View";

export function DeleteRiskMeasures() {
  const dataProvider = useDataProvider();
  const [specificInvestments, setSpecificInvestments] = useState(true);
  const [deleteInvestmentOptions, setDeleteInvestmentOptions] = useState<
    IdName[]
  >([]);
  const setDeleteInvestmentOptionsDebounced = useDebouncedCallback(
    setInvestmentOptions(dataProvider, setDeleteInvestmentOptions),
    300
  );
  const [deleteInvestmentPick, setDeleteInvestmentPick] =
    useState<IdName | null>(null);
  const [confirming, setConfirming] = useState(false);
  const [working, setWorking] = useState(false);
  const [message, setMessage] = useState("");
  const [specificCategories, setSpecificCategories] = useState(true);
  const [specificPeriods, setSpecificPeriods] = useState(true);
  const [categoryOptions, setCategoryOptions] = useState<IdName[]>([]);
  const [categoryPicks, setCategoryPicks] = useState(
    new Set<number | string>()
  );
  const [periodMin, setPeriodMin] = useState<Date | null>(null);
  const [periodMax, setPeriodMax] = useState<Date | null>(null);

  function displayInvestment() {
    return !specificInvestments
      ? "Daily Refresh List"
      : deleteInvestmentPick?.name;
  }
  function displayCategories() {
    return !specificCategories
      ? "All categories"
      : categoryOptions
          .filter(({ id }) => categoryPicks.has(id))
          .map(({ name }) => name)
          .join(", ");
  }
  function displayPeriods() {
    return !specificPeriods
      ? "All periods"
      : !periodMin && !periodMax
      ? "All periods"
      : "From " +
        (periodMin ? format(periodMin, "MMM yyyy") : "all preceding periods") +
        " to " +
        (periodMax ? format(periodMax, "MMM yyyy") : "all following periods");
  }

  useEffect(() => {
    dataProvider
      .getList("investmentRiskCategory", {
        filter: {
          level: 1,
        },
        sort: {
          field: "name",
          order: "ASC",
        },
        pagination: {
          page: 1,
          perPage: 100,
        },
      })
      .then(({ data }) =>
        setCategoryOptions(data.map(({ id, name }) => ({ id, name })))
      );
  }, []);

  return (
    <>
      <h3>Delete Risk Data</h3>
      <Stack direction="row" spacing={2} alignItems="center">
        <span>Delete risk for:</span>
        <RadioGroup
          row
          style={{ display: "inline-block" }}
          name="refresh-investments-group"
          value={specificInvestments ? "CUSTOM" : "DATABASE"}
          onChange={e => {
            const isSpecific = e.target.value === "CUSTOM";
            setSpecificInvestments(isSpecific);
            if (!isSpecific) {
              // > if we can default to ‘Positions’ being selected when ‘Daily Refresh List’ is selected, saves some time
              if (specificCategories) setCategoryPicks(new Set(["11"]));
            }
          }}
        >
          <FormControlLabel
            value="CUSTOM"
            control={<Radio />}
            label="Specific Investment"
          />
          <FormControlLabel
            value="DATABASE"
            control={<Radio />}
            label="Daily Refresh List"
          />
        </RadioGroup>
      </Stack>
      <Grid container spacing={2}>
        {specificInvestments && (
          <Grid item xs={12}>
            <Autocomplete
              filterOptions={options => options}
              options={deleteInvestmentOptions}
              renderInput={params => (
                <TextField
                  variant="standard"
                  {...params}
                  label="Select investment"
                />
              )}
              onInputChange={(event, value, reason) => {
                switch (reason) {
                  case "input":
                    return setDeleteInvestmentOptionsDebounced(value);
                  default:
                    return setDeleteInvestmentOptions([]);
                }
              }}
              onChange={(event, value, reason) => {
                switch (reason) {
                  case "selectOption":
                    return setDeleteInvestmentPick(value as IdName);
                  case "clear":
                    return setDeleteInvestmentPick(null);
                }
              }}
              value={deleteInvestmentPick}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              getOptionLabel={option => `${option.name} - ${option.id}`}
              data-cy="delete-investment-select"
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <FormControl variant="standard" component="fieldset">
            <FormLabel component="legend">Categories</FormLabel>
            <RadioGroup
              row
              name="delete-categories-group"
              onChange={(event, value) => {
                const isSpecific = value === "specific";
                setSpecificCategories(isSpecific);
                if (!isSpecific) setCategoryPicks(new Set());
              }}
              value={specificCategories ? "specific" : "all"}
            >
              <FormControlLabel
                value="specific"
                control={<Radio />}
                label="Specific"
              />
              <FormControlLabel value="all" control={<Radio />} label="All" />
            </RadioGroup>
          </FormControl>
          {specificCategories && (
            <List dense>
              {categoryOptions.map(option => (
                <ListItem key={option.id} disablePadding dense>
                  <ListItemButton
                    role="checkbox"
                    dense
                    onClick={() => {
                      const newSet = new Set(categoryPicks);
                      if (newSet.has(option.id)) newSet.delete(option.id);
                      else newSet.add(option.id);
                      setCategoryPicks(newSet);
                    }}
                  >
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        checked={categoryPicks.has(option.id)}
                        style={{ padding: 0 }}
                      />
                    </ListItemIcon>
                    <ListItemText primary={option.name} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          )}
        </Grid>
        <Grid item xs={12}>
          <FormControl variant="standard" component="fieldset">
            <FormLabel component="legend">Periods</FormLabel>
            <RadioGroup
              row
              name="delete-periods-group"
              onChange={(event, value) => {
                const isSpecific = value === "specific";
                setSpecificPeriods(isSpecific);
                if (!isSpecific) {
                  setPeriodMin(null);
                  setPeriodMax(null);
                }
              }}
              value={specificPeriods ? "specific" : "all"}
            >
              <FormControlLabel
                value="specific"
                control={<Radio />}
                label="Specific"
              />
              <FormControlLabel value="all" control={<Radio />} label="All" />
            </RadioGroup>
          </FormControl>
          {specificPeriods && (
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Stack direction="row" spacing={2} alignItems="center">
                <span>From</span>
                <DatePicker
                  views={["year", "month"]}
                  label="Year and Month"
                  maxDate={new Date()}
                  value={periodMin}
                  onChange={newValue => {
                    setPeriodMin(newValue);
                  }}
                  slotProps={{
                    textField: {
                      variant: "standard",
                      helperText: null,
                    },
                  }}
                  format="MMM yyyy"
                />
                <span>To</span>
                <DatePicker
                  views={["year", "month"]}
                  label="Year and Month"
                  maxDate={new Date()}
                  value={periodMax}
                  onChange={newValue => {
                    setPeriodMax(newValue);
                  }}
                  slotProps={{
                    textField: {
                      variant: "standard",
                      helperText: null,
                    },
                  }}
                  format="MMM yyyy"
                />
              </Stack>
            </LocalizationProvider>
          )}
        </Grid>
      </Grid>
      <div style={{ margin: "10px", marginLeft: 0 }}>
        <LoadingButton
          variant="contained"
          color="primary"
          disabled={
            (specificInvestments && !deleteInvestmentPick) ||
            (specificCategories && !categoryPicks.size) ||
            (specificPeriods && !periodMin && !periodMax) ||
            working
          }
          onClick={() => {
            setConfirming(true);
          }}
          loading={working}
          data-cy="delete-submit"
        >
          Delete
        </LoadingButton>
        <Confirm
          isOpen={confirming}
          title="Confirm Deletion"
          content={
            <div>
              Are you sure you want to delete data for the following?
              <ul>
                <li>{displayInvestment()}</li>
                <li>{displayCategories()}</li>
                <li>{displayPeriods()}</li>
              </ul>
            </div>
          }
          onConfirm={async () => {
            setConfirming(false);
            const promise = dataProvider.update("investmentRisk", {
              id: "deleteRiskMeasures",
              data: {
                listType: specificInvestments ? "CUSTOM" : "DATABASE",
                investmentIds:
                  specificInvestments && deleteInvestmentPick
                    ? [deleteInvestmentPick.id]
                    : undefined,
                categoryIds: specificCategories
                  ? [...categoryPicks.values()]
                  : undefined,
                minDate: specificPeriods ? periodMin ?? undefined : undefined,
                maxDate: specificPeriods ? periodMax ?? undefined : undefined,
              },
              previousData: { id: "" },
            });
            setMessage("");
            setWorking(true);
            try {
              await promise;
              setMessage("Delete completed successfully");
            } catch (error) {
              setMessage("An error occurred");
              throw error;
            } finally {
              setWorking(false);
            }
          }}
          onClose={() => {
            setConfirming(false);
          }}
        />
      </div>
      {working ? <p>Working...</p> : message ? <p>{message}</p> : null}
    </>
  );
}
