import * as client from "_graphql-types";

import Grid from "@mui/material/Grid";
import {
  Button,
  NumberInput,
  RadioButtonGroupInput,
  TextInput,
  useRecordContext,
  useResourceDefinition,
} from "react-admin";
import { Link } from "react-router-dom";
import { CustomEdit } from "../CustomEdit";
import { CompanyEnumEntry } from "./Create";

type Record = NonNullable<
  client.GetOnePersonWorkExperienceQuery["personWorkExperience"]
> & { companyAction: "create" | "select" };

function validateYear(value: any) {
  value = Number(value);
  if (!(Number.isInteger(value) && 1900 < value && value < 3000)) {
    return "Invalid year";
  }
}

function validateForm(form: any) {
  const errors = {};
  if (form.fromYear && form.toYear && form.toYear < form.fromYear) {
    form.toYear = ["To Year cannot precede From Year"];
  }
  return errors;
}

const ViewPersonButton = () => {
  const record = useRecordContext<Record>();
  return !record?.person?.id ? null : (
    <Button
      component={Link}
      to={{
        pathname: `/person/${record.person.id}`,
      }}
      label="Go to Person"
    />
  );
};

export const PersonWorkExperienceEdit = () => {
  const { name } = useResourceDefinition();

  return (
    <CustomEdit<Record>
      title={record =>
        record?.person &&
        `Work Experience for ${record.person.lastName || ""}, ${
          record.person.firstName || ""
        } ${record.person.middleName || ""}`
      }
      customFormProps={{
        customToolbarProps: {
          allowDelete: true,
          deleteButtonRedirect: record => `/person/${record.person.id}`,
        },
        simpleFormProps: {
          defaultValues: { companyAction: "select" },
          validate: validateForm,
        },
      }}
      resource={name}
    >
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <ViewPersonButton />
        </Grid>
        <Grid item xs={6}></Grid>
        <Grid item xs={12}>
          <RadioButtonGroupInput
            label=""
            source="companyAction"
            defaultValue="select"
            choices={[
              { id: "select", name: "Select existing company" },
              { id: "create", name: "Missing company" },
            ]}
            row={true}
          />
        </Grid>
        <Grid item xs={9}>
          <CompanyEnumEntry />
        </Grid>
        <Grid item xs={3}></Grid>
        <Grid item xs={5}>
          {/* company$, title, from year, to year */}
          <TextInput label="Title" source="title" />
        </Grid>
        <Grid item xs={2}>
          <NumberInput
            label="From Year"
            source="fromYear"
            validate={validateYear}
          />
        </Grid>
        <Grid item xs={2}>
          <NumberInput
            label="To Year"
            source="toYear"
            validate={validateYear}
          />
        </Grid>
        <Grid item xs={3}></Grid>
        <Grid item xs={3}></Grid>
      </Grid>
    </CustomEdit>
  );
};
