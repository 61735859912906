import { useQuery, gql } from "@apollo/client";
import { FieldAccess } from "_graphql-types";

export const USER_MUTATION_ACCESSIBLE = gql`
  query getUserMutationAccessible(
    $mutationName: String!
    $mutationArgs: String!
  ) {
    userMutationAccessible(
      mutationName: $mutationName
      mutationArgs: $mutationArgs
    ) {
      canAccess
      fieldAccess {
        field
        canInput
        pointer
      }
    }
  }
`;

export const useCanAccessMutation = (
  mutationName: string,
  mutationArgs: string
) => {
  const { data, loading } = useQuery(USER_MUTATION_ACCESSIBLE, {
    variables: { mutationName, mutationArgs },
  });

  const canEdit = !!data?.userMutationAccessible.canAccess;
  const fieldAccess: FieldAccess[] =
    data?.userMutationAccessible.fieldAccess ?? [];
  const accessMap = fieldAccess.reduce((acc, { field, canInput, pointer }) => {
    if (pointer) {
      acc[`${pointer}.${field}`] = canInput;
    } else {
      acc[field] = canInput;
    }
    return acc;
  }, {} as { [key: string]: boolean });
  console.log("accessMap", accessMap);

  return {
    canEdit,
    canEditField: accessMap,
    loading: loading,
  };
};
