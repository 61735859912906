import {
  AutocompleteInput,
  Filter,
  ReferenceInput,
  TextInput,
} from "react-admin";

export const filters = [
  <ReferenceInput
    key="fdpLoginFilters_firmId"
    source="firmId"
    alwaysOn
    reference="firm"
    sort={{ field: "nameSearchRank", order: "ASC" }}
    sx={{ width: 150 }}
  >
    <AutocompleteInput optionText="name" label="Firm" fullWidth />
  </ReferenceInput>,
  <ReferenceInput
    key="fdpLoginFilters_investmentId"
    source="investmentId"
    alwaysOn
    reference="investment"
    sort={{ field: "nameSearchRank", order: "ASC" }}
    sx={{ width: 150 }}
  >
    <AutocompleteInput optionText="name" label="Investment" fullWidth />
  </ReferenceInput>,
  <ReferenceInput
    key="fdpLoginFilters_portfolioId"
    source="portfolioId"
    alwaysOn
    reference="investment"
    filter={{ portfolioFilters: { isPortfolio: true } }}
    sort={{ field: "nameSearchRank", order: "ASC" }}
    sx={{ width: 150 }}
  >
    <AutocompleteInput optionText="name" label="Portfolio" fullWidth />
  </ReferenceInput>,
  <ReferenceInput
    key="fdpLoginFilters_fdpId"
    source="financialDataProviderId"
    alwaysOn
    reference="financialDataProvider"
    sort={{ field: "nameSearchRank", order: "ASC" }}
    sx={{ width: 150 }}
  >
    <AutocompleteInput optionText="name" label="FDP" fullWidth />
  </ReferenceInput>,
  <TextInput
    key="fdpLoginFilters_q"
    label="Username/Email"
    source="q"
    alwaysOn
    style={{ width: 150 }}
  />,
  <TextInput
    key="fdpLoginFilters_qWebsite"
    alwaysOn
    label="Website"
    source="qWebsite"
    style={{ width: 150 }}
  />,
  <TextInput
    key="fdpLoginFilters_qNotes"
    alwaysOn
    label="Notes"
    source="qNotes"
    style={{ width: 150 }}
  />,
];

export const FdpLoginFilter = () => <Filter>{filters}</Filter>;
