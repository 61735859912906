import {
  BooleanInput,
  ImageField,
  ImageInput,
  NumberInput,
  ReferenceInput,
  SelectArrayInput,
  SelectInput,
  required,
} from "react-admin";
import { Grid } from "@mui/material";
import { memo } from "react";
import * as client from "_graphql-types";
import { EntityInput } from "../UI/EntityInput";
import { useWatch } from "react-hook-form";
import { EntityInputMany } from "../UI/EntityInputMany";
import { CALENDAR_UNIT_ENUM } from "frontend/src/utils/constants";

export type IRecord = NonNullable<
  client.GetOnePortfolioConfigQuery["portfolioConfig"]
>;

const validateRequired = [required()];

export function PConfigForm({
  canEditField,
  isCreate = false,
}: {
  canEditField: (field: string) => boolean;
  isCreate?: boolean;
}) {
  const [performanceLag, lookthroughEnabled, clientVisibleTabs] = useWatch({
    name: ["performanceLag", "lookthroughEnabled", "clientVisibleTabs"],
  });

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6} md={3}>
        <EntityInput<NonNullable<IRecord>>
          sourceKey="portfolioId"
          resource="investment"
          recordKey="portfolio"
          filters={{
            portfolioFilters: {
              isPortfolio: true,
            },
          }}
          label="Portfolio"
          isRequired
          allowChange={isCreate}
          disabled={!canEditField("portfolioId")}
        />
      </Grid>
      <Grid item xs={6} sm={3} md={2}>
        <NumberInput
          source="performanceLag.lag"
          label="Performance Lag"
          disabled={!canEditField("performanceLag")}
        />
      </Grid>
      <Grid item xs={6} sm={3} md={2}>
        <ReferenceInput
          source="performanceLag.lagUnitId"
          reference="calendarUnitEnum"
          required={performanceLag ? validateRequired : undefined}
        >
          <SelectInput
            label="Performance Lag Unit"
            optionText="name"
            disabled={!canEditField("performanceLag")}
            defaultValue={CALENDAR_UNIT_ENUM.month}
          />
        </ReferenceInput>
      </Grid>
      <Grid item xs={6} sm={3} md={2}>
        <BooleanInput
          label="Show Investments?"
          source="showInvestments"
          disabled={!canEditField("showInvestments")}
        />
      </Grid>
      <Grid item xs={6} sm={3} md={2}>
        <BooleanInput
          label="Enable Lookthrough?"
          source="lookthroughEnabled"
          disabled={!canEditField("lookthroughEnabled")}
        />
      </Grid>
      <Grid item xs={12}>
        <EntityInputMany
          label="Enabled Lookthrough Investments"
          resource="investment"
          recordKey="lookthroughInvestments"
          name="investments"
          filters={{
            portfolioFilters: {
              isPortfolio: false,
            },
          }}
          allowChange
          disabled={
            !lookthroughEnabled || !canEditField("lookthroughInvestmentIds")
          }
        />
      </Grid>
      <Grid item xs={12}>
        <SelectArrayInput
          label="Client Visible Tabs"
          source="clientVisibleTabs"
          choices={[
            { id: client.ClientVisibleTabs.Overview, name: "Overview" },
            ...((clientVisibleTabs || []).includes(
              client.ClientVisibleTabs.Overview
            )
              ? [
                  {
                    id: client.ClientVisibleTabs.OverviewGeneral,
                    name: "Overview - General",
                  },
                  {
                    id: client.ClientVisibleTabs.OverviewPerformance,
                    name: "  Overview - Performance",
                  },
                  {
                    id: client.ClientVisibleTabs.OverviewPorfolio,
                    name: "   Overview - Porfolio",
                  },
                  {
                    id: client.ClientVisibleTabs.OverviewServiceProviders,
                    name: "Overview - Service Providers",
                  },
                ]
              : []),
            { id: client.ClientVisibleTabs.Analytics, name: "Analytics" },
            { id: client.ClientVisibleTabs.Investment, name: "Investment" },
            { id: client.ClientVisibleTabs.Documents, name: "Documents" },
          ]}
        />
      </Grid>
    </Grid>
  );
}

export const PortfolioConfigForm = memo(PConfigForm);

type RaImage = {
  src: string;
  title: string;
  rawFile?: File;
};
function isRAImage(
  doc: NonNullable<IRecord["tileImage"]> | RaImage
): doc is RaImage {
  return "src" in doc;
}

export const TileImageInput = () => {
  function docToRaImage(doc: IRecord["tileImage"] | RaImage) {
    console.log("doc ->", doc);
    if (doc && isRAImage(doc)) {
      return doc;
    }
    return {
      src: doc?.signedUrl,
      title: doc?.name,
    };
  }

  return (
    <ImageInput
      source="tileImage"
      label="Tile Image"
      accept={{ "image/*": [] }}
      maxSize={15 * 1000000}
      format={docToRaImage}
      placeholder={<p>Drop your file here</p>}
      // parse={docParse}
    >
      <ImageField source="src" title="title" />
    </ImageInput>
  );
};
