import { InputProps, Input } from "@mui/material";
import React, { useState } from "react";

type Props = InputProps & {
  year: number;
  month: number;
  returnValue: number | null | undefined;
  canEdit: boolean;
};

export const MonthInput = (props: Props) => {
  const { year, month, returnValue, onFocus, onBlur, canEdit, ...rest } = props;

  const [focus, setFocus] = useState(false);

  const value =
    returnValue == null ? "" : focus ? returnValue : returnValue.toFixed(2);

  return (
    <Input
      {...rest}
      data-id={`performanceTableMTD-${year}-${month}`}
      type="number"
      fullWidth={true}
      margin="dense"
      inputProps={{
        style: {
          textAlign: "right",
          backgroundColor: "#f0f0f0",
          color: !focus && (returnValue ?? 0) < 0 ? "red" : "initial",
        },
      }}
      value={value}
      disableUnderline
      onFocus={e => {
        setFocus(true);
        onFocus?.(e);
        // ensure the value is selected
        setTimeout(() => e.target.select());
      }}
      onBlur={e => {
        setFocus(false);
        onBlur?.(e);
      }}
      readOnly={!canEdit}
    />
  );
};
