import { Datagrid, EditButton, List, TextField } from "react-admin";

import { InvestmentFilter } from "./Filter";
import { ListActions } from "./ListActions";

export const InvestmentList = () => (
  <List
    filters={<InvestmentFilter />}
    sort={{ field: "nameSearchRank", order: "ASC" }}
    actions={<ListActions />}
    sx={{
      ".RaFilter-form": {
        flex: "0 1 50%",
      },
    }}
    filterDefaultValues={{ active: true }}
  >
    <Datagrid rowClick="edit" bulkActionButtons={false}>
      <TextField label="ID" source="id" />
      <TextField label="Name" source="name" />
      <TextField label="List" source="list.name" sortable={false} />
      <TextField label="Firm" source="firm.name" sortable={false} />
      <EditButton label="Go to Investment" />
    </Datagrid>
  </List>
);
