import DialogContentText from "@mui/material/DialogContentText";
import React from "react";
import * as client from "_graphql-types";
import { DataProvider, RaRecord } from "react-admin";
import { StdioNull } from "child_process";
import { FieldValue, FieldValues, FormState } from "react-hook-form";
import { InputProps, ValidationErrorMessage } from "ra-core";
import { alpha } from "@mui/material/styles";

export const validateWholeNumber = (value: string) => {
  if (value?.length && Number(value) !== parseInt(value)) {
    return "Must be a whole number";
  }
};

export function validateField<T>(
  values: T,
  prop: keyof T,
  validator: (value: any) => string | undefined
) {
  const value = values[prop];
  const error = validator(value);
  if (error) {
    console.log("VALUE", value, error);
    return { [prop]: [error] };
  }
}

export const marketTranslation = (id: number) => {
  if (id === 2) return "Private";
  return "Public";
};

export const secondLockupEndValueTranslation = ({
  id,
  name,
}: {
  id: number;
  name: string;
}) => {
  if (id === 1) return "End of Repeat Lockup";
  return name;
};

const CONFIRM_PE_FUND_NUMBER_DUPLICATE =
  "There is already an investment with the selected PEFundNumber for this firm. Do you want to save anyway?";

export function getInvestmentConfirmationMessage(
  saveParams?: client.CreateInvestmentMutationVariables["input"],
  firmInvestments?: client.GetListInvestmentQuery["investmentList"]["items"]
): React.ReactNode | null {
  if (saveParams?.marketEnumId === 1) return null;

  if (!firmInvestments || firmInvestments.length < 1) return null;

  const peFundNumbers = firmInvestments.map(
    investment => investment.peFundNumberEnumId
  );

  if (
    saveParams?.peFundNumberEnumId !== 6 &&
    peFundNumbers.includes(saveParams?.peFundNumberEnumId)
  ) {
    return (
      <DialogContentText>{CONFIRM_PE_FUND_NUMBER_DUPLICATE}</DialogContentText>
    );
  }

  return null;
}

export const LLMBGColor_Css = {
  backgroundColor: "rgba(201,186,232,0.5)",
};

export const LLMBGColor = {
  "& .MuiInputBase-input": {
    backgroundColor: "rgba(201,186,232,0.5)",
  },
};

export const LLMBGColor__AutoComplete = {
  "& .MuiInputBase-root": {
    backgroundColor: "rgba(201,186,232,0.5)",
  },
};

export const LLMColor__Switch = {
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: "rgba(201,186,232)",
    "&:hover": {
      backgroundColor: "rgba(201,186,232,0.5)",
    },
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: "rgba(201,186,232,0.5)",
  },
};

export function removeFromAutoPopulatedFieldsOnTouch({
  autoPopulatedFields,
  formState,
  setAutoPopulatedFields,
}: {
  autoPopulatedFields: string[];
  formState: FormState<FieldValues>;
  setAutoPopulatedFields: (fields: string[]) => void;
}) {
  if (
    autoPopulatedFields.length &&
    Object.keys(formState.touchedFields).length
  ) {
    const touchedKeys = Object.keys(formState.touchedFields).filter(
      key => formState.touchedFields[key] === true
    );
    const unTouchedAutoPopulatedFields = autoPopulatedFields.filter(
      autoPField => !touchedKeys.includes(autoPField)
    );
    if (unTouchedAutoPopulatedFields.length !== autoPopulatedFields.length) {
      setAutoPopulatedFields(unTouchedAutoPopulatedFields);
    }
  }
}

/**
 * In order to integrate with the global (ie. form level) validation
 * ArrayInput fields need to return validation errors as an array
 * https://marmelab.com/react-admin/ArrayInput.html#global-validation
 * @param value field value
 * @param allValues form values
 * @param props input props
 * @returns {ValidationErrorMessage|undefined}
 */
export const validateRequiredArrayFields = <IRecord extends RaRecord>(
  value: FieldValue<IRecord>,
  allValues: FieldValues,
  { source }: InputProps
) => {
  const [entity, arrayIndex, field] = source.split(".");
  const errors: ValidationErrorMessage = {
    message: "",
    args: {
      [entity]: allValues[entity].map((_rec: IRecord) => ({})),
    },
  };
  if (value === undefined || value === null || value === "") {
    errors.args[entity][arrayIndex] = {
      [field]: "ra.validation.required",
    };
    return errors;
  }

  return undefined;
};
