import { format, startOfQuarter } from "date-fns";
import { AumEntry } from "_graphql-types";

export function parsePasteArea(text: string): AumEntry[] {
  const recs = text
    .split("\n")
    .filter(row => row.replace(/\s/g, "")) // just ignore rows that are just whitespace
    .map(row => row.split("	"))
    .map(row => row.map(cell => cell.replace(/,/g, "")))
    .map(row => row.map(cell => cell.replace(/\$/g, ""))) // remove dollar signs
    .filter((row, i) => i !== 0 || !isNaN(new Date(row[0]).getTime())) // remove first row if not in valid format
    .map(row => {
      if (!row || row?.length < 2) {
        throw Error("Incorrect Formatting (tab deliminated)");
      }
      const [asOfDate, investmentAUM, strategyAUM, firmAUM] = row;
      startOfQuarter(new Date(asOfDate));

      return {
        asOfDate:
          format(startOfQuarter(new Date(asOfDate)), "yyyy-MM-dd") +
          "T00:00:00.000Z",
        investmentAUM:
          !isNaN(Number(investmentAUM)) && investmentAUM !== ""
            ? Number(investmentAUM)
            : null,
        firmAUM:
          !isNaN(Number(firmAUM)) && firmAUM !== "" ? Number(firmAUM) : null,
        strategyAUM:
          !isNaN(Number(strategyAUM)) && strategyAUM !== ""
            ? Number(strategyAUM)
            : null,
      };
    })
    .sort(
      (a, b) => new Date(b.asOfDate).getTime() - new Date(a.asOfDate).getTime()
    );
  return recs;
}
