import * as client from "_graphql-types";
import { filterSortPage } from "../DataProvider";
import * as types from "./types";

type Sdk = ReturnType<typeof client.getSdk>;

/*********************************************************************/
// Data Provider Functions
/*********************************************************************/

/*********************************************************************/

async function getList(
  sdk: Sdk,
  params: types.GetListParams<client.TagSortEnum>
) {
  const { sort, filter } = params;

  const {
    tagList: { items: data, total },
  } = await sdk.getListTag(filterSortPage({ filter, sort }));

  return {
    data,
    total,
  };
}

/*********************************************************************/

async function getOne(sdk: Sdk, params: types.GetOneParams) {
  const id = Number(params.id);

  const { tag: data } = await sdk.getOneTag({
    id,
  });
  return { data };
}

/*********************************************************************/

async function getMany(sdk: Sdk, params: types.GetManyParams) {
  const ids = params.ids.map(id => Number(id));
  const { tagMany: data } = await sdk.getManyTag({
    ids,
  });
  return { data };
}

/*********************************************************************/

async function getManyReference(
  sdk: Sdk,
  params: types.GetManyReferenceParams<client.TagSortEnum>
) {
  const id = Number(params.id);
  const {
    pagination: { page, perPage },
    sort: { field, order },
    filter,
    target,
  } = params;
  const {
    tagList: { items: data, total },
  } = await sdk.getManyReferenceTag({
    page: {
      offset: (page - 1) * perPage,
      limit: perPage,
    },
    sort: [{ field, order }],
    filter: { ...filter, [target]: id },
  });

  return {
    data,
    total,
  };
}

/*********************************************************************/

export const tag = types.dataProvider({
  getOne,
  getMany,
  getList,
  getManyReference,
});
