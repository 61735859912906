import * as client from "_graphql-types";
import * as types from "./types";

type Sdk = ReturnType<typeof client.getSdk>;

/*********************************************************************/
// Data Provider Functions
/*********************************************************************/

/*********************************************************************/
async function getList(
  sdk: Sdk,
  params: types.GetListParams<client.FdpLoginSortEnum>
) {
  const {
    pagination: { page, perPage },
    sort: { field, order },
    filter,
  } = params;

  const {
    fdpLoginList: { items: data, total },
  } = await sdk.getListFdpLogin({
    page: {
      offset: (page - 1) * perPage,
      limit: perPage,
    },
    sort: [{ field, order }],
    filter,
  });

  return {
    data,
    total,
  };
}

/*********************************************************************/
async function getOne(sdk: Sdk, params: types.GetOneParams) {
  const id = Number(params.id);
  const { fdpLogin: data } = await sdk.getOneFdpLogin({
    id,
  });
  return {
    data,
  };
}

/*********************************************************************/
async function getMany(sdk: Sdk, params: types.GetManyParams) {
  const ids = params.ids.map(id => Number(id));

  const { fdpLoginMany: data } = await sdk.getManyFdpLogin({
    ids,
  });

  return {
    data,
  };
}

/*********************************************************************/
async function getManyReference(
  sdk: Sdk,
  params: types.GetManyReferenceParams<client.FdpLoginSortEnum>
) {
  const id = params.id;
  const {
    pagination: { page, perPage },
    sort: { field, order },
    filter,
    target,
  } = params;

  console.log("getManyReference", params);

  const {
    fdpLoginList: { items: data, total },
  } = await sdk.getListFdpLogin({
    page: {
      offset: (page - 1) * perPage,
      limit: perPage,
    },
    sort: [{ field, order }],
    filter: { ...filter, [target]: id },
  });

  return {
    data,
    total,
  };
}

/*********************************************************************/
async function create(
  sdk: Sdk,
  params: types.CreateParams<
    NonNullable<
      client.GetOneFdpLoginQuery["fdpLogin"] & {
        authenticatorImage: client.InputMaybe<
          client.Scalars["Upload"]["input"]
        >;
      }
    >
  >
) {
  const { data } = params;
  console.log(
    JSON.stringify(
      {
        type: typeof data.authenticatorImage?.rawFile,
        data: data.authenticatorImage?.rawFile?.constructor,
      },
      null,
      2
    )
  );

  const input: client.CreateFdpLoginMutationVariables["input"] = {
    password: data.password ?? undefined,
    email: data.email ?? undefined,
    mfaUser: data.mfaUser ?? undefined,
    notes: data.notes ?? undefined,
    securityQuestion: data.securityQuestion ?? undefined,
    authenticatorEnumId: data.authenticatorEnumId ?? undefined,
    authenticatorImage: data.authenticatorImage?.rawFile ?? undefined,
    username: data.username ?? undefined,
    firmIds: data.firms?.map(firm => firm.id) || undefined,
    investmentIds:
      data.investments?.map(investment => investment.id) || undefined,
    portfolioIds: data.portfolios?.map(portfolio => portfolio.id) || undefined,
    financialDataProviderId: data.financialDataProvider?.id,
  };

  const {
    addFdpLogin: { id },
  } = await sdk.createFdpLogin({
    input,
  });
  return await getOne(sdk, { id: id! });
}

/*********************************************************************/
async function update(
  sdk: Sdk,
  params: types.UpdateParams<
    NonNullable<
      client.GetOneFdpLoginQuery["fdpLogin"] & {
        authenticatorImage: client.InputMaybe<
          client.Scalars["Upload"]["input"]
        >;
      }
    >
  >
) {
  const id = Number(params.id);
  const { data } = params;

  console.log("RAW FILE ->", data.authenticatorImage?.rawFile);

  const input: client.UpdateFdpLoginMutationVariables["input"] = {
    password: data.password ?? undefined,
    email: data.email ?? undefined,
    mfaUser: data.mfaUser ?? undefined,
    notes: data.notes ?? undefined,
    securityQuestion: data.securityQuestion ?? undefined,
    authenticatorEnumId: data.authenticatorEnumId ?? undefined,
    authenticatorImage: data.authenticatorImage?.rawFile ?? undefined,
    username: data.username ?? undefined,
    firmIds: data.firms?.map(firm => firm.id) || undefined,
    investmentIds:
      data.investments?.map(investment => investment.id) || undefined,
    financialDataProviderId: data.financialDataProvider?.id,
    portfolioIds: data.portfolios?.map(portfolio => portfolio.id) || undefined,
    firmFDPLogins: data.firmFDPLogins as client.FirmFdpLoginsInput[],
    investmentFDPLogins:
      data.investmentFDPLogins as client.InvestmentFdpLoginsInput[],
    portfolioFDPLogins:
      data.portfolioFDPLogins as client.PortfolioFdpLoginsInput[],
  };

  await sdk.updateFdpLogin({
    id,
    input,
  });

  return await getOne(sdk, { id });
}

/*********************************************************************/

async function deleteOne(sdk: Sdk, params: types.DeleteParams) {
  const id = Number(params.id);
  await sdk.deleteFdpLogin({
    id,
  });
  return { data: { id } };
}

/*********************************************************************/
export const fdpLogin = types.dataProvider({
  getList,
  getOne,
  getMany,
  getManyReference,
  create,
  update,
  delete: deleteOne,
});
