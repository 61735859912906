import * as client from "_graphql-types";

import Grid from "@mui/material/Grid";
import { NO_WHITESPACE_REGEX } from "frontend/src/utils/constants";
import {
  BooleanInput,
  ImageField,
  ImageInput,
  ReferenceInput,
  SelectInput,
  TextInput,
  regex,
  required,
} from "react-admin";
import { CustomCreate } from "../CustomCreate";
import { EntityInput } from "../UI/EntityInput";

type Record = NonNullable<client.GetOnePersonQuery["person"]>;

const validateNoWhitespace = [
  regex(NO_WHITESPACE_REGEX, "Please remove leading and trailing spaces"),
];
const validateRequiredNoWhitespace = [
  required(),
  regex(NO_WHITESPACE_REGEX, "Please remove leading and trailing spaces"),
];

export const PronounSelections = [
  {
    id: "They/Them",
    name: "They/Them",
  },
  {
    id: "She/Her",
    name: "She/Her",
  },
  {
    id: "He/Him",
    name: "He/Him",
  },
];

export const PersonCreate = () => (
  <CustomCreate<Record>>
    <Grid container spacing={2}>
      <Grid item xs={3}>
        <ReferenceInput
          reference="titleEnum"
          sort={{ field: "name", order: "ASC" }}
          source="titleEnumId"
        >
          <SelectInput optionText="name" label="Salutation" />
        </ReferenceInput>
      </Grid>
      <Grid item xs={3}>
        <TextInput
          label="First Name"
          source="firstName"
          validate={validateRequiredNoWhitespace}
        />
      </Grid>
      <Grid item xs={3}>
        <TextInput
          label="Middle Name"
          source="middleName"
          validate={validateNoWhitespace}
        />
      </Grid>
      <Grid item xs={3}>
        <TextInput
          label="Last Name"
          source="lastName"
          validate={validateRequiredNoWhitespace}
        />
      </Grid>

      <Grid item xs={3}>
        <SelectInput
          source="pronouns"
          label="Pronouns"
          createLabel="Other"
          onCreate={() => {
            const newCategoryName = prompt("Enter pronouns");
            if (newCategoryName) {
              const newCategory = {
                id: newCategoryName.toLowerCase(),
                name: newCategoryName,
              };
              PronounSelections.push(newCategory);
              return newCategory;
            }
            return null;
          }}
          choices={PronounSelections}
        />
      </Grid>
      <Grid item xs={3}>
        <TextInput label="Phone" source="phone" />
      </Grid>
      <Grid item xs={3}>
        <TextInput label="Phone 2" source="phone2" />
      </Grid>
      <Grid item xs={3}>
        <TextInput label="Fax" source="fax" />
      </Grid>
      <Grid item xs={3}>
        <TextInput label="Fax 2" source="fax2" />
      </Grid>
      <Grid item xs={3}>
        <TextInput label="Email" source="email" />
      </Grid>
      <Grid item xs={3}>
        <TextInput label="Email 2" source="email2" />
      </Grid>
      <Grid item xs={3}>
        <TextInput label="LinkedIn" source="linkedin" />
      </Grid>
      <Grid item xs={3}>
        <TextInput label="Twitter" source="twitter" />
      </Grid>
      <Grid item xs={3}>
        <BooleanInput label="Is CFA?" source="isCFA" />
      </Grid>
      <Grid item xs={12}>
        <hr />
        <h3>Diversity</h3>
      </Grid>
      <Grid item xs={3}>
        <BooleanInput
          label="African American"
          source="diversity.africanAmerican"
          data-cy="diversity.africanAmerican"
        />
      </Grid>
      <Grid item xs={3}>
        <BooleanInput
          label="Asian"
          source="diversity.asian"
          data-cy="diversity.asian"
        />
      </Grid>
      <Grid item xs={3}>
        <BooleanInput
          label="Hispanic"
          source="diversity.hispanic"
          data-cy="diversity.hispanic"
        />
      </Grid>
      <Grid item xs={3}>
        <BooleanInput
          label="LGBTQ+"
          source="diversity.lgbtq"
          data-cy="diversity.lgbtq"
        />
      </Grid>
      <Grid item xs={3}>
        <BooleanInput
          label="Native American"
          source="diversity.nativeAmerican"
          data-cy="diversity.nativeAmerican"
        />
      </Grid>
      <Grid item xs={3}>
        <BooleanInput
          label="Hawaiian / Pacific Islander"
          source="diversity.hawaiianOrPacificIslander"
          data-cy="diversity.hawaiianOrPacificIslander"
        />
      </Grid>
      <Grid item xs={3}>
        <BooleanInput
          label="Veteran"
          source="diversity.veteran"
          data-cy="diversity.veteran"
        />
      </Grid>
      <Grid item xs={3}>
        <BooleanInput
          label="Disabled"
          source="diversity.disabled"
          data-cy="diversity.disabled"
        />
      </Grid>
      <Grid item xs={3}>
        <BooleanInput
          label="Woman"
          source="diversity.woman"
          data-cy="diversity.woman"
        />
      </Grid>
      <Grid item xs={3}>
        <BooleanInput
          label="NonBinary"
          source="diversity.nonBinary"
          data-cy="diversity.nonBinary"
        />
      </Grid>
      <Grid item xs={3}>
        <BooleanInput
          label="Other"
          source="diversity.other"
          data-cy="diversity.other"
        />
      </Grid>
      <Grid item xs={12}>
        <TextInput
          label="Personal Biography"
          multiline
          rows={5}
          source="biography"
        />
      </Grid>
      <Grid item xs={12}>
        <TextInput
          label="Work Experience Summary"
          multiline
          rows={5}
          source="workExperienceSummary"
        />
      </Grid>
      <Grid item xs={12}>
        <TextInput
          label="Education Summary"
          multiline
          rows={5}
          source="educationSummary"
        />
      </Grid>
      <Grid item xs={12}>
        <TextInput label="Comments" multiline rows={5} source="comments" />
      </Grid>
      <Grid item xs={12}>
        <hr />
        <h3>Firm Employment</h3>
      </Grid>
      <Grid item xs={12}>
        (Optional) Select a firm or company to cause a Firm/Company Employment
        to be created when the Person is created.
      </Grid>
      <Grid item xs={12}>
        <EntityInput<NonNullable<Record>>
          resource="firm"
          recordKey="firm"
          label={"Firm"}
          allowChange={true}
          filters={{ includeCompanies: true }}
        />
      </Grid>
      <Grid item xs={12}>
        <ImageInput
          source="photo"
          label="Person Photo"
          accept={{ "image/*": [] }}
          maxSize={15 * 1000000}
        >
          <ImageField source="src" />
        </ImageInput>
      </Grid>
    </Grid>
  </CustomCreate>
);
