import * as client from "_graphql-types";
import { filterSortPage } from "../DataProvider";
import * as types from "./types";
import { getChangedFields } from "../helpers/diff";

type Sdk = ReturnType<typeof client.getSdk>;

type CompanyInput = {
  firmAttributes: { impactTags: number[] };
} & client.GetOneCompanyQuery["company"];

function formatImpactTags(data: number[] | client.ImpactEnum[]) {
  return data.map(record => (typeof record === "number" ? record : record.id));
}

const inputParams = (
  data: Partial<CompanyInput>
): client.UpdateCompanyMutationVariables["input"] => ({
  name: data.name,
  summary: data.summary,
  operatingStatusEnumId: data.operatingStatusEnumId
    ? Number(data.operatingStatusEnumId)
    : undefined,
  lastFundingTypeEnumId: data.lastFundingTypeEnumId
    ? Number(data.lastFundingTypeEnumId)
    : undefined,
  companyTypeEnumId: data?.companyTypeEnumId
    ? Number(data.companyTypeEnumId)
    : undefined,
  primaryAssignment: data?.primaryAssignment,
  ndaSigned: data.ndaSigned,
  firmAttributes: {
    stockSymbol: data.firmAttributes?.stockSymbol,
    primaryAddress: data.firmAttributes?.primaryAddress,
    yearFounded: data.firmAttributes?.yearFounded,
    employeeTotal: data.firmAttributes?.employeeTotal,
    email: data.firmAttributes?.email,
    linkedIn: data.firmAttributes?.linkedIn,
    website: data.firmAttributes?.website,
    impactTags: formatImpactTags(data.firmAttributes?.impactTags ?? []),
    isStaffMWDBE: data.firmAttributes?.isStaffMWDBE,
    firmTags: data.firmAttributes?.firmTags?.map(firmTag => ({
      tagId: firmTag.tagId,
    })),
  },
});

/*********************************************************************/
// Data Provider Functions
/*********************************************************************/

/*********************************************************************/
export async function getList(
  sdk: Sdk,
  params: types.GetListParams<client.CompanySortEnum>
) {
  const { pagination, sort, filter } = params;

  const {
    companyList: { items: data, total },
  } = await sdk.getListCompany(
    filterSortPage({
      filter,
      sort,
      nameField: client.CompanySortEnum.Name,
      pagination,
    })
  );

  return {
    data,
    total,
  };
}

/*********************************************************************/
async function getOne(sdk: Sdk, params: types.GetOneParams) {
  const id = Number(params.id);
  const { company: data } = await sdk.getOneCompany({
    id,
  });
  return {
    data,
  };
}

/*********************************************************************/
async function getMany(sdk: Sdk, params: types.GetManyParams) {
  const ids = params.ids.map(id => Number(id));

  const { companyMany: data } = await sdk.getManyCompany({
    ids,
  });

  return {
    data,
  };
}

/*********************************************************************/
async function getManyReference(
  sdk: Sdk,
  params: types.GetManyReferenceParams<client.CompanySortEnum>
) {
  const id = Number(params.id);
  const {
    pagination: { page, perPage },
    sort: { field, order },
    filter,
    target,
  } = params;

  const {
    companyList: { items: data, total },
  } = await sdk.getListCompany({
    page: {
      offset: (page - 1) * perPage,
      limit: perPage,
    },
    sort: [{ field, order }],
    filter: { ...filter, [target]: id },
  });

  return {
    data,
    total,
  };
}

/*********************************************************************/
async function create(sdk: Sdk, params: types.CreateParams<CompanyInput>) {
  const { data } = params;

  const input: client.CreateCompanyMutationVariables["input"] =
    inputParams(data);

  const {
    addCompany: { id },
  } = await sdk.createCompany({
    input,
  });

  return await getOne(sdk, { id: id! });
}

/*********************************************************************/
async function update(sdk: Sdk, params: types.UpdateParams<CompanyInput>) {
  const id = Number(params.id);
  const { data } = params;

  const input: client.UpdateCompanyMutationVariables["input"] =
    inputParams(data);

  await sdk.updateCompany({
    id,
    input,
  });
  return await getOne(sdk, { id });
}

/*********************************************************************/
export const company = types.dataProvider({
  getList,
  getOne,
  getMany,
  getManyReference,
  create,
  update,
});
