import * as client from "_graphql-types";
import * as types from "./types";

import { filterSortPage } from "../DataProvider";

type Sdk = ReturnType<typeof client.getSdk>;

/*********************************************************************/
// Data Provider Functions
/*********************************************************************/

/*********************************************************************/
async function getList(
  sdk: Sdk,
  params: types.GetListParams<client.MeasureTypeEnumSortEnum>
) {
  const { pagination, sort } = params;

  const {
    measureTypeEnumList: { items: data, total },
  } = await sdk.getListMeasureTypeEnum(filterSortPage({ sort, pagination }));

  return {
    data,
    total,
  };
}

/*********************************************************************/
async function getOne(sdk: Sdk, params: types.GetOneParams) {
  const id = Number(params.id);
  const { measureTypeEnum: data } = await sdk.getOneMeasureTypeEnum({
    id,
  });
  return {
    data,
  };
}

/*********************************************************************/
async function getMany(sdk: Sdk, params: types.GetManyParams) {
  const ids = Number(params.ids);

  const { measureTypeEnumMany: data } = await sdk.getManyMeasureTypeEnum({
    ids,
  });

  return {
    data,
  };
}

/*********************************************************************/
export const measureTypeEnum = types.dataProvider({
  getList,
  getOne,
  getMany,
  ttl: types.defaultEnumTtl,
});
