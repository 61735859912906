import BusinessApplicationDocuments from "frontend/src/components/BusinessApplicationDocuments";
import { Grid, Paper } from "@mui/material";
import * as types from "_graphql-types";
import { useQuery } from "@apollo/client";
import { GET_APPLICATION_COMPANY } from "../graphql";
import CompletionConfirmation from "../CompletionConfirmation";

function BusinessApplicationDocumentsPage() {
  const { data, error } = useQuery<types.GetApplicantCompanyQuery>(
    GET_APPLICATION_COMPANY
  );

  const ownerId = data?.companyList.items[0].id;
  if (error) return <>An error occurred, please contact support</>;
  if (!data) return null;
  if (!ownerId && typeof ownerId !== "number")
    return <>Business application unavailable, please contact support</>;

  return (
    <Grid>
      <CompletionConfirmation />
      <Grid item xs={12}>
        <Paper style={{ marginTop: "1em", padding: "0 1em" }}>
          <BusinessApplicationDocuments companyId={ownerId} />
          <br />
        </Paper>
      </Grid>
    </Grid>
  );
}

export default BusinessApplicationDocumentsPage;
