import * as client from "_graphql-types";
import * as types from "./types";
import { EnsureAllKeys } from "../helpers/types";
import { INVESTMENT_BUSINESS_OBJECT_ENUM } from "./businessObjectEnum";
import {
  DOCUMENT_ACCESS_LEVEL_ENUM,
  DOCUMENT_TYPE_ENUM,
} from "frontend/src/utils/constants";

type Sdk = ReturnType<typeof client.getSdk>;

type PortfolioConfig = NonNullable<
  client.GetOnePortfolioConfigQuery["portfolioConfig"]
>;
type TileImageInput = PortfolioConfig["tileImage"] & { rawFile: File };

type PortfolioConfigInput = EnsureAllKeys<
  client.MutationAddPortfolioConfigArgs["input"]
>;

type PhotoInput = {
  rawFile: File;
  src: string;
  title: string;
};

function getInputFromData(
  data: Partial<
    NonNullable<client.GetOnePortfolioConfigQuery["portfolioConfig"]>
  >
): PortfolioConfigInput {
  const input: PortfolioConfigInput = {
    portfolioId: data.portfolio?.id || data.portfolioId,
    performanceLag: data.performanceLag
      ? {
          lag: data.performanceLag?.lag,
          lagUnitId: data.performanceLag?.lagUnitId,
        }
      : undefined,
    lookthroughEnabled: data.lookthroughEnabled,
    showInvestments: data.showInvestments,
    lookthroughInvestmentIds: data.lookthroughInvestments?.map(inv => inv.id),
    tileImageId: data.tileImageId,
    clientVisibleTabs: data.clientVisibleTabs,
  };
  return input;
}

function getTileImageInput({
  photo,
  portfolioId,
}: {
  photo: PhotoInput;
  portfolioId: number;
}) {
  const photoInput: client.AddDocumentMutationVariables = {
    file: photo.rawFile,
    businessObjectEnumId: INVESTMENT_BUSINESS_OBJECT_ENUM,
    investmentId: portfolioId,
    documentTypeEnumId: DOCUMENT_TYPE_ENUM.logo,
    date: new Date().toISOString().split("T")[0],
    allowDuplicateFile: true,
    accessLevel: DOCUMENT_ACCESS_LEVEL_ENUM.PUBLIC,
  };
  return photoInput;
}

/*********************************************************************/
// Data Provider Functions
/*********************************************************************/

/*********************************************************************/
async function getList(
  sdk: Sdk,
  params: types.GetListParams<client.PortfolioConfigSortEnum>
) {
  const {
    pagination: { page, perPage },
    sort: { field, order },
    filter,
  } = params;

  const {
    portfolioConfigList: { items: data, total },
  } = await sdk.getListPortfolioConfig({
    page: {
      offset: (page - 1) * perPage,
      limit: perPage,
    },
    sort: [{ field, order }],
    filter,
  });

  return {
    data,
    total,
  };
}

/*********************************************************************/
async function getOne(sdk: Sdk, params: types.GetOneParams) {
  const id = Number(params.id);
  const { portfolioConfig: data } = await sdk.getOnePortfolioConfig({
    id,
  });

  return {
    data,
  };
}

/*********************************************************************/
async function getMany(sdk: Sdk, params: types.GetManyParams) {
  const ids = params.ids.map(Number);

  const { portfolioConfigMany: data } = await sdk.getManyPortfolioConfig({
    ids,
  });

  return {
    data,
  };
}

/*********************************************************************/
async function getManyReference(
  sdk: Sdk,
  params: types.GetManyReferenceParams<client.PortfolioConfigSortEnum>
) {
  const id = params.id;
  const {
    pagination: { page, perPage },
    sort: { field, order },
    filter,
    target,
  } = params;

  const {
    portfolioConfigList: { items: data, total },
  } = await sdk.getListPortfolioConfig({
    page: {
      offset: (page - 1) * perPage,
      limit: perPage,
    },
    sort: [{ field, order }],
    filter: { ...filter, [target]: id },
  });

  return {
    data,
    total,
  };
}

/*********************************************************************/
async function create(
  sdk: Sdk,
  params: types.CreateParams<
    NonNullable<client.GetOnePortfolioConfigQuery["portfolioConfig"]>
  > & { data: { photo?: PhotoInput } }
) {
  const { photo, ...data } = params.data;

  if (photo?.rawFile && data.portfolioId) {
    const photoInput = getTileImageInput({
      photo,
      portfolioId: data.portfolioId,
    });
    const {
      addDocument: { id },
    } = await sdk.addDocument({
      ...photoInput,
    });
    data.tileImageId = id;
  }

  const input: client.CreatePortfolioConfigMutationVariables["input"] =
    getInputFromData(data);

  const {
    addPortfolioConfig: { id },
  } = await sdk.createPortfolioConfig({
    input,
  });
  return await getOne(sdk, { id: id! });
}

/*********************************************************************/
async function update(
  sdk: Sdk,
  params: types.UpdateParams<
    NonNullable<client.GetOnePortfolioConfigQuery["portfolioConfig"]>
  > & { data: { tileImage?: PhotoInput } }
) {
  const id = Number(params.id);
  const { tileImageId, tileImage, ...data } = params.data;
  let _tileImageId: number | null | undefined = tileImageId || tileImage?.id;
  if (tileImage === null) {
    _tileImageId = null;
  } // when user removes the image

  const _portfolioId = data.portfolioId || params.previousData?.portfolioId;

  if (tileImage?.rawFile && _portfolioId) {
    const photoInput = getTileImageInput({
      photo: tileImage,
      portfolioId: _portfolioId,
    });
    if (tileImage?.rawFile.name !== params.previousData?.tileImage?.name) {
      const {
        addDocument: { id },
      } = await sdk.addDocument({
        ...photoInput,
      });
      _tileImageId = id;
    }
  }

  const input: client.UpdatePortfolioConfigMutationVariables["input"] =
    getInputFromData({ ...data, tileImageId: _tileImageId });

  await sdk.updatePortfolioConfig({
    id,
    input,
  });
  return await getOne(sdk, { id });
}

/*********************************************************************/
export const portfolioConfig = types.dataProvider({
  getList,
  getOne,
  getMany,
  getManyReference,
  create,
  update,
});
