import { WarningOutlined } from "@ant-design/icons";
import LoadingButton from "@mui/lab/LoadingButton";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import * as client from "_graphql-types";
import { AsyncTaskStatus } from "_graphql-types";
import { useState } from "react";
import { useDataProvider } from "react-admin";

interface Message {
  value?: string;
  error?: string;
}

export function RefreshInvestmentRisk() {
  const dataProvider = useDataProvider();
  const [bulkFromEntry, setBulkFromEntry] = useState("");
  const [specificInvestments, setSpecificInvestments] = useState(true);
  const [working, setWorking] = useState(false);
  const [message, setMessage] = useState<Message | undefined>();

  return (
    <>
      <h3>Refresh Risk Data</h3>
      <Stack direction="row" spacing={2} alignItems="center">
        <span>Refresh risk for:</span>
        <RadioGroup
          row
          style={{ display: "inline-block" }}
          name="refresh-investments-group"
          value={specificInvestments ? "CUSTOM" : "DATABASE"}
          onChange={e => {
            const isSpecific = e.target.value === "CUSTOM";
            setSpecificInvestments(isSpecific);
          }}
        >
          <FormControlLabel
            value="CUSTOM"
            control={<Radio />}
            label="Custom List"
          />
          <FormControlLabel
            value="DATABASE"
            control={<Radio />}
            label="Daily Refresh List"
          />
        </RadioGroup>
      </Stack>
      {specificInvestments && (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            Enter the investment IDs below, one per line.
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="bulkFromEntry"
              multiline
              rows={10}
              fullWidth
              variant="outlined"
              onChange={e => setBulkFromEntry(e.target.value.trim())}
            />
          </Grid>
        </Grid>
      )}
      <div style={{ margin: "10px", marginLeft: 0 }}>
        <LoadingButton
          variant="contained"
          color="primary"
          disabled={(specificInvestments && !bulkFromEntry) || working}
          onClick={async () => {
            setMessage(undefined);
            // parse the IDs
            const lines = bulkFromEntry.split("\n").flatMap(x => {
              x = x.trim();
              return x ? [x] : [];
            });
            const bulkFromEntryIds = new Set<number>();
            for (const line of lines) {
              const id = Number(line);
              if (isFinite(id) && id === Math.trunc(id)) {
                bulkFromEntryIds.add(id);
              } else {
                const error = `Invalid ID: ${line}`;
                setMessage({ error });
                return;
              }
            }
            if (specificInvestments && !bulkFromEntryIds.size) return;

            setWorking(true);
            try {
              // validate the IDs
              const entryIds = [...bulkFromEntryIds];
              const { data: items } = await dataProvider.getMany<
                NonNullable<
                  client.GetManyInvestmentQuery["investmentMany"][number]
                >
              >("investment", {
                ids: entryIds,
              });
              const validIds = new Set(items.flatMap(x => (x ? [x.id] : [])));
              const errorIds = entryIds.filter(id => !validIds.has(id));
              if (errorIds.length) {
                const error = `Unable to match ${
                  errorIds.length
                } ID(s): ${errorIds.join(", ")}`;
                setMessage({ error });
                setWorking(false);
                return;
              }
              // refresh risk data
              const { id } = (
                await dataProvider.update("investmentRisk", {
                  id: "refreshInvestmentRisk",
                  data: {
                    listType: specificInvestments ? "CUSTOM" : "DATABASE",
                    investmentIds: specificInvestments
                      ? [...validIds]
                      : undefined,
                  },
                  previousData: { id: "" },
                })
              ).data as AsyncTaskStatus;
              console.log("task ID ->", id);
              if (!id) throw Error("No task ID");
              let timeout = 0;
              let interval = 0;
              interval = window.setInterval(async () => {
                const { endDate, error } = (
                  await dataProvider.getOne("asyncTaskStatus", {
                    id,
                  })
                ).data as AsyncTaskStatus;
                if (error) console.log(error);
                if (endDate) {
                  clearInterval(interval);
                  clearTimeout(timeout);
                  if (error) setMessage({ error: "An error occurred" });
                  else setMessage({ value: "Refresh completed successfully" });
                  setWorking(false);
                }
              }, 1000 * 5);
              timeout = window.setTimeout(async () => {
                clearInterval(interval);
                setMessage({ error: "An error occurred (timeout)" });
                setWorking(false);
              }, 1000 * 60 * 30);
            } catch (error) {
              setMessage({ error: "An error occurred" });
              setWorking(false);
              throw error;
            }
          }}
          loading={working}
          data-cy="refresh-submit"
        >
          Refresh
        </LoadingButton>
      </div>
      {working ? (
        <p data-cy="working">Working...</p>
      ) : message?.error ? (
        <p data-cy="message-error">
          <WarningOutlined
            style={{
              fontSize: "50px",
              color: "red",
              verticalAlign: "middle",
            }}
          />{" "}
          {message.error}
        </p>
      ) : message?.value ? (
        <p data-cy="message-value">{message.value}</p>
      ) : null}
    </>
  );
}
