import { Button } from "ra-ui-materialui";
import {
  ChipField,
  Datagrid,
  DateField,
  FunctionField,
  TextField,
} from "react-admin";
import { Link } from "react-router-dom";

export const NotesDataGrid = () => {
  return (
    <Datagrid data-id="notes-grid" bulkActionButtons={false}>
      <FunctionField
        label=""
        render={(rec: any) =>
          rec && (
            <Button
              label="Edit"
              component={Link}
              variant="contained"
              to={{
                pathname: `/notes/${rec.id}`,
              }}
            />
          )
        }
      />
      <TextField source="text" />
      <TextField source="modifyUser" />
      <DateField label="Date" source="noteDate" options={{ timeZone: "UTC" }} />
      <ChipField label="Type" source="noteMeta.label" sortable={false} />
    </Datagrid>
  );
};
