import * as client from "_graphql-types";
import * as types from "./types";
import { getChangedFields } from "../helpers/diff";

type Sdk = ReturnType<typeof client.getSdk>;

/*********************************************************************/
// Data Provider Functions
/*********************************************************************/

/*********************************************************************/

async function getOne(sdk: Sdk, params: types.GetOneParams) {
  const id = Number(params.id);
  const { investment: data } = await sdk.getOneInvestmentCloseDates({
    investmentId: id,
  });

  return { data };
}

async function getManyReference(
  sdk: Sdk,
  params: types.GetManyReferenceParams<any>
) {
  const { investment: data } = await sdk.getOneInvestmentCloseDates({
    investmentId: Number(params.id),
  });

  return {
    data: data?.closeDateFundSize?.map((rec, i) => ({ id: i, ...rec })) ?? [],
    total: data?.closeDateFundSize?.length ?? 0,
  };
}

async function update(sdk: Sdk, params: any) {
  const investmentId = Number(params.id);
  const { data } = params;
  const closeDates = data.closeDateFundSize
    .filter(Boolean)
    .map(
      ({ closeDate, fundSize }: { closeDate: string; fundSize: string }) => ({
        fundSize: Number(fundSize),
        closeDate,
      })
    );

  await sdk.updateInvestmentCloseDates({
    investmentId,
    closeDates,
  });
  return await getOne(sdk, { id: investmentId });
}

export const closeDate = types.dataProvider({
  getOne,
  update,
  getManyReference,
});
