import * as client from "_graphql-types";
import { CustomEdit } from "../CustomEdit";
import CustomBreadCrumb from "../CustomBreadCumb";
import { ShareClassForm } from "./Form";
import { useCanAccessMutation } from "../../util/useCanAccessMutation";

type IRecord = NonNullable<client.GetOneShareClassQuery["shareClass"]>;

export function ShareClassEdit() {
  const {
    canEdit,
    canEditField: fieldAccessMap,
    loading,
  } = useCanAccessMutation("updateShareClass", JSON.stringify({}));

  function canEditField(field: string) {
    return canEdit && fieldAccessMap[field];
  }

  return (
    <CustomEdit<IRecord>
      title={() => "Edit Share Class"}
      actions={
        <CustomBreadCrumb<IRecord>
          name="shareClass"
          items={[
            {
              path: "investment",
              getId: record => record?.investment?.id,
              getName: record => record?.investment?.name,
            },
            {
              path: "investmentTerms",
              getId: record => record?.investment?.id,
              getName: () => "Terms",
            },
          ]}
        />
      }
      customFormProps={{
        customToolbarProps: {
          canAccessMutation: canEdit,
        },
        canEditField: fieldAccessMap,
        loading,
      }}
    >
      <ShareClassForm canEditField={canEditField} />
    </CustomEdit>
  );
}
