import * as client from "_graphql-types";
import { SelectInput, TextInput, useGetRecordId, useNotify } from "react-admin";
import { useNavigate } from "react-router-dom";
import { Grid } from "@mui/material";
import { CustomEdit } from "../../CustomEdit";
import { PrimaryAddressInput } from "../Create";
import { validateYearFounded } from "../../firm/Edit";
import CompletionConfirmation from "./CompletionConfirmation";
import { useMemo } from "react";
import { useCanAccessMutation } from "../../../util/useCanAccessMutation";

type Record = NonNullable<client.GetOneCompanyQuery["company"]>;

export const BasicInfo = () => {
  const notify = useNotify();
  const navigate = useNavigate();
  const recordId = useGetRecordId();
  const { canEdit, canEditField, loading } = useCanAccessMutation(
    "updateCompany",
    JSON.stringify({ id: recordId })
  );
  const yearChoices = useMemo(
    () =>
      [...Array(100).keys()].map(v => ({
        id: new Date().getFullYear() - v,
        name: `${new Date().getFullYear() - v}`,
      })),
    []
  );

  return (
    <CustomEdit<Record>
      title={record => record && `Company - ${record.name || ""}`}
      mutationOptions={{
        onSuccess: () => {
          notify("Success", { type: "info" });
          navigate("/businessApplication");
        },
      }}
      customFormProps={{
        customToolbarProps: {
          canAccessMutation: canEdit,
        },
        loading,
        canEditField,
      }}
    >
      <CompletionConfirmation />
      <Grid>
        <Grid item xs={6}>
          <TextInput label="Legal Name" source="name" />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            label="Main Business Email"
            source="firmAttributes.primaryAddress.email"
          />
        </Grid>
        <Grid item xs={12}>
          <TextInput
            label="Company Summary"
            source="summary"
            placeholder="Give a concise description of the business' primary activities and the product(s) or service(s) it provides."
            multiline
            rows={5}
          />
        </Grid>
        <Grid item xs={6}>
          <SelectInput
            label="Year Founded"
            source="firmAttributes.yearFounded"
            validate={validateYearFounded}
            choices={yearChoices} // last 100 years.
          />
        </Grid>
        <Grid item xs={12}>
          <hr />
          <h3>Address</h3>
        </Grid>
        <Grid item xs={12}>
          <PrimaryAddressInput />
        </Grid>
      </Grid>
    </CustomEdit>
  );
};
