import { DocumentTypeEnum } from "_graphql-types";
import { lastDayOfMonth, lastDayOfQuarter, parse } from "date-fns";
import { capitalize } from "lodash";

const [
  COMPANY_BUSINESS_OBJECT_ENUM,
  DEAL_BUSINESS_OBJECT_ENUM,
  FIRM_BUSINESS_OBJECT_ENUM,
  INVESTMENT_BUSINESS_OBJECT_ENUM,
] = [3, 4, 2, 1];

export const PERIODIC_DOC_CATEGORY = 1;
export const DILIGENCE_DOC_CATEGORY = 2;
export const CLIENT_DOC_CATEGORY = 4;

export const CLIENT_DOC_CATEGORIES = [CLIENT_DOC_CATEGORY];
export const NON_CLIENT_DOC_CATEGORIES = [
  PERIODIC_DOC_CATEGORY,
  DILIGENCE_DOC_CATEGORY,
];

/**
 * If the documentCategoryEnum is client, append "(Client)" to the document type name.
 */
export function distinguishClientDocTypes(record: DocumentTypeEnum) {
  if (record.documentCategoryEnumId === CLIENT_DOC_CATEGORY) {
    return `${record.name} (Client)`;
  }
  return `${record.name}`;
}

export type DocumentEntityName = "company" | "deal" | "firm" | "investment";

export function getEntityNameFromBusinessObjectEnum(
  businessObjectEnumId: number
) {
  return businessObjectEnumId === FIRM_BUSINESS_OBJECT_ENUM
    ? "firm"
    : businessObjectEnumId === INVESTMENT_BUSINESS_OBJECT_ENUM
    ? "investment"
    : businessObjectEnumId === DEAL_BUSINESS_OBJECT_ENUM
    ? "deal"
    : businessObjectEnumId === COMPANY_BUSINESS_OBJECT_ENUM
    ? "company"
    : undefined;
}

export function getDocumentRoute(
  businessObjectEnumId: number,
  getId: (entityName: DocumentEntityName) => number | undefined
) {
  const entityName = getEntityNameFromBusinessObjectEnum(businessObjectEnumId);

  if (!entityName) return;
  const id = getId(entityName);
  if (!id) return;

  return `/${entityName}Document/${id}`;
}

const COMMON_DATE_FORMATS = [
  {
    exp: /(1[0-2]|0[1-9])\/\d{1,2}\/\d{4}/,
    formatString: "MM/dd/yyyy",
  }, // 1/12/2022
  {
    exp: /\d{1,2}\/(1[0-2]|0[1-9]|[1-9])\/\d{4}/,
    formatString: "dd/MM/yyyy",
  }, // 31/01/2023
  {
    exp: /(1[0-2]|0[1-9])\-\d{1,2}\-\d{4}/,
    formatString: "MM-dd-yyyy",
  }, // 1-12-2022
  {
    exp: /\d{1,2}\-(1[0-2]|0[1-9]|[1-9])\-\d{4}/,
    formatString: "dd-MM-yyyy",
  }, // 31-01-2023
  {
    exp: /\d{1,2} \b(january|february|march|april|may|june|july|august|september|october|november|december) \d{4}/,
    formatString: "dd MMMM yyyy",
  }, // 1 December 2022
  {
    exp: /\d{1,2} [a-zA-Z]{3} \d{4}/,
    formatString: "dd MMM yyyy",
  }, // 1 Dec 2022
  {
    exp: /\b(january|february|march|april|may|june|july|august|september|october|november|december) \d{1,2} \d{4}/,
    formatString: "MMMM dd yyyy",
  }, // December 1 2022
  {
    exp: /\b(january|february|march|april|may|june|july|august|september|october|november|december) \d{4}/,
    formatString: "MMMM yyyy",
    selectDay: (date: Date) => lastDayOfMonth(date),
  }, // December 2022
  {
    exp: /\d{4}q\d{1}/,
    formatString: "yyyyQQQ",
    selectDay: (date: Date) => {
      return lastDayOfQuarter(date);
    },
  }, //2024q1
];

export const findDate = (text: string) => {
  const simplifiedText = text
    .replaceAll(",", "")
    .replaceAll(".", "")
    .toLowerCase();
  console.log(simplifiedText);
  let bestIndex = Infinity;
  let bestDate: Date | undefined;
  for (const { exp, formatString, selectDay } of COMMON_DATE_FORMATS) {
    const matches = simplifiedText.match(exp);
    if (typeof matches?.index === "number") {
      const date = parse(matches[0].toUpperCase(), formatString, new Date());
      // date should not be more than five years in the past nor one year in the future
      const yearDiff =
        (date.getTime() - Date.now()) / (365.25 * 24 * 60 * 60 * 1000);
      if (matches.index < bestIndex && -5 <= yearDiff && yearDiff <= 1) {
        bestIndex = matches.index;
        bestDate = selectDay ? selectDay(date) : date;
      }
    }
  }
  return bestDate;
};

export const capitalizedName = (rec: any) => capitalize(rec.name);
