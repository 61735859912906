import * as client from "_graphql-types";
import * as types from "./types";

import { filterSortPage } from "../DataProvider";
import { getChangedFields } from "../helpers/diff";

type Sdk = ReturnType<typeof client.getSdk>;

type Record = client.CreateCustomAnalyticsMutationVariables["input"] & {
  investment?: { id: number; name: string };
};

function inputParams(
  params: Record
): client.CreateCustomAnalyticsMutationVariables["input"] {
  return {
    ownerId: params.investment?.id,
    ownerType: client.FieldOwnerType.Investment,
    value: params.value,
    file: params.file,
    asOfDate: params.asOfDate
      ? new Date(params.asOfDate).toISOString()
      : undefined,
  };
}

/*********************************************************************/
// Data Provider Functions
/*********************************************************************/

/*********************************************************************/
async function getList(
  sdk: Sdk,
  params: types.GetListParams<client.CustomAnalyticsSortEnum>
) {
  const { pagination, sort, filter } = params;

  let filters = filter;
  if (filter) {
    const { investmentId, firmId, ...restOfFilter } = filter;
    if (investmentId) {
      filters = {
        ownerId: filter.investmentId,
        ownerType: client.FieldOwnerType.Investment,
        ...restOfFilter,
      };
    }
    if (firmId) {
      filters = {
        ownerId: filter.firmId,
        ownerType: client.FieldOwnerType.Firm,
        ...restOfFilter,
      };
    }
  }

  const {
    customAnalyticsList: { items: data, total },
  } = await sdk.getListCustomAnalytics(
    filterSortPage({
      filter: filters,
      sort,
      pagination,
    })
  );

  return {
    data,
    total,
  };
}

/*********************************************************************/
async function getManyReference(
  sdk: Sdk,
  params: types.GetManyReferenceParams<client.CustomAnalyticsSortEnum>
) {
  const id = Number(params.id);
  const {
    pagination: { page, perPage },
    sort: { field, order },
    filter,
    target,
  } = params;

  const {
    customAnalyticsList: { items: data, total },
  } = await sdk.getListCustomAnalytics({
    page: {
      offset: (page - 1) * perPage,
      limit: perPage,
    },
    sort: [{ field, order }],
    filter: { ...filter, [target]: id },
  });

  return {
    data,
    total,
  };
}
/*********************************************************************/
async function getOne(sdk: Sdk, params: types.GetOneParams) {
  const id = params.id;
  const { customAnalytics: data } = await sdk.getOneCustomAnalytics({
    id: String(id),
  });

  return {
    data: {
      ...data,
      asOfDate: data.asOfDate
        ? new Date(data?.asOfDate).toISOString().substring(0, 10)
        : undefined,
    },
  };
}

/*********************************************************************/
async function create(sdk: Sdk, params: types.CreateParams<Record>) {
  const { data } = params;

  const input = inputParams(data);

  const {
    addCustomAnalytics: { id },
  } = await sdk.createCustomAnalytics({
    input,
  });

  return await getOne(sdk, { id: id! });
}

/*********************************************************************/
async function update(sdk: Sdk, params: types.UpdateParams<Record>) {
  const id = String(params.id);
  const { data } = params;
  const input = inputParams(data);

  await sdk.updateCustomAnalytics({
    id,
    input,
  });
  return await getOne(sdk, { id });
}

/*********************************************************************/
export const customAnalytics = types.dataProvider({
  getList,
  getOne,
  create,
  update,
  getManyReference,
});
