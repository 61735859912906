import {
  AutocompleteInput,
  Filter,
  ReferenceInput,
  TextInput,
} from "react-admin";

export const ServiceProviderNotesFilter = () => (
  <Filter>
    <TextInput label="Text Search" source="q" alwaysOn resettable />
    <ReferenceInput
      source="providerId"
      alwaysOn
      reference="serviceProviderFirm"
    >
      <AutocompleteInput optionText="name" label="Service Provider" fullWidth />
    </ReferenceInput>

    <ReferenceInput
      source="providerNoteTypeEnumId"
      alwaysOn
      reference="globalProviderNoteTypeEnum"
    >
      <AutocompleteInput optionText="name" label="Type" fullWidth />
    </ReferenceInput>
  </Filter>
);
