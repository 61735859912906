import { ComponentProps } from "react";
import { Create, RaRecord, SaveContext, SaveContextValue } from "react-admin";
import { CustomForm, CustomFormProps } from "./CustomForm";
import { NestProps } from "./react-admin-fixes";

export type CustomCreateProps<TRecord extends RaRecord> = NestProps<
  ComponentProps<typeof Create>,
  "customFormProps",
  CustomFormProps<TRecord>,
  "title"
>;

function CustomTitle<TRecord extends RaRecord>(
  props: Pick<CustomCreateProps<TRecord>, "title" | "record">
) {
  return !props.title ? null : (
    <span>{props.title(props.record as TRecord)}</span>
  );
}

export const CustomCreate = <TRecord extends RaRecord>({
  children,
  customFormProps,
  ...props
}: CustomCreateProps<TRecord>) => (
  <Create
    {...props}
    title={<CustomTitle<TRecord> title={props.title} record={props.record} />}
  >
    <CustomForm<TRecord> {...customFormProps}>{children}</CustomForm>
  </Create>
);
