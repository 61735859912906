import * as client from "_graphql-types";
import * as types from "./types";

import { filterSortPage } from "../DataProvider";

type Sdk = ReturnType<typeof client.getSdk>;

/*********************************************************************/
// Data Provider Functions
/*********************************************************************/

/*********************************************************************/
export async function getList(
  sdk: Sdk,
  params: types.GetListParams<client.TypeAssetClassStrategyOptionsSortEnum>
) {
  const { sort, filter } = params;

  const {
    strategyOptions: { items: data, total },
  } = await sdk.getListStrategyEnumOptions(filterSortPage({ filter, sort }));

  return {
    data,
    total,
  };
}

/*********************************************************************/
async function getOne(sdk: Sdk, params: types.GetOneParams) {
  const id = Number(params.id);
  const { strategyEnum: data } = await sdk.getOneStrategyEnum({
    id,
  });
  return {
    data,
  };
}

/*********************************************************************/
async function getMany(sdk: Sdk, params: types.GetManyParams) {
  const ids = params.ids.map(id => Number(id));

  const { strategyEnumMany: data } = await sdk.getManyStrategyEnum({
    ids,
  });

  return {
    data,
  };
}

/*********************************************************************/
export const strategyEnum = types.dataProvider({
  getList,
  getOne,
  getMany,
  ttl: types.defaultEnumTtl,
});
