import * as client from "_graphql-types";

import {
  AutocompleteInput,
  BooleanInput,
  DateInput,
  ReferenceInput,
  TextInput,
} from "react-admin";
import { CustomCreate } from "../CustomCreate";

import { useWatch } from "react-hook-form";

import Grid from "@mui/material/Grid";
import { Button, required } from "react-admin";
import { Link } from "react-router-dom";
import { FaceOfEntityTooltip } from "../FaceOfEntityTooltip";

type Record = NonNullable<
  client.GetOneInvestmentFirmEmploymentQuery["investmentFirmEmployment"]
>;

const validateRequired = [required()];

const ViewFirmEmploymentButton = (props: any) => {
  const {
    record,
  }: {
    record: Record;
  } = props;
  return !record?.firmEmployment?.id ? null : (
    <Button
      component={Link}
      to={{
        pathname: `/firmEmployment/${record.firmEmployment.id}`,
      }}
      label="Go to Firm Employment"
    />
  );
};

const ViewPersonButton = (props: any) => {
  const record = props.record as Record;
  return !record?.firmEmployment?.person?.id ? null : (
    <Button
      component={Link}
      to={{
        pathname: `/person/${record.firmEmployment.person.id}`,
      }}
      label="Go to Person"
    />
  );
};

function InvestmentFirmEmploymentCreateForm() {
  const formData = useWatch();
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <ViewFirmEmploymentButton />
        </Grid>
        <Grid item xs={6}>
          <ViewPersonButton />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            label="Firm"
            source="dummy"
            format={() => formData?.firmEmployment?.firm?.name}
            disabled
          />
        </Grid>
        <Grid item xs={4}>
          <TextInput
            label="Person"
            source="dummy"
            format={() =>
              [formData?.firmEmployment?.person]
                .filter(p => p)
                .map(
                  p =>
                    `${p?.lastName || ""}, ${p?.firstName || ""} ${
                      p?.middleName || ""
                    }`
                )[0]
            }
            disabled
          />
        </Grid>
        <Grid item xs={2}>
          <TextInput
            label="Firm Employment ID"
            source="dummy"
            format={() => formData?.firmEmployment?.id}
            disabled
          />
        </Grid>
        <Grid item xs={6}>
          <ReferenceInput
            filter={{ firmId: formData?.firmEmployment?.firm?.id }}
            source="investment.id"
            reference="investment"
            sort={{ field: "name", order: "ASC" }}
          >
            <AutocompleteInput
              optionText="name"
              label="Investment"
              validate={validateRequired}
            />
          </ReferenceInput>
        </Grid>
        <Grid item xs={4}>
          <ReferenceInput
            source="employeeRoleEnumId"
            reference="investmentFirmEmploymentRoleEnum"
            sort={{ field: "name", order: "ASC" }}
          >
            <AutocompleteInput optionText="name" label="Employee Role" />
          </ReferenceInput>
        </Grid>
        <Grid item xs={2}></Grid>
        <Grid item xs={3}>
          <DateInput label="Start Date" source="startDate" />
        </Grid>
        <Grid item xs={3}>
          <DateInput label="End Date" source="leftDate" />
        </Grid>
        <Grid item xs={3}>
          <FaceOfEntityTooltip entity="investment">
            <BooleanInput
              label="Face of Investment"
              source="faceOfInvestment"
            />
          </FaceOfEntityTooltip>
        </Grid>
      </Grid>
    </>
  );
}

export const InvestmentFirmEmploymentCreate = () => (
  <CustomCreate title={() => "Create Investment Assignment"}>
    <InvestmentFirmEmploymentCreateForm />
  </CustomCreate>
);
