import * as client from "_graphql-types";
import { Button } from "ra-ui-materialui";
import { useState } from "react";
import {
  Edit,
  Pagination,
  ReferenceManyField,
  useRecordContext,
} from "react-admin";
import { Link } from "react-router-dom";
import { DEAL_BUSINESS_OBJECT_ENUM } from "../../dataProvider/resources/document";
import CustomBreadCrumb from "../CustomBreadCumb";
import {
  CustomDocumentFilterToolbar,
  DocumentToolbarFilters,
} from "../document/CustomDocumentFilterToolbar";
import { DocumentEditableDataGrid } from "../document/EditableDataGrid";

type Record = NonNullable<client.GetOneDealQuery["deal"]>;

const Title = () => {
  const record = useRecordContext<Record>();
  return (
    <span data-id="deal-documents-title">
      Deal Documents - {record ? `${record.name}` : ""}{" "}
      <Button
        label="Create Documents"
        component={Link}
        variant="contained"
        data-cy="createDocumentsMulti"
        to={{
          pathname: `/documentMulti/create`,
          search: `?source=${JSON.stringify({
            businessObjectEnumId: DEAL_BUSINESS_OBJECT_ENUM,
            ...(record && {
              deal: { id: record.id, name: encodeURIComponent(record.name!) },
            }),
          })}`,
        }}
      />
    </span>
  );
};

const DealDocumentDataGrid = () => {
  const record = useRecordContext<Record>();
  if (!record) return;

  const [filters, setFilters] = useState<DocumentToolbarFilters>({
    isApproved: null,
    documentTypeEnumId: null,
    accessLevel: null,
    minDate: null,
  });

  return (
    <ReferenceManyField
      label="Deal Documents"
      reference="document"
      target="dealId"
      perPage={10}
      pagination={<Pagination />}
      sort={{ field: "date", order: "DESC" }}
      filter={filters}
    >
      <CustomDocumentFilterToolbar filters={filters} setFilters={setFilters} />
      <DocumentEditableDataGrid
        referenceKey="deal_documents"
        noDelete={false}
        bulkActionButtons={false}
        defaultRowValues={{
          businessObjectEnumId: DEAL_BUSINESS_OBJECT_ENUM,
          dealId: record.id,
        }}
        entityOwner={{ dealId: record.id }}
      />
    </ReferenceManyField>
  );
};

export const DealDocumentShow = () => {
  return (
    <Edit
      title={<Title />}
      actions={
        <CustomBreadCrumb<Record>
          name="dealDocument"
          items={[
            {
              path: "firm",
              getId: record => record?.firm?.id,
              getName: record => record?.firm?.name,
            },
            {
              path: "company",
              getId: record => record?.company?.id,
              getName: record => record?.company?.name,
            },
            {
              path: "deal",
              getId: record => record?.id,
              getName: record => record?.name,
            },
          ]}
        />
      }
    >
      <DealDocumentDataGrid />
    </Edit>
  );
};
