import * as client from "_graphql-types";
import { filterSortPage } from "../DataProvider";
import * as types from "./types";

type Sdk = types.Sdk;

export const INVESTMENT_BUSINESS_OBJECT_ENUM = 1;
export const FIRM_BUSINESS_OBJECT_ENUM = 2;
export const COMPANY_BUSINESS_OBJECT_ENUM = 3;
export const DEAL_BUSINESS_OBJECT_ENUM = 4;

interface DocCreateParams extends client.DocumentInput {
  file: any;
  firm: { id: number; name: string };
  investment: { id: number; name: string };
  deal: { id: number; name: string };
  company: { id: number; name: string };
  accessLevel: number;
  isGallery: boolean;
  isApproved: boolean;
  notifyUsers: boolean;
  allowDuplicateFile: boolean;
}

export type DocumentCreateParams = types.CreateParams<DocCreateParams>;

export class DuplicateDocumentFileError extends Error {
  constructor(
    public readonly duplicateDocumentFiles: client.GetDocumentsListQuery["documents"]["items"]
  ) {
    super("Duplicate document file");
  }
}

async function duplicateCheck(sdk: Sdk, file: File) {
  const {
    documents: { items: duplicateDocumentFiles },
  } = await sdk.getDocumentsList({
    filter: {
      duplicates: file,
    },
    page: {
      offset: 0,
      limit: 10,
    },
    sort: [
      {
        field: client.DocumentSortEnum.Name,
        order: client.SortInput.Asc,
      },
    ],
  });

  if (duplicateDocumentFiles.length) {
    throw new DuplicateDocumentFileError(duplicateDocumentFiles);
  }
}

/*********************************************************************/
// Data Provider Functions
/*********************************************************************/

/*********************************************************************/
async function getList(
  sdk: Sdk,
  params: types.GetListParams<client.DocumentSortEnum>
) {
  const { pagination, filter, sort } = params;

  const res = await sdk.getDocumentsList(
    filterSortPage({
      filter,
      sort,
      pagination,
    })
  );
  console.log(res);

  return { data: res.documents?.items, total: res.documents?.total };
}

/*********************************************************************/
async function getManyReference(
  sdk: Sdk,
  params: types.GetManyReferenceParams<client.DocumentSortEnum>
) {
  const id = Number(params.id);
  const {
    pagination: { page, perPage },
    sort: { field, order },
    filter,
    target,
  } = params;

  const res = await sdk.getDocumentsList({
    filter: { ...filter, [target]: id },
    sort: [{ field, order }],
    page: {
      offset: (page - 1) * perPage,
      limit: perPage,
    },
  });

  return { data: res.documents?.items, total: res.documents?.total };
}

/*********************************************************************/
async function getOne(sdk: Sdk, params: types.GetOneParams) {
  const id = Number(params.id);

  const { document: data } = await sdk.getOneDocument({ id });

  return { data };
}

/*********************************************************************/
async function update(sdk: Sdk, params: types.UpdateParams<DocCreateParams>) {
  const id = Number(params.id);
  const { data } = params;

  const file = data.file?.rawFile;
  if (file && !data.allowDuplicateFile) {
    await duplicateCheck(sdk, file);
  }

  // Since we're arbitrarily deleting props from the data object,
  // we need to account for that!
  // Avoid making NaN that becomes NULL in graphql!

  const num = (t: number | null | undefined) => t && Number(t);

  const businessObjectEnumId = num(data.businessObjectEnumId);
  const companyId = num(data.company?.id ?? data.companyId);
  const dealId = num(data.deal?.id ?? data.dealId);
  const firmId = num(data.firm?.id ?? data.firmId);
  const investmentId = num(data.investment?.id ?? data.investmentId);

  const input: client.UpdateDocumentMutationVariables = {
    id,
    date: data.date?.split("T")[0],
    documentTypeEnumId: data.documentTypeEnumId,
    businessObjectEnumId,
    companyId,
    dealId,
    firmId,
    investmentId,
    file,
    accessLevel: data.accessLevel,
    isGallery: data.isGallery,
    isApproved: data.isApproved,
    notifyUsers: data.notifyUsers,
    allowDuplicateFile: data.allowDuplicateFile,
  };
  await sdk.updateDocument(input);

  return await getOne(sdk, { id });
}

/*********************************************************************/
async function create(sdk: Sdk, param: types.CreateParams<DocCreateParams>) {
  const { data } = param;

  console.log("data ->", data);

  const file = data.file?.rawFile;
  if (!file) {
    throw new Error("Missing file");
  }
  if (!data.allowDuplicateFile) {
    // Check for duplicate file
    await duplicateCheck(sdk, file);
  }

  // Since we're arbitrarily deleting props from the data object,
  // we need to account for that!
  // Avoid making NaN that becomes NULL in graphql!

  const num = (t: number | null | undefined) => t && Number(t);

  const businessObjectEnumId = num(data.businessObjectEnumId);
  if (!businessObjectEnumId) {
    throw Error(`No owner selected`);
  }
  const companyId = num(data.company?.id ?? data.companyId);
  const dealId = num(data.deal?.id ?? data.dealId);
  const firmId = num(data.firm?.id ?? data.firmId);
  const investmentId = num(data.investment?.id ?? data.investmentId);

  const {
    addDocument: { id },
  } = await sdk.addDocument({
    file,
    date: types.required(param.data, "date").split("T")[0],
    documentTypeEnumId: types.required(param.data, "documentTypeEnumId"),
    businessObjectEnumId,
    companyId,
    dealId,
    firmId,
    investmentId,
    accessLevel: data.accessLevel,
    isGallery: data.isGallery,
    isApproved: data.isApproved,
    notifyUsers: data.notifyUsers,
    allowDuplicateFile: data.allowDuplicateFile,
  });

  return await getOne(sdk, { id });
}

/*********************************************************************/
async function _delete(sdk: Sdk, params: types.DeleteParams) {
  const id = Number(params.id);
  await sdk.deleteDocument({ id });

  return { data: { id } };
}

/*********************************************************************/
export const document = types.dataProvider({
  getList,
  getOne,
  create,
  update,
  getManyReference,
  delete: _delete,
});
