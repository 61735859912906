import SearchIcon from "@mui/icons-material/Search";
import { Grid, IconButton, Popover, TextField } from "@mui/material";
import { useMemo, useState } from "react";
import { required } from "react-admin";
import { useInput } from "../react-admin-fixes";
import { IdInvestmentAutocomplete } from "../riskRefreshList/IdInvestmentAutocomplete";

const toPositiveInteger = (value: any) => {
  if ((value ?? "") === "") return;
  value = typeof value === "string" ? Number(value) : value;
  if (Number.isSafeInteger(value) && value > 0) return value as number;
};
const validatePositiveInteger = (value: any) => {
  if ((value ?? "") === "") return;
  if (typeof toPositiveInteger(value) !== "number") {
    return "Must be a whole number";
  }
};

/**
 * The input data type is bi-modal:
 *  - When the user has typed, the data type is `string` (working value), to
 *    provide a smooth input experience with validation.
 *  - When the input blurs, the data type becomes `number | null` (persist
 *    value). The input tries to convert the string value to a positive integer.
 *    If that succeeds, then the input value is the positive integer. Otherwise,
 *    the input value is the value from the prior blur state (the initial value
 *    or the previously entered valid value). This is the data type persisted in
 *    the form state, so should not require additional conversion to number in
 *    the data provider.
 */
type InputProps = Parameters<typeof useInput<string | number | null>>[0] & {
  textFieldProps?: Parameters<typeof TextField>[0] & { [data: string]: any };
};

export const IdInvestmentInput = ({ textFieldProps, ...props }: InputProps) => {
  const validate = useMemo(() => {
    const validate = !props.validate
      ? []
      : Array.isArray(props.validate)
      ? props.validate
      : [props.validate];
    return [
      ...(props.isRequired ? [required()] : []),
      validatePositiveInteger,
      ...validate,
    ];
  }, [props.validate, props.isRequired]);
  const input = useInput({
    ...props,
    validate,
  });
  const {
    field: { onChange, onBlur, value, ...field },
    fieldState: { isTouched, error: fieldError },
    formState: { isSubmitted },
    isRequired,
  } = input;

  // react admin passes wrong error shape
  const error = fieldError && {
    message: !fieldError.message
      ? void 0
      : typeof fieldError.message === "string"
      ? fieldError.message
      : (fieldError.message as any as { message: string }).message,
  };

  const [persistValue, setPersistValue] = useState(
    toPositiveInteger(value) ?? null
  );
  const [workingValue, setWorkingValue] = useState(String(persistValue ?? ""));
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const searchOpen = !!anchorEl;
  const searchId = searchOpen ? "id-investment-input-search" : void 0;

  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          whiteSpace: "normal",
        }}
      >
        <TextField
          {...textFieldProps}
          {...field}
          label={props.label}
          error={(isTouched || isSubmitted) && !!error}
          helperText={
            (console.log(
              "ERROR ->",
              "1",
              error?.message,
              "2",
              textFieldProps?.helperText
            ),
            (isTouched || isSubmitted) && error
              ? error.message
              : textFieldProps?.helperText ?? "")
          }
          required={isRequired}
          inputProps={{
            inputMode: "numeric",
            pattern: "[0-9]+",
          }}
          onChange={({ target }) => {
            setWorkingValue(target.value);
            onChange(target.value);
          }}
          onBlur={() => {
            const posint =
              workingValue === ""
                ? null
                : toPositiveInteger(workingValue) ?? persistValue;
            setPersistValue(posint);
            setWorkingValue(String(posint ?? ""));
            onChange(posint);
          }}
          value={workingValue}
          onFocus={e => e.target.select()}
        />
        <div>
          <div>
            <IconButton
              aria-label="search"
              aria-describedby={searchId}
              onClick={e => void setAnchorEl(e.currentTarget)}
            >
              <SearchIcon />
            </IconButton>
          </div>
          <div>&nbsp;</div>
        </div>
      </div>
      <Popover
        id={searchId}
        open={searchOpen}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <Grid container width={500} margin="16px">
          <Grid item xs={12}>
            <IdInvestmentAutocomplete
              onSelect={({ id }) => {
                setPersistValue(id);
                setWorkingValue(String(id));
                onChange(id);
                setAnchorEl(null);
              }}
              clearAfterSelect
            />
          </Grid>
        </Grid>
      </Popover>
    </>
  );
};
