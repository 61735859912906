import {
  ArrayField,
  AutocompleteInput,
  BooleanInput,
  ChipField,
  CreateButton,
  Datagrid,
  EditButton,
  ExportButton,
  Filter,
  List,
  ReferenceInput,
  SingleFieldList,
  SortButton,
  TextField,
  TextInput,
  TopToolbar,
} from "react-admin";

const USER_SX = {
  ".RaFilter-form": {
    flex: "0 1 50%",
  },
} as const;

const INPUT_WIDTH = { width: 250 } as const;

const SORT_BY_NAME = { field: "name", order: "ASC" } as const;

export const UserAndGroupsList = () => {
  return (
    <List
      resource="user"
      sx={USER_SX}
      filters={
        <Filter>
          <TextInput
            label="Name Search"
            source="q"
            alwaysOn
            resettable
            style={INPUT_WIDTH}
          />

          <ReferenceInput
            source="organizationId"
            reference="organization"
            sort={SORT_BY_NAME}
            alwaysOn
            allowEmpty
          >
            <AutocompleteInput
              label="Organization Name"
              optionText="name"
              style={INPUT_WIDTH}
            />
          </ReferenceInput>

          <ReferenceInput
            source="roleId"
            reference="applicationFlag"
            alwaysOn
            allowEmpty
            perPage={100}
          >
            <AutocompleteInput
              style={INPUT_WIDTH}
              label="Role Name"
              optionText="name"
            />
          </ReferenceInput>
          <BooleanInput source="isGroup" alwaysOn defaultValue={false} />
          <BooleanInput source="isUser" alwaysOn defaultValue={false} />
        </Filter>
      }
      sort={SORT_BY_NAME}
      actions={
        <TopToolbar>
          <SortButton fields={["name"]} />
          <CreateButton />
          <ExportButton maxResults={250} />
        </TopToolbar>
      }
    >
      <Datagrid rowClick="edit" bulkActionButtons={false}>
        <TextField label="Email" source="email" sortable={false} />
        <TextField label="Name" source="commonName" sortable={false} />
        <TextField
          label="Organization"
          source="organization.name"
          sortable={false}
        />
        <ArrayField source="groups" sortable={false}>
          <SingleFieldList>
            <ChipField source="commonName" size="small" />
          </SingleFieldList>
        </ArrayField>

        <ArrayField source="roles" sortable={false}>
          <SingleFieldList>
            <ChipField source="name" size="small" />
          </SingleFieldList>
        </ArrayField>
        <EditButton />
      </Datagrid>
    </List>
  );
};
