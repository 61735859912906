import * as client from "_graphql-types";

import Grid from "@mui/material/Grid";
import { TextInput } from "react-admin";
import { CustomCreate } from "../CustomCreate";
import { EntityInput } from "../UI/EntityInput";

type Record = NonNullable<
  client.GetOneEmailDomainXOrganizationQuery["emailDomainXOrganization"]
>;

export const EmailDomainXOrganizationCreate = () => (
  <CustomCreate<Record>
    title={() => "Add Email Domain Mapping To Organization"}
  >
    <>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <EntityInput<NonNullable<Record>>
            resource="organization"
            recordKey="organization"
            label="Organization"
            style={{ minWidth: "300px" }}
            allowChange
            isRequired={true}
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            label="Email Domain"
            source="emailDomain"
            isRequired={true}
          />
        </Grid>
      </Grid>
    </>
  </CustomCreate>
);
