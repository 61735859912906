import { Button } from "ra-ui-materialui";
import {
  Edit,
  Pagination,
  ReferenceManyField,
  useRecordContext,
} from "react-admin";
import { Link } from "react-router-dom";
import * as client from "_graphql-types";
import { DEAL_BUSINESS_OBJECT_ENUM } from "../../dataProvider/resources/document";
import CustomBreadCrumb from "../CustomBreadCumb";
import { NotesDataGrid } from "../notes/dataGrid";

type Record = NonNullable<client.GetOneDealQuery["deal"]>;

const Title = () => {
  const record = useRecordContext<Record>();
  return (
    <span>
      Deal Notes - {record ? `${record.name}` : ""}{" "}
      <Button
        label="Create Notes"
        component={Link}
        variant="contained"
        data-cy="createNote"
        to={{
          pathname: `/notes/create`,
          search: `?source=${JSON.stringify({
            businessObjectEnumId: DEAL_BUSINESS_OBJECT_ENUM,
            ...(record && {
              deal: {
                id: record.id,
                name: encodeURIComponent(record.name || ""),
              },
            }),
          })}`,
        }}
      />
    </span>
  );
};

export const DealNoteShow = () => {
  return (
    <Edit
      title={<Title />}
      actions={
        <CustomBreadCrumb<Record>
          name={"dealNotes"}
          items={[
            {
              path: "firm",
              getId: record => record?.firm?.id,
              getName: record => record?.firm?.name,
            },
            {
              path: "company",
              getId: record => record?.company?.id,
              getName: record => record?.company?.name,
            },
            {
              path: "deal",
              getId: record => record?.id,
              getName: record => record?.name,
            },
          ]}
        />
      }
    >
      <ReferenceManyField
        label="Deal Notes"
        reference="notes"
        target="dealId"
        perPage={25}
        pagination={<Pagination />}
        sort={{ field: "id", order: "DESC" }}
      >
        <NotesDataGrid />
      </ReferenceManyField>
    </Edit>
  );
};
