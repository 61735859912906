import { Grid } from "@mui/material";
import * as client from "_graphql-types";
import { Button } from "ra-ui-materialui";
import { Pagination, ReferenceManyField, useRecordContext } from "react-admin";
import { Link } from "react-router-dom";
import { CustomEdit } from "../CustomEdit";
import { InvestmentCustomAnalyticsDataGrid } from "./DataGrid";

type Record = NonNullable<client.GetOneInvestmentQuery["investment"]>;

const NewRecordButton = () => {
  const record = useRecordContext<Record>();
  return (
    <Button
      label="Create"
      component={Link}
      variant="contained"
      data-cy="add-new_customanalytics"
      to={{
        pathname: `/customAnalytics/create`,
        search: `?source=${JSON.stringify({
          ...(record && {
            investment: {
              id: record.id,
              name: encodeURIComponent(record.name),
            },
          }),
        })}`,
      }}
    />
  );
};

function CustomAnalyticsForm() {
  const record = useRecordContext<Record>();

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} />
        <Grid item xs={11} />
        <Grid item xs={1}>
          <NewRecordButton />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <ReferenceManyField
            label="Custom Analytics"
            reference="customAnalytics"
            target="ownerId"
            perPage={10}
            pagination={<Pagination />}
            sort={{ field: "asOfDate", order: "DESC" }}
            filter={{ ownerType: "investment", ownerId: record?.id }}
          >
            <InvestmentCustomAnalyticsDataGrid />
          </ReferenceManyField>
        </Grid>
      </Grid>
    </>
  );
}

export const InvestmentCustomAnalytics = () => {
  return (
    <CustomEdit<Record>
      title={record => record && `Custom Analytics - ${record.name}`}
    >
      <CustomAnalyticsForm />
    </CustomEdit>
  );
};
