import * as client from "_graphql-types";
import * as types from "./types";

type Sdk = ReturnType<typeof client.getSdk>;

/*********************************************************************/
// Data Provider Functions
/*********************************************************************/

/*********************************************************************/
async function getList(
  sdk: Sdk,
  params: types.GetListParams<client.InvestmentAxysCodeSortEnum>
) {
  const {
    pagination: { page, perPage },
    sort: { field, order },
    filter,
  } = params;

  const {
    investmentAxysCodeList: { items: data, total },
  } = await sdk.getListInvestmentAxysCode({
    page: {
      offset: (page - 1) * perPage,
      limit: perPage,
    },
    sort: [{ field, order }],
    filter,
  });

  return {
    data,
    total,
  };
}

/*********************************************************************/
async function getOne(sdk: Sdk, params: types.GetOneParams) {
  const id = Number(params.id);
  const { investmentAxysCode: data } = await sdk.getOneInvestmentAxysCode({
    id,
  });
  return {
    data,
  };
}

/*********************************************************************/
async function getMany(sdk: Sdk, params: types.GetManyParams) {
  const ids = params.ids.map(id => Number(id));

  const { investmentAxysCodeMany: data } = await sdk.getManyInvestmentAxysCode({
    ids,
  });

  return {
    data,
  };
}

/*********************************************************************/
async function getManyReference(
  sdk: Sdk,
  params: types.GetManyReferenceParams<client.InvestmentAxysCodeSortEnum>
) {
  const id = Number(params.id);
  const {
    pagination: { page, perPage },
    sort: { field, order },
    filter,
    target,
  } = params;

  const {
    investmentAxysCodeList: { items: data, total },
  } = await sdk.getListInvestmentAxysCode({
    page: {
      offset: (page - 1) * perPage,
      limit: perPage,
    },
    sort: [{ field, order }],
    filter: { ...filter, [target]: id },
  });

  return {
    data,
    total,
  };
}

/*********************************************************************/
async function create(
  sdk: Sdk,
  params: types.CreateParams<
    NonNullable<client.GetOneInvestmentAxysCodeQuery["investmentAxysCode"]> & {
      investment: { id: number };
    }
  >
) {
  const { data } = params;
  const input: client.CreateInvestmentAxysCodeMutationVariables["input"] = {
    code: types.required(data, "axysCode").code,
    investmentId: types.required(data, "investment").id,
  };

  console.log("AXYS CREATE DATA ->", data);

  const {
    addInvestmentAxysCode: { id },
  } = await sdk.createInvestmentAxysCode({
    input,
  });
  return await getOne(sdk, { id: id! });
}
/*********************************************************************/
async function del(sdk: Sdk, params: types.DeleteParams): types.DeleteOut<any> {
  const id = Number(params.id);
  await sdk.deleteInvestmentAxysCode({ id });
  return { data: { id } };
}

/*********************************************************************/
export const investmentAxysCode = types.dataProvider({
  getList,
  getOne,
  getMany,
  getManyReference,
  create,
  delete: del,
});
