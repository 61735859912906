import "./util/init";
import * as Sentry from "@sentry/react";
import "frontend/src/legacyStyles/application.scss";
import "./index.css";

import { StrictMode } from "react";
import App from "./App";
import config from "./config";
import { createRoot } from "react-dom/client";

Sentry.init({
  dsn: config.SENTRY_DSN(),
  tunnel: config.SENTRY_TUNNEL(),
  environment: config.SENTRY_ENVIRONMENT(),
  release: config.SENTRY_RELEASE(),
  integrations: [
    ...(!["dev", "staging", "production"].includes(config.SENTRY_ENVIRONMENT())
      ? [
          Sentry.breadcrumbsIntegration({
            console: false,
          }),
        ]
      : []),
  ],
  beforeSend(event) {
    if (
      event.environment &&
      !["dev", "staging", "production"].includes(event.environment)
    ) {
      console.log("Environment: ", event.environment);
      console.log("Stubbed Sentry Event: ", event);
      return null;
    }
    return event;
  },
});

// DISABLE SCROLLING NUMBER INPUTS RCS-4638
document.addEventListener("wheel", function (event) {
  if ((document as any).activeElement.type === "number") {
    (document as any).activeElement.blur();
  }
});

// DISABLE UP ARROW DOWN ARROW NUMBER INPUTS RCS-4638
document.addEventListener("keydown", function (e) {
  if ((document as any).activeElement.type === "number") {
    if (e.key === "ArrowDown" || e.key === "ArrowUp") {
      e.preventDefault();
    }
  }
});

const container = document.getElementById("root");
if (!container) throw Error("root not found");
const root = createRoot(container);

root.render(
  <StrictMode>
    <App />
  </StrictMode>
);
