import { AutocompleteInput, Filter, ReferenceInput } from "react-admin";

export const InvestmentInstitutionsFilter = () => (
  <Filter>
    <ReferenceInput
      source="investmentId"
      alwaysOn
      reference="investment"
      sort={{ field: "nameSearchRank", order: "ASC" }}
    >
      <AutocompleteInput optionText="name" label="Investment" fullWidth />
    </ReferenceInput>
    <ReferenceInput
      source="institutionId"
      reference="officeLocation"
      alwaysOn
      sort={{ field: "nameSearchRank", order: "ASC" }}
    >
      <AutocompleteInput
        optionText="institutionNameWithLocation"
        label="Office Location"
        fullWidth
      />
    </ReferenceInput>
    <ReferenceInput
      source="institutionTypeEnumId"
      reference="institutionTypeEnum"
      pagination={{ perPage: 50 }}
      alwaysOn
      sort={{ field: "nameSearchRank", order: "ASC" }}
    >
      <AutocompleteInput optionText="name" label="Service Type" fullWidth />
    </ReferenceInput>
  </Filter>
);
