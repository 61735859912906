import { BasicInfo } from "./BusinessApplication/BasicInfo";
import { BusinessApplicationRedirect } from "./BusinessApplication/Redirect";
import { CompanyCreate as create } from "./Create";
import { CompanyEdit as edit } from "./Edit";
import { CompanyList as list } from "./List";

export default {
  edit,
  list,
  create,
  BusinessApplicationEdit: BasicInfo,
  BusinessApplicationRedirect,
};
