import * as React from "react";
import { useCustomRouteContext } from "./CustomNavigation";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { Search, SearchResultsPanel } from "@react-admin/ra-search";
import { usePermissions } from "react-admin";
import { Permissions } from "./CustomAdmin";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
const MySearchResultItem = ({ data, onClose }: any) => {
  const { currentRoute, entityContext } = useCustomRouteContext();

  const to =
    entityContext === data.type
      ? `/${currentRoute}/${data.content.id}`
      : `/${data.type}/${data.content.id}`;

  return (
    <List key={data.id}>
      <ListItemButton component={Link} to={to}>
        <ListItemText
          primary={`[${data.content.id}] - ${data.content.label}`}
        />
      </ListItemButton>
    </List>
  );
};

export const CustomSearchBar = () => {
  return (
    <Search sx={{ maxWidth: "230px" }}>
      <SearchResultsPanel>
        <MySearchResultItem />
      </SearchResultsPanel>
    </Search>
  );
};
