import { EditInDialogButton } from "@react-admin/ra-form-layout";
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import { DialogForm, DialogTitle } from "./ManageWebsiteRelationships";
import {
  useUpdate,
  useRecordContext,
  useNotify,
  useRefresh,
} from "react-admin";
import { IconButton, Tooltip } from "@mui/material";
import LinkOffIcon from "@mui/icons-material/LinkOff";

export const ManageRelationshipsButton = () => (
  <EditInDialogButton
    inline
    fullWidth
    label="Manage"
    icon={<SettingsSuggestIcon />}
    mutationMode="pessimistic"
    title={<DialogTitle />}
    maxWidth="lg"
    redirect={false}
  >
    <DialogForm />
  </EditInDialogButton>
);

/**
 * For use on the Firm or Investment edit page to unlink an FDP Login from that entity
 * Will delete the FirmXFdpLogin or InvestmentXFdpLogin record
 * @param sourceKey firms or investments
 */
export const UnlinkRelationshipButton = ({
  sourceKey,
  entityId,
}: {
  sourceKey: string;
  entityId: number;
}) => {
  const record = useRecordContext();
  if (!record) return;

  const refresh = useRefresh();
  const [update, { isLoading, error }] = useUpdate();
  const notify = useNotify();
  const diff = record[sourceKey].filter(
    ({ id }: { id: number }) => id !== entityId
  );
  const handleClick = () => {
    update(
      "fdpLogin",
      { id: record.id, data: { [sourceKey]: diff } },
      {
        onSuccess: () => {
          notify(
            `${sourceKey.slice(0, -1)}Id ${entityId} unlinked from FDP Login`
          );
          refresh();
        },
      }
    );
  };

  if (error) {
    notify(`Error unlinking ${sourceKey.slice(0, -1)} from FDP Login`);
  }

  return (
    <Tooltip title="Unlink">
      <IconButton
        onClick={handleClick}
        disabled={isLoading}
        size="small"
        color="secondary"
        data-cy={`unlink-${sourceKey}-button`}
      >
        <LinkOffIcon />
      </IconButton>
    </Tooltip>
  );
};
