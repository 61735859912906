import * as client from "_graphql-types";
import { Button, Link, required, useRecordContext } from "react-admin";
import { CustomCreate } from "../CustomCreate";
import Grid from "@mui/material/Grid";
import React from "react";

import { useCanAccessMutation } from "../../util/useCanAccessMutation";
import { EntityInput } from "../UI/EntityInput";
import { FDPLoginInput } from "./FDPLoginInput";
import { useSearchParams } from "react-router-dom";
import { EntityInputMany } from "../UI/EntityInputMany";
import { ReferenceManyInput } from "@react-admin/ra-relationships";

type Record = NonNullable<client.GetOneFirmFdpLoginsQuery["firmFDPLogins"]>;

const ViewFirmButton = () => {
  const record = useRecordContext<Record>();

  if (record && record.firm) {
    if (record.firm.isCompany) {
      return (
        <Button
          component={Link}
          to={{
            pathname: `/company/${record.firm.companyId}`,
          }}
          label="Go to Company"
        />
      );
    }
    return (
      <Button
        component={Link}
        to={{
          pathname: `/firm/${record.firm.id}`,
        }}
        label="Go to Firm"
      />
    );
  }
  return null;
};

const ViewFDPLoginButton = () => {
  const record = useRecordContext<Record>();
  return !record?.fdpLogin?.id ? null : (
    <Button
      component={Link}
      to={{
        pathname: `/fdpLogin/${record.fdpLogin.id}`,
      }}
      label="Go to FDPLogin"
    />
  );
};

function getFirmPath(search: URLSearchParams) {
  return `/firm/${getFirmId(search)}`;
}

function getFirmId(search: URLSearchParams) {
  const source = search.get("source");
  const { firm } = JSON.parse(source ?? "{}");
  return firm?.id;
}

export const FirmFdpLoginsCreate = () => {
  const mutationArgs = JSON.stringify({});
  const [search] = useSearchParams();

  const firmId = getFirmId(search);

  const { canEdit, canEditField, loading } = useCanAccessMutation(
    "addFirmFDPLogins",
    mutationArgs
  );

  return (
    <CustomCreate
      title={() => "Link FDPLogin to Firm"}
      customFormProps={{
        customToolbarProps: {
          canAccessMutation: canEdit,
          redirectToPathOnSave: getFirmPath(search),
        },
        loading,
        canEditField,
      }}
    >
      <>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <ViewFirmButton />
          </Grid>
          <Grid item xs={6}>
            <ViewFDPLoginButton />
          </Grid>
          <Grid item xs={6}>
            <EntityInput<NonNullable<Record>>
              resource="firm"
              recordKey="firm"
              label="Firm"
              isRequired={true}
              allowChange={false}
              sort={{ field: "nameSearchRank", order: "ASC" }}
            />
          </Grid>

          <Grid item xs={6}>
            <FDPLoginInput isRequired={true} />
          </Grid>
        </Grid>
      </>
    </CustomCreate>
  );
};
