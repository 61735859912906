import { Button, Grid, Modal, TextField } from "@mui/material";
import { addQuarters, getQuarter, getYear, parse } from "date-fns";
import { useEffect, useMemo, useState } from "react";
import { useCreate, useNotify, useRecordContext } from "react-admin";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Record } from "./Edit";
import { styled } from "@mui/material/styles";

const DivPaper = styled("div")(({ theme }) => ({
  position: "absolute",
  width: 400,
  backgroundColor: theme.palette.background.paper,
  border: "2px solid #000",
  boxShadow: theme.shadows[5],
  padding: theme.spacing(2, 4, 3),
  top: "50%",
  left: "50%",
  transform: `translate(-50%,-50%)`,
  outline: "none",
}));

export const getDefaultQuarter = (
  performancePrivate: { returnYear: number; returnQuarter: number }[]
) => {
  const maxPerformance = performancePrivate.reduce(
    (currentMax, nextPerformance) => {
      const comparablePerformance = ({
        returnYear,
        returnQuarter,
      }: {
        returnYear: number;
        returnQuarter: number;
      }) => {
        return Number(`${returnYear}${returnQuarter}`);
      };

      if (
        comparablePerformance(nextPerformance) >
        comparablePerformance(currentMax)
      ) {
        return {
          returnYear: nextPerformance.returnYear,
          returnQuarter: nextPerformance.returnQuarter,
        };
      }
      return currentMax;
    },
    {
      returnYear: performancePrivate[0] && performancePrivate[0].returnYear,
      returnQuarter:
        performancePrivate[0] && performancePrivate[0].returnQuarter,
    }
  );
  if (maxPerformance.returnYear && maxPerformance.returnQuarter) {
    const maxPerformanceDate = parse(
      `${maxPerformance.returnYear}-${maxPerformance.returnQuarter}`,
      "yyyy-Q",
      new Date()
    );
    const nextQuarter = addQuarters(maxPerformanceDate, 1);
    return {
      returnYear: getYear(nextQuarter),
      returnQuarter: getQuarter(nextQuarter),
    };
  }
  return {
    returnYear: getYear(new Date()),
    returnQuarter: getQuarter(new Date()),
  };
};

export const NewQuarter = (props: any) => {
  const record = useRecordContext<Record>();
  if (!record) return;

  const { open, close } = props;
  const { performancePrivate, id } = record;
  const notify = useNotify();
  const [netIRR, changeNetIRR] = useState<null | number>(null);
  const [tvpi, changeTvpi] = useState<null | number>(null);
  const [dpi, changeDpi] = useState<null | number>(null);

  const defaultQuarter = useMemo(
    () => getDefaultQuarter(performancePrivate ?? []),
    [performancePrivate]
  );

  const [returnQuarter, changeReturnQuarter] = useState<{
    returnYear: number;
    returnQuarter: number;
  }>(defaultQuarter);

  //refresh form
  useEffect(() => {
    const newYear = returnQuarter.returnYear;
    const newQuarter = returnQuarter.returnQuarter;
    const existingReturn = (performancePrivate ?? []).find(
      ({
        returnQuarter,
        returnYear,
      }: {
        returnQuarter: number;
        returnYear: number;
      }) => {
        return returnYear === newYear && returnQuarter === newQuarter;
      }
    );
    if (existingReturn) {
      changeNetIRR(existingReturn?.netIRR ?? null);
      changeTvpi(existingReturn?.tvpi ?? null);
      changeDpi(existingReturn?.dpi ?? null);
    } else {
      changeNetIRR(null);
      changeTvpi(null);
      changeDpi(null);
    }
  }, [returnQuarter, performancePrivate]);

  console.log("stuff", id, netIRR, tvpi, dpi);
  const [create] = useCreate();

  return (
    <Modal
      open={open}
      onClose={close}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <DivPaper>
        <h2 id="simple-modal-title">Add Quarter</h2>
        <Grid container>
          <Grid container item xs={12}>
            <DatePicker
              selected={parse(
                `${returnQuarter.returnYear}-${returnQuarter.returnQuarter}`,
                "yyyy-Q",
                new Date()
              )}
              onChange={(date: Date | null) => {
                if (date) {
                  const newQuarter = getQuarter(date);
                  const newYear = getYear(date);
                  changeReturnQuarter({
                    returnQuarter: newQuarter,
                    returnYear: newYear,
                  });
                }
              }}
              dateFormat="yyyy, QQQ"
              showQuarterYearPicker
            />
          </Grid>
          <Grid container item xs={12}>
            <Grid item xs={6}>
              <TextField
                variant="standard"
                type="number"
                label="NetIRR"
                onChange={e => changeNetIRR(Number(e.target.value))}
                data-id="new-quarter-netIRR"
                value={netIRR}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                variant="standard"
                type="number"
                label="TVPI"
                onChange={e => changeTvpi(Number(e.target.value))}
                data-id="new-quarter-tvpi"
                value={tvpi}
              />
            </Grid>
          </Grid>
          <Grid container item xs={12} spacing={3}>
            <Grid item xs={12}>
              <TextField
                variant="standard"
                type="number"
                label="DPI"
                onChange={e => changeDpi(Number(e.target.value))}
                data-id="new-quarter-dpi"
                value={dpi}
              />
            </Grid>
          </Grid>
        </Grid>
        <Button
          onClick={() => {
            create(
              "performancePrivate",
              {
                data: {
                  id,
                  netIRR,
                  tvpi,
                  dpi,
                  returnQuarter: returnQuarter.returnQuarter,
                  returnYear: returnQuarter.returnYear,
                },
              },
              {
                onSuccess: () => {
                  notify("Saved", { type: "success" });
                },
              }
            );
            close();
          }}
          variant="contained"
          color="primary"
          data-id="new-quarter-save"
        >
          Save
        </Button>
      </DivPaper>
    </Modal>
  );
};
