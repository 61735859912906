import { useRecordContext } from "react-admin";
import * as client from "_graphql-types";
import { Grid } from "@mui/material";
import NumberInputWithSymbol from "../NumberWithInputAdornment";

type IRecord = NonNullable<client.GetOneInvestmentTermsQuery["investment"]>;

export function PrivateFields({
  canEditField,
}: {
  canEditField: (field: string) => boolean;
}) {
  const record = useRecordContext<IRecord>();
  if (!record) return;

  if (record.marketEnumId === 1) return null;

  return (
    <>
      <Grid item xs={3}>
        <NumberInputWithSymbol
          source="investmentPeriodYrs"
          label="Investment Period"
          symbol="Years"
          symbolAlignment="end"
          disabled={!canEditField("investmentPeriodYrs")}
        />
      </Grid>
      <Grid item xs={3}>
        <NumberInputWithSymbol
          source="harvestPeriodYrs"
          label="Harvest Period"
          symbol="Years"
          symbolAlignment="end"
          disabled={!canEditField("harvestPeriodYrs")}
        />
      </Grid>
      <Grid item xs={3}>
        <NumberInputWithSymbol
          source="extensionPeriodYrs"
          label="Extension Period"
          symbol="Years"
          symbolAlignment="end"
          disabled={!canEditField("extensionPeriodYrs")}
        />
      </Grid>
      <Grid item xs={3}>
        <NumberInputWithSymbol
          source="peLegalLifeYrs"
          label="Legal Life of Fund"
          symbol="Years"
          symbolAlignment="end"
          disabled={!canEditField("peLegalLifeYrs")}
        />
      </Grid>
    </>
  );
}
