import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { GetInvestmentRiskInvestmentListQuery } from "_graphql-types";
import { useState } from "react";
import { DataProvider, useDataProvider } from "react-admin";

import { useDebouncedCallback } from "use-debounce";

export type IdName =
  GetInvestmentRiskInvestmentListQuery["investmentRiskInvestmentList"]["items"][number];

/*
 * If we like this component in general, we can move it from the investmentRisk
 * namespace up to Query. For internal RockCreek use only due to ID exposure.
 */
const setInvestmentOptions =
  (dataProvider: DataProvider<string>, handler: (options: IdName[]) => void) =>
  (q: string) => {
    dataProvider
      .getList<IdName>("investmentRiskInvestment", {
        filter: { q },
        sort: {
          field: "nameSearchRank",
          order: "ASC",
        },
        pagination: {
          page: 1,
          perPage: 20,
        },
      })
      .then(({ data }) => void handler(data));
  };

interface IdInvestmentInputProps {
  onSelect: (item: IdName) => void;
  clearAfterSelect?: boolean;
}

const NBSP = "\xa0";

export function IdInvestmentAutocomplete({
  onSelect,
  clearAfterSelect,
}: IdInvestmentInputProps) {
  const dataProvider = useDataProvider();

  const [investmentOptions, setCurrentInvestmentOptions] = useState<IdName[]>(
    []
  );

  const setSourceInvestmentOptionsDebounced = useDebouncedCallback(
    setInvestmentOptions(dataProvider, setCurrentInvestmentOptions),
    300
  );
  const [currentInvestmentPick, setCurrentInvestmentPick] =
    useState<IdName | null>(null);

  return (
    <Autocomplete
      filterOptions={options => options}
      options={investmentOptions}
      blurOnSelect
      renderInput={params => (
        <TextField variant="standard" {...params} label="Source investment" />
      )}
      onInputChange={(event, value, reason) => {
        switch (reason) {
          case "input":
            return setSourceInvestmentOptionsDebounced(value);
          default:
            return setCurrentInvestmentOptions([]);
        }
      }}
      onChange={(event, investment, reason) => {
        switch (reason) {
          case "selectOption":
            if (investment) onSelect(investment);
            break;
          case "clear":
            break;
        }
        if (clearAfterSelect) setCurrentInvestmentPick(null);
      }}
      value={currentInvestmentPick}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={option => `${option.id}.${NBSP.repeat(3)}${option.name}`}
      data-cy="risk-investment-select"
    />
  );
}
