import {
  CreateButton,
  Datagrid,
  EditButton,
  ExportButton,
  List,
  TextField,
  TopToolbar,
} from "react-admin";
import { InstitutionEmploymentFilter } from "./Filter";

export const InstitutionEmploymentDatagrid = (props: any) => {
  return (
    <Datagrid rowClick="edit" bulkActionButtons={false}>
      <TextField label="Person" source="person.fullName" sortable={false} />
      <TextField
        label="Institution"
        source="institution.name"
        sortable={false}
      />
      <TextField label="Start Date" source="startDate" sortable={false} />
      <TextField label="End Date" source="endDate" sortable={false} />
      <EditButton />
    </Datagrid>
  );
};

export const InstitutionEmploymentList = () => {
  return (
    <List
      sx={{
        ".RaFilter-form": {
          flex: "0 1 50%",
        },
      }}
      filters={<InstitutionEmploymentFilter />}
      sort={{ field: "name", order: "ASC" }}
      actions={
        <TopToolbar>
          <CreateButton />
          <ExportButton maxResults={250}/>
        </TopToolbar>
      }
    >
      <InstitutionEmploymentDatagrid />
    </List>
  );
};
