import { Link, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import * as client from "_graphql-types";
import {
  ArrayField,
  Datagrid,
  FunctionField,
  Pagination,
  ReferenceManyField,
  TextField,
  useRecordContext,
  Labeled,
  WrapperField,
  SingleFieldList,
  ReferenceField,
} from "react-admin";
import { Authorized } from "../Authorized";
import { FCField_RenderTextWithConvenienceCopy } from "../UI/CopyField";
import { PasswordField } from "../UI/PasswordField";
import { Stack } from "@mui/system";

function FixedUrlField<T = any>({
  getUrl,
}: {
  getUrl: (t: T) => string | undefined;
}) {
  return (
    <FunctionField
      render={(record: any) => {
        let url = getUrl(record)?.trim();
        if (!url) return null;
        if (!url.startsWith("http://") && !url.startsWith("https://")) {
          url = "https://" + url;
        }
        return (
          <Link href={url} target="_blank" rel="noopener" underline="hover">
            {url}
          </Link>
        );
      }}
    />
  );
}

type Record = NonNullable<
  client.GetListFirmEmploymentQuery["firmEmploymentList"]["items"][number]
>;

function getFullName(
  person: Record["person"] | undefined,
  fallback: string = "-"
) {
  if (!person) return fallback;
  return [person.firstName, person.lastName].filter(Boolean).join(" ");
}

export default function PerformanceContact() {
  const record = useRecordContext<Record>();

  return (
    <>
      <Grid container spacing={2} marginBottom={5}>
        <Grid item sm={6} xs={12}>
          <Table style={{ tableLayout: "fixed" }}>
            <colgroup>
              <col width="100em" />
              <col width="*" />
            </colgroup>
            <TableHead>
              <TableRow>
                <TableCell colSpan={2} style={{ fontWeight: "bold" }}>
                  Contact
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell
                  style={{
                    whiteSpace: "normal",
                    overflowWrap: "break-word",
                  }}
                >
                  <FunctionField
                    render={(record: any) =>
                      getFullName(
                        record?.firm?.firmEmployments[0]
                          ?.person as Record["person"]
                      )
                    }
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Phone</TableCell>
                <TableCell
                  style={{
                    whiteSpace: "normal",
                    overflowWrap: "break-word",
                  }}
                >
                  <TextField source="firm.firmEmployments[0].person.phone" />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Email</TableCell>
                <TableCell
                  style={{
                    whiteSpace: "normal",
                    overflowWrap: "break-word",
                  }}
                >
                  <TextField source="firm.firmEmployments[0].person.email" />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Grid>
        <Grid item sm={6} xs={12}>
          <Table style={{ tableLayout: "fixed" }}>
            <colgroup>
              <col width="100em" />
              <col width="*" />
            </colgroup>
            <TableHead>
              <TableRow>
                <TableCell colSpan={2} style={{ fontWeight: "bold" }}>
                  Website
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>Link</TableCell>
                <TableCell
                  style={{
                    whiteSpace: "normal",
                    overflowWrap: "break-word",
                  }}
                >
                  <FixedUrlField
                    getUrl={record => record?.firm?.primaryAddress.website}
                  />
                </TableCell>
              </TableRow>
              <Authorized checks={["isRockCreek"]}>
                <TableRow>
                  <TableCell>Username</TableCell>
                  <TableCell
                    style={{
                      whiteSpace: "normal",
                      overflowWrap: "break-word",
                    }}
                  >
                    <TextField source="firm.primaryAddress.webUserId" />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Password</TableCell>
                  <TableCell
                    style={{
                      whiteSpace: "normal",
                      overflowWrap: "break-word",
                    }}
                  >
                    <TextField source="firm.primaryAddress.webPassword" />
                  </TableCell>
                </TableRow>
              </Authorized>
            </TableBody>
          </Table>
        </Grid>
      </Grid>
      <Stack direction="column" marginBottom={5} width={"100%"}>
        <Typography
          variant="h6"
          gutterBottom
          sx={{ fontSize: "0.875rem", fontWeight: "bold", marginLeft: "15px" }}
        >
          FDP Logins
        </Typography>
        <Authorized checks={["isRockCreek"]}>
          <ArrayField source="fdpLogins">
            <Datagrid bulkActionButtons={false}>
              <WrapperField source="name" label="Name">
                <Labeled label="Name">
                  <TextField source="name" />
                </Labeled>
              </WrapperField>
              <WrapperField
                source="username_password"
                label="Username / Password"
                sortBy="username"
              >
                <Stack direction="column" spacing={2}>
                  <Labeled label="Username">
                    <FunctionField
                      sortBy="username"
                      source="username"
                      render={(record: any) => (
                        <FCField_RenderTextWithConvenienceCopy
                          record={record as Record}
                          source="username"
                        />
                      )}
                    />
                  </Labeled>
                  <Labeled label="Password">
                    <FunctionField
                      sortable={false}
                      source="password"
                      render={(record: any) => (
                        <PasswordField
                          record={record as Record}
                          source="password"
                          allowCopy={true}
                        />
                      )}
                    />
                  </Labeled>
                </Stack>
              </WrapperField>
              <WrapperField
                source="email_mfaUser"
                label="Email / 2FA User"
                sortBy="email"
              >
                <Stack direction="column" spacing={2}>
                  <Labeled label="Email">
                    <TextField source="email" />
                  </Labeled>
                  <Labeled label="2FA User">
                    <TextField source="mfaUser" />
                  </Labeled>
                </Stack>
              </WrapperField>
              <WrapperField
                source="fdpName_fdpWebsite"
                label="Financial Data Provider"
                sortable={false}
              >
                <Stack direction="column" spacing={2}>
                  <Labeled label="Name">
                    <ReferenceField
                      source="financialDataProviderId"
                      reference="financialDataProvider"
                    >
                      <TextField source="name" />
                    </ReferenceField>
                  </Labeled>
                  <Labeled label="Websites">
                    <ReferenceManyField
                      reference="fdpWebsites"
                      target="financialDataProviderId"
                      source="financialDataProvider.id"
                    >
                      <SingleFieldList linkType={false}>
                        <FunctionField
                          source="website"
                          render={(record: any) => (
                            <FCField_RenderTextWithConvenienceCopy
                              record={record as Record}
                              source="website"
                              maxChars={20}
                              isLink={true}
                            />
                          )}
                        />
                      </SingleFieldList>
                    </ReferenceManyField>
                  </Labeled>
                </Stack>
              </WrapperField>
              <WrapperField
                source="securityQuestion_notes"
                label="Security Question / Notes"
                sortable={false}
              >
                <Stack direction="column" spacing={2}>
                  <Labeled label="Security Question">
                    <TextField source="securityQuestion" sortable={false} />
                  </Labeled>
                  <Labeled label="Notes">
                    <TextField source="notes" sortable={false} />
                  </Labeled>
                </Stack>
              </WrapperField>
            </Datagrid>
          </ArrayField>
        </Authorized>
      </Stack>
    </>
  );
}
