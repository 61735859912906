import { InputProps, useInput } from "react-admin";
import React, { Dispatch, SetStateAction, useState } from "react";
import { Grid, Button, Typography } from "@mui/material";
import { parseSpreadsheet } from "./Chart/helpers";

function SpreadSheetUpload(
  props: InputProps & {
    setRows: Dispatch<SetStateAction<(string | number | null)[][]>>;
    setHeaders: Dispatch<SetStateAction<string[]>>;
  }
) {
  const [filename, setFilename] = useState<string>("");

  const {
    field: { onChange },
  } = useInput(props);
  const { setHeaders, setRows } = props;

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Button color="primary" variant="contained">
          <label>
            Import From SpreadSheet
            <input
              data-id="customAnalyticsUpload"
              type="file"
              accept=".csv,.xlsx"
              style={{ display: "none" }}
              onChange={async (e: any) => {
                const files = e.target.files;
                if (files && files[0]) {
                  parseSpreadsheet(files[0])
                    .then(parsedRecords => {
                      console.log("PARSED RECORDS: ", parsedRecords);
                      setHeaders(parsedRecords.headers);
                      setRows(parsedRecords.rows);
                      onChange(files[0]);
                      setFilename(files[0].name);
                    })
                    .catch(e => {
                      console.log(
                        `error parsing your spreadsheet : ${e}`,
                        "error"
                      );
                    });
                }
              }}
            />
          </label>
        </Button>
        <Typography variant="caption" style={{ paddingLeft: 10 }}>
          {filename}
        </Typography>
      </Grid>
    </Grid>
  );
}

export default SpreadSheetUpload;
