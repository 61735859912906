import DefaultIcon from "@mui/icons-material/ViewList";
import { capitalize } from "lodash";
import { ReactNode } from "react";
import {
  useResourceDefinitions,
  usePermissions,
  useSidebarState,
} from "react-admin";
import { Permissions } from "./CustomAdmin";
import SsidChartIcon from "@mui/icons-material/SsidChart";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import ArrowBackIcon from "@mui/icons-material/KeyboardArrowLeft";
import ArrowForwardIcon from "@mui/icons-material/KeyboardArrowRight";
import Check from "@mui/icons-material/Check";

import { MultiLevelMenu } from "@react-admin/ra-navigation";
import React from "react";
import { Tooltip } from "@mui/material";

interface Props {
  name: string;
  children: ReactNode;
  icon?: ReactNode;
}

/* A hack to configure group icons, since react admin takes `menu` as
 * a functional component definition, not an instance we can pass props to. 🤷
 */
function getGroupIcon(key: string, isOpen: boolean) {
  let icon;
  switch (key) {
    case "Bulk Performance":
      icon = <SsidChartIcon />;
  }

  if (!isOpen)
    return (
      <span>
        {icon || <AbbreviationIcon label={key} />}
        <ArrowForwardIcon titleAccess={key} />
      </span>
    );

  return icon;
}

const AbbreviationIcon = ({ label }: { label: string }) => {
  let iconLabel = label.substring(0, 2).toUpperCase();

  if (label.split(" ").length > 1)
    iconLabel = label
      .split(" ")
      .map(word => word[0])
      .join("")
      .toUpperCase();

  return <>{iconLabel}</>;
};

function renderMenuItemsFromResources(
  resources: any[],
  key: string | number,
  isOpen: boolean
) {
  return resources.map(
    (resource, iresource) =>
      !!resource.hasList && (
        <MultiLevelMenu.Item
          name={String([key, iresource])}
          key={String([key, iresource])}
          to={`/${resource.name}`}
          label={resource.options?.label ?? capitalize(resource.name)}
          icon={
            resource.icon ? (
              <resource.icon />
            ) : isOpen ? undefined : (
              <AbbreviationIcon
                label={resource.options?.label ?? capitalize(resource.name)}
              />
            )
          }
        />
      )
  );
}

export const CustomMenu = () => {
  const resourcesDefinitions = useResourceDefinitions();
  const [isOpen] = useSidebarState();
  const { permissions } = usePermissions<Permissions>();

  const [selectedGroup, setSelectedGroup] = React.useState<string | null>(null);

  const resources = Object.keys(resourcesDefinitions)
    .map(name => resourcesDefinitions[name])
    .sort((a, b) => {
      const aIndex = a.options?.displayOrder;
      const bIndex = b.options?.displayOrder;
      return !aIndex ? -1 : !bIndex ? 1 : aIndex - bIndex;
    });

  React.useEffect(() => {
    if (isOpen) setSelectedGroup(null);
  }, [isOpen]);

  const resourceGroups = new Map<string | number, any[]>();
  resources.forEach((resource, i) => {
    const key =
      (resource.options?.group as string | undefined) ??
      /*(resource.options?.displayOrder as number | undefined) ??*/ i;
    resourceGroups.get(key)?.push(resource) ??
      resourceGroups.set(key, [resource]);
  });

  if (selectedGroup && resourceGroups.get(selectedGroup)) {
    const children = renderMenuItemsFromResources(
      resourceGroups.get(selectedGroup) as any[],
      selectedGroup,
      isOpen
    );
    return (
      <MultiLevelMenu>
        <MultiLevelMenu.Item
          name={"back"}
          label={"Back"}
          to="#"
          icon={<ArrowBackIcon />}
          onClick={() => setSelectedGroup(null)}
        />
        {children}
      </MultiLevelMenu>
    );
  }

  return (
    <MultiLevelMenu>
      {[...resourceGroups.entries()].map(([key, resources]) => {
        const children = renderMenuItemsFromResources(resources, key, isOpen);
        // If the group key is a string, we render a group menu item
        if (typeof key === "string") {
          return (
            <MultiLevelMenu.Item
              key={key}
              name={key}
              label={key}
              icon={getGroupIcon(key, isOpen)}
              slotProps={{
                root: {
                  onMouseDown: e => {
                    !isOpen && setSelectedGroup(key);
                  },
                },
              }}
            >
              {children}
            </MultiLevelMenu.Item>
          );
        }

        return children;
      })}

      {permissions?.flags.businessApplicant &&
        !permissions?.flags.RECFReviewer && [
          <MultiLevelMenu.Item
            key={"businessApplication"}
            name="businessApplication"
            to={`/businessApplication`}
            label={"Application"}
            icon={<ReceiptLongIcon />}
          />,
          <MultiLevelMenu.Item
            key={"businessApplicationAffiliates"}
            name="businessApplicationAffiliates"
            to={`/businessApplicationAffiliates`}
            label={"Affiliations Questionaire"}
            icon={<HelpOutlineIcon />}
          />,
          <MultiLevelMenu.Item
            key={"businessApplicationDocuments"}
            name="businessApplicationDocuments"
            to={`/businessApplicationDocuments`}
            label={"Documents"}
            icon={<FileUploadOutlinedIcon />}
          />,
        ]}
    </MultiLevelMenu>
  );
};
