import { getSdk, SortInput } from "_graphql-types";
import { RaRecord } from "ra-core";

type CreateOrUpdateMeta = {
  disabledFields?: string[];
  fieldAccess?: { [key: string]: boolean };
};

export interface GetListParams<
  TSortEnum extends string,
  TFilter = any,
  TMeta = any
> {
  pagination: {
    page: number;
    perPage: number;
  };
  sort: {
    field: TSortEnum;
    order: SortInput;
  };
  filter: TFilter;
  meta?: TMeta;
}

export interface GetOneParams<TId = string | number, TMeta = any> {
  id: TId;
  meta?: TMeta;
}

export interface GetManyParams<TId = string | number, TMeta = any> {
  ids: TId[];
  meta?: TMeta;
}

export interface GetManyReferenceParams<TSortEnum extends string, TMeta = any> {
  pagination: {
    page: number;
    perPage: number;
  };
  sort: {
    field: TSortEnum;
    order: SortInput;
  };
  filter: any;
  target: string;
  id: string | number;
  meta?: TMeta;
}

export interface CreateParams<T, TMeta = CreateOrUpdateMeta> {
  data: Partial<T>;
  meta?: TMeta;
}

export interface UpdateParams<
  T,
  TId = string | number,
  TMeta = CreateOrUpdateMeta
> {
  id: TId;
  data: Partial<T>;
  previousData?: T;
  meta?: TMeta;
}

export interface UpdateManyParams<T, TId = string | number, TMeta = any> {
  ids: TId[];
  data: Partial<T>;
  meta?: TMeta;
}

export interface DeleteParams<TId = string | number, TMeta = any> {
  id: TId;
  meta?: TMeta;
}

export interface DeleteManyParams<TId = string | number, TMeta = any> {
  ids: TId[];
  meta?: TMeta;
}

export type GetListOut<TOut> = Promise<{
  data: TOut[];
  total: number;
}>;
export type GetOneOut<TOut> = Promise<{ data: TOut | null | undefined }>;
export type GetManyOut<TOut> = Promise<{ data: (TOut | null | undefined)[] }>;
export type GetManyReferenceOut<TOut> = Promise<{
  data: TOut[];
  total: number;
}>;
export type CreateOut<TOut> = Promise<{ data: TOut | null | undefined }>;
export type UpdateOut<TOut> = Promise<{ data: TOut | null | undefined }>;
export type UpdateManyOut = Promise<{ data?: (string | number)[] }>;
export type DeleteOut<TOut> = Promise<{ data?: TOut | null | undefined }>;
export type DeleteManyOut = Promise<{ data?: (string | number)[] }>;

export type Sdk = ReturnType<typeof getSdk>;

export interface DataProvider<
  TSortEnum extends string = any,
  TListOut = any,
  TOneOut = any,
  TManyOut = any,
  TManyReferenceOut = any,
  TCreateIn = any,
  TCreateOut = any,
  TUpdateIn = any,
  TUpdateManyIn = any,
  TUpdateOut = any,
  TDeleteOut = any
> {
  getList(sdk: Sdk, params: GetListParams<TSortEnum>): GetListOut<TListOut>;
  getOne(sdk: Sdk, params: GetOneParams): GetOneOut<TOneOut>;
  getMany(sdk: Sdk, params: GetManyParams): GetManyOut<TManyOut>;
  getManyReference(
    sdk: Sdk,
    params: GetManyReferenceParams<TSortEnum>
  ): GetManyReferenceOut<TManyReferenceOut>;
  create(sdk: Sdk, params: CreateParams<TCreateIn>): CreateOut<TCreateOut>;
  update(sdk: Sdk, params: UpdateParams<TUpdateIn>): UpdateOut<TUpdateOut>;
  updateMany(sdk: Sdk, params: UpdateManyParams<TUpdateManyIn>): UpdateManyOut;
  delete(sdk: Sdk, params: DeleteParams): DeleteOut<TDeleteOut>;
  deleteMany(sdk: Sdk, params: DeleteManyParams): DeleteManyOut;
  /**
   * Controls caching for the data provider responses. TTL is in milliseconds.
   */
  ttl?: {
    getList?: number;
    getOne?: number;
    getMany?: number;
    getManyReference?: number;
  };
}

const defaults: DataProvider = {
  async getList(sdk, params) {
    throw new Error("not implemented");
  },
  async getOne(sdk, params) {
    throw new Error("not implemented");
  },
  async getMany(sdk, params) {
    throw new Error("not implemented");
  },
  async getManyReference(sdk, params) {
    throw new Error("not implemented");
  },
  async create(sdk, params) {
    throw new Error("not implemented");
  },
  async update(sdk, params) {
    throw new Error("not implemented");
  },
  async updateMany(sdk, params) {
    throw new Error("not implemented");
  },
  async delete(sdk, params) {
    throw new Error("not implemented");
  },
  async deleteMany(sdk, params) {
    throw new Error("not implemented");
  },
};

export type UniformDataProvider<T extends RaRecord> = DataProvider<
  any,
  T[],
  T,
  T[],
  T[],
  any,
  T,
  any,
  any,
  T,
  T
>;

export const dataProvider = <
  TSortEnum extends string,
  TListOut,
  TOneOut,
  TManyOut,
  TManyReferenceOut,
  TCreateIn,
  TCreateOut,
  TUpdateIn,
  TUpdateManyIn,
  TUpdateOut,
  TDeleteOut
>(
  provider: Partial<
    DataProvider<
      TSortEnum,
      TListOut,
      TOneOut,
      TManyOut,
      TManyReferenceOut,
      TCreateIn,
      TCreateOut,
      TUpdateIn,
      TUpdateManyIn,
      TUpdateOut,
      TDeleteOut
    >
  >
) => ({
  ...defaults,
  ...provider,
});

export const ONE_HOUR = 24 * 60 * 60 * 1000;

export const defaultEnumTtl: DataProvider["ttl"] = {
  getList: ONE_HOUR,
  getOne: ONE_HOUR,
  getManyReference: ONE_HOUR,
  getMany: ONE_HOUR,
};

export const required = <T, K extends string & keyof T>(
  t: T,
  key: K,
  fallback?: (t: T) => T[K]
) => {
  let val: T[K] | undefined = t[key];
  if (val === undefined) {
    val = fallback?.(t);
    if (val === undefined) {
      throw new Error(`Missing required field: ${key}`);
    }
  }
  return val;
};
