import { gql } from "@apollo/client";

export const GET_APPLICATION_COMPANY = gql`
  query getApplicantCompany {
    companyList {
      items {
        id
        name
        applicationCompletedAt
      }
    }
  }
`;
