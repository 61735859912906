import LoadingButton from "@mui/lab/LoadingButton";
import Autocomplete from "@mui/material/Autocomplete";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { useState } from "react";
import { useDataProvider } from "react-admin";
import { useDebouncedCallback } from "use-debounce";
import { IdName, setInvestmentOptions } from "./View";

export function CopyRiskMeasures() {
  const dataProvider = useDataProvider();
  const [sourceInvestmentOptions, setSourceInvestmentOptions] = useState<
    IdName[]
  >([]);
  const setSourceInvestmentOptionsDebounced = useDebouncedCallback(
    setInvestmentOptions(dataProvider, setSourceInvestmentOptions),
    300
  );
  const [targetInvestmentOptions, setTargetInvestmentOptions] = useState<
    IdName[]
  >([]);
  const setTargetInvestmentOptionsDebounced = useDebouncedCallback(
    setInvestmentOptions(dataProvider, setTargetInvestmentOptions),
    300
  );
  const [sourceInvestmentPick, setSourceInvestmentPick] =
    useState<IdName | null>(null);
  const [targetInvestmentPick, setTargetInvestmentPick] =
    useState<IdName | null>(null);
  const [working, setWorking] = useState(false);
  const [message, setMessage] = useState("");

  return (
    <>
      <h3>Copy Risk Data</h3>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Autocomplete
            filterOptions={options => options}
            options={sourceInvestmentOptions}
            renderInput={params => (
              <TextField
                variant="standard"
                {...params}
                label="Source investment"
              />
            )}
            onInputChange={(event, value, reason) => {
              switch (reason) {
                case "input":
                  return setSourceInvestmentOptionsDebounced(value);
                default:
                  return setSourceInvestmentOptions([]);
              }
            }}
            onChange={(event, value, reason) => {
              switch (reason) {
                case "selectOption":
                  return setSourceInvestmentPick(value as IdName);
                case "clear":
                  return setSourceInvestmentPick(null);
              }
            }}
            value={sourceInvestmentPick}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            getOptionLabel={option => `${option.name} - ${option.id}`}
            data-cy="copy-source-investment-select"
          />
        </Grid>
        <Grid item xs={12}>
          <Autocomplete
            filterOptions={options => options}
            options={targetInvestmentOptions}
            renderInput={params => (
              <TextField
                variant="standard"
                {...params}
                label="Target investment"
              />
            )}
            onInputChange={(event, value, reason) => {
              switch (reason) {
                case "input":
                  return setTargetInvestmentOptionsDebounced(value);
                default:
                  return setTargetInvestmentOptions([]);
              }
            }}
            onChange={(event, value, reason) => {
              switch (reason) {
                case "selectOption":
                  return setTargetInvestmentPick(value as IdName);
                case "clear":
                  return setTargetInvestmentPick(null);
              }
            }}
            value={targetInvestmentPick}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            getOptionLabel={option => `${option.name} - ${option.id}`}
            data-cy="copy-target-investment-select"
          />
        </Grid>
      </Grid>
      <div style={{ margin: "10px", marginLeft: 0 }}>
        <LoadingButton
          variant="contained"
          color="primary"
          disabled={
            !sourceInvestmentPick ||
            !targetInvestmentPick ||
            sourceInvestmentPick === targetInvestmentPick ||
            working
          }
          onClick={async () => {
            const promise = dataProvider.update("investmentRisk", {
              id: "copyRiskMeasures",
              data: {
                sourceInvestmentId: sourceInvestmentPick!.id,
                targetInvestmentId: targetInvestmentPick!.id,
              },
              previousData: { id: "" },
            });
            setMessage("");
            setWorking(true);
            try {
              await promise;
              setMessage("Copy completed successfully");
            } catch (error) {
              setMessage("An error occurred");
              throw error;
            } finally {
              setWorking(false);
            }
          }}
          loading={working}
          data-cy="copy-submit"
        >
          Copy
        </LoadingButton>
      </div>
      {working ? <p>Working...</p> : message ? <p>{message}</p> : null}
    </>
  );
}
