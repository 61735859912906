import * as client from "_graphql-types";
import { CustomCreate } from "../CustomCreate";
import { TopToolbar } from "react-admin";
import { Breadcrumb, BreadcrumbItem } from "@react-admin/ra-navigation";
import { useLocation, Location } from "react-router-dom";
import { ShareClassForm } from "./Form";
import { useCanAccessMutation } from "../../util/useCanAccessMutation";

type IRecord = NonNullable<client.GetOneShareClassQuery["shareClass"]>;

const getInvestmentFromSearchParams = (
  location: Location
): { id: number; name: string } => {
  const searchParams = new URLSearchParams(location.search);
  return JSON.parse(searchParams.get("source") || "{}")?.investment;
};

const InvestmentBreadcrumb = () => {
  const location = useLocation();
  // no record context available in create component actions
  const investment = getInvestmentFromSearchParams(location);

  if (!investment?.id) return null;

  return (
    <TopToolbar sx={{ justifyContent: "flex-start" }}>
      <Breadcrumb>
        <BreadcrumbItem
          name="shareClass"
          label={investment.name}
          to={`/investment/${investment.id}`}
        />
        <BreadcrumbItem
          name="shareClass"
          label="Terms"
          to={`/investmentTerms/${investment.id}`}
        />
      </Breadcrumb>
    </TopToolbar>
  );
};

export function ShareClassCreate() {
  const {
    canEdit,
    canEditField: fieldAccessMap,
    loading,
  } = useCanAccessMutation("addShareClass", JSON.stringify({}));

  function canEditField(field: string) {
    return canEdit && fieldAccessMap[field];
  }
  return (
    <>
      <CustomCreate<IRecord>
        title={() => "Create Share Class"}
        actions={<InvestmentBreadcrumb />}
        customFormProps={{
          customToolbarProps: {
            canAccessMutation: canEdit,
          },
          canEditField: fieldAccessMap,
          loading,
        }}
      >
        <ShareClassForm canEditField={canEditField} />
      </CustomCreate>
    </>
  );
}
