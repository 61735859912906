import { CustomEdit } from "../CustomEdit";
import * as client from "_graphql-types";
import { Grid } from "@mui/material";
import {
  AutocompleteInput,
  Button,
  Datagrid,
  EditButton,
  Pagination,
  ReferenceInput,
  ReferenceField,
  ReferenceManyField,
  TextField,
  TextInput,
  useRecordContext,
  DeleteButton,
} from "react-admin";
import { Link } from "react-router-dom";
import { OrgLinksInput } from "./OrgLinks";

type Record = NonNullable<client.GetOneOrganizationQuery["organization"]>;

const AddNewLinkButton = () => (
  <Button
    component={Link}
    to={{
      pathname: "/links/create",
    }}
    label="Add New Link"
    data-id="AddNewLink"
  />
);

const CreateRelatedLinksButton = () => {
  const record = useRecordContext<Record>();
  return !record ? null : (
    <Button
      component={Link}
      to={{
        pathname: "/orgLinks/create",
        search: `?source=${JSON.stringify({
          organization: {
            id: record.id,
            name: record.name ? encodeURIComponent(record.name) : "",
          },
        })}`,
      }}
      label="Add Organization Links"
      data-id="CreateRelatedLinks"
    />
  );
};

const CreateEmailDomainMappingButton = () => {
  const record = useRecordContext<Record>();
  return !record ? null : (
    <Button
      component={Link}
      to={{
        pathname: "/emailDomainXOrganization/create",
        search: `?source=${JSON.stringify({
          organization: {
            id: record.id,
            name: encodeURIComponent(record.name),
          },
        })}`,
      }}
      label="Add Email Domain Mapping"
      data-id="CreateEmailDomainMapping"
    />
  );
};

const EmailDomainInput = () => {
  const record = useRecordContext<Record>();
  if (!record) return;

  return (
    <>
      <Grid item xs={12}>
        <hr />
        <h3>Email Domain Mappings</h3>
      </Grid>
      <Grid item xs={12}>
        <ReferenceManyField
          label="Email Domain Mappings"
          reference="emailDomainXOrganization"
          target="organizationId"
          sort={{ field: "id", order: "DESC" }}
          pagination={<Pagination />}
        >
          <Datagrid rowClick="edit" bulkActionButtons={false}>
            <TextField
              label="Email Domain"
              source="emailDomain"
              sortable={false}
            />
            <EditButton />
            <DeleteButton
              data-cy="unlinkDomain__button"
              redirect={`/organization/${record.id}`}
            />
          </Datagrid>
        </ReferenceManyField>
      </Grid>
    </>
  );
};

export const OrganizationEdit = () => {
  return (
    <CustomEdit<Record>
      title={record => (record ? `Organization ${record.name || ""}` : null)}
    >
      <>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextInput label="Organization Name" source="name" disabled />
          </Grid>
          <Grid item xs={12}>
            <ReferenceInput
              reference="client"
              sort={{ field: "name", order: "ASC" }}
              perPage={100}
              source="clientId"
            >
              <AutocompleteInput optionText="clientName" label="Client" />
            </ReferenceInput>
          </Grid>
          <Grid item xs={12}>
            <hr />
            <h3>Portal Organization</h3>
          </Grid>
          <Grid item xs={12}>
            <ReferenceField
              label="Portal Organization"
              reference="portalOrganization"
              source="intakeOrganizationId"
              emptyText="Warning no associated portal Organization."
            >
              <TextField label="Name" source="name" />
            </ReferenceField>
          </Grid>

          <Grid item xs={12}>
            <hr />
            <h3>Organization Links</h3>
          </Grid>
          <Grid item xs={12}>
            <OrgLinksInput />
          </Grid>
          <Grid item xs={12}>
            <AddNewLinkButton />
          </Grid>
          <EmailDomainInput />
          <Grid item xs={12}>
            <CreateEmailDomainMappingButton />
          </Grid>
        </Grid>
      </>
    </CustomEdit>
  );
};
