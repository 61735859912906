import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import {
  Datagrid,
  EditButton,
  FunctionField,
  List,
  SortButton,
  TextField,
  TopToolbar,
} from "react-admin";
import { ServiceProviderDocumentFilter } from "./Filter";

export const ServiceProviderDocumentList = () => {
  return (
    <List
      sx={{
        ".RaFilter-form": {
          flex: "0 1 50%",
        },
      }}
      filters={<ServiceProviderDocumentFilter />}
      sort={{ field: "nameSearchRank", order: "ASC" }}
      actions={
        <TopToolbar>
          <SortButton fields={["nameSearchRank", "name"]} />
        </TopToolbar>
      }
    >
      <Datagrid rowClick="edit" bulkActionButtons={false}>
        <TextField label="Name" source="name" />
        <EditButton />
        <FunctionField
          label="Download"
          render={(rec: any) =>
            rec?.url && (
              <a href={`${rec.url}`} download={rec.name}>
                <CloudDownloadIcon />
              </a>
            )
          }
          sortable={false}
        />
      </Datagrid>
    </List>
  );
};
