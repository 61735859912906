import { CustomEdit } from "../CustomEdit";
import * as client from "_graphql-types";
import { Grid } from "@mui/material";

import { EntityInput } from "../UI/EntityInput";
import { TextInput } from "react-admin";

type Record = NonNullable<client.GetOnePortalUserQuery["portalUser"]>;

export const PortalUserEdit = () => {
  return (
    <CustomEdit<Record>
      title={record => record && `User ${record.email || ""}`}
      customFormProps={{
        customToolbarProps: { allowDelete: true },
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextInput label="First Name" source="firstName" disabled />
        </Grid>
        <Grid item xs={6}>
          <TextInput label="Last Name" source="lastName" disabled />
        </Grid>
        <Grid item xs={6}>
          <TextInput label="Email" source="email" disabled />
        </Grid>
        <Grid item xs={6}>
          <EntityInput
            label="Organization"
            resource="portalOrganization"
            sourceKey="organizationId"
            recordKey="organization"
            isRequired
            allowChange
          />
        </Grid>
      </Grid>
    </CustomEdit>
  );
};
