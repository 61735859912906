import Grid from "@mui/material/Grid";
import React from "react";
import NumberInputWithSymbol from "../NumberWithInputAdornment";
import * as client from "_graphql-types";
import { LLMBGColor } from "./util";

export const PublicMarketFields = ({
  canEditField,
  autoPopulatedFields = [],
}: {
  canEditField: (field: keyof client.InvestmentInput) => boolean;
  autoPopulatedFields?: string[];
}) => {
  return (
    <>
      <Grid item xs={12}>
        <hr />
        <h3>Public Market Attributes</h3>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={6} sm={3} lg={3}>
          <NumberInputWithSymbol
            disabled={!canEditField("minGrossExposure")}
            label="Min Gross Exposure"
            source="minGrossExposure"
            symbol="%"
            sx={
              autoPopulatedFields.includes("minGrossExposure")
                ? LLMBGColor
                : undefined
            }
          />
        </Grid>
        <Grid item xs={6} sm={3} lg={3}>
          <NumberInputWithSymbol
            disabled={!canEditField("maxGrossExposure")}
            label="Max Gross Exposure"
            source="maxGrossExposure"
            symbol="%"
            sx={
              autoPopulatedFields.includes("maxGrossExposure")
                ? LLMBGColor
                : undefined
            }
          />
        </Grid>
        <Grid item xs={6} sm={3} lg={3}>
          <NumberInputWithSymbol
            disabled={!canEditField("minNetExposure")}
            label="Min Net Exposure"
            source="minNetExposure"
            symbol="%"
            sx={
              autoPopulatedFields.includes("minNetExposure")
                ? LLMBGColor
                : undefined
            }
          />
        </Grid>
        <Grid item xs={6} sm={3} lg={3}>
          <NumberInputWithSymbol
            disabled={!canEditField("maxNetExposure")}
            label="Max Net Exposure"
            source="maxNetExposure"
            symbol="%"
            sx={
              autoPopulatedFields.includes("maxNetExposure")
                ? LLMBGColor
                : undefined
            }
          />
        </Grid>
        <Grid item xs={6} sm={3} lg={3}>
          <NumberInputWithSymbol
            disabled={!canEditField("minExpectedVolatility")}
            label="Min Expected Volatility"
            source="minExpectedVolatility"
            symbol="%"
            sx={
              autoPopulatedFields.includes("minExpectedVolatility")
                ? LLMBGColor
                : undefined
            }
          />
        </Grid>
        <Grid item xs={6} sm={3} lg={3}>
          <NumberInputWithSymbol
            disabled={!canEditField("maxExpectedVolatility")}
            label="Max Expected Volatility"
            source="maxExpectedVolatility"
            symbol="%"
            sx={
              autoPopulatedFields.includes("maxExpectedVolatility")
                ? LLMBGColor
                : undefined
            }
          />
        </Grid>
      </Grid>
    </>
  );
};
