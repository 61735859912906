import { Divider, Grid } from "@mui/material";
import * as client from "_graphql-types";
import { NO_WHITESPACE_REGEX } from "frontend/src/utils/constants";
import { Button } from "ra-ui-materialui";
import { useEffect } from "react";
import {
  BooleanInput,
  CheckboxGroupInput,
  Pagination,
  ReferenceArrayInput,
  ReferenceInput,
  ReferenceManyField,
  SelectInput,
  TextInput,
  regex,
  required,
  useGetOne,
  useInput,
  useRecordContext,
} from "react-admin";
import { Link, useSearchParams } from "react-router-dom";
import { FirmInstitutionsListDataGrid } from "../firmInstitutions/List";
import { InstitutionEmploymentDatagrid } from "../institutionEmployment/List";
import { InvestmentInstitutionsListDataGrid } from "../investmentInstitutions/List";

type Record = NonNullable<client.GetOneInstitutionQuery["institution"]>;

const validateRequired = [required()];
const validateRequiredNoWhitespace = [
  required(),
  regex(NO_WHITESPACE_REGEX, "Please remove leading and trailing spaces"),
];

const CreateInvestmentLinkButton = () => {
  const record = useRecordContext<Record>();
  if (!record) return null;
  return (
    <>
      <Button
        label="Create Investment Link"
        component={Link}
        variant="contained"
        to={{
          pathname: `/investmentInstitutions/create`,
          search: `?institutionId=${record.id}&redirect=%2FofficeLocation%2F${record.id}`,
        }}
      />
    </>
  );
};

const CreateFirmLinkButton = () => {
  const record = useRecordContext<Record>();
  if (!record) return null;
  return (
    <>
      <Button
        label="Create Firm Link"
        component={Link}
        variant="contained"
        to={{
          pathname: `/firmInstitutions/create`,
          search: `?institutionId=${record.id}&redirect=%2FofficeLocation%2F${record.id}`,
        }}
      />
    </>
  );
};

const CreateInstitutionEmploymentLinkButton = () => {
  const record = useRecordContext<Record>();
  if (!record) return null;
  return (
    <>
      <Button
        label="Create Employment Link"
        component={Link}
        variant="contained"
        to={{
          pathname: `/institutionEmployment/create`,
          search: `?institutionId=${record.id}&redirect=%2FofficeLocation%2F${record.id}`,
        }}
      />
    </>
  );
};

const AddressInput = () => (
  <Grid container spacing={1}>
    <Grid item xs={12}>
      <TextInput
        label="Line 1"
        source="address.line1"
        validate={validateRequired}
      />
    </Grid>
    <Grid item xs={12}>
      <TextInput label="Line 2" source="address.line2" />
    </Grid>
    <Grid item xs={12}>
      <TextInput label="Line 3" source="address.line3" />
    </Grid>
    <Grid item xs={4}>
      <TextInput
        label="City"
        source="address.city"
        validate={validateRequired}
      />
    </Grid>
    <Grid item xs={4}>
      <ReferenceInput
        reference="stateEnum"
        sort={{ field: "name", order: "ASC" }}
        perPage={100}
        source="address.stateEnumId"
      >
        <SelectInput optionText="name" label="US State" />
      </ReferenceInput>
    </Grid>
    <Grid item xs={4}>
      <TextInput label="Province" source="address.otherState" />
    </Grid>
    <Grid item xs={4}>
      <TextInput label="Zip Code" source="address.zipCode" />
    </Grid>
    <Grid item xs={4}>
      <ReferenceInput
        reference="countryEnum"
        sort={{ field: "name", order: "ASC" }}
        perPage={1000}
        source="address.countryEnumId"
      >
        <SelectInput
          optionText="name"
          label="Country"
          validate={validateRequired}
        />
      </ReferenceInput>
    </Grid>
    <Grid item xs={4}></Grid>
    <Grid item xs={4}>
      <TextInput label="Phone Number" source="address.phone" />
    </Grid>
    <Grid item xs={4}>
      <TextInput label="Phone Number 2" source="address.phone2" />
    </Grid>
    <Grid item xs={4}></Grid>
    <Grid item xs={4}>
      <TextInput label="Fax Number" source="address.fax" />
    </Grid>
    <Grid item xs={4}>
      <TextInput label="Fax Number 2" source="address.fax2" />
    </Grid>
    <Grid item xs={4}></Grid>
  </Grid>
);

function Form() {
  const record = useRecordContext<Record>();

  const [params] = useSearchParams();

  const serviceProviderFirmIdFromParams = params.get("serviceProviderFirmId");

  const {
    field: { onChange: onserviceProviderFirmIdFromParams, value: providerId },
  } = useInput({ source: "globalProviderInstitution.providerId" });

  useEffect(() => {
    if (serviceProviderFirmIdFromParams)
      onserviceProviderFirmIdFromParams(
        Number(serviceProviderFirmIdFromParams)
      );
  }, [serviceProviderFirmIdFromParams]); //including onChange causes refresh issue

  const {
    field: { onChange: updateOfficeLocationName },
  } = useInput({ source: "name" });

  const { data } = useGetOne("serviceProviderFirm", { id: providerId });

  useEffect(() => {
    if (data && !record) updateOfficeLocationName(data.name);
  }, [data, record]);

  return (
    <Grid spacing={2}>
      <Grid container spacing={2}>
        <Grid item xs={10}>
          <TextInput
            label="Name"
            source="name"
            validate={validateRequiredNoWhitespace}
          />
          {record ? (
            <div style={{ marginBottom: "10px" }}>
              <Link
                to={`/serviceProviderFirm/${record.globalProviderInstitution?.providerId}`}
              >
                Go To Provider Firm
              </Link>
            </div>
          ) : (
            <div style={{ marginBottom: "30px" }} />
          )}
          <ReferenceInput
            reference="serviceProviderFirm"
            sort={{ field: "name", order: "ASC" }}
            source="globalProviderInstitution.providerId"
            pagination={{ perPage: 50 }}
          >
            <SelectInput
              optionText="name"
              label="Service Provider Firm"
              validate={validateRequired}
              disabled={
                (!record && !!serviceProviderFirmIdFromParams) || !!record
              }
            />
          </ReferenceInput>

          <BooleanInput
            source="globalProviderInstitution.isCorporate"
            label="Is Corporate"
          />

          <ReferenceInput
            reference="institutionSelectionStatusEnum"
            sort={{ field: "name", order: "ASC" }}
            source="institutionSelectionStatusEnumId"
          >
            <SelectInput optionText="name" label="Selection Status" />
          </ReferenceInput>
        </Grid>
        <Grid data-cy="serviceProviderTypes" item xs={10}>
          <ReferenceArrayInput
            reference="institutionTypeEnum"
            sort={{ field: "name", order: "ASC" }}
            perPage={1000}
            source="institutionInstitutionTypesList"
          >
            <CheckboxGroupInput
              label="Service Provider Type"
              optionText="name"
            />
          </ReferenceArrayInput>
        </Grid>
        <Grid item xs={12}>
          <hr />
          <h3>Address</h3>
        </Grid>
        <Grid item xs={12}>
          <AddressInput />
        </Grid>
      </Grid>

      {record && (
        <>
          <Divider style={{ marginBottom: "20px" }} textAlign="left">
            Investment Links
          </Divider>
          <CreateInvestmentLinkButton />
          <ReferenceManyField
            label="Investment Service Provider Links"
            reference="investmentInstitutions"
            target="institutionId"
            perPage={10}
            pagination={<Pagination />}
          >
            <InvestmentInstitutionsListDataGrid />
          </ReferenceManyField>
        </>
      )}

      {record && (
        <>
          <Divider style={{ marginBottom: "20px" }} textAlign="left">
            Firm Links
          </Divider>
          <CreateFirmLinkButton />
          <ReferenceManyField
            label="Firm Service Provider Links"
            reference="firmInstitutions"
            target="institutionId"
            perPage={10}
            pagination={<Pagination />}
          >
            <FirmInstitutionsListDataGrid />
          </ReferenceManyField>
        </>
      )}

      {record && (
        <>
          <Divider style={{ marginBottom: "20px" }} textAlign="left">
            Employments
          </Divider>
          <CreateInstitutionEmploymentLinkButton />
          <ReferenceManyField
            label="Firm Service Provider Links"
            reference="institutionEmployment"
            target="institutionId"
            perPage={10}
            pagination={<Pagination />}
          >
            <InstitutionEmploymentDatagrid />
          </ReferenceManyField>
        </>
      )}
    </Grid>
  );
}

export default Form;
