import { Button, Checkbox } from "@mui/material";
import Help from "@mui/icons-material/Help";
import LoadingButton from "@mui/lab/LoadingButton";
import { FormControlLabel, Radio, RadioGroup, Stack } from "@mui/material";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import { DataGrid } from "@mui/x-data-grid";
import { CSSProperties, useState } from "react";
import {
  DataProvider,
  RadioButtonGroupInput,
  useDataProvider,
} from "react-admin";
import { useDropzone } from "react-dropzone";
import {
  AsyncTaskStatus,
  ImportRiskMeasuresStatus,
  RiskFileParse,
  RiskIdName,
} from "_graphql-types";
import { WarningOutlined } from "@ant-design/icons";

interface Message {
  value?: string;
  error?: string;
}

const importRiskMeasures =
  (
    dataProvider: DataProvider,
    validateOnly: boolean,
    isEstimated: boolean,
    file: File,
    notify: boolean,
    setMessage: (message: Message | undefined) => void,
    setWorking: (working: boolean) => void,
    onSuccess?: (returnValue: RiskFileParse) => void
  ) =>
  async () => {
    setMessage(undefined);
    setWorking(true);

    try {
      const { id } = (
        await dataProvider.update("investmentRisk", {
          id: "importRiskMeasures",
          data: {
            validateOnly,
            isEstimated,
            file,
            notify: true,
          },
          previousData: { id: "" },
        })
      ).data as AsyncTaskStatus;
      console.log("ID ->", id);
      if (!id) throw Error("No ID");
      let timeout = 0;
      let interval = 0;
      interval = window.setInterval(async () => {
        try {
          const {
            status: { endDate, error },
            returnValue,
          } = (
            await dataProvider.getOne("investmentRisk", {
              id: JSON.stringify(["importRiskMeasuresStatus", id]),
            })
          ).data as ImportRiskMeasuresStatus;
          if (error) throw Error(error);
          if (!endDate) return; // keep polling
          if (!returnValue) throw Error("Missing returnValue");
          onSuccess?.(returnValue);
        } catch (error) {
          console.log(error);
          setMessage({ error: "An error occurred" });
        }
        clearInterval(interval);
        clearTimeout(timeout);
        setWorking(false);
      }, 1000 * 5);
      timeout = window.setTimeout(async () => {
        clearInterval(interval);
        setMessage({ error: "An error occurred (timeout)" });
        setWorking(false);
      }, 1000 * 60 * 30);
    } catch (error) {
      setMessage({ error: "An error occurred" });
      setWorking(false);
      throw error;
    }
  };

const style: CSSProperties = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
};

const FileDropzone = ({
  onFileSelected,
  selectedFile,
  multiple,
}: {
  onFileSelected: (file?: File) => void;
  selectedFile?: File;
  multiple?: boolean;
}) => {
  const onDrop = (acceptedFiles: File[]) => {
    if (!multiple) {
      const file = acceptedFiles.pop();
      if (file) onFileSelected(file);
    } else {
      acceptedFiles.forEach(file => onFileSelected(file));
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: multiple ?? false,
    maxFiles: 10,
  });

  return (
    <div className="container">
      <div data-cy="dropzone" {...getRootProps({ style })}>
        <input {...getInputProps()} />
        {selectedFile ? (
          <p data-cy="selected-file">Selected file: {selectedFile.name}</p>
        ) : isDragActive ? (
          <p>Drop the file here.</p>
        ) : (
          <p>Drop a file here, or click to select a file.</p>
        )}
      </div>
    </div>
  );
};

interface RowResult {
  id: number;
  error?: string;
  line: number;
  investmentId?: string;
  reportDate?: string;
  measure?: string;
  tier1?: string;
  tier2?: string;
  category1?: string;
  category2?: string;
  category3?: string;
  category4?: string;
  category5?: string;
}

export const RiskFileImport = () => {
  const dataProvider = useDataProvider();
  const [isEstimated, setIsEstimated] = useState(false);
  const [file, setFile] = useState<File | undefined>();
  const [errorRows, setErrorRows] = useState<RowResult[] | undefined>();
  const [validInvestments, setValidInvestments] = useState<
    RiskIdName[] | undefined
  >();
  const [validating, setValidating] = useState(false);
  const [saving, setSaving] = useState(false);
  const [message, setMessage] = useState<undefined | Message>();
  const [showHelp, setShowHelp] = useState(false);
  const [autoSave, setAutoSave] = useState(true);
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Paper
          elevation={2}
          style={{
            padding: 1,
            paddingLeft: "1em",
            paddingRight: "1em",
            marginTop: "1em",
          }}
        >
          <h3>Import Risk Data</h3>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Stack direction="row" spacing={2} alignItems="center">
                {showHelp ? (
                  <Button onClick={() => setShowHelp(!showHelp)}>
                    Hide Help
                  </Button>
                ) : (
                  <IconButton
                    onClick={() => setShowHelp(!showHelp)}
                    size="large"
                  >
                    <Help />
                  </IconButton>
                )}
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={autoSave}
                      onChange={e => setAutoSave(e.target.checked)}
                      name="Auto"
                    />
                  }
                  label="Auto Save"
                />
                {autoSave ? (
                  <span>
                    Records will save automatically if all records are valid,
                    you will be notified by email of results
                  </span>
                ) : (
                  <></>
                )}
              </Stack>
            </Grid>
            {showHelp && (
              <Grid item xs={12}>
                <p>
                  Provide a <em>tab-delimited</em> text file with the following
                  fields. (These field names are allowed as headers.)
                  <table>
                    <tbody>
                      <tr>
                        <td align="right">1.&ensp;</td>
                        <td>ManagerID</td>
                      </tr>
                      <tr>
                        <td align="right">2.&ensp;</td>
                        <td>ReportDate (mm/dd/yyyy format)</td>
                      </tr>
                      <tr>
                        <td align="right">3.&ensp;</td>
                        <td>Measure</td>
                      </tr>
                      <tr>
                        <td align="right">4.&ensp;</td>
                        <td>Tier1</td>
                      </tr>
                      <tr>
                        <td align="right">5.&ensp;</td>
                        <td>Tier2</td>
                      </tr>
                      <tr>
                        <td align="right">6.&ensp;</td>
                        <td>Category1</td>
                      </tr>
                      <tr>
                        <td align="right">7.&ensp;</td>
                        <td>Category2</td>
                      </tr>
                      <tr>
                        <td align="right">8.&ensp;</td>
                        <td>Category3</td>
                      </tr>
                      <tr>
                        <td align="right">9.&ensp;</td>
                        <td>Category4</td>
                      </tr>
                      <tr>
                        <td align="right">10.&ensp;</td>
                        <td>Category5</td>
                      </tr>
                    </tbody>
                  </table>
                </p>
              </Grid>
            )}
            <Grid item xs={12}>
              <FileDropzone
                onFileSelected={async file => {
                  setFile(file);
                  setErrorRows(undefined);
                  setValidInvestments(undefined);
                  setMessage(undefined);
                  if (!autoSave) return;
                  console.log("File selected", file);
                  importRiskMeasures(
                    dataProvider,
                    false,
                    isEstimated,
                    file!,
                    true,
                    setMessage,
                    setSaving,
                    () => {
                      setMessage({ value: "Import completed successfully" });
                    }
                  )();
                }}
                selectedFile={file}
                multiple={autoSave}
              />
            </Grid>
            <Grid item xs={12}>
              {!autoSave && (
                <Stack direction="row" spacing={2}>
                  <Button
                    data-cy="clear-file"
                    variant="contained"
                    color="primary"
                    disabled={!file}
                    onClick={() => {
                      setFile(undefined);
                      setErrorRows(undefined);
                      setValidInvestments(undefined);
                      setMessage(undefined);
                    }}
                  >
                    Clear File
                  </Button>
                  <LoadingButton
                    data-cy="validate-file"
                    variant="contained"
                    color="primary"
                    disabled={!file}
                    onClick={importRiskMeasures(
                      dataProvider,
                      true,
                      isEstimated,
                      file!,
                      false,
                      setMessage,
                      setValidating,
                      ({
                        errorRows,
                        errorCount,
                        totalCount,
                        validInvestments,
                      }) => {
                        const parsed = errorRows.map(({ error, line, raw }) => {
                          return {
                            id: line,
                            error,
                            line,
                            ...raw,
                          } as RowResult;
                        });
                        setErrorRows(parsed);
                        setValidInvestments(validInvestments);
                        if (errorCount === 0) {
                          setMessage({
                            value: `All ${totalCount} records are valid`,
                          });
                        } else {
                          setMessage({
                            error: `${errorCount} invalid records, out of ${totalCount} total records`,
                          });
                        }
                      }
                    )}
                    loading={validating}
                  >
                    Validate File
                  </LoadingButton>
                  <LoadingButton
                    data-cy="save-file"
                    variant="contained"
                    color="primary"
                    disabled={!errorRows}
                    onClick={importRiskMeasures(
                      dataProvider,
                      false,
                      isEstimated,
                      file!,
                      true,
                      setMessage,
                      setSaving,
                      () => {
                        setMessage({ value: "Import completed successfully" });
                      }
                    )}
                    loading={saving}
                  >
                    Save Records
                  </LoadingButton>
                </Stack>
              )}
            </Grid>
            <Grid item xs={12}>
              {validating && <p data-cy="validating">Validating...</p>}
              {saving && <p data-cy="saving">Saving...</p>}
              {!!message?.value && (
                <p data-cy="message-value">{message.value}</p>
              )}
              {!!message?.error && (
                <p data-cy="message-error">
                  <WarningOutlined
                    style={{
                      fontSize: "50px",
                      color: "red",
                      verticalAlign: "middle",
                    }}
                  />{" "}
                  {message.error}
                </p>
              )}
            </Grid>
            {!!errorRows?.length && (
              <>
                <Grid item xs={12}>
                  {errorRows.length} invalid records, fix these to upload file.
                </Grid>
                <Grid item xs={12}>
                  <DataGrid
                    disableRowSelectionOnClick
                    autoHeight
                    rows={errorRows}
                    columns={[
                      {
                        field: "error",
                        headerName: "Reason for Invalid",
                        sortable: false,
                        disableColumnMenu: true,
                        width: 400,
                      },
                      {
                        field: "line",
                        headerName: "Line",
                        sortable: false,
                        disableColumnMenu: true,
                        width: 80,
                        align: "right",
                      },
                      {
                        field: "investmentId",
                        headerName: "ID",
                        sortable: false,
                        disableColumnMenu: true,
                        width: 80,
                      },
                      {
                        field: "reportDate",
                        headerName: "Date",
                        sortable: false,
                        disableColumnMenu: true,
                        width: 100,
                      },
                      {
                        field: "measure",
                        headerName: "Measure",
                        sortable: false,
                        disableColumnMenu: true,
                        width: 120,
                      },
                      {
                        field: "tier1",
                        headerName: "Tier 1",
                        sortable: false,
                        disableColumnMenu: true,
                        width: 150,
                      },
                      {
                        field: "tier2",
                        headerName: "Tier 2",
                        sortable: false,
                        disableColumnMenu: true,
                        width: 300,
                      },
                      {
                        field: "category1",
                        headerName: "Category 1",
                        sortable: false,
                        disableColumnMenu: true,
                        width: 200,
                      },
                      {
                        field: "category2",
                        headerName: "Category 2",
                        sortable: false,
                        disableColumnMenu: true,
                        width: 200,
                      },
                      {
                        field: "category3",
                        headerName: "Category 3",
                        sortable: false,
                        disableColumnMenu: true,
                        width: 200,
                      },
                      {
                        field: "category4",
                        headerName: "Category 4",
                        sortable: false,
                        disableColumnMenu: true,
                        width: 200,
                      },
                      {
                        field: "category5",
                        headerName: "Category 5",
                        sortable: false,
                        disableColumnMenu: true,
                        width: 200,
                      },
                    ]}
                  />
                </Grid>
              </>
            )}
            {Array.isArray(validInvestments) && (
              <>
                <Grid item xs={12}>
                  {validInvestments.length} distinct investments with valid
                  records
                </Grid>
                <Grid item xs={12}>
                  <DataGrid
                    disableRowSelectionOnClick
                    autoHeight
                    rows={validInvestments}
                    columns={[
                      {
                        field: "id",
                        headerName: "ID",
                        sortable: true,
                        disableColumnMenu: true,
                        width: 80,
                      },
                      {
                        field: "name",
                        headerName: "Name",
                        sortable: true,
                        disableColumnMenu: true,
                        width: 300,
                      },
                    ]}
                  />
                </Grid>{" "}
              </>
            )}
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};
