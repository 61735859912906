import { Grid, TextField } from "@mui/material";
import Highcharts from "highcharts";
import { useState } from "react";
import { DateInput, useRecordContext } from "react-admin";
import * as client from "_graphql-types";
import { CustomCreate } from "../CustomCreate";
import { EntityInput } from "../UI/EntityInput";
import Chart from "./Chart";
import SpreadSheetUpload from "./FileUpload";

export type Record = NonNullable<
  client.GetOneCustomAnalyticsQuery["customAnalytics"]
>;

const defaultOptions: Highcharts.Options = {
  xAxis: {
    type: "linear",
  },
  yAxis: [
    {
      type: "linear",
    },
  ],
  chart: {
    type: "column",
    width: 850,
  },
  title: {
    text: "",
    align: "center",
  },
  exporting: {
    enabled: false,
  },
  credits: {
    enabled: false,
  },
};

export function CustomAnalyticsCreate() {
  const [options, setOptions] = useState<Highcharts.Options>(defaultOptions);
  const [headers, setHeaders] = useState<string[]>([]);
  const [rows, setRows] = useState<(string | number | null)[][]>([]);
  const record = useRecordContext<Record>();
  const title = () => {
    return record?.investment?.name
      ? `Custom Analytics - ${record.investment?.name}`
      : "Custom Analytics";
  };

  return (
    <CustomCreate<Record> title={title}>
      <>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <EntityInput<NonNullable<Record>>
              resource="investment"
              recordKey="investment"
              label="Investment"
              allowChange={true}
              isRequired={true}
              filters={{ active: true }}
            />
          </Grid>
          <Grid item xs={6}>
            <DateInput source="asOfDate" label="As Of Date" />
          </Grid>
        </Grid>
        <Grid container style={{ marginTop: 5 }}>
          <Grid container spacing={1}>
            <Grid item xs={8}>
              <TextField
                fullWidth
                variant="outlined"
                required
                data-cy="custom-analytics__chart-title"
                label="Chart Title"
                onChange={e => {
                  setOptions({
                    ...options,
                    title: { text: e.target.value },
                  });
                }}
              />
            </Grid>
          </Grid>
          <Grid item xs={3}>
            <h4>Data</h4>
          </Grid>
          <SpreadSheetUpload
            setHeaders={setHeaders}
            setRows={setRows}
            source="file"
          />
          <Chart
            source="value"
            chartOptions={options}
            setOptions={setOptions}
            headers={headers}
            setHeaders={setHeaders}
            rows={rows}
            setRows={setRows}
          />
        </Grid>
      </>
    </CustomCreate>
  );
}
