import {
  CreateButton,
  Datagrid,
  EditButton,
  ExportButton,
  List,
  SortButton,
  TextField,
  TopToolbar,
} from "react-admin";
import { CustomIndexInvestmentFilter } from "./Filter";

export const CustomIndexInvestmentList = () => {
  return (
    <List
      sx={{
        ".RaFilter-form": {
          flex: "0 1 50%",
        },
      }}
      filters={<CustomIndexInvestmentFilter />}
      sort={{ field: "nameSearchRank", order: "ASC" }}
      actions={
        <TopToolbar>
          <SortButton fields={["nameSearchRank", "id"]} />
          <CreateButton />
          <ExportButton maxResults={250}/>
        </TopToolbar>
      }
    >
      <Datagrid rowClick="edit" bulkActionButtons={false}>
        <TextField label="Output Investment ID" source="id" />
        <TextField
          label="Output Investment Name"
          source="outputInvestment.name"
        />
        <EditButton />
      </Datagrid>
    </List>
  );
};
