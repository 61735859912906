import * as client from "_graphql-types";
import * as types from "./types";
import { EnsureAllKeys } from "../helpers/types";

type Sdk = ReturnType<typeof client.getSdk>;

/*********************************************************************/
// Data Provider Functions
/*********************************************************************/

/*********************************************************************/
async function getList(
  sdk: Sdk,
  params: types.GetListParams<client.SetSortEnum>
) {
  const {
    pagination: { page, perPage },
    sort: { field, order },
    filter,
  } = params;

  const {
    setList: { items: data, total },
  } = await sdk.getListSet({
    page: {
      offset: (page - 1) * perPage,
      limit: perPage,
    },
    sort: [{ field, order }],
    filter,
  });

  return {
    data,
    total,
  };
}

/*********************************************************************/
async function getOne(sdk: Sdk, params: types.GetOneParams) {
  const id = Number(params.id);
  const { set: data } = await sdk.getOneSet({
    id,
  });
  return {
    data,
  };
}

/*********************************************************************/
async function getMany(sdk: Sdk, params: types.GetManyParams) {
  const ids = params.ids.map(Number);

  const { setMany: data } = await sdk.getManySet({
    ids,
  });

  return {
    data,
  };
}

/*********************************************************************/
async function getManyReference(
  sdk: Sdk,
  params: types.GetManyReferenceParams<client.SetSortEnum>
) {
  const id = Number(params.id);
  const {
    pagination: { page, perPage },
    sort: { field, order },
    filter,
    target,
  } = params;

  const {
    setList: { items: data, total },
  } = await sdk.getListSet({
    page: {
      offset: (page - 1) * perPage,
      limit: perPage,
    },
    sort: [{ field, order }],
    filter: { ...filter, [target]: id },
  });

  return {
    data,
    total,
  };
}

/*********************************************************************/
async function create(
  sdk: Sdk,
  params: types.CreateParams<NonNullable<client.GetOneSetQuery["set"]>>
) {
  const { data } = params;
  const input: EnsureAllKeys<client.CreateSetMutationVariables["input"]> = {
    name: data.name,
    description: data.description,
    expression: data.expression,
    setType: data.setType,
    isBaseSet: data.isBaseSet,
    disabled: data.disabled,
    subscriptionLevelEnumId: data.subscriptionLevelEnumId,
    givesAccess: data.givesAccess,
  };

  const {
    addSet: { id },
  } = await sdk.createSet({
    input,
  });
  return await getOne(sdk, { id: id! });
}

/*********************************************************************/
async function update(
  sdk: Sdk,
  params: types.UpdateParams<NonNullable<client.GetOneSetQuery["set"]>>
) {
  const id = Number(params.id);
  const { data } = params;

  const input: EnsureAllKeys<client.UpdateSetMutationVariables["input"]> = {
    name: data.name,
    description: data.description,
    expression: data.expression,
    setType: data.setType,
    isBaseSet: data.isBaseSet,
    disabled: data.disabled,
    subscriptionLevelEnumId: data.subscriptionLevelEnumId,
    givesAccess: data.givesAccess,
  };

  await sdk.updateSet({
    id,
    input,
  });
  return await getOne(sdk, { id });
}

/*********************************************************************/
export const set = types.dataProvider({
  getList,
  getOne,
  getMany,
  getManyReference,
  create,
  update,
});
