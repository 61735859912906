import * as client from "_graphql-types";
import * as types from "./types";

type Sdk = ReturnType<typeof client.getSdk>;

/*********************************************************************/
// Data Provider Functions
/*********************************************************************/

/*********************************************************************/
async function getList(sdk: Sdk, params: types.GetListParams<client.FeeCalcEnumSortEnum>) {
  const {
    pagination: { page, perPage },
    sort: { field, order }
  } = params;

  const {
    feeCalcEnumList: { items: data, total }
  } = await sdk.getListFeeCalcEnum({
    page: {
      offset: (page - 1) * perPage,
      limit: perPage
    },
    sort: [{ field, order }]
  });

  return {
    data,
    total,
  };
}

/*********************************************************************/
async function getOne(sdk: Sdk, params: types.GetOneParams) {
  const id = Number(params.id);
  const { feeCalcEnum: data } = await sdk.getOneFeeCalcEnum({
    id
  });
  return {
    data,
  };
}

/*********************************************************************/
async function getMany(sdk: Sdk, params: types.GetManyParams) {
  const ids = params.ids.map(Number);

  const {
    feeCalcEnumMany: data
  } = await sdk.getManyFeeCalcEnum({
    ids
  });

  return {
    data,
  };
}


/*********************************************************************/
export const feeCalcEnum = types.dataProvider({
  getList,
  getOne,
  getMany
});