import { Button, Grid, TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
import * as client from "_graphql-types";
import { AumEntry, AumFlow } from "_graphql-types";
import { getQuarter } from "date-fns";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useGetRecordId, useInput } from "react-admin";
import { useCanAccessMutation } from "../../util/useCanAccessMutation";
import CustomBreadCrumb from "../CustomBreadCumb";
import { CustomEdit } from "../CustomEdit";
import { CommaFormattedNumber } from "../CustomNumberInput";
import { NavSearch } from "../Navigator";
import PerformanceContact from "../performance/performanceContact";
import { AumEntryModal } from "./EntryModal";

type FormAumEntry = AumEntry & {
  flow?: AumFlow;
};

const AumInputContainer = styled("div")(() => ({
  ul: {
    maxHeight: 600,
    overflowY: "scroll",
  },
}));

type Record = NonNullable<client.GetOneInvestmentAumQuery["investment"]>;

const ModalButton = (props: {
  onChange: (
    updatefn: (
      existingAssets: Map<string, FormAumEntry>
    ) => Map<string, FormAumEntry>
  ) => void;
}) => {
  const [open, changeOpen] = useState(false);

  return (
    <>
      <AumEntryModal
        open={open}
        onClose={() => changeOpen(false)}
        change={props.onChange}
      />
      <Button data-cy="aum-modal-open" onClick={() => changeOpen(true)}>
        Paste Records
      </Button>
    </>
  );
};

const fieldStyle = { maxWidth: "19%" };
const AumInputRow = memo(
  ({
    updateField,
    value,
    canEditField,
  }: {
    updateField: (date: string, field: string, value: any) => void;
    value: FormAumEntry;
    canEditField: (field: keyof client.AumEntry) => boolean;
  }) => {
    const updateInvestmentAum = useCallback(
      (event: any) => {
        updateField(
          value.asOfDate,
          "investmentAUM",
          event.target.value.replaceAll(",", "")
        );
      },
      [updateField, value.asOfDate]
    );
    const updateFirmAum = useCallback(
      (event: any) => {
        updateField(
          value.asOfDate,
          "firmAUM",
          event.target.value.replaceAll(",", "")
        );
      },
      [updateField, value.asOfDate]
    );
    const updateStrategyAum = useCallback(
      (event: any) => {
        updateField(
          value.asOfDate,
          "strategyAUM",
          event.target.value.replaceAll(",", "")
        );
      },
      [updateField, value.asOfDate]
    );

    const asOfDate = value.asOfDate;
    const [year, month] = String(asOfDate)
      .split("-", 3)
      .slice(0, 2)
      .map(Number);
    const quarter = getQuarter(new Date(year, month - 1, 1));

    return (
      <Grid container style={{ marginTop: 8, marginBottom: 8 }}>
        <Grid item xs={1}>
          {year}-Q{quarter}
        </Grid>
        <Grid item xs={11}>
          <TextField
            label="Investment AUM"
            variant="outlined"
            size="small"
            style={fieldStyle}
            fullWidth={false}
            disabled={!canEditField("investmentAUM")}
            helperText=""
            inputProps={{
              "data-id": `${asOfDate}-investmentAUM`,
            }}
            onBlur={updateInvestmentAum}
            value={value.investmentAUM ?? ""}
            InputProps={{
              inputComponent: CommaFormattedNumber,
            }}
          />
          <TextField
            label="Strategy AUM"
            variant="outlined"
            size="small"
            helperText=""
            fullWidth={false}
            disabled={!canEditField("strategyAUM")}
            inputProps={{
              "data-id": `${asOfDate}-strategyAUM`,
            }}
            onBlur={updateStrategyAum}
            value={value.strategyAUM ?? ""}
            InputProps={{
              inputComponent: CommaFormattedNumber,
            }}
          />
          <TextField
            label="Firm AUM"
            variant="outlined"
            size="small"
            helperText=""
            fullWidth={false}
            disabled={!canEditField("firmAUM")}
            inputProps={{
              "data-id": `${asOfDate}-firmAUM`,
            }}
            onBlur={updateFirmAum}
            value={value.firmAUM ?? ""}
            InputProps={{
              inputComponent: CommaFormattedNumber,
            }}
          />{" "}
          <TextField
            label="Investment Flows"
            fullWidth={false}
            disabled
            variant="outlined"
            style={fieldStyle}
            helperText="Computed"
            value={value.flow?.investment ?? ""}
          />
          <TextField
            label="Strategy Flows"
            fullWidth={false}
            disabled
            variant="outlined"
            style={fieldStyle}
            helperText="Computed"
            value={value.flow?.strategy ?? ""}
          />
        </Grid>
      </Grid>
    );
  },
  // AFFECTS THE PERFORMANCE OF THE WHOLE PAGE
  // we only want to rerender rows whose value has changed
  // when the asset changes we create a new value object, so we can just check its reference
  (prevProps, nextProps) => prevProps.value === nextProps.value
);

const AumInput = ({
  fieldAccessMap,
  canEdit,
}: {
  fieldAccessMap: { [key: string]: boolean };
  canEdit: boolean;
}) => {
  const {
    field: { value, onChange },
    fieldState: { isDirty },
    formState: { isSubmitSuccessful },
  } = useInput({ source: "assets" });

  function canEditField(field: keyof client.AumEntry) {
    return canEdit && fieldAccessMap[field];
  }

  const [assets, setAssets] = useState<Map<string, FormAumEntry>>(
    new Map(value.map((obj: FormAumEntry) => [obj.asOfDate, obj]))
  );

  const [wasSubmittedSuccessfully, setWasSubmittedSuccessfully] =
    useState(false);

  useEffect(() => {
    if (isSubmitSuccessful) {
      setWasSubmittedSuccessfully(true);
    }
  }, [isSubmitSuccessful]);

  const valuesArray = useMemo(() => {
    return Array.from(assets.values());
  }, [assets]);

  useEffect(() => {
    onChange(valuesArray);
  }, [valuesArray]); //performance only when valuesArray Changes

  useEffect(() => {
    if (!isDirty && wasSubmittedSuccessfully) {
      setAssets(new Map(value.map((obj: FormAumEntry) => [obj.asOfDate, obj])));
    }
  }, [isDirty, wasSubmittedSuccessfully]); //can't get updated data until form was submitted successfully and is no longer dirty.

  const updateField = useCallback((date: string, field: string, value: any) => {
    setAssets(assets => {
      const oldField = assets.get(date);
      if (!oldField) throw new Error("can't find field with date " + date);
      assets.set(date, { ...oldField, [field]: value });
      return new Map(assets);
    });
  }, []);

  return (
    <>
      {canEdit && <ModalButton onChange={setAssets} />}

      <AumInputContainer>
        {valuesArray.map(asset => (
          <AumInputRow
            key={asset.asOfDate}
            value={asset}
            updateField={updateField}
            canEditField={canEditField}
          />
        ))}
      </AumInputContainer>
    </>
  );
};

export const AumEntryEdit = () => {
  const recordId = useGetRecordId();
  const mutationArgs = JSON.stringify({
    investmentId: recordId,
  });

  const { canEdit, canEditField, loading } = useCanAccessMutation(
    "addOrUpdateAUM",
    mutationArgs
  );

  return (
    <CustomEdit<Record>
      title={record => record && `AUM - ${record.name}`}
      actions={
        <CustomBreadCrumb<Record>
          name="aum"
          items={[
            {
              path: "firm",
              getId: record => record?.firm?.id,
              getName: record => record?.firm?.name,
            },
            {
              path: "investment",
              getId: record => record?.id,
              getName: record => record?.name,
            },
          ]}
        />
      }
      sourcedFromOdc={(record?: Record) =>
        !!record?.portalSubmitted?.migratedAt
      }
      customFormProps={{
        customToolbarProps: {
          canAccessMutation: canEdit,
        },
        canEditField,
        loading,
      }}
    >
      <>
        <NavSearch name="aum" />

        <Grid container>
          <PerformanceContact />
          <Grid item xs={12}>
            <hr />
          </Grid>
        </Grid>

        <AumInput fieldAccessMap={canEditField} canEdit={canEdit} />
      </>
    </CustomEdit>
  );
};
