import * as client from "_graphql-types";
import { filterSortPage } from "../DataProvider";
import * as types from "./types";

type Sdk = ReturnType<typeof client.getSdk>;

/*********************************************************************/
// Data Provider Functions
/*********************************************************************/

/*********************************************************************/
async function getList(
  sdk: Sdk,
  params: types.GetListParams<client.PersonWorkExperienceSortEnum>
) {
  const { pagination, sort, filter } = params;

  const {
    personWorkExperienceList: { items: data, total },
  } = await sdk.getListPersonWorkExperience(
    filterSortPage({ filter, sort, pagination })
  );

  return {
    data,
    total,
  };
}

/*********************************************************************/
async function getOne(sdk: Sdk, params: types.GetOneParams) {
  const id = Number(params.id);
  const { personWorkExperience: data } = await sdk.getOnePersonWorkExperience({
    id,
  });
  return {
    data,
  };
}

/*********************************************************************/
async function getMany(sdk: Sdk, params: types.GetManyParams) {
  const ids = params.ids.map(id => Number(id));

  const { personWorkExperienceMany: data } =
    await sdk.getManyPersonWorkExperience({
      ids,
    });

  return {
    data,
  };
}

/*********************************************************************/
async function getManyReference(
  sdk: Sdk,
  params: types.GetManyReferenceParams<client.PersonWorkExperienceSortEnum>
) {
  const id = Number(params.id);
  const {
    pagination: { page, perPage },
    sort: { field, order },
    filter,
    target,
  } = params;

  const {
    personWorkExperienceList: { items: data, total },
  } = await sdk.getManyReferencePersonWorkExperience({
    page: {
      offset: (page - 1) * perPage,
      limit: perPage,
    },
    sort: [{ field, order }],
    filter: { ...filter, [target]: id },
  });

  return {
    data,
    total,
  };
}

/*********************************************************************/
async function create(
  sdk: Sdk,
  params: types.CreateParams<
    NonNullable<client.GetOnePersonWorkExperienceQuery["personWorkExperience"]>
  >
) {
  const { data } = params;
  console.log("PWE DATA ->", data);
  const input: client.CreatePersonWorkExperienceMutationVariables["input"] = {
    personId: types.required(data, "person").id,
    companyName: data.company!.name,
    title: data.title,
    fromYear: data.fromYear,
    toYear: data.toYear,
  };

  const {
    addPersonWorkExperience: { id },
  } = await sdk.createPersonWorkExperience({
    input,
  });

  return await getOne(sdk, { id: id! });
}

/*********************************************************************/
async function update(
  sdk: Sdk,
  params: types.UpdateParams<
    NonNullable<client.GetOnePersonWorkExperienceQuery["personWorkExperience"]>
  >
): types.UpdateOut<any> {
  const id = Number(params.id);
  const { data } = params;
  const input: client.UpdatePersonWorkExperienceMutationVariables["input"] = {
    personId: types.required(data, "person").id,
    companyName: data.company?.name || "",
    title: data.title,
    fromYear: data.fromYear,
    toYear: data.toYear,
  };

  await sdk.updatePersonWorkExperience({
    id,
    input,
  });

  return await getOne(sdk, { id });
}

/*********************************************************************/
async function del(sdk: Sdk, params: types.DeleteParams): types.DeleteOut<any> {
  const id = Number(params.id);

  await sdk.deletePersonWorkExperience({ id });

  return { data: { id } };
}

/*********************************************************************/
export const personWorkExperience = types.dataProvider({
  getList,
  getOne,
  getMany,
  getManyReference,
  create,
  update,
  delete: del,
});
