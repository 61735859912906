import React from "react";
import { CognitoUser } from "@aws-amplify/auth";
import { Auth } from "aws-amplify";

type GenericAttributes = { email: string };

export const DEFAULT_WISH_LIST =
  "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz~!@-#$";

export function generatePassword(length = 16, wishlist = DEFAULT_WISH_LIST) {
  return [...window.crypto.getRandomValues(new Uint32Array(length))]
    .map(x => wishlist[x % wishlist.length])
    .join("");
}

export interface SignUpParams<Attributes> {
  setError: React.Dispatch<React.SetStateAction<string | null>>;
  attributes: Attributes;
  validate: (attributes: Attributes) => { validationErrors: string[] };
  setCustomLoginCognitoUser: React.Dispatch<
    React.SetStateAction<CognitoUser | undefined>
  >;
  passwordGenerator?: (length?: number, wishlist?: string) => string;
  activateUser?: (attributes: Attributes) => Promise<{ error?: string }>;
}

export async function customUserSignUp<Attributes extends GenericAttributes>({
  setError,
  attributes,
  validate,
  activateUser,
  setCustomLoginCognitoUser,
  passwordGenerator = generatePassword,
}: SignUpParams<Attributes>) {
  setError(null);
  const { email } = attributes;

  const { validationErrors } = validate(attributes);

  if (validationErrors.length) {
    setError(validationErrors[0]);
    throw Error(
      `Validation Error ${JSON.stringify(validationErrors, null, 2)}`
    );
  }

  try {
    if (activateUser) {
      const res = await activateUser(attributes);
      if (res.error) throw new Error(res.error);
    }
    await Auth.signUp({
      username: email,
      password: passwordGenerator(),
      attributes,
    });
    const user = await Auth.signIn(email);
    setCustomLoginCognitoUser(user);
  } catch (e: any) {
    setError(e.message);
    throw e;
  }
}
