import * as client from "_graphql-types";

import Grid from "@mui/material/Grid";
import { DateInput, ReferenceInput, SelectInput, required } from "react-admin";
import { EntityInput } from "../UI/EntityInput";

type Record =
  client.GetOneServiceProviderDocumentsQuery["serviceProviderDocuments"];

const validateRequired = [required()];

export const ServiceProviderDocuments = () => (
  <>
    <Grid item xs={8}>
      <EntityInput<NonNullable<Record>>
        resource="serviceProviderFirm"
        recordKey="serviceProviderFirm"
        label="Firm"
        isRequired
        allowChange
      />
    </Grid>
    <Grid item xs={4}>
      <DateInput source="documentDate" validate={validateRequired} />
    </Grid>
    <Grid item xs={12}>
      <ReferenceInput
        source="documentTypeId"
        reference="serviceProviderDocumentType"
      >
        <SelectInput
          optionText="name"
          label="Type"
          validate={validateRequired}
        />
      </ReferenceInput>
    </Grid>
  </>
);
