import { FirmDEICreate as create } from "./Create";
import { FirmDEIEdit as edit } from "./Edit";
import { FirmDEIList as list } from "./List";

const FirmDEI = {
  edit,
  create,
  list,
};

export default FirmDEI;
