import React from "react";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";

export const Placeholder = ({
  width,
  height,
}: {
  width?: number;
  height?: number;
}) => (
  <div
    style={{
      height: height || 200,
      width: width || 300,
      backgroundColor: "whiteSmoke",
      display: "flex",
      alignItems: "center",
      flexWrap: "wrap",
      justifyContent: "center",
    }}
  >
    <PhotoCameraIcon htmlColor="black" fontSize="large" />
  </div>
);
