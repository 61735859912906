import * as client from "_graphql-types";
import * as types from "./types";

type Sdk = ReturnType<typeof client.getSdk>;

/*********************************************************************/
// Data Provider Functions
/*********************************************************************/

/*********************************************************************/
async function getList(
  sdk: Sdk,
  params: types.GetListParams<client.PerformanceSourceEnumSortEnum>
) {
  const {
    pagination: { page, perPage },
    sort: { field, order },
  } = params;

  const {
    performanceSourceEnumList: { items: data, total },
  } = await sdk.getListPerformanceSourceEnum({
    page: {
      offset: (page - 1) * perPage,
      limit: perPage,
    },
    sort: [{ field, order }],
  });

  return {
    data,
    total,
  };
}

/*********************************************************************/
async function getOne(sdk: Sdk, params: types.GetOneParams) {
  const id = Number(params.id);
  const { performanceSourceEnum: data } = await sdk.getOnePerformanceSourceEnum(
    {
      id,
    }
  );
  return {
    data,
  };
}

/*********************************************************************/
async function getMany(sdk: Sdk, params: types.GetManyParams) {
  const ids = params.ids.map(id => Number(id));

  const { performanceSourceEnumMany: data } =
    await sdk.getManyPerformanceSourceEnum({
      ids,
    });

  return {
    data,
  };
}

/*********************************************************************/
export const performanceSourceEnum = types.dataProvider({
  getList,
  getOne,
  getMany,
});
