import { INVESTMENT_BUSINESS_OBJECT_ENUM } from "_resources/businessObjectEnum";
import { format } from "date-fns";
import { TempFile } from "./ProfileBuilderButton";

import { FieldValues } from "react-hook-form";
import { isDefinedAndNotNull } from "frontend/src/utils/helpers";
import { GeneratedInvestmentProfile } from "frontend/src/graphql-types/graphql";
import { TempFileSuggestions } from "_graphql-types";
import { compareRichText } from "frontend/src/components/Template/Static/TextStatic";

export const ORGANIZATION_ACCESS_LEVEL = 2;

export const DEFAULT_DOCUMENT_TYPE = 7; // marketing;

function formatSuggestedDate(suggestedDate: TempFileSuggestions["date"]) {
  const defaultDate = format(new Date(), "yyyy-MM-dd");
  if (!suggestedDate) return defaultDate;
  let formattedDate: string = defaultDate;
  try {
    formattedDate = format(new Date(suggestedDate), "yyyy-MM-dd");
  } catch (e) {
    console.error(`Error formatting date: ${suggestedDate} - ${e}`);
  } finally {
    return formattedDate;
  }
}

export function getDocumentParams({
  tempFiles = [],
}: {
  tempFiles?: TempFile[];
}) {
  if (!tempFiles?.length) return [];

  return tempFiles.map(({ suggestions, file }) => ({
    businessObjectEnumId: INVESTMENT_BUSINESS_OBJECT_ENUM,
    allowDuplicateFile: false,
    accessLevel: ORGANIZATION_ACCESS_LEVEL,
    documentTypeEnumId:
      suggestions?.documentTypeEnumId || DEFAULT_DOCUMENT_TYPE,
    date: formatSuggestedDate(suggestions?.date),
    file: file,
  }));
}

interface PopulateFormParams {
  draftInvestment: GeneratedInvestmentProfile;
  skipList?: string[];
  fieldHistory?: FieldValues;
}

export async function mapToFieldValues({
  draftInvestment,
  skipList,
  fieldHistory,
}: PopulateFormParams) {
  const {
    marketEnumId,
    assetClassEnumId,
    vehicleTypeEnumId,
    strategyEnumId,
    firmId,
    firmName,
    currencyDenominationEnumId,
    gpCommitted,
    committedCapital,
    name,
    summary,
    ...investment
  } = draftInvestment;

  function canPopulateField(
    value: any,
    key: keyof FieldValues,
    equalityCheck?: (prev: any, curr: any) => boolean
  ) {
    if (skipList && skipList.includes(key)) return false;
    if (fieldHistory) {
      const isEqual = equalityCheck
        ? equalityCheck(value, fieldHistory[key])
        : fieldHistory?.[key] === value;
      if (isEqual) return false;
    }
    return isDefinedAndNotNull(value);
  }

  const valuesForReset: FieldValues = {};

  if (canPopulateField(marketEnumId, "marketEnumId")) {
    valuesForReset["marketEnumId"] = marketEnumId;
  }

  if (canPopulateField(vehicleTypeEnumId, "vehicleTypeEnumId")) {
    valuesForReset["vehicleTypeEnumId"] = vehicleTypeEnumId;

    if (canPopulateField(assetClassEnumId, "assetClassEnumId")) {
      valuesForReset["assetClassEnumId"] = assetClassEnumId;

      if (canPopulateField(strategyEnumId, "strategyEnumId")) {
        valuesForReset["strategyEnumId"] = strategyEnumId;
      }
    }
  }

  if (canPopulateField(gpCommitted, "gpCommitted")) {
    valuesForReset["gpCommitted"] = gpCommitted;

    if (currencyDenominationEnumId) {
      valuesForReset["gpCommittedCurrencyId"] = currencyDenominationEnumId;
    }
  }

  if (canPopulateField(committedCapital, "committedCapital")) {
    valuesForReset["committedCapital"] = committedCapital;

    if (currencyDenominationEnumId) {
      valuesForReset["committedCapitalCurrencyId"] = currencyDenominationEnumId;
    }
  }

  if (canPopulateField(firmId, "firm")) {
    // we need to set name otherwise react-admin will query firmList with "q: undefined"
    valuesForReset["firm"] = { id: firmId, name: firmName };
  }

  if (canPopulateField(name, "name")) {
    valuesForReset["name"] = name;
  }

  if (canPopulateField(summary, "summary", compareRichText)) {
    valuesForReset["summary"] = summary;
  }

  for (const [key, value] of Object.entries(investment)) {
    if (key === "__typename") continue;
    if (canPopulateField(value, key)) {
      valuesForReset[key] = value;
    }
    continue;
  }

  return valuesForReset;
}
