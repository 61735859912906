import {
  Button,
  Divider,
  Grid,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  ToggleButton,
  Tooltip,
} from "@mui/material";
import { format, parse } from "date-fns";
import { useState } from "react";
import { useNotify } from "react-admin";
import { MtdRec } from "../Edit";
import {
  onlyDistinctRecords,
  parsePasteArea,
  parseSpreadsheet,
} from "../helpers";
import PercentIcon from "@mui/icons-material/Percent";
import NumberIcon from "@mui/icons-material/Tag";
import { styled } from "@mui/material/styles";

const DivPaper = styled("div")(({ theme }) => ({
  position: "absolute",
  width: 800,
  backgroundColor: theme.palette.background.paper,
  border: "2px solid #000",
  boxShadow: theme.shadows[5],
  padding: theme.spacing(2, 4, 3),
  top: "50%",
  left: "50%",
  transform: `translate(-50%,-50%)`,
  outline: "none",
}));

interface Props {
  open: boolean;
  onClose: () => void;
  bulkAddNewRecords: (recs: Record<string, MtdRec>) => void;
  oldRecords: Record<string, MtdRec>;
  currencyId: number;
}

export const FileUploadModal = (props: Props) => {
  const { open, onClose, bulkAddNewRecords, oldRecords, currencyId } = props;
  // key is String([returnYear, returnMonth])
  const [newRecords, setNewRecords] = useState<Record<string, MtdRec>>({});
  const [pasteArea, setPasteArea] = useState("");
  const [notifyTimeout, setNotifyTimeout] = useState(false);
  const [isRawPercent, setIsRawPercent] = useState(true);
  const notify = useNotify();

  const uploadButtonMessage = (
    <>
      <p>
        Upload an .xlsx or .csv with returns posted as values under heading
        'returns'.
      </p>
      <table>
        <tr>
          <td style={{ border: "1px solid white" }}>returns</td>
          <td style={{ border: "1px solid white" }} />
        </tr>
        <tr>
          <td style={{ border: "1px solid white" }}>mm/dd/yy (asOfDate)</td>
          <td style={{ border: "1px solid white" }}>n.nn (return as %)</td>
        </tr>
      </table>
    </>
  );

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <DivPaper>
        <h2 id="simple-modal-title">Add Bulk Performance</h2>
        <Grid container>
          <Grid item xs={6}>
            <TextField
              variant="standard"
              data-id="pasteArea"
              label="Paste Performance"
              placeholder="mm/dd/yy n.nn"
              InputProps={{
                style: { height: "100%" },
              }}
              sx={{
                overflow: "auto",
                height: 300,
                width: "100%",
              }}
              value={pasteArea}
              onChange={(e: any) => {
                setIsRawPercent(true);
                setPasteArea(e.target.value);
                let newRecords: Record<string, MtdRec>;
                try {
                  newRecords = parsePasteArea(e.target.value, currencyId);
                } catch (e) {
                  setNewRecords({});
                  if (!notifyTimeout) {
                    setNotifyTimeout(true);
                    setTimeout(() => setNotifyTimeout(false), 10000);
                    notify(`error parsing your text : ${e}`, {
                      type: "error",
                    });
                  }
                  return;
                }
                const distinctRecords = onlyDistinctRecords(
                  newRecords,
                  oldRecords
                );
                if (!notifyTimeout) {
                  setNotifyTimeout(true);
                  setTimeout(() => setNotifyTimeout(false), 10000);
                  notify(
                    `${
                      Object.keys(distinctRecords).length
                    } New Records Parsed ${
                      Object.keys(newRecords).length -
                      Object.keys(distinctRecords).length
                    } records ignored`,
                    { type: "info" }
                  );
                }

                setNewRecords(distinctRecords);
              }}
              multiline
              fullWidth
              rows={13}
            />
          </Grid>
          <Grid item xs={6}>
            <TableContainer
              sx={{ overflow: "auto", height: 300, width: "100%" }}
            >
              <Table size="small">
                <TableBody>
                  {Object.values(newRecords).map(record => (
                    <TableRow
                      key={String([record.returnYear, record.returnMonth])}
                    >
                      <TableCell>
                        {`${record.returnYear}-${record.returnMonth}`}
                      </TableCell>
                      <TableCell>
                        {record.return === null ? "blank" : `${record.return}%`}
                      </TableCell>
                      <TableCell>
                        {`${
                          record.asOfDate
                            ? format(
                                parse(
                                  record.asOfDate,
                                  "yyyy-MM-dd",
                                  new Date()
                                ),
                                "MM/dd/yyyy"
                              )
                            : ""
                        }`}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
        <Divider />
        <Grid container>
          <Grid item xs={12}>
            Return value can be a number or the word <em>blank</em>.
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Tooltip title={uploadButtonMessage}>
              <Button color="primary" variant="contained">
                <label>
                  Import From SpreadSheet
                  <input
                    data-id="performanceUpload"
                    type="file"
                    accept=".csv,.xlsx"
                    style={{ display: "none" }}
                    onChange={e => {
                      setIsRawPercent(true);
                      const files = e.target.files;
                      if (files?.[0]) {
                        parseSpreadsheet(files[0], currencyId)
                          .then(parsedRecords => {
                            setPasteArea("");
                            const distinctRecords = onlyDistinctRecords(
                              parsedRecords,
                              oldRecords
                            );
                            setNewRecords(distinctRecords);
                            notify(
                              `${
                                Object.keys(distinctRecords).length
                              } New Records Parsed ${Math.max(
                                Object.keys(parsedRecords).length -
                                  Object.keys(distinctRecords).length
                              )} records ignored`,
                              { type: "info" }
                            );
                          })
                          .catch(e => {
                            setNewRecords({});
                            notify(`error parsing your spreadsheet : ${e}`, {
                              type: "error",
                            });
                          });
                      }
                    }}
                  />
                </label>
              </Button>
            </Tooltip>
            <ToggleButton
              data-id="multiply"
              color="primary"
              size={"small"}
              style={{ marginLeft: 10, height: 30 }}
              disabled={!Object.keys(newRecords).length}
              value="check"
              selected={isRawPercent}
              onClick={() => {
                setIsRawPercent(isRawPercent => {
                  setNewRecords(records => {
                    return Object.entries(records).reduce<typeof records>(
                      (acc, [key, rec]) => {
                        acc[key] = {
                          ...rec,
                          return:
                            rec.return &&
                            Math.round(
                              (isRawPercent
                                ? rec.return * 100
                                : rec.return / 100) * 100000000
                            ) / 100000000,
                        };
                        return acc;
                      },
                      {}
                    );
                  });
                  return !isRawPercent;
                });
              }}
            >
              {isRawPercent ? <PercentIcon /> : <NumberIcon />}
            </ToggleButton>
          </Grid>
          <Grid item xs={12}>
            <Button
              data-id="bulkAddRecordsButton"
              color="primary"
              variant="contained"
              disabled={!Object.keys(newRecords).length}
              onClick={() => bulkAddNewRecords(newRecords)}
            >
              Load These Records
            </Button>
          </Grid>
        </Grid>
      </DivPaper>
    </Modal>
  );
};
