import React, { useState } from "react";
import { InputProps, useDataProvider, useInput } from "react-admin";

import Autocomplete from "@mui/material/Autocomplete";
import { TextField } from "@mui/material";
import { Provided } from "../../dataProvider/DataProvider";
import { getList } from "../../dataProvider/resources/schoolEnum";

export const SchoolInput = (props: InputProps) => {
  const dataProvider = useDataProvider();
  const [options, setOptions] = useState<Provided<typeof getList>>([]);

  const input = useInput(props);
  const {
    field: { name, onChange, value: inputValue },
    fieldState: { isTouched, error },
    isRequired,
  } = input;
  return (
    <Autocomplete
      id="school-input"
      options={options}
      getOptionLabel={(option: any) => option.name}
      inputValue={inputValue}
      noOptionsText="No matches"
      filterOptions={options => options}
      renderInput={params => (
        <TextField
          {...params}
          name={name}
          onChange={onChange}
          label={props.label}
          variant="outlined"
          size="small"
          error={!!(isTouched && error)}
          helperText={
            isTouched && error ? "Required" : "Type to display matches"
          }
          required={isRequired}
        />
      )}
      onInputChange={async (event, value, reason) => {
        if (value) {
          const { data } = await dataProvider.getList<
            Provided<typeof getList>[0]
          >("schoolEnum", {
            pagination: {
              page: 1,
              perPage: 10,
            },
            sort: {
              field: "name",
              order: "ASC",
            },
            filter: {
              q: value,
            },
          });
          setOptions(data);
        } 
      }}
      onChange={(event: any, value: any, reason: any) => {
        onChange(value?.name || "");
      }}
    />
  );
};
