import { Grid } from "@mui/material";
import * as client from "_graphql-types";
import { useEffect } from "react";
import {
  AutocompleteInput,
  DateInput,
  ReferenceInput,
  required,
  useInput,
  usePermissions,
  useRecordContext,
} from "react-admin";
import { useWatch } from "react-hook-form";
import { Link, useSearchParams } from "react-router-dom";
import { Permissions } from "../../components/CustomAdmin";

export type Record = NonNullable<
  client.GetOneInvestmentInstitutionsQuery["investmentInstitutions"]
>;

const VALIDATE_REQUIRED = [required()];

export function Form() {
  const record = useRecordContext<Record>();
  const [params] = useSearchParams();

  const investmentIdFromParams = params.get("investmentId");
  const institutionIdFromParams = params.get("institutionId");

  const institutionId = useWatch({ name: "institutionId" });
  const {
    field: { onChange },
  } = useInput({ source: "institutionInstitutionTypeEnumId" });

  const {
    field: { onChange: onChangeInstitutionId },
  } = useInput({ source: "institutionId" });

  const {
    field: { onChange: onChangeInvestmentId },
  } = useInput({ source: "investmentId" });

  useEffect(() => {
    onChange(null);
  }, [institutionId]); //including onChange causes refresh issue

  useEffect(() => {
    if (investmentIdFromParams)
      onChangeInvestmentId(Number(investmentIdFromParams));
  }, [investmentIdFromParams]); //including onChange causes refresh issue

  useEffect(() => {
    if (institutionIdFromParams)
      onChangeInstitutionId(Number(institutionIdFromParams));
  }, [institutionIdFromParams]); //including onChange causes refresh issue

  const { permissions } = usePermissions<Permissions>();

  return (
    <Grid container spacing={2}>
      <Grid xs={6} item>
        {record ? (
          <div style={{ marginBottom: "10px" }}>
            <Link
              to={`/investmentServiceProviderLinks/${record.investmentId}/show`}
            >
              Go To Investment
            </Link>
          </div>
        ) : (
          <div style={{ marginBottom: "30px" }} />
        )}
        <ReferenceInput
          reference="investment"
          source={"investmentId"}
          sort={{ field: "nameSearchRank", order: "ASC" }}
        >
          <AutocompleteInput
            validate={VALIDATE_REQUIRED}
            optionText="name"
            label="Investment"
            fullWidth
            disabled={!!record}
          />
        </ReferenceInput>
      </Grid>
      <Grid xs={6} item>
        {permissions?.flags.isServiceProviderManager && record ? (
          <div style={{ marginBottom: "10px" }}>
            <Link to={`/officeLocation/${record.institutionId}`}>
              Go To Office Location
            </Link>
          </div>
        ) : (
          <div style={{ marginBottom: "30px" }} />
        )}
        <ReferenceInput
          reference="officeLocation"
          source={"institutionId"}
          sort={{ field: "nameSearchRank", order: "ASC" }}
        >
          <AutocompleteInput
            optionText="institutionNameWithLocation"
            label="Office Location"
            fullWidth
            disabled={!!record}
            validate={VALIDATE_REQUIRED}
          />
        </ReferenceInput>
      </Grid>

      <Grid xs={6} item>
        <ReferenceInput
          source="institutionInstitutionTypeEnumId"
          reference="institutionInstitutionTypeEnum"
          sort={{ field: "nameSearchRank", order: "ASC" }}
          filter={{ institutionId }}
          pagination={{ perPage: 50 }}
        >
          <AutocompleteInput
            optionText="institutionTypeEnum.name"
            label="Service Type"
            fullWidth
            disabled={!!record || !institutionId}
            validate={VALIDATE_REQUIRED}
          />
        </ReferenceInput>
      </Grid>

      <Grid xs={6} item>
        <DateInput source="startDate" />
      </Grid>

      <Grid xs={6} item>
        <DateInput source="endDate" />
      </Grid>
    </Grid>
  );
}
