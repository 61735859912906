import {
  Datagrid,
  EditButton,
  List,
  TextField,
  TopToolbar,
  CreateButton,
  ExportButton,
  SortButton,
  NumberField,
} from "react-admin";
import React from "react";
import { SetFilter } from "./Filter";

export const SetList = () => {
  return (
    <List
      sx={{
        ".RaFilter-form": {
          flex: "0 1 50%",
        },
      }}
      filters={<SetFilter />}
      sort={{ field: "nameSearchRank", order: "ASC" }}
      actions={
        <TopToolbar>
          <SortButton fields={["name"]} />
          <CreateButton />
          <ExportButton maxResults={250} />
        </TopToolbar>
      }
    >
      <Datagrid rowClick="edit" bulkActionButtons={false}>
        <TextField label="Name" source="name" />
        <NumberField label="Set Type" source="setType" />

        <EditButton />
      </Datagrid>
    </List>
  );
};
