import { Grid } from "@mui/material";
import * as client from "_graphql-types";
import { format, parseISO } from "date-fns";
import { Button, DateInput, TextInput, useRecordContext } from "react-admin";
import { useWatch } from "react-hook-form";
import { Link } from "react-router-dom";
import { CustomEdit } from "../CustomEdit";
import { EntityInput } from "../UI/EntityInput";
import CustomHighCharts from "./Chart/HighChart.component";

const dateFormat = "MMM d yyyy";

export type Record = NonNullable<
  client.GetOneCustomAnalyticsQuery["customAnalytics"]
>;

const ViewInvestmentCustomAnalyticsButton = () => {
  const record = useRecordContext<Record>();
  return !record?.investment?.id ? null : (
    <Button
      component={Link}
      id="view-investmentCustomAnalytics"
      to={{
        pathname: `/investmentCustomAnalytics/${record.investment.id}`,
      }}
      label="View Investment Custom Analytics Records"
    />
  );
};

function CustomAnalyticsForm() {
  const formData = useWatch();

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <ViewInvestmentCustomAnalyticsButton />
        </Grid>
        <Grid item xs={6}>
          <EntityInput<NonNullable<Record>>
            resource="investment"
            recordKey="investment"
            label={"Investment"}
            isRequired
            allowChange
          />
        </Grid>
        <Grid item xs={6}>
          <DateInput
            source="asOfDate"
            label="As Of Date"
            format={(value: Date | string) => {
              if (!value) return "";
              else if (value instanceof Date)
                return value.toISOString().slice(0, 10);
              else return new Date(value).toISOString().slice(0, 10);
            }}
            parse={(value: string) => {
              return parseISO(value) || value;
            }}
            onChange={({ target: { value } }) => {
              const date = new Date(value.slice(0, 10).replaceAll(/-/g, "/"));
              if (formData.value.subtitle.text && !!date.getTime()) {
                formData.value.subtitle.text = `(As of ${format(
                  date,
                  dateFormat
                )})`;
              }
              return;
            }}
          />
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={8}>
          <TextInput
            fullWidth
            label="Chart Title"
            data-cy="chart-title"
            source="value.title.text"
          />
        </Grid>
      </Grid>
      <CustomHighCharts chartOptions={formData.value} />
    </>
  );
}

export function CustomAnalyticsEdit() {
  return (
    <CustomEdit<Record>
      title={record =>
        record ? `Custom Analytics - ${record.investment?.name}` : null
      }
    >
      <CustomAnalyticsForm />
    </CustomEdit>
  );
}
