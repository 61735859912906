import { Autocomplete, TextField } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import * as client from "_graphql-types";
import { useEffect, useState } from "react";
import { useDataProvider, useInput, useRecordContext } from "react-admin";
import { Provided } from "../../dataProvider/DataProvider";
import { getList } from "../../dataProvider/resources/impactEnums";

type Record = NonNullable<client.GetOneCompanyQuery["company"]>;
export const ImpactSelection = ({ source }: { source: string }) => {
  const record = useRecordContext<Record>();

  const dataProvider = useDataProvider();
  const [impactCategories, setImpactCategories] =
    useState<Provided<typeof getList>>();
  const [selectedTags, changeSelectedTags] = useState<number[]>(
    record?.firmAttributes?.firmXImpact?.length
      ? record?.firmAttributes?.firmXImpact.map(
          ({ impactEnumId }) => impactEnumId
        )
      : []
  );

  useEffect(() => {
    async function fetchChoices() {
      const choices = await dataProvider.getList("impactEnums", {
        filter: {},
        sort: { field: "id", order: "ASC" },
        pagination: { page: 1, perPage: 25 },
      });

      setImpactCategories(choices.data as client.ImpactCategoryEnum[]);
    }
    fetchChoices();
  }, []);

  const {
    field: { onChange },
  } = useInput({ source });

  const getIdsInCategory = (categoryId: number) => {
    if (!impactCategories) return [];
    const selectedCategory = impactCategories.find(
      ({ id }) => id === categoryId
    );
    if (!selectedCategory) return [];

    return selectedCategory.impactEnums.map(({ id }) => id);
  };

  const handleChange = (
    value: client.ImpactEnum[],
    reason: string,
    impactCategoryId: number
  ) => {
    let newTags;

    switch (reason) {
      case "selectOption":
        newTags = Array.from(
          new Set([...value.map(({ id }) => id), ...selectedTags])
        );

        changeSelectedTags(newTags);
        onChange(newTags);

        break;
      case "removeOption":
        newTags = Array.from(
          new Set([
            ...selectedTags.filter(
              id => !getIdsInCategory(impactCategoryId).includes(id)
            ),
            ...value.map(({ id }) => id),
          ])
        );

        break;
      case "clear":
        newTags = selectedTags.filter(
          id => !getIdsInCategory(impactCategoryId).includes(id)
        );
    }
    if (newTags) {
      changeSelectedTags(newTags);
      onChange(newTags);
    }
  };

  return (
    <Box
      sx={{
        marginLeft: 10,
        marginRight: 10,
        marginBottom: 20,
      }}
    >
      <Grid container spacing={2}>
        {impactCategories &&
          impactCategories.map(impactCategory => (
            <Grid item xs={8} sm={6} lg={3} key={impactCategory.id}>
              <Autocomplete
                multiple
                id={`impactInput${impactCategory.id}`}
                autoComplete={false}
                getOptionLabel={option => option.name}
                options={impactCategory.impactEnums}
                value={impactCategory.impactEnums.filter(({ id }) =>
                  selectedTags.includes(id)
                )}
                /* value */
                filterSelectedOptions
                onChange={(event: any, value: any, reason: any) => {
                  handleChange(value, reason, impactCategory.id);
                }}
                renderInput={params => (
                  <TextField
                    {...params}
                    name={impactCategory.name || `${impactCategory.id}`}
                    label={impactCategory.name}
                    variant="outlined"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new",
                    }}
                  />
                )}
              />
            </Grid>
          ))}
      </Grid>
    </Box>
  );
};
