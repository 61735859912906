import * as client from "_graphql-types";
import { filterSortPage } from "../DataProvider";
import * as types from "./types";

type Sdk = ReturnType<typeof client.getSdk>;

/*********************************************************************/
// Data Provider Functions
/*********************************************************************/

async function getOne(sdk: Sdk, params: types.GetOneParams) {
  const id = Number(params.id);

  const { user: data } = await sdk.getOneUser({
    id,
  });
  return { data };
}

/*********************************************************************/

async function getList(
  sdk: Sdk,
  params: types.GetListParams<client.UserSortEnum>
) {
  const { pagination, sort, filter } = params;
  const {
    userList: { items: data, total },
  } = await sdk.getListUser(filterSortPage({ filter, sort, pagination }));

  return {
    data,
    total,
  };
}

/*********************************************************************/
async function getMany(sdk: Sdk, params: types.GetManyParams) {
  const {
    userList: { items: data },
  } = await sdk.getManyUser({ userNames: params.ids.map(id => `${id}`) });

  return {
    data,
  };
}

async function update(
  sdk: Sdk,
  params: types.UpdateParams<
    | ((client.UserGroupUpdateInput | client.UserUpdateInput) &
        client.GetOneUserQuery["user"])
    | { lifecycle: "ACTIVATE" }
    | { lifecycle: "DEACTIVATE" }
  > & {
    previousData?: (client.UserGroupUpdateInput | client.UserUpdateInput) &
      client.GetOneUserQuery["user"];
  }
) {
  const { id, data: input, previousData } = params;

  if ("lifecycle" in input) {
    if (input.lifecycle === "ACTIVATE") {
      await sdk.activateUser({ id: Number(id) });
    } else if (input.lifecycle === "DEACTIVATE") {
      await sdk.deactivateUser({ id: Number(id) });
    } else {
      throw new Error(`Invalid lifecycle: ${input}`);
    }
  } else {
    if (previousData?.isGroup) {
      let groupInput = input as client.UserGroupUpdateInput;
      await sdk.updateUserGroup({
        id: Number(id),
        input: groupInput,
      });
    } else {
      let userInput = input as client.UserUpdateInput;
      await sdk.updateUser({
        id: Number(id),
        input: userInput,
      });
    }
  }

  return await getOne(sdk, { id });
}

async function create(
  sdk: Sdk,
  params: types.CreateParams<
    (client.UserCreateInput | client.UserGroupCreateInput) & {
      isGroup: boolean;
    }
  >
) {
  const input = params.data;

  let id;
  if (input.isGroup) {
    let groupInput = input as client.UserGroupCreateInput;
    const result = await sdk.createUserGroup({
      input: {
        commonName: groupInput.commonName,
        email: groupInput.email,
        organizationId: groupInput.organizationId,
      },
    });
    id = result.createUserGroup.id;
  } else {
    let userInput = input as client.UserCreateInput;
    const result = await sdk.createUser({
      input: {
        commonName: userInput.commonName,
        email: userInput.email,
        organizationId: userInput.organizationId,
      },
    });
    id = result.createUser.id;
  }

  return await getOne(sdk, { id });
}

export const user = types.dataProvider({
  getList,
  getMany,
  getOne,
  update,
  create,
});
