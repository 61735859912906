import * as client from "_graphql-types";
import { filterSortPage } from "../DataProvider";
import * as types from "./types";

type Sdk = ReturnType<typeof client.getSdk>;

/*********************************************************************/
// Data Provider Functions
/*********************************************************************/

/*********************************************************************/
async function getList(
  sdk: Sdk,
  params: types.GetListParams<client.PersonEducationSortEnum>
) {
  const { pagination, sort, filter } = params;

  const {
    personEducationList: { items: data, total },
  } = await sdk.getListPersonEducation(
    filterSortPage({ filter, sort, pagination })
  );

  return {
    data,
    total,
  };
}

/*********************************************************************/
async function getOne(sdk: Sdk, params: types.GetOneParams) {
  const id = Number(params.id);
  const { personEducation: data } = await sdk.getOnePersonEducation({
    id,
  });
  return {
    data,
  };
}

/*********************************************************************/
async function getMany(sdk: Sdk, params: types.GetManyParams) {
  const ids = params.ids.map(id => Number(id));

  const { personEducationMany: data } = await sdk.getManyPersonEducation({
    ids,
  });

  return {
    data,
  };
}

/*********************************************************************/
async function getManyReference(
  sdk: Sdk,
  params: types.GetManyReferenceParams<client.PersonEducationSortEnum>
) {
  const id = Number(params.id);
  const {
    pagination: { page, perPage },
    sort: { field, order },
    filter,
    target,
  } = params;

  const {
    personEducationList: { items: data, total },
  } = await sdk.getManyReferencePersonEducation({
    page: {
      offset: (page - 1) * perPage,
      limit: perPage,
    },
    sort: [{ field, order }],
    filter: { ...filter, [target]: id },
  });

  return {
    data,
    total,
  };
}

/*********************************************************************/
async function create(
  sdk: Sdk,
  params: types.CreateParams<
    NonNullable<client.GetOnePersonEducationQuery["personEducation"]>
  >
) {
  const { data } = params;
  const input: client.CreatePersonEducationMutationVariables["input"] = {
    personId: types.required(data, "person").id,
    schoolName: data.school?.name,
    degreeEnumId: data.degreeEnumId,
    graduationYear: data.graduationYear,
  };

  const {
    addPersonEducation: { id },
  } = await sdk.createPersonEducation({
    input,
  });

  return await getOne(sdk, { id: id! });
}

/*********************************************************************/
async function update(
  sdk: Sdk,
  params: types.UpdateParams<
    NonNullable<client.GetOnePersonEducationQuery["personEducation"]>
  >
): types.UpdateOut<any> {
  const id = Number(params.id);
  const { data } = params;
  const input: client.UpdatePersonEducationMutationVariables["input"] = {
    personId: data.person?.id,
    schoolName: data.school?.name,
    degreeEnumId: data.degreeEnumId,
    graduationYear: data.graduationYear,
  };

  await sdk.updatePersonEducation({
    id,
    input,
  });

  return await getOne(sdk, { id });
}

/*********************************************************************/
async function del(sdk: Sdk, params: types.DeleteParams): types.DeleteOut<any> {
  const id = Number(params.id);

  await sdk.deletePersonEducation({ id });

  return { data: { id } };
}

/*********************************************************************/
export const personEducation = types.dataProvider({
  getList,
  getOne,
  getMany,
  getManyReference,
  create,
  update,
  delete: del,
});
