import React, { useState } from "react";
import { Snackbar } from "@mui/material";
import Alert from "@mui/lab/Alert";
import { v4 as uuid } from "uuid";
import useInterval from "use-interval";

const SEARCH_TEXT = "window.SENTRY_RELEASE";

export const VersionChecker = () => {
  const [version, setVersion] = useState<string | undefined>(undefined);
  const [notificationOpen, changeNotificationOpen] = useState(false);

  const check = async () => {
    console.log(
      `checking for new version at: ${new Date().getHours()}:${new Date()
        .getMinutes()
        .toString()
        .padStart(2, "0")}`
    );
    const text = await (await fetch(`/config.js?q=${uuid()}`)).text();
    const versionLine = text
      .split("\n")
      .find(line => line.includes(SEARCH_TEXT));

    if (!versionLine) return;
    const currentVersion = versionLine?.slice(
      versionLine?.indexOf(`"`) + 1,
      versionLine?.lastIndexOf(`"`)
    );

    if (!version || version !== currentVersion) {
      setVersion(currentVersion);
      if (!version) return;
      if (!notificationOpen) {
        changeNotificationOpen(true);
      }
    }
  };

  useInterval(check, 1000 * 60 * 10, true);

  return (
    <Snackbar
      open={notificationOpen}
      onClose={() => changeNotificationOpen(false)}
      autoHideDuration={null}
      message="New Version of Data Collection"
    >
      <Alert
        onClose={() => changeNotificationOpen(false)}
        onClick={() => window.location.reload()}
        severity="info"
      >
        New Version of Data Collection Click to Reload
      </Alert>
    </Snackbar>
  );
};
