/**
 * If the value can be converted to a number it will return the number.
 * If the value is undefined or null it will return the original value.
 * Otherwise for NaN it will return undefined.
 * @param value {string | number | null | undefined}
 */
export function numberUnlessNaN(value: string | number | null | undefined) {
  if (value === null || value === undefined) {
    return value;
  }
  const num = Number(value);
  if (Number.isNaN(num)) {
    return undefined;
  }
  return num;
}
