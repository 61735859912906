import AccountBalanceOutlinedIcon from "@mui/icons-material/AccountBalanceOutlined";
import AddChartIcon from "@mui/icons-material/Addchart";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import AllInclusive from "@mui/icons-material/AllInclusive";
import BusinessOutlinedIcon from "@mui/icons-material/BusinessOutlined";
import CorporateFareIcon from "@mui/icons-material/CorporateFare";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import Equalizer from "@mui/icons-material/Equalizer";
import FileUpload from "@mui/icons-material/FileUpload";
import FunctionsIcon from "@mui/icons-material/Functions";
import HandshakeIcon from "@mui/icons-material/Handshake";
import HistoryIcon from "@mui/icons-material/History";
import LinkIcon from "@mui/icons-material/Link";
import ListIcon from "@mui/icons-material/List";
import PersonIcon from "@mui/icons-material/Person";
import PlaceIcon from "@mui/icons-material/Place";
import SettingsIcon from "@mui/icons-material/Settings";
import SsidChartIcon from "@mui/icons-material/SsidChart";
import UserIcon from "@mui/icons-material/SupervisedUserCircle";
import Sync from "@mui/icons-material/Sync";
import Timeline from "@mui/icons-material/Timeline";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import blue from "@mui/material/colors/blue";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { AppLocationContext } from "@react-admin/ra-navigation";
import { MutationCache, QueryCache, QueryClient } from "@tanstack/react-query";
import * as client from "_graphql-types";
import { RCGApolloProvider } from "frontend/src/components/RCGApolloProvider";
import {
  ReactNode,
  useContext,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import {
  Admin,
  CustomRoutes,
  Layout,
  DataProvider as RaDataProvider,
  RaThemeOptions,
  Resource,
  WithPermissions,
  localStorageStore,
} from "react-admin";
import { Route, useLocation } from "react-router-dom";
import config from "../config";
import AssumeUserEdit from "./AssumeUser";
import { AuthContext } from "./Auth/AuthProvider.component";
import { CustomAppBar } from "./CustomAppBar";
import { CustomMenu } from "./CustomMenu";
import { useDataProvider } from "./DataProviderContext";
import { DisplayThemeContext } from "./DisplayThemeContext";
import firmDEI from "./FirmDEI";
import { HistoryLogger } from "./HistoryLogger";
import links from "./Links";
import { TermsOfService } from "./TermsOfService";
import user from "./UserAndGroups";
import aum from "./aum";
import closeDate from "./closeDate";
import company from "./company";
import { BusinessApplicationDocuments } from "./company/BusinessApplication";
import AffiliateQuestionaire from "./company/BusinessApplication/AffiliateQuestionaire";
import BusinessApplication from "./company/BusinessApplication/ApplicationForm";
import companyDocument from "./companyDocument";
import companyValuation from "./companyValuation";
import customAnalytics from "./customAnalytics";
import customIndexInvestment from "./customIndexInvestment";
import dataLinks from "./dataLinks";
import deal from "./deal";
import dealDocument from "./dealDocument";
import dealNotes from "./dealNotes";
import document from "./document";
import emailDomainXOrganization from "./emailDomainXOrganization";
import family from "./family";
import fdpLogin from "./fdpLogin";
import financialDataProvider from "./financialDataProvider";
import firm from "./firm";
import firmDocument from "./firmDocument";
import firmEmployment from "./firmEmployment";
import firmFDPLogins from "./firmFDPLogins";
import firmFirmDEI from "./firmFirmDEI";
import firmInstitutions from "./firmInstitutions";
import firmServiceProviderLinks from "./firmServiceProviderLinks";
import institutionEmployment from "./institutionEmployment";
import investment from "./investment";
import investmentAxysCode from "./investmentAxysCode";
import investmentCustomAnalytics from "./investmentCustomAnalytics";
import investmentDocuments from "./investmentDocument";
import investmentFDPLogins from "./investmentFDPLogins";
import investmentFirmEmployment from "./investmentFirmEmployment";
import investmentFirmEmploymentBulk from "./investmentFirmEmploymentBulk";
import investmentInstitutions from "./investmentInstitutions";
import investmentPeers from "./investmentPeers";
import * as investmentRisk from "./investmentRisk";
import { RiskFileImport } from "./investmentRisk/RiskFileImport";
import investmentServiceProviderLinks from "./investmentServiceProviderLinks";
import investmentTerms from "./investmentTerms";
import notes from "./notes";
import officeLocation from "./officeLocation";
import organization from "./organization";
import performance from "./performance";
import * as performanceBackfill from "./performanceBackfill";
import * as performanceMultiInvestment from "./performanceMultiInvestment";
import performancePrivate from "./performancePrivate";
import person from "./person";
import personEducation from "./personEducation";
import personWorkExperience from "./personWorkExperience";
import portalFirm from "./portalFirm";
import portalOrganization from "./portalOrganization";
import portalServiceProviderFirms from "./portalServiceProviderFirms";
import portalSubmitted from "./portalSubmitted";
import portalUser from "./portalUser";
import portfolioConfig from "./portfolioConfig";
import RiskRefreshList from "./riskRefreshList";
import serviceProviderDocument from "./serviceProviderDocument";
import serviceProviderFirm from "./serviceProviderFirm";
import serviceProviderNotes from "./serviceProviderNotes";
import set from "./set";
import shareClass from "./shareClass";

export type Permissions = Pick<
  client.GetOneCurrentUserQuery["currentUser"],
  "flags" | "organization"
> & {
  assumedUser?: Pick<
    client.GetOneCurrentUserQuery["currentUser"],
    "flags" | "organization"
  >;
};

const PermissionedAssumeUserEdit = (props: any) => (
  <WithPermissions
    location={props.location}
    render={({ permissions }: { permissions: Permissions }) =>
      permissions?.flags.canAssumeUser ? (
        <AssumeUserEdit {...props} permissions={permissions} />
      ) : (
        <></>
      )
    }
  />
);

declare module "@mui/material/styles" {
  interface TypographyVariants {
    mono: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    mono?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    mono: true;
  }
}

const customTheme = (useDarkTheme: boolean): RaThemeOptions => {
  return createTheme({
    palette: {
      mode: useDarkTheme ? "dark" : "light",
      primary: blue,
      secondary: {
        main: "rgba(44,68,173,1)",
      },
    },
    ...{
      sidebar: {
        width: 240,
        closedWidth: 50,
      } as any,
    },
    typography: {
      fontFamily: [
        '"Open Sans"',
        "Roboto",
        "Helvetica",
        "Arial",
        "sans-serif",
      ].join(","),
      mono: {
        fontFamily: ['"Roboto Mono"', "Courier", "Monaco", "monospace"].join(),
      },
    },
    components: {
      MuiInputBase: {
        defaultProps: { fullWidth: true, size: "medium" },
      },
      MuiTextField: {
        defaultProps: { fullWidth: true, size: "medium", variant: "outlined" },
      },
      MuiSelect: {
        defaultProps: { fullWidth: true, size: "medium", variant: "outlined" },
      },
      MuiTablePagination: {
        styleOverrides: {
          input: {
            width: "inherit",
          },
        },
      },
      ...{
        RaSelectInput: {
          styleOverrides: {
            root: {
              margin: 0,
            },
          },
        },
      },
    },
  });
};

const lightTheme = customTheme(false);
const darkTheme = customTheme(true);

function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return size;
}

export function useCustomHeaderEffect() {
  const [width, height] = useWindowSize();
  const location = useLocation();
  const [mainLayoutPadding, setMainLayoutPadding] = useState(20);

  //Could be better
  useEffect(() => {
    let header = window.document.querySelector("header");
    let height = header?.offsetHeight;
    setMainLayoutPadding(height && height > 68 ? height - 48 : 20);
  }, [location, height, width, setMainLayoutPadding]);

  return mainLayoutPadding;
}

const CustomLayout = ({ children }: { children?: ReactNode }) => {
  const mainLayoutPadding = useCustomHeaderEffect();
  return (
    <AppLocationContext>
      <Layout
        appBar={CustomAppBar}
        menu={CustomMenu}
        sx={{
          ".RaLayout-content": { paddingTop: `${mainLayoutPadding}px` },
          ".RaSidebar-fixed": { paddingTop: `${mainLayoutPadding}px` },
        }}
      >
        {children}
      </Layout>
    </AppLocationContext>
  );
};

function FallbackComponent() {
  const { signOut } = useContext(AuthContext);
  return (
    <div>
      <button type="button" onClick={signOut}>
        Log Out
      </button>
      <div>
        Something went wrong while loading user. A deployment may be occurring,
        try refreshing in 60 seconds.
      </div>
    </div>
  );
}

const queryCache = new QueryCache();

const mutationCache = new MutationCache({
  onSuccess: (data: any, variables: any, context: any, mutation: any) => {
    queryCache.findAll(variables.resource).map(query => query.invalidate());
    if (variables.resource === "officeLocation") {
      queryCache
        .findAll({ queryKey: ["serviceProviderFirm"] })
        .map(query => query.invalidate());
    }
    return;
  },
});

/*
https://marmelab.com/react-admin/Caching.html
*/
const queryClient = new QueryClient({
  defaultOptions: {
    /*
     * DO NOT SET A DEFAULT staleTime!!
     * That is the root of all the caching problems, due to
     * RA's (bad) design that updates the getOne cache from
     * a getList response. No way to change that unfortunately.
     * https://github.com/marmelab/react-admin/issues/8702
     * Custom caching is implemented in the data provider.
     
     * This will ensure that a getOne request will be sent on the show pages
     * But the getOne pages(edit/show) will still need to respond to potentially missing 
     * attributes from relating entity requests. User Edit may provide an example for this. If requesting
     * less attributes in for lists.
     * 
     * Removing stale time generally diminishes the chance of displaying old data to the User, as
     * we will always be requesting the latest data from the server. However, this will increase the
     * number of requests to the server, but likely those requests are good to have in an app focused on data capturing.
     * - Sam
    */
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
    },
  },
  mutationCache,
  queryCache,
});

export const CustomAdmin = () => {
  const { useDarkTheme } = useContext(DisplayThemeContext);
  const { dataProvider, showLoginDialog } = useDataProvider();
  const { signOut, auth } = useContext(AuthContext);
  const location = useLocation();

  const shadowFeaturesEnabled = location.search.includes("shadow=true");
  console.log("Shadow features enabled:", shadowFeaturesEnabled);
  console.log("Location:", location.search);

  const authProvider = {
    login: async () => {},
    logout: async () => {
      signOut();
    },
    checkAuth: async () => {},
    checkError: async (error: any) => {
      const message = String(error.message);
      console.log("Data provider error:", message);
      if (message.includes("AuthenticationError")) {
        showLoginDialog();
      }
    },
    getPermissions: async () => {
      const { data } = await dataProvider.getOne<client.CurrentUser>(
        "currentUser",
        { id: "dummy" }
      );

      const permissions: Permissions = {
        flags: data!.flags,
        organization: data!.organization,
      };
      return Promise.resolve(permissions);
    },
  };

  if (!auth) return null;

  return (
    <>
      <RCGApolloProvider
        url={config.GRAPHQL_API()}
        getToken={auth.getIdToken}
        FallbackComponent={FallbackComponent}
        webSocketUrl={config.RCG_SIRIUS_WS_URI()}
      >
        <TermsOfService>
          <HistoryLogger />
          <ThemeProvider theme={useDarkTheme ? darkTheme : lightTheme}>
            <Admin
              queryClient={queryClient}
              authProvider={authProvider}
              dataProvider={dataProvider as RaDataProvider}
              layout={CustomLayout}
              theme={useDarkTheme ? darkTheme : lightTheme}
              title="RockCreek Data Collection"
              store={localStorageStore("1.1")}
            >
              <CustomRoutes>
                <Route
                  path="/assumeUser"
                  element={<PermissionedAssumeUserEdit />}
                />
                <Route
                  path="/businessApplication"
                  element={<BusinessApplication />}
                />
                <Route
                  path="/businessApplicationAffiliates"
                  element={<AffiliateQuestionaire />}
                />
                <Route
                  path="/businessApplicationDocuments"
                  element={<BusinessApplicationDocuments />}
                />
              </CustomRoutes>
              {(permissions: Permissions) => (
                <>
                  {permissions.flags.businessApplicant &&
                    !permissions.flags.RECFReviewer && (
                      <>
                        <Resource
                          name="company"
                          {...company}
                          list={company.BusinessApplicationRedirect}
                          edit={company.BusinessApplicationEdit}
                          icon={BusinessOutlinedIcon}
                        />
                      </>
                    )}
                  {permissions.flags.isClientServicesUser && (
                    <>
                      <Resource
                        name="portfolioSettings"
                        {...portfolioConfig}
                        options={{
                          label: "Portfolio Settings",
                        }}
                        icon={SettingsIcon}
                      />
                    </>
                  )}
                  {permissions.flags.isServiceProviderManager && (
                    <>
                      <Resource
                        name="serviceProviderFirm"
                        icon={BusinessOutlinedIcon}
                        options={{
                          label: "Service Provider Firms",
                          group: "Service Providers",
                          displayOrder: 1,
                        }}
                        {...serviceProviderFirm}
                      />
                      <Resource
                        name="officeLocation"
                        icon={PlaceIcon}
                        options={{
                          label: "Office Location",
                          group: "Service Providers",
                          displayOrder: 2,
                        }}
                        {...officeLocation}
                      />
                      <Resource
                        name="institutionEmployment"
                        {...institutionEmployment}
                        icon={PersonIcon}
                        options={{
                          group: "Service Providers",
                          label: "Employments",
                          displayOrder: 3,
                        }}
                      />
                      <Resource
                        name="serviceProviderDocument"
                        icon={DescriptionOutlinedIcon}
                        options={{
                          label: "Service Provider Documents",
                          group: "Service Providers",
                          displayOrder: 4,
                        }}
                        {...serviceProviderDocument}
                      />
                      <Resource
                        name="serviceProviderNotes"
                        icon={DescriptionOutlinedIcon}
                        options={{
                          label: "Service Provider Notes",
                          group: "Service Providers",
                          displayOrder: 5,
                        }}
                        {...serviceProviderNotes}
                      />
                      <Resource
                        name="investmentInstitutions"
                        icon={LinkIcon}
                        options={{
                          group: "Service Providers",
                          label: "Investment Link",
                          displayOrder: 6,
                        }}
                        {...investmentInstitutions}
                      />
                      <Resource
                        name="firmInstitutions"
                        icon={LinkIcon}
                        options={{
                          group: "Service Providers",
                          label: "Firm Link",
                          displayOrder: 7,
                        }}
                        {...firmInstitutions}
                      />
                      <Resource
                        name="investmentServiceProviderLinks"
                        {...investmentServiceProviderLinks}
                      />
                      <Resource
                        name="firmServiceProviderLinks"
                        {...firmServiceProviderLinks}
                      />
                    </>
                  )}
                  {permissions.flags.DCAdmin && (
                    <>
                      <Resource
                        {...portalFirm}
                        name="portalFirm"
                        options={{
                          label: "Portal Firms",
                          group: "Portal",
                          displayOrder: 7,
                        }}
                      />
                      <Resource
                        {...portalSubmitted}
                        name="portalSubmitted"
                        options={{
                          label: "Portal Submitted",
                          group: "Portal",
                          displayOrder: 8,
                        }}
                      />
                      <Resource
                        {...portalOrganization}
                        name="portalOrganization"
                        options={{
                          label: "Portal Organizations",
                          group: "Portal",
                          displayOrder: 9,
                        }}
                      />
                      <Resource
                        name="set"
                        {...set}
                        icon={AdminPanelSettingsIcon}
                        options={{
                          label: "Sets",
                          displayOrder: 12,
                        }}
                      />
                      <Resource
                        {...user}
                        name="user"
                        icon={UserIcon}
                        options={{
                          label: "Users & Groups",
                          displayOrder: 12,
                        }}
                      />
                      <Resource
                        {...portalServiceProviderFirms}
                        name="portalServiceProviderFirms"
                      />
                      <Resource
                        {...portalUser}
                        name="portalUser"
                        options={{
                          label: "Portal Users",
                          group: "Portal",
                          displayOrder: 10,
                        }}
                      />
                      <Resource
                        icon={CorporateFareIcon}
                        name="organization"
                        options={{
                          label: "Organizations",
                          displayOrder: 11,
                        }}
                        {...organization}
                      />
                      <Resource name="orgLinks" />
                      <Resource
                        name="links"
                        {...links}
                        options={{
                          label: "Org Links",
                        }}
                      />
                      <Resource
                        name="emailDomainXOrganization"
                        {...emailDomainXOrganization}
                      />
                      <Resource name="userActivity" />
                    </>
                  )}
                  {permissions.flags.isRockCreek && (
                    <>
                      <Resource
                        icon={BusinessOutlinedIcon}
                        name="firm"
                        options={{
                          displayOrder: 12,
                        }}
                        {...firm}
                      />
                      <Resource
                        icon={PersonIcon}
                        name="person"
                        options={{ displayOrder: 13 }}
                        {...person}
                      />
                      <Resource
                        name="company"
                        icon={BusinessOutlinedIcon}
                        options={{ displayOrder: 15 }}
                        {...company}
                      />
                      <Resource
                        name="investmentAxysCode"
                        {...investmentAxysCode}
                      />
                      <Resource
                        name="fdpLogin"
                        options={{ label: "Logins", group: "FDP Logins" }}
                        {...fdpLogin}
                        icon={VpnKeyIcon}
                      />
                      <Resource name="firmFDPLogins" {...firmFDPLogins} />
                      <Resource
                        name="investmentFDPLogins"
                        {...investmentFDPLogins}
                      />
                      <Resource
                        name="financialDataProvider"
                        options={{ label: "FDPs", group: "FDP Logins" }}
                        icon={BusinessOutlinedIcon}
                        {...financialDataProvider}
                      />
                      <Resource name="family" {...family} />
                      <Resource name="closeDate" {...closeDate} />
                      <Resource name="agency" />
                      <Resource name="firmEmployment" {...firmEmployment} />
                      <Resource name="firmDocument" {...firmDocument} />
                      <Resource name="companyDocument" {...companyDocument} />
                      <Resource
                        name="investmentCustomAnalytics"
                        {...investmentCustomAnalytics}
                      />

                      <Resource name="investmentBulk" />
                      <Resource
                        name="investmentFirmEmployment"
                        {...investmentFirmEmployment}
                      />
                      <Resource
                        name="personWorkExperience"
                        {...personWorkExperience}
                      />
                      <Resource name="personEducation" {...personEducation} />
                      <Resource name="investmentTags" />
                      <Resource name="tags" />
                      <Resource name="tagClasses" />
                      <Resource name="notes" {...notes} />
                      <Resource name="noteMetaEnum" />
                      <Resource name="serviceProviderDocumentType" />
                      <Resource name="investmentAssignmentOption" />
                      {/* APPEND RESOURCE */}

                      <Resource
                        icon={AccountBalanceOutlinedIcon}
                        name="investment"
                        options={{ displayOrder: 14 }}
                        {...investment}
                      />
                      <Resource
                        name="investmentFirmEmploymentBulk"
                        {...investmentFirmEmploymentBulk}
                      />
                      <Resource
                        name="investmentDocument"
                        {...investmentDocuments}
                      />
                      <Resource name="investmentPeers" {...investmentPeers} />
                      <Resource name="shareClass" {...shareClass} />
                      <Resource name="investmentTerms" {...investmentTerms} />
                      <Resource
                        name="performancePrivate"
                        {...performancePrivate}
                      />
                      <Resource
                        name="documentMulti"
                        {...document}
                        create={document.multiCreate}
                      />
                      <Resource name="document" {...document} />
                      <Resource name="document" {...document} />
                      <Resource
                        name="aum"
                        icon={Equalizer}
                        options={{ label: "AUM", displayOrder: 21 }}
                        {...aum}
                      />
                      <Resource
                        {...performance}
                        name="performance"
                        icon={Timeline}
                        options={{
                          label: "Performance",
                          displayOrder: 22,
                        }}
                      />

                      <Resource name="companyValuation" {...companyValuation} />

                      <Resource name="client" />
                      <Resource name="dealStructure" />
                      <Resource name="investmentsFamily" />
                      <Resource name="investmentRiskCategory" />
                      <Resource name="investmentRiskInvestment" />
                      <Resource name="asyncTaskStatus" />
                      <Resource
                        name="deal"
                        icon={HandshakeIcon}
                        options={{ label: "Deals", displayOrder: 16 }}
                        {...deal}
                      />
                      <Resource name="dealDocument" {...dealDocument} />
                      <Resource name="dealNotes" {...dealNotes} />
                      <Resource name="dealStructureEnum" />
                      <Resource
                        name="customAnalytics"
                        {...customAnalytics}
                        icon={AddChartIcon}
                        options={{
                          label: "Custom Analytics",
                          displayOrder: 17,
                        }}
                      />
                      <Resource name="dataLinks" {...dataLinks} />
                    </>
                  )}
                  {permissions.flags.DCAdmin && (
                    <>
                      <Resource
                        name="portalServiceProviderFirms"
                        {...portalServiceProviderFirms}
                      />
                      <Resource name="portalEmailDomainOrganization" />

                      <Resource
                        name="importRiskFile"
                        options={{
                          label: "Import Data",
                          group: "Risk Data",
                          displayOrder: 18,
                        }}
                        list={RiskFileImport}
                        icon={FileUpload}
                      />
                      <Resource
                        name="investmentRisk"
                        options={{
                          label: "Manage Data",
                          group: "Risk Data",
                          displayOrder: 19,
                        }}
                        {...investmentRisk}
                        icon={Sync}
                      />
                      <Resource name="portalFirmTypeEnum" />
                      <Resource name="userXGroups" />
                      <Resource name="application" />
                      <Resource name="adEntriesXApplicationFlags" />
                      <Resource name="adEntriesXApplicationsXSets" />
                    </>
                  )}
                  {permissions.flags.isRiskUser && (
                    <>
                      <Resource
                        name="riskRefreshList"
                        options={{
                          label: "Daily Refresh List",
                          group: "Risk Data",
                          displayOrder: 20,
                        }}
                        {...RiskRefreshList}
                        icon={ListIcon}
                      />
                    </>
                  )}
                  {permissions.flags.isDataManager && (
                    <>
                      <Resource
                        name="firmDEI"
                        icon={AllInclusive}
                        options={{ label: "Firm DEI", displayOrder: 23 }}
                        {...firmDEI}
                      />
                      <Resource name="firmFirmDEI" {...firmFirmDEI} />
                    </>
                  )}
                  {permissions.flags.DCAdmin && (
                    <>
                      <Resource name="performanceBackfillInvestment" />
                      <Resource
                        {...performanceBackfill}
                        name="performance-backfill"
                        options={{
                          label: "Backfill",
                          group: "Bulk Performance",
                          displayOrder: 24,
                        }}
                        icon={HistoryIcon}
                      />
                      <Resource
                        {...performanceMultiInvestment}
                        name="performance-multi-investment"
                        options={{
                          label: "Multi-Investment",
                          group: "Bulk Performance",
                          displayOrder: 25,
                        }}
                        icon={SsidChartIcon}
                      />
                      <Resource
                        {...customIndexInvestment}
                        name="customIndexInvestment"
                        options={{
                          label: "Custom Index",
                          group: "Bulk Performance",
                          displayOrder: 26,
                        }}
                        icon={FunctionsIcon}
                      />
                      <Resource name="orgLinks" />
                      <Resource name="portalFirmTypeEnum" />
                      <Resource name="customIndexPreview" />
                    </>
                  )}
                  {/* Any One */}
                  <Resource name="applicationFlag" />
                  <Resource name="axysCode" />
                  <Resource name="currencyDenominationEnum" />
                  <Resource name="auditContactTypeEnum" />
                  <Resource name="companyEnum" />
                  <Resource name="contactTypeEnum" />
                  <Resource name="countryEnum" />
                  <Resource name="degreeEnum" />
                  <Resource name="documentTypeEnum" />
                  <Resource name="employeeRoleEnum" />
                  <Resource name="investmentFirmEmploymentRoleEnum" />
                  <Resource name="schoolEnum" />
                  <Resource name="stateEnum" />
                  <Resource name="strategyEnum" />
                  <Resource name="titleEnum" />
                  <Resource name="investmentListEnum" />
                  <Resource name="peStrategyEnum" />
                  <Resource name="assetClassEnum" />
                  <Resource name="marketEnum" />
                  <Resource name="vehicleTypeEnum" />
                  <Resource name="geographicFocusEnum" />
                  <Resource name="subStrategyEnum" />
                  <Resource name="investmentStructureEnum" />
                  <Resource name="legalStructureEnum" />
                  <Resource name="organizationStructureEnum" />
                  <Resource name="navClassificationEnum" />
                  <Resource name="pricingFrequencyEnum" />
                  <Resource name="institutionTypeEnum" />
                  <Resource name="institutionSelectionStatusEnum" />
                  <Resource name="institutionInstitutionTypeEnum" />
                  <Resource name="measureTypeEnum" />
                  <Resource name="measureEnum" />
                  <Resource name="deiCategory2Enum" />
                  <Resource name="deiCategory1Enum" />
                  <Resource name="geographyEnum" />
                  <Resource name="peFundNumberEnum" />
                  <Resource name="businessObjectEnum" />
                  <Resource name="resultEnum" />
                  <Resource name="stageEnum" />
                  <Resource name="operatingStatusEnum" />
                  <Resource name="companyTypeEnum" />
                  <Resource name="fundingTypeEnum" />
                  <Resource name="statusEnum" />
                  <Resource name="resultEnum" />
                  <Resource name="dealMarketingEnum" />
                  <Resource name="iconEnum" />
                  {/* everyone */}
                  <Resource name="currentUser" />
                  <Resource name="assumeUser" />
                </>
              )}
            </Admin>
          </ThemeProvider>
        </TermsOfService>
      </RCGApolloProvider>
    </>
  );
};
