import * as client from "_graphql-types";
import * as types from "./types";

type Sdk = ReturnType<typeof client.getSdk>;

async function getOne(sdk: Sdk, params: types.GetOneParams) {
  const id = Number(params.id);

  const { firm: data } = await sdk.getOneFirm({
    id,
  });
  return { data };
}

export const firmFirmDEI = types.dataProvider({
  getOne,
});
