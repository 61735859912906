import * as client from "_graphql-types";
import * as types from "./types";

import { filterSortPage } from "../DataProvider";

type Sdk = ReturnType<typeof client.getSdk>;

/*********************************************************************/
// Data Provider Functions
/*********************************************************************/

/*********************************************************************/
export async function getList(
  sdk: Sdk,
  params: types.GetListParams<client.DealSortEnum>
) {
  const { filter } = params;

  const { dealAvailableFilterOptions: data } =
    await sdk.getListDealFilterOptions({ filter });

  //doesn't feel quite right, perhaps should use apollo client directly?
  return {
    data: [{ id: Date.now(), ...data }],
    total: 1,
  };
}

/*********************************************************************/
export const dealFilterOptions = types.dataProvider({
  getList,
});
