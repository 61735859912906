import { useState } from "react";

import ContactSupport from "@mui/icons-material/ContactSupport";
import IconButton from "@mui/material/IconButton";
import Popover from "@mui/material/Popover";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { AppBar } from "react-admin";
import { CustomNavigation } from "./CustomNavigation";
import { CustomSearchBar } from "./CustomSearchBar";
import { CustomUserMenu } from "./CustomUserMenu";

export const CustomAppBar = () => {
  // const { useDarkTheme, setUseDarkTheme } = useContext(DisplayThemeContext);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const ContactSupportId = open ? "contact-support" : undefined;

  return (
    <AppBar userMenu={<CustomUserMenu />}>
      <div style={{ width: "100%" }}>
        <Toolbar style={{ minHeight: "48px" }}>
          <Typography
            variant="h6"
            color="inherit"
            sx={{
              "& a, & a:link, & a:visited, & a:hover": {
                color: "white",
                fontWeight: "bold",
                fontStyle: "normal",
                textDecoration: "none",
              },
            }}
            id="logo"
          >
            <a href="/">RockCreek</a>
          </Typography>
          <span style={{ width: "24px" }} />
          <Typography
            sx={{
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
            }}
            variant="h6"
            color="inherit"
            id="react-admin-title"
          />
          <span style={{ flex: 1 }} />
          <CustomSearchBar />
          <IconButton
            aria-describedby={ContactSupportId}
            arial-label="Contact External Support"
            title="Contact External Support"
            sx={{ color: "white" }}
            onClick={handleClick}
            size="large"
            color="inherit"
          >
            <ContactSupport />
          </IconButton>
          <Popover
            id={ContactSupportId}
            open={open}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            <div style={{ margin: "1em" }}>
              <strong>Contact Support</strong>
              <p>
                Email:{" "}
                <a href="mailto:portal@therockcreekgroup.com">
                  portal@therockcreekgroup.com
                </a>
              </p>
            </div>
          </Popover>
        </Toolbar>
        <CustomNavigation />
      </div>

      {/* Disabled until we support toggle for antd. 
        https://github.com/ant-design/ant-design-dark-theme/issues/8
      {useDarkTheme ? (
        <IconButton
          arial-label="Display Light Theme"
          title="Display Light Theme"
          className={classes.displayTheme}
          onClick={() => setUseDarkTheme(false)}
        >
          <LightIcon />
        </IconButton>
      ) : (
        <IconButton
          arial-label="Display Dark Theme"
          title="Display Dark Theme"
          className={classes.displayTheme}
          onClick={() => setUseDarkTheme(true)}
        >
          <DarkIcon />
        </IconButton>
      )} */}
    </AppBar>
  );
};
