import React, { Dispatch, SetStateAction, useState, useEffect } from "react";
import * as client from "_graphql-types";
import {
  Grid,
  MenuItem,
  Chip,
  TextField,
  FormControlLabel,
  Box,
  RadioGroup,
  FormLabel,
  Radio,
  OutlinedInput,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import {
  AxisConfiguration,
  isCategoryAxisOptions,
  CategoryAxisOptions,
  getCategoriesFromRows,
  SpreadsheetCategories,
  CustomCategories,
  updateYAxisConfig,
  LayerConfiguration,
  getLabelFormatString,
} from "./helpers";

interface AxisConfigProps {
  axisOptions: AxisConfiguration;
  setAxisOptions: Dispatch<SetStateAction<AxisConfiguration>>;
  headers: string[];
  rows: (string | number | null)[][];
  setChartOptions: Dispatch<SetStateAction<any>>;
  chartOptions: any;
  layerConfig: LayerConfiguration;
}

function CategoryOptions({
  categoryAxisType,
  setCategoryAxisType,
  headers,
  axisOptions,
  setAxisOptions,
  rows,
  chartOptions,
  setChartOptions,
}: {
  categoryAxisType: string | null;
  setCategoryAxisType: Dispatch<SetStateAction<string | null>>;
  setAxisOptions: Dispatch<SetStateAction<AxisConfiguration>>;
  headers: string[];
  axisOptions: AxisConfiguration;
  rows: (string | number | null)[][];
  setChartOptions: Dispatch<SetStateAction<any>>;
  chartOptions: any;
}) {
  function handleCategories(
    event: React.ChangeEvent<{}>,
    value: string[],
    reason: any
  ) {
    const selection = getCategoriesFromRows(value, headers, rows);
    const mainAxisCategories = (axisOptions.mainAxis as CategoryAxisOptions)
      .categories as SpreadsheetCategories | undefined;

    if (reason === "clear") {
      setAxisOptions({
        ...axisOptions,
        mainAxis: {
          ...axisOptions.mainAxis,
          categories: {
            ...(axisOptions.mainAxis as CategoryAxisOptions).categories,
            labels: [],
            dataColumn: "",
          },
        },
      });

      setChartOptions({
        ...chartOptions,
        xAxis: {
          ...chartOptions.xAxis,
          categories: [],
        },
      });
    }

    if (reason === "removeOption") {
      setAxisOptions({
        ...axisOptions,
        mainAxis: {
          ...axisOptions.mainAxis,
          categories: {
            ...(axisOptions.mainAxis as CategoryAxisOptions).categories,
            labels: value,
          },
        },
      });

      setChartOptions({
        ...chartOptions,
        xAxis: {
          ...chartOptions.xAxis,
          categories: value,
        },
      });
    }

    if (reason === "selectOption") {
      if (
        mainAxisCategories?.dataColumn &&
        mainAxisCategories?.labels?.length
      ) {
        setAxisOptions({
          ...axisOptions,
          mainAxis: {
            ...axisOptions.mainAxis,
            categories: {
              ...(axisOptions.mainAxis as CategoryAxisOptions).categories,
              labels: value,
            },
          },
        });

        setChartOptions({
          ...chartOptions,
          xAxis: {
            ...chartOptions.xAxis,
            categories: value,
          },
        });
      } else {
        setAxisOptions({
          ...axisOptions,
          mainAxis: {
            ...axisOptions.mainAxis,
            categories: {
              dataColumn: value[0],
              labels: selection,
            },
          },
        });
        setChartOptions({
          ...chartOptions,
          xAxis: {
            ...chartOptions.xAxis,
            categories: selection,
          },
        });
      }
    }
  }

  function getCategoryLabelOptions(mainAxisCategories?: SpreadsheetCategories) {
    return mainAxisCategories?.dataColumn && mainAxisCategories?.labels?.length
      ? getCategoriesFromRows(
          mainAxisCategories?.dataColumn,
          headers,
          rows
        ).filter(label => !mainAxisCategories?.labels?.includes(label))
      : [...headers];
  }

  return (
    <>
      <Grid container spacing={2} style={{ marginTop: 15 }}>
        <Grid item xs={12}>
          <FormControl variant="standard">
            <FormLabel id="category-options-radio-group-label">
              Category Options
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="category-options-radio-group-label"
              name="radio-buttons-group"
              value={categoryAxisType}
              onChange={e => setCategoryAxisType(e.target.value)}
            >
              <FormControlLabel
                value="pullFromSpreadsheet"
                control={<Radio />}
                label="Pull From Spreadsheet"
              />
              <FormControlLabel
                value="customCategories"
                control={<Radio />}
                label="Custom"
              />
            </RadioGroup>
          </FormControl>
        </Grid>
      </Grid>
      {categoryAxisType === "pullFromSpreadsheet" && (
        <>
          <Grid container spacing={2} style={{ marginTop: 10 }}>
            <Grid item xs={6}>
              <Autocomplete
                multiple
                id="main-axis__categories"
                options={getCategoryLabelOptions(
                  (axisOptions.mainAxis as CategoryAxisOptions)
                    .categories as SpreadsheetCategories
                )}
                value={
                  (
                    (axisOptions.mainAxis as CategoryAxisOptions)
                      .categories as SpreadsheetCategories
                  )?.labels ?? []
                }
                onChange={(e, value, reason) =>
                  handleCategories(e, value as string[], reason)
                }
                renderTags={(value, getTagProps) =>
                  value.map((option, index: number) => (
                    <Chip
                      size="small"
                      variant="outlined"
                      label={option as string}
                      {...getTagProps({ index })}
                    />
                  ))
                }
                renderInput={params => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Categories"
                    helperText="Select single column from spreadsheet"
                  />
                )}
              />
            </Grid>

            {rows.length ? (
              <Grid item xs={4}>
                <FormControl variant="standard" fullWidth>
                  <InputLabel
                    id="main-axis-data-column__label"
                    variant="outlined"
                    shrink
                  >
                    Data Column
                  </InputLabel>
                  <Select
                    readOnly
                    id="main-axis-data-column"
                    labelId="main-axis-data-column__label"
                    label="Data Column"
                    placeholder="Data Column"
                    value={
                      (
                        (axisOptions.mainAxis as CategoryAxisOptions)
                          .categories as SpreadsheetCategories
                      )?.dataColumn ?? null
                    }
                    variant="outlined"
                    onChange={e =>
                      setAxisOptions({
                        ...axisOptions,
                        mainAxis: {
                          ...axisOptions.mainAxis,
                          categories: {
                            ...((axisOptions.mainAxis as CategoryAxisOptions)
                              .categories as SpreadsheetCategories),
                            dataColumn: e.target.value,
                          },
                        },
                      })
                    }
                  >
                    {headers.map(header => (
                      <MenuItem key={header} value={header}>
                        {header}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            ) : null}
          </Grid>
        </>
      )}
      {categoryAxisType === "customCategories" && (
        <>
          <Grid container spacing={2} style={{ marginTop: 10 }}>
            <Grid item xs={6}>
              <Autocomplete
                multiple
                id="main-axis__categories"
                options={[...headers]}
                value={
                  (
                    (axisOptions.mainAxis as CategoryAxisOptions)
                      .categories as SpreadsheetCategories
                  )?.labels ?? []
                }
                freeSolo
                onChange={(e, value, reason) => {
                  setAxisOptions({
                    ...axisOptions,
                    mainAxis: {
                      ...axisOptions.mainAxis,
                      categories: {
                        ...(axisOptions.mainAxis as CategoryAxisOptions)
                          .categories,
                        labels: value,
                      },
                    },
                  });
                  setChartOptions({
                    ...chartOptions,
                    xAxis: {
                      ...chartOptions.xAxis,
                      categories: value,
                    },
                  });
                }}
                renderTags={(value, getTagProps) =>
                  value.map((option, index: number) => (
                    <Chip
                      size="small"
                      variant="outlined"
                      label={option as string}
                      {...getTagProps({ index })}
                    />
                  ))
                }
                renderInput={params => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Categories"
                    helperText="Order must match data columns"
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              {rows.length ? (
                <FormControl variant="standard" fullWidth>
                  <InputLabel
                    id="main-axis-data-column__label"
                    variant="outlined"
                    shrink
                    required
                  >
                    Data Columns
                  </InputLabel>
                  <Select
                    multiple
                    required
                    id="main-axis-data-column"
                    labelId="main-axis-data-column__label"
                    label="Data Column"
                    placeholder="Data Column"
                    value={
                      (
                        (axisOptions.mainAxis as CategoryAxisOptions)
                          .categories as CustomCategories
                      )?.dataColumns ?? []
                    }
                    variant="outlined"
                    onChange={e =>
                      setAxisOptions({
                        ...axisOptions,
                        mainAxis: {
                          ...axisOptions.mainAxis,
                          categories: {
                            ...((axisOptions.mainAxis as CategoryAxisOptions)
                              .categories as CustomCategories),
                            dataColumns:
                              typeof e.target.value === "string"
                                ? e.target.value.split(",")
                                : e.target.value,
                          },
                        },
                      })
                    }
                    input={
                      <OutlinedInput id="select-multiple-chip" label="Chip" />
                    }
                    renderValue={(selected: string[]) => (
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                        {selected.map(value => (
                          <Chip size="small" key={value} label={value} />
                        ))}
                      </Box>
                    )}
                  >
                    {headers.map(header => (
                      <MenuItem key={header} value={header}>
                        {header}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              ) : null}
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
}

export function getDataColumnOptions(
  headers: string[],
  rows: (string | number | null)[][],
  axisType?: Highcharts.AxisTypeValue
) {
  if (axisType === "linear" || axisType === "logarithmic") {
    return headers.filter((header, index) =>
      rows.every(row => !row[index] || typeof row[index] !== "string")
    );
  }
  return headers;
}

function AxisConfig({
  axisOptions,
  setAxisOptions,
  headers,
  rows,
  chartOptions,
  setChartOptions,
  layerConfig,
}: AxisConfigProps) {
  const [categoryAxisType, setCategoryAxisType] = useState<string | null>(
    "pullFromSpreadsheet"
  );

  const [labelFormat, setLabelFormat] = useState<{
    prefix?: string;
    suffix?: string;
  }>({});

  useEffect(() => {
    const formatString = getLabelFormatString(labelFormat);

    updateYAxisConfig(
      { labels: { format: formatString } },
      0,
      layerConfig,
      chartOptions,
      setChartOptions
    );
  }, [labelFormat]);

  return (
    <>
      <Grid item xs={12} style={{ marginTop: 10 }}>
        <h3>Axis Configuration</h3>
        <hr />
      </Grid>
      {chartOptions.chart.type !== "pie" && (
        <>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <h4>Main Axis</h4>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <FormControl variant="standard" fullWidth>
                <InputLabel
                  id="main-axis-type__label"
                  variant="outlined"
                  shrink
                  required
                >
                  Axis Type
                </InputLabel>
                <Select
                  required
                  id="main-axis-type"
                  labelId="main-axis-type__label"
                  label="Axis Type"
                  placeholder="Axis Type"
                  value={axisOptions?.mainAxis.type}
                  variant="outlined"
                  onChange={e => {
                    setAxisOptions({
                      ...axisOptions,
                      mainAxis: {
                        ...axisOptions.mainAxis,
                        type: e.target.value as Highcharts.AxisTypeValue,
                      },
                    });
                    setChartOptions({
                      ...chartOptions,
                      xAxis: { ...chartOptions.xAxis, type: e.target.value },
                    });
                  }}
                >
                  <MenuItem value="category">Category</MenuItem>
                  <MenuItem value="linear">Linear</MenuItem>
                  <MenuItem value="datetime">Datetime</MenuItem>
                  <MenuItem value="logarithmic">Logarithmic</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            {rows.length && !isCategoryAxisOptions(axisOptions.mainAxis) ? (
              <Grid item xs={4}>
                <FormControl variant="standard" fullWidth>
                  <InputLabel
                    id="main-axis-data-column__label"
                    variant="outlined"
                    shrink
                  >
                    Data Column
                  </InputLabel>
                  <Select
                    id="main-axis-data-column"
                    labelId="main-axis-data-column__label"
                    label="Data Column"
                    placeholder="Data Column"
                    value={axisOptions.mainAxis.dataColumn ?? null}
                    variant="outlined"
                    onChange={e =>
                      setAxisOptions({
                        ...axisOptions,
                        mainAxis: {
                          ...axisOptions.mainAxis,
                          dataColumn: e.target.value,
                        },
                      })
                    }
                  >
                    {getDataColumnOptions(
                      headers,
                      rows,
                      axisOptions.mainAxis.type
                    ).map(header => (
                      <MenuItem key={header} value={header}>
                        {header}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            ) : null}
            <Grid item xs={3}>
              <TextField
                fullWidth
                variant="outlined"
                id="main-axis-title"
                label="Axis Title"
                onChange={e => {
                  setChartOptions({
                    ...chartOptions,
                    xAxis: {
                      ...chartOptions.xAxis,
                      title: {
                        text: e.target.value,
                      },
                    },
                  });
                }}
              />
            </Grid>
          </Grid>
          {isCategoryAxisOptions(axisOptions.mainAxis) && (
            <CategoryOptions
              categoryAxisType={categoryAxisType}
              setCategoryAxisType={setCategoryAxisType}
              chartOptions={chartOptions}
              setChartOptions={setChartOptions}
              axisOptions={axisOptions}
              setAxisOptions={setAxisOptions}
              headers={headers}
              rows={rows}
            />
          )}
        </>
      )}
      <Grid item xs={3}>
        <h4>Cross Axis</h4>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <FormControl variant="standard" fullWidth>
            <InputLabel
              id="cross-axis-type__label"
              variant="outlined"
              shrink
              required
            >
              Axis Type
            </InputLabel>
            <Select
              required
              id="cross-axis-type"
              labelId="cross-axis-type__label"
              label="Axis Type"
              placeholder="Axis Type"
              disabled={chartOptions.chart.type === "pie"}
              value={axisOptions.crossAxis.type}
              variant="outlined"
              onChange={e => {
                setAxisOptions({
                  ...axisOptions,
                  crossAxis: {
                    ...axisOptions.crossAxis,
                    type: e.target.value as Highcharts.AxisTypeValue,
                  },
                });
                const value = e.target.value as Highcharts.AxisTypeValue;
                updateYAxisConfig(
                  { type: value },
                  0,
                  layerConfig,
                  chartOptions,
                  setChartOptions
                );
              }}
            >
              <MenuItem value="linear">Linear</MenuItem>
              <MenuItem value="datetime">Datetime</MenuItem>
              <MenuItem value="logarithmic">Logarithmic</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        {rows.length && categoryAxisType !== "customCategories" ? (
          <Grid item xs={4} style={{ alignSelf: "center" }}>
            <FormControl variant="standard" fullWidth>
              <InputLabel
                id="cross-axis-data-column__label"
                variant="outlined"
                shrink
                required
              >
                Data Column
              </InputLabel>
              <Select
                required
                id="cross-axis-data-column"
                labelId="cross-axis-data-column__label"
                label="Data Column"
                placeholder="Data Column"
                value={axisOptions.crossAxis?.dataColumn ?? ""}
                variant="outlined"
                onChange={e =>
                  setAxisOptions({
                    ...axisOptions,
                    crossAxis: {
                      ...axisOptions.crossAxis,
                      dataColumn: e.target.value,
                    },
                  })
                }
              >
                {getDataColumnOptions(
                  headers,
                  rows,
                  axisOptions.crossAxis.type
                ).map(header => (
                  <MenuItem key={header} value={header}>
                    {header}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        ) : null}
        <Grid item xs={3}>
          <TextField
            fullWidth
            variant="outlined"
            id="cross-axis-title"
            label="Axis Title"
            onChange={e =>
              updateYAxisConfig(
                {
                  title: {
                    text: e.target.value,
                  },
                },
                0,
                layerConfig,
                chartOptions,
                setChartOptions
              )
            }
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} style={{ marginTop: 10 }}>
        <Grid item xs={4}>
          <TextField
            fullWidth
            variant="outlined"
            id="cross-axis-datalabel-prefix"
            label="Datalabel Prefix (eg. $)"
            onChange={e =>
              setLabelFormat({ ...labelFormat, prefix: e.target.value })
            }
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            fullWidth
            variant="outlined"
            id="cross-axis-datalabel-suffix"
            label="Datalabel Suffix (eg. km, b)"
            onChange={e =>
              setLabelFormat({ ...labelFormat, suffix: e.target.value })
            }
          />
        </Grid>
      </Grid>
    </>
  );
}

export default AxisConfig;
