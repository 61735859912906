import { useLazyQuery, useMutation } from "@apollo/client";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import { Tooltip } from "@mui/material";
import {
  AddTempFilesMutation,
  ProfileBuilderQuery,
  TempFileUpload,
} from "_graphql-types-frontend/graphql";
import { Button, notification } from "antd";
import {
  ADD_TEMP_FILES,
  ADD_USER_ACTIITY as ADD_USER_ACTIVITY,
  BUILD_INVESTMENT_PROFILE,
} from "frontend/src/utils/graphql";
import { useCurrentUser } from "frontend/src/utils/hooks";
import { ChangeEvent, useState } from "react";
import { useFormContext } from "react-hook-form";
import { TFormHistory } from "./TrackChanges";

export type TempFile = {
  file?: File;
  filename?: string;
  key?: string;
  bucket?: string;
  suggestions?: AddTempFilesMutation["addTempFiles"][0]["suggestions"];
};

interface ProfileBuilderButtonProps {
  response?: ProfileBuilderQuery["investmentProfileBuilder"];
  setResponse: (
    response: ProfileBuilderQuery["investmentProfileBuilder"]
  ) => void;
  tempFiles?: TempFile[];
  setTempFiles: (tempFiles: TempFile[]) => void;
  setFieldHistory?: (values: TFormHistory) => void;
}

export function ProfileBuilderButton({
  setResponse,
  setTempFiles,
  tempFiles,
  setFieldHistory,
}: ProfileBuilderButtonProps) {
  const currentUser = useCurrentUser();
  const { getValues } = useFormContext();

  const [buildInvestmentProfile] = useLazyQuery(BUILD_INVESTMENT_PROFILE, {
    fetchPolicy: "no-cache",
  });
  const [addTempFiles] = useMutation(ADD_TEMP_FILES, {
    fetchPolicy: "no-cache",
  });
  const [addUserActivity] = useMutation(ADD_USER_ACTIVITY, {
    fetchPolicy: "no-cache",
  });
  const [loading, setLoading] = useState(false);

  async function fetchInvestmentProfile(e: ChangeEvent<HTMLInputElement>) {
    const { files } = e.target;
    if (!files?.length) return;

    setLoading(true);
    try {
      const newTempFiles: TempFile[] = Array.from(files).map(file => ({
        file,
        filename: file.name,
      }));
      setTempFiles(newTempFiles);
      console.log("NEW TEMP FILES 1 ->", newTempFiles);

      const addTemp = await addTempFiles({
        variables: {
          input: newTempFiles.map(({ file }) => ({
            file,
          })) as TempFileUpload[],
        },
      });
      if (addTemp.errors?.length) throw addTemp.errors;
      if (!addTemp.data) throw Error("No temp file data");

      const addedFiles = addTemp.data.addTempFiles.map(
        ({ filename, bucket, key, suggestions }) => {
          const newTempFile = newTempFiles.find(x => x.filename === filename);
          if (!newTempFile) throw Error("Temp file not found");
          return { ...newTempFile, key, bucket, suggestions };
        }
      );
      setTempFiles(addedFiles);
      console.log("NEW TEMP FILES 2 ->", addedFiles);

      if (setFieldHistory) {
        setFieldHistory(getValues());
      }

      // side effect
      addUserActivity({
        variables: {
          input: {
            email: currentUser?.email || currentUser.userName,
            requestUtc: new Date().toISOString(),
            requestUri: "InvestmentProfileBuilder",
            applicationId: 402,
          },
        },
      });

      const [{ bucket }] = addedFiles;
      const keys = addedFiles.map(({ key }) => key);

      const { data, error } = await buildInvestmentProfile({
        variables: { bucket, keys },
      });
      if (error) throw error;
      if (!data) throw Error("No investment profile data");

      console.log("INVESTMENT PROFILE ->", data.investmentProfileBuilder);
      setResponse(data.investmentProfileBuilder);
    } catch (error) {
      console.log(error);
      notification.error({ message: "Something went wrong" });
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <Tooltip title="Populate from file(s)">
        <Button disabled={!!tempFiles?.length} type="primary" loading={loading}>
          <label>
            <AutoAwesomeIcon />
            <input
              data-id="factSheetLLMUpload"
              data-cy="factSheetLLMUpload"
              type="file"
              accept=".pdf"
              multiple={true}
              style={{ display: "none" }}
              onChange={fetchInvestmentProfile}
              disabled={loading || !!tempFiles?.length}
            />
          </label>
        </Button>
      </Tooltip>
    </>
  );
}
