import { Grid } from "@mui/material";
import * as client from "_graphql-types";
import { CustomEdit } from "../CustomEdit";

import {
  BooleanInput,
  FormDataConsumer,
  ReferenceInput,
  SelectInput,
  TextInput,
} from "react-admin";
import { EntityInput } from "../UI/EntityInput";
import { withForm } from "../react-admin-fixes";

type Record = NonNullable<client.GetOnePortalFirmQuery["portalFirm"]>;

export const PortalFirmEdit = () => {
  return (
    <CustomEdit<Record>
      title={record => (record ? `PortalFirm ${record.name || ""}` : null)}
    >
      <FormDataConsumer>
        {withForm<Record>(({ formData }) => (
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextInput label="Name" source="name" />
            </Grid>
            <Grid item xs={6}>
              <ReferenceInput
                source="firmTypeEnumId"
                reference="portalFirmTypeEnum"
              >
                <SelectInput optionText="name" label="Firm Type" />
              </ReferenceInput>
            </Grid>
            <Grid item xs={6}>
              <EntityInput
                label="Organization"
                resource="portalOrganization"
                sourceKey="organizationId"
                recordKey="organization"
                isRequired
                allowChange
              />
            </Grid>
            <Grid item xs={6}></Grid>
            <Grid item xs={8}>
              <EntityInput
                label="Firm"
                resource="firm"
                sourceKey="rcgFirmId"
                recordKey="rcgFirm"
                isRequired
                allowChange
              />
              {formData.rcgFirmId && <div>Firm Id: {formData.rcgFirmId}</div>}
            </Grid>
            <Grid item xs={4}>
              <BooleanInput
                source="shouldUpdate"
                label="Sync to Diligence"
                disabled={!formData.rcgFirmId}
              />
            </Grid>
          </Grid>
        ))}
      </FormDataConsumer>
    </CustomEdit>
  );
};
