import { useInput, useDataProvider } from "react-admin";
import { Grid, TextField, Autocomplete } from "@mui/material";
import { useState, useEffect } from "react";
import * as client from "_graphql-types";
import { useDebouncedCallback } from "use-debounce";

function UserSelectionInput(props: { source: string }) {
  const input = useInput({ source: props.source });
  const {
    field: { onChange, value },
  } = input;

  const dataProvider = useDataProvider();

  const [orgChoices, setOrgChoices] = useState<client.Organization[]>([]);
  const [orgId, setOrgId] = useState<number | null>(null);
  const [userChoices, setUserChoices] = useState<client.User[]>([]);

  useEffect(() => {
    let retries = 0;
    async function fetchUser() {
      const choices = await dataProvider.getOne<client.User>("user", {
        id: value,
      });

      // For some reason choices is coming back null, Perhaps a bug in react admin?
      // I see no reason that our side of the code would return null. Probably another
      // race condition in react admin?
      if (!choices && retries < 3) {
        retries++;
        await fetchUser();
        return;
      }

      setUserChoices([choices.data]);
    }

    if (value) {
      fetchUser();
    }
  }, []);

  const setUserOptionsDebounced = useDebouncedCallback(
    async ({
      q,
      organizationId = orgId,
    }: {
      q?: string;
      organizationId?: number | null;
    }) => {
      const userList = await dataProvider.getList<client.User>("user", {
        pagination: { page: 1, perPage: 25 },
        filter: {
          q,
          isUser: true,
          ...(organizationId && { organizationId: Number(organizationId) }),
        },
        sort: { field: "name", order: "ASC" },
      });
      setUserChoices(userList?.data);
    },
    300
  );

  const setOrgOptionsDebounced = useDebouncedCallback(async (q: string) => {
    const orgList = await dataProvider.getList<client.Organization>(
      "organization",
      {
        pagination: { page: 1, perPage: 25 },
        sort: {
          field: "name",
          order: "ASC",
        },
        filter: { q },
      }
    );
    setOrgChoices(orgList?.data);
  }, 300);

  function handleChange(value: client.Organization | null, reason: any) {
    if (reason === "clear") {
      setOrgId(null);
      setUserOptionsDebounced({ organizationId: null });
    }
    if (value) {
      setOrgId(value.id);
      setUserOptionsDebounced({ organizationId: value.id });
    }
  }

  return (
    <>
      <Grid item xs={12} lg={6}>
        <Autocomplete
          fullWidth
          id="assumeUser__organization-input"
          options={orgChoices}
          filterSelectedOptions
          autoComplete={false}
          value={
            userChoices.find(choice => choice.id === value)?.organization ??
            orgChoices.find(choice => choice.id === orgId) ??
            null
          }
          getOptionLabel={option => option.name ?? "(missing option.name)"}
          isOptionEqualToValue={(option, value) => value.id === option.id}
          renderInput={params => (
            <TextField
              {...params}
              name="organization"
              label="Organization"
              variant="outlined"
              inputProps={{
                ...params.inputProps,
                autoComplete: "new",
              }}
            />
          )}
          onChange={(event, value, reason) => {
            handleChange(value, reason);
          }}
          onInputChange={(event, value, reason) => {
            switch (reason) {
              case "input":
                setOrgOptionsDebounced(value);
                break;
              case "clear":
                setOrgChoices([]);
                break;
            }
          }}
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <Autocomplete
          fullWidth
          id="assumeUser__user-input"
          options={userChoices}
          filterSelectedOptions
          autoComplete={false}
          value={userChoices.find(choice => choice.id === value) ?? null}
          getOptionLabel={option =>
            option.commonName ?? "(missing option.name)"
          }
          isOptionEqualToValue={(option, value) => value.id === option.id}
          renderInput={params => (
            <TextField
              {...params}
              name="user"
              label="User"
              variant="outlined"
              inputProps={{
                ...params.inputProps,
                autoComplete: "new",
              }}
            />
          )}
          onInputChange={(event, value, reason) => {
            switch (reason) {
              case "input":
                setUserOptionsDebounced({ q: value });
                break;
              case "clear":
                setUserChoices([]);
                break;
            }
          }}
          onChange={(event, value, reason) => {
            onChange(value?.id);
          }}
        />
      </Grid>
    </>
  );
}

export default UserSelectionInput;
