import { TableCell, TableRow } from "@mui/material";
import { MtdRec } from "../Edit";
import { yearToDate } from "../helpers";
import { MonthInput } from "./MonthInput";
import { Headers } from "./index";

interface Props {
  now: Date;
  year: number;
  filteredMonths: MtdRec[];
  addChangedRecord: (count: number, year: number, value: string) => void;
  selectMonthDetail: (returnMonth: number, returnYear: number) => void;
  canEdit: boolean;
}

export const Year = (props: Props) => {
  const filteredMonths = props.filteredMonths.sort(
    (a: any, b: any) => b.returnMonth - a.returnMonth
  );
  const { now, year, selectMonthDetail, addChangedRecord, canEdit } = props;

  const ytd = yearToDate(filteredMonths.map((month: any) => month.return));

  return (
    <TableRow>
      <TableCell
        sx={{ padding: 0, textAlign: "center" }}
        size="small"
        variant="head"
      >
        {year}
      </TableCell>
      {Headers.slice(1, 13).map((month, i) => {
        const currentMonth = filteredMonths.find(
          (rec: any) => rec.returnMonth === i + 1
        );
        const disabled =
          year > now.getFullYear() ||
          (year >= now.getFullYear() && i + 1 > now.getMonth() + 1);

        return (
          <TableCell
            key={i}
            sx={{ padding: 0, textAlign: "center" }}
            size="small"
            style={{
              borderLeft: "solid 1px #FFF",
              borderRight: "solid 1px #FFF",
              padding: 0,
              margin: 0,
            }}
          >
            {disabled ? null : (
              <MonthInput
                year={year}
                month={i}
                sx={{ fontSize: 12, margin: 0, textAlign: "center" }}
                returnValue={currentMonth?.return}
                onChange={e => {
                  addChangedRecord(i + 1, year, e.target.value);
                }}
                onFocus={() => {
                  selectMonthDetail(i + 1, year);
                }}
                canEdit={canEdit}
              />
            )}
          </TableCell>
        );
      })}
      <TableCell
        variant="head"
        style={{ textAlign: "right", color: ytd < 0 ? "red" : "initial" }}
      >
        {ytd.toFixed(2)}
      </TableCell>
    </TableRow>
  );
};
