import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Collapse,
  Grid,
  IconButton,
} from "@mui/material";
import * as client from "_graphql-types";
import { endOfMonth, format, parseISO, startOfMonth } from "date-fns";
import { ArrayInput, SimpleFormIterator } from "ra-ui-materialui";
import { useMemo, useState } from "react";
import {
  DateInput,
  FormDataConsumer,
  NumberInput,
  useDataProvider,
  useNotify,
} from "react-admin";
import { DeepPartial, useFormContext } from "react-hook-form";
import {
  ValidationOf,
  pruneErrors,
  withForm,
  withScopedForm,
} from "../react-admin-fixes";
import { IdInvestmentInput } from "./IdInvestmentInput";
import { Preview } from "./Preview";

export type Record = NonNullable<
  client.GetOneCustomIndexInvestmentQuery["customIndexInvestment"]
> & { outputInvestmentId?: number };

export const NBSP = "\xa0";

const tryParseISO = (
  value?: string | null,
  onfail?: (error: unknown) => void
) => {
  if (!(value ??= "")) return;
  try {
    return parseISO(value);
  } catch (error) {
    onfail?.(error);
  }
};

const selectOnFocus = (
  e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
) => {
  e.target.select();
};

export const validateForm =
  (create: boolean) => (form: DeepPartial<Record>) => {
    const errors: ValidationOf<Record> = {};
    if (create) {
      if (!form.outputInvestmentId) {
        errors.outputInvestmentId = "Required";
      }
    }
    errors.groups = {
      items: !form.groups?.items?.length
        ? "Required"
        : form.groups.items.map(group => {
            const errors: ValidationOf<typeof group> = {};

            const geReturnDate =
              tryParseISO(group?.geReturnDate, () => {
                errors.geReturnDate = "Invalid start date";
              })?.getTime() ?? Number.MIN_SAFE_INTEGER;
            const leReturnDate =
              tryParseISO(group?.leReturnDate, () => {
                errors.leReturnDate = "Invalid end date";
              })?.getTime() ?? Number.MAX_SAFE_INTEGER;
            if (!(geReturnDate < leReturnDate))
              errors.geReturnDate = "Must precede end date";

            const inputInvestmentIds = new Set<number>();
            errors.terms = {
              items: !group?.terms?.items?.length
                ? "Required"
                : group.terms.items.map(term => {
                    const errors: ValidationOf<typeof term> = {};
                    if (!term?.inputInvestmentId)
                      errors.inputInvestmentId = "Required";
                    else {
                      if (inputInvestmentIds.has(term.inputInvestmentId))
                        errors.inputInvestmentId =
                          "Cannot duplicate investment within a date range";
                      inputInvestmentIds.add(term.inputInvestmentId);
                    }
                    if (
                      typeof term?.laggedMonths === "number" &&
                      term.laggedMonths < 0
                    ) {
                      errors.laggedMonths = "Must be non-negative";
                    }
                    return errors;
                  }),
            };
            return errors;
          }),
    };
    const prunedErrors = pruneErrors(errors);
    return prunedErrors;
  };

export const CustomIndexInvestmentForm = () => {
  const form = useFormContext();
  const notify = useNotify();
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <ArrayInput source="groups.items" label="Date Ranges" data-cy="groups">
          <SimpleFormIterator fullWidth>
            <FormDataConsumer>
              {withForm<Record, Record["groups"]["items"][number]>(
                ({ formData, scopedFormData: groupData }) => {
                  const dataProvider = useDataProvider();
                  const [perfReply, setPerfReply] =
                    useState<client.PerformanceMtd[]>();
                  const perfClean = useMemo(() => {
                    if (!perfReply?.length) return;
                    const years = perfReply.map(x => x.returnYear);
                    const minYear = Math.min(...years);
                    const maxYear = Math.max(...years);
                    return {
                      years: Array.from(
                        new Array(maxYear - minYear + 1),
                        (_, i) => maxYear - i
                      ),
                      perfs: new Map(
                        perfReply.map(x => [
                          String([x.returnYear, x.returnMonth]),
                          x.return,
                        ])
                      ),
                    };
                  }, [perfReply]);

                  return (
                    <>
                      <Accordion style={{ width: "100%" }} data-cy="group">
                        <AccordionSummary
                          title="Expand/Collapse"
                          expandIcon={<ExpandMoreIcon data-cy="expand" />}
                          style={{
                            flexDirection: "row-reverse",
                          }}
                        >
                          <Grid container>
                            <Grid item xs={1}>
                              {
                                // space
                              }
                            </Grid>
                            <Grid
                              item
                              xs={4}
                              display="flex"
                              justifyContent="center"
                              alignItems="center"
                              title=""
                            >
                              <DateInput
                                source="geReturnDate"
                                parse={value => {
                                  const date =
                                    typeof value === "string" && value
                                      ? parseISO(value)
                                      : value instanceof Date
                                      ? value
                                      : void 0;
                                  if (!date) return;
                                  return format(
                                    startOfMonth(date),
                                    "yyyy-MM-dd"
                                  );
                                }}
                                label="Start (inclusive)"
                                onClick={e => void e.stopPropagation()}
                                data-cy="geReturnDate"
                              />
                            </Grid>
                            <Grid
                              item
                              xs={1}
                              display="flex"
                              flexDirection="column"
                              justifyContent="center"
                              alignItems="center"
                            >
                              <div>&mdash;</div>
                              <div>&nbsp;</div>
                            </Grid>
                            <Grid
                              item
                              xs={4}
                              display="flex"
                              justifyContent="center"
                              alignItems="center"
                              title=""
                            >
                              <DateInput
                                source="leReturnDate"
                                parse={value => {
                                  const date =
                                    typeof value === "string" && value
                                      ? parseISO(value)
                                      : value instanceof Date
                                      ? value
                                      : void 0;
                                  if (!date) return;
                                  return format(endOfMonth(date), "yyyy-MM-dd");
                                }}
                                label="End (inclusive)"
                                onClick={e => void e.stopPropagation()}
                                data-cy="leReturnDate"
                              />
                            </Grid>
                            <Grid
                              item
                              xs={2}
                              display="flex"
                              flexDirection="column"
                              justifyContent="center"
                              alignItems="flex-end"
                              title=""
                            >
                              {perfReply ? (
                                <IconButton
                                  data-cy="hide-preview"
                                  title="Hide Preview"
                                  onClick={e => {
                                    e.stopPropagation();
                                    setPerfReply(void 0);
                                  }}
                                >
                                  <VisibilityOffIcon />
                                </IconButton>
                              ) : (
                                <IconButton
                                  data-cy="show-preview"
                                  title="Show Preview"
                                  onClick={async e => {
                                    e.stopPropagation();
                                    const valid = await form.trigger();
                                    if (!valid) {
                                      notify(
                                        "Cannot preview: The form is not valid.",
                                        { type: "error" }
                                      );
                                      return;
                                    }
                                    const meta: client.CustomIndexInvestmentInput =
                                      {
                                        outputInvestmentId:
                                          formData.outputInvestmentId ?? // create
                                          formData.id, // edit
                                        groups: [
                                          groupData as DeepPartial<client.CustomIndexGroup>,
                                        ].map(group => ({
                                          geReturnDate: group!.geReturnDate,
                                          leReturnDate: group!.leReturnDate,
                                          terms: group!.terms!.items!.map(
                                            (
                                              term
                                            ): client.CustomIndexTermInput => ({
                                              inputInvestmentId:
                                                term!.inputInvestmentId!,
                                              additionFactor:
                                                term!.additionFactor,
                                              additionFactorAfter:
                                                term!.additionFactorAfter,
                                              laggedMonths: term!.laggedMonths,
                                              multiplierFactor:
                                                term!.multiplierFactor,
                                            })
                                          ),
                                        })),
                                      };
                                    const {
                                      data: { meta: perfs },
                                    } = await dataProvider.getOne<{
                                      id: 0;
                                      meta: client.PerformanceMtd[];
                                    }>("customIndexPreview", {
                                      id: 0,
                                      meta,
                                    });
                                    setPerfReply(perfs);
                                  }}
                                >
                                  <VisibilityIcon />
                                </IconButton>
                              )}
                            </Grid>
                          </Grid>
                        </AccordionSummary>
                        <AccordionDetails style={{ width: "100%" }}>
                          <ArrayInput
                            source="terms.items"
                            label="Constituents"
                            data-cy="terms"
                          >
                            <SimpleFormIterator>
                              <FormDataConsumer>
                                {withScopedForm<
                                  Record["groups"]["items"][number]["terms"]["items"][number]
                                >(() => (
                                  <Grid
                                    container
                                    spacing={0}
                                    marginTop="16px"
                                    data-cy="term"
                                  >
                                    <Grid
                                      item
                                      xs={0.5}
                                      display="flex"
                                      flexDirection="column"
                                      justifyContent="center"
                                      alignItems="center"
                                    >
                                      <div>[&nbsp;(</div>
                                      <div>&nbsp;</div>
                                    </Grid>
                                    <Grid
                                      item
                                      xs={3.5}
                                      style={{ whiteSpace: "nowrap" }}
                                      display="flex"
                                      justifyContent="stretch"
                                      alignItems="center"
                                    >
                                      <IdInvestmentInput
                                        source="inputInvestmentId"
                                        textFieldProps={{
                                          size: "small",
                                          helperText: NBSP,
                                          "data-cy": "inputInvestmentId",
                                        }}
                                        label="Investment ID"
                                        isRequired
                                      />
                                    </Grid>
                                    <Grid
                                      item
                                      xs={1.5}
                                      display="flex"
                                      justifyContent="center"
                                      alignItems="center"
                                    >
                                      <NumberInput
                                        source="laggedMonths"
                                        placeholder="0"
                                        defaultValue={0}
                                        label="Lag (mos.)"
                                        data-cy="laggedMonths"
                                        onFocus={selectOnFocus}
                                      />
                                    </Grid>
                                    <Grid
                                      item
                                      xs={0.5}
                                      display="flex"
                                      flexDirection="column"
                                      justifyContent="center"
                                      alignItems="center"
                                    >
                                      <div>)&nbsp;+</div>
                                      <div>&nbsp;</div>
                                    </Grid>
                                    <Grid
                                      item
                                      xs={1.5}
                                      display="flex"
                                      justifyContent="center"
                                      alignItems="center"
                                    >
                                      <NumberInput
                                        source="additionFactor"
                                        placeholder="0"
                                        defaultValue={0}
                                        label="Add"
                                        data-cy="additionFactor"
                                        onFocus={selectOnFocus}
                                      />
                                    </Grid>
                                    <Grid
                                      item
                                      xs={0.5}
                                      display="flex"
                                      flexDirection="column"
                                      justifyContent="center"
                                      alignItems="center"
                                    >
                                      <div>]&nbsp;&times;</div>
                                      <div>&nbsp;</div>
                                    </Grid>
                                    <Grid
                                      item
                                      xs={1.5}
                                      display="flex"
                                      justifyContent="center"
                                      alignItems="center"
                                    >
                                      <NumberInput
                                        source="multiplierFactor"
                                        defaultValue={1}
                                        label="Multiply"
                                        data-cy="multiplierFactor"
                                        onFocus={selectOnFocus}
                                      />
                                    </Grid>
                                    <Grid
                                      item
                                      xs={0.5}
                                      display="flex"
                                      flexDirection="column"
                                      justifyContent="center"
                                      alignItems="center"
                                    >
                                      <div>+&nbsp;</div>
                                      <div>&nbsp;</div>
                                    </Grid>
                                    <Grid
                                      item
                                      xs={1.5}
                                      display="flex"
                                      justifyContent="center"
                                      alignItems="center"
                                    >
                                      <NumberInput
                                        source="additionFactorAfter"
                                        placeholder="0"
                                        defaultValue={0}
                                        label="Post Add"
                                        data-cy="additionFactorAfter"
                                        onFocus={selectOnFocus}
                                      />
                                    </Grid>
                                    <Grid
                                      item
                                      xs={0.5}
                                      display="flex"
                                      flexDirection="column"
                                      justifyContent="center"
                                      alignItems="center"
                                    >
                                      <div>+&nbsp;</div>
                                      <div>&nbsp;</div>
                                    </Grid>
                                  </Grid>
                                ))}
                              </FormDataConsumer>
                            </SimpleFormIterator>
                          </ArrayInput>
                        </AccordionDetails>
                      </Accordion>
                      {
                        <Collapse in={!!perfReply} style={{ width: "100%" }}>
                          <Preview
                            geReturnDate={groupData?.geReturnDate}
                            leReturnDate={groupData?.leReturnDate}
                            perfClean={perfClean}
                          />
                        </Collapse>
                      }
                    </>
                  );
                }
              )}
            </FormDataConsumer>
          </SimpleFormIterator>
        </ArrayInput>
      </Grid>
    </Grid>
  );
};
