import { Identifier } from "react-admin";
import * as client from "_graphql-types";
import * as types from "./types";

type Sdk = ReturnType<typeof client.getSdk>;

function ok(record?: null | number) {
  return record !== null && record !== undefined;
}

type PerformanceIdentifierObject = {
  investmentId: Identifier;
  period: "Quarterly" | "MTD";
  returnType: client.ReturnTypes;
};

export function deserializePerformanceId(
  identifier: Identifier
): PerformanceIdentifierObject {
  if (typeof identifier == "number")
    throw new Error("identifier should not be number for performance");

  const [investmentId, period, returnType] = identifier.split("::");
  if (!["MTD", "Quarterly"].includes(period)) {
    throw new Error(
      "improperly serialized period performance data:  " + identifier
    );
  }

  if (
    !Object.keys(client.ReturnTypes)
      .map(s => s.toUpperCase())
      .includes(returnType)
  ) {
    throw new Error(
      "improperly serialized returnType performance data:  " + identifier
    );
  }

  return {
    investmentId: Number(investmentId),
    period: period as "MTD" | "Quarterly",
    returnType: returnType as client.ReturnTypes,
  };
}

export function serializePerformanceId(
  identifierObject: PerformanceIdentifierObject
): Identifier {
  return `${identifierObject.investmentId}::${identifierObject.period}::${identifierObject.returnType}`;
}

/*********************************************************************/
// Data Provider Functions
/*********************************************************************/

/*********************************************************************/
async function getOne(
  sdk: Sdk,
  params: {
    id: Identifier;
  }
) {
  if (`${params.id}`.split("::").length === 1) {
    const { investment: data } = await sdk.performanceBackground({
      investmentId: Number(params.id),
    });

    return { data: { ...data, id: params.id } };
  }
  const { investmentId, period, returnType } = deserializePerformanceId(
    params.id
  );

  if (period === "Quarterly") {
    const { investment: data } = await sdk.getOneInvestmentPerformanceQuarterly(
      {
        investmentId: Number(investmentId),
        priorTrackRecord: true,
        returnType: returnType,
      }
    );
    return { data: { ...data, id: params.id } };
  } else {
    const { investment: data } = await sdk.getOneInvestmentPerformanceMTD({
      investmentId: Number(investmentId),
      priorTrackRecord: true,
      returnType: returnType,
    });

    return { data: { ...data, id: params.id } };
  }
}

/*********************************************************************/
async function update(sdk: Sdk, params: any) {
  const { data } = params;
  const { investmentId, newRecords, returnType, period, currencyId } = data;

  if (period === "Quarterly") {
    await sdk.bulkUpdatePerformanceQuarterly({
      investmentId: Number(investmentId),
      returnType,
      quarters: newRecords.map((record: any) => ({
        returnYear: Number(record.returnYear),
        returnQuarter: Number(record.returnQuarter),
        fundReturn: ok(record.return) ? Number(record.return) : null,
      })),
    });
  } else if (period === "MTD") {
    await sdk.bulkUpdatePerformanceMTD({
      investmentId: Number(investmentId),
      returnType,
      currencyId: Number(currencyId),
      months: newRecords.map((record: any) => ({
        returnYear: Number(record.returnYear),
        returnMonth: Number(record.returnMonth),
        fundReturn: ok(record.return) ? Number(record.return) : null,
        asOfDate: record.asOfDate ? new Date(record.asOfDate) : undefined,
        performanceCommentary: record.performanceCommentary,
      })),
    });
  }

  return await getOne(sdk, {
    id: serializePerformanceId({
      investmentId,
      returnType,
      period,
    }),
  });
}

/*********************************************************************/
export const performance = types.dataProvider({
  getOne,
  update,
});
