import DialogContentText from "@mui/material/DialogContentText";
import * as client from "_graphql-types";
import { TouchedState } from "../CustomForm";
const CONFIRM_DATE =
  'Is this an update to your DEI information?  If so please update the "As Of Date".  If not, click "continue" to save this correction to your existing data.';

const CONFIRM_COMPLETE =
  'Have you entered all available data for the selected date? If so please mark the form as completed. If not, click "continue" to save your data as incomplete';

type Record = client.GetOneFirmDeiQuery["firmDEI"];

export function getFirmDEIConfirmationMessage({
  saveParams,
  touched,
}: {
  saveParams?: Record;
  touched?: TouchedState<Record>;
}): React.ReactNode {
  if (!touched?.asOfDate && !saveParams?.isCompleted) {
    return (
      <>
        <DialogContentText>{CONFIRM_DATE}</DialogContentText>
        <br />
        <DialogContentText>{CONFIRM_COMPLETE}</DialogContentText>
      </>
    );
  } else if (!touched?.asOfDate && saveParams?.isCompleted) {
    return <DialogContentText>{CONFIRM_DATE}</DialogContentText>;
  } else if (!saveParams?.isCompleted && touched?.asOfDate) {
    return <DialogContentText>{CONFIRM_COMPLETE}</DialogContentText>;
  }
}
