import * as client from "_graphql-types";
import * as types from "./types";

type Sdk = ReturnType<typeof client.getSdk>;

/*********************************************************************/
// Data Provider Functions
/*********************************************************************/

/*********************************************************************/
async function getMany(sdk: Sdk, params: types.GetManyParams) {
  const ids = params.ids.map(id => Number(id));
  const endDate = params?.meta?.endDate;

  const data = (
    await sdk.getManyPerformanceStatistics({
      ids,
      endDate,
    })
  ).investmentMany.flatMap(i => i?.performanceStats || []);

  return {
    data,
  };
}

/*********************************************************************/
async function getOne(sdk: Sdk, params: types.GetOneParams) {
  const id = Number(params.id);
  const { dependentIds, asOfDate } = params?.meta;

  const { investment: data } = await sdk.getOnePerformanceStatistics({
    id,
    dependentIds,
    asOfDate,
  });
  return { data };
}

/*********************************************************************/
export const performanceStatistics = types.dataProvider({
  getOne,
  getMany,
});
