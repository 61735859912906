import {
  BooleanField,
  Datagrid,
  EditButton,
  List,
  TextField,
} from "react-admin";

import { FirmDeiFilter } from "./Filter";
import CustomDeleteButton from "../CustomDeleteButton";
import {useCanAccessMutation} from "../../util/useCanAccessMutation";

export const FirmDEIList = (props: any) => {
  const { canEdit, loading, canEditField } = useCanAccessMutation("deleteFirmDei", JSON.stringify({}));
  return (
    <List
      sx={{
        ".RaFilter-form": {
          flex: "0 1 50%",
        },
      }}
      filters={<FirmDeiFilter />}
      sort={{ field: "asOfDate", order: "DESC" }}
      {...props}
    >
      <Datagrid
        rowClick="edit"
        data-cy="firmDEI-datagrid"
        bulkActionButtons={false}
      >
        <TextField label="Firm" source="firm.name" sortable={false} />
        <TextField
          label="Geography"
          source="geographyEnum.description"
          sortable={false}
        />
        <TextField label="As Of Date" source="asOfDate" />
        <BooleanField label="Completed" source="isCompleted" sortable={false} />
        <EditButton data-id="firmDEI-edit" />
        <CustomDeleteButton disabled={!canEdit}/>
      </Datagrid>
    </List>
  )};
