import React, { useState } from "react";
import {
  Tooltip,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import HelpIcon from "@mui/icons-material/Help";
import Divider from "@mui/material/Divider";
import InfoIcon from "@mui/icons-material/Info";

function LiquidityDialog() {
  const [open, setOpen] = useState<boolean>(false);
  const handleClickOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <>
      <IconButton aria-label="help" onClick={handleClickOpen}>
        <Tooltip title="Help">
          <InfoIcon />
        </Tooltip>
      </IconButton>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Liquidity Help</DialogTitle>
        <DialogContent>
          <LiquidityHelp />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

function LiquidityHelp() {
  return (
    <>
      <p>
        The Liquidity parameters determine the dates on which redemptions are
        possible, relative to an investment date.&nbsp; Consider a timeline on
        which the investment date and possible redemption dates are
        marked.&nbsp; The date of the first possible redemption depends on three
        parameters: investment date, lockup period, and calendar quantization of
        the redemption.&nbsp; Optionally, the date of the second possible
        redemption depends on two parameters:
      </p>
      <ol className="alpha indent-5">
        <li>first possible redemption date</li>
        <li>lockup period or calendar quantization of the redemption</li>
      </ol>
      <p>
        After these dates, an infinite sequence of possible redemption dates is
        determined by repeating the liquidity period.
      </p>
      <p>
        To determine the next possible redemption date on or after a given date,
        the system finds the given date on the timeline, then moves forward the
        length of the notice period and finds the next possible redemption date
        as marked on the timeline.&nbsp; Note that a possible redemption date
        marked on the timeline may be passed over, in order to satisfy the
        required notice period.
      </p>
      <p>
        <strong>
          Has Lockup?
          <br />
        </strong>
        Indicates whether the shareclass has a lockup period.&nbsp; Select "Yes"
        to enable the Lockup Period entry field.
      </p>
      <p>
        <strong>
          Lockup Period
          <br />
        </strong>
        Specifies the duration of lockup in months.
      </p>
      <p>
        <strong>
          First Redemption On
          <br />
        </strong>
        Required. Specifies when the first possible redemption occurs.&nbsp; If
        the shareclass has lockup, then first redemption cannot occur until
        after the lockup period.&nbsp; Possible values:
      </p>
      <ul className="default-ul indent-5">
        <li>
          <strong>
            End of Lockup
            <br />
          </strong>
          First redemption will be possible immediately at the end of the lockup
          period or, if there is no lockup, immediately after the investment
          date.
        </li>
        <li>
          <strong>
            Next Quarter End
            <br />
          </strong>
          First redemption will be possible upon the end of the calendar quarter
          in which the lockup period ends or, if there is no lockup, upon the
          end of the calendar quarter of the investment date.&nbsp; If lockup
          ends on the last day of a calendar quarter, then the first redemption
          will be possible immediately at the end of lockup; otherwise, the
          first redemption will not be possible until the end of that quarter.
        </li>
        <li>
          <strong>
            Next Semiannual End
            <br />
          </strong>
          Similar to Next Quarter End, with first possible redemption upon the
          end of a calendar half-year, i.e., June 30 or December 31.
        </li>
        <li>
          <strong>
            Next Annual End
            <br />
          </strong>
          Similar to Next Quarter End, with first possible redemption upon the
          end of a calendar year, i.e., December 31.
        </li>
        <li>
          <strong>
            Next Jun. 30
            <br />
          </strong>
          First redemption will be possible upon June 30 following the end of
          the lockup period or, if there is no lockup, upon June 30 following
          the investment date.&nbsp; Similar to Next Annual End, with the year
          beginning on July 1, e.g., a fiscal year.
        </li>
      </ul>
      <p>
        <strong>
          Last Business Day (First Redemption)
          <br />
        </strong>
        Specifies whether the first redemption must be on a business day.&nbsp;
        If selected, the first possible redemption occurs on the last business
        day preceeding the day determined by the First Redemption On selection.
      </p>
      <p>
        <strong>
          Subsequent Redemption On
          <br />
        </strong>
        Optional.&nbsp; Specifies when the second possible redemption occurs in
        cases where the period between the first and second redemption differs
        from the liquidity period.&nbsp; Options are similar to First Redemption
        On, with the selection determining the date of the second possible
        redemption.&nbsp; Exceptions:
      </p>
      <ul className="default-ul indent-5">
        <li>
          <strong>
            End of Repeat Lockup
            <br />
          </strong>
          The lockup period is repeated, beginning on the date determined by
          First Redemption On and Last Business Day.&nbsp; The second redemption
          is possible immediately upon the end of the repeated lockup period.
        </li>
      </ul>
      <p>
        <strong>
          Last Business Day (Subsequent Redemption)
          <br />
        </strong>
        Similar to Last Business Day (First Redemption), with the second
        redemption date being affected.&nbsp; Only enabled when Subsequent
        Redemption On is selected.&nbsp;
      </p>
      <p>
        <strong>
          Liquidity Period
          <br />
        </strong>
        Specifies the liquidity period in months or days.&nbsp; Determines the
        sequence of possible redemption dates following the first possible
        redemption date (or second possible redemption date redemption if
        Subsequent Redemption On is selected) by repeatedly adding the liquidity
        period to the first (or second) possible redemption date.&nbsp; Addition
        is done in the units (months or days) specified.&nbsp; For example, 30
        days is different than one month.&nbsp; July 15 plus one month equals
        August 15, while July 15 plus 30 days equals August 14.
      </p>
      <p>
        <strong>
          Cash Availability
          <br />
        </strong>
        Specifies the number of days after the redemption date that cash is
        received.
      </p>
      <p>
        <strong>
          Notice Period
          <br />
        </strong>
        Specifies the notice period in days.&nbsp; Used when calculating the
        next possible redemption date on or after a given date.
      </p>
      <p>
        <strong>
          Business Days (Notice)
          <br />
        </strong>
        Specifies whether the notice period is in business days; otherwise it is
        in calendar days.
      </p>
      <p>
        <strong>
          Gate?
          <br />
        </strong>
        Indicates whether the shareclass limits redemptions.&nbsp; Includes the
        following entry fields:
      </p>
      <ul className="default-ul indent-5">
        <li>
          <strong>
            % Invested Capital
            <br />
          </strong>
          The maximum percentage of Rock Creek's investment in the fund that may
          be redeemed at one time.&nbsp; If the desired redemption exceeds this
          gate, then the redemption is broken in togate-sized chunks and
          distributed over multiple redemption dates.&nbsp; The value of Rock
          Creek's investment is stored in the NAV model.
        </li>
        <li>
          <strong>
            % NAV
            <br />
          </strong>
          The maximum percentage of the fund's total NAV (not the NAV of Rock
          Creek's investment) that may be redeemed at one time.&nbsp; The fund's
          total NAV is not stored in our system.
        </li>
      </ul>
      <p>
        <strong>
          Holdback?
          <br />
        </strong>
        Indicates whether the fund holds back a portion of the redemption until
        after annual audits are complete.&nbsp; The initial disbursement of cash
        is determined by the redemption date, Cash Availability and %
        Holdback.&nbsp; The disbursement of holdback cash is determined by the
        redemption date, the Fiscal Year Ending Month, the Holdback Cash
        Availability, and the initial disbursement amount.
      </p>
      <p>
        <strong>
          % Holdback
          <br />
        </strong>
        Specifies the percentage of the redemption that is held back until after
        the annual audit.
      </p>
    </>
  );
}

export default LiquidityDialog;
