import * as client from "_graphql-types";
import { filterSortPage } from "../DataProvider";
import * as types from "./types";

type Sdk = ReturnType<typeof client.getSdk>;

/*********************************************************************/
// Data Provider Functions
/*********************************************************************/

/*********************************************************************/
export async function getList(
  sdk: Sdk,
  params: types.GetListParams<client.PersonSortEnum>
) {
  const { pagination, sort, filter } = params;

  const {
    personList: { items: data, total },
  } = await sdk.getListPerson(
    filterSortPage({
      filter,
      sort,
      nameField: client.PersonSortEnum.LastName,
      pagination,
    })
  );

  return {
    data,
    total,
  };
}

/*********************************************************************/
async function getOne(
  sdk: Sdk,
  params: types.GetOneParams
): types.GetOneOut<any> {
  const id = Number(params.id);
  const { person: data } = await sdk.getOnePerson({
    id,
  });
  return { data };
}

/*********************************************************************/
async function getMany(sdk: Sdk, params: types.GetManyParams) {
  const ids = params.ids.map(id => Number(id));

  const { personMany: data } = await sdk.getManyPerson({
    ids,
  });

  return {
    data,
  };
}

/*********************************************************************/
async function create(
  sdk: Sdk,
  params: types.CreateParams<
    client.PersonInput & { firm: { id: string; name: string } }
  >
) {
  const { data } = params;
  let { firm, ...input } = data;

  console.log(
    JSON.stringify(
      {
        type: typeof data.photo?.rawFile,
        data: data.photo?.rawFile?.constructor,
      },
      null,
      2
    )
  );
  input.photo = data.photo?.rawFile;
  const {
    addPerson: { id },
  } = await sdk.createPerson({
    input,
    firmId: firm?.id ? Number(firm?.id) : undefined,
  });

  return await getOne(sdk, { id: id! });
}

/*********************************************************************/
async function update(
  sdk: Sdk,
  params: types.UpdateParams<client.PersonInput>
) {
  const id = Number(params.id);
  const { data } = params;

  console.log("RAW FILE ->", data.photo?.rawFile);

  const input: client.UpdatePersonMutationVariables["input"] = {
    firstName: data.firstName,
    middleName: data.middleName,
    lastName: data.lastName,
    pronouns: data.pronouns,
    phone: data.phone,
    phone2: data.phone2,
    fax: data.fax,
    fax2: data.fax2,
    email: data.email,
    email2: data.email2,
    isCFA: data.isCFA,
    biography: data.biography,
    titleEnumId: data.titleEnumId,
    workExperienceSummary: data.workExperienceSummary,
    educationSummary: data.educationSummary,
    comments: data.comments,
    linkedin: data.linkedin,
    twitter: data.twitter,
    photo: data.photo?.rawFile,
    diversity: {
      africanAmerican: data.diversity?.africanAmerican,
      asian: data.diversity?.asian,
      hispanic: data.diversity?.hispanic,
      lgbtq: data.diversity?.lgbtq,
      other: data.diversity?.other,
      nativeAmerican: data.diversity?.nativeAmerican,
      veteran: data.diversity?.veteran,
      disabled: data.diversity?.disabled,
      nonBinary: data.diversity?.nonBinary,
      hawaiianOrPacificIslander: data.diversity?.hawaiianOrPacificIslander,
      woman: data.diversity?.woman,
    },
  };

  await sdk.updatePerson({ id, input: input });

  return await getOne(sdk, { id });
}

/*********************************************************************/
export const person = types.dataProvider({
  getList,
  getOne,
  getMany,
  create,
  update,
});
