import { Chip, Tooltip } from "@mui/material";
import { Stack } from "@mui/system";
import { chunk } from "lodash";

const MAX_CHARS = 20;

export const ChipList = ({
  record,
}: {
  record: { id: number; name: string }[];
}) => {
  if (!record?.length) return <></>;
  return (
    <Stack direction="column" spacing={1}>
      {chunk(record, 2).map((chunk, index) => (
        <Stack
          key={`chip-list-${index}`}
          direction={{ xs: "column", sm: "row" }}
          spacing={1}
        >
          {chunk.map(({ id, name }) => {
            const truncName =
              name.length > MAX_CHARS ? `${name.slice(0, MAX_CHARS)}...` : name;
            return (
              <Tooltip key={`${id}::${name}`} title={name}>
                <Chip size="small" key={`${id}::${name}`} label={truncName} />
              </Tooltip>
            );
          })}
        </Stack>
      ))}
    </Stack>
  );
};
