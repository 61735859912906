import * as client from "_graphql-types";

import Grid from "@mui/material/Grid";
import { FileField, FileInput, required } from "react-admin";
import { CustomCreate } from "../CustomCreate";
import { ServiceProviderDocuments } from "./Form";

type Record =
  client.GetOneServiceProviderDocumentsQuery["serviceProviderDocuments"];

const validateName = [required()];

export const ServiceProviderDocumentCreate = () => (
  <CustomCreate title={() => "Create Service Provider Document"}>
    <Grid container spacing={2}>
      <ServiceProviderDocuments />
      <Grid item xs={12}>
        <FileInput source="file" validate={validateName}>
          <FileField source="src" title="title" />
        </FileInput>
      </Grid>
    </Grid>
  </CustomCreate>
);
