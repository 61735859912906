import { Button } from "ra-ui-materialui";
import {
  Show,
  Pagination,
  ReferenceManyField,
  useRecordContext,
} from "react-admin";
import { Link } from "react-router-dom";
import * as client from "_graphql-types";
import CustomBreadCrumb from "../CustomBreadCumb";
import { InvestmentInstitutionsListDataGrid } from "../investmentInstitutions/List";

type Record = NonNullable<client.GetOneInvestmentQuery["investment"]>;

const Title = () => {
  const record = useRecordContext<Record>();
  return (
    <span data-id="investment-service-provider-title">
      Investment Service Providers - {record ? `${record.name}   ` : "   "}
    </span>
  );
};

const CreateButton = () => {
  const record = useRecordContext<Record>();
  if (!record) return null;
  return (
    <>
      <Button
        label="Create Link"
        component={Link}
        variant="contained"
        to={{
          pathname: `/investmentInstitutions/create`,
          search: `?investmentId=${record.id}&redirect=%2FinvestmentServiceProviderLinks%2F${record.id}%2Fshow`,
        }}
      />
    </>
  );
};

export const InvestmentServiceProviderShow = () => {
  return (
    <Show
      title={<Title />}
      resource={"investment"}
      actions={
        <CustomBreadCrumb<Record>
          name="investmentServiceProviderLinks"
          items={[
            {
              path: "firm",
              getId: record => record?.firm?.id,
              getName: record => record?.firm?.name,
            },
            {
              path: "investment",
              getId: record => record?.id,
              getName: record => record?.name,
            },
          ]}
        />
      }
    >
      <CreateButton />
      <ReferenceManyField
        label="Investment Service Provider Links"
        reference="investmentInstitutions"
        target="investmentId"
        perPage={10}
        pagination={<Pagination />}
      >
        <InvestmentInstitutionsListDataGrid />
      </ReferenceManyField>
    </Show>
  );
};
