import { CustomCreate } from "../CustomCreate";
import { Form } from "./Form";
import { useSearchParams } from "react-router-dom";

export const FirmInstitutionsCreate = () => {
  return (
    <CustomCreate title={() => "Create Firm Service Provider Link"}>
      <Form />
    </CustomCreate>
  );
};
