import { Input, TableCell, TableRow } from "@mui/material";
import { QtdRec } from "../Edit";
import { Headers } from "./index";

interface Props {
  year: number;
  filteredQuarters: QtdRec[];
  addChangedRecord: (count: number, year: number, value: string) => void;
  canEdit: boolean;
}

export const Year = (props: Props) => {
  const filteredQuarters = props.filteredQuarters.sort(
    (a: any, b: any) => b.returnMonth - a.returnMonth
  );

  return (
    <TableRow>
      <TableCell>{props.year}</TableCell>
      {Headers.slice(1, 5).map((month, i) => {
        const currentQuarter = filteredQuarters.find(
          (rec: any) => rec.returnQuarter === i + 1
        );
        return (
          <TableCell
            key={`${props.year}-Q${i + 1}-${currentQuarter?.returnType}`}
          >
            <Input
              type="number"
              data-id={`performanceTableQuarterly-${props.year}-Q${i + 1}`}
              defaultValue={currentQuarter?.return?.toFixed(2) ?? ""}
              onChange={e => {
                props.addChangedRecord(i + 1, props.year, e.target.value);
              }}
              readOnly={!props.canEdit}
            />
          </TableCell>
        );
      })}
    </TableRow>
  );
};
