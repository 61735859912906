import { CircularProgress, Grid } from "@mui/material";
import * as client from "_graphql-types";
import { NO_WHITESPACE_REGEX } from "frontend/src/utils/constants";
import {
  DateInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  TextInput,
  regex,
  required,
  useGetRecordId,
  useRecordContext,
} from "react-admin";
import { useCanAccessMutation } from "../../util/useCanAccessMutation";
import CustomBreadCrumb from "../CustomBreadCumb";
import { CustomEdit } from "../CustomEdit";

type Record = NonNullable<client.GetOneDataLinksQuery["investment"]>;

const validateRequired = [required()];
const validateRequiredNoWhitespace = [
  required(),
  regex(NO_WHITESPACE_REGEX, "Please remove leading and trailing spaces"),
];

const CogencyLinkInput = ({
  canEdit,
  fieldAccessMap,
}: {
  canEdit: boolean;
  fieldAccessMap: { [key: string]: boolean };
}) => {
  const record = useRecordContext<Record>();

  function canEditField(field: string) {
    return canEdit && fieldAccessMap[field];
  }
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <h3>Cogency Link</h3>
        </Grid>
        <Grid item xs={12}>
          <NumberInput
            source="cogencyLink.id"
            label="RockCreek ID"
            readOnly
            defaultValue={record?.id}
          />
          <NumberInput
            source="cogencyLink.cogencyId"
            label="Cogency ID"
            validate={validateRequired}
            readOnly={!canEditField("cogencyId")}
          />
          <DateInput
            source="cogencyLink.cogStartDate"
            label="Start Date"
            validate={validateRequired}
            readOnly={!canEditField("cogStartDate")}
          />
        </Grid>
      </Grid>
    </>
  );
};

const BloombergForm = () => {
  const record = useRecordContext<Record>();

  const mutationArgs = JSON.stringify({
    id: record?.id,
    input: {
      firmId: record?.firmId,
    },
  });

  const {
    canEdit,
    canEditField: fieldAccessMap,
    loading,
  } = useCanAccessMutation("updateInvestment", mutationArgs);

  function canEditField(field: string) {
    return canEdit && fieldAccessMap[field];
  }

  const bloombergFieldValues = [
    { name: "REAL_TIME_TRR_MTD" },
    { name: "PX_LAST" },
    { name: "TOT_RETURN_INDEX_NET_DVDS" },
    { name: "NONE" },
  ];
  if (loading)
    return <CircularProgress data-cy="circularProgres__loadingAccess" />;
  if (!record) return null;

  return (
    <>
      <Grid spacing={2}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <h3>Bloomberg Code</h3>
          </Grid>
          <Grid item xs={10}>
            <TextInput
              label="Bloomberg Code"
              source="bloombergCode"
              validate={validateRequiredNoWhitespace}
              readOnly={!canEditField("bloombergCode")}
            />
            <ReferenceInput
              reference="indexCalcTypeEnum"
              sort={{ field: "id", order: "ASC" }}
              source="indexCalcTypeEnumId"
            >
              <SelectInput
                readOnly={!canEditField("indexCalcTypeEnumId")}
                optionText="indexCalcType"
                label="Provider Format"
              />
            </ReferenceInput>
            <ReferenceInput
              reference="performanceSourceEnum"
              sort={{ field: "id", order: "ASC" }}
              source="performanceSourceEnumId"
            >
              <SelectInput
                readOnly={!canEditField("performanceSourceEnumId")}
                optionText="name"
                label="Performance Source"
              />
            </ReferenceInput>
            <SelectInput
              label="Bloomberg Field"
              source="bloombergField"
              choices={bloombergFieldValues}
              optionText="name"
              optionValue="name"
              readOnly={!canEditField("bloombergField")}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export const DataLinksEdit = () => {
  const recordId = useGetRecordId();

  const {
    canEdit: canEditCogencyLink,
    canEditField: canEditFieldCogencyLink,
    loading: loadingCanEditCogencyLink,
  } = useCanAccessMutation(
    "addOrUpdateCogencyLink",
    JSON.stringify({
      id: recordId,
    })
  );

  return (
    <>
      <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
        <CustomBreadCrumb<Record>
          name="dataLinks"
          items={[
            {
              path: "firm",
              getId: record => record?.firm?.id,
              getName: record => record?.firm?.name,
            },
            {
              path: "investment",
              getId: record => record?.id,
              getName: record => record?.name,
            },
          ]}
        />

        <div style={{ flex: 1, display: "flex", alignItems: "stretch" }}>
          <Grid container spacing={2} style={{ flex: 1 }}>
            <Grid item xs={12} sm={6}>
              <CustomEdit<Record>
                title={record => record && `Data Links - ${record.name || ""}`}
                resource={"dataLinks"}
                redirect={`/dataLinks`}
                customFormProps={{
                  customToolbarProps: {
                    canAccessMutation: canEditCogencyLink,
                  },
                  loading: loadingCanEditCogencyLink,
                  canEditField: canEditFieldCogencyLink,
                }}
              >
                <>
                  <CogencyLinkInput
                    fieldAccessMap={canEditFieldCogencyLink}
                    canEdit={canEditCogencyLink}
                  />
                </>
              </CustomEdit>
            </Grid>
            <Grid item xs={12} sm={6}>
              <CustomEdit resource={"investment"} redirect={`/dataLinks`}>
                <BloombergForm />
              </CustomEdit>
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  );
};
