import Chip from "@mui/material/Chip";
import Grid from "@mui/material/Grid";
import * as client from "_graphql-types";
import { NO_WHITESPACE_REGEX } from "frontend/src/utils/constants";
import { AuthorizedTo } from "frontend/src/utils/types";
import { useEffect } from "react";
import {
  ArrayInput,
  BooleanInput,
  Button,
  CheckboxGroupInput,
  Datagrid,
  DateField,
  DateInput,
  EditButton,
  FormDataConsumer,
  FunctionField,
  NumberInput,
  Pagination,
  ReferenceArrayInput,
  ReferenceInput,
  ReferenceManyField,
  SelectInput,
  SimpleFormIterator,
  TextField,
  TextInput,
  regex,
  required,
  useRecordContext,
  useGetRecordId,
} from "react-admin";
import { Link, useLocation } from "react-router-dom";
import { CustomEdit } from "../CustomEdit";
import { useCanAccessMutation } from "../../util/useCanAccessMutation";
import { FDPEditableDataGrid } from "../fdpLogin/DataGrid";
import { UnlinkRelationshipButton } from "../fdpLogin/DataGridActions";
import { EditRowButton } from "@react-admin/ra-editable-datagrid";
import { withScopedForm } from "../react-admin-fixes";

type Record = AuthorizedTo<NonNullable<client.GetOneFirmQuery["firm"]>, "id">;

const DocumentsButton = () => {
  const record = useRecordContext<Record>();
  return !record ? null : (
    <Button
      component={Link}
      to={{
        pathname: `/firmDocument/${record.id}`,
      }}
      label="Go to Documents"
      data-id="documents"
    />
  );
};

const DEIButton = () => {
  const record = useRecordContext<Record>();
  return !record ? null : (
    <Button
      component={Link}
      to={{
        pathname: `/firmFirmDEI/${record.id}`,
      }}
      label="Go to DEI Entry"
      data-id="firmFirmDEI"
    />
  );
};

const CreateRelatedFirmFDPLoginButton = () => {
  const record = useRecordContext<Record>();
  return !record ? null : (
    <Button
      component={Link}
      to={{
        pathname: "/firmFDPLogins/create",
        search: `?source=${JSON.stringify({
          firm: {
            id: record.id,
            name: record.name ? encodeURIComponent(record.name) : "",
          },
        })}`,
      }}
      label="Link Existing FDP Login"
      title="Link existing FDP Login record to this firm"
      data-id="createFirmFDPLogin"
    />
  );
};

const FDPLoginActions = ({ firmId }: { firmId: number }) => (
  <>
    <EditRowButton />
    <UnlinkRelationshipButton entityId={firmId} sourceKey="firms" />
  </>
);

const AddDealButton = (props: any) => {
  const record = useRecordContext<Record>();
  return !record ? null : (
    <Button
      component={Link}
      label="Add Deal"
      to={{
        pathname: "/deal/create",
        search: `?source=${JSON.stringify({
          firm: {
            id: record.id,
            name: record.name ? encodeURIComponent(record.name) : "",
          },
        })}`,
      }}
    />
  );
};

export const getListColors = (id: number) => {
  switch (id) {
    case 1:
      return "#ff9800";
    case 2:
      return "#03a9f4";
    case 3:
      return "#4caf50";
    case 4:
      return "#f44336";
    default:
      return "#ffffff";
  }
};

const CreateRelatedFirmEmploymentButton = () => {
  const record = useRecordContext<Record>();
  return !record ? null : (
    <Button
      component={Link}
      to={{
        pathname: "/firmEmployment/create",
        search: `?source=${JSON.stringify({
          firm: {
            id: record.id,
            name: record.name ? encodeURIComponent(record.name) : "",
          },
        })}`,
      }}
      label="Relate to Existing Person"
      title="Add a firm employment for an existing person"
      data-id="createFirmEmployment"
    />
  );
};

const CreateRelatedInvestmentButton = () => {
  const record = useRecordContext<Record>();
  return !record ? null : (
    <Button
      component={Link}
      to={{
        pathname: "/investment/create",
        search: `?source=${JSON.stringify({
          firm: {
            id: record.id,
            name: record.name ? encodeURIComponent(record.name) : "",
          },
        })}`,
      }}
      label="Add New Investment"
      title="Add a new investment to this firm"
      data-id="createInvestment"
    />
  );
};

const CreateRelatedPersonButton = () => {
  const record = useRecordContext<Record>();
  return !record ? null : (
    <Button
      component={Link}
      to={{
        pathname: "/person/create",
        search: `?source=${JSON.stringify({
          firm: {
            id: record.id,
            name: record.name ? encodeURIComponent(record.name) : "",
          },
        })}`,
      }}
      label="Relate to New Person"
      title="Add a firm employment and create a new person"
      data-id="createPerson"
    />
  );
};

const CreateRelatedFamilyButton = () => {
  const record = useRecordContext<Record>();
  return !record ? null : (
    <Button
      component={Link}
      to={{
        pathname: "/family/create",
        search: `?source=${JSON.stringify({
          firm: {
            id: record.id,
            name: record.name ? encodeURIComponent(record.name) : "",
          },
        })}`,
      }}
      label="Add New Family"
      title="Add a new firm family"
      data-id="createFamily"
    />
  );
};

const PrimaryAddressInput = () => (
  <Grid container spacing={1}>
    <Grid item xs={12}>
      <TextInput label="Line 1" source="primaryAddress.line1" />
    </Grid>
    <Grid item xs={12}>
      <TextInput label="Line 2" source="primaryAddress.line2" />
    </Grid>
    <Grid item xs={12}>
      <TextInput label="Line 3" source="primaryAddress.line3" />
    </Grid>
    <Grid item xs={4}>
      <TextInput label="City" source="primaryAddress.city" />
    </Grid>
    <Grid item xs={4}>
      <ReferenceInput
        reference="stateEnum"
        sort={{ field: "name", order: "ASC" }}
        perPage={100}
        source="primaryAddress.stateEnumId"
      >
        <SelectInput optionText="name" label="US State" />
      </ReferenceInput>
    </Grid>
    <Grid item xs={4}>
      <TextInput label="Province" source="primaryAddress.otherState" />
    </Grid>
    <Grid item xs={4}>
      <TextInput label="Zip Code" source="primaryAddress.zipCode" />
    </Grid>
    <Grid item xs={4}>
      <ReferenceInput
        reference="countryEnum"
        sort={{ field: "name", order: "ASC" }}
        perPage={1000}
        source="primaryAddress.countryEnumId"
      >
        <SelectInput optionText="name" label="Country" />
      </ReferenceInput>
    </Grid>
    <Grid item xs={4}></Grid>
    <Grid item xs={4}>
      <TextInput label="Phone Number" source="primaryAddress.phone" />
    </Grid>
    <Grid item xs={4}>
      <TextInput label="Phone Number 2" source="primaryAddress.phone2" />
    </Grid>
    <Grid item xs={4}></Grid>
    <Grid item xs={4}>
      <TextInput label="Fax Number" source="primaryAddress.fax" />
    </Grid>
    <Grid item xs={4}>
      <TextInput label="Fax Number 2" source="primaryAddress.fax2" />
    </Grid>
    <Grid item xs={4}></Grid>
  </Grid>
);

const OtherAddressesInput = () => (
  <ArrayInput source="otherAddresses" data-cy="otherAddresses">
    <SimpleFormIterator>
      <FormDataConsumer>
        {withScopedForm<NonNullable<Record["otherAddresses"]>[number]>(
          ({ scopedFormData }) => {
            return (
              <>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <TextInput label="Line 1" source="line1" />
                  </Grid>
                  <Grid item xs={12}>
                    <TextInput label="Line 2" source="line2" />
                  </Grid>
                  <Grid item xs={12}>
                    <TextInput label="Line 3" source="line3" />
                  </Grid>
                  <Grid item xs={4}>
                    <TextInput label="City" source="city" />
                  </Grid>
                  <Grid item xs={4}>
                    <ReferenceInput
                      reference="stateEnum"
                      sort={{ field: "name", order: "ASC" }}
                      perPage={100}
                      source="stateEnumId"
                    >
                      <SelectInput optionText="name" label="US State" />
                    </ReferenceInput>
                  </Grid>
                  <Grid item xs={4}>
                    <TextInput label="Province" source="otherState" />
                  </Grid>
                  <Grid item xs={4}>
                    <TextInput label="Zip Code" source="zipCode" />
                  </Grid>
                  <Grid item xs={4}>
                    <ReferenceInput
                      reference="countryEnum"
                      sort={{ field: "name", order: "ASC" }}
                      perPage={1000}
                      record={scopedFormData}
                      source="countryEnumId"
                    >
                      <SelectInput optionText="name" label="Country" />
                    </ReferenceInput>
                  </Grid>
                  <Grid item xs={4}></Grid>
                  <Grid item xs={4}>
                    <TextInput label="Phone Number" source="phone" />
                  </Grid>
                  <Grid item xs={4}>
                    <TextInput label="Phone Number 2" source="phone2" />
                  </Grid>
                  <Grid item xs={4}></Grid>
                  <Grid item xs={4}>
                    <TextInput label="Fax Number" source="fax" />
                  </Grid>
                  <Grid item xs={4}>
                    <TextInput label="Fax Number 2" source="fax2" />
                  </Grid>
                  <Grid item xs={4}></Grid>
                </Grid>
              </>
            );
          }
        )}
      </FormDataConsumer>
    </SimpleFormIterator>
  </ArrayInput>
);

export const validateYearFounded = (value: string) => {
  if (value) {
    const num = Number(value);
    if (isNaN(num)) return "Invalid year";
    const int = parseInt(value);
    if (int !== num) return "Invalid year";
    if (int < 1700 || new Date().getFullYear() < int)
      return "Invalid year. Year founded has to be after 1700";
  }
};

function scrollToView(hash: string) {
  if (hash !== "") {
    setTimeout(() => {
      const id = hash.replace("#", "");
      const element = document.getElementById(id);
      if (element) element.scrollIntoView(false);
    }, 0);
  }
}

const validateRequiredNoWhitespace = [
  required(),
  regex(NO_WHITESPACE_REGEX, "Please remove leading and trailing spaces"),
];
const validateYearFoundedInput = [validateYearFounded, required()];

export const FirmEdit = () => {
  const location = useLocation();
  useEffect(() => {
    scrollToView(location.hash);
  }, [location]);
  const recordId = useGetRecordId();
  const mutationArgs = JSON.stringify({
    id: recordId,
  });

  const { canEdit, canEditField, loading } = useCanAccessMutation(
    "updateFirm",
    mutationArgs
  );

  console.log({ fieldAccessMap: canEditField });
  return (
    <CustomEdit<Record>
      title={record => record && `Firm - ${record.name}`}
      sourcedFromOdc={(record: Record) => !!record?.portalFirm?.migratedAt}
      customFormProps={{
        customToolbarProps: {
          allowDelete: true,
        },
      }}
    >
      <Grid container spacing={1}>
        <Grid item xs={10}>
          <TextInput
            label="Firm Name"
            source="name"
            validate={validateRequiredNoWhitespace}
          />
        </Grid>
        <Grid item xs={2}>
          <NumberInput
            label="Year Founded"
            source="yearFounded"
            validate={validateYearFoundedInput}
          />
        </Grid>
        <Grid item xs={3}>
          <BooleanInput label="Migrate to Company" source="isCompany" />
        </Grid>
        <Grid data-cy="registeredWithIds" item xs={10}>
          <ReferenceArrayInput
            label="Registered with"
            reference="agency"
            sort={{ field: "shortName", order: "ASC" }}
            perPage={1000}
            source="registeredWithIds"
            data-cy="registeredWithIds"
          >
            <CheckboxGroupInput optionText="shortName" />
          </ReferenceArrayInput>
        </Grid>
        <Grid item xs={2}>
          <TextInput label="Other Registration" source="registeredWithOther" />
        </Grid>
        <Grid item xs={3}>
          <TextInput label="SEC CIK" source="cik" />
        </Grid>
        <Grid item xs={3}>
          <TextInput label="LEI" source="lei" />
        </Grid>
        <Grid item xs={3}>
          <TextInput label="CRD" source="crd" />
        </Grid>
        <Grid item xs={3}>
          <BooleanInput label="SBAI Signatory" source="ukHfsbSignatory" />
        </Grid>
        <Grid item xs={12}>
          <DocumentsButton />
        </Grid>
        <Grid item xs={12}>
          <hr />
          <h3>Primary Address</h3>
        </Grid>
        <Grid item xs={12}>
          <PrimaryAddressInput />
        </Grid>
        <Grid item xs={12}>
          <hr />
          <h3>Other Addresses</h3>
        </Grid>
        <Grid item xs={12}>
          <OtherAddressesInput />
        </Grid>
        <Grid item xs={12}>
          <hr />
          <h3>Diversity of Ownership</h3>
          <p>Firm ownership is deprecated, please use firm DEI:</p>
          <DEIButton />
        </Grid>
        <Grid item xs={3}>
          <DateInput
            label="As of"
            source="ownershipDiversity.asOfDate"
            disabled
          />
        </Grid>
        <Grid item xs={3}>
          {/* setting `disabled` will cause maximum update depth exceeded error */}
          <BooleanInput label="Is MWDBE" source="isStaffMWDBE" readOnly />
        </Grid>
        <Grid item xs={9}></Grid>
        <Grid item xs={12}>
          <b>Details</b>
        </Grid>
        <Grid item xs={12}>
          Percentage by Category
        </Grid>
        <Grid item xs={3}>
          <NumberInput
            label="% African American"
            source="ownershipDiversity.africanAmericanPercent"
            min={0}
            max={100}
            disabled
          />
        </Grid>
        <Grid item xs={3}>
          <NumberInput
            label="% Asian"
            source="ownershipDiversity.asianPercent"
            min={0}
            max={100}
            disabled
          />
        </Grid>
        <Grid item xs={3}>
          <NumberInput
            label="% Hispanic"
            source="ownershipDiversity.hispanicPercent"
            min={0}
            max={100}
            disabled
          />
        </Grid>
        <Grid item xs={3}>
          <NumberInput
            label="% LGBTQ"
            source="ownershipDiversity.LGBTQPercent"
            min={0}
            max={100}
            disabled
          />
        </Grid>
        <Grid item xs={3}>
          <NumberInput
            label="% Native American"
            source="ownershipDiversity.nativeAmericanPercent"
            min={0}
            max={100}
            disabled
          />
        </Grid>
        <Grid item xs={3}>
          <NumberInput
            label="% Veteran"
            source="ownershipDiversity.veteranPercent"
            min={0}
            max={100}
            disabled
          />
        </Grid>
        <Grid item xs={3}>
          <NumberInput
            label="% Disabled"
            source="ownershipDiversity.disabledPercent"
            min={0}
            max={100}
            disabled
          />
        </Grid>
        <Grid item xs={3}>
          <NumberInput
            label="% Women"
            source="ownershipDiversity.womenPercent"
            min={0}
            max={100}
            disabled
          />
        </Grid>
        <Grid item xs={3}>
          <NumberInput
            label="% Other"
            source="ownershipDiversity.otherPercent"
            min={0}
            max={100}
            disabled
          />
        </Grid>
        <Grid item xs={12}>
          <b>Totals</b>
        </Grid>
        <Grid item xs={12}>
          Percentage by Category
        </Grid>
        <Grid item xs={3}>
          <NumberInput
            label="% Diverse"
            source="ownershipDiversity.diversePercent"
            min={0}
            max={100}
            disabled
          />
        </Grid>
        <Grid item xs={3}>
          <NumberInput
            label="% Women"
            source="ownershipDiversity.womenPercent"
            min={0}
            max={100}
            disabled
          />
        </Grid>
        <Grid item xs={12}>
          <hr />
          <h3>Firm Employee Summary</h3>
        </Grid>
        <Grid item xs={1}>
          <NumberInput label="Total" source="employeeTotal" />
        </Grid>
        <Grid item xs={1}>
          <NumberInput label="Portfolio Mgr" source="portfolioManagerCount" />
        </Grid>
        <Grid item xs={1}>
          <NumberInput label="Investment" source="investmentCount" />
        </Grid>
        <Grid item xs={1}>
          <NumberInput label="Analyst" source="analystCount" />
        </Grid>
        <Grid item xs={1}>
          <NumberInput label="Trader" source="traderCount" />
        </Grid>
        <Grid item xs={1}>
          <NumberInput label="Operations" source="operationsCount" />
        </Grid>
        <Grid item xs={1}>
          <NumberInput label="Administrative" source="administrativeCount" />
        </Grid>
        <Grid item xs={1}>
          <NumberInput
            label="Investor Relations"
            source="investorRelationsCount"
          />
        </Grid>
        <Grid item xs={1}>
          <NumberInput label="Legal Count" source="legalCount" />
        </Grid>
        <Grid item xs={3}></Grid>

        <Grid item xs={12}>
          <hr />
          <h3>Website</h3>
        </Grid>
        <Grid item xs={6}>
          <TextInput label="URL" source="primaryAddress.website" />
        </Grid>
        <Grid item xs={3}>
          <TextInput label="User ID" source="primaryAddress.webUserId" />
        </Grid>
        <Grid item xs={3}>
          <TextInput label="Password" source="primaryAddress.webPassword" />
        </Grid>
        <Grid item xs={6}>
          <TextInput label="Firm Group IR Email" source="firmIrEmail" />
        </Grid>
        <Grid item xs={6}></Grid>
        <Grid item xs={12}>
          <TextInput
            label="Other Comments"
            multiline
            rows={5}
            source="comments"
          />
        </Grid>
        <Grid item xs={12}>
          <hr />
          <h3>FDP Logins</h3>
        </Grid>
        <Grid item xs={12}>
          <ReferenceManyField
            reference="fdpLogin"
            perPage={1000}
            sort={{ field: "username", order: "ASC" }}
            target="firmId"
            pagination={<Pagination />}
          >
            <FDPEditableDataGrid
              mutationMode="undoable"
              actions={<FDPLoginActions firmId={Number(recordId)} />}
              referenceKey="firm_fdpLogins"
              bulkActionButtons={false}
              noDelete={true}
              relationships={["investments"]}
              defaultRowValues={{ firms: [{ id: Number(recordId) }] }}
              entityContext="firm"
              entity={{ id: Number(recordId) }}
            />
          </ReferenceManyField>
        </Grid>
        <Grid item xs={12}>
          <CreateRelatedFirmFDPLoginButton />
        </Grid>
        <Grid item xs={12}>
          <hr />
          <h3>Investments</h3>
        </Grid>
        <Grid item xs={12}>
          <ReferenceManyField
            label="Investments"
            reference="investment"
            target="firmId"
            sort={{ field: "name", order: "ASC" }}
            pagination={<Pagination />}
          >
            <Datagrid rowClick="edit" bulkActionButtons={false}>
              <TextField label="ID" source="id" />
              <TextField label="Name" source="name" />
              <FunctionField
                label="List"
                render={(record: any) => {
                  if (!record?.list?.id) return "";

                  return (
                    <Chip
                      label={record.list.name}
                      style={{
                        backgroundColor: getListColors(record.list.id),
                      }}
                    >
                      {record.list.name}
                    </Chip>
                  );
                }}
              />
              <FunctionField
                label="Link"
                render={(record: any) => {
                  return (
                    <SelectInput
                      source={`fundLinksSerialized._${record.id}`}
                      label="Link"
                      defaultValue={record.fundLinkId}
                      choices={[...Array(10).keys()].map(i => ({
                        id: i + 1,
                        name: `Link ${i + 1}`,
                      }))}
                    />
                  );
                }}
              />

              <EditButton data-cy="investment-edit" />
            </Datagrid>
          </ReferenceManyField>
        </Grid>
        <Grid item xs={12}>
          <CreateRelatedInvestmentButton />
        </Grid>
        <Grid item xs={12}>
          <hr />
          <h3>Firm Families</h3>
        </Grid>
        <Grid item xs={12}>
          <ReferenceManyField
            label="Firm Families"
            reference="family"
            target="firmId"
            sort={{ field: "name", order: "ASC" }}
            pagination={<Pagination />}
          >
            <Datagrid rowClick="edit" bulkActionButtons={false}>
              <TextField label="ID" source="id" sortable={false} />
              <TextField label="Name" source="name" sortable={false} />
              <EditButton />
            </Datagrid>
          </ReferenceManyField>
        </Grid>
        <Grid id="firmFamilyList" item xs={12}>
          <CreateRelatedFamilyButton />
        </Grid>
        <Grid item xs={12}>
          <hr />
          <h3>Firm Employments</h3>
        </Grid>
        <Grid item xs={12}>
          <ReferenceManyField
            label="Firm Employments"
            reference="firmEmployment"
            target="firmId"
            sort={{ field: "firmDefault", order: "ASC" }}
            pagination={<Pagination />}
          >
            <Datagrid rowClick="edit" bulkActionButtons={false}>
              <TextField label="ID" source="id" sortable={false} />
              <TextField
                label="Last Name"
                source="person.lastName"
                sortable={false}
              />
              <TextField
                label="First Name"
                source="person.firstName"
                sortable={false}
              />
              <TextField
                label="Role"
                source="employeeRole.name"
                sortable={false}
              />
              <TextField
                label="Contact Type"
                source="contactType.name"
                sortable={false}
              />
              <FunctionField
                label="Departed?"
                render={(firmEmployment: any) =>
                  firmEmployment?.leftInd ? "Yes" : ""
                }
                sortable={false}
              />
              <DateField
                label="Departed on"
                source="leftDate"
                sortable={false}
                options={{ timeZone: "UTC" }}
              />
              <EditButton />
            </Datagrid>
          </ReferenceManyField>
        </Grid>
        <Grid id="firmEmploymentList" item xs={12}>
          <CreateRelatedFirmEmploymentButton />
          <CreateRelatedPersonButton />
        </Grid>
        <Grid item xs={12}>
          <hr />
          <h3>Deals</h3>
        </Grid>
        <Grid item xs={12}>
          <ReferenceManyField
            label="Deals"
            reference="deal"
            target="firmId"
            sort={{ field: "id", order: "DESC" }}
            pagination={<Pagination />}
          >
            <Datagrid rowClick="edit" bulkActionButtons={false}>
              <TextField label="ID" source="id" sortable={false} />
              <TextField label="Firm" source="investor.name" sortable={false} />
              <TextField
                label="Company"
                source="company.name"
                sortable={false}
              />
              <TextField
                label="Funding Round"
                source="fundingRound.name"
                sortable={false}
              />
              <EditButton />
            </Datagrid>
          </ReferenceManyField>
        </Grid>
        <Grid item xs={12}>
          <AddDealButton />
        </Grid>
        <Grid item xs={12}>
          <hr />
          <h3>Data Organization</h3>
        </Grid>
        <Grid item xs={6}>
          <NumberInput
            label="Data Organization Id"
            source="dataOrganizationId"
            disabled={!canEditField["dataOrganizationId"]}
          />
        </Grid>
      </Grid>
    </CustomEdit>
  );
};
