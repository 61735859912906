import { createContext, useState } from "react";

const USE_DARK_THEME = "rcg::useDarkTheme";

export const DisplayThemeContext = createContext(
  {} as {
    useDarkTheme: boolean;
    setUseDarkTheme: (useDarkTheme: boolean) => void;
  }
);

export const DisplayThemeContextProvider = ({ children }: any) => {
  const [useDarkTheme, setUseDarkTheme] = useState(
    localStorage.getItem(USE_DARK_THEME) === "true"
  );

  const displayThemeContext = {
    useDarkTheme,
    setUseDarkTheme: (useDarkTheme: boolean) => {
      if (useDarkTheme) {
        localStorage.setItem(USE_DARK_THEME, "true");
      } else {
        localStorage.removeItem(USE_DARK_THEME);
      }
      setUseDarkTheme(useDarkTheme);
    },
  };

  console.log("DISPLAY THEME CONTEXT");
  return (
    <DisplayThemeContext.Provider value={displayThemeContext}>
      {children}
    </DisplayThemeContext.Provider>
  );
};

export const { Consumer: DisplayThemeContextConsumer } = DisplayThemeContext;
