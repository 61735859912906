import { addSearchMethod } from "@react-admin/ra-search";
import { createContext, useContext } from "react";
import DataProvider from "../dataProvider/DataProvider";
import { AuthContext } from "./Auth/AuthProvider.component";
import { DataProvider as RaDataProvider } from "react-admin";

export const DataProviderContext = createContext(
  {} as {
    dataProvider: DataProvider;
    showLoginDialog: () => void;
    setShowLoginDialog: (trigger: () => void) => void;
  }
);

// Use a boxed value and don't use React state, to avoid rerendering the form and losing unsaved data.
const showLoginDialogTrigger = [() => {}];

export const DataProviderContextProvider = ({ children }: any) => {
  const { auth } = useContext(AuthContext);

  if (!auth) return null;
  const baseDataProvider = new DataProvider(auth);

  //Type issue, properly returns our data provider decorated with search
  const dataProvider: any = addSearchMethod(
    baseDataProvider as RaDataProvider,
    [
      // search across these resources
      "investment",
      "firm",
    ]
  );

  function showLoginDialog() {
    showLoginDialogTrigger[0]();
  }
  function setShowLoginDialog(trigger: () => void) {
    showLoginDialogTrigger[0] = trigger;
  }

  const dataProviderContext = {
    dataProvider,
    showLoginDialog,
    setShowLoginDialog,
  };

  console.log("DATA CONTEXT PROVIDER");
  return (
    <DataProviderContext.Provider value={dataProviderContext}>
      {children}
    </DataProviderContext.Provider>
  );
};

export const { Consumer: DataProviderContextConsumer } = DataProviderContext;

export const useDataProvider = () => useContext(DataProviderContext);
