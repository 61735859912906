import React from "react";
import { Tooltip } from "@mui/material";
import { startCase } from "lodash";

export const FaceOfEntityTooltip = ({
  children,
  entity,
}: {
  children: any;
  entity: string;
}) => {
  return (
    <Tooltip
      title={`If ${entity === "investment" ? "an" : "a"} ${startCase(
        entity
      )} Employment is marked as 
    face of ${entity.toLocaleLowerCase()} the associated person will
    show up on the overview page replacing
    the system determined ${
      entity === "investment" ? "portfolio manager" : "ceo/founder"
    }.`}
    >
      <div>{children}</div>
    </Tooltip>
  );
};
