import {
  Divider,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import * as client from "_graphql-types";
import { NO_WHITESPACE_REGEX } from "frontend/src/utils/constants";
import { compact } from "lodash";
import { Button } from "ra-ui-materialui";
import { useMemo } from "react";
import {
  BooleanInput,
  NumberInput,
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar,
  maxValue,
  minValue,
  regex,
  required,
  useRecordContext,
} from "react-admin";
import { Link } from "react-router-dom";
import { RegisterFormMeta } from "../RegisterFormMeta";

export type Record = NonNullable<
  client.GetOneServiceProviderFirmQuery["serviceProviderFirm"]
>;

const validateRequiredNoWhitespace = [
  required(),
  regex(NO_WHITESPACE_REGEX, "Please remove leading and trailing spaces"),
];
const validateYearFounded = [
  minValue(1600),
  maxValue(new Date().getFullYear()),
];
const validateTierLevel = [minValue(1), maxValue(5)];

function AddressView({
  address,
}: {
  address: NonNullable<Record["officeLocations"]>[0]["institution"]["address"];
}) {
  return (
    <Grid container spacing={2}>
      <Grid item xs={4}>
        <ul>
          {address?.line1 && (
            <li>
              <strong>Line 1:</strong> {address.line1}
            </li>
          )}
          {address?.line2 && (
            <li>
              <strong>Line 2:</strong> {address.line2}
            </li>
          )}
          {address?.line3 && (
            <li>
              <strong>Line 3:</strong> {address.line3}
            </li>
          )}
          {address?.city && (
            <li>
              <strong>City:</strong> {address.city}
            </li>
          )}
          {address?.state?.name && (
            <li>
              <strong>State:</strong> {address.state.name}
            </li>
          )}
          {address?.otherState && (
            <li>
              <strong>Province:</strong> {address.otherState}
            </li>
          )}
        </ul>
      </Grid>
      <Grid item xs={4}>
        <ul>
          {address?.zipCode && (
            <li>
              <strong>Zip Code:</strong> {address.zipCode}
            </li>
          )}
          {address?.country?.name && (
            <li>
              <strong>Country:</strong> {address.country.name}
            </li>
          )}
          {address?.phone && (
            <li>
              <strong>Phone:</strong> {address.phone}
            </li>
          )}
          {address?.phone2 && (
            <li>
              <strong>Phone 2:</strong> {address.phone2}
            </li>
          )}
          {address?.fax && (
            <li>
              <strong>Fax:</strong> {address.fax}
            </li>
          )}
          {address?.fax2 && (
            <li>
              <strong>Fax 2:</strong> {address.fax2}
            </li>
          )}
          {address?.email && (
            <li>
              <strong>Email:</strong> {address.email}
            </li>
          )}
        </ul>
      </Grid>
    </Grid>
  );
}

const CreateOfficeLocationButton = () => {
  const record = useRecordContext<Record>();
  if (!record) return null;
  return (
    <>
      <Button
        label="Create Office Location"
        component={Link}
        variant="contained"
        to={{
          pathname: `/officeLocation/create`,
          search: `?serviceProviderFirmId=${record.id}`,
        }}
      />
    </>
  );
};

function RegisteredAddress() {
  const record = useRecordContext<Record>();

  const corporateOfficeLocation = useMemo(
    () =>
      (record?.officeLocations || []).find(location => location.isCorporate),
    [record?.officeLocations]
  );

  if (!record) return null;

  return (
    <div>
      <Divider style={{ marginBottom: "20px" }} textAlign="left">
        Registered address
      </Divider>
      <em>
        {corporateOfficeLocation
          ? "Using corporate address from office locations. " +
            corporateOfficeLocation?.institution.name
          : "No office location marked as corporate."}
      </em>
      {corporateOfficeLocation?.institution.address && (
        <AddressView address={corporateOfficeLocation?.institution.address} />
      )}
    </div>
  );
}

function Form() {
  const record = useRecordContext<Record>();
  return (
    <SimpleForm
      warnWhenUnsavedChanges
      toolbar={
        <Toolbar>
          <SaveButton />
        </Toolbar>
      }
    >
      <RegisterFormMeta />
      <Grid>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextInput
              label="Name"
              source="name"
              validate={validateRequiredNoWhitespace}
            />
          </Grid>
          <Grid item xs={3}>
            <NumberInput
              label="Year Founded"
              source="yearFounded"
              validate={validateYearFounded}
            />
          </Grid>
          <Grid item xs={3}>
            <NumberInput
              label="Tier Level"
              source="tierLevel"
              validate={validateTierLevel}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <BooleanInput label="Is Evaluating" source="isEvaluating" />
          </Grid>
        </Grid>
        <RegisteredAddress />
        {record?.id && (
          <>
            <Divider style={{ marginBottom: "20px" }} textAlign="left">
              Office Locations
            </Divider>
            <CreateOfficeLocationButton />
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="caption table">
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Is Corporate Location</TableCell>
                    <TableCell>Types</TableCell>
                    <TableCell>Selection Status</TableCell>
                    <TableCell>Location</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(record?.officeLocations || []).map(officeLocation => (
                    <TableRow key={officeLocation.institution.name}>
                      <TableCell component="th" scope="row">
                        {officeLocation.institution.name}
                      </TableCell>
                      <TableCell>
                        {officeLocation.isCorporate ? "Yes" : ""}
                      </TableCell>
                      <TableCell>
                        {officeLocation.institution?.institutionsInstitutionTypeEnumList.items
                          .map(({ institutionTypeEnum: { name } }) => name)
                          .join(", ")}
                      </TableCell>
                      <TableCell>
                        {
                          officeLocation.institution.institutionSelectionStatus
                            ?.name
                        }
                      </TableCell>
                      <TableCell>
                        {compact([
                          officeLocation.institution.address?.country?.name,
                          officeLocation.institution.address?.state?.name,
                          officeLocation.institution.address?.otherState,
                        ]).join(", ")}
                      </TableCell>
                      <TableCell>
                        <Link
                          to={`/officeLocation/${officeLocation.institution.id}`}
                        >
                          Edit
                        </Link>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}
        <Divider style={{ marginBottom: "20px" }} textAlign="left">
          Documents
        </Divider>
        <Grid container spacing={2}>
          {record?.id && (
            <>
              <Grid item xs={4}>
                <Button
                  label="Go To Documents"
                  component={Link}
                  variant="contained"
                  size="large"
                  to={{
                    pathname: "/serviceProviderDocument",
                    search: `?filter=${JSON.stringify({
                      serviceProviderFirmId: record.id,
                    })}`,
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <Button
                  label="Add Document"
                  component={Link}
                  variant="contained"
                  size="large"
                  to={{
                    pathname: "/serviceProviderDocument/create",
                    search: `?source=${JSON.stringify({
                      serviceProviderFirm: {
                        id: record.id,
                        name: encodeURIComponent(record.name),
                      },
                    })}`,
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <Button
                  label="Go To Notes"
                  component={Link}
                  variant="contained"
                  size="large"
                  to={{
                    pathname: "/serviceProviderNotes",
                    search: `?filter=${JSON.stringify({
                      providerId: record.id,
                    })}`,
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <Button
                  label="Add Notes"
                  component={Link}
                  variant="contained"
                  size="large"
                  to={{
                    pathname: `/serviceProviderNotes/create`,
                    search: `?providerId=${record.id}`,
                  }}
                />
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
    </SimpleForm>
  );
}

export default Form;
