import { Grid } from "@mui/material";
import * as client from "_graphql-types";
import {
  ArrayInput,
  AutocompleteInput,
  DateInput,
  FormDataConsumer,
  ReferenceInput,
  SimpleFormIterator,
} from "react-admin";
import { withScopedForm } from "../react-admin-fixes";

type Record = NonNullable<client.GetOneOrganizationQuery["organization"]>;

export const OrgLinksInput = () => (
  <ArrayInput source="orgLinks" data-cy="organization__orgLinks" label="">
    <SimpleFormIterator
      fullWidth
      sx={{
        "& .RaSimpleFormIterator-line": {
          borderBottom: "none",
        },
      }}
      disableReordering
      disableClear
    >
      <FormDataConsumer>
        {withScopedForm<NonNullable<Record["orgLinks"]>[number]>(
          ({ scopedFormData }) => (
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <ReferenceInput
                  reference="links"
                  sort={{ field: "name", order: "ASC" }}
                  perPage={1000}
                  record={scopedFormData}
                  source="linkId"
                >
                  <AutocompleteInput
                    optionText={record => `${record.name} (${record.url})`}
                    label="Link"
                  />
                </ReferenceInput>
              </Grid>
              <Grid item xs={6}>
                <DateInput label="Expiration Date" source="expirationDate" />
              </Grid>
            </Grid>
          )
        )}
      </FormDataConsumer>
    </SimpleFormIterator>
  </ArrayInput>
);
