import * as client from "_graphql-types";
import { CustomCreate } from "../CustomCreate";
import {
  NumberInput,
  RadioButtonGroupInput,
  ReferenceInput,
  SelectInput,
  TextInput,
  required,
} from "react-admin";
import { useWatch } from "react-hook-form";
import Grid from "@mui/material/Grid";
import { SchoolInput } from "./SchoolInput";
import { getPersonPath } from "../person/Edit";
import { useLocation } from "react-router-dom";

type Record = client.GetOnePersonEducationQuery["personEducation"] & {
  schoolAction: "create" | "select";
};

const validateRequired = [required()];

function validateYear(value: any) {
  value = Number(value);
  if (!(Number.isInteger(value) && 1900 < value && value < 3000)) {
    return "Invalid year";
  }
}

export const SchoolEntry = () => {
  const schoolAction = useWatch({ name: "schoolAction" });

  if (schoolAction === "create") {
    return (
      <TextInput
        label="Create School"
        source="school.name"
        validate={validateRequired}
      />
    );
  }

  return (
    <SchoolInput
      label="Select School"
      source="school.name"
      validate={validateRequired}
    />
  );
};

export const PersonEducationCreate = () => {
  const location = useLocation();
  return (
    <CustomCreate<Record>
      title={record =>
        record?.person &&
        `Create Education for ${record.person.lastName || ""}, ${
          record.person.firstName || ""
        } ${record.person.middleName || ""}`
      }
      customFormProps={{
        customToolbarProps: {
          redirectToPathOnSave: getPersonPath(location),
        },
        simpleFormProps: {
          defaultValues: { schoolAction: "select" },
        },
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <RadioButtonGroupInput
            label=""
            source="schoolAction"
            choices={[
              { id: "select", name: "Select existing school" },
              { id: "create", name: "Add new school" },
            ]}
            row={true}
          />
        </Grid>
        <Grid item xs={9}>
          <SchoolEntry />
        </Grid>
        <Grid item xs={3}></Grid>
        <Grid item xs={6}>
          {/* school$, degree$, graduation year */}
          <ReferenceInput
            reference="degreeEnum"
            sort={{ field: "name", order: "ASC" }}
            source="degreeEnumId"
            perPage={999}
          >
            <SelectInput optionText="name" label="Degree" />
          </ReferenceInput>
        </Grid>
        <Grid item xs={3}>
          <NumberInput
            label="Graduation Year"
            source="graduationYear"
            validate={validateYear}
          />
        </Grid>
        <Grid item xs={3}></Grid>
        <Grid item xs={3}></Grid>
      </Grid>
    </CustomCreate>
  );
};
