import * as client from "_graphql-types";
import * as types from "./types";
import uuid from "frontend/src/utils/uuid";

type Sdk = ReturnType<typeof client.getSdk>;

const create = async (
  sdk: Sdk,
  param: types.CreateParams<{ draftId: number }>
) => {
  console.log("create:", param);

  if (!param.data.draftId) {
    throw new Error("No draftId to process");
  }
  await sdk.processDraftDocument({ draftId: param.data.draftId });

  return { data: { id: param.data.draftId } };
};

export const draftDocumentProcessing = types.dataProvider({
  create,
});
