import AddIcon from "@mui/icons-material/Add";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import RemoveIcon from "@mui/icons-material/Remove";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from "@mui/material";
import * as client from "_graphql-types";
import { useEffect, useState } from "react";
import { useDataProvider, useGetRecordId } from "react-admin";
import { Provided } from "../../dataProvider/DataProvider";
import { getList as investmentList } from "../../dataProvider/resources/investmentBulk";
import { getList as strategyEnumList } from "../../dataProvider/resources/strategyEnum";
import { useCanAccessMutation } from "../../util/useCanAccessMutation";
import CustomBreadCrumb from "../CustomBreadCumb";
import { CustomEdit } from "../CustomEdit";
import { NavSearch } from "../Navigator";
import { EntityAutoComplete } from "../UI/EntityAutocomplete";
import { PeersInput } from "./PeersInput";

export type Record = NonNullable<
  client.GetOneInvestmentPeersQuery["investment"]
>;
export type IdName = Pick<Record, "id" | "name">;

export type OnPeersChange = (
  event: "add" | "remove" | "clear",
  values: IdName[]
) => void;

export const InvestmentPeersEdit = () => {
  const [onPeersChange, setOnPeersChange] = useState<OnPeersChange>(
    () => () => {
      throw new Error("peers handler called before initialization");
    }
  );

  const dataProvider = useDataProvider();
  const [strategies, setStrategies] = useState<
    Provided<typeof strategyEnumList>
  >([]);
  const [selectedStrategies, setSelectedStrategies] = useState<
    {
      id: string;
      name: string;
    }[]
  >([]);
  const [investments, setInvestments] = useState<
    Provided<typeof investmentList>
  >([]);
  const [selectedInvestments, setSelectedInvestments] = useState<IdName[]>([]);
  const [expandedIndex, setExpandedIndex] = useState(-1);
  const [bulkSource, setBulkSource] = useState<
    "fromInvestment" | "fromTextEntry"
  >("fromInvestment");
  const [bulkFromInvestment, setBulkFromInvestment] = useState<IdName>();
  const [bulkFromInvestmentMessage, setBulkFromInvestmentMessage] =
    useState("");
  const [bulkFromEntryIds, setBulkFromEntryIds] = useState<number[]>([]);
  const [bulkFromEntryError, setBulkFromEntryError] = useState("");
  const [bulkFromEntryMessage, setBulkFromEntryMessage] = useState("");

  const id = useGetRecordId();

  const mutationArgs = JSON.stringify({
    id: id,
    input: {},
  });

  const { canEdit, canEditField, loading } = useCanAccessMutation(
    "updateInvestment",
    mutationArgs
  );

  async function loadStrategies() {
    const { data } = await dataProvider.getList<
      Provided<typeof strategyEnumList>[0]
    >("strategyEnum", {
      sort: {
        field: "strategy",
        order: "ASC",
      },
      pagination: {
        page: 1,
        perPage: 25,
      },
      filter: {},
    });
    setStrategies(data);
    setSelectedStrategies([]);
  }

  async function loadInvestments() {
    const strategyIds: number[] = [];
    const subStrategyIds: number[] = [];
    for (const selected of selectedStrategies) {
      const [type, id] = selected.id.split(":", 2).map(Number);
      if (type === 0) {
        strategyIds.push(id);
      } else {
        subStrategyIds.push(id);
      }
    }
    const { data } = await dataProvider.getList<
      Provided<typeof investmentList>[0]
    >("investmentBulk", {
      sort: {
        field: "name",
        order: "ASC",
      },
      filter: {
        strategy: {
          strategyIds,
          subStrategyIds,
        },
      },
      pagination: {
        page: 1,
        perPage: 25,
      },
    });
    setInvestments(data);
    setSelectedInvestments([]);
  }

  useEffect(() => {
    loadStrategies();
  }, []);

  useEffect(() => {
    loadInvestments();
  }, [selectedStrategies]);

  function onSelectedStrategyChange(event: any) {
    const { options } = event.target as HTMLSelectElement;
    const values = [];
    for (let i = 0; i < options.length; ++i) {
      const option = options[i];
      if (option.selected) {
        values.push({ id: option.value, name: option.text });
      }
    }
    console.log("SELECTED STRATEGIES", values);
    setSelectedStrategies(values);
  }

  function onSelectedInvestmentChange(event: any) {
    const { options } = event.target as HTMLSelectElement;
    const values = [...options].flatMap(option =>
      option.selected
        ? {
            id: Number(option.value),
            // remove the ID from the name
            name: option.text.substring(0, option.text.lastIndexOf("[") - 1),
          }
        : []
    );
    console.log("SELECTED INVESTMENTS", values);
    setSelectedInvestments(values);
  }

  function onAdd() {
    onPeersChange("add", selectedInvestments);
  }

  function onRemove() {
    onPeersChange("remove", selectedInvestments);
  }

  return (
    <CustomEdit<Record>
      title={record => record && `Peers - ${record.name}`}
      sourcedFromOdc={(record?: Record) =>
        !!record?.portalSubmitted?.migratedAt
      }
      customFormProps={{
        customToolbarProps: {
          canAccessMutation: canEdit,
        },
        loading,
        canEditField,
      }}
      actions={
        <CustomBreadCrumb<Record>
          name="investmentPeers"
          items={[
            {
              path: "firm",
              getId: record => record?.firm?.id,
              getName: record => record?.firm?.name,
            },
            {
              path: "investment",
              getId: record => record?.id,
              getName: record => record?.name,
            },
          ]}
        />
      }
    >
      <Grid container>
        <Grid item xs={12}>
          <NavSearch name="investmentPeers" />
        </Grid>
        <Grid item xs={12}>
          <Accordion
            expanded={expandedIndex === 0}
            onChange={(e, expand) => setExpandedIndex(expand ? 0 : -1)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon data-cy="expandBulkImport" />}
              style={{
                flexDirection: "row-reverse",
              }}
            >
              Bulk Import
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <RadioGroup
                    row
                    name="bulkImport"
                    defaultValue="fromInvestment"
                    value={bulkSource}
                    onChange={e => {
                      setBulkSource(e.target.value as any);
                      setBulkFromInvestment(void 0);
                      setBulkFromInvestmentMessage("");
                      setBulkFromEntryIds([]);
                      setBulkFromEntryError("");
                      setBulkFromEntryMessage("");
                    }}
                  >
                    <FormControlLabel
                      value="fromInvestment"
                      control={<Radio data-cy="rbFromInvestment" />}
                      label="From Investment"
                    />
                    <FormControlLabel
                      value="fromTextEntry"
                      control={<Radio data-cy="rbFromTextEntry" />}
                      label="From Text Entry"
                    />
                  </RadioGroup>
                </Grid>
                {bulkSource === "fromInvestment" && (
                  <>
                    <Grid item xs={12}>
                      Select the investment from which to import peers. The
                      import will merge the peers into the list. The changes
                      will not be saved automatically.
                    </Grid>
                    <Grid item xs={12}>
                      <EntityAutoComplete
                        inputId="bulkFromInvestment"
                        resource="investment"
                        label="Source Investment"
                        sourceKey="id"
                        recordKey="investment"
                        onChange={value => {
                          setBulkFromInvestment(
                            value ? (value as IdName) : void 0
                          );
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        disabled={!bulkFromInvestment}
                        onClick={async () => {
                          setBulkFromInvestmentMessage("Working...");
                          if (bulkFromInvestment) {
                            // fetch the peers of the source investment
                            const {
                              data: {
                                peersList: { items },
                              },
                            } = await dataProvider.getOne<
                              client.GetOneInvestmentPeersQuery["investment"]
                            >("investmentPeers", {
                              id: bulkFromInvestment.id,
                            });
                            onPeersChange("add", items);
                            setBulkFromInvestmentMessage(
                              `Imported ${items.length} peers from ${bulkFromInvestment.name}. Changes have NOT been saved.`
                            );
                          }
                        }}
                        title="Import Peers"
                        data-cy="btnBulkFromInvestment"
                        startIcon={<AddIcon />}
                      >
                        Import Peers
                      </Button>
                    </Grid>
                    {bulkFromInvestmentMessage && (
                      <Grid item xs={12}>
                        {bulkFromInvestmentMessage}
                      </Grid>
                    )}
                  </>
                )}
                {bulkSource === "fromTextEntry" && (
                  <>
                    <Grid item xs={12}>
                      Enter the peer investment IDs below, one per line. The
                      import will merge the peers into the list. The changes
                      will not be saved automatically.
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        name="bulkFromEntry"
                        multiline
                        rows={10}
                        fullWidth
                        variant="outlined"
                        onChange={e => {
                          const text = e.target.value;
                          const lines = text.split("\n").flatMap(x => {
                            x = x.trim();
                            return x ? [x] : [];
                          });
                          let error = "";
                          const values = new Set<number>();
                          for (const line of lines) {
                            const id = Number(line);
                            if (isFinite(id) && id === Math.trunc(id)) {
                              values.add(id);
                            } else {
                              error = `Invalid ID: ${line}`;
                              break;
                            }
                          }
                          if (!error) setBulkFromEntryIds([...values]);
                          setBulkFromEntryError(error);
                        }}
                      />
                    </Grid>
                    {bulkFromEntryError && (
                      <Grid item xs={12}>
                        {bulkFromEntryError}
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <Button
                        disabled={
                          !bulkFromEntryIds.length || !!bulkFromEntryError
                        }
                        data-cy="btnBulkFromEntry"
                        onClick={async () => {
                          setBulkFromEntryMessage("Working...");
                          if (bulkFromEntryIds.length) {
                            const { data: items } = await dataProvider.getMany<
                              NonNullable<
                                client.GetManyInvestmentQuery["investmentMany"][number]
                              >
                            >("investment", {
                              ids: bulkFromEntryIds,
                            });
                            const ids = new Map(
                              items.flatMap(x =>
                                x ? [[x.id, { id: x.id, name: x.name }]] : []
                              )
                            );
                            const errors = bulkFromEntryIds.flatMap(id =>
                              ids.has(id) ? [] : [id]
                            );
                            if (errors.length) {
                              setBulkFromEntryMessage(
                                `Unable to match ${
                                  errors.length
                                } ID(s): ${errors.join(", ")}`
                              );
                              return;
                            }
                            onPeersChange(
                              "add",
                              items.map(x => ({ id: x.id, name: x.name }))
                            );
                            setBulkFromEntryMessage(
                              `Imported ${items.length} peers. Changes have NOT been saved.`
                            );
                          }
                        }}
                        title="Import Peers"
                        startIcon={<AddIcon />}
                      >
                        Import Peers
                      </Button>
                    </Grid>
                    {bulkFromEntryMessage && (
                      <Grid item xs={12}>
                        {bulkFromEntryMessage}
                      </Grid>
                    )}
                  </>
                )}
              </Grid>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expandedIndex === 1}
            onChange={(e, expand) => setExpandedIndex(expand ? 1 : -1)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon data-cy="expandStrategySelect" />}
              style={{
                flexDirection: "row-reverse",
              }}
            >
              Select by Strategy
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <FormControl variant="standard" fullWidth>
                    <InputLabel shrink htmlFor="peers-strategy-select">
                      Strategies
                    </InputLabel>
                    <Select
                      variant="standard"
                      multiple
                      native
                      inputProps={{
                        id: "peers-strategy-select",
                        style: { height: 200 },
                      }}
                      onChange={onSelectedStrategyChange}
                      style={{ border: "solid 1px lightgray" }}
                    >
                      {strategies?.map((strategy: any) => (
                        <>
                          <option
                            key={`0:${strategy.id}`}
                            value={`0:${strategy.id}`}
                            title={strategy.name}
                            style={{ fontWeight: "bold" }}
                          >
                            {strategy.name}
                          </option>
                          {strategy.subStrategies?.map((substrategy: any) => (
                            <option
                              key={`1:${substrategy.id}`}
                              value={`1:${substrategy.id}`}
                              title={substrategy.name}
                            >
                              &nbsp;&nbsp;&nbsp;&nbsp;{substrategy.name}
                            </option>
                          ))}
                        </>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl variant="standard" fullWidth>
                    <InputLabel shrink htmlFor="peers-investment-select">
                      Investments
                    </InputLabel>
                    <Select
                      variant="standard"
                      multiple
                      native
                      inputProps={{
                        id: "peers-investment-select",
                        style: { height: 200 },
                      }}
                      onChange={onSelectedInvestmentChange}
                      style={{ border: "solid 1px lightgray" }}
                    >
                      {investments?.map((investment: any) => (
                        <option
                          key={`${investment.id}`}
                          value={`${investment.id}`}
                          title={`${investment.name} [${investment.id}]`}
                        >
                          {investment.name} [{investment.id}]
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6}></Grid>
                <Grid item xs={6}>
                  {canEdit && (
                    <Grid container>
                      <Grid item xs={6}>
                        <Button
                          onClick={onAdd}
                          title="Add selected investments to peers list"
                          data-id="peers-bulk-add"
                          color="success"
                          fullWidth
                          startIcon={<AddIcon />}
                        >
                          Add to List
                        </Button>
                      </Grid>
                      <Grid item xs={6}>
                        <Button
                          onClick={onRemove}
                          title="Remove selected investments from peers list"
                          data-id="peers-bulk-remove"
                          color="warning"
                          fullWidth
                          startIcon={<RemoveIcon />}
                        >
                          Remove from List
                        </Button>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>

        <Grid item xs={6}>
          {!canEdit && loading && <CircularProgress />}
        </Grid>
        <Grid item xs={12} sx={{ mt: 3 }}>
          <PeersInput
            subscribePeersChange={setOnPeersChange}
            canEdit={canEdit}
          />
        </Grid>
      </Grid>
    </CustomEdit>
  );
};
