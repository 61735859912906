import React from "react";
import * as client from "_graphql-types";
import { EntityInput, EntityInputProps } from "../UI/EntityInput";

type Record = client.GetOneFirmFdpLoginsQuery["firmFDPLogins"];

export const FDPLoginInput = ({
  isRequired,
  filters,
}: {
  isRequired: boolean;
  filters?: EntityInputProps["filters"];
}) => (
  <EntityInput<NonNullable<Record>>
    label="FDPLogin"
    recordKey="fdpLogin"
    resource="fdpLogin"
    sourceKey="fdpLoginId"
    filters={filters}
    getChoice={record => {
      return {
        id: record.id,
        name: [record.financialDataProvider.name, " - ", record.username].join(
          ""
        ),
      };
    }}
    isRequired={isRequired}
    sort={{
      field: client.FdpLoginSortEnum.NameSearchRank,
      order: client.SortInput.Asc,
    }}
    allowChange
  />
);
