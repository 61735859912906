import * as client from "_graphql-types";
import * as types from "./types";
import { isDefinedAndNotNull } from "frontend/src/utils/helpers";
import { DefinedAtKey } from "../helpers/types";

type Sdk = ReturnType<typeof client.getSdk>;

interface GetManyArgs {
  ids: number[];
  meta?: GetManyMetaArgs;
}

interface GetManyMetaArgs {
  isPrivate: boolean;
  isGross: boolean;
  isQtd: boolean;
  asOfDate: Date;
}

export interface PerformanceMultiInvestmentEntry {
  id: number;
  currency?: {
    id: number;
    name: string;
  };
  returnValue?: number | null;
  periodYearReturns?: number[] | null;
}

function getPreviousReturns<T, K extends keyof T>(
  performanceData: T[],
  returnKey: K
) {
  return performanceData
    .filter((p): p is DefinedAtKey<T, K> => isDefinedAndNotNull(p[returnKey]))
    .map(data => data[returnKey]);
}

async function getMany(sdk: Sdk, { ids, meta }: GetManyArgs) {
  const { isPrivate, isGross, isQtd, asOfDate } = meta!;
  const data: PerformanceMultiInvestmentEntry[] = [];
  if (isPrivate) {
    const maxAsOfDate = new Date(
      Date.UTC(
        asOfDate.getUTCFullYear(),
        Math.trunc(asOfDate.getUTCMonth() / 3) * 3,
        1
      )
    );
    // there is no currency so no need to get prior month
    const { investmentMany } = await sdk.getManyPerformanceIrr({
      investmentIds: ids,
      minAsOfDate: maxAsOfDate.toISOString(),
      maxAsOfDate: maxAsOfDate.toISOString(),
      limit: 1,
      offset: 0,
    });
    investmentMany.forEach(investment => {
      if (investment) {
        const perf = investment.performancePrivate?.[0];
        if (perf) {
          data.push({
            id: investment.id,
            returnValue: perf.netIRR,
          });
        }
      }
    });
    return { data };
  }
  if (isQtd) {
    const maxAsOfDate = new Date(
      Date.UTC(
        asOfDate.getUTCFullYear(),
        Math.trunc(asOfDate.getUTCMonth() / 3) * 3,
        1
      )
    );
    // there is no currency so no need to get prior month
    const { investmentMany } = await sdk.getManyPerformanceTwrrQtd({
      investmentIds: ids,
      returnType: isGross ? client.ReturnTypes.Gross : client.ReturnTypes.Net,
      minAsOfDate: maxAsOfDate.toISOString(),
      maxAsOfDate: maxAsOfDate.toISOString(),
      limit: 1,
      offset: 0,
    });
    investmentMany.forEach(investment => {
      if (investment) {
        const perf = investment.performanceQuarterly?.[0];
        if (perf) {
          data.push({
            id: investment.id,
            returnValue: perf.return,
          });
        }
      }
    });
    return { data };
  }
  // compares on month
  console.log(
    ">>> asOfDate",
    asOfDate.getUTCFullYear(),
    asOfDate.getUTCMonth() + 1,
    asOfDate.getUTCDate()
  );
  const maxAsOfDate = new Date(
    Date.UTC(asOfDate.getUTCFullYear(), asOfDate.getUTCMonth(), 1)
  );
  const minAsOfDate = new Date(
    Date.UTC(maxAsOfDate.getUTCFullYear(), maxAsOfDate.getUTCMonth() - 1, 1)
  );

  const startOfPeriodYear = new Date(
    Date.UTC(asOfDate.getUTCFullYear(), 0, 1)
  ).toISOString();

  const { investmentMany } = await sdk.getManyPerformanceTwrrMtd({
    investmentIds: ids,
    returnType: isGross ? client.ReturnTypes.Gross : client.ReturnTypes.Net,
    minAsOfDate: minAsOfDate.toISOString(),
    maxAsOfDate: maxAsOfDate.toISOString(),
  });
  const { investmentMany: investmentManyYearToDate } =
    await sdk.getManyPerformanceTwrrMtd({
      investmentIds: ids,
      returnType: isGross ? client.ReturnTypes.Gross : client.ReturnTypes.Net,
      minAsOfDate: startOfPeriodYear,
      maxAsOfDate: maxAsOfDate.toISOString(),
    });
  console.log({ investmentMany });
  console.log({ investmentManyYearToDate });
  investmentMany.forEach(investment => {
    const performanceFromYear = investmentManyYearToDate.find(
      i => i?.id === investment?.id
    );
    if (investment) {
      const perf = investment.performanceMTD?.[0];
      if (perf) {
        const { asOfDate: asOfDateStr, currency, return: ret } = perf;
        const asOfDate = new Date(asOfDateStr);
        // return comes from current period only
        console.log(
          "maxAsOfDate",
          maxAsOfDate.getUTCFullYear(),
          maxAsOfDate.getUTCMonth() + 1,
          maxAsOfDate.getUTCDate()
        );
        console.log(
          "asOfDate",
          asOfDate.getUTCFullYear(),
          asOfDate.getUTCMonth() + 1,
          asOfDate.getUTCDate()
        );
        const returnValue =
          asOfDate.getUTCFullYear() === maxAsOfDate.getUTCFullYear() &&
          asOfDate.getUTCMonth() === maxAsOfDate.getUTCMonth()
            ? ret
            : null;
        console.log("RETURN VALUE", ret, returnValue);
        data.push({
          id: investment.id,
          // currency comes from latest entry in current or previous period
          currency,
          returnValue,
          periodYearReturns: getPreviousReturns(
            performanceFromYear?.performanceMTD ?? [],
            "return"
          ),
        });
      }
    }
  });
  return { data };
}

export interface ManualUpdateData {
  asOfDate: Date;
  newValues: Map<number, number | null | undefined>;
  oldValues: Map<number, PerformanceMultiInvestmentEntry>;
}

export interface BulkUpdateData {
  investmentId: number;
  asOfDate: Date;
  returnValue: number | null | undefined;
}

export interface UpdateArgs {
  id: 0;
  data: {
    type: "private" | "qtd" | "mtd";
    returnType: client.ReturnTypes;
    data:
      | { type: "manual"; data: ManualUpdateData }
      | { type: "bulk"; data: BulkUpdateData[] };
  };
  previousData?: { id: 0 };
}

async function updateBulk(
  sdk: Sdk,
  type: UpdateArgs["data"]["type"],
  returnType: client.ReturnTypes,
  data: BulkUpdateData[]
) {
  switch (type) {
    case "private": {
      const map = new Map<number, client.PerformancePrivateShort[]>();
      for (const { investmentId, asOfDate, returnValue } of data) {
        const returnYear = asOfDate.getFullYear();
        const returnQuarter = Math.trunc(asOfDate.getMonth() / 3) + 1;
        let quarters = map.get(investmentId);
        if (!quarters) {
          quarters = [];
          map.set(investmentId, quarters);
        }
        quarters.push({
          returnYear,
          returnQuarter,
          netIRR: returnValue,
        });
      }
      const input: client.ManyPerformancePrivate[] = [...map].map(
        ([investmentId, quarters]) => ({
          investmentId,
          quarters,
        })
      );
      await sdk.bulkUpsertPerformancePrivate({ input });
      return { data: { id: 0 } };
    }
    case "qtd": {
      const map = new Map<number, client.PerformanceQuarterlyShort[]>();
      for (const { investmentId, asOfDate, returnValue } of data) {
        const returnYear = asOfDate.getFullYear();
        const returnQuarter = Math.trunc(asOfDate.getMonth() / 3) + 1;
        let quarters = map.get(investmentId);
        if (!quarters) {
          quarters = [];
          map.set(investmentId, quarters);
        }
        quarters.push({
          returnYear,
          returnQuarter,
          fundReturn: returnValue,
        });
      }
      const input: client.ManyPerformanceQuarterly[] = [...map].map(
        ([investmentId, quarters]) => ({
          investmentId,
          returnType,
          quarters,
        })
      );
      await sdk.bulkUpsertPerformanceQuarterly({
        input,
      });
      return { data: { id: 0 } };
    }
    case "mtd": {
      const map = new Map<number, client.PerformanceMtdShort[]>();
      for (const { investmentId, asOfDate, returnValue } of data) {
        const returnYear = asOfDate.getFullYear();
        const returnMonth = asOfDate.getMonth() + 1;
        let months = map.get(investmentId);
        if (!months) {
          months = [];
          map.set(investmentId, months);
        }
        months.push({
          returnYear,
          returnMonth,
          asOfDate: asOfDate.toISOString(),
          fundReturn: returnValue,
        });
      }
      const input: client.ManyPerformanceMtd[] = [...map].map(
        ([investmentId, months]) => ({
          investmentId,
          returnType,
          months,
        })
      );
      await sdk.bulkUpsertPerformanceMTD({ input });
      return { data: { id: 0 } };
    }
  }
}

async function updateManual(
  sdk: Sdk,
  type: UpdateArgs["data"]["type"],
  returnType: client.ReturnTypes,
  data: ManualUpdateData
) {
  const { asOfDate, newValues, oldValues } = data;
  switch (type) {
    case "private": {
      const returnYear = asOfDate.getFullYear();
      const returnQuarter = Math.trunc(asOfDate.getMonth() / 3) + 1;
      const input: client.ManyPerformancePrivate[] = [...newValues].map(
        ([investmentId, netIRR]) => ({
          investmentId,
          quarters: [
            {
              returnYear,
              returnQuarter,
              netIRR,
            },
          ],
        })
      );
      await sdk.bulkUpsertPerformancePrivate({ input });
      return { data: { id: 0 } };
    }
    case "qtd": {
      const returnYear = asOfDate.getFullYear();
      const returnQuarter = Math.trunc(asOfDate.getMonth() / 3) + 1;
      const input: client.ManyPerformanceQuarterly[] = [...newValues].map(
        ([investmentId, fundReturn]) => ({
          investmentId,
          returnType,
          quarters: [
            {
              returnYear,
              returnQuarter,
              fundReturn,
            },
          ],
        })
      );
      await sdk.bulkUpsertPerformanceQuarterly({
        input,
      });
      return { data: { id: 0 } };
    }
    case "mtd": {
      const returnYear = asOfDate.getFullYear();
      const returnMonth = asOfDate.getMonth() + 1;
      const input: client.ManyPerformanceMtd[] = [...newValues].map(
        ([investmentId, fundReturn]) => ({
          investmentId,
          returnType,
          currencyId: oldValues.get(investmentId)?.currency?.id ?? 1,
          months: [
            {
              returnYear,
              returnMonth,
              asOfDate: asOfDate.toISOString(),
              fundReturn,
            },
          ],
        })
      );
      await sdk.bulkUpsertPerformanceMTD({ input });
      return { data: { id: 0 } };
    }
  }
}

function update(sdk: Sdk, args: UpdateArgs) {
  const { data, returnType, type } = args.data;
  switch (data.type) {
    case "manual": {
      return updateManual(sdk, type, returnType, data.data);
    }
    case "bulk": {
      return updateBulk(sdk, type, returnType, data.data);
    }
  }
}

export const performanceMultiInvestment = types.dataProvider({
  getMany,
  update: update as types.DataProvider["update"],
});
