import {
  AutocompleteInput,
  BooleanInput,
  Filter,
  ReferenceInput,
  TextInput,
} from "react-admin";

export const ServiceProviderFirmFilter = () => (
  <Filter>
    <TextInput
      label="Name Search"
      source="q"
      alwaysOn
      style={{ width: 500 }}
      resettable
    />
    <ReferenceInput
      reference="institutionTypeEnum"
      sort={{ field: "nameSearchRank", order: "ASC" }}
      source="institutionTypeEnumId"
      pagination={{ perPage: 50 }}
      alwaysOn
    >
      <AutocompleteInput optionText="name" label="Type" />
    </ReferenceInput>
    <BooleanInput source="isEvaluating" label="Evaluating" alwaysOn />
  </Filter>
);
