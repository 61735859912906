import * as client from "_graphql-types";

import { Tooltip } from "@mui/material";
import Grid from "@mui/material/Grid";
import { NO_WHITESPACE_REGEX } from "frontend/src/utils/constants";
import {
  AutocompleteInput,
  BooleanInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  TextInput,
  regex,
  required,
} from "react-admin";
import { CustomCreate } from "../CustomCreate";
import { CustomTagsInput } from "../CustomTagsInput";
import { validateYearFounded } from "../firm/Edit";
import { ImpactSelection } from "./ImpactSelect";

import { useCanAccessMutation } from "../../util/useCanAccessMutation";

type Record = NonNullable<client.GetOneCompanyQuery["company"]>;

export const PrimaryAddressInput = () => (
  <Grid container spacing={1}>
    <Grid item xs={12}>
      <TextInput label="Line 1" source="firmAttributes.primaryAddress.line1" />
    </Grid>
    <Grid item xs={12}>
      <TextInput label="Line 2" source="firmAttributes.primaryAddress.line2" />
    </Grid>
    <Grid item xs={12}>
      <TextInput label="Line 3" source="firmAttributes.primaryAddress.line3" />
    </Grid>
    <Grid item xs={4}>
      <TextInput label="City" source="firmAttributes.primaryAddress.city" />
    </Grid>
    <Grid item xs={4}>
      <ReferenceInput
        reference="stateEnum"
        sort={{ field: "name", order: "ASC" }}
        perPage={100}
        source="firmAttributes.primaryAddress.stateEnumId"
      >
        <SelectInput optionText="name" label="US State" />
      </ReferenceInput>
    </Grid>
    <Grid item xs={4}>
      <TextInput
        label="Province"
        source="firmAttributes.primaryAddress.otherState"
      />
    </Grid>
    <Grid item xs={4}>
      <TextInput
        label="Zip Code"
        source="firmAttributes.primaryAddress.zipCode"
      />
    </Grid>
    <Grid item xs={4}>
      <ReferenceInput
        reference="countryEnum"
        sort={{ field: "name", order: "ASC" }}
        perPage={1000}
        source="firmAttributes.primaryAddress.countryEnumId"
      >
        <SelectInput optionText="name" label="Country" />
      </ReferenceInput>
    </Grid>
    <Grid item xs={4}></Grid>
    <Grid item xs={4}>
      <TextInput
        label="Phone Number"
        source="firmAttributes.primaryAddress.phone"
      />
    </Grid>
    <Grid item xs={4}>
      <TextInput
        label="Phone Number 2"
        source="firmAttributes.primaryAddress.phone2"
      />
    </Grid>
    <Grid item xs={4}></Grid>
    <Grid item xs={4}>
      <TextInput
        label="Fax Number"
        source="firmAttributes.primaryAddress.fax"
      />
    </Grid>
    <Grid item xs={4}>
      <TextInput
        label="Fax Number 2"
        source="firmAttributes.primaryAddress.fax2"
      />
    </Grid>
    <Grid item xs={4}></Grid>
  </Grid>
);

// This needs to be a constant variable, to prevent POSTs on every rerender.
const COMPANY_TAG_CLASS_IDS = [22];

const validateRequiredNoWhitespace = [
  required(),
  regex(NO_WHITESPACE_REGEX, "Please remove leading and trailing spaces"),
];

export const CompanyCreate = () => {
  const { canEdit, canEditField, loading } = useCanAccessMutation(
    "addCompany",
    JSON.stringify({})
  );
  return (
    <CustomCreate
      title={() => "Create Company"}
      customFormProps={{
        loading,
        customToolbarProps: {
          canAccessMutation: canEdit,
        },
        canEditField,
      }}
    >
      <Grid data-cy="company__create" container spacing={2}>
        <Grid item xs={6}>
          <TextInput
            label="Legal Name"
            source="name"
            validate={validateRequiredNoWhitespace}
          />
        </Grid>
        <Grid item xs={6}>
          <NumberInput
            label="Year Founded"
            source="firmAttributes.yearFounded"
            validate={validateYearFounded}
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput label="Stock Symbol" source="firmAttributes.stockSymbol" />
        </Grid>
        <Grid item xs={6}>
          <ReferenceInput
            source="lastFundingTypeEnumId"
            perPage={100}
            reference="fundingTypeEnum"
            sort={{ field: "name", order: "ASC" }}
          >
            <SelectInput
              fullWidth={true}
              optionText="name"
              label="Last Funding Type"
            />
          </ReferenceInput>
        </Grid>
        <Grid item xs={6}>
          <ReferenceInput
            source="operatingStatusEnumId"
            reference="operatingStatusEnum"
            sort={{ field: "name", order: "ASC" }}
          >
            <SelectInput
              fullWidth={true}
              optionText="name"
              label="Operating Status"
            />
          </ReferenceInput>
        </Grid>
        <Grid item xs={6}>
          <ReferenceInput
            source="companyTypeEnumId"
            reference="companyTypeEnum"
            sort={{ field: "name", order: "ASC" }}
          >
            <SelectInput
              fullWidth={true}
              optionText="name"
              label="Company Type"
            />
          </ReferenceInput>
        </Grid>
        <Grid item xs={6}>
          <TextInput source="firmAttributes.email" label="Company Email" />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextInput
            source="firmAttributes.linkedIn"
            label="Company LinkedIn"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextInput source="firmAttributes.website" label="Company Website" />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <ReferenceInput
            source="primaryAssignment"
            reference="investmentAssignmentOption"
            perPage={100}
            sort={{ field: "commonName", order: "ASC" }}
            filter={{ isPrimary: true }}
          >
            <AutocompleteInput
              optionText="commonName"
              label="Primary Assignment"
            />
          </ReferenceInput>
        </Grid>
        <Grid item xs={12}>
          <TextInput
            label="Company Summary"
            source="summary"
            multiline
            rows={5}
          />
        </Grid>
        <Grid item xs={3}>
          <BooleanInput label="Is MWDBE" source="firmAttributes.isStaffMWDBE" />
        </Grid>
        <Grid item xs={12}>
          <hr />
          <h3>Company Tags</h3>
        </Grid>
        <CustomTagsInput
          source="firmAttributes.firmTags"
          label="Company Tags"
          tagClassIdList={COMPANY_TAG_CLASS_IDS}
        />
        <Grid item xs={12}>
          <hr />
          <h3>Impact Tags</h3>
        </Grid>
        <ImpactSelection source="firmAttributes.impactTags" />
        <Grid item xs={12}>
          <hr />
          <h3>Company Employee Summary</h3>
        </Grid>
        <Grid item xs={1}>
          <NumberInput label="Total" source="employeeTotal" />
        </Grid>
        <Grid item xs={12}>
          <hr />
          <h3>Address</h3>
        </Grid>
        <Grid item xs={12}>
          <PrimaryAddressInput />
        </Grid>
        <Grid item xs={12}>
          <hr />
        </Grid>
        <Grid item xs={4} lg={3}>
          <Tooltip
            title="Has RockCreek signed an NDA precluding them from sharing company data with 3rd Parties?"
            placement="bottom"
          >
            <div>
              <BooleanInput label="NDA Signed" source="ndaSigned" />
            </div>
          </Tooltip>
        </Grid>
      </Grid>
    </CustomCreate>
  );
};
