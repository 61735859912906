import * as client from "_graphql-types";
import * as types from "./types";

import { filterSortPage } from "../DataProvider";
import { getChangedFields } from "../helpers/diff";

type Sdk = ReturnType<typeof client.getSdk>;

/*********************************************************************/
// Data Provider Functions
/*********************************************************************/

/*********************************************************************/
async function getList(
  sdk: Sdk,
  params: types.GetListParams<client.OrganizationSortEnum>
) {
  const { pagination, sort, filter } = params;

  const {
    organizationList: { items: data, total },
  } = await sdk.getListOrganization(
    filterSortPage({ filter, sort, pagination })
  );

  return {
    data,
    total,
  };
}

/*********************************************************************/
async function getOne(sdk: Sdk, params: types.GetOneParams) {
  const id = Number(params.id);
  const { organization: data } = await sdk.getOneOrganization({
    id,
  });
  return {
    data,
  };
}
/*********************************************************************/

async function getMany(sdk: Sdk, params: types.GetManyParams) {
  const ids = params.ids.map(id => Number(id));

  const { organizationList: data } = await sdk.getListOrganization({
    filter: { ids },
  });

  return {
    data: data.items.filter(item => item.id),
  };
}

/*********************************************************************/
async function getManyReference(
  sdk: Sdk,
  params: types.GetManyReferenceParams<client.OrganizationSortEnum>
) {
  const id = Number(params.id);
  const {
    pagination: { page, perPage },
    sort: { field, order },
    filter,
    target,
  } = params;

  const {
    organizationList: { items: data, total },
  } = await sdk.getListOrganization({
    page: {
      offset: (page - 1) * perPage,
      limit: perPage,
    },
    sort: [{ field, order }],
    filter: { ...filter, [target]: id },
  });

  return {
    data,
    total,
  };
}

/*********************************************************************/
async function update(
  sdk: Sdk,
  params: types.UpdateParams<
    NonNullable<client.GetOneOrganizationQuery["organization"]>
  >
) {
  const id = Number(params.id);

  const input: client.UpdateOrganizationMutationVariables["input"] =
    params.data;

  await sdk.updateOrganization({
    id,
    input: {
      name: input.name,
      clientId: input.clientId,
      orgLinks: params.data.orgLinks?.map(({ id, link, ...input }) => input),
    },
  });
  return await getOne(sdk, { id });
}

/*********************************************************************/
export const organization = types.dataProvider({
  getList,
  getOne,
  getManyReference,
  update,
  getMany,
});
