import * as client from "_graphql-types";
import {
  useRecordContext,
  SimpleForm,
  TextInput,
  SelectInput,
  SimpleFormIterator,
  ReferenceInput,
  useEditController,
  SaveHandler,
  Toolbar,
  SaveButton,
} from "react-admin";
import { ReferenceManyInput } from "@react-admin/ra-relationships";
import { FieldValues } from "react-hook-form";

type Record = NonNullable<client.GetOneFdpLoginQuery["fdpLogin"]>;
type FRecord = NonNullable<client.GetOneFirmFdpLoginsQuery["firmFDPLogins"]>;
type IRecord = NonNullable<
  client.GetOneInvestmentFdpLoginsQuery["investmentFDPLogins"]
>;
type PRecord = NonNullable<
  client.GetOnePortfolioFdpLoginsQuery["portfolioFDPLogins"]
>;

function handleSave(data: FieldValues, saveHandler: SaveHandler<any>) {
  // follows the temporaryFieldName pattern from ra-relationships
  const {
    "@@ra-many/fdpLogin/firmFDPLogins/fdpLoginId": raManyFirmFDPLogins,
    "@@ra-many/fdpLogin/investmentFDPLogins/fdpLoginId":
      raManyInvestmentFDPLogins,
    "@@ra-many/fdpLogin/portfolioFDPLogins/fdpLoginId":
      raManyPortfolioFDPLogins,
    ...rest
  } = data;
  const _data = rest as Record;

  const _raManyFirmFDPLogins = raManyFirmFDPLogins as
    | [{ firmFDPLogins: FRecord[] }]
    | undefined;

  const _raManyInvestmentFDPLogins = raManyInvestmentFDPLogins as
    | [{ investmentFDPLogins: IRecord[] }]
    | undefined;

  const _raManyPortfolioFDPLogins = raManyPortfolioFDPLogins as
    | [{ portfolioFDPLogins: PRecord[] }]
    | undefined;

  const firmFDPLogins = _raManyFirmFDPLogins?.[0]?.firmFDPLogins || [];
  const investmentFDPLogins =
    _raManyInvestmentFDPLogins?.[0]?.investmentFDPLogins || [];
  const portfolioFDPLogins =
    _raManyPortfolioFDPLogins?.[0]?.portfolioFDPLogins || [];
  const parsedData = {
    ..._data,
    firmFDPLogins: firmFDPLogins.map((item: FRecord) => ({
      id: item.id,
      firmId: item.firmId,
      fdpWebsiteId: item.fdpWebsiteId,
      fdpLoginId: item.fdpLoginId,
    })),
    investmentFDPLogins: investmentFDPLogins.map((i: IRecord) => ({
      id: i.id,
      investmentId: i.investmentId,
      fdpLoginId: i.fdpLoginId,
      fdpWebsiteId: i.fdpWebsiteId,
    })),
    portfolioFDPLogins: portfolioFDPLogins.map((i: PRecord) => ({
      id: i.id,
      portfolioId: i.portfolioId,
      fdpLoginId: i.fdpLoginId,
      fdpWebsiteId: i.fdpWebsiteId,
    })),
  };
  return saveHandler(parsedData);
}

export const CustomToolbar = () => (
  <Toolbar sx={{ justifyContent: "space-between" }}>
    <SaveButton />
  </Toolbar>
);

export const DialogForm = () => {
  const record = useRecordContext<Record>();
  if (!record) return;

  const { save } = useEditController({
    resource: "fdpLogin",
    id: record.id,
  });

  return (
    <SimpleForm
      toolbar={<CustomToolbar />}
      onSubmit={(data: FieldValues) => !!save && handleSave(data, save)}
    >
      <ReferenceManyInput
        reference="firmFDPLogins"
        target="fdpLoginId"
        label="Firm Relationships"
        sort={{ field: "firmId", order: "ASC" }}
        sx={{
          marginTop: "1em",
          "& label.RaReferenceManyInput-label.MuiFormLabel-root": {
            fontSize: "1.5rem",
          },
        }}
      >
        <SimpleFormIterator
          inline
          disableAdd
          disableRemove
          sx={{
            "& .RaSimpleFormIterator-list>li": {
              marginTop: "1em",
            },
            "& .RaSimpleFormIterator-list>li:first-child": {
              marginTop: 0,
            },
          }}
        >
          <TextInput source="firm.name" label="Firm" readOnly />
          <ReferenceInput
            source="fdpWebsiteId"
            reference="fdpWebsites"
            sort={{ field: "website", order: "ASC" }}
            filter={{
              financialDataProviderId: record.financialDataProvider.id,
            }}
            perPage={100}
          >
            <SelectInput
              optionValue="id"
              optionText="website"
              label="Website"
            />
          </ReferenceInput>
        </SimpleFormIterator>
      </ReferenceManyInput>

      <ReferenceManyInput
        reference="investmentFDPLogins"
        target="fdpLoginId"
        label="Investment Relationships"
        sort={{ field: "investmentId", order: "ASC" }}
        sx={{
          marginTop: "1em",
          "& label.RaReferenceManyInput-label.MuiFormLabel-root": {
            fontSize: "1.5rem",
          },
        }}
      >
        <SimpleFormIterator inline disableAdd disableRemove>
          <TextInput source="investment.name" label="Investment" disabled />
          <ReferenceInput
            source="fdpWebsiteId"
            reference="fdpWebsites"
            sort={{ field: "website", order: "ASC" }}
            filter={{
              financialDataProviderId: record.financialDataProvider.id,
            }}
            perPage={100}
          >
            <SelectInput
              optionValue="id"
              optionText="website"
              label="Website"
            />
          </ReferenceInput>
        </SimpleFormIterator>
      </ReferenceManyInput>
      <ReferenceManyInput
        reference="portfolioFDPLogins"
        target="fdpLoginId"
        label="Portfolio Relationships"
        sort={{ field: "portfolioId", order: "ASC" }}
        sx={{
          marginTop: "1em",
          "& label.RaReferenceManyInput-label.MuiFormLabel-root": {
            fontSize: "1.5rem",
          },
        }}
      >
        <SimpleFormIterator inline disableAdd disableRemove>
          <TextInput source="portfolio.name" label="Portfolio" disabled />
          <ReferenceInput
            source="fdpWebsiteId"
            reference="fdpWebsites"
            sort={{ field: "website", order: "ASC" }}
            filter={{
              financialDataProviderId: record.financialDataProvider.id,
            }}
            perPage={100}
          >
            <SelectInput
              optionValue="id"
              optionText="website"
              label="Website"
            />
          </ReferenceInput>
        </SimpleFormIterator>
      </ReferenceManyInput>
    </SimpleForm>
  );
};

export const DialogTitle = () => {
  const record = useRecordContext<Record>();
  if (!record) return;

  return (
    <span>
      Manage Login for <b>{record.username}</b> on FDP{" "}
      <b>{record.financialDataProvider.name}</b>
    </span>
  );
};
