import * as client from "_graphql-types";
import * as types from "./types";

type Sdk = ReturnType<typeof client.getSdk>;

/*********************************************************************/
// Data Provider Functions
/*********************************************************************/

/*********************************************************************/
async function getList(
  sdk: Sdk,
  params: types.GetListParams<client.InstitutionEmploymentSortEnum>
) {
  const {
    pagination: { page, perPage },
    sort: { field, order },
    filter,
  } = params;

  const {
    institutionEmploymentList: { items: data, total },
  } = await sdk.getListInstitutionEmployment({
    page: {
      offset: (page - 1) * perPage,
      limit: perPage,
    },
    sort: [],
    filter,
  });

  return {
    data,
    total,
  };
}

/*********************************************************************/
async function getOne(sdk: Sdk, params: types.GetOneParams) {
  const id = params.id;
  const { institutionEmployment: data } = await sdk.getOneInstitutionEmployment(
    {
      id: Number(id),
    }
  );
  return {
    data,
  };
}

/*********************************************************************/
async function getMany(sdk: Sdk, params: types.GetManyParams) {
  const ids = params.ids;

  const { institutionEmploymentMany: data } =
    await sdk.getManyInstitutionEmployment({
      ids: ids.map(Number),
    });

  return {
    data,
  };
}

/*********************************************************************/
async function getManyReference(
  sdk: Sdk,
  params: types.GetManyReferenceParams<client.InstitutionEmploymentSortEnum>
) {
  const id = params.id;
  const {
    pagination: { page, perPage },
    sort: { field, order },
    filter,
    target,
  } = params;

  const {
    institutionEmploymentList: { items: data, total },
  } = await sdk.getListInstitutionEmployment({
    page: {
      offset: (page - 1) * perPage,
      limit: perPage,
    },
    sort: [],
    filter: { ...filter, [target]: id },
  });

  return {
    data,
    total,
  };
}

/*********************************************************************/
async function create(
  sdk: Sdk,
  params: types.CreateParams<
    client.GetOneInstitutionEmploymentQuery["institutionEmployment"]
  >
) {
  const { data } = params;
  const input: client.CreateInstitutionEmploymentMutationVariables["input"] = {
    ...data,
  };

  const {
    addInstitutionEmployment: { id },
  } = await sdk.createInstitutionEmployment({
    input,
  });
  return await getOne(sdk, { id: String(id)! });
}

/*********************************************************************/
async function update(
  sdk: Sdk,
  params: types.UpdateParams<
    NonNullable<
      client.GetOneInstitutionEmploymentQuery["institutionEmployment"]
    >
  >
) {
  const id = params.id;
  const { data } = params;
  const { institution, person, id: _id, ...inputParams } = data;

  const input: client.UpdateInstitutionEmploymentMutationVariables["input"] = {
    ...inputParams,
  };

  await sdk.updateInstitutionEmployment({
    id: Number(id),
    input,
  });
  return await getOne(sdk, { id: String(id)! });
}

/*********************************************************************/
export const institutionEmployment = types.dataProvider({
  getList,
  getOne,
  getMany,
  getManyReference,
  create,
  update,
});
