import * as React from "react";
import { useState } from "react";
import { RaRecord, useTranslate } from "ra-core";
import {
  InputAdornment,
  IconButton,
  Typography,
  TextFieldProps,
  Stack,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { CopyButton } from "./CopyField";

export function PasswordField<TRecord extends RaRecord>({
  record,
  source,
  initiallyVisible = false,
  allowCopy = false,
}: {
  record?: TRecord;
  source: string;
  initiallyVisible?: boolean;
  allowCopy?: boolean;
}) {
  const [visible, setVisible] = useState(initiallyVisible);
  const translate = useTranslate();
  const value = record?.[source] || "";

  const obfuscatedSting = value.replace(/./g, "\u2022");

  const handleClick = () => {
    setVisible(!visible);
  };

  const ActionButtons = () => {
    const VisibilityButton = (
      <IconButton
        aria-label={translate(
          visible
            ? "ra.input.password.toggle_visible"
            : "ra.input.password.toggle_hidden"
        )}
        onClick={handleClick}
        size="small"
        color="primary"
        data-cy="password-visibility-button"
      >
        {visible ? (
          <Visibility sx={{ fontSize: "1rem" }} />
        ) : (
          <VisibilityOff sx={{ fontSize: "1rem" }} />
        )}
      </IconButton>
    );
    if (allowCopy) {
      return (
        <Stack direction="row" spacing="0">
          {VisibilityButton}
          <CopyButton value={value} source={source} />
        </Stack>
      );
    }
    return VisibilityButton;
  };

  return (
    <Stack
      direction={{ xs: "row" }}
      spacing={{ xs: 0.5 }}
      alignItems="baseline"
    >
      <Typography variant="body2">
        {visible ? value : obfuscatedSting}
      </Typography>
      <ActionButtons />
    </Stack>
  );
}
