import { Grid } from "@mui/material";
import {
  AutocompleteInput,
  BooleanInput,
  Datagrid,
  EditButton,
  ListBase,
  ReferenceInput,
  TextField,
  TextInput,
  regex,
  required,
} from "react-admin";
import { useWatch } from "react-hook-form";
import { CustomCreate } from "../CustomCreate";

const validateRequired = [required()];
const validateCommonName = [required(), regex(/^\S/)];
const SORT_BY_NAME = { field: "name", order: "ASC" } as const;

function MatchingUsers() {
  const email = useWatch({ name: "email" });

  return (
    <Grid item xs={8}>
      <h4>Matching Users</h4>
      <ListBase
        resource="user"
        filter={{ email }}
        sort={{ field: "name", order: "DESC" }}
        perPage={4}
        disableSyncWithLocation={true}
      >
        <Datagrid rowClick="edit" bulkActionButtons={false}>
          <TextField label="email" source="email" sortable={false} />
          <EditButton />
        </Datagrid>
      </ListBase>
    </Grid>
  );
}

function MatchingGroups() {
  const q = useWatch({ name: "commonName" });

  return (
    <Grid item xs={8}>
      <h4>Matching Groups</h4>
      <ListBase
        disableSyncWithLocation={true}
        resource="user"
        filter={{ q, isGroup: true }}
        sort={{ field: "name", order: "DESC" }}
        perPage={4}
      >
        <Datagrid rowClick="edit" bulkActionButtons={false}>
          <TextField label="commonName" source="commonName" sortable={false} />
          <EditButton />
        </Datagrid>
      </ListBase>
    </Grid>
  );
}

function GroupCreateForm() {
  return (
    <>
      <Grid container>
        <Grid item xs={6}>
          <TextInput
            label="Common Name"
            source="commonName"
            validate={validateCommonName}
          />
          <ReferenceInput
            source="organizationId"
            reference="organization"
            sort={SORT_BY_NAME}
            alwaysOn
            allowEmpty
          >
            <AutocompleteInput
              label="Organization Name"
              source="id"
              optionText="name"
            />
          </ReferenceInput>
          <TextInput label="Email" source="email" />
        </Grid>
        <Grid item xs={6}>
          <MatchingGroups />
        </Grid>
      </Grid>
    </>
  );
}

function UserCreateForm() {
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextInput label="Email" source="email" validate={validateRequired} />
          <ReferenceInput
            source="organizationId"
            reference="organization"
            sort={SORT_BY_NAME}
            alwaysOn
            allowEmpty
          >
            <AutocompleteInput
              label="Organization Name"
              source="id"
              optionText="name"
            />
          </ReferenceInput>
          <TextInput
            label="Common Name"
            source="commonName"
            validate={validateRequired}
          />
        </Grid>
        <Grid item xs={6}>
          <MatchingUsers />
        </Grid>
      </Grid>
    </>
  );
}

function UserAndGroupCreateForm() {
  const isGroup = useWatch<{ isGroup: boolean }>({
    name: "isGroup",
  });

  if (!isGroup) {
    return <UserCreateForm />;
  } else if (isGroup) {
    return <GroupCreateForm />;
  }

  return <></>;
}

export function UserAndGroupsCreate() {
  return (
    <CustomCreate title={() => `User or Group Create`} resource="user">
      <BooleanInput source="isGroup" label="Is User Group" />
      <UserAndGroupCreateForm />
    </CustomCreate>
  );
}
