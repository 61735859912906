import TextField, { TextFieldProps } from "@mui/material/TextField";
import { InputProps, RaRecord, useInput } from "react-admin";
import { NumericFormat } from "react-number-format";

export function CommaFormattedNumber(props: any) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumericFormat
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      {...other}
    />
  );
}

interface CustomProps {
  FormattedComponent: (props: any) => JSX.Element;
  record?: RaRecord;
}

type CustomInputProps = InputProps & CustomProps & TextFieldProps;

export function FormattedNumberInput({
  FormattedComponent,
  helperText = " ",
  inputProps = {},
  fullWidth,
  size = "medium",
  variant = "outlined",
  sx,
  ...restProps
}: CustomInputProps) {
  const {
    field: { name, onChange, ...rest },
    fieldState: { isTouched, error },
    isRequired,
  } = useInput({ source: restProps.source });

  return (
    <TextField
      label={restProps.label}
      variant={variant}
      size={size}
      fullWidth={fullWidth}
      onChange={onChange}
      name={name}
      error={!!(isTouched && error)}
      helperText={(isTouched && error?.message) || helperText}
      required={isRequired}
      inputProps={inputProps}
      InputProps={{
        inputComponent: FormattedComponent,
      }}
      sx={sx}
      {...rest}
    />
  );
}
