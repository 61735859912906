import {
  ReferenceInput,
  SelectInput,
  BooleanInput,
  NullableBooleanInput,
  NumberInput,
  TextInput,
  Button,
} from "react-admin";
import { Grid, Tooltip } from "@mui/material";
import NumberInputWithSymbol from "../NumberWithInputAdornment";
import { useWatch } from "react-hook-form";
import { secondLockupEndValueTranslation } from "../investment/util";

const cashAvailableDayOptions = [10, 15, 20, 30, 45, 60];
const cashAvailableDaysChoices = cashAvailableDayOptions.map(option => ({
  id: option,
  name: `After ${option} Days`,
}));

export function Liquidity({
  canEditField: _canEditField,
}: {
  canEditField: (key: string) => boolean;
}) {
  const [hasLockup, hasGate, hasHoldback] = useWatch({
    name: ["liquidity.hasLockup", "liquidity.hasGate", "liquidity.hasHoldback"],
  });

  function canEditField(field: string) {
    return _canEditField(`liquidity.${field}`);
  }

  return (
    <>
      <Grid container spacing={2} marginTop={"1em"}>
        <Grid item xs={6} md={6} lg={3}>
          <NumberInput
            source="liquidity.subscriptionValue"
            label="Subscription Frequency"
            disabled={!canEditField("subscriptionValue")}
          />
        </Grid>
        <Grid item xs={6} md={6} lg={3}>
          <ReferenceInput
            source="liquidity.subscriptionUnitId"
            reference="calendarUnitEnum"
            sort={{ field: "id", order: "ASC" }}
          >
            <SelectInput
              label="Unit"
              optionText="name"
              disabled={!canEditField("subscriptionUnitId")}
            />
          </ReferenceInput>
        </Grid>
        <Grid item xs={6} md={6} lg={3}>
          <NullableBooleanInput
            source="liquidity.hasLockup"
            label="Lockup?"
            disabled={!canEditField("hasLockup")}
            fullWidth
          />
        </Grid>
        <Grid item xs={6} md={6} lg={3}>
          <NumberInputWithSymbol
            source="liquidity.lockupValue"
            label="Lockup Period"
            disabled={!hasLockup || !canEditField("lockupValue")}
            symbol="Month"
            symbolAlignment="end"
          />
        </Grid>
        <Grid item xs={6} md={6} lg={3}>
          <ReferenceInput
            source="liquidity.lockupEndValueId"
            reference="lockupEndValueEnum"
          >
            <SelectInput
              label="First Redemption on"
              optionText="name"
              disabled={!canEditField("lockupEndValueId")}
            />
          </ReferenceInput>
        </Grid>
        <Grid item xs={6} md={6} lg={3}>
          <BooleanInput
            label="Last Business Day"
            source="liquidity.lockupEndBizDays"
          />
        </Grid>
        <Grid item xs={6} md={6} lg={3}>
          <ReferenceInput
            source="liquidity.secondLockupEndValueId"
            reference="lockupEndValueEnum"
          >
            <SelectInput
              label="Subsequent Redemption on"
              optionText={choice => secondLockupEndValueTranslation(choice)}
              disabled={!canEditField("lockupEndValueId")}
            />
          </ReferenceInput>
        </Grid>
        <Grid item xs={6} md={6} lg={3}>
          <BooleanInput
            label="Last Business Day"
            source="liquidity.secondLockupEndBizDays"
          />
        </Grid>
        <Grid item xs={6} md={6} lg={3}>
          <NumberInput
            source="liquidity.liquidityValue"
            label="Liquidity Period"
            disabled={!canEditField("liquidityValue")}
          />
        </Grid>
        <Grid item xs={6} md={6} lg={2}>
          <ReferenceInput
            source="liquidity.liquidityUnitId"
            reference="calendarUnitEnum"
            sort={{ field: "id", order: "ASC" }}
          >
            <SelectInput
              label="Unit"
              optionText="name"
              disabled={!canEditField("liquidityUnitId")}
            />
          </ReferenceInput>
        </Grid>
        <Grid item xs={6} md={6} lg={3}>
          <SelectInput
            source="liquidity.cashAvailableDays"
            label="Cash Availibility"
            choices={cashAvailableDaysChoices}
            disabled={!canEditField("cashAvailableDays")}
          />
        </Grid>
        <Grid item xs={6} md={6} lg={2}>
          <NumberInputWithSymbol
            source="liquidity.noticeValue"
            label="Notice Period"
            symbol="Day"
            symbolAlignment="end"
            disabled={!canEditField("noticeValue")}
          />
        </Grid>
        <Grid item xs={6} md={6} lg={2}>
          <NullableBooleanInput
            label="Business Days"
            source="liquidity.noticeBizDays"
            trueLabel="Business Days"
            falseLabel="Calendar Days"
            fullWidth
          />
        </Grid>
        <Grid item xs={6} md={6} lg={2}>
          <NullableBooleanInput
            source="liquidity.hasGate"
            label="Gate?"
            fullWidth
          />
        </Grid>
        <Grid item xs={6} md={6} lg={2}>
          <NumberInputWithSymbol
            source="liquidity.gatePercentCapital"
            label="Investor Level Gate"
            symbol="%"
            disabled={!hasGate || !canEditField("gatePercentCapital")}
          />
        </Grid>
        <Grid item xs={6} md={6} lg={2}>
          <NumberInputWithSymbol
            source="liquidity.gatePercentNav"
            label="Fund Level Gate"
            symbol="%"
            disabled={!hasGate || !canEditField("gatePercentNav")}
          />
        </Grid>
        <Grid item xs={6} md={6} lg={2}>
          <NullableBooleanInput
            source="liquidity.hasHoldback"
            label="Holdback?"
            fullWidth
          />
        </Grid>
        <Grid item xs={6} md={6} lg={2}>
          <NumberInputWithSymbol
            source="liquidity.holdbackPercent"
            label="Holdback"
            symbol="%"
            disabled={!hasHoldback || !canEditField("holdbackPercent")}
          />
        </Grid>
        <Grid item xs={6} md={6} lg={2}>
          <ReferenceInput source="liquidity.workoutId" reference="workoutEnum">
            <SelectInput label="Workout" optionText="name" />
          </ReferenceInput>
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInput
            multiline
            rows={5}
            source="liquidity.exceptions"
            label="Can the liquidity be modeled using the information above? If not, note the exceptions below."
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInput
            multiline
            rows={5}
            source="liquidity.modelingNotes"
            label="Additional notes that do not affect liquidity modeling."
          />
        </Grid>
      </Grid>
    </>
  );
}
