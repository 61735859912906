import * as client from "_graphql-types";
import * as types from "./types";

type Sdk = ReturnType<typeof client.getSdk>;

/*********************************************************************/

/*********************************************************************/
async function getOne(sdk: Sdk, params: types.GetOneParams) {
  const id = Number(params.id);
  const { investment: data } = await sdk.getOneInvestmentTerms({
    id,
  });
  return {
    data,
  };
}

/*********************************************************************/
async function update(
  sdk: Sdk,
  params: types.UpdateParams<client.GetOneInvestmentTermsQuery["investment"]>
) {
  const id = Number(params.id);
  const { data } = params;

  const input: client.UpdateInvestmentTermsMutationVariables["input"] = {
    shareClassNote: data.shareClassNote,
    peLegalLifeYrs: data.peLegalLifeYrs,
    harvestPeriodYrs: data.harvestPeriodYrs,
    investmentPeriodYrs: data.investmentPeriodYrs,
    extensionPeriodYrs: data.extensionPeriodYrs,
  };

  await sdk.updateInvestmentTerms({
    id,
    input,
  });
  return await getOne(sdk, { id });
}

/*********************************************************************/
export const investmentTerms = types.dataProvider({
  getOne,
  update,
});
