import * as client from "_graphql-types";

import { CustomCreate } from "../CustomCreate";
import { EntityInput } from "../UI/EntityInput";
import Grid from "@mui/material/Grid";
import { useLocation } from "react-router-dom";

type Record =
  client.GetOnePortalServiceProviderFirmQuery["portalServiceProviderFirm"];

function getOrganizationIdFromSearchParams(
  urlParams: string
): string | undefined {
  const params = new URLSearchParams(urlParams);
  const source = params.get("source");
  return source && JSON.parse(source).organization.id;
}

const Foo = (s: any) => null;

export const PortalServiceProviderFirmCreate = () => {
  const location = useLocation();

  const organizationId = getOrganizationIdFromSearchParams(location.search);
  return (
    <CustomCreate<Record>
      customFormProps={{
        customToolbarProps: {
          redirectToPathOnSave: organizationId
            ? `/portalOrganization/${organizationId}`
            : void 0,
        },
      }}
    >
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <EntityInput
            label="Organization"
            resource="portalOrganization"
            sourceKey="organizationId"
            recordKey="organization"
            isRequired
          />
        </Grid>
        <Grid item xs={6}>
          <EntityInput
            label="Firm"
            resource="portalFirm"
            sourceKey="firmId"
            recordKey="firm"
            isRequired
          />
        </Grid>
      </Grid>
    </CustomCreate>
  );
};
