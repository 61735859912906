import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
} from "@mui/material";
import Alert from "@mui/material/Alert";
import * as client from "_graphql-types";
import { useEffect, useMemo, useState } from "react";
import {
  BooleanInput,
  Button,
  DateInput,
  RadioButtonGroupInput,
  RecordContextProvider,
  ReferenceInput,
  SaveContext,
  SelectInput,
  TextInput,
  Title,
  required,
  useGetList,
  useGetOne,
  useInput,
  useMutationMiddlewares,
  useNotify,
  useRecordContext,
  useRedirect,
  useRefresh,
  useUpdate,
} from "react-admin";
import { useWatch } from "react-hook-form";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { CustomTitle } from "../CustomEdit";
import { CustomForm } from "../CustomForm";
import {
  ADDITIONAL_CATEGORIES,
  FirmDEIRecordInput,
  MAIN_CATEGORIES,
  MAIN_CATEGORIES_REST_OF_WORLD,
} from "../FirmDEIRecord/Edit";
import { EntityInput } from "../UI/EntityInput";
import { getFirmDEIConfirmationMessage } from "./helpers";
import { round } from "lodash";

type Record = NonNullable<client.GetOneFirmDeiQuery["firmDEI"]>;

const validateRequired = [required()];

function getValidator({
  measureEnums,
}: {
  measureEnums?: client.MeasureEnum[];
}) {
  if (!measureEnums) return null;

  let measureMents = measureEnums.reduce((acc, cur) => {
    acc[cur.id] = cur;
    return acc;
  }, {} as { [key: string]: client.MeasureEnum });

  return ({
    firmDEIRecords,
    asOfDate,
    geographyEnumId,
    firmId,
  }: {
    asOfDate?: Date;
    geographyEnumId?: number;
    firmId?: number;
    firmDEIRecords?: client.FirmDeiRecordInput[];
  }) => {
    let errors: any = {};

    //You can’t use both form level validation and input level validation - this is a react-hook-form limitation.
    if (!asOfDate) {
      errors.asOfDate = "As of date is required";
    }

    if (!geographyEnumId) {
      errors.geographyEnumId = "Geography is required";
    }

    if (!firmId) {
      errors.firmId = "Firm is required";
    }

    const summedPercents = (firmDEIRecords || []).reduce<{
      [key: string]: number;
    }>((acc, cur) => {
      if (!cur.measureEnumId) return acc;
      const measure = measureMents[cur.measureEnumId];
      if (!measure || !cur?.deiCategory1EnumId) return acc;

      let category;
      if (
        [...MAIN_CATEGORIES, ...MAIN_CATEGORIES_REST_OF_WORLD].includes(
          cur?.deiCategory1EnumId
        )
      ) {
        category = "main";
      } else if (ADDITIONAL_CATEGORIES.includes(cur?.deiCategory1EnumId)) {
        category = "additional";
      }

      if (
        measure.measureTypeEnum?.name === "Percent" &&
        cur.value &&
        category
      ) {
        acc[`${cur.measureEnumId}::${category}`] =
          acc[`${cur.measureEnumId}::${category}`] || 0;

        acc[`${cur.measureEnumId}::${category}`] += cur.value;
      }

      return acc;
    }, {});

    const measurePercentErrors = Object.entries(summedPercents).reduce(
      (acc, [key, value]) => {
        const [measureEnumId, category] = key.split("::");
        if (category === "main" && round(value, 2) !== 100) {
          acc[measureEnumId] = "Primary percentages must add up to 100";
        }
        if (category === "additional" && round(value, 2) > 100) {
          acc[measureEnumId] = "Diversity percentages must be less than 100";
        }
        return acc;
      },
      {} as { [key: string]: string }
    );

    const measureCountErrors = (firmDEIRecords || []).reduce((acc, curr) => {
      if (!curr.measureEnumId) return acc;
      const measure = measureMents[curr.measureEnumId];
      if (!measure) return acc;
      if (measure.measureTypeEnum?.name === "Percent") return acc;
      if (curr.value == null || curr.value == undefined) return acc;
      if (curr.value % 1 !== 0) {
        acc[curr.measureEnumId] = "Counts must be whole numbers";
      }

      return acc;
    }, {} as { [key: string]: string });

    const measureErrors = Object.assign(
      {},
      measurePercentErrors,
      measureCountErrors
    );

    if (Object.values(measureErrors).length > 0) {
      errors.firmDEIRecords = measureErrors;
    }

    return errors;
  };
}

const ViewFirmButton = () => {
  const record = useRecordContext<Record>();
  if (!record?.firm?.id) return null;

  return record?.firm?.isCompany ? (
    <Button
      component={Link}
      to={{
        pathname: `/company/${record.firm.companyId}`,
      }}
      label="View Company"
    />
  ) : (
    <Button
      component={Link}
      to={{
        pathname: `/firm/${record.firm.id}`,
      }}
      label="View Firm"
    />
  );
};

const ViewFirmDEIButton = () => {
  const record = useRecordContext<Record>();
  return !record?.firm?.id ? null : (
    <Button
      component={Link}
      to={{
        pathname: `/firmFirmDEI/${record.firm.id}`,
      }}
      label="View Firm DEI Records"
    />
  );
};

function AsOfDateSideEffects() {
  const record = useRecordContext<Record>();
  const location = useLocation();
  const redirect = useRedirect();
  const urlId = location.pathname.split("/")?.[2];

  const {
    field: { onChange, value },
  } = useInput({
    source: "asOfDate",
  });

  const [asOfDate, firm, geographyEnumId] = useWatch({
    name: ["asOfDate", "firm", "geographyEnumId"],
  });

  const list = useGetList("firmDEI", {
    filter: { firmId: firm.id, asOfDate, geographyEnumId },
    pagination: { page: 1, perPage: 1 },
  });

  const existingId = list.data?.[0]?.id;

  const revert = () => {
    record && onChange(record.asOfDate);
  };

  return (
    <Dialog
      open={existingId && urlId !== String(existingId)}
      onClose={revert}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {"Existing record found with as of date!"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          The as of date you entered matches an existing record. Would you like
          to navigate to it and discard your changes?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={revert} label={"Revert Change"} />
        <Button
          onClick={() => {
            redirect("edit", "firmDEI", existingId);
          }}
          autoFocus
          label={"Navigate to Existing Record"}
        />
      </DialogActions>
    </Dialog>
  );
}

function IsCompletedSideEffects() {
  const {
    formState: {
      dirtyFields: { asOfDate, firmDEIRecords, geographyEnumId },
    },
    field: { onChange, value },
  } = useInput({
    source: "isCompleted",
  });

  //IsDirty for isCompleted not being marked as dirty after it is changed. Perhaps related to
  // default values, using this instead.
  let [shouldResetIsCompleted, setShouldResetIsCompleted] = useState(
    value === true
  );

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (
      (asOfDate || firmDEIRecords || geographyEnumId) &&
      shouldResetIsCompleted
    ) {
      setShouldResetIsCompleted(false);
      onChange(false);
    }
  }, [value, asOfDate, firmDEIRecords, geographyEnumId]); // placing onChange inside dependency triggers an infinite rerender loop

  return null;
}

export const FirmDEIEdit = () => {
  const location = useLocation();
  const urlId = location.pathname.split("/")?.[2];
  if (!urlId) throw new Error("No ID found in URL");

  const title = (record?: Record) =>
    record ? `Firm DEI - ${record.firm?.name || ""}` : null;

  const navigate = useNavigate();
  const [update, { isLoading: saving }] = useUpdate();

  const { data: measureEnums }: { data?: client.MeasureEnum[] } = useGetList(
    "measureEnum",
    {
      pagination: { page: 1, perPage: 999 },
      sort: { field: "displayOrder", order: "ASC" },
    }
  );

  let validate = useMemo(() => {
    return getValidator({ measureEnums });
  }, [measureEnums]);

  const notify = useNotify();
  // This does not use CustomEdit because FirmDEI performs a create or update
  // Which is not supported internally by react admin. This is a workaround
  // The id changes when it is a create causing an id miss match error inside of
  // use edit controller.
  const record = useGetOne("firmDEI", { id: urlId });
  const mutationMiddlewares = useMutationMiddlewares();

  const refresh = useRefresh();
  const save = async (data: any, callbacks: any) => {
    if (!data) throw new Error("Data being saved should never be null");

    return update(
      "firmDEI",
      { id: data.id, data },
      {
        mutationMode: "pessimistic",
        ...callbacks,
        onSuccess: data => {
          refresh();
          if (data.id !== urlId) navigate(`/firmDEI/${data.id}`);
          notify("Element updated");
        },
        onError: (error: any) => {
          notify(error.message, { type: "error" });
        },
      }
    );
  };

  if (!record.data || !validate) return null;

  return (
    <RecordContextProvider value={record.data} key={urlId}>
      <SaveContext.Provider value={{ save, saving, ...mutationMiddlewares }}>
        <Title title={<CustomTitle<Record> title={title} />} />
        <CustomForm<Record>
          customToolbarProps={{
            getConfirmationMessage: getFirmDEIConfirmationMessage,
            confirmOnSave: true,
          }}
          title={title}
          simpleFormProps={{
            defaultValues: record.data,
            validate,
            mode: "onChange",
          }}
        >
          <IsCompletedSideEffects />
          <AsOfDateSideEffects />
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <ViewFirmButton />
              <ViewFirmDEIButton />
            </Grid>
            <Grid item xs={12} sm={6}>
              <EntityInput<NonNullable<Record>>
                resource="firm"
                recordKey="firm"
                label="Firm"
                isRequired={true}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <DateInput
                label="As Of"
                source="asOfDate"
                validate={validateRequired}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <ReferenceInput
                reference="geographyEnum"
                sort={{ field: "name", order: "ASC" }}
                perPage={1000}
                source="geographyEnumId"
              >
                <SelectInput
                  helperText="Geography of Team Members Indicated (Offices)"
                  optionText="description"
                  label="Geography"
                  validate={validateRequired}
                  readOnly
                />
              </ReferenceInput>
            </Grid>
          </Grid>
          <Grid item xs={3} style={{ marginTop: "1em" }}>
            <BooleanInput
              source="isCompleted"
              label="Is Completed"
              helperText="All available data for the selected date has been entered"
            />
          </Grid>
          <Grid item xs={3} style={{ marginTop: "1em" }}>
            <ReferenceInput
              source="diversityStatsEnumId"
              reference="diversityStatsEnum"
              sort={{ field: "id", order: "ASC" }}
            >
              <RadioButtonGroupInput
                optionText="value"
                label="Can the firm provide diversity statistics for the entire portfolio management team?"
                defaultValue={1}
                validate={validateRequired}
              />
            </ReferenceInput>
          </Grid>
          <Grid item xs={12} style={{ marginTop: "1em" }}>
            <Alert severity="info">
              Please complete the information below for employees of the
              Management Company. For publicly-held managers and managers with
              multiple business lines, please provide information relevant to
              the specific product in which the receiving LP is invested. Where
              data beyond gender is not collected or otherwise not available,
              enter employee counts by gender in the "No Information Available
              (Race/Ethnicity)" column, leaving the other race/ethnicity columns
              blank.
            </Alert>
          </Grid>
          <FirmDEIRecordInput />
          <TextInput label="Comments" source="comments" multiline rows={5} />
        </CustomForm>
      </SaveContext.Provider>
    </RecordContextProvider>
  );
};
