import React, { useCallback, useEffect, useState } from "react";
import { getCognitoAuth, getOktaAuth } from "./Auth";

export enum AuthProviderType {
  Okta,
  Cognito,
}

export interface Auth {
  // It is expected that getIdToken will automatically refresh the session.
  // This appears to be consistent between okta and cognito.
  getIdToken: () => Promise<string>;
  signOut: () => void;
  authProvider?: AuthProviderType;
  getEmail: () => Promise<string>;
  refreshSession: () => void;
}

export const AuthContext = React.createContext<{
  auth?: Auth;
  configure: (arg: Auth) => void;
  signOut: () => void;
  isLoading: boolean;
}>({
  auth: undefined,
  configure: () => {},
  signOut: () => {},
  isLoading: true,
});

export default function AuthProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [auth, configure] = useState<Auth | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const signOut = useCallback(() => {
    auth && auth.signOut();
    configure(undefined);
  }, [auth]);

  useEffect(() => {
    const initialSetup = async () => {
      const cognitoAuth = await getCognitoAuth();
      if (cognitoAuth) {
        configure(cognitoAuth);
        setIsLoading(false);
        return;
      }
      const oktaAuth = await getOktaAuth();
      if (oktaAuth) {
        configure(oktaAuth);
      }
      setIsLoading(false);
    };
    initialSetup();
  }, []);

  useEffect(() => {
    if (!auth) return;

    const refreshSession = async () => {
      try {
        console.log("refreshing session");
        await auth.refreshSession();
      } catch (e) {
        console.log("failed to refresh session");
        console.log(e);
      }
    };
    const interval = setInterval(refreshSession, 3 * 60 * 1000);
    refreshSession();
    return () => clearInterval(interval);
  }, [auth]);

  if (isLoading) return null;

  return (
    <AuthContext.Provider value={{ auth, configure, signOut, isLoading }}>
      {children}
    </AuthContext.Provider>
  );
}
