import { Dispatch, SetStateAction } from "react";

import { format } from "date-fns";
import { isEqual, uniq, uniqWith } from "lodash";
import XLSX from "xlsx";
const dateFormat = "MMM d yyyy";

export const parseSpreadsheet = (
  file: File
): Promise<{ rows: any[]; headers: string[] }> => {
  return new Promise((res, rej) => {
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;
    reader.onload = e => {
      try {
        const bstr = e.target!.result;
        const wb = XLSX.read(bstr, {
          type: rABS ? "binary" : "array",
          cellDates: true,
        });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];

        const data: any[] = XLSX.utils.sheet_to_json(ws, {
          header: 1,
          defval: null,
        });

        res({
          rows: data.slice(1),
          headers: data[0],
        });
      } catch (e) {
        rej(e);
      }
    };
    if (rABS) reader.readAsBinaryString(file);
    else reader.readAsArrayBuffer(file);
  });
};

export function getHeaderFromCategories(
  categories: string[],
  headers: string[],
  rows: (string | number | null)[][]
): string {
  return headers.reduce((accu: string, curr: string, currentIndex: number) => {
    if (
      rows.some(
        row =>
          typeof row[currentIndex] === "string" &&
          categories.includes(row[currentIndex] as string)
      )
    ) {
      accu = curr;
    }
    return accu;
  }, "");
}

export function getCategoriesFromRows(
  selection: string | string[],
  headers: string[],
  rows?: (string | number | null)[][]
) {
  if (Array.isArray(selection)) {
    return selection.reduce((accu: string[], curr: string) => {
      const i = headers.findIndex(h => h === curr);
      return accu.concat(
        uniq(rows?.map(row => row[i]).filter((v): v is string => !!v))
      );
    }, []);
  }
  const i = headers.findIndex(h => h === selection);
  return uniq(rows?.map(row => row[i]).filter((v): v is string => !!v));
}

export function isSpreadsheetCategories(
  category: CustomCategories | SpreadsheetCategories
): category is SpreadsheetCategories {
  return (
    category &&
    category?.hasOwnProperty("dataColumn") &&
    category?.hasOwnProperty("labels")
  );
}

export function updateYAxisConfig(
  axisConfig: Highcharts.YAxisOptions,
  yAxisId: number,
  layerConfig: LayerConfiguration,
  chartOptions: any,
  setChartOptions: Dispatch<SetStateAction<any>>
) {
  if (yAxisId === 0) {
    if (
      layerConfig.enabled &&
      layerConfig.yAxis2 &&
      layerConfig.yAxis2Config?.type &&
      layerConfig.yAxis2Config.dataColumn
    ) {
      setChartOptions({
        ...chartOptions,
        yAxis: [
          { ...chartOptions?.yAxis[0], ...axisConfig },
          { ...chartOptions?.yAxis[1] },
        ],
      });
    } else {
      setChartOptions({
        ...chartOptions,
        yAxis: [{ ...chartOptions?.yAxis[0], ...axisConfig }],
      });
    }
  } else {
    setChartOptions({
      ...chartOptions,
      yAxis: [
        { ...chartOptions?.yAxis[0] },
        {
          ...chartOptions?.yAxis[1],
          opposite: true,
          title: { text: "" },
          labels: {
            format: "{value}x",
          },
          ...axisConfig,
        },
      ],
    });
  }
}

export function isCategoryAxisOptions(
  axisOptions: AxisOptions | CategoryAxisOptions
): axisOptions is CategoryAxisOptions {
  return axisOptions.type === "category";
}

export interface CustomCategories {
  labels?: string[];
  dataColumns?: string[];
}

export interface LayerConfiguration {
  enabled: boolean;
  dataColumn: string;
  chartType?: Highcharts.ChartOptions["type"];
  yAxis2?: boolean;
  yAxis2Config?: Partial<AxisOptions>;
}
export interface SeriesConfiguration {
  enabled: boolean;
  labelColumn?: string;
  labels: string[];
}

export interface SpreadsheetCategories {
  labels?: string[];
  dataColumn?: string | null;
}

export interface CategoryAxisOptions {
  type?: Highcharts.AxisTypeValue;
  categories?: CustomCategories | SpreadsheetCategories;
}

export interface AxisOptions {
  type?: Highcharts.AxisTypeValue;
  dataColumn?: string | null;
}

export interface AxisConfiguration {
  mainAxis: AxisOptions | CategoryAxisOptions;
  crossAxis: AxisOptions;
}

function formatDataPoint(
  value: string | number | null,
  axisType?: Highcharts.AxisTypeValue
) {
  switch (axisType) {
    case "datetime":
      return !!value
        ? new Date(value as string | number | Date).getTime()
        : null;
    default:
      return value;
  }
}

export function formatDataForSpreadsheetCategories(
  mainAxisCategories: SpreadsheetCategories,
  crossAxisConfig: AxisOptions,
  headers: string[],
  data: (string | number | null)[][]
) {
  const labelColumnIndex = headers.findIndex(
    h => h === mainAxisCategories.dataColumn
  );
  const dataIndex = headers.findIndex(h => h === crossAxisConfig.dataColumn);
  if (labelColumnIndex === -1 || dataIndex === -1) return;
  return uniqWith(
    data.map(row => [
      row[labelColumnIndex],
      formatDataPoint(row[dataIndex], crossAxisConfig?.type),
    ]),
    isEqual
  );
}

export function formatDataForCustomCategories(
  mainAxisCustomCategories: CustomCategories,
  crossAxisConfig: AxisOptions,
  headers: string[],
  data: (string | number | null)[][]
) {
  if (
    !mainAxisCustomCategories?.dataColumns ||
    !mainAxisCustomCategories?.dataColumns.length
  )
    return;

  return data.reduce(
    (
      accu: Array<{ x: number; y: string | number | null }>,
      row: (string | number | null)[]
    ) => {
      (mainAxisCustomCategories.dataColumns as string[]).forEach(
        (dataColumn, i) => {
          const dataIndex = headers.findIndex(h => h === dataColumn);
          accu.push({ x: i, y: row[dataIndex] });
        }
      );
      return accu;
    },
    []
  );
}

export function formatDataForNonCategoryAxis(
  mainAxisConfig: AxisOptions,
  crossAxisConfig: AxisOptions,
  headers: string[],
  data: (string | number | null)[][]
) {
  const mainAxisDataIndex = headers.findIndex(
    h => h === (mainAxisConfig as AxisOptions).dataColumn
  );
  const crossAxisDataIndex = headers.findIndex(
    h => h === (crossAxisConfig as AxisOptions).dataColumn
  );

  return uniqWith(
    data.map(row => [
      formatDataPoint(row[mainAxisDataIndex], mainAxisConfig?.type),
      formatDataPoint(row[crossAxisDataIndex], crossAxisConfig?.type),
    ]),
    isEqual
  );
}

export function formatChartData(
  axisConfiguration: AxisConfiguration,
  headers: string[],
  chartType: Highcharts.ChartOptions["type"] = "column",
  rows?: (string | number | null)[][],
  series?: SeriesConfiguration
) {
  const mainAxisConfig = axisConfiguration.mainAxis;
  const crossAxisConfig = axisConfiguration.crossAxis;

  if (!rows || !rows.length) return;

  if (chartType === "pie") {
    if (!series?.enabled || !series.labelColumn) {
      throw new Error("pie charts require data series");
    }
    const seriesIndex = headers.findIndex(h => h === series.labelColumn);
    const dataIndex = headers.findIndex(h => h === crossAxisConfig.dataColumn);
    const dataRowsSeries = rows.filter(
      row =>
        row[seriesIndex] && series.labels.includes(row[seriesIndex] as string)
    );

    const data = dataRowsSeries?.reduce(
      (accu: { name: string; y: number | null }[], curr) => {
        const existing = accu.findIndex(r => r["name"] == curr[seriesIndex]);

        if (
          existing !== -1 &&
          curr[dataIndex] !== null &&
          typeof curr[dataIndex] === "number"
        ) {
          const dataRow = accu[existing];
          accu[existing] = {
            ...accu[existing],
            y:
              dataRow?.y === null
                ? (curr[dataIndex] as number)
                : dataRow.y + (curr[dataIndex] as number),
          };
        } else {
          accu = [
            ...accu,
            {
              name: curr[seriesIndex] as string,
              y: curr[dataIndex] as number,
            },
          ];
        }

        return accu;
      },
      []
    );

    return [
      {
        name: series.labelColumn,
        type: chartType,
        colorByPoint: true,
        data,
      },
    ];
  }

  if (isCategoryAxisOptions(mainAxisConfig)) {
    if (!mainAxisConfig.categories) return;
    if (isSpreadsheetCategories(mainAxisConfig.categories)) {
      const mainAxisCategories = mainAxisConfig.categories;
      if (series?.enabled && series.labelColumn && series.labels?.length) {
        const seriesIndex = headers.findIndex(h => h === series.labelColumn);
        const seriesData = series.labels.map(label => ({
          name: label,
          data: formatDataForSpreadsheetCategories(
            mainAxisCategories,
            crossAxisConfig,
            headers,
            rows.filter(row => row[seriesIndex] === label)
          ),
        }));
        return seriesData;
      }
      return formatDataForSpreadsheetCategories(
        mainAxisCategories,
        crossAxisConfig,
        headers,
        rows
      );
    }
    const mainAxisCustomCategories = mainAxisConfig.categories;
    if (!mainAxisCustomCategories) return;
    if (series && series.labelColumn && series.labels.length) {
      const seriesIndex = headers.findIndex(h => h === series.labelColumn);
      const seriesData = series.labels.map(label => ({
        name: label,
        data: formatDataForCustomCategories(
          mainAxisCustomCategories,
          crossAxisConfig,
          headers,
          rows.filter(row => row[seriesIndex] === label)
        ),
      }));
      return seriesData;
    }
    return formatDataForCustomCategories(
      mainAxisCustomCategories,
      crossAxisConfig,
      headers,
      rows
    );
  }

  const mainAxisOptions = mainAxisConfig;

  if (series && series.labelColumn) {
    const seriesIndex = headers.findIndex(h => h === series.labelColumn);
    const seriesData = series.labels.map(label => ({
      name: label,
      data: formatDataForNonCategoryAxis(
        mainAxisOptions,
        crossAxisConfig,
        headers,
        rows.filter(row => row[seriesIndex] === label)
      ),
    }));
    return seriesData;
  }
  return formatDataForNonCategoryAxis(
    mainAxisOptions,
    crossAxisConfig,
    headers,
    rows
  );
}

export function getLabelFormatString(labelFormat: {
  prefix?: string;
  suffix?: string;
}): string {
  if (labelFormat.prefix && labelFormat.suffix) {
    return `${labelFormat.prefix}{text}${labelFormat.suffix}`;
  }

  if (labelFormat.prefix && !labelFormat.suffix) {
    return `${labelFormat.prefix}{text}`;
  }

  if (labelFormat.suffix && !labelFormat.prefix) {
    return `{text}${labelFormat.suffix}`;
  }

  return "{text}";
}

export function generateConfigFromHCOptions({
  options,
  rows,
  headers,
}: {
  options: Highcharts.Options & { xAxis?: Highcharts.XAxisOptions };
  rows: (string | number | null)[][];
  headers: string[];
}) {
  const { xAxis, yAxis, series } = options;
  let layerConfig: LayerConfiguration | null = null;
  let seriesConfig: SeriesConfiguration | null = null;
  let axisConfig: AxisConfiguration = {
    mainAxis: {
      type: xAxis?.type,
    },
    crossAxis: {
      type: Array.isArray(yAxis) ? yAxis[0].type : yAxis?.type,
    },
  };

  if (xAxis?.categories && xAxis.type === "category") {
    const dataColumn = getHeaderFromCategories(xAxis.categories, headers, rows);

    if (dataColumn !== "") {
      (axisConfig.mainAxis as CategoryAxisOptions).categories = {
        labels: xAxis.categories,
        dataColumn,
      };
    } else {
      (axisConfig.mainAxis as CategoryAxisOptions).categories = {
        labels: xAxis.categories,
        dataColumns: [],
      };
    }
  }

  const secondLayer = series?.find(s => s.type !== options?.chart?.type);
  if (secondLayer) {
    layerConfig = {
      enabled: true,
      chartType: secondLayer.type,
      dataColumn: "",
      yAxis2: Array.isArray(options.yAxis),
    };
    if (Array.isArray(options.yAxis)) {
      layerConfig.yAxis2Config = {
        type: options.yAxis[1].type,
      };
    }
  }

  const seriesLabels = series
    ?.map(s => s.name)
    .filter((name: string | undefined): name is string => !!name);

  if (seriesLabels && seriesLabels.length) {
    const dataColumn = getHeaderFromCategories(seriesLabels, headers, rows);
    seriesConfig = {
      enabled: true,
      labels: seriesLabels,
      labelColumn: dataColumn,
    };
  }

  return {
    layerConfig,
    seriesConfig,
    axisConfig,
  };
}

export function generateHCOptionsFromConfig({
  axisConfig,
  headers,
  options,
  rows,
  seriesConfig,
  layerConfig,
  asOfDate,
}: {
  axisConfig: AxisConfiguration;
  headers: string[];
  options: Highcharts.Options;
  rows: (string | number | null)[][];
  seriesConfig: SeriesConfiguration;
  layerConfig: LayerConfiguration;
  asOfDate?: string | null | Date;
}) {
  const data = formatChartData(
    axisConfig,
    headers,
    options.chart?.type,
    rows,
    seriesConfig
  );
  if (seriesConfig?.enabled && seriesConfig.labels.length) {
    if (layerConfig?.enabled && layerConfig.chartType) {
      return {
        ...options,
        ...(asOfDate && {
          subtitle: {
            text: `(As of ${format(
              asOfDate instanceof Date
                ? new Date(
                    asOfDate.toISOString().slice(0, 10).replace(/-/g, "/")
                  )
                : new Date(asOfDate.slice(0, 10).replace(/-/g, "/")),
              dateFormat
            )})`,
          },
        }),
        series: [
          {
            ...data,
            type: options.chart?.type,
            ...(Array.isArray(options.yAxis) &&
              options.yAxis.length > 1 && { yAxis: 0 }),
          },
          {
            type: layerConfig.chartType,
            showInLegend: false,
            ...(Array.isArray(options.yAxis) &&
              options.yAxis.length > 1 && { yAxis: 1 }),
            data: formatChartData(
              {
                ...axisConfig,
                crossAxis: {
                  ...axisConfig.crossAxis,
                  dataColumn: layerConfig.dataColumn,
                },
              },
              headers,
              layerConfig.chartType,
              rows,
              { enabled: false, labels: [] }
            ),
          },
        ],
      };
    }
    return {
      ...options,
      ...(asOfDate && {
        subtitle: {
          text: `(As of ${format(
            asOfDate instanceof Date
              ? new Date(asOfDate.toISOString().slice(0, 10).replace(/-/g, "/"))
              : new Date(asOfDate.slice(0, 10).replace(/-/g, "/")),
            dateFormat
          )})`,
        },
      }),
      series: data,
    };
  }

  if (layerConfig?.enabled && layerConfig.chartType) {
    return {
      ...options,
      ...(asOfDate && {
        subtitle: {
          text: `(As of ${format(
            asOfDate instanceof Date
              ? new Date(asOfDate.toISOString().slice(0, 10).replace(/-/g, "/"))
              : new Date(asOfDate.slice(0, 10).replace(/-/g, "/")),
            dateFormat
          )})`,
        },
      }),
      series: [
        {
          data: data,
          type: options.chart?.type,
          showInLegend: false,
          ...(Array.isArray(options.yAxis) &&
            options.yAxis.length > 1 && { yAxis: 0 }),
        },
        {
          type: layerConfig.chartType,
          showInLegend: false,
          ...(Array.isArray(options.yAxis) &&
            options.yAxis.length > 1 && { yAxis: 1 }),
          data: formatChartData(
            {
              ...axisConfig,
              crossAxis: {
                ...axisConfig.crossAxis,
                dataColumn: layerConfig.dataColumn,
              },
            },
            headers,
            layerConfig.chartType,
            rows,
            { enabled: false, labels: [] }
          ),
        },
      ],
    };
  }

  return {
    ...options,
    ...(asOfDate && {
      subtitle: {
        text: `(As of ${format(
          asOfDate instanceof Date
            ? new Date(asOfDate.toISOString().slice(0, 10).replace(/-/g, "/"))
            : new Date(asOfDate.slice(0, 10).replace(/-/g, "/")),
          dateFormat
        )})`,
      },
    }),
    series: [
      {
        data: data,
        type: options.chart?.type,
        showInLegend: false,
      },
    ],
  };
}
