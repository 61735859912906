import {
  Autocomplete,
  Button,
  MenuItem,
  Modal,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import { DataProvider, useDataProvider } from "react-admin";
import { useFormState } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useDebouncedCallback } from "use-debounce";
import { styled } from "@mui/material/styles";

const DivPaper = styled("div")(({ theme }) => ({
  position: "absolute",
  width: 400,
  backgroundColor: theme.palette.background.paper,
  border: "2px solid #000",
  boxShadow: theme.shadows[5],
  padding: theme.spacing(2, 4, 3),
  top: "50%",
  left: "50%",
  transform: `translate(-50%,-50%)`,
  outline: "none",
}));

interface IdName {
  id: number;
  name: string;
  list: string;
}

export const fetchInvestmentOptions =
  (dataProvider: DataProvider, handler: (options: IdName[]) => void) =>
  (q: string) => {
    dataProvider
      .getList<{
        id: string;
        name: string;
        list: { id: string; name: string } | null;
      }>("investment", {
        filter: { q },
        sort: {
          field: "nameSearchRank",
          order: "ASC",
        },
        pagination: {
          page: 1,
          perPage: 20,
        },
      })
      .then(({ data }) => {
        handler(
          data.map(({ id, name, list }) => ({
            id: Number(id),
            name: name,
            list: list?.name || "",
          }))
        );
      });
  };

interface Props {
  pathName: string;
  hasChanges: boolean;
}

export const NavSearch = ({ name }: { name: string }) => {
  const { isDirty } = useFormState();
  return <Navigation pathName={name} hasChanges={isDirty} />;
};

export const Navigation = ({ pathName, hasChanges }: Props) => {
  const dataProvider = useDataProvider();

  const [investmentInput, setInvestmentInput] = useState("");
  const [investmentOptions, setInvestmentOptions] = useState<IdName[]>([]);
  const setInvestmentOptionsDebounced = useDebouncedCallback(
    fetchInvestmentOptions(dataProvider, setInvestmentOptions),
    300
  );

  useEffect(() => {
    setInvestmentOptionsDebounced("");
  }, [setInvestmentOptionsDebounced]);
  const [investmentPick, setInvestmentPick] = useState<IdName>();
  const [challenge, setChallenge] = useState(false);

  const navigate = useNavigate();
  return (
    <>
      <Modal
        open={!!challenge}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <DivPaper>
          You have unsaved changes do you really want to navigate away?
          <br />
          <Button
            onClick={() => {
              navigate(`/${pathName}/${investmentPick!.id}`);
              setChallenge(false);
              setInvestmentPick(undefined);
            }}
          >
            Yes
          </Button>
          <Button
            onClick={() => {
              setChallenge(false);
              setInvestmentPick(undefined);
            }}
          >
            No
          </Button>
        </DivPaper>
      </Modal>
      <Autocomplete
        fullWidth
        sx={{ mb: 2 }}
        size="small"
        filterOptions={options => options}
        options={investmentOptions}
        renderInput={params => (
          <TextField
            variant="standard"
            {...params}
            label="Investment Navigation"
          />
        )}
        onInputChange={(event, value, reason) => {
          switch (reason) {
            case "input":
              setInvestmentOptionsDebounced(value);
              setInvestmentInput(value);
              break;
            case "clear":
              setInvestmentOptions([]);
              setInvestmentInput("");
              break;
          }
        }}
        onChange={(event, value, reason) => {
          switch (reason) {
            case "selectOption":
              if (hasChanges) {
                setInvestmentPick(value);
                setInvestmentInput("");
                setInvestmentOptions([]);
                setChallenge(true);
              } else {
                navigate(`/${pathName}/${value.id}`);
              }
              break;
          }
        }}
        getOptionLabel={option => option.name || ""}
        disableClearable
        inputValue={investmentInput}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        renderOption={(props, option) => (
          <MenuItem {...props}>
            <div style={{ width: "100%" }}>
              {option.name}{" "}
              <span style={{ float: "right" }}>{option.list}</span>
            </div>
          </MenuItem>
        )}
        data-cy="investment-navigation"
      />
    </>
  );
};
