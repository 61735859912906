import * as client from "_graphql-types";
import * as types from "./types";

type Sdk = ReturnType<typeof client.getSdk>;

/*********************************************************************/
// Data Provider Functions
/*********************************************************************/

/*********************************************************************/
async function getOne(sdk: Sdk, params: types.GetOneParams) {
  const id = Number(params.id);
  const { investment: data } = await sdk.getOneInvestmentPeers({
    id,
    sort: [
      {
        field: client.InvestmentSortEnum.Name,
        order: client.SortInput.Asc,
      },
    ],
    filter: {
      excludeSelf: true,
    },
  });
  return { data };
}

/*********************************************************************/
async function update(
  sdk: Sdk,
  params: types.UpdateParams<
    NonNullable<client.GetOneInvestmentPeersQuery["investment"]>
  >
) {
  const id = Number(params.id);
  const { data } = params;
  const peerIds = types.required(data, "peersList").items.map(item => item.id);

  console.log("PEERS IDS", peerIds);

  await sdk.updateInvestmentPeers({
    id,
    peerIds,
  });

  return await getOne(sdk, { id });
}

/*********************************************************************/
export const investmentPeers = types.dataProvider({
  getOne,
  update,
});
