import React, { useContext } from "react";
import { Navigate, Route, RouteProps } from "react-router";
import { AuthContext } from "./AuthProvider.component";

export default function SecureRoute({
  children,
}: {
  children: React.ReactElement | null;
}) {
  const { auth, isLoading } = useContext(AuthContext);

  if (isLoading) return null;

  if (!auth) return <Navigate to="/login" />;

  return children;
}
