import {
  Datagrid,
  EditButton,
  EmailField,
  List,
  TextField,
  ReferenceField,
  FunctionField,
  useResourceDefinition,
} from "react-admin";
import { PortalUserFilter } from "./Filter";
import CustomDeleteButton from "../CustomDeleteButton";
import CustomFlag from "../CustomFlag";
import { ListActions } from "./ListActions";

export const PortalUserList = () => {
  const { name } = useResourceDefinition();
  return (
    <List
      sx={{
        ".RaFilter-form": {
          flex: "0 1 50%",
        },
      }}
      resource={name}
      filters={<PortalUserFilter />}
      sort={{ field: "nameSearchRank", order: "ASC" }}
      actions={<ListActions />}
    >
      <Datagrid
        rowClick="edit"
        data-cy="portalUser-list"
        bulkActionButtons={false}
      >
        <EmailField label="Email" source="email" />
        <TextField label="First Name" source="firstName" />
        <TextField label="Last Name" source="lastName" />
        <ReferenceField
          reference="portalOrganization"
          source="organizationId"
          label="Organization"
          sortBy="organizationName"
        >
          <TextField source="name" />
        </ReferenceField>
        <FunctionField
          label="Deleted?"
          render={(investment: any) =>
            investment.deleteDate ? <CustomFlag label="Deleted" /> : null
          }
          sortable={false}
        />
        <EditButton />
        <CustomDeleteButton />
      </Datagrid>
    </List>
  );
};
