import {
  Filter,
  TextInput,
  SelectInput,
  NullableBooleanInput,
} from "react-admin";
import * as client from "_graphql-types";

import React from "react";

export const SetTypeChoices = [
  { id: client.SetType.Investment, name: "Set of Investments" },
  { id: client.SetType.Set, name: "Set of Sets" },
];

export const SubscriptionLevelChoices = [
  { id: client.SetSubscriptionLevel.AutoSubscribe, name: "Auto Subscribe" },
  { id: client.SetSubscriptionLevel.Subscribeable, name: "Subscribeable" },
  {
    id: client.SetSubscriptionLevel.NotSubscribeable,
    name: "Not Subscribeable",
  },
];

export const SetFilter = () => (
  <Filter>
    <TextInput label="Name Search" source="q" alwaysOn style={{ width: 500 }} />
    <SelectInput
      alwaysOn
      label="Set Type"
      source="setType"
      choices={SetTypeChoices}
    />
    <SelectInput
      alwaysOn
      label="Subscription Level"
      source="subscriptionLevelEnumId"
      choices={SubscriptionLevelChoices}
    />
    <NullableBooleanInput
      alwaysOn
      label="In My Curated List?"
      source="myCuratedSets"
    />
  </Filter>
);
