import * as client from "_graphql-types";

import {
  DateInput,
  ReferenceInput,
  TextInput,
  SelectInput,
  SimpleFormIterator,
  ArrayInput,
} from "react-admin";
import { CustomCreate } from "../CustomCreate";
import Grid from "@mui/material/Grid";
import { OrgLinksInput } from "./Edit";

type Record = client.GetOneLinkQuery["links"];

export const LinksCreate = () => (
  <CustomCreate<NonNullable<Record>> title={() => "Create OrgLinks"}>
    <>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextInput label="Name" source="name" />
        </Grid>
        <Grid item xs={6}>
          <TextInput label="URL" source="url" />
        </Grid>
        <Grid item xs={12}>
          <ReferenceInput
            source="iconEnumId"
            reference="iconEnum"
            sort={{ field: "name", order: "ASC" }}
          >
            <SelectInput fullWidth={true} optionText="name" label="Icon" />
          </ReferenceInput>
        </Grid>
        <Grid item xs={12}>
          <hr />
          <h3>Organizations</h3>
        </Grid>
        <Grid item xs={12}>
          <OrgLinksInput />
        </Grid>
      </Grid>
    </>
  </CustomCreate>
);
