import * as client from "_graphql-types";
import * as types from "./types";

type Sdk = ReturnType<typeof client.getSdk>;

/*********************************************************************/
// Data Provider Functions
/*********************************************************************/

/*********************************************************************/
async function getOne(sdk: Sdk, params: types.GetOneParams) {
  // input shape is { id: 0, meta }
  const input = types.required(params, "meta");
  const { customIndexPreview: meta } = await sdk.getOneCustomIndexPreview({
    input,
  });
  // output shape is symmetric with the input
  return {
    data: {
      id: 0,
      meta,
    },
  };
}

/*********************************************************************/
export const customIndexPreview = types.dataProvider({
  getOne,
});
