import RCGLogo from "./RCGLogo.svg";
export default function Header() {
  return (
    <div
      style={{
        width: "100%",
        backgroundColor: "#2a3fb3",
        minHeight: "60px",
        textAlign: "center",
      }}
    >
      <div style={{ paddingTop: "10px" }}>
        <a href="https://therockcreekgroup.com/">
          <img
            src={RCGLogo}
            alt="RCG Logo"
            style={{ height: "40px", color: "white" }}
          />
        </a>
      </div>
    </div>
  );
}
