import * as client from "_graphql-types";
import * as types from "./types";

import { filterSortPage } from "../DataProvider";

type Sdk = ReturnType<typeof client.getSdk>;

/*********************************************************************/
// Data Provider Functions
/*********************************************************************/

/*********************************************************************/
async function getList(
  sdk: Sdk,
  params: types.GetListParams<client.InstitutionSortEnum>
) {
  const { pagination, sort, filter } = params;

  const {
    institutionList: { items: data, total },
  } = await sdk.getListInstitution(
    filterSortPage({
      sort,
      pagination,
      filter,
    })
  );

  return {
    data,
    total,
  };
}

/*********************************************************************/
async function getOne(sdk: Sdk, params: types.GetOneParams) {
  const id = Number(params.id);
  const { institution: data } = await sdk.getOneInstitution({
    id,
  });
  return {
    data,
  };
}

/*********************************************************************/
async function getMany(sdk: Sdk, params: types.GetManyParams) {
  const ids = params.ids.map(id => Number(id));

  const { institutionMany: data } = await sdk.getManyInstitution({
    ids,
  });

  return {
    data,
  };
}

/*********************************************************************/
async function create(
  sdk: Sdk,
  params: types.CreateParams<client.InstitutionInput>
) {
  const { data } = params;
  const input: client.CreateInstitutionMutationVariables["input"] = data;

  const {
    addInstitution: { id },
  } = await sdk.createInstitution({
    input,
  });
  return await getOne(sdk, { id: String(id) });
}

async function update(
  sdk: Sdk,
  params: types.UpdateParams<client.InstitutionInput & { id: string | number }>
) {
  const id = Number(params.id);
  const { data } = params;

  const input: client.UpdateInstitutionMutationVariables["input"] = {
    name: data.name,
    address: data.address,
    institutionSelectionStatusEnumId: data.institutionSelectionStatusEnumId,
    institutionInstitutionTypesList: data.institutionInstitutionTypesList,
    globalProviderInstitution: data.globalProviderInstitution,
  };

  await sdk.updateInstitution({
    id: Number(id),
    input,
  });
  return await getOne(sdk, { id: String(id) });
}

/*********************************************************************/

/*********************************************************************/
export const officeLocation = types.dataProvider({
  getList,
  getOne,
  getMany,
  create,
  update,
});
