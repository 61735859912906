import { useMutation, useQuery, gql } from "@apollo/client";
import { Alert, AppBar, Card, Container, Grid, Paper } from "@mui/material";
import * as types from "_graphql-types";
import { Button } from "antd";
import { useEffect, useState } from "react";
import { GET_APPLICATION_COMPANY } from "./graphql";
import { fontSize } from "@mui/system";

const UPDATE_COMPANY = gql`
  mutation completeApplication($id: Int!, $input: CompanyInput!) {
    updateCompany(id: $id, input: $input) {
      id
      applicationCompletedAt
    }
  }
`;

const Submit = ({ ownerId }: { ownerId: number }) => {
  const [submit, { loading }] = useMutation(UPDATE_COMPANY);
  return (
    <Button
      onClick={() =>
        submit({ variables: { id: ownerId, input: { completed: true } } })
      }
      type="primary"
      htmlType="submit"
      loading={loading}
    >
      Finalize Application
    </Button>
  );
};

const CompletionConfirmation = () => {
  const { data, error } = useQuery<types.GetApplicantCompanyQuery>(
    GET_APPLICATION_COMPANY
  );

  const applicationSubmitted =
    data?.companyList.items[0].applicationCompletedAt;

  const ownerId = data?.companyList.items[0].id;

  const getAlertMessage = () => {
    if (error) {
      return (
        <>
          <Alert severity="error" variant="filled">
            An error occurred, please contact support
          </Alert>
        </>
      );
    }
    if (!data) {
      return null;
    }
    if (!ownerId && typeof ownerId !== "number") {
      return (
        <>
          <Alert severity="error" variant="filled">
            Business application unavailable, please contact support
          </Alert>
        </>
      );
    }
    if (!applicationSubmitted) {
      return (
        <>
          <Alert severity="info" variant="filled">
            <p style={{ marginBottom: "5px", fontSize: 16 }}>
              Your application has not been finalized yet. If you have completed
              your answers, please finalize your application before exiting.
            </p>
            <Submit ownerId={ownerId} />
          </Alert>
        </>
      );
    }
    return (
      <>
        <Alert severity="success" variant="filled">
          Thank you for completing your application. Our team will be reaching
          out soon.
        </Alert>
      </>
    );
  };

  return (
    <AppBar
      color="transparent"
      position="sticky"
      style={{
        top: "0px",
        bottom: "auto",
      }}
      elevation={0}
    >
      {getAlertMessage()}
      <br />
    </AppBar>
  );
};

export default CompletionConfirmation;
