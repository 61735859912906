import {
  Datagrid,
  EditButton,
  List,
  TextField,
  TopToolbar,
  CreateButton,
  ExportButton,
  SortButton,
  DateField,
  ChipField,
} from "react-admin";
import React from "react";
import { ServiceProviderNotesFilter } from "./Filter";

export const ServiceProviderNotesList = () => {
  return (
    <List
      sx={{
        ".RaFilter-form": {
          flex: "0 1 50%",
        },
      }}
      filters={<ServiceProviderNotesFilter />}
      sort={{ field: "id", order: "ASC" }}
      actions={
        <TopToolbar>
          <SortButton fields={["id", "textSearchRank"]} />
          <CreateButton />
          <ExportButton maxResults={250}/>
        </TopToolbar>
      }
    >
      <Datagrid rowClick="edit" bulkActionButtons={false}>
        <TextField label="Note Text" source="noteText" sortable={false} />
        <TextField source="modifyUser" sortable={false} />
        <DateField
          label="Date"
          source="eventDate"
          options={{ timeZone: "UTC" }}
          sortable={false}
        />
        <ChipField
          label="Type"
          source="providerNoteTypeEnum.name"
          sortable={false}
        />
        <EditButton />
      </Datagrid>
    </List>
  );
};
