import {
  useInput,
  useGetList,
  useGetMany,
  useRecordContext,
} from "react-admin";
import * as client from "_graphql-types";
import { camelCase } from "lodash";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Grid, Tooltip, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { Chip, ChipProps } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import {
  MainCategoryTable,
  AdditionalCategoriesTable,
  calculateTotal,
} from "./CustomInput";
import { useMemo, useState } from "react";
import {
  FieldError,
  FieldErrors,
  useForm,
  useFormState,
  useWatch,
} from "react-hook-form";
import { styled } from "@mui/system";

type MeasureEnum = NonNullable<client.GetOneMeasureEnumQuery["measureEnum"]>;
type Category1 = NonNullable<
  client.GetOneDeiCategory1EnumQuery["deiCategory1Enum"]
>;
type Category2 = NonNullable<
  client.GetOneDeiCategory2EnumQuery["deiCategory2Enum"]
>;

const ChipWithErrorTooltip = (props: ChipProps & { error?: FieldError }) => {
  const { error, ...rest } = props;
  if (error) {
    return (
      <Tooltip title={error.message} placement="left">
        <Chip {...rest} />
      </Tooltip>
    );
  }
  return <Chip {...rest} />;
};

const AccordionBox = styled(Box)({
  marginTop: "1em",
  marginBottom: "2em",
  width: "100%",
});

// this is temporary until the other race/ethnicity categories are mapped to these and removed from the database
export const MAIN_CATEGORIES = [1, 2, 3, 4, 18, 24, 8, 7, 25];
export const MAIN_CATEGORIES_REST_OF_WORLD = [26];
export const ADDITIONAL_CATEGORIES = [9, 10, 11];
export const REST_OF_WORLD = 5;

type Record = NonNullable<client.GetOneFirmDeiQuery["firmDEI"]>;

export function FirmDEIRecordInput() {
  const formData = useWatch();
  const record = useRecordContext<Record>();
  if (!record) return;

  const isCompany = formData?.firm?.isCompany;
  const isRestOfWorld = formData?.geographyEnumId === REST_OF_WORLD;
  const _MAIN_CATEGORIES = isRestOfWorld
    ? MAIN_CATEGORIES_REST_OF_WORLD
    : MAIN_CATEGORIES;

  const { errors }: { errors: FieldErrors<Record> } = useFormState();

  const [localStateRecords, setLocalStateRecords] = useState(
    formData?.firmDEIRecords ?? []
  );

  const { data: measureEnums }: { data?: MeasureEnum[] } = useGetList(
    "measureEnum",
    {
      pagination: { page: 1, perPage: 999 },
      sort: { field: "displayOrder", order: "ASC" },
    }
  );

  const { data: deiCategory1Enum }: { data?: Category1[] } = useGetMany(
    "deiCategory1Enum",
    {
      ids: [...MAIN_CATEGORIES, ...ADDITIONAL_CATEGORIES],
    }
  );

  const { data: category2 }: { data?: Category2[] } = useGetList(
    "deiCategory2Enum",
    {
      pagination: { page: 1, perPage: 999 },
      sort: { field: "name", order: "ASC" },
    }
  );

  const {
    field: { onChange },
  } = useInput({ source: "firmDEIRecords" });

  const memoizedOnChange = useMemo(() => onChange, []);

  const category1 = useMemo(
    () => (deiCategory1Enum || []).filter(c => _MAIN_CATEGORIES.includes(c.id)),
    [deiCategory1Enum, _MAIN_CATEGORIES]
  );

  const additionalCategories = useMemo(
    () =>
      (deiCategory1Enum || []).filter(c =>
        ADDITIONAL_CATEGORIES.includes(c.id)
      ),
    [deiCategory1Enum]
  );

  if (!measureEnums || !category2 || !deiCategory1Enum) return <></>;

  const companyMeasures = [
    "Company Head (CEO)",
    "Company Leadership (C-Suite)",
    "Founders",
    "Board of Directors / Advisory Board",
  ];
  const companyOrFirmMeasures = isCompany
    ? measureEnums.filter(measure =>
        companyMeasures.includes(measure.description)
      )
    : measureEnums.filter(
        measure =>
          measure.measureTypeEnum?.name !== "Investment Professionals" &&
          measure.measureTypeEnum?.name !==
            "Operations / Administration Professionals" &&
          ![
            "Minority Leadership (CEO)",
            "Company Leadership (C-Suite)",
          ].includes(measure.description)
      );

  return (
    <>
      <AccordionBox>
        {companyOrFirmMeasures.map(measure => (
          <Accordion
            key={`${record.id}::${measure.id}`}
            id={`${measure.id}`}
            TransitionProps={{ unmountOnExit: true }}
          >
            <AccordionSummary
              aria-controls={`${camelCase(measure.description)}-content`}
              expandIcon={<ExpandMoreIcon />}
            >
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs={11}>
                      <h4>{measure.description}</h4>
                    </Grid>
                    <Grid item xs={1}>
                      <ChipWithErrorTooltip
                        error={
                          errors?.firmDEIRecords?.[measure.id] as FieldError
                        }
                        color={
                          errors?.firmDEIRecords?.[measure.id]
                            ? "error"
                            : "primary"
                        }
                        size="small"
                        sx={{ opacity: 0.75 }}
                        data-cy={`${record.id}::${measure.id}::total`}
                        label={`${calculateTotal({
                          records: formData?.firmDEIRecords ?? [],
                          measureEnumId: measure.id,
                          categoryIds: _MAIN_CATEGORIES,
                        })}${
                          measure.measureTypeEnum?.name === "Percent"
                            ? " %"
                            : ""
                        }`}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Typography component="p" variant="subtitle2">
                    {measure.questionText}
                  </Typography>
                </Grid>
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <MainCategoryTable
                    measureEnumId={measure.id}
                    recordId={record.id}
                    isPercent={measure.measureTypeEnum?.name === "Percent"}
                    firmDEIRecords={formData?.firmDEIRecords}
                    raOnChange={memoizedOnChange}
                    MAIN_CATEGORIES={_MAIN_CATEGORIES}
                    {...{
                      category1,
                      category2,
                      localStateRecords,
                      setLocalStateRecords,
                      isRestOfWorld,
                    }}
                  />
                </Grid>
                <Grid item xs={4} />
                <Grid item xs={8}>
                  <AdditionalCategoriesTable
                    measureEnumId={measure.id}
                    recordId={record.id}
                    firmDEIRecords={formData?.firmDEIRecords}
                    headerCategories={additionalCategories}
                    isPercent={measure.measureTypeEnum?.name === "Percent"}
                    raOnChange={memoizedOnChange}
                    {...{
                      localStateRecords,
                      setLocalStateRecords,
                    }}
                  />
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        ))}
      </AccordionBox>
      {!isCompany && (
        <>
          <Typography variant="h5" component="h2">
            Professionals
          </Typography>
          <Typography variant="subtitle1">
            Please provide information on the diversity of all FTEs employed by
            the firm, inclusive of any individuals noted in the tables above.
          </Typography>

          <AccordionBox>
            <h3>Investment Professionals</h3>

            {measureEnums
              .filter(
                measure =>
                  measure.measureTypeEnum?.name === "Investment Professionals"
              )
              .map(measure => (
                <Accordion
                  key={`${record.id}::${measure.id}`}
                  id={`${measure.id}`}
                  TransitionProps={{ unmountOnExit: true }}
                >
                  <AccordionSummary
                    aria-controls={`${camelCase(measure.description)}-content`}
                    expandIcon={<ExpandMoreIcon />}
                  >
                    <Grid container>
                      <Grid item xs={11}>
                        <h4>{measure.description}</h4>
                      </Grid>
                      <Grid item xs={1}>
                        <ChipWithErrorTooltip
                          error={
                            errors?.firmDEIRecords?.[measure.id] as FieldError
                          }
                          color={
                            errors?.firmDEIRecords?.[measure.id]
                              ? "error"
                              : "primary"
                          }
                          size="small"
                          sx={{ opacity: 0.75 }}
                          data-cy={`${record.id}::${measure.id}::total`}
                          label={`${calculateTotal({
                            records: formData?.firmDEIRecords ?? [],
                            measureEnumId: measure.id,
                            categoryIds: _MAIN_CATEGORIES,
                          })}${
                            measure.measureTypeEnum?.name === "Percent"
                              ? " %"
                              : ""
                          }`}
                        />
                      </Grid>
                    </Grid>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <MainCategoryTable
                          measureEnumId={measure.id}
                          recordId={record.id}
                          isPercent={
                            measure.measureTypeEnum?.name === "Percent"
                          }
                          firmDEIRecords={formData?.firmDEIRecords}
                          raOnChange={memoizedOnChange}
                          MAIN_CATEGORIES={_MAIN_CATEGORIES}
                          {...{
                            category1,
                            category2,
                            localStateRecords,
                            setLocalStateRecords,
                            isRestOfWorld,
                          }}
                        />
                      </Grid>
                      <Grid item xs={4} />
                      <Grid item xs={8}>
                        <AdditionalCategoriesTable
                          measureEnumId={measure.id}
                          recordId={record.id}
                          firmDEIRecords={formData?.firmDEIRecords}
                          headerCategories={additionalCategories}
                          isPercent={
                            measure.measureTypeEnum?.name === "Percent"
                          }
                          raOnChange={memoizedOnChange}
                          {...{
                            localStateRecords,
                            setLocalStateRecords,
                          }}
                        />
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              ))}
          </AccordionBox>
          <AccordionBox>
            <h3>Operations / Administration Professionals</h3>
            {measureEnums
              .filter(
                measure =>
                  measure.measureTypeEnum?.name ===
                  "Operations / Administration Professionals"
              )
              .map(measure => (
                <Accordion
                  key={`${record.id}::${measure.id}`}
                  id={`${measure.id}`}
                  TransitionProps={{ unmountOnExit: true }}
                >
                  <AccordionSummary
                    aria-controls={`${camelCase(measure.description)}-content`}
                    expandIcon={<ExpandMoreIcon />}
                  >
                    <Grid container>
                      <Grid item xs={11}>
                        <h4>{measure.description}</h4>
                      </Grid>
                      <Grid item xs={1}>
                        <ChipWithErrorTooltip
                          error={
                            errors?.firmDEIRecords?.[measure.id] as FieldError
                          }
                          color={
                            errors?.firmDEIRecords?.[measure.id]
                              ? "error"
                              : "primary"
                          }
                          size="small"
                          sx={{ opacity: 0.75 }}
                          data-cy={`${record.id}::${measure.id}::total`}
                          label={`${calculateTotal({
                            records: formData?.firmDEIRecords ?? [],
                            measureEnumId: measure.id,
                            categoryIds: _MAIN_CATEGORIES,
                          })}${
                            measure.measureTypeEnum?.name === "Percent"
                              ? " %"
                              : ""
                          }`}
                        />
                      </Grid>
                    </Grid>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <MainCategoryTable
                          measureEnumId={measure.id}
                          recordId={record.id}
                          isPercent={
                            measure.measureTypeEnum?.name === "Percent"
                          }
                          firmDEIRecords={formData?.firmDEIRecords}
                          raOnChange={memoizedOnChange}
                          MAIN_CATEGORIES={_MAIN_CATEGORIES}
                          {...{
                            category1,
                            category2,
                            localStateRecords,
                            setLocalStateRecords,
                            isRestOfWorld,
                          }}
                        />
                      </Grid>
                      <Grid item xs={4} />
                      <Grid item xs={8}>
                        <AdditionalCategoriesTable
                          measureEnumId={measure.id}
                          recordId={record.id}
                          firmDEIRecords={formData?.firmDEIRecords}
                          headerCategories={additionalCategories}
                          raOnChange={memoizedOnChange}
                          isPercent={
                            measure.measureTypeEnum?.name === "Percent"
                          }
                          {...{
                            localStateRecords,
                            setLocalStateRecords,
                          }}
                        />
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              ))}
          </AccordionBox>
        </>
      )}
    </>
  );
}
