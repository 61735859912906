import * as client from "_graphql-types";
import * as types from "./types";

import { filterSortPage } from "../DataProvider";

type Sdk = ReturnType<typeof client.getSdk>;

/*********************************************************************/
// Data Provider Functions
/*********************************************************************/
async function getList(
  sdk: Sdk,
  params: types.GetListParams<
    client.NoteMetaEnumSortEnum,
    client.NoteMetaEnumFilter
  >
) {
  const { pagination, sort, filter } = params;

  const {
    noteMetaEnumList: { items: data, total },
  } = await sdk.getListNotesMetaEnum(
    filterSortPage({ filter, sort, pagination })
  );

  return {
    data,
    total,
  };
}

/*********************************************************************/
async function getManyReference(
  sdk: Sdk,
  params: types.GetManyReferenceParams<client.NoteMetaEnumSortEnum>
) {
  const id = Number(params.id);
  const {
    pagination: { page, perPage },
    sort: { field, order },
    filter,
    target,
  } = params;

  const {
    noteMetaEnumList: { items: data, total },
  } = await sdk.getListNotesMetaEnum({
    filter: { ...filter, [target]: id },
    page: {
      offset: (page - 1) * perPage,
      limit: perPage,
    },
    sort: [{ field, order }],
  });

  return {
    data,
    total,
  };
}

async function getMany(sdk: Sdk, params: types.GetManyParams) {
  const ids = params.ids.map(id => Number(id));

  const { noteMetaEnumMany: data } = await sdk.getManyNotesMetaEnum({
    ids,
  });

  return {
    data,
  };
}

/*********************************************************************/
export const noteMetaEnum = types.dataProvider({
  getList,
  getMany,
  getManyReference,
  ttl: types.defaultEnumTtl,
});
