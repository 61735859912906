import { Grid } from "@mui/material";
import * as client from "_graphql-types";
import { useMemo } from "react";
import {
  BooleanInput,
  Button,
  DateInput,
  FormDataConsumer,
  ReferenceInput,
  SelectInput,
  TextInput,
  regex,
  required,
  useRecordContext,
} from "react-admin";
import { useWatch } from "react-hook-form";
import { Link } from "react-router-dom";
import { CustomEdit } from "../CustomEdit";
import { CustomTagsInput } from "../CustomTagsInput";
import { EntityInput } from "../UI/EntityInput";

import { NO_WHITESPACE_REGEX } from "frontend/src/utils/constants";
import CustomBreadCrumb from "../CustomBreadCumb";
import {
  CommaFormattedNumber,
  FormattedNumberInput,
} from "../CustomNumberInput";

export type Record = NonNullable<client.GetOneDealQuery["deal"]>;

const DocumentsButton = () => {
  const record = useRecordContext<Record>();
  return !record ? null : (
    <Button
      component={Link}
      to={{
        pathname: `/dealDocument/${record.id}`,
      }}
      label="Go to Documents"
      data-id="documents"
    />
  );
};

const NotesButton = () => {
  const record = useRecordContext<Record>();
  return !record ? null : (
    <Button
      component={Link}
      to={{
        pathname: `/dealNotes/${record.id}`,
      }}
      label="Go to Notes"
      data-id="notes"
    />
  );
};

const ViewInvestorButton = () => {
  const record = useRecordContext<Record>();
  return !record?.firm?.id ? null : (
    <Button
      component={Link}
      to={{
        pathname: `/firm/${record.firm.id}`,
      }}
      label="Go to Firm"
    />
  );
};

const ViewCompanyButton = () => {
  const record = useRecordContext<Record>();
  return !record?.company?.id ? null : (
    <Button
      component={Link}
      to={{
        pathname: `/company/${record.company.id}`,
      }}
      label="Go to Company"
    />
  );
};

function PassedNoteInput() {
  const resultEnumId = useWatch({ name: "resultEnumId" });
  if (resultEnumId && Number(resultEnumId) === 1) {
    return (
      <Grid item xs={12}>
        <TextInput
          label="Passed Comment"
          source="passedNote"
          multiline
          rows={5}
        />
      </Grid>
    );
  }
  return null;
}

// This needs to be a constant variable, to prevent POSTs on every rerender.
const COMPANY_TAG_CLASS_IDS = [22];
const DEAL_TAG_CLASS_IDS = [22, 24];

export const DealOrCompanyTags = () => {
  const company = useWatch({ name: "company" });

  const tagElement = useMemo(() => {
    if (company?.id) {
      return (
        <>
          <Grid item xs={12}>
            <hr />
            <h3 data-cy="companyTags__header">Company Tags</h3>
          </Grid>
          <CustomTagsInput
            source="company.firmAttributes.firmTags"
            label="Company Tags"
            readOnly
            tagClassIdList={COMPANY_TAG_CLASS_IDS}
          />
        </>
      );
    }

    return (
      <>
        <Grid item xs={12}>
          <hr />
          <h3 data-cy="dealTags__header">Deal Tags</h3>
        </Grid>
        <CustomTagsInput
          source="dealTags"
          label="Deal Tags"
          tagClassIdList={DEAL_TAG_CLASS_IDS}
        />
      </>
    );
  }, [company?.id]);

  return tagElement;
};

const validateRequiredNoWhitespace = [
  required(),
  regex(NO_WHITESPACE_REGEX, "Please remove leading and trailing spaces"),
];

export const DealEdit = () => {
  return (
    <CustomEdit<Record>
      title={record => record && `Deal - ${record.name || ""}`}
      actions={
        <CustomBreadCrumb<Record>
          name="deal"
          items={[
            {
              path: "company",
              getId: record => record?.company?.id,
              getName: record => record?.company?.name,
            },
            {
              path: "firm",
              getId: record => record?.firm?.id,
              getName: record => record?.firm?.name,
            },
          ]}
        />
      }
    >
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <ViewCompanyButton />
        </Grid>
        <Grid item xs={6}>
          <ViewInvestorButton />
        </Grid>
        <Grid item xs={6}>
          <EntityInput<NonNullable<Record>>
            resource="company"
            recordKey="company"
            label="Company"
            isRequired={false}
            allowChange={true}
          />
        </Grid>
        <Grid item xs={6}>
          <EntityInput<NonNullable<Record>>
            resource="firm"
            recordKey="firm"
            label="Firm"
            isRequired={true}
            allowChange={true}
          />
        </Grid>
        <Grid item xs={3}>
          <DocumentsButton />
        </Grid>
        <Grid item xs={3}>
          <NotesButton />
        </Grid>
        <Grid item xs={6} />
        <Grid item xs={6}>
          <TextInput
            label="Project Name"
            source="name"
            validate={validateRequiredNoWhitespace}
          />
        </Grid>
        <Grid item xs={3}>
          <BooleanInput source="isLeadInvestor" label="Lead Investor" />
        </Grid>
        <Grid item xs={3}>
          <BooleanInput source="isImpact" label="Is Impact" />
        </Grid>
        <Grid item xs={6}>
          <ReferenceInput
            source="rcSource"
            reference="investmentAssignmentOption"
            sort={{ field: "commonName", order: "ASC" }}
            filter={{ isPrimary: true }}
            perPage={500}
          >
            <SelectInput
              fullWidth={true}
              optionText="commonName"
              label="RC Source"
            />
          </ReferenceInput>
        </Grid>
        <Grid item xs={6}>
          <TextInput label="Source" source="source" />
        </Grid>
        <Grid item xs={6}>
          <ReferenceInput
            source="fundingRoundEnumId"
            reference="fundingTypeEnum"
            sort={{ field: "name", order: "ASC" }}
          >
            <SelectInput
              fullWidth={true}
              optionText="name"
              label="Funding Round"
            />
          </ReferenceInput>
        </Grid>
        <Grid item xs={6}>
          <DateInput label="Date Received" source="dateReceived" />
        </Grid>
        <Grid item xs={6}>
          <DateInput label="Expected Close Date" source="expectedCloseDate" />
        </Grid>

        <Grid item xs={6}>
          <ReferenceInput
            source="dealStructureEnumId"
            reference="dealStructureEnum"
            sort={{ field: "name", order: "ASC" }}
          >
            <SelectInput
              fullWidth={true}
              optionText="name"
              label="Deal Structure"
            />
          </ReferenceInput>
        </Grid>

        <Grid item xs={6}>
          <ReferenceInput
            source="stageEnumId"
            reference="stageEnum"
            sort={{ field: "displayOrder", order: "ASC" }}
          >
            <SelectInput fullWidth={true} optionText="name" label="Stage" />
          </ReferenceInput>
        </Grid>
        <Grid item xs={6}>
          <ReferenceInput
            source="resultEnumId"
            reference="resultEnum"
            sort={{ field: "name", order: "ASC" }}
          >
            <SelectInput fullWidth={true} optionText="name" label="Result" />
          </ReferenceInput>
        </Grid>
        <PassedNoteInput />

        <Grid item xs={6}>
          <FormattedNumberInput
            source="amount"
            variant="outlined"
            label="Amount"
            fullWidth
            FormattedComponent={CommaFormattedNumber}
          />
        </Grid>
        <Grid item xs={6}>
          <FormattedNumberInput
            source="roundSize"
            variant="outlined"
            label="Total Round Size"
            fullWidth
            FormattedComponent={CommaFormattedNumber}
          />
        </Grid>
        <Grid item xs={6}>
          <FormattedNumberInput
            source="preMoneyValuation"
            variant="outlined"
            label="Pre-money Valuation"
            fullWidth
            FormattedComponent={CommaFormattedNumber}
          />
        </Grid>
        <Grid item xs={6}>
          <ReferenceInput
            source="headquartersEnumId"
            reference="countryEnum"
            perPage={1000}
            sort={{ field: "name", order: "ASC" }}
          >
            <SelectInput
              fullWidth={true}
              optionText="name"
              label="Headquarters"
            />
          </ReferenceInput>
        </Grid>
        <Grid item xs={6}>
          <ReferenceInput
            source="assetClassEnumId"
            reference="assetClassEnum"
            sort={{ field: "assetClass", order: "ASC" }}
          >
            <SelectInput
              fullWidth={true}
              optionText="name"
              label="Asset Class"
            />
          </ReferenceInput>
        </Grid>
        <FormDataConsumer>
          {({ formData, ...rest }) => {
            return (
              <Grid item xs={6}>
                <ReferenceInput
                  source="strategyEnumId"
                  reference="strategyEnum"
                  sort={{ field: "strategy", order: "ASC" }}
                  filter={{
                    ...(formData?.assetClassEnumId && {
                      assetClassId: formData.assetClassEnumId,
                    }),
                  }}
                  {...rest}
                >
                  <SelectInput
                    fullWidth={true}
                    optionText="name"
                    label="Strategy"
                    disabled={formData?.assetClassEnumId === undefined}
                  />
                </ReferenceInput>
              </Grid>
            );
          }}
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData, ...rest }) => {
            return (
              <Grid item xs={6}>
                <ReferenceInput
                  source="subStrategyEnumId"
                  reference="subStrategyEnum"
                  filter={{
                    ...(formData?.strategyEnumId && {
                      strategyId: formData.strategyEnumId,
                    }),
                  }}
                  {...rest}
                >
                  <SelectInput
                    fullWidth={true}
                    optionText="name"
                    label="Sub Strategy"
                    disabled={formData?.strategyEnumId === undefined}
                  />
                </ReferenceInput>
              </Grid>
            );
          }}
        </FormDataConsumer>
        <Grid item xs={6}>
          <ReferenceInput
            source="dealMarketingEnumId"
            reference="dealMarketingEnum"
            sort={{ field: "displayOrder", order: "ASC" }}
          >
            <SelectInput
              fullWidth={true}
              optionText="name"
              label="Deal Marketing"
            />
          </ReferenceInput>
        </Grid>
        <Grid item xs={12}>
          <TextInput label="Deal Summary" source="summary" multiline rows={5} />
        </Grid>
        <Grid item xs={12}>
          <TextInput
            label="Co-Investors"
            source="coInvestors"
            multiline
            rows={3}
          />
        </Grid>
        <DealOrCompanyTags />
      </Grid>
    </CustomEdit>
  );
};
