import { CognitoUser } from "@aws-amplify/auth";
import {
  Button,
  Divider,
  Grid,
  StyledEngineProvider,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import { useContext, useEffect, useMemo, useState } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { buildOktaAuth, getCognitoAuth } from "./Auth";
import { AuthContext } from "./AuthProvider.component";
import { BusinessApplicantSignUp } from "./BusinessApplicantSignup";
import {
  AuthState,
  CognitoConfirmSignIn,
  CognitoSignIn,
} from "./CognitoSignIn";
import ExelonLogo from "./ExelonCorpLogo.png";
import { ExternalManagerSignUp } from "./ExternalManagerSignUp";
import Header from "./Header";
import ODCLogo from "./ODCLogo.svg?url";
import OktaLogo from "./Okta_Logo_White_Medium.png";
import RCGLogo from "./RCGLogo.svg";

export enum LoginContextEnum {
  main = "main",
  businessApplicant = "businessApplicant",
  externalManager = "externalManager",
  rcManager = "rcManager",
}

function getLoginRouteFromContext(option: LoginContextEnum) {
  return option === LoginContextEnum.businessApplicant
    ? "businessApplication"
    : option === LoginContextEnum.externalManager
    ? "external"
    : option === LoginContextEnum.rcManager
    ? "internal"
    : "";
}

function InternalLogin() {
  const oktaAuth = useMemo(() => {
    return buildOktaAuth();
  }, []);

  return (
    <Button
      color={"primary"}
      onClick={() => oktaAuth.signInWithRedirect()}
      data-cy="okta-login"
      variant="contained"
      fullWidth
      sx={{ height: 100 }}
    >
      <img src={OktaLogo} alt="Okta Logo" height="80" />
    </Button>
  );
}

function ExternalLogin() {
  const { configure } = useContext(AuthContext);
  const [authState, setAuthState] = useState<AuthState>(AuthState.SignIn);

  useEffect(() => {
    const configureAuth = async () => {
      const auth = await getCognitoAuth();
      if (auth) configure(auth);
    };

    configureAuth();
  }, [authState, configure]);

  const [cognitoUser, setCustomLoginCognitoUser] = useState<CognitoUser>();

  return (
    <div>
      {authState === AuthState.SignedIn && <Navigate to="/" />}

      {authState === AuthState.CustomConfirmSignIn && (
        <CognitoConfirmSignIn
          setAuthState={setAuthState}
          cognitoUser={cognitoUser as CognitoUser}
        />
      )}
      {authState === AuthState.SignIn && (
        <CognitoSignIn
          setAuthState={setAuthState}
          setCustomLoginCognitoUser={setCustomLoginCognitoUser}
        />
      )}
    </div>
  );
}

export default function Login({
  loginContext,
}: {
  loginContext: LoginContextEnum;
}) {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    window.onpopstate = () => {
      if (location.pathname === "/login") {
        localStorage.removeItem("dcLoginOption");
        navigate("/login");
      }
    };
  });

  const { auth } = useContext(AuthContext);
  const [showBusinessApplicantSignup, changeShowBusinessApplicantSignup] =
    useState<boolean>(false);
  const [showCognitoUserSignup, changeShowCognitoUserSignup] =
    useState<boolean>(false);

  if (auth) return <Navigate to="/" />;

  function handleClick() {
    localStorage.removeItem("dcLoginOption");
    navigate("/login");
  }

  const savedLoginOption = localStorage.getItem("dcLoginOption") || undefined;
  if (savedLoginOption && loginContext === LoginContextEnum.main) {
    const reroute = getLoginRouteFromContext(
      savedLoginOption as LoginContextEnum
    );
    return <Navigate to={`/login/${reroute}`} />;
  } else if (loginContext !== LoginContextEnum.main) {
    localStorage.setItem("dcLoginOption", loginContext);
  }

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider
        theme={createTheme({
          palette: {
            primary: {
              light: "#757ce8",
              main: "#0d4f94",
              dark: "#002884",
              contrastText: "#fff",
            },
            secondary: {
              light: "#ff7961",
              main: "#f44336",
              dark: "#ba000d",
              contrastText: "#000",
            },
          },
        })}
      >
        <div
          style={{
            backgroundColor: "#F3F4F6",
            width: "100%",
            height: "100%",
          }}
        >
          <Header />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={
                loginContext === LoginContextEnum.main
                  ? {
                      backgroundColor: "white",
                      marginTop: "90px",
                      marginRight: "20px",
                      marginLeft: "20px",
                      padding: "15px",
                      minHeight: "180px",
                      minWidth: "960px",
                      borderWidth: "2px",
                    }
                  : {
                      backgroundColor: "white",
                      marginTop: "45px",
                      marginRight: "20px",
                      marginLeft: "20px",
                      padding: "15px",
                      minHeight: "560px",
                      minWidth: "460px",
                      borderWidth: "2px",
                    }
              }
            >
              {loginContext === LoginContextEnum.main && (
                <Grid
                  container
                  spacing={2}
                  alignItems="center"
                  justifyContent="center"
                >
                  <Grid container item spacing={2} direction="row">
                    <Grid
                      item
                      xs={12}
                      sm={4}
                      style={{ marginBottom: "2em", marginTop: "1em" }}
                    >
                      <Button
                        color={"primary"}
                        href={"login/external"}
                        data-cy="odc-login"
                        variant="contained"
                        fullWidth
                      >
                        <img src={ODCLogo} alt="ODC Logo" />
                      </Button>
                      <h3
                        style={{ marginBottom: "0.5em", textAlign: "center" }}
                      >
                        External Manager Login
                      </h3>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={4}
                      style={{ marginBottom: "2em", marginTop: "1em" }}
                    >
                      <Button
                        color={"primary"}
                        href={"login/businessApplication"}
                        data-cy="exelon-login"
                        variant="contained"
                        fullWidth
                        sx={{ height: 100 }}
                      >
                        <img src={ExelonLogo} alt="Exelon Logo" height="80" />
                      </Button>
                      <h3
                        style={{ marginBottom: "0.5em", textAlign: "center" }}
                      >
                        Exelon Application Login
                      </h3>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={4}
                      style={{ marginBottom: "2em", marginTop: "1em" }}
                    >
                      <Button
                        color={"primary"}
                        href={"login/internal"}
                        data-cy="rcg-login"
                        variant="contained"
                        fullWidth
                      >
                        <img
                          src={RCGLogo}
                          alt="RCG Logo"
                          style={{ color: "white", height: "88px" }}
                        />
                      </Button>
                      <h3
                        style={{ marginBottom: "0.5em", textAlign: "center" }}
                      >
                        RockCreek Login
                      </h3>
                    </Grid>
                  </Grid>
                </Grid>
              )}
              {loginContext === LoginContextEnum.businessApplicant && (
                <>
                  {showBusinessApplicantSignup ? (
                    <div style={{ marginBottom: "2em", marginTop: "1em" }}>
                      <h3 style={{ marginBottom: "0.5em" }}>Sign Up</h3>
                      <BusinessApplicantSignUp
                        changeShowSignup={changeShowBusinessApplicantSignup}
                      />
                      <div style={{ marginTop: "7em", float: "right" }}>
                        <Button
                          onClick={handleClick}
                          variant="text"
                          color="primary"
                        >
                          Back to Login Options
                        </Button>
                      </div>
                    </div>
                  ) : (
                    <div style={{ marginBottom: "2em", marginTop: "1em" }}>
                      <h3 style={{ marginBottom: "0.5em" }}>Applicant Login</h3>

                      <div style={{ marginBottom: "2em" }}>
                        <ExternalLogin />
                      </div>

                      <Divider />

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-around",
                          marginTop: "2em",
                        }}
                      >
                        <Button
                          onClick={() =>
                            changeShowBusinessApplicantSignup(true)
                          }
                          variant="outlined"
                          color="primary"
                          fullWidth={true}
                          data-cy="sign-up-button"
                        >
                          Sign Up
                        </Button>
                      </div>
                      <div style={{ marginTop: "13em", float: "right" }}>
                        <Button
                          onClick={handleClick}
                          variant="text"
                          color="primary"
                        >
                          Back to Login Options
                        </Button>
                      </div>
                    </div>
                  )}
                </>
              )}
              {loginContext === LoginContextEnum.externalManager && (
                <>
                  {showCognitoUserSignup ? (
                    <div style={{ marginBottom: "2em", marginTop: "1em" }}>
                      <h3 style={{ marginBottom: "0.5em" }}>Sign Up</h3>
                      <ExternalManagerSignUp
                        changeShowSignup={changeShowCognitoUserSignup}
                      />
                      <div style={{ marginTop: "7em", float: "right" }}>
                        <Button
                          onClick={handleClick}
                          variant="text"
                          color="primary"
                        >
                          Back to Login Options
                        </Button>
                      </div>
                    </div>
                  ) : (
                    <div style={{ marginBottom: "2em", marginTop: "1em" }}>
                      <h3 style={{ marginBottom: "0.5em" }}>External Login</h3>

                      <div style={{ marginBottom: "2em" }}>
                        <ExternalLogin />
                      </div>
                      <Divider />

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-around",
                          marginTop: "2em",
                        }}
                      >
                        <Button
                          onClick={() => changeShowCognitoUserSignup(true)}
                          variant="outlined"
                          color="primary"
                          fullWidth={true}
                          data-cy="sign-up-button"
                        >
                          Sign Up
                        </Button>
                      </div>
                      <div style={{ marginTop: "13em", float: "right" }}>
                        <Button
                          onClick={handleClick}
                          variant="text"
                          color="primary"
                        >
                          Back to Login Options
                        </Button>
                      </div>
                    </div>
                  )}
                </>
              )}
              {loginContext === LoginContextEnum.rcManager && (
                <>
                  <div style={{ marginBottom: "2em", marginTop: "1em" }}>
                    <h2 style={{ marginBottom: "0.5em" }}>Internal Login</h2>
                    <div style={{ marginTop: "6em" }}>
                      <InternalLogin />
                    </div>
                    <div style={{ marginTop: "18em", float: "right" }}>
                      <Button
                        onClick={handleClick}
                        variant="text"
                        color="primary"
                      >
                        Back to Login Options
                      </Button>
                    </div>
                  </div>
                </>
              )}
            </div>
            <div
              style={{
                marginTop: "45px",
                marginRight: "40px",
                marginLeft: "40px",
                padding: "15px",
                minHeight: "260px",
                minWidth: "460px",
                borderWidth: "2px",
              }}
            >
              <p>
                Since our founding in 2003 by CEO Afsaneh Mashayekhi Beschloss,
                the RockCreek Group has mobilized capital, resources, and
                insights for action. RockCreek invests in a diverse portfolio of
                entrepreneurs, companies, and investors. We believe that
                integrating inclusion and sustainability from the very beginning
                is the soundest way to generate long-term value.
              </p>
              <p>
                We believe that what is good for the world and what is right for
                pensioners, students, workers, and future generations are one
                and the same. Since our founding, RockCreek’s commitment to
                sustainable value creation and good governance has been rooted
                in the firm’s entrepreneurial spirit, open culture, and
                inclusive team.
              </p>
              <p>
                For more information, please visit{" "}
                <a
                  href="https://therockcreekgroup.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  therockcreekgroup.com
                </a>
                .
              </p>
            </div>
          </div>
        </div>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
