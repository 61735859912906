import * as client from "_graphql-types";
import * as types from "./types";

type Sdk = ReturnType<typeof client.getSdk>;

/*********************************************************************/
// Data Provider Functions
/*********************************************************************/

/*********************************************************************/
async function getList(
  sdk: Sdk,
  params: types.GetListParams<client.FirmInstitutionsSortEnum>
) {
  const {
    pagination: { page, perPage },
    sort: { field, order },
    filter,
  } = params;

  const {
    firmInstitutionsList: { items: data, total },
  } = await sdk.getListFirmInstitutions({
    page: {
      offset: (page - 1) * perPage,
      limit: perPage,
    },
    sort: [{ field, order }],
    filter,
  });

  return {
    data,
    total,
  };
}

/*********************************************************************/
async function getOne(sdk: Sdk, params: types.GetOneParams) {
  const id = params.id;
  const { firmInstitutions: data } = await sdk.getOneFirmInstitutions({
    id: Number(id),
  });
  return {
    data,
  };
}

/*********************************************************************/
async function getMany(sdk: Sdk, params: types.GetManyParams) {
  const ids = params.ids;

  const { firmInstitutionsMany: data } = await sdk.getManyFirmInstitutions({
    ids: ids.map(Number),
  });

  return {
    data,
  };
}

/*********************************************************************/
async function getManyReference(
  sdk: Sdk,
  params: types.GetManyReferenceParams<client.FirmInstitutionsSortEnum>
) {
  const id = params.id;
  const {
    pagination: { page, perPage },
    sort: { field, order },
    filter,
    target,
  } = params;

  const {
    firmInstitutionsList: { items: data, total },
  } = await sdk.getListFirmInstitutions({
    page: {
      offset: (page - 1) * perPage,
      limit: perPage,
    },
    sort: [{ field, order }],
    filter: { ...filter, [target]: id },
  });

  return {
    data,
    total,
  };
}

/*********************************************************************/
async function create(
  sdk: Sdk,
  params: types.CreateParams<
    client.CreateFirmInstitutionsMutationVariables["input"]
  >
) {
  const { data } = params;
  const input: client.CreateFirmInstitutionsMutationVariables["input"] = {
    ...data,
  };

  const {
    addFirmInstitutions: { id },
  } = await sdk.createFirmInstitutions({
    input,
  });
  return await getOne(sdk, { id: String(id) });
}

/*********************************************************************/
async function update(
  sdk: Sdk,
  params: types.UpdateParams<
    client.UpdateFirmInstitutionsMutationVariables["input"] &
      client.GetOneFirmInstitutionsQuery["firmInstitutions"]
  >
) {
  const id = params.id;
  const { data } = params;
  const {
    id: _,
    officeLocationServiceType,
    firm,
    institution,
    ...inputData
  } = data;

  const input: client.UpdateFirmInstitutionsMutationVariables["input"] = {
    ...inputData,
  };

  await sdk.updateFirmInstitutions({
    id: Number(id),
    input,
  });
  return await getOne(sdk, { id: String(id) });
}

async function del(sdk: Sdk, params: types.DeleteParams) {
  const id = Number(params.id);
  await sdk.deleteFirmInstitutions({ id });
  return { data: { id } };
}

/*********************************************************************/
export const firmInstitutions = types.dataProvider({
  getList,
  getOne,
  getMany,
  getManyReference,
  create,
  update,
  delete: del,
});
