import * as client from "_graphql-types";
import * as types from "./types";

import { filterSortPage } from "../DataProvider";

type Sdk = ReturnType<typeof client.getSdk>;

/*********************************************************************/
// Data Provider Functions
/*********************************************************************/

/*********************************************************************/
async function getList(
  sdk: Sdk,
  params: types.GetListParams<client.EmailDomainOrganizationSortEnum>
) {
  const { pagination, sort, filter } = params;

  const {
    portalEmailDomainOrganizationList: { items: data, total },
  } = await sdk.getListEmailDomainOrganization(
    filterSortPage({ filter, sort, pagination })
  );

  return {
    data: data.map(d => ({ ...d, id: d.emailDomain })),
    total,
  };
}

/*********************************************************************/
async function getManyReference(
  sdk: Sdk,
  params: types.GetManyReferenceParams<client.EmailDomainOrganizationSortEnum>
) {
  const id = Number(params.id);
  const {
    pagination: { page, perPage },
    sort: { field, order },
    filter,
    target,
  } = params;

  const {
    portalEmailDomainOrganizationList: { items: data, total },
  } = await sdk.getManyReferenceEmailDomainOrganization({
    page: {
      offset: (page - 1) * perPage,
      limit: perPage,
    },
    sort: [{ field, order }],
    filter: { ...filter, [target]: id },
  });

  return {
    data: data.map(d => ({ ...d, id: d.emailDomain })),
    total,
  };
}

/*********************************************************************/
export const portalEmailDomainOrganization = types.dataProvider({
  getList,
  getManyReference,
});
