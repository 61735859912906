import { Grid } from "@mui/material";
import Alert from "@mui/material/Alert";
import * as client from "_graphql-types";
import { Button } from "ra-ui-materialui";
import { Pagination, ReferenceManyField, useRecordContext } from "react-admin";
import { Link } from "react-router-dom";
import { CustomEdit } from "../CustomEdit";
import { FirmDEIDataGrid } from "../FirmDEI/DataGrid";
const newRecordText = "New Geographic Record";

type Record = NonNullable<client.GetOneFirmQuery["firm"]>;

const NewGeographicRecordButton = () => {
  const record = useRecordContext<Record>();
  return (
    <Button
      label={newRecordText}
      component={Link}
      variant="contained"
      to={{
        pathname: `/firmDEI/create`,
        search: `?source=${JSON.stringify({
          ...(record && {
            firm: {
              id: record.id,
              name: encodeURIComponent(record.name),
            },
          }),
        })}`,
      }}
    />
  );
};

export const FirmFirmDEIShow = () => {
  return (
    <CustomEdit<Record>
      title={record => record && `Firm DEI Records - ${record.name}`}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} />
        <Grid item xs={10} />
        <Grid item xs={2}>
          <NewGeographicRecordButton />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Alert severity="info">
            Firm DEI Entries are grouped by geographic location with the most
            recent record for each location shown below. Select one of the
            records below to update data for an existing location. Changing the
            as of date will automatically create a new record pre-filled with
            the existing data so that you can update it accordingly. To begin
            recording data for a new geographic location, click "{newRecordText}
            ".
          </Alert>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <ReferenceManyField
            label="Firm DEI Records"
            reference="firmDEI"
            target="firmId"
            perPage={10}
            pagination={<Pagination />}
            sort={{ field: "asOfDate", order: "DESC" }}
            filter={{ mostRecentMeasure: true }}
          >
            <FirmDEIDataGrid />
          </ReferenceManyField>
        </Grid>
      </Grid>
    </CustomEdit>
  );
};
