import {
  Edit,
  useGetOne,
  SimpleForm,
  ReferenceManyField,
  Datagrid,
  TextField,
  ListBase,
  Pagination,
  Toolbar,
  SaveButton,
  DeleteButton,
} from "react-admin";
import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  Table,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import UserSelectionInput from "./UserSelectionInput";
import { Permissions } from "../CustomAdmin";
import { UserFlags } from "../UserAndGroups/Edit";

interface Props {
  permissions: Permissions;
}
const CARD_SX = { maxWidth: 600, marginBottom: "1em" };
const HEADER_SX = { fontWeight: "bold" };

export function AssumeUserSettings({ permissions }: Props) {
  const { data, isLoading, error } = useGetOne(
    "currentUser",
    { id: "" },
    { refetchOnMount: true }
  );

  if (isLoading) return null;

  if (error) {
    return <>Unable to load user</>;
  }

  console.log("data -> ", data);

  const userId = data.id;

  console.log(userId);

  return (
    <Edit
      resource="assumeUser"
      id={`${userId}`}
      title="Assume User"
      mutationOptions={{
        onSuccess: () => {
          window.location.reload();
        },
      }}
      mutationMode="pessimistic"
    >
      <SimpleForm
        toolbar={
          <Toolbar>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                flex: 1,
              }}
            >
              <SaveButton />
              <DeleteButton
                mutationMode="pessimistic"
                mutationOptions={{
                  onSuccess: () => {
                    window.location.reload();
                  },
                }}
              />
            </div>
          </Toolbar>
        }
      >
        <Grid container spacing={2}>
          <Grid item xs={5}>
            <Card sx={CARD_SX}>
              <CardHeader title={"User"} />
              <CardContent>
                <Table>
                  <TableRow>
                    <TableCell sx={HEADER_SX}>Current User</TableCell>
                    <TableCell>{data?.email}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={HEADER_SX}>Organization id</TableCell>
                    <TableCell>{data?.organizationId}</TableCell>
                  </TableRow>
                </Table>
              </CardContent>
            </Card>
            <UserFlags />
          </Grid>
          <Grid item xs={7}>
            <h3>Accessible Firms</h3>
            <ListBase
              resource="firm"
              sort={{ field: "name", order: "DESC" }}
              perPage={8}
              disableSyncWithLocation={true}
            >
              <Datagrid rowClick="edit" bulkActionButtons={false}>
                <TextField label="ID" source="id" sortable={false} />
                <TextField label="Name" source="name" sortable={false} />
              </Datagrid>
              <Pagination />
            </ListBase>
          </Grid>
          <Grid item xs={6}>
            <Typography data-cy="assume-user__heading" noWrap>
              Assume User
            </Typography>
          </Grid>
          <Grid
            container
            spacing={2}
            style={{ alignItems: "center", marginTop: 2 }}
          >
            <UserSelectionInput source="assumedUserId" />
          </Grid>
        </Grid>
      </SimpleForm>
    </Edit>
  );
}
