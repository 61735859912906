import { Grid } from "@mui/material";
import * as client from "_graphql-types";
import { TextInput } from "react-admin";
import { CustomCreate } from "../CustomCreate";

type Record = NonNullable<
  client.GetOnePortalOrganizationQuery["portalOrganization"]
>;

export function PortalOrganizationCreate() {
  return (
    <CustomCreate<Record>
      title={record => record && `Organization ${record?.name || ""}`}
    >
      <>
        <Grid data-cy="portalOrganization__edit" container spacing={2}>
          <Grid item xs={10}>
            <TextInput label="Organization Name" source="name" />
          </Grid>
        </Grid>
      </>
    </CustomCreate>
  );
}
