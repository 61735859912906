import * as client from "_graphql-types";

import { CustomCreate } from "../CustomCreate";

import { useCanAccessMutation } from "../../util/useCanAccessMutation";
import { PortfolioConfigForm } from "./Form";
import { ImageField, ImageInput } from "react-admin";
import { Grid } from "@mui/material";

type IRecord = NonNullable<
  client.GetOnePortfolioConfigQuery["portfolioConfig"]
>;

export const PortfolioConfigCreate = () => {
  const mutationArgs = JSON.stringify({});

  const {
    canEdit,
    canEditField: fieldAccessMap,
    loading,
  } = useCanAccessMutation("addPortfolioConfig", mutationArgs);

  function canEditField(field: string) {
    return canEdit && fieldAccessMap[field];
  }

  return (
    <CustomCreate<IRecord>
      title={() => "Portfolio Settings"}
      customFormProps={{
        customToolbarProps: {
          canAccessMutation: canEdit,
        },
        loading,
        canEditField: fieldAccessMap,
      }}
    >
      <>
        <PortfolioConfigForm canEditField={canEditField} isCreate />
        <Grid item xs={12}>
          <ImageInput
            source="photo"
            label="Tile Image"
            accept={{ "image/*": [] }}
            maxSize={15 * 1000000}
          >
            <ImageField source="src" />
          </ImageInput>
        </Grid>
      </>
    </CustomCreate>
  );
};
