import * as client from "_graphql-types";
import { Button } from "ra-ui-materialui";
import { useState } from "react";
import {
  Edit,
  Pagination,
  ReferenceManyField,
  useRecordContext,
  useResourceDefinition,
} from "react-admin";
import { Link } from "react-router-dom";
import { FIRM_BUSINESS_OBJECT_ENUM } from "../../dataProvider/resources/document";
import CustomBreadCrumb from "../CustomBreadCumb";
import {
  CustomDocumentFilterToolbar,
  DocumentToolbarFilters,
} from "../document/CustomDocumentFilterToolbar";
import { DocumentEditableDataGrid } from "../document/EditableDataGrid";

type Record = NonNullable<client.GetOneFirmQuery["firm"]>;

const Title = () => {
  const record = useRecordContext<Record>();
  return (
    <span data-id="firm-documents-title">
      Firm Documents - {record ? `${record.name}` : ""}{" "}
      <Button
        label="Create Documents"
        component={Link}
        variant="contained"
        data-cy="createDocumentsMulti"
        to={{
          pathname: `/documentMulti/create`,
          search: `?source=${JSON.stringify({
            businessObjectEnumId: FIRM_BUSINESS_OBJECT_ENUM,
            ...(record && {
              firm: { id: record.id, name: encodeURIComponent(record.name) },
            }),
          })}`,
        }}
      />
    </span>
  );
};

const FirmDocumentDataGrid = ({ name }: { name: string }) => {
  const record = useRecordContext<Record>();
  if (!record) return;

  const [filters, setFilters] = useState<DocumentToolbarFilters>({
    isApproved: null,
    documentTypeEnumId: null,
    accessLevel: null,
    minDate: null,
  });

  return (
    <ReferenceManyField
      resource={name}
      label="Firm Documents"
      reference="document"
      target="firmId"
      perPage={10}
      pagination={<Pagination />}
      sort={{ field: "date", order: "DESC" }}
      filter={filters}
    >
      <CustomDocumentFilterToolbar filters={filters} setFilters={setFilters} />
      <DocumentEditableDataGrid
        referenceKey="firm_documents"
        noDelete={false}
        bulkActionButtons={false}
        defaultRowValues={{
          businessObjectEnumId: FIRM_BUSINESS_OBJECT_ENUM,
          firmId: record.id,
        }}
        entityOwner={{ firmId: record.id }}
      />
    </ReferenceManyField>
  );
};

export const FirmDocumentShow = () => {
  const { name } = useResourceDefinition();

  return (
    <Edit
      title={<Title />}
      actions={
        <CustomBreadCrumb<Record>
          name="firmDocument"
          items={[
            {
              path: "firm",
              getId: record => record?.id,
              getName: record => record?.name,
            },
          ]}
        />
      }
    >
      <FirmDocumentDataGrid name={name} />
    </Edit>
  );
};
