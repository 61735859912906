import * as client from "_graphql-types";

import {
  AutocompleteInput,
  BooleanInput,
  DateInput,
  ReferenceInput,
  TextInput,
  useRecordContext,
} from "react-admin";
import { CustomCreate } from "../CustomCreate";

import { Button } from "react-admin";
import Grid from "@mui/material/Grid";
import { Link } from "react-router-dom";
import { PersonInput } from "./PersonInput";
import { FaceOfEntityTooltip } from "../FaceOfEntityTooltip";
import { EntityInput } from "../UI/EntityInput";

type Record = NonNullable<client.GetOneFirmEmploymentQuery["firmEmployment"]>;

const ViewFirmButton = () => {
  const record = useRecordContext<Record>();
  if (record && record.firm) {
    if (record.firm.isCompany) {
      return (
        <Button
          component={Link}
          to={{
            pathname: `/company/${record.firm.companyId}`,
          }}
          label="Go to Company"
        />
      );
    }
    return (
      <Button
        component={Link}
        to={{
          pathname: `/firm/${record.firm.id}`,
        }}
        label="Go to Firm"
      />
    );
  }
  return null;
};

const ViewPersonButton = () => {
  const record = useRecordContext<Record>();
  return !record?.person?.id ? null : (
    <Button
      component={Link}
      to={{
        pathname: `/person/${record.person.id}`,
      }}
      label="Go to Person"
    />
  );
};

export function FaceOfEntity() {
  const record = useRecordContext<Record>();

  if (record?.firm?.isCompany) {
    return (
      <Grid item xs={3}>
        <FaceOfEntityTooltip entity="company">
          <BooleanInput label="Face of Company" source="faceOfFirm" />
        </FaceOfEntityTooltip>
      </Grid>
    );
  }
  return null;
}

export const FirmEmploymentCreate = () => (
  <CustomCreate title={() => "Create Firm Employment"}>
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <ViewFirmButton />
      </Grid>
      <Grid item xs={6}>
        <ViewPersonButton />
      </Grid>
      <Grid item xs={6}>
        <EntityInput<NonNullable<Record>>
          resource="firm"
          recordKey="firm"
          label="Firm"
          isRequired={true}
          filters={{ includeCompanies: true }}
          allowChange
          sort={{ field: "nameSearchRank", order: "ASC" }}
        />
      </Grid>
      <Grid item xs={6}>
        <PersonInput isRequired={true} />
      </Grid>
      <Grid item xs={3}>
        <ReferenceInput
          source="employeeRoleEnumId"
          reference="employeeRoleEnum"
          sort={{ field: "name", order: "ASC" }}
        >
          <AutocompleteInput optionText="name" label="Employee Role" />
        </ReferenceInput>
      </Grid>
      <Grid item xs={3}>
        <DateInput label="Start Date" source="startDate" />
      </Grid>
      <Grid item xs={3}>
        <ReferenceInput
          source="contactTypeEnumId"
          reference="contactTypeEnum"
          sort={{ field: "name", order: "ASC" }}
        >
          <AutocompleteInput optionText="name" label="Contact Type" />
        </ReferenceInput>
      </Grid>
      <Grid item xs={3}>
        <ReferenceInput
          source="auditContactTypeEnumId"
          reference="auditContactTypeEnum"
          sort={{ field: "name", order: "ASC" }}
        >
          <AutocompleteInput optionText="name" label="Audit Contact Type" />
        </ReferenceInput>
      </Grid>
      <Grid item xs={3}>
        <BooleanInput label="Is Founder?" source="isFounder" />
      </Grid>
      <Grid item xs={3}>
        <BooleanInput label="Is Key Personnel?" source="isKeyPersonnel" />
      </Grid>
      <Grid item xs={3}>
        <BooleanInput label="Is Data Contact?" source="dataContact" />
      </Grid>
      <Grid item xs={3}>
        <BooleanInput
          label="Is Data Entry Contact?"
          source="isDataEntryContact"
        />
      </Grid>
      <FaceOfEntity />
      <Grid item xs={3}>
        <ReferenceInput
          source="focusStrategyEnumId"
          reference="strategyEnum"
          sort={{ field: "strategy", order: "ASC" }}
        >
          <AutocompleteInput optionText="name" label="Focus Strategy" />
        </ReferenceInput>
      </Grid>
      <Grid item xs={3}>
        <BooleanInput label="Is Admin Contact?" source="isAdminContact" />
      </Grid>
      <Grid item xs={3}>
        <BooleanInput label="Authorized to Trade?" source="authToTrade" />
      </Grid>
      <Grid item xs={3}>
        <BooleanInput label="Has Outside Afilliations?" source="outsideAffil" />
      </Grid>
      <Grid item xs={12}>
        <TextInput
          label="Job Description"
          multiline
          rows={5}
          source="jobDesc"
        />
      </Grid>
      <Grid item xs={12}>
        <TextInput label="Comments" multiline rows={5} source="comments" />
      </Grid>
      <Grid item xs={12}>
        <hr />
        <h3>Firm Employee Departure</h3>
      </Grid>
      <Grid item xs={3}>
        <BooleanInput label="Employee Departed?" source="leftInd" />
      </Grid>
      <Grid item xs={3}>
        <DateInput label="Departure Date" source="leftDate" />
      </Grid>
      <Grid item xs={6}>
        <TextInput
          label="Departure Reason"
          multiline
          rows={5}
          source="leftReason"
        />
      </Grid>
    </Grid>
  </CustomCreate>
);
