import {
  Filter,
  ReferenceInput,
  SelectInput,
  NullableBooleanInput,
} from "react-admin";

import * as client from "_graphql-types";
import React from "react";

import { EntityInput } from "../UI/EntityInput";

type Record = client.GetOneFirmDeiQuery["firmDEI"];

export const FirmDeiFilter = (props: any) => (
  <div style={{ marginTop: "20px" }}>
    <Filter {...props} style={{ alignItems: "start" }}>
      <ReferenceInput
        label="Firm"
        source="firmId"
        reference="firm"
        alwaysOn={true}
      >
        <EntityInput<NonNullable<Record>>
          resource="firm"
          recordKey="firm"
          label="Firm"
          style={{ minWidth: "300px" }}
          allowChange
        />
      </ReferenceInput>

      <ReferenceInput
        reference="geographyEnum"
        sort={{ field: "name", order: "ASC" }}
        perPage={1000}
        source="geographyEnumId"
        alwaysOn
      >
        <SelectInput
          optionText="description"
          helperText="Geography of Team Members Indicated (Offices)"
          label="Geography"
        />
      </ReferenceInput>

      <NullableBooleanInput
        label="Most Recent"
        source="mostRecentMeasure"
        alwaysOn
      />
    </Filter>
  </div>
);
