import * as client from "_graphql-types";

import {
  Button,
  NumberInput,
  RadioButtonGroupInput,
  ReferenceInput,
  SelectInput,
} from "react-admin";
import { CustomEdit } from "../CustomEdit";

import Grid from "@mui/material/Grid";
import { Link } from "react-router-dom";
import { SchoolEntry } from "./Create";

type Record = NonNullable<
  client.GetOnePersonEducationQuery["personEducation"]
> & {
  schoolAction: "create" | "select";
};

function validateYear(value: any) {
  if (value) {
    value = Number(value);
    if (!(Number.isInteger(value) && 1900 < value && value < 3000)) {
      return "Invalid year";
    }
  }
}

const ViewPersonButton = (props: any) => {
  const {
    record,
  }: {
    record: Record;
  } = props;
  return !record?.person?.id ? null : (
    <Button
      component={Link}
      to={{
        pathname: `/person/${record.person.id}`,
      }}
      label="Go to Person"
    />
  );
};

export const PersonEducationEdit = () => {
  return (
    <CustomEdit<Record>
      title={record =>
        record?.person &&
        `Education for ${record.person.lastName || ""}, ${
          record.person.firstName || ""
        } ${record.person.middleName || ""}`
      }
      customFormProps={{
        customToolbarProps: {
          allowDelete: true,
          deleteButtonRedirect: record => `/person/${record.person.id}`,
        },
        simpleFormProps: {
          defaultValues: { schoolAction: "select" },
        },
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <ViewPersonButton />
        </Grid>
        <Grid item xs={6}></Grid>
        <Grid item xs={12}>
          <RadioButtonGroupInput
            label=""
            source="schoolAction"
            defaultValue="select"
            choices={[
              { id: "select", name: "Select existing school" },
              { id: "create", name: "Add new school" },
            ]}
            row={true}
          />
        </Grid>
        <Grid item xs={9}>
          <SchoolEntry />
        </Grid>
        <Grid item xs={3}></Grid>
        <Grid item xs={6}>
          {/* school$, degree$, graduation year */}
          <ReferenceInput
            reference="degreeEnum"
            sort={{ field: "name", order: "ASC" }}
            source="degreeEnumId"
            perPage={999}
          >
            <SelectInput optionText="name" label="Degree" />
          </ReferenceInput>
        </Grid>
        <Grid item xs={3}>
          <NumberInput
            label="Graduation Year"
            source="graduationYear"
            validate={validateYear}
          />
        </Grid>
        <Grid item xs={3}></Grid>
        <Grid item xs={3}></Grid>
      </Grid>
    </CustomEdit>
  );
};
