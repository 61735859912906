import * as client from "_graphql-types";
import * as types from "./types";

type Sdk = ReturnType<typeof client.getSdk>;

/*********************************************************************/
// Data Provider Functions
/*********************************************************************/

/*********************************************************************/
async function getList(
  sdk: Sdk,
  params: types.GetListParams<client.ServiceProviderNotesSortEnum>
) {
  const {
    pagination: { page, perPage },
    sort: { field, order },
    filter,
  } = params;

  const {
    serviceProviderNotesList: { items: data, total },
  } = await sdk.getListServiceProviderNotes({
    page: {
      offset: (page - 1) * perPage,
      limit: perPage,
    },
    sort: [{ field, order }],
    filter,
  });

  return {
    data,
    total,
  };
}

/*********************************************************************/
async function getOne(sdk: Sdk, params: types.GetOneParams) {
  const id = params.id;
  const { serviceProviderNotes: data } = await sdk.getOneServiceProviderNotes({
    id: Number(id),
  });
  return {
    data,
  };
}

/*********************************************************************/
async function getMany(sdk: Sdk, params: types.GetManyParams) {
  const ids = params.ids;

  const { serviceProviderNotesMany: data } =
    await sdk.getManyServiceProviderNotes({
      ids: ids.map(Number),
    });

  return {
    data,
  };
}

/*********************************************************************/
async function getManyReference(
  sdk: Sdk,
  params: types.GetManyReferenceParams<client.ServiceProviderNotesSortEnum>
) {
  const id = params.id;
  const {
    pagination: { page, perPage },
    sort: { field, order },
    filter,
    target,
  } = params;

  const {
    serviceProviderNotesList: { items: data, total },
  } = await sdk.getListServiceProviderNotes({
    page: {
      offset: (page - 1) * perPage,
      limit: perPage,
    },
    sort: [{ field, order }],
    filter: { ...filter, [target]: id },
  });

  return {
    data,
    total,
  };
}

/*********************************************************************/
async function create(
  sdk: Sdk,
  params: types.CreateParams<
    NonNullable<client.GetOneServiceProviderNotesQuery["serviceProviderNotes"]>
  >
) {
  const { data } = params;

  const input: client.CreateServiceProviderNotesMutationVariables["input"] = {
    noteText: data.noteText,
    eventDate: data.eventDate,
    providerNoteTypeEnumId: data.providerNoteTypeEnumId,
    providerId: Number(data?.providerId),
  };

  const {
    addServiceProviderNotes: { id },
  } = await sdk.createServiceProviderNotes({
    input,
  });
  return await getOne(sdk, { id: String(id)! });
}

/*********************************************************************/
async function update(
  sdk: Sdk,
  params: types.UpdateParams<
    client.UpdateServiceProviderNotesMutationVariables["input"]
  >
) {
  const id = params.id;
  const { data } = params;

  const input: client.UpdateServiceProviderNotesMutationVariables["input"] = {
    noteText: data.noteText,
    eventDate: data.eventDate,
    providerNoteTypeEnumId: data.providerNoteTypeEnumId,
    providerId: data.providerId,
  };

  await sdk.updateServiceProviderNotes({
    id: Number(id),
    input,
  });
  return await getOne(sdk, { id: String(id) });
}

/*********************************************************************/
export const serviceProviderNotes = types.dataProvider({
  getList,
  getOne,
  getMany,
  getManyReference,
  create,
  update,
});
