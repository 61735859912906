import * as client from "_graphql-types";
import * as types from "./types";
import { EnsureAllKeys } from "../helpers/types";
type Sdk = ReturnType<typeof client.getSdk>;

/*********************************************************************/
// Data Provider Functions
/*********************************************************************/

/*********************************************************************/
async function getList(
  sdk: Sdk,
  params: types.GetListParams<client.UsersXGroupsSortEnum>
) {
  const {
    pagination: { page, perPage },
    sort: { field, order },
    filter,
  } = params;

  const {
    usersXGroupsList: { items: data, total },
  } = await sdk.getListUsersXGroups({
    page: {
      offset: (page - 1) * perPage,
      limit: perPage,
    },
    sort: [{ field, order }],
    filter,
  });

  return {
    data,
    total,
  };
}

/*********************************************************************/
async function getOne(sdk: Sdk, params: types.GetOneParams) {
  const id = params.id as string;
  const { usersXGroups: data } = await sdk.getOneUsersXGroups({
    id,
  });
  return {
    data,
  };
}

/*********************************************************************/
async function getMany(sdk: Sdk, params: types.GetManyParams) {
  const ids = params.ids as string[];

  const { usersXGroupsMany: data } = await sdk.getManyUsersXGroups({
    ids,
  });

  return {
    data,
  };
}

/*********************************************************************/
async function getManyReference(
  sdk: Sdk,
  params: types.GetManyReferenceParams<client.UsersXGroupsSortEnum>
) {
  const id = params.id;
  const {
    pagination: { page, perPage },
    sort: { field, order },
    filter,
    target,
  } = params;

  const {
    usersXGroupsList: { items: data, total },
  } = await sdk.getListUsersXGroups({
    page: {
      offset: (page - 1) * perPage,
      limit: perPage,
    },
    sort: [{ field, order }],
    filter: { ...filter, [target]: id },
  });

  return {
    data,
    total,
  };
}

/*********************************************************************/
async function create(
  sdk: Sdk,
  params: types.CreateParams<
    NonNullable<client.GetOneUsersXGroupsQuery["usersXGroups"]>
  >
) {
  const { data } = params;
  const input: EnsureAllKeys<
    client.CreateUsersXGroupsMutationVariables["input"]
  > = {
    userId: data.userId,
    groupId: data.groupId,
  };

  const {
    addUsersXGroups: { id },
  } = await sdk.createUsersXGroups({
    input,
  });
  return await getOne(sdk, { id: id! });
}

/*********************************************************************/
async function update(
  sdk: Sdk,
  params: types.UpdateParams<
    NonNullable<client.GetOneUsersXGroupsQuery["usersXGroups"]>
  >
) {
  const id = params.id as string;
  const { data } = params;

  const input: EnsureAllKeys<
    client.UpdateUsersXGroupsMutationVariables["input"]
  > = {
    userId: data.userId,
    groupId: data.groupId,
  };

  await sdk.updateUsersXGroups({
    id,
    input,
  });
  return await getOne(sdk, { id });
}

async function deleteOne(sdk: Sdk, params: types.DeleteParams) {
  const id = String(params.id);
  await sdk.deleteUsersXGroups({
    id,
  });
  return { data: { id } };
}

/*********************************************************************/
export const usersXGroups = types.dataProvider({
  getList,
  getOne,
  getMany,
  getManyReference,
  delete: deleteOne,
  create,
  update,
});
