import Highcharts from "highcharts";
import HighChartsReact from "highcharts-react-official";
import HighchartsData from "highcharts/modules/data";
import HighchartsExport from "highcharts/modules/exporting";
import HighChartsErrors from "highcharts/es-modules/Extensions/Debugger/ErrorMessages";
import { get } from "lodash";
import { useNotify } from "react-admin";
HighchartsData(Highcharts);
HighchartsExport(Highcharts);

const errorMessages: {
  [key: string]: { title: string; text: string; enduser?: string };
} = HighChartsErrors;

function CustomHighCharts({ chartOptions }: { chartOptions: any }) {
  console.log("OPTIONS: ", chartOptions);
  console.log("Error Messages", errorMessages);
  const notify = useNotify();

  Highcharts.addEvent(Highcharts.Chart, "displayError", function (err: any) {
    if (!Highcharts.charts || !Highcharts.charts[0] || !err) return;

    const fullError = get(errorMessages, err.code);

    notify(fullError?.title, { type: "error" });
  });

  return (
    <HighChartsReact options={chartOptions} highcharts={Highcharts} immutable />
  );
}

export default CustomHighCharts;
