import * as client from "_graphql-types";
import * as types from "./types";

import { filterSortPage } from "../DataProvider";

type Sdk = ReturnType<typeof client.getSdk>;

/*********************************************************************/
// Data Provider Functions
/*********************************************************************/

/*********************************************************************/
async function getList(
  sdk: Sdk,
  params: types.GetListParams<client.PeStrategyEnumSortEnum>
) {
  const { pagination, sort } = params;

  const {
    peStrategyEnumList: { items: data, total },
  } = await sdk.getListPEStrategyEnum(filterSortPage({ sort, pagination }));

  return {
    data,
    total,
  };
}

/*********************************************************************/
async function getOne(sdk: Sdk, params: types.GetOneParams) {
  const id = Number(params.id);
  const { peStrategyEnum: data } = await sdk.getOnePEStrategyEnum({
    id,
  });
  return {
    data,
  };
}

/*********************************************************************/
async function getMany(sdk: Sdk, params: types.GetManyParams) {
  const ids = params.ids.map(id => Number(id));

  const { peStrategyEnumMany: data } = await sdk.getManyPEStrategyEnum({
    ids,
  });

  return {
    data,
  };
}

/*********************************************************************/
export const peStrategyEnum = types.dataProvider({
  getList,
  getOne,
  getMany,
  ttl: types.defaultEnumTtl,
});
