import * as client from "_graphql-types";

import {
  DateInput,
  ReferenceInput,
  TextInput,
  SelectInput,
  SimpleFormIterator,
  ArrayInput,
  FormDataConsumer,
  AutocompleteInput,
} from "react-admin";
import Grid from "@mui/material/Grid";
import { CustomEdit } from "../CustomEdit";
import { withScopedForm } from "../react-admin-fixes";

type Record = client.GetOneLinkQuery["links"];

export const OrgLinksInput = () => {
  return (
    <ArrayInput source="orgLinks" label="">
      <SimpleFormIterator
        fullWidth
        disableReordering
        disableClear
        sx={{
          "& .RaSimpleFormIterator-line": {
            borderBottom: "none",
          },
        }}
      >
        <FormDataConsumer>
          {withScopedForm<NonNullable<Record>["orgLinks"][number]>(
            ({ scopedFormData }) => (
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <ReferenceInput
                    reference="organization"
                    sort={{ field: "name", order: "ASC" }}
                    perPage={1000}
                    record={scopedFormData}
                    source="organizationId"
                  >
                    <AutocompleteInput optionText="name" label="Organization" />
                  </ReferenceInput>
                </Grid>
                <Grid item xs={6}>
                  <DateInput label="Expiration Date" source="expirationDate" />
                </Grid>
              </Grid>
            )
          )}
        </FormDataConsumer>
      </SimpleFormIterator>
    </ArrayInput>
  );
};

export const LinksEdit = () => (
  <CustomEdit<NonNullable<Record>> title={() => "Edit OrgLinks"}>
    <>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextInput label="Name" source="name" />
        </Grid>
        <Grid item xs={6}>
          <TextInput label="URL" source="url" />
        </Grid>
        <Grid item xs={12}>
          <ReferenceInput
            source="iconEnumId"
            reference="iconEnum"
            sort={{ field: "name", order: "ASC" }}
          >
            <SelectInput fullWidth={true} optionText="name" label="Icon" />
          </ReferenceInput>
        </Grid>
        <Grid item xs={12}>
          <hr />
          <h3>Organizations</h3>
        </Grid>
        <Grid item xs={12}>
          <OrgLinksInput />
        </Grid>
      </Grid>
    </>
  </CustomEdit>
);
