import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";

export interface PreviewProps {
  geReturnDate: string | null | undefined;
  leReturnDate: string | null | undefined;
  perfClean:
    | {
        years: number[] /* contiguous range, sorted desc */;
        perfs: Map<
          string /* String([year,month]) */,
          number | null | undefined /* return */
        >;
      }
    | undefined;
}

export const Preview = ({
  geReturnDate,
  leReturnDate,
  perfClean,
}: PreviewProps) => (
  <Card
    sx={{
      width: "100%",
      backgroundColor: "rgb(245,245,245)",
      marginBottom: "16px",
    }}
    data-cy="preview"
  >
    <CardHeader
      title="Custom Index Preview"
      subheader={
        <>
          Start {geReturnDate ?? "(none)"} &mdash; End{" "}
          {leReturnDate ?? "(none)"}
        </>
      }
    />
    <CardContent>
      <Grid container>
        <Grid item xs={12}>
          <Table size="small" padding="none">
            <TableHead>
              <TableRow>
                {[
                  "Year",
                  "Jan",
                  "Feb",
                  "Mar",
                  "Apr",
                  "May",
                  "Jun",
                  "Jul",
                  "Aug",
                  "Sep",
                  "Oct",
                  "Nov",
                  "Dec",
                ].map((x, i) => (
                  <TableCell key={x} align={i === 0 ? "left" : "right"}>
                    {x}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {!perfClean ? (
                <TableRow data-cy="no-data">
                  <TableCell colSpan={13} align="center">
                    No data to display
                  </TableCell>
                </TableRow>
              ) : (
                perfClean.years.map(year =>
                  // omit the intervening years
                  perfClean.years.length <= 11 ||
                  perfClean.years[0] - year < 5 ||
                  year - perfClean.years.at(-1)! < 5 ? (
                    <TableRow key={year} data-cy={`year-${year}`}>
                      {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(icol => {
                        const nvalue =
                          icol === 0
                            ? year
                            : perfClean.perfs.get(String([year, icol]));
                        return (
                          <TableCell
                            align={icol === 0 ? "left" : "right"}
                            style={{
                              color: icol && (nvalue ?? 0) < 0 ? "red" : void 0,
                            }}
                            key={icol}
                            data-cy={`col-${icol}`}
                          >
                            {icol === 0 ? (
                              nvalue
                            ) : typeof nvalue === "number" ? (
                              nvalue.toFixed(2)
                            ) : (
                              <>&ndash;</>
                            )}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  ) : perfClean.years[0] - year === 5 ? (
                    <TableRow data-cy="ellipsis">
                      <TableCell colSpan={13} align="center">
                        &middot;&nbsp;&middot;&nbsp;&middot;
                      </TableCell>
                    </TableRow>
                  ) : null
                )
              )}
            </TableBody>
          </Table>
        </Grid>
      </Grid>
    </CardContent>
  </Card>
);
