import { Button, RaRecord, useRecordContext } from "react-admin";
import Queue from "@mui/icons-material/Queue";
import { Link } from "react-router-dom";
import { stringify } from "querystring";
import { omit } from "lodash";

const CloneButtonWithOmmit = <TRecord extends RaRecord>({
  keysToOmit = ["id"],
  pathname,
  dataCy = "clone-button",
}: {
  keysToOmit: (keyof TRecord)[];
  pathname: string;
  dataCy?: string;
}) => {
  const record = useRecordContext<TRecord>();
  return (
    <Button
      data-cy={dataCy}
      component={Link}
      label="ra.action.clone"
      onClick={e => e.stopPropagation()}
      to={
        record
          ? {
              pathname,
              search: stringify({
                source: JSON.stringify(omit(record, keysToOmit)),
              }),
            }
          : {
              pathname,
            }
      }
    >
      <Queue />
    </Button>
  );
};

export default CloneButtonWithOmmit;
