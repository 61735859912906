import * as client from "_graphql-types";
import * as types from "./types";

import { filterSortPage } from "../DataProvider";

type Sdk = ReturnType<typeof client.getSdk>;

/*********************************************************************/
// Data Provider Functions
/*********************************************************************/

/*********************************************************************/
async function getList(
  sdk: Sdk,
  params: types.GetListParams<client.SubStrategyEnumSortEnum>
) {
  const { sort, filter } = params;

  const {
    subStrategyEnumList: { items: data, total },
  } = await sdk.getListSubStrategyEnum(filterSortPage({ filter, sort }));

  return {
    data,
    total,
  };
}

/*********************************************************************/
async function getOne(sdk: Sdk, params: types.GetOneParams) {
  const id = Number(params.id);
  const { subStrategyEnum: data } = await sdk.getOneSubStrategyEnum({
    id,
  });
  return {
    data,
  };
}

/*********************************************************************/
async function getMany(sdk: Sdk, params: types.GetManyParams) {
  const ids = params.ids.map(id => Number(id));

  const { subStrategyEnumMany: data } = await sdk.getManySubStrategyEnum({
    ids,
  });

  return {
    data,
  };
}

/*********************************************************************/
export const subStrategyEnum = types.dataProvider({
  getList,
  getOne,
  getMany,
  ttl: types.defaultEnumTtl,
});
