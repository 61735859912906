import * as client from "_graphql-types";
import * as types from "./types";

type Sdk = ReturnType<typeof client.getSdk>;

const getQuarterForDate = (date: Date) =>
  new Date(
    Date.UTC(date.getUTCFullYear(), Math.floor(date.getUTCMonth() / 3) * 3, 1)
  );

const getQuartersFromRange = (
  start: Date,
  end: Date,
  dataQuarters = [] as string[]
) => {
  const quarters: string[] = [];
  for (
    let date = start;
    date < end;
    date = new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth() + 3, 1))
  ) {
    const isoDate = date.toISOString();
    if (!dataQuarters.includes(isoDate)) {
      quarters.push(isoDate);
    }
  }
  return quarters;
};

/*********************************************************************/
// Data Provider Functions
/*********************************************************************/

/*********************************************************************/

async function getOne(sdk: Sdk, params: { id: any }) {
  const id = Number(params.id);
  const { investment: data } = await sdk.getOneInvestmentAum({
    investmentId: id,
  });

  if (!data.assets) return { data: undefined };
  let startingQuarter;
  if (data?.inceptionDate) {
    const firstDateQuarter =
      data.assets.slice(-1)[0]?.asOfDate &&
      getQuarterForDate(new Date(data.assets.slice(-1)[0]?.asOfDate));
    const inceptionQuarter = getQuarterForDate(new Date(data.inceptionDate));
    startingQuarter = inceptionQuarter;

    if (firstDateQuarter && firstDateQuarter < inceptionQuarter)
      startingQuarter = firstDateQuarter;
  } else {
    startingQuarter = new Date(Date.UTC(1995, 1 - 1, 1));
  }

  const utcDate = new Date(
    Date.UTC(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDay()
    )
  );
  const currentQuarter = getQuarterForDate(utcDate);

  const assets = [
    ...data.assets,
    ...getQuartersFromRange(
      startingQuarter,
      currentQuarter,
      data.assets.map(({ asOfDate }) => asOfDate)
    ).map(asOfDate => ({ asOfDate })),
  ].sort(
    (a, b) => new Date(b.asOfDate).getTime() - new Date(a.asOfDate).getTime()
  );

  return {
    data: {
      ...data,
      assets,
    },
  };
}

async function update(
  sdk: Sdk,
  params: types.UpdateParams<{
    assets: (client.AumEntry & { flow?: client.AumFlow })[];
  }>
) {
  const investmentId = Number(params.id);
  const oldQuarters = new Map(
    params.previousData!.assets.map(asset => [asset.asOfDate, asset])
  );
  const entries: client.AumEntry[] = [];
  for (const newQuarter of params.data.assets ?? []) {
    const { investmentAUM, strategyAUM, firmAUM, asOfDate } = newQuarter;
    const entry: client.AumEntry = { asOfDate };
    entry.investmentAUM = investmentAUM ? Number(investmentAUM) : null;
    entry.strategyAUM = strategyAUM ? Number(strategyAUM) : null;
    entry.firmAUM = firmAUM ? Number(firmAUM) : null;

    const oldQuarter = oldQuarters.get(newQuarter.asOfDate);
    if (
      oldQuarter?.investmentAUM !== entry.investmentAUM ||
      oldQuarter?.strategyAUM !== entry.strategyAUM ||
      oldQuarter?.firmAUM !== entry.firmAUM
    ) {
      entries.push(entry);
    }
  }
  if (entries.length) {
    await sdk.bulkUpdateAUM({ investmentId, entries });
  } else {
    throw Error("No changed Records");
  }
  return await getOne(sdk, { id: investmentId });
}

export const aum = types.dataProvider({
  getOne,
  update,
});
