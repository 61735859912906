import React from "react";
import { useLocation } from "react-router-dom";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Link } from "react-router-dom";
import Toolbar from "@mui/material/Toolbar";
import { usePermissions } from "react-admin";
import { Permissions } from "./CustomAdmin";

interface LinkTabProps {
  label?: string;
  to: string;
  value: number;
}

type RouteInfo = {
  route: string;
  label: string;
  flag?: keyof Permissions["flags"];
  view?: "show" | "edit";
};

function LinkTab(props: LinkTabProps) {
  return <Tab component={Link} {...props} />;
}

export const INVESTMENT_ROUTES: RouteInfo[] = [
  { route: "investment", label: "General" },
  { route: "investmentTerms", label: "Terms" },
  { route: "investmentPeers", label: "Peers" },
  { route: "investmentDocument", label: "Documents" },
  { route: "performance", label: "Monthly Performance" },
  { route: "performancePrivate", label: "IRR Performance" },
  { route: "aum", label: "AUM" },
  {
    route: "investmentCustomAnalytics",
    label: "Custom Analytics",
    flag: "isRockCreek",
  },
  {
    route: "dataLinks",
    label: "Data Links",
    flag: "isRockCreek",
  },
  {
    route: "investmentServiceProviderLinks",
    view: "show",
    label: "Provider Links",
    flag: "isServiceProviderManager",
  },
];

export const FIRM_ROUTES: RouteInfo[] = [
  { route: "firm", label: "General" },
  { route: "firmDocument", label: "Documents" },
  { route: "firmFirmDEI", label: "Firm DEI" },
  {
    route: "firmServiceProviderLinks",
    view: "show",
    label: "Provider Links",
    flag: "isServiceProviderManager",
  },
];

const ROUTE_CONTEXTS = {
  firm: FIRM_ROUTES,
  investment: INVESTMENT_ROUTES,
};

export function useCustomRouteContext(): {
  entityId: string | null;
  entityContext: keyof typeof ROUTE_CONTEXTS | null;
  contextRoutes: RouteInfo[];
  currentRoute: string;
  hasMatchingContext: boolean;
} {
  const location = useLocation();
  const [_blank, currentRoute, entityId] = location.pathname.split("/");

  const match = Object.entries(ROUTE_CONTEXTS).find(
    ([entityContext, routes]) => {
      return routes.find(routeInfo => {
        return routeInfo.route === currentRoute;
      });
    }
  );

  if (!match) {
    return {
      hasMatchingContext: false,
      entityContext: null,
      entityId: null,
      currentRoute: currentRoute,
      contextRoutes: [],
    };
  }

  const [entityContext, contextRoutes] = match;

  return {
    hasMatchingContext: !!entityId && !!match,
    entityContext: entityContext as keyof typeof ROUTE_CONTEXTS,
    entityId,
    currentRoute,
    contextRoutes,
  };
}

export function CustomNavigation() {
  const { permissions } = usePermissions<Permissions>();

  const {
    hasMatchingContext,
    entityContext,
    entityId,
    contextRoutes,
    currentRoute,
  } = useCustomRouteContext();

  if (!hasMatchingContext) return null;

  return (
    <>
      <Toolbar style={{ minHeight: "48px" }}>
        <Tabs
          value={currentRoute}
          aria-label={`${entityContext} form navigation`}
          textColor="inherit"
          sx={{
            "& .MuiTabs-flexContainer": {
              flexWrap: "wrap",
            },
          }}
        >
          {contextRoutes.map(({ route, label, flag, view }, index) => {
            if (flag && !permissions?.flags[flag]) return null;
            return (
              <LinkTab
                key={route}
                label={label}
                to={`/${route}/${entityId}${view ? `/${view}` : ""}`}
                value={route as any} // this is recommended to be a number
              />
            );
          })}
        </Tabs>
      </Toolbar>
    </>
  );
}
