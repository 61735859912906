import * as types from "_graphql-types";
import { Permissions } from "../CustomAdmin";

export function isAuthorized({
  permissions,
  checks,
}: {
  permissions: Permissions;
  checks: Array<keyof types.GetOneCurrentUserQuery["currentUser"]["flags"]>;
}) {
  return checks.every(check => permissions.flags[check]);
}
