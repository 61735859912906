import {
  Datagrid,
  EditButton,
  FunctionField,
  List,
  TextField,
  useResourceDefinition,
} from "react-admin";
import CustomDeleteButton from "../CustomDeleteButton";
import CustomFlag from "../CustomFlag";
import { PortalSubmittedFilter } from "./Filter";
import { ListActions } from "./ListActions";

export const PortalSubmittedList = () => {
  const { name } = useResourceDefinition();
  return (
    <List
      sx={{
        ".RaFilter-form": {
          flex: "0 1 50%",
        },
      }}
      filters={<PortalSubmittedFilter />}
      sort={{ field: "nameSearchRank", order: "ASC" }}
      actions={<ListActions />}
      resource={name}
    >
      <Datagrid rowClick="edit" bulkActionButtons={false}>
        <TextField label="Name" source="name" />
        <TextField label="Firm" source="firm.name" />
        <FunctionField
          label="Deleted?"
          render={(investment: any) =>
            investment.deleteDate ? <CustomFlag label="Deleted" /> : null
          }
          sortable={false}
        />
        <EditButton />
        <CustomDeleteButton />
      </Datagrid>
    </List>
  );
};
