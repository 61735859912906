import {
  Datagrid,
  EditButton,
  List,
  TextField,
  TopToolbar,
  CreateButton,
  ExportButton,
  SortButton,
  FunctionField,
  BooleanField,
} from "react-admin";
import React from "react";
import { PortfolioConfigFilter } from "./Filter";
import { IRecord } from "./Form";

export const PortfolioConfigList = () => {
  return (
    <List
      sx={{
        ".RaFilter-form": {
          flex: "0 1 50%",
        },
      }}
      filters={<PortfolioConfigFilter />}
      sort={{ field: "nameSearchRank", order: "ASC" }}
      actions={
        <TopToolbar>
          <CreateButton />
          <ExportButton maxResults={250} />
        </TopToolbar>
      }
    >
      <Datagrid
        data-cy="portfolioSettings__dataGrid"
        rowClick="edit"
        bulkActionButtons={false}
      >
        <TextField label="Portfolio" source="portfolio.name" sortable={false} />
        <FunctionField
          label="Performance Lag"
          render={({ performanceLag }: IRecord) =>
            performanceLag
              ? `${performanceLag.lag} ${performanceLag.lagUnit.name}${
                  performanceLag.lag > 1 ? "s" : ""
                }`
              : undefined
          }
          sortable={false}
        />
        <BooleanField
          label="Show Investments"
          source="showInvestments"
          sortable={false}
        />
        <BooleanField
          label="Lookthrough Enabled"
          source="lookthroughEnabled"
          sortable={false}
        />
        <EditButton />
      </Datagrid>
    </List>
  );
};
