import { Grid, InputLabel } from "@mui/material";
import * as client from "_graphql-types";
import {
  ArrayInput,
  BooleanInput,
  Button,
  Datagrid,
  DeleteButton,
  EditButton,
  ReferenceField,
  ReferenceManyField,
  SimpleFormIterator,
  TextField,
  TextInput,
  useRecordContext,
} from "react-admin";
import { useWatch } from "react-hook-form";
import { Link } from "react-router-dom";
import { CustomEdit } from "../CustomEdit";
import { CustomAddButton, CustomRemoveButton } from "./CustomButton";

const PortalServiceProviderFirmInput = () => {
  const record = useRecordContext<Record>();
  if (!record) return;

  return (
    <>
      <Grid item xs={12}>
        <hr />
        <h3>Firms</h3>
      </Grid>
      <Grid item xs={12}>
        <ReferenceManyField
          label=""
          reference="portalServiceProviderFirms"
          target="organizationId"
          sort={{ field: "name", order: "ASC" }}
        >
          <Datagrid
            data-cy="portalServiceProviderFirms__datagrid"
            bulkActionButtons={false}
          >
            <TextField label="ID" source="id" />
            <TextField label="Name" source="firm.name" />
            <TextField label="Organization" source="firm.organization.name" />
            <DeleteButton
              label="Unlink Firm"
              data-cy="unlinkFirm__button"
              redirect={`/portalOrganization/${record.id}`}
              mutationMode="undoable"
            />
          </Datagrid>
        </ReferenceManyField>
      </Grid>
      <Grid id="addPortalServiceProviderFirm" item xs={12}>
        <AddPortalServiceProviderFirmToOrganizationButton />
      </Grid>
    </>
  );
};

type Record = NonNullable<
  client.GetOnePortalOrganizationQuery["portalOrganization"]
>;

const ViewUsersButton = () => {
  return (
    <Button
      component={Link}
      to={{
        pathname: "/portalUser",
      }}
      label="View All Users"
      title="View Users"
      data-id="viewUsers"
    />
  );
};

const AddPortalServiceProviderFirmToOrganizationButton = () => {
  const record = useRecordContext<Record>();

  return !record ? null : (
    <Button
      component={Link}
      to={{
        pathname: "/portalServiceProviderFirms/create",
        search: `?source=${JSON.stringify({
          organization: {
            id: record.id,
            name: record.name ? encodeURIComponent(record.name) : "",
          },
        })}`,
      }}
      label="Add Service Provider Firm"
      title="Add Service Provider Firm to Organization"
      data-id="createPortalServiceProviderFirm"
    />
  );
};

const EmailDomainInput = () => (
  <ArrayInput
    label=""
    source="emailDomainMappings"
    data-cy="emailDomainMappings__input"
  >
    <SimpleFormIterator
      addButton={<CustomAddButton customButtonText="Add New Domain" />}
      removeButton={<CustomRemoveButton />}
    >
      <TextInput
        label="Domain Name"
        source={"emailDomain"}
        data-cy="emailDomain__item"
      />
    </SimpleFormIterator>
  </ArrayInput>
);

function PortaOrganizationEditForm() {
  const record = useRecordContext<Record>();
  const [allowMultiFirm] = useWatch({ name: ["allowMultiFirm"] });

  return (
    <>
      <Grid data-cy="portalOrganization__edit" container spacing={2}>
        <Grid item xs={10}>
          <TextInput label="Organization Name" source="name" />
        </Grid>
        <Grid item xs={10}>
          <TextInput label="Primary Firm" source="primaryFirm.name" disabled />
        </Grid>
        <Grid item xs={10}>
          <BooleanInput label="Allow Multi-Firm" source="allowMultiFirm" />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item md={6}>
          <InputLabel>Email Domains</InputLabel>
          <EmailDomainInput />
        </Grid>
      </Grid>

      {allowMultiFirm || record?.allowMultiFirm ? (
        <PortalServiceProviderFirmInput />
      ) : null}

      <Grid item xs={12}>
        <hr />
        <h3>Users</h3>
      </Grid>
      <Grid item xs={12}>
        <ReferenceManyField
          label=""
          reference="portalUser"
          target="organizationId"
          sort={{ field: "email", order: "ASC" }}
        >
          <Datagrid
            rowClick="edit"
            data-cy="portalUser__datagrid"
            bulkActionButtons={false}
          >
            <TextField label="First Name" source="firstName" sortable={false} />
            <TextField label="Last Name" source="lastName" sortable={false} />
            <TextField label="Email" source="email" sortable={false} />
            <EditButton label="Edit" />
          </Datagrid>
        </ReferenceManyField>
      </Grid>
      <Grid item xs={12}>
        <ViewUsersButton />
      </Grid>

      <Grid item xs={12}>
        <hr />
        <h3>Internal Organization</h3>
      </Grid>
      <Grid item xs={12}>
        <ReferenceField
          label="Organization"
          reference="organization"
          source="organization.id"
          emptyText="Internal organization not synced yet."
        >
          <TextField label="Name" source="name" />
        </ReferenceField>
      </Grid>
    </>
  );
}

export function PortalOrganizationEdit() {
  return (
    <CustomEdit<Record>
      title={record => (record ? `Organization ${record?.name || ""}` : null)}
    >
      <PortaOrganizationEditForm />
    </CustomEdit>
  );
}
