import * as client from "_graphql-types";
import * as types from "./types";

import { filterSortPage } from "../DataProvider";

type Sdk = ReturnType<typeof client.getSdk>;

export type FirmDEIInput =
  client.AddOrUpdateFirmDeiMutationVariables["input"] & {
    firm: { id: number };
  }; // client.GetOneFirmDeiQuery["firmDEI"];

const inputParams = (
  data: Partial<FirmDEIInput>
): client.FirmDeiCreateOrUpdate | undefined => {
  if (!data) return;
  return {
    firmId: data.firm?.id ?? types.required(data, "firmId"),
    geographyEnumId: types.required(data, "geographyEnumId"),
    isCompleted: data.isCompleted,
    asOfDate: types.required(data, "asOfDate"),
    comments: data.comments,
    diversityStatsEnumId: Number(data?.diversityStatsEnumId),
    firmDEIRecords: data.firmDEIRecords?.map(record => ({
      deiCategory1EnumId: record.deiCategory1EnumId,
      deiCategory2EnumId: record.deiCategory2EnumId,
      measureEnumId: record.measureEnumId,
      value: record.value,
      firmDEIId: record.firmDEIId ?? data.id,
      id: record.id,
    })),
  };
};

/*********************************************************************/
// Data Provider Functions
/*********************************************************************/

/*********************************************************************/
async function getManyReference(
  sdk: Sdk,
  params: types.GetManyReferenceParams<client.FirmDeiSortEnum>
) {
  const id = Number(params.id);
  const {
    pagination: { page, perPage },
    sort: { field, order },
    filter,
    target,
  } = params;

  const {
    firmDEIList: { items: data, total },
  } = await sdk.getListFirmDEI({
    page: {
      offset: (page - 1) * perPage,
      limit: perPage,
    },
    sort: [{ field, order }],
    filter: { ...filter, [target]: id },
  });

  return {
    data,
    total,
  };
}

/*********************************************************************/
async function getList(
  sdk: Sdk,
  params: types.GetListParams<client.FirmDeiSortEnum>
) {
  const { pagination, sort, filter } = params;

  const { firm, ...restOfFilter } = filter || {};

  const constructedFilter = {
    ...restOfFilter,
    ...((firm?.id && {
      firmId: firm.id,
    }) ||
      {}),
  };
  const {
    firmDEIList: { items: data, total },
  } = await sdk.getListFirmDEI(
    filterSortPage({
      filter: constructedFilter,
      sort,
      nameField: client.FirmDeiSortEnum.IsCompleted,
      pagination,
    })
  );

  return {
    data,
    total,
  };
}

/*********************************************************************/
async function getOne(sdk: Sdk, params: types.GetOneParams) {
  const id = Number(params.id);
  const { firmDEI: data } = await sdk.getOneFirmDEI({
    id,
  });
  return {
    data,
  };
}

/*********************************************************************/
async function create(sdk: Sdk, params: types.CreateParams<FirmDEIInput>) {
  const { data } = params;

  const input = inputParams(data);

  if (!input) return { data: undefined };

  const {
    createOrUpdateFirmDEI: { id },
  } = await sdk.addOrUpdateFirmDEI({
    input,
  });

  return await getOne(sdk, { id: id! });
}

/*********************************************************************/
async function update(sdk: Sdk, params: types.UpdateParams<FirmDEIInput>) {
  const { data } = params;
  const input = inputParams(data);

  if (!input) return { data: undefined };

  const {
    createOrUpdateFirmDEI: { id },
  } = await sdk.addOrUpdateFirmDEI({
    input,
  });
  return await getOne(sdk, { id });
}

async function softDelete(sdk: Sdk, params: types.DeleteParams) {
  const id = String(params.id);

  await sdk.deleteFirmDei({ id });

  return {
    data: { id },
  };
}
/*********************************************************************/

/*********************************************************************/
export const firmDEI = types.dataProvider({
  getOne,
  getList,
  getManyReference,
  create,
  update,
  delete: softDelete,
});
