import {
  Button,
  CardActions,
  CardContent,
  CircularProgress,
  Card as MuiCard,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import * as client from "_graphql-types";
import { useMemo } from "react";
import {
  FilterList,
  FilterListItem,
  useGetList,
  useListContext,
} from "react-admin";

const Card = styled(MuiCard)(({ theme }) => ({
  [theme.breakpoints.up("sm")]: {
    order: -1, // display on the left rather than on the right of the list
    width: "30em",
    marginRight: "1em",
  },
  [theme.breakpoints.down("md")]: {
    display: "none",
  },
}));

export const DealFilterSidebar = (props: any) => {
  const listContext = useListContext();

  const { data, isLoading, error } = useGetList<
    client.DealFilterOptions & { id: string }
  >("dealFilterOptions", {
    filter: listContext.filterValues,
  });

  const listData = useMemo(() => {
    return data && data[0];
  }, [data, isLoading, error]);

  const headquarterOptions = useMemo(
    () => listData?.headQuarterOptions || [],
    [listData]
  );

  const rcSourceOptions = useMemo(
    () =>
      (listData?.rcSourceOptions || []).map(rcSourceOptions => {
        return { name: rcSourceOptions, id: rcSourceOptions };
      }),
    [listData]
  );
  const fundingTypeOptions = useMemo(
    () => listData?.fundingTypeOptions || [],
    [listData]
  );
  const dealStructureOptions = useMemo(
    () => listData?.dealStructureOptions || [],
    [listData]
  );
  const stageOptions = useMemo(() => listData?.stageOptions || [], [listData]);

  const dealMarketingOptions = useMemo(
    () => listData?.dealMarketingOptions || [],
    [listData]
  );
  const resultOptions = useMemo(
    () => listData?.resultOptions || [],
    [listData]
  );

  const assetClassOptions = useMemo(
    () => listData?.assetClassOptions || [],
    [listData]
  );

  return (
    <Card>
      <CardActions>
        <Button
          size="small"
          data-cy="sidebar-filter-reset"
          onClick={() => {
            listContext.setFilters(
              { isBusinessApplication: false, isActive: true },
              listContext.displayedFilters
            );
          }}
        >
          Reset Filters
        </Button>
      </CardActions>
      {isLoading && (
        <div>
          <CircularProgress />
        </div>
      )}
      <CardContent data-cy="sidebar-filter">
        <div data-cy="filter__isImpact">
          <FilterList label="Is Impact" icon={null}>
            <FilterListItem label="Yes" value={{ isImpact: true }} />
            <FilterListItem label="No" value={{ isImpact: false }} />
          </FilterList>
        </div>
        <div data-cy="filter__companyIsBusinessApplicant">
          <FilterList label="Company is Business Applicant" icon={null}>
            <FilterListItem
              label="Yes"
              value={{ isBusinessApplication: true }}
            />
            <FilterListItem
              label="No"
              value={{ isBusinessApplication: false }}
            />
          </FilterList>
        </div>
        <div data-cy="filter__stage">
          <FilterList label="Stage" icon={null}>
            {stageOptions &&
              stageOptions
                .sort((a, b) => b.displayOrder - a.displayOrder)
                .map(stage => (
                  <FilterListItem
                    label={stage.name}
                    key={stage.id}
                    value={{ stageEnumId: stage.id }}
                  />
                ))}
          </FilterList>
        </div>
        <div data-cy="filter__result">
          <FilterList label="Result" icon={null}>
            {resultOptions &&
              [...resultOptions, { name: "None", id: null }].map(result => (
                <FilterListItem
                  label={result.name}
                  key={result.id}
                  value={{
                    ...(result.id
                      ? { resultEnumId: result.id }
                      : { isActive: true }),
                  }}
                />
              ))}
          </FilterList>
        </div>
        <div data-cy="filter__fundingRound">
          <FilterList label="Funding Round" icon={null}>
            {fundingTypeOptions &&
              fundingTypeOptions
                .sort()
                .map((fundingType: client.FundingTypeEnum) => (
                  <FilterListItem
                    label={fundingType.name || ""}
                    key={fundingType.id}
                    value={{ fundingRoundEnumId: fundingType.id }}
                    data-cy={fundingType.name}
                  />
                ))}
          </FilterList>
        </div>
        <div data-cy="filter__dealMarketing">
          <FilterList label="Deal Marketing" icon={null}>
            {dealMarketingOptions &&
              dealMarketingOptions
                .sort((a, b) => b.displayOrder - a.displayOrder)
                .map(dealMarketing => (
                  <FilterListItem
                    label={dealMarketing.name}
                    key={dealMarketing.id}
                    value={{ dealMarketingEnumId: dealMarketing.id }}
                  />
                ))}
          </FilterList>
        </div>
        <div data-cy="filter__dealStructure">
          <FilterList label="Deal Structure" icon={null}>
            {dealStructureOptions &&
              dealStructureOptions
                .sort()
                .map(deal => (
                  <FilterListItem
                    label={deal.name}
                    key={deal.id}
                    value={{ dealStructureEnumId: deal.id }}
                    data-cy={deal.name}
                  />
                ))}
          </FilterList>
        </div>
        <div data-cy="filter__assetClass">
          <FilterList label="Asset Class" icon={null}>
            {assetClassOptions &&
              assetClassOptions
                .sort()
                .map(assetClass => (
                  <FilterListItem
                    label={assetClass.name}
                    key={assetClass.id}
                    value={{ assetClassEnumId: assetClass.id }}
                    data-cy={assetClass.name}
                  />
                ))}
          </FilterList>
        </div>
        <div data-cy="filter__headquarters">
          <FilterList label="Headquarters" icon={null}>
            {headquarterOptions &&
              headquarterOptions
                .sort()
                .map(country => (
                  <FilterListItem
                    label={country.name}
                    key={country.id}
                    value={{ headquartersEnumId: country.id }}
                    data-cy={country.name}
                  />
                ))}
          </FilterList>
        </div>
        <div data-cy="filter__rcSource">
          <FilterList label="RC Source" icon={null}>
            {rcSourceOptions &&
              rcSourceOptions
                .sort()
                .map(user => (
                  <FilterListItem
                    label={user.name}
                    key={user.id}
                    value={{ rcSource: user.id }}
                    data-cy={user.id}
                  />
                ))}
          </FilterList>
        </div>
      </CardContent>
    </Card>
  );
};
