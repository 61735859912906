import AddIcon from "@mui/icons-material/Add";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { MtdRec, YearRange, range } from "../Edit";
import { Year } from "./year";

export const Headers: string[] = [
  "Year",
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const headerStyling = { width: "100px" };

interface Props {
  data: MtdRec[];
  loading: boolean;
  yearRange: YearRange;
  setYearRange: (range: YearRange) => void;
  addChangedRecord: (count: number, year: number, value: string) => void;
  selectMonthDetail: (count: number, year: number) => void;
  canEdit: boolean;
}

export const Monthly = (props: Props) => {
  const {
    data,
    loading,
    yearRange,
    setYearRange,
    addChangedRecord,
    selectMonthDetail,
    canEdit,
  } = props;

  if (!data) {
    return <div></div>;
  }

  return (
    <TableContainer sx={{ maxHeight: 600 }}>
      <Table
        stickyHeader
        size="small"
        data-id="performanceTableMTD"
        style={{ borderCollapse: "collapse" }}
      >
        <TableHead>
          <TableRow>
            {[...Headers, "YTD"].map(name => (
              <TableCell key={name} style={headerStyling}>
                {name}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data && !loading && (
            <>
              {canEdit &&
                (yearRange.newMax ?? yearRange.oldMax) <
                  new Date().getFullYear() && (
                  <TableRow>
                    <TableCell>
                      <IconButton
                        size="small"
                        color="primary"
                        title="Add Next Year"
                        onClick={() => {
                          setYearRange({
                            ...yearRange,
                            newMax: (yearRange.newMax ?? yearRange.oldMax) + 1,
                          });
                        }}
                        disabled={!canEdit}
                      >
                        <AddIcon />
                      </IconButton>
                    </TableCell>
                    <TableCell colSpan={Headers.length}></TableCell>
                  </TableRow>
                )}
              {range(
                yearRange.newMin ?? yearRange.oldMin,
                yearRange.newMax ?? yearRange.oldMax
              ).map((year: number, i: number) => (
                <Year
                  now={new Date()}
                  year={year}
                  filteredMonths={data.filter(
                    (month: any) => month.returnYear === year
                  )}
                  addChangedRecord={addChangedRecord}
                  selectMonthDetail={selectMonthDetail}
                  key={year}
                  canEdit={canEdit}
                />
              ))}
              {canEdit && (
                <TableRow>
                  <TableCell>
                    <IconButton
                      size="small"
                      color="primary"
                      title="Add Previous Year"
                      onClick={() => {
                        setYearRange({
                          ...yearRange,
                          newMin: (yearRange.newMin ?? yearRange.oldMin) - 1,
                        });
                      }}
                      disabled={!canEdit}
                    >
                      <AddIcon />
                    </IconButton>
                  </TableCell>
                  <TableCell colSpan={Headers.length}></TableCell>
                </TableRow>
              )}
            </>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
