import * as client from "_graphql-types";
import * as types from "./types";

type Sdk = ReturnType<typeof client.getSdk>;

/*********************************************************************/
// Data Provider Functions
/*********************************************************************/

/*********************************************************************/
async function getList(sdk: Sdk, params: types.GetListParams<client.LockupEndValueEnumSortEnum>) {
  const {
    pagination: { page, perPage },
    sort: { field, order }
  } = params;

  const {
    lockupEndValueEnumList: { items: data, total }
  } = await sdk.getListLockupEndValueEnum({
    page: {
      offset: (page - 1) * perPage,
      limit: perPage
    },
    sort: [{ field, order }]
  });

  return {
    data,
    total,
  };
}

/*********************************************************************/
async function getOne(sdk: Sdk, params: types.GetOneParams) {
  const id = Number(params.id);
  const { lockupEndValueEnum: data } = await sdk.getOneLockupEndValueEnum({
    id
  });
  return {
    data,
  };
}

/*********************************************************************/
async function getMany(sdk: Sdk, params: types.GetManyParams) {
  const ids = params.ids.map(Number);

  const {
    lockupEndValueEnumMany: data
  } = await sdk.getManyLockupEndValueEnum({
    ids
  });

  return {
    data,
  };
}


/*********************************************************************/
export const lockupEndValueEnum = types.dataProvider({
  getList,
  getOne,
  getMany
});