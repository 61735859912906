import Chip from "@mui/material/Chip";
import Tab from "@mui/material/Tab";
import { Edit, RaRecord, useRecordContext } from "react-admin";

import { CustomForm, CustomFormProps } from "./CustomForm";
import { ComponentProps } from "react";
import { NestProps } from "./react-admin-fixes";

type CustomEditProps<TRecord extends RaRecord> = NestProps<
  ComponentProps<typeof Edit> & {
    sourcedFromOdc?: (record?: TRecord) => boolean;
    customTabs?: (record?: TRecord) => { name: string; onClick: () => void }[];
  },
  "customFormProps",
  CustomFormProps<TRecord>,
  "title"
>;

export function CustomTitle<TRecord extends RaRecord>({
  title,
  sourcedFromOdc,
  customTabs,
}: {
  title?: (record: TRecord) => string | null;
  sourcedFromOdc?: (record?: TRecord) => boolean;
  customTabs?: (record?: TRecord) => { name: string; onClick: () => void }[];
}) {
  const record = useRecordContext<TRecord>();
  if (!record) return;

  return title ? (
    <>
      <span>
        {title(record)}
        &emsp;
        {!!sourcedFromOdc?.(record) && (
          <Chip color="primary" label="Sourced From ODC" />
        )}
        &emsp;
        {(customTabs?.(record) || []).map(({ name, onClick }: any) => (
          <Tab data-cy={`${name}-tab`} label={name} onClick={onClick} />
        ))}
      </span>
    </>
  ) : null;
}

export function CustomEdit<TRecord extends RaRecord>({
  children,
  title,
  sourcedFromOdc,
  customTabs,
  customFormProps,
  transform: customTransform,
  ...props
}: CustomEditProps<TRecord>) {
  return (
    <Edit
      {...props}
      title={
        <CustomTitle<TRecord>
          title={title}
          sourcedFromOdc={sourcedFromOdc}
          customTabs={customTabs}
        />
      }
      redirect="edit"
      mutationMode="pessimistic"
      transform={x => {
        console.log("transform", x);
        return x;
      }}
    >
      <CustomForm<TRecord> {...customFormProps}>{children}</CustomForm>
    </Edit>
  );
}
