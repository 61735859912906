import React, { Dispatch, SetStateAction } from "react";
import { Grid, MenuItem, Chip, TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import { getCategoriesFromRows, SeriesConfiguration } from "./helpers";

interface SeriesConfigProps {
  headers: string[];
  rows: (string | number | null)[][];
  setChartOptions: Dispatch<SetStateAction<any>>;
  chartOptions: any;
  setSeriesConfiguration: Dispatch<SetStateAction<SeriesConfiguration>>;
  seriesConfiguration: SeriesConfiguration;
}

function SeriesConfig({
  headers,
  rows,
  setSeriesConfiguration,
  seriesConfiguration,
}: SeriesConfigProps) {
  function handleSeriesSelection(
    event: React.ChangeEvent<{}>,
    value: string[],
    reason: any
  ) {
    const selection = getCategoriesFromRows(value, headers, rows);

    if (reason === "clear") {
      setSeriesConfiguration({
        ...seriesConfiguration,
        labelColumn: "",
        labels: [],
      });
    }
    if (reason === "removeOption") {
      setSeriesConfiguration({
        ...seriesConfiguration,
        labels: value,
      });
    }

    if (reason === "selectOption") {
      setSeriesConfiguration({
        ...seriesConfiguration,
        labels: value,
      });
    }
  }

  function getAutocompleteOptions() {
    if (!seriesConfiguration.labelColumn) return [];
    if (seriesConfiguration?.labels?.length) {
      return (
        getCategoriesFromRows(seriesConfiguration.labelColumn, headers, rows)
          .filter(label => !seriesConfiguration?.labels.includes(label))
          // force string in case of mixed type columns
          .map(category => String(category))
      );
    } else {
      return getCategoriesFromRows(
        seriesConfiguration.labelColumn,
        headers,
        rows
      ).map(category => String(category));
    }
  }

  return (
    <>
      <Grid
        data-cy="customAnalytics__series-options"
        container
        spacing={2}
        style={{ marginTop: 5 }}
      >
        <Grid item xs={4}>
          <FormControl variant="standard" fullWidth>
            <InputLabel
              id="series-data-column__label"
              variant="outlined"
              shrink
            >
              Data Column
            </InputLabel>
            <Select
              id="series-data-column"
              labelId="series-data-column__label"
              label="Data Column"
              placeholder="Data Column"
              value={seriesConfiguration?.labelColumn ?? null}
              variant="outlined"
              onChange={e => {
                setSeriesConfiguration({
                  ...seriesConfiguration,
                  labelColumn: e.target.value ?? "",
                  labels: [],
                });
              }}
            >
              {headers.map(header => (
                <MenuItem key={header} value={header}>
                  {header}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <Autocomplete
            multiple
            id="series-categories"
            options={getAutocompleteOptions()}
            value={seriesConfiguration.labels ?? []}
            onChange={(e, value, reason) =>
              handleSeriesSelection(e, value as string[], reason)
            }
            renderTags={(value, getTagProps) =>
              value.map((option, index: number) => (
                <Chip
                  size="small"
                  variant="outlined"
                  label={option as string}
                  {...getTagProps({ index })}
                />
              ))
            }
            renderInput={params => (
              <TextField
                {...params}
                variant="outlined"
                label="Series"
                helperText="Select single column from spreadsheet to populate series labels"
              />
            )}
          />
        </Grid>
      </Grid>
    </>
  );
}

export default SeriesConfig;
