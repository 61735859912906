import * as client from "_graphql-types";
import * as types from "./types";

type Sdk = ReturnType<typeof client.getSdk>;

export async function getList(sdk: Sdk) {
  const data = await sdk.GetImpactCategoryEnumList();

  return {
    data: data.impactCategoryEnumList.items,
    total: data.impactCategoryEnumList.total,
  };
}

export const impactEnums = types.dataProvider({
  getList,
  ttl: types.defaultEnumTtl,
});
