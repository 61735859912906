import * as client from "_graphql-types";
import {
  COMPANY_BUSINESS_OBJECT_ENUM,
  DEAL_BUSINESS_OBJECT_ENUM,
  FIRM_BUSINESS_OBJECT_ENUM,
  INVESTMENT_BUSINESS_OBJECT_ENUM,
} from "./document";
import * as types from "./types";

type Sdk = ReturnType<typeof client.getSdk>;

const create = async (
  sdk: Sdk,
  param: {
    data: {
      businessObjectEnumId: string;
      investment: { id: number };
      company: { id: number };
      firm: { id: number };
      deal: { id: number };
      accessLevel: number;
      drafts: [
        {
          date: string;
          documentTypeEnumId: number;
          file: { rawFile: any };
          isGallery: boolean;
          draftId: number;
        }
      ];
    };
  }
) => {
  console.log("create:", param);

  const res = await sdk.publishDraftDocuments({
    input: param.data.drafts.map(draft => ({
      draftDocumentId: draft.draftId,
      date: draft.date,
      documentTypeEnumId: draft.documentTypeEnumId,
      isGallery: draft.isGallery,
      accessLevel: param.data.accessLevel,
      businessObjectEnumId: Number(param.data.businessObjectEnumId),
      firmId:
        Number(param.data.businessObjectEnumId) === FIRM_BUSINESS_OBJECT_ENUM
          ? Number(param.data.firm?.id)
          : undefined,
      investmentId:
        Number(param.data.businessObjectEnumId) ===
        INVESTMENT_BUSINESS_OBJECT_ENUM
          ? Number(param.data.investment?.id)
          : undefined,
      companyId:
        Number(param.data.businessObjectEnumId) === COMPANY_BUSINESS_OBJECT_ENUM
          ? Number(param.data.company?.id)
          : undefined,
      dealId:
        Number(param.data.businessObjectEnumId) === DEAL_BUSINESS_OBJECT_ENUM
          ? Number(param.data.deal?.id)
          : undefined,
    })),
  });
  return {
    data: {
      id: res.publishDraftDocuments.map(({ id }) => id).join(),
      drafts: param.data.drafts.map((draftData, i) => ({
        ...draftData,
        docId: res.publishDraftDocuments[i].id,
      })),
    },
  };
};

async function getOne(sdk: Sdk, params: types.GetOneParams) {
  const id = Number(params.id);

  return { data: { id: id } };
}

export const documentMulti = types.dataProvider({
  create,
  getOne,
});
