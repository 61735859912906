import { Button, CircularProgress, Grid } from "@mui/material";
import * as client from "_graphql-types";
import { useState } from "react";
import {
  ArrayInput,
  FormDataConsumer,
  NumberInput,
  SimpleFormIterator,
  useGetRecordId,
} from "react-admin";
import { useCanAccessMutation } from "../../util/useCanAccessMutation";
import CustomBreadCrumb from "../CustomBreadCumb";
import { CustomEdit } from "../CustomEdit";
import { Navigation } from "../Navigator";
import { NewQuarter } from "./newQuarter";

export type Record = NonNullable<
  client.GetOneInvestmentPerformancePrivateQuery["investment"]
>;

export const PerformancePrivateEdit = () => {
  const [modalOpen, changeModalOpen] = useState(false);

  const id = useGetRecordId();

  const mutationArgs = JSON.stringify({
    input: {
      investmentId: id,
    },
  });

  const { canEdit, canEditField, loading } = useCanAccessMutation(
    "addOrUpdatePerformancePrivate",
    mutationArgs
  );

  return (
    <CustomEdit<Record>
      title={record => record && `Private Performance - ${record.name}`}
      sourcedFromOdc={(record?: Record) =>
        !!record?.portalSubmitted?.migratedAt
      }
      customFormProps={{
        customToolbarProps: {
          canAccessMutation: canEdit,
        },
        loading,
        canEditField,
      }}
      actions={
        <CustomBreadCrumb<Record>
          name="performancePrivate"
          items={[
            {
              path: "firm",
              getId: record => record?.firm?.id,
              getName: record => record?.firm?.name || "Firm",
            },
            {
              path: "investment",
              getId: record => record?.id,
              getName: record => record?.name || "Investment",
            },
          ]}
        />
      }
    >
      <Grid container>
        <Navigation pathName={"performancePrivate"} hasChanges={false} />
        <NewQuarter open={modalOpen} close={() => changeModalOpen(false)} />
        {!canEdit && loading && <CircularProgress />}
        <ArrayInput source="performancePrivate">
          <SimpleFormIterator disableAdd disableRemove disableReordering>
            <FormDataConsumer>
              {row => {
                const year = row.scopedFormData!.returnYear;
                const quarter = row.scopedFormData!.returnQuarter;

                return (
                  <Grid container style={{ marginTop: 8, marginBottom: 8 }}>
                    <Grid item xs={1}>
                      <div>
                        {year}-Q
                        {quarter}
                      </div>
                    </Grid>
                    <div key={`${year}-${quarter}`}>
                      <Grid container>
                        <Grid item xs={4}>
                          <NumberInput
                            source="netIRR"
                            label="Net IRR"
                            variant="outlined"
                            inputProps={{
                              "data-id": `${year}-${quarter}-netIRR`,
                            }}
                            disabled={!canEdit}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <NumberInput
                            label="TVPI"
                            source="tvpi"
                            step={0.01}
                            variant="outlined"
                            inputProps={{
                              "data-id": `${year}-${quarter}-tvpi`,
                            }}
                            disabled={!canEdit}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <NumberInput
                            label="DPI"
                            source="dpi"
                            step={0.01}
                            variant="outlined"
                            inputProps={{
                              "data-id": `${year}-${quarter}-dpi`,
                            }}
                            disabled={!canEdit}
                          />
                        </Grid>
                      </Grid>
                    </div>
                  </Grid>
                );
              }}
            </FormDataConsumer>
          </SimpleFormIterator>
        </ArrayInput>
      </Grid>
      <div style={{ marginBottom: "10px" }}>
        {canEdit && (
          <Button
            variant="contained"
            color="primary"
            onClick={() => changeModalOpen(true)}
            data-id="add-quarter"
          >
            Add Quarter
          </Button>
        )}
      </div>
    </CustomEdit>
  );
};
