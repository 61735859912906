import * as client from "_graphql-types";
import * as types from "./types";

import { unhandledCase } from "../helpers/typescript";

type Sdk = ReturnType<typeof client.getSdk>;

interface RiskGetOneArgs {
  // id is JSON.stringify([key,id])
  id: string;
}

type RiskUpdateArgs =
  | {
      id: "refreshInvestmentRisk";
      data: client.RefreshInvestmentRiskMutationVariables;
    }
  | {
      id: "copyRiskMeasures";
      data: client.CopyRiskMeasuresMutationVariables;
    }
  | {
      id: "deleteRiskMeasures";
      data: client.DeleteRiskMeasuresMutationVariables;
    }
  | {
      id: "importRiskMeasures";
      data: client.ImportRiskMeasuresMutationVariables;
    };

async function getOne(sdk: Sdk, params: RiskGetOneArgs) {
  const [key, id] = JSON.parse(params.id);
  switch (key) {
    case "importRiskMeasuresStatus": {
      const { importRiskMeasuresStatus: data } =
        await sdk.importRiskMeasuresStatus({ id });
      return {
        data,
      };
    }
    default:
      throw unhandledCase(key as never);
  }
}

async function update(sdk: Sdk, params: RiskUpdateArgs) {
  switch (params.id) {
    case "copyRiskMeasures": {
      const { copyRiskMeasures: data } = await sdk.copyRiskMeasures(
        params.data
      );
      return {
        data: { id: params.id, data },
      };
    }
    case "deleteRiskMeasures": {
      const { deleteRiskMeasures: data } = await sdk.deleteRiskMeasures(
        params.data
      );
      return {
        data: { id: params.id, data },
      };
    }
    case "refreshInvestmentRisk": {
      const { refreshInvestmentRisk: data } = await sdk.refreshInvestmentRisk(
        params.data
      );
      // workaround for type-graphql emitting id as nullable
      return { data: { ...data, id: data.id! } };
    }
    case "importRiskMeasures": {
      const { importRiskMeasures: data } = await sdk.importRiskMeasures(
        params.data
      );
      // workaround for type-graphql emitting id as nullable
      return { data: { ...data, id: data.id! } };
    }
    default:
      throw unhandledCase(params);
  }
}

export const investmentRisk = types.dataProvider({
  getOne,
  update: update as types.DataProvider["update"],
});
