import * as client from "_graphql-types";
import { filterSortPage } from "../DataProvider";
import * as types from "./types";

type Sdk = ReturnType<typeof client.getSdk>;

/*********************************************************************/
// Data Provider Functions
/*********************************************************************/

/*********************************************************************/
export async function getList(
  sdk: Sdk,
  params: types.GetListParams<client.PortalFirmSortEnum>
) {
  const {
    pagination,
    sort,
    filter: { shouldUpdate, ...restOfFilter },
  } = params;

  const {
    portalFirmList: { items: data, total },
  } = await sdk.getListPortalFirm(
    filterSortPage({
      filter: {
        ...(shouldUpdate && { shouldUpdate: true }),
        ...restOfFilter,
      },
      sort,
      pagination,
    })
  );

  return {
    data,
    total,
  };
}

/*********************************************************************/
async function getOne(sdk: Sdk, params: types.GetOneParams) {
  const id = String(params.id);
  const { portalFirm: data } = await sdk.getOnePortalFirm({
    id,
  });
  return {
    data,
  };
}

/*********************************************************************/
async function getMany(sdk: Sdk, params: types.GetManyParams) {
  const ids = params.ids.map(id => String(id));

  const { portalFirmMany: data } = await sdk.getManyPortalFirm({
    ids,
  });

  return {
    data,
  };
}

/*********************************************************************/
async function getManyReference(
  sdk: Sdk,
  params: types.GetManyReferenceParams<client.PortalFirmSortEnum>
) {
  const id = String(params.id);
  const {
    pagination: { page, perPage },
    sort: { field, order },
    filter,
    target,
  } = params;

  const {
    portalFirmList: { items: data, total },
  } = await sdk.getListPortalFirm({
    page: {
      offset: (page - 1) * perPage,
      limit: perPage,
    },
    sort: [{ field, order }],
    filter: { ...filter, [target]: id },
  });

  return {
    data,
    total,
  };
}

/*********************************************************************/
async function update(
  sdk: Sdk,
  params: types.UpdateParams<
    NonNullable<client.GetOnePortalFirmQuery["portalFirm"]>
  >
): types.UpdateOut<any> {
  const id = String(params.id);
  const { data } = params;

  const input: client.UpdatePortalFirmMutationVariables["input"] = {
    shouldUpdate: data.shouldUpdate,
    rcgFirmId: data.rcgFirmId ? `${data.rcgFirmId}` : undefined,
    firmTypeEnumId: data.firmTypeEnumId
      ? Number(data.firmTypeEnumId)
      : undefined,
    organizationId: data.organizationId,
    name: data.name,
  };

  await sdk.updatePortalFirm({
    id,
    input,
  });

  return await getOne(sdk, { id });
}

async function softDelete(sdk: Sdk, params: types.DeleteParams) {
  const id = String(params.id);

  await sdk.deletePortalFirm({ id });

  return {
    data: { id },
  };
}

/*********************************************************************/
export const portalFirm = types.dataProvider({
  getList,
  getOne,
  getMany,
  getManyReference,
  update,
  delete: softDelete,
});
