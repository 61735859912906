import * as client from "_graphql-types";
import { filterSortPage } from "../DataProvider";
import * as types from "./types";

type Sdk = ReturnType<typeof client.getSdk>;

/*********************************************************************/
// Data Provider Functions
/*********************************************************************/

async function getOne(sdk: Sdk, params: types.GetOneParams) {
  const id = String(params.id);

  const { userActivity: data } = await sdk.getOneUserActivity({
    id,
  });
  return { data };
}

/*********************************************************************/

async function getList(
  sdk: Sdk,
  params: types.GetListParams<client.UserActivitySortEnum>
) {
  const { pagination, sort, filter } = params;
  const {
    userActivityList: { items: data, total },
  } = await sdk.getListUserActivity(
    filterSortPage({ filter, sort, pagination })
  );

  return {
    data,
    total,
  };
}

/*********************************************************************/
async function getMany(sdk: Sdk, params: types.GetManyParams) {
  const {
    userActivityList: { items: data },
  } = await sdk.getManyUserActivity({ ids: params.ids.map(String) });

  return {
    data,
  };
}

export const userActivity = types.dataProvider({
  getList,
  getMany,
  getOne,
});
