import * as client from "_graphql-types";
import { filterSortPage } from "../DataProvider";
import * as types from "./types";

// import {addDays} from "date-fns";

type Sdk = ReturnType<typeof client.getSdk>;

/*********************************************************************/
// Data Provider Functions
/*********************************************************************/

/*********************************************************************/
async function getList(
  sdk: Sdk,
  params: types.GetListParams<client.InvestmentFirmEmploymentRoleEnumSortEnum>
) {
  const { sort, filter } = params;

  const {
    investmentFirmEmploymentRoleEnumList: { items: data, total },
  } = await sdk.getListInvestmentFirmEmploymentRoleEnum(
    filterSortPage({
      filter,
      sort,
    })
  );

  return {
    data,
    total,
  };
}

/*********************************************************************/
async function getOne(sdk: Sdk, params: types.GetOneParams) {
  const id = Number(params.id);
  const { investmentFirmEmploymentRoleEnum: data } =
    await sdk.getOneInvestmentFirmEmploymentRoleEnum({
      id,
    });
  return {
    data,
  };
}

/*********************************************************************/
async function getMany(sdk: Sdk, params: types.GetManyParams) {
  const ids = params.ids.map(id => Number(id));

  const { investmentFirmEmploymentRoleEnumMany: data } =
    await sdk.getManyInvestmentFirmEmploymentRoleEnum({
      ids,
    });

  return {
    data,
  };
}

/*********************************************************************/
export const investmentFirmEmploymentRoleEnum = types.dataProvider({
  getList,
  getOne,
  getMany,
  ttl: types.defaultEnumTtl,
});
