import * as client from "_graphql-types";
import * as types from "./types";

import { addDays } from "date-fns";

type Sdk = ReturnType<typeof client.getSdk>;

/*********************************************************************/
// Data Provider Functions
/*********************************************************************/

/*********************************************************************/
async function getList(
  sdk: Sdk,
  params: types.GetListParams<client.ServiceProviderDocumentTypeSortEnum>
) {
  const {
    pagination: { page, perPage },
    sort: { field, order },
  } = params;

  const {
    serviceProviderDocumentTypeList: { items: data, total },
  } = await sdk.getListServiceProviderDocumentType({
    page: {
      offset: (page - 1) * perPage,
      limit: perPage,
    },
    sort: [{ field, order }],
  });

  return {
    data,
    total,
    // one day from now
    validUntil: addDays(new Date(), 1),
  };
}

/*********************************************************************/
async function getOne(sdk: Sdk, params: types.GetOneParams) {
  const id = Number(params.id);
  const { serviceProviderDocumentType: data } =
    await sdk.getOneServiceProviderDocumentType({
      id,
    });
  return {
    data,
    // one day from now
    validUntil: addDays(new Date(), 1),
  };
}

/*********************************************************************/
async function getMany(sdk: Sdk, params: types.GetManyParams) {
  const ids = params.ids.map(Number);

  const { serviceProviderDocumentTypeMany: data } =
    await sdk.getManyServiceProviderDocumentType({
      ids,
    });

  return {
    data,
    // one day from now
    validUntil: addDays(new Date(), 1),
  };
}

/*********************************************************************/
export const serviceProviderDocumentType = types.dataProvider({
  getList,
  getOne,
  getMany,
});
