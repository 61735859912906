import * as client from "_graphql-types";
import * as types from "./types";

type Sdk = ReturnType<typeof client.getSdk>;

/*********************************************************************/
// Data Provider Functions
/*********************************************************************/

/*********************************************************************/
async function getList(
  sdk: Sdk,
  params: types.GetListParams<client.GlobalProviderNoteTypeEnumSortEnum>
) {
  const {
    pagination: { page, perPage },
    sort: { field, order },
    filter,
  } = params;

  const {
    globalProviderNoteTypeEnumList: { items: data, total },
  } = await sdk.getListGlobalProviderNoteTypeEnum({
    page: {
      offset: (page - 1) * perPage,
      limit: perPage,
    },
    sort: [{ field, order }],
    filter,
  });

  return {
    data,
    total,
  };
}

/*********************************************************************/
async function getOne(sdk: Sdk, params: types.GetOneParams) {
  const id = params.id;
  const { globalProviderNoteTypeEnum: data } =
    await sdk.getOneGlobalProviderNoteTypeEnum({
      id: Number(id),
    });
  return {
    data,
  };
}

/*********************************************************************/
async function getMany(sdk: Sdk, params: types.GetManyParams) {
  const ids = params.ids;

  const { globalProviderNoteTypeEnumMany: data } =
    await sdk.getManyGlobalProviderNoteTypeEnum({
      ids: ids.map(Number),
    });

  return {
    data,
  };
}

/*********************************************************************/
async function getManyReference(
  sdk: Sdk,
  params: types.GetManyReferenceParams<client.GlobalProviderNoteTypeEnumSortEnum>
) {
  const id = params.id;
  const {
    pagination: { page, perPage },
    sort: { field, order },
    filter,
    target,
  } = params;

  const {
    globalProviderNoteTypeEnumList: { items: data, total },
  } = await sdk.getListGlobalProviderNoteTypeEnum({
    page: {
      offset: (page - 1) * perPage,
      limit: perPage,
    },
    sort: [{ field, order }],
    filter: { ...filter, [target]: id },
  });

  return {
    data,
    total,
  };
}

/*********************************************************************/
export const globalProviderNoteTypeEnum = types.dataProvider({
  getList,
  getOne,
  getMany,
  getManyReference,
  ttl: types.defaultEnumTtl,
});
