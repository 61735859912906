import * as client from "_graphql-types";
import * as types from "./types";
import { EnsureAllKeys } from "../helpers/types";
type Sdk = ReturnType<typeof client.getSdk>;

/*********************************************************************/
// Data Provider Functions
/*********************************************************************/

/*********************************************************************/
async function getList(
  sdk: Sdk,
  params: types.GetListParams<client.ApplicationSortEnum>
) {
  const {
    pagination: { page, perPage },
    sort: { field, order },
    filter,
  } = params;

  const {
    applicationList: { items: data, total },
  } = await sdk.getListApplication({
    page: {
      offset: (page - 1) * perPage,
      limit: perPage,
    },
    sort: [{ field, order }],
    filter,
  });

  return {
    data,
    total,
  };
}

/*********************************************************************/
async function getOne(sdk: Sdk, params: types.GetOneParams) {
  const id = params.id as number;
  const { application: data } = await sdk.getOneApplication({
    id,
  });
  return {
    data,
  };
}

/*********************************************************************/
async function getMany(sdk: Sdk, params: types.GetManyParams) {
  const ids = params.ids as number[];

  const { applicationMany: data } = await sdk.getManyApplication({
    ids,
  });

  return {
    data,
  };
}

/*********************************************************************/
async function getManyReference(
  sdk: Sdk,
  params: types.GetManyReferenceParams<client.ApplicationSortEnum>
) {
  const id = params.id as number;
  const {
    pagination: { page, perPage },
    sort: { field, order },
    filter,
    target,
  } = params;

  const {
    applicationList: { items: data, total },
  } = await sdk.getListApplication({
    page: {
      offset: (page - 1) * perPage,
      limit: perPage,
    },
    sort: [{ field, order }],
    filter: { ...filter, [target]: id },
  });

  return {
    data,
    total,
  };
}

/*********************************************************************/
export const application = types.dataProvider({
  getList,
  getOne,
  getMany,
  getManyReference,
});
