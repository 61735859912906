import * as client from "_graphql-types";
import { filterSortPage } from "../DataProvider";
import * as types from "./types";

type Sdk = ReturnType<typeof client.getSdk>;

/*********************************************************************/
// Data Provider Functions
/*********************************************************************/

/*********************************************************************/

export async function getList(
  sdk: Sdk,
  params: types.GetListParams<client.TagClassSortEnum>
) {
  const { pagination, sort, filter } = params;

  const {
    tagClassList: { items: data, total },
  } = await sdk.getListTagClasses(filterSortPage({ filter, sort, pagination }));

  return {
    data,
    total,
  };
}

/*********************************************************************/

async function getOne(sdk: Sdk, params: types.GetOneParams) {
  const id = Number(params.id);

  const { tagClass: data } = await sdk.getOneTagClasses({
    id,
  });
  return { data };
}

/*********************************************************************/

async function getMany(sdk: Sdk, params: types.GetManyParams) {
  const ids = params.ids.map(id => Number(id));
  const { tagClassMany: data } = await sdk.getManyTagClasses({
    ids,
  });
  return { data };
}

/*********************************************************************/

export const tagClasses = types.dataProvider({
  getOne,
  getMany,
  getList,
});
