import { Chip, Link } from "@mui/material";
import {
  CreateButton,
  Datagrid,
  ExportButton,
  FilterButton,
  FunctionField,
  List,
  ReferenceManyField,
  SingleFieldList,
  TextField,
  TopToolbar,
  WrapperField,
} from "react-admin";
import { CopyButton } from "../UI/CopyField";
import { FinancialDataProviderFilter, filters } from "./Filter";

export const FinancialDataProviderList = () => {
  return (
    <List
      sx={{
        ".RaFilter-form": {
          flex: "0 1 50%",
        },
      }}
      empty={false}
      disableSyncWithLocation
      filters={<FinancialDataProviderFilter />}
      sort={{ field: "nameSearchRank", order: "ASC" }}
      actions={
        <TopToolbar>
          <FilterButton filters={filters} />
          <CreateButton />
          <ExportButton maxResults={250} />
        </TopToolbar>
      }
    >
      <Datagrid
        data-cy="financialDataProviderList"
        rowClick={"edit"}
        bulkActionButtons={false}
        sx={{
          "& .MuiTableRow-root": {
            verticalAlign: "top",
          },
        }}
      >
        <TextField source="name" />
        <WrapperField source="websites" sortable={false}>
          <ReferenceManyField
            reference="fdpWebsites"
            target="financialDataProviderId"
            label="Websites"
          >
            <SingleFieldList linkType={false}>
              <FunctionField
                source="website"
                render={(record: any) => (
                  <Chip
                    size="small"
                    label={
                      <Link
                        href={
                          record.website.startsWith("http")
                            ? record.website
                            : `https://${record.website}`
                        }
                        target="_blank"
                        rel="noreferrer"
                      >
                        {record.website}
                      </Link>
                    }
                    sx={{
                      "&.MuiChip-root": {
                        margin: "6px",
                      },
                    }}
                    onClick={e => e.stopPropagation()}
                    icon={
                      <CopyButton value={record.website} source="website" />
                    }
                  />
                )}
              />
            </SingleFieldList>
          </ReferenceManyField>
        </WrapperField>
      </Datagrid>
    </List>
  );
};
