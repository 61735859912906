import React, { useEffect, useState, useRef } from "react";
import { useWatch, useFormContext } from "react-hook-form";
import { useRecordContext } from "react-admin";

function usePrevious<T>(value: T): T | undefined {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export default function InvestmentFormDecorator() {
  const [vehicleTypeEnumId, assetClassEnumId, strategyEnumId] = useWatch({
    name: ["vehicleTypeEnumId", "assetClassEnumId", "strategyEnumId"],
  });

  const { setValue } = useFormContext();
  const previous = usePrevious<{
    vehicleTypeEnumId?: number;
    assetClassEnumId?: number;
    strategyEnumId?: number;
  }>({
    vehicleTypeEnumId,
    assetClassEnumId,
    strategyEnumId,
  });

  useEffect(() => {
    if (previous && previous.vehicleTypeEnumId !== vehicleTypeEnumId) {
      setValue("assetClassEnumId", null);
    }
  }, [vehicleTypeEnumId]);

  useEffect(() => {
    if (previous && previous.assetClassEnumId !== assetClassEnumId) {
      setValue("strategyEnumId", null);
      setValue("peStrategyEnumId", null);
    }
  }, [assetClassEnumId]);

  useEffect(() => {
    if (previous && strategyEnumId !== previous.strategyEnumId) {
      setValue("subStrategyEnumId", null);
    }
  }, [strategyEnumId]);

  return null;
}
