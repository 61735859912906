import * as client from "_graphql-types";

import Grid from "@mui/material/Grid";
import {
  AutocompleteInput,
  BooleanInput,
  Button,
  DateInput,
  ReferenceInput,
  TextInput,
  useRecordContext,
} from "react-admin";
import { useWatch } from "react-hook-form";
import { Link } from "react-router-dom";
import CustomBreadCrumb from "../CustomBreadCumb";
import { CustomEdit } from "../CustomEdit";
import { FaceOfEntityTooltip } from "../FaceOfEntityTooltip";

type Record = NonNullable<
  client.GetOneInvestmentFirmEmploymentQuery["investmentFirmEmployment"]
>;

const ViewFirmEmploymentButton = () => {
  const record = useRecordContext<Record>();
  return !record?.firmEmployment?.id ? null : (
    <Button
      component={Link}
      to={{
        pathname: `/firmEmployment/${record.firmEmployment.id}`,
      }}
      label="Go to Firm Employment"
    />
  );
};

const ViewInvestmentButton = () => {
  const record = useRecordContext<Record>();
  return !record?.investment?.id ? null : (
    <Button
      component={Link}
      to={{
        pathname: `/investment/${record.investment.id}`,
      }}
      label="Go to Investment"
    />
  );
};

const ViewPersonButton = () => {
  const record = useRecordContext<Record>();

  const id = record?.firmEmployment?.person?.id;
  return !(id || id === 0) ? null : (
    <Button
      component={Link}
      to={{
        pathname: `/person/${id}`,
      }}
      label="Go to Person"
    />
  );
};

function InvestmentFirmEmploymentEditForm() {
  const formData = useWatch();
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <ViewFirmEmploymentButton />
        </Grid>
        <Grid item xs={6}>
          <ViewPersonButton />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            label="Firm"
            source=""
            format={() => {
              const firm = formData?.firmEmployment?.firm;
              return firm?.name;
            }}
            disabled
          />
        </Grid>
        <Grid item xs={4}>
          <TextInput
            label="Person"
            source=""
            format={() => {
              const person = formData?.firmEmployment?.person;
              return `${person?.lastName || ""}, ${person?.firstName || ""} ${
                person?.middleName || ""
              }`;
            }}
            disabled
          />
        </Grid>
        <Grid item xs={2}>
          <TextInput
            label="Firm Employment ID"
            source=""
            format={() => {
              return formData?.firmEmployment?.id;
            }}
            disabled
          />
        </Grid>
        <Grid item xs={6}>
          <ViewInvestmentButton />
        </Grid>
        <Grid item xs={6}></Grid>
        <Grid item xs={6}>
          <TextInput
            source=""
            label="Investment"
            format={() => {
              return formData?.investment?.name;
            }}
            disabled
          />
        </Grid>
        <Grid item xs={4}>
          <ReferenceInput
            source="employeeRoleEnumId"
            reference="investmentFirmEmploymentRoleEnum"
            sort={{ field: "name", order: "ASC" }}
          >
            <AutocompleteInput optionText="name" label="Employee Role" />
          </ReferenceInput>
        </Grid>
        <Grid item xs={2}></Grid>
        <Grid item xs={3}>
          <DateInput label="Start Date" source="startDate" />
        </Grid>
        <Grid item xs={3}>
          <DateInput label="End Date" source="leftDate" />
        </Grid>
        <Grid item xs={3}>
          <FaceOfEntityTooltip entity="investment">
            <BooleanInput
              label="Face of Investment"
              source="faceOfInvestment"
            />
          </FaceOfEntityTooltip>
        </Grid>
      </Grid>
    </>
  );
}

export const InvestmentFirmEmploymentEdit = () => {
  return (
    <CustomEdit
      title={record => record && `Investment Assignment (${record.id})`}
      actions={
        <CustomBreadCrumb<Record>
          name="investmentFirmEmployment"
          items={[
            {
              path: "firm",
              getId: record => record?.firmEmployment?.firm?.id,
              getName: record => record?.firmEmployment?.firm?.name,
            },
            {
              path: "investment",
              getId: record => record?.investment?.id,
              getName: record => record?.investment?.name,
            },
            {
              path: "person",
              getId: record => record?.firmEmployment?.person.id,
              getName: record =>
                record?.firmEmployment?.person.id
                  ? `${record?.firmEmployment?.person.firstName} ${record?.firmEmployment?.person.lastName}`
                  : undefined,
            },
          ]}
        />
      }
      customFormProps={{
        customToolbarProps: {
          allowDelete: true,
          deleteButtonRedirect: record =>
            `/firmEmployment/${record.firmEmployment.id}`,
        },
      }}
    >
      <InvestmentFirmEmploymentEditForm />
    </CustomEdit>
  );
};
