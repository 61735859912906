import React from "react";
import {
  RaRecord,
  SelectArrayInput,
  SortPayload,
  required,
  useRecordContext,
} from "react-admin";
import { EntityAutoComplete } from "./EntityAutocomplete";
import { Autocomplete, Checkbox } from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

type EntityInputProps = {
  resource: string;
  recordKey: string;
  sourceKey?: string;
  label: string;
  filters?: { [key: string]: any };
  style?: React.CSSProperties;
  isRequired?: boolean;
  allowChange?: boolean;
  hidden?: boolean;
  getChoice?: (record: any) => { id: string | number; name: string };
  sort?: SortPayload;
  name?: string;
  onChange?: Parameters<typeof EntityAutoComplete>[0]["onChange"];
  renderOptionsAsCheckboxes?: boolean;
  inputId?: string;
  disabled?: boolean;
};

export function EntityInputMany<TRecord extends RaRecord>(
  props: EntityInputProps
) {
  const {
    resource,
    recordKey,
    label,
    sourceKey,
    style,
    filters,
    isRequired = false,
    allowChange = false,
    hidden = false,
    getChoice,
    sort,
    onChange,
    renderOptionsAsCheckboxes = true,
    inputId,
    disabled,
  } = props;

  const record = useRecordContext<TRecord>();

  const renderOption: Parameters<
    typeof EntityAutoComplete
  >[0]["renderOption"] = (props, option, { selected }) => (
    <li {...props}>
      <Checkbox
        icon={icon}
        checkedIcon={checkedIcon}
        style={{ marginRight: 8 }}
        checked={selected}
      />
      {getChoice ? getChoice(option).name : (option as RaRecord).name}
    </li>
  );

  return !record?.[recordKey]?.id || allowChange ? (
    <EntityAutoComplete
      hidden={hidden}
      recordKey={recordKey}
      resource={resource}
      style={{ display: hidden ? "none" : "block", ...style }}
      label={label}
      sourceKey={sourceKey}
      isRequired={isRequired}
      data-id={`${recordKey}-input-many-vacant`}
      filters={filters}
      renderOption={renderOptionsAsCheckboxes ? renderOption : undefined}
      formatOption={
        getChoice
          ? record => {
              return getChoice(record).name;
            }
          : undefined
      }
      sort={sort ? sort : { field: "nameSearchRank", order: "ASC" }}
      onChange={onChange}
      allowMultiple={true}
      inputId={inputId}
      disabled={disabled}
    />
  ) : (
    <SelectArrayInput
      label={label}
      className={hidden ? "hidden" : ""}
      source={`${recordKey}.id`}
      validate={isRequired ? [required()] : []}
      disabled
      choices={[
        getChoice
          ? getChoice(record[recordKey])
          : { id: record[recordKey].id, name: record[recordKey].name },
      ]}
      data-id={`${recordKey}-input-many-filled`}
    />
  );
}
