import * as client from "_graphql-types";
import {
  TextField,
  useRecordContext,
  Labeled,
  ReferenceManyField,
  ReferenceField,
  useListContext,
} from "react-admin";
import { FCField_RenderTextWithConvenienceCopy } from "../UI/CopyField";
import { Chip, Collapse, List as MuiList, ListItemText } from "@mui/material";
import { Stack } from "@mui/system";

import { EntityInput } from "../UI/EntityInput";
import { ChipDropdown, SimpleDropdownDisplay } from "../SimpleDropdownDisplay";

type Record = NonNullable<client.GetOneFdpLoginQuery["fdpLogin"]>;

function websitesUnmappedToFirmFundOrPortfolio(record: Record) {
  const firmWebsiteIds =
    record.firmFDPLogins?.map(({ fdpWebsite }) => fdpWebsite?.id) || [];
  const investmentWebsiteIds =
    record.investmentFDPLogins?.map(({ fdpWebsite }) => fdpWebsite?.id) || [];
  const portfolioWebsiteIds =
    record.portfolioFDPLogins?.map(({ fdpWebsite }) => fdpWebsite?.id) || [];

  const unmapped = record.financialDataProvider.fdpWebsites.filter(
    website =>
      [
        ...firmWebsiteIds,
        ...investmentWebsiteIds,
        ...portfolioWebsiteIds,
      ].indexOf(website.id) === -1
  );

  return unmapped;
}

const WebsiteCell = () => {
  const { isLoading, data } =
    useListContext<Record["financialDataProvider"]["fdpWebsites"][number]>();
  if (isLoading || !data?.length) return null;

  function renderWebsiteCell(
    website: Record["financialDataProvider"]["fdpWebsites"][number]
  ) {
    return (
      <FCField_RenderTextWithConvenienceCopy
        key={`fdpWebsite-${website.id}`}
        record={website}
        source="website"
        maxChars={30}
        width={250}
        isLink={true}
      />
    );
  }

  if (data.length > 1) {
    return (
      <SimpleDropdownDisplay
        triggerType="button"
        label={`${data.length} Websites`}
        dataList={data}
        renderListItem={renderWebsiteCell}
      />
    );
  }

  return renderWebsiteCell(data[0]);
};

const FDPWebsiteCell = ({
  record,
  entityContext,
  entity,
}: {
  record: Record;
  entityContext?: string;
  entity?: { id: number; firmId?: number | null };
}) => {
  let websiteCell = (
    <ReferenceManyField
      reference="fdpWebsites"
      target="financialDataProviderId"
      source="financialDataProvider.id"
    >
      <WebsiteCell />
    </ReferenceManyField>
  );
  let entityLevelWebsiteCell;

  if (entity?.id && entityContext) {
    if (entityContext === "firm") {
      const firmRelationship = record.firmFDPLogins?.find(
        ({ firmId }) => firmId === entity.id
      );
      if (firmRelationship?.fdpWebsite) {
        entityLevelWebsiteCell = (
          <FCField_RenderTextWithConvenienceCopy
            key={`fdpWebsite-${firmRelationship.fdpWebsite.id}`}
            record={firmRelationship.fdpWebsite}
            source="website"
            maxChars={40}
            isLink={true}
          />
        );
      }
    }
    if (entityContext === "investment") {
      const investmentRelationship = record.investmentFDPLogins?.find(
        ({ investmentId }) => investmentId === entity.id
      );

      if (investmentRelationship?.fdpWebsite) {
        entityLevelWebsiteCell = (
          <FCField_RenderTextWithConvenienceCopy
            key={`fdpWebsite-${investmentRelationship.fdpWebsite.id}`}
            record={investmentRelationship.fdpWebsite}
            source="website"
            maxChars={40}
            isLink={true}
          />
        );
      }
      // fall back to firm if there is no investment relationship
      else if (!investmentRelationship && entity.firmId) {
        const firmRelationship = record.firmFDPLogins?.find(
          ({ firmId }) => firmId === entity.firmId
        );
        if (firmRelationship?.fdpWebsite) {
          entityLevelWebsiteCell = (
            <FCField_RenderTextWithConvenienceCopy
              key={`fdpWebsite-${firmRelationship.fdpWebsite.id}`}
              record={firmRelationship.fdpWebsite}
              source="website"
              maxChars={40}
              isLink={true}
            />
          );
        }
      }
    }
    // last resort, check if there is a website unmapped to any other entity
    if (!entityLevelWebsiteCell) {
      const websites = websitesUnmappedToFirmFundOrPortfolio(record);
      if (websites.length === 1) {
        entityLevelWebsiteCell = (
          <FCField_RenderTextWithConvenienceCopy
            key={`fdpWebsite-${websites[0].id}`}
            record={websites[0]}
            source="website"
            maxChars={40}
            isLink={true}
          />
        );
      }
    }
    websiteCell = entityLevelWebsiteCell || websiteCell;
  }
  return websiteCell;
};

export const FDPShowCell = ({
  entityContext,
  entity,
}: {
  entityContext?: string;
  entity?: { id: number; firmId?: number | null };
}) => {
  const record = useRecordContext<Record>();
  if (!record) return;

  return (
    <Stack direction="column" spacing={2}>
      <Labeled label="Name">
        <ReferenceField
          source="financialDataProviderId"
          reference="financialDataProvider"
        >
          <TextField source="name" />
        </ReferenceField>
      </Labeled>
      <Labeled label="Websites">
        <ReferenceManyField
          reference="fdpWebsites"
          target="financialDataProviderId"
          source="financialDataProvider.id"
        >
          <FDPWebsiteCell
            record={record}
            entity={entity}
            entityContext={entityContext}
          />
        </ReferenceManyField>
      </Labeled>
    </Stack>
  );
};

export const FDPEditCell = ({
  allowFDPChange = true,
}: {
  allowFDPChange?: boolean;
}) => {
  return (
    <Stack direction="column" spacing={2}>
      <EntityInput<Record>
        label="FDP"
        resource="financialDataProvider"
        recordKey="financialDataProvider"
        name="financialDataProvider"
        style={{ width: 250 }}
        allowChange={allowFDPChange}
        isRequired={true}
      />
    </Stack>
  );
};
