import { Filter, ReferenceInput, TextInput } from "react-admin";
import { EntityInput } from "../UI/EntityInput";

export const LinksFilter = (props: any) => (
  <Filter {...props}>
    <TextInput
      label="Name Search"
      source="name"
      alwaysOn
      style={{ width: 250 }}
      resettable
    />
    <TextInput
      label="Url Search"
      source="url"
      alwaysOn
      style={{ width: 250 }}
      resettable
    />
  </Filter>
);
