import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
} from "@mui/material";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { InputProps, useInput } from "react-admin";
import { useWatch } from "react-hook-form";
import { Record } from "../Create";
import AxisConfig from "./AxisConfig";
import CustomHighCharts from "./HighChart.component";
import { LayerConfig } from "./LayerConfig";
import SeriesConfig from "./SeriesConfig";
import {
  AxisConfiguration,
  LayerConfiguration,
  SeriesConfiguration,
  generateConfigFromHCOptions,
  generateHCOptionsFromConfig,
} from "./helpers";

interface CustomChartProps {
  chartOptions: Highcharts.Options;
  setOptions: Dispatch<SetStateAction<Highcharts.Options>>;
  rows: (string | number | null)[][];
  headers: string[];
  setRows: Dispatch<SetStateAction<(string | number | null)[][]>>;
  setHeaders: Dispatch<SetStateAction<string[]>>;
  source: string;
}

function CustomChart({
  chartOptions,
  setOptions,
  rows,
  setRows,
  headers,
  setHeaders,
  source,
}: CustomChartProps & InputProps) {
  const formData = useWatch<NonNullable<Record>>();

  const [layerConfig, setLayerConfig] = useState<LayerConfiguration>({
    enabled: false,
    dataColumn: "",
  });
  const [axisConfig, setAxisConfig] = useState<AxisConfiguration>({
    mainAxis: { type: "linear" },
    crossAxis: { type: "linear" },
  });
  const [seriesConfig, setSeriesConfig] = useState<SeriesConfiguration>({
    labels: [],
    enabled: false,
    labelColumn: "",
  });

  useEffect(() => {
    if (formData?.value) {
      setOptions(formData.value);

      const config = generateConfigFromHCOptions({
        options: formData.value,
        rows,
        headers,
      });

      if (config.axisConfig) {
        setAxisConfig(config.axisConfig);
      }
      if (config.seriesConfig) {
        setSeriesConfig(config.seriesConfig);
      }
      if (config.layerConfig) {
        setLayerConfig(config.layerConfig);
      }
    }
  }, []);

  const input = useInput({ source });
  const {
    field: { onChange },
  } = input;

  return (
    <>
      <AxisConfig
        {...{ headers, rows, layerConfig }}
        setAxisOptions={setAxisConfig}
        axisOptions={axisConfig}
        chartOptions={chartOptions}
        setChartOptions={setOptions}
      />
      <Grid item xs={3}>
        <h4>Chart Options</h4>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <FormControl variant="standard" fullWidth>
            <InputLabel id="chart-type__label" variant="outlined" required>
              Chart Type
            </InputLabel>
            <Select
              required
              id="chart-type"
              labelId="chart-type__label"
              label="Chart Type"
              placeholder="Chart Type"
              variant="outlined"
              value={chartOptions?.chart?.type ?? null}
              onChange={e => {
                setOptions({
                  ...chartOptions,
                  chart: {
                    ...chartOptions?.chart,
                    type: e.target.value ?? "",
                  },
                });
              }}
            >
              <MenuItem value="area">Area</MenuItem>
              <MenuItem value="line">Line</MenuItem>
              <MenuItem value="bar">Bar</MenuItem>
              <MenuItem value="column">Column</MenuItem>
              <MenuItem value="pie">Pie</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl variant="standard">
            <RadioGroup
              row
              name="radio-group"
              defaultValue={
                chartOptions?.chart?.type === "pie"
                  ? "multipleDataSeries"
                  : "regular"
              }
              onChange={e => {
                if (e.target.value === "multipleChartLayers") {
                  setLayerConfig({
                    ...layerConfig,
                    enabled: e.target.checked,
                  });
                  setSeriesConfig({
                    ...seriesConfig,
                    enabled: false,
                  });
                }
                if (e.target.value === "multipleDataSeries") {
                  setSeriesConfig({
                    ...seriesConfig,
                    enabled: e.target.checked,
                  });
                  setLayerConfig({
                    ...layerConfig,
                    enabled: false,
                  });
                }
                if (e.target.value === "regular") {
                  setSeriesConfig({
                    ...seriesConfig,
                    enabled: false,
                  });
                  setLayerConfig({
                    ...layerConfig,
                    enabled: false,
                  });
                }
              }}
            >
              <FormControlLabel
                value="multipleDataSeries"
                control={<Radio />}
                label="Multiple Data Series"
              />
              <FormControlLabel
                value="multipleChartLayers"
                disabled={chartOptions?.chart?.type === "pie"}
                label="Multiple Chart Layers"
                control={<Radio />}
              />
              <FormControlLabel
                value="regular"
                label="Regular Chart"
                disabled={chartOptions?.chart?.type === "pie"}
                control={<Radio />}
              />
            </RadioGroup>
          </FormControl>
        </Grid>
      </Grid>
      <Grid container spacing={3} style={{ marginTop: 1 }}>
        <Grid item xs={6}>
          <FormControl variant="standard">
            <FormGroup>
              {(chartOptions.chart?.type === "column" ||
                chartOptions.chart?.type === "bar" ||
                chartOptions.chart?.type === "area") && (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        chartOptions.plotOptions?.[chartOptions.chart.type]
                          ?.stacking === "normal"
                      }
                      onChange={e => {
                        chartOptions.chart?.type &&
                          setOptions({
                            ...chartOptions,
                            plotOptions: {
                              ...chartOptions.plotOptions,
                              [chartOptions.chart.type]: {
                                ...chartOptions.plotOptions?.[
                                  chartOptions.chart.type as
                                    | "area"
                                    | "column"
                                    | "bar"
                                ],
                                stacking: e.target.checked
                                  ? "normal"
                                  : undefined,
                              },
                            },
                          });
                      }}
                      name="stacked"
                    />
                  }
                  label="Stacking"
                />
              )}
            </FormGroup>
          </FormControl>
        </Grid>
      </Grid>
      {!!seriesConfig?.enabled && (
        <SeriesConfig
          {...{
            headers,
            rows,
          }}
          seriesConfiguration={seriesConfig}
          setSeriesConfiguration={setSeriesConfig}
          chartOptions={chartOptions}
          setChartOptions={setOptions}
        />
      )}
      <Grid container style={{ marginTop: 10 }}>
        {layerConfig.enabled && (
          <LayerConfig
            {...{ headers, rows }}
            chartOptions={chartOptions}
            setChartOptions={setOptions}
            axisOptions={axisConfig}
            layerConfiguration={layerConfig}
            setLayerConfiguration={setLayerConfig}
          />
        )}
      </Grid>
      <Grid container style={{ marginTop: 25 }}>
        <Button
          color="primary"
          variant="contained"
          id="customAnalytics__refresh-chart"
          onClick={e => {
            const hcOptions = generateHCOptionsFromConfig({
              axisConfig,
              headers,
              rows,
              options: chartOptions,
              seriesConfig,
              layerConfig,
              asOfDate: formData?.asOfDate,
            });
            setOptions(hcOptions as Highcharts.Options);
            onChange(hcOptions);
          }}
        >
          Generate/Refresh Chart
        </Button>
      </Grid>
      <Grid container style={{ marginTop: 25 }}>
        {rows.length ? <CustomHighCharts chartOptions={chartOptions} /> : null}
      </Grid>
    </>
  );
}

export default CustomChart;
