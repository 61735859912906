import * as client from "_graphql-types";
import * as types from "./types";

import { filterSortPage } from "../DataProvider";

type Sdk = ReturnType<typeof client.getSdk>;

/*********************************************************************/
// Data Provider Functions
/*********************************************************************/

/*********************************************************************/
async function getList(
  sdk: Sdk,
  params: types.GetListParams<client.CompanyValuationSortEnum>
) {
  const { pagination, sort, filter } = params;

  const {
    companyValuationList: { items: data, total },
  } = await sdk.getListCompanyValuation(
    filterSortPage({ filter, sort, pagination })
  );

  return {
    data,
    total,
  };
}

/*********************************************************************/
async function getOne(sdk: Sdk, params: types.GetOneParams) {
  const id = Number(params.id);
  const { companyValuation: data } = await sdk.getOneCompanyValuation({
    id,
  });
  return {
    data,
  };
}

/*********************************************************************/
async function getMany(sdk: Sdk, params: types.GetManyParams) {
  const ids = params.ids.map(id => Number(id));

  const { companyValuationMany: data } = await sdk.getManyCompanyValuation({
    ids,
  });

  return {
    data,
  };
}

/*********************************************************************/
async function getManyReference(
  sdk: Sdk,
  params: types.GetManyReferenceParams<client.CompanyValuationSortEnum>
) {
  const id = params.id;
  const {
    pagination: { page, perPage },
    sort: { field, order },
    filter,
    target,
  } = params;

  const {
    companyValuationList: { items: data, total },
  } = await sdk.getListCompanyValuation({
    page: {
      offset: (page - 1) * perPage,
      limit: perPage,
    },
    sort: [{ field, order }],
    filter: { ...filter, [target]: id },
  });

  return {
    data,
    total,
  };
}

async function create(
  sdk: Sdk,
  params: types.CreateParams<
    client.CompanyValuation & { company: { id: string; name: string } }
  >
) {
  const { data } = params;

  const {
    addCompanyValuation: { id },
  } = await sdk.createCompanyValuation({
    input: {
      ...data,
      asOfDate: types.required(data, "asOfDate"),
      companyId: Number(types.required(data, "company").id),
    },
  });
  return await getOne(sdk, { id: `${id}` });
}

/*********************************************************************/
async function update(
  sdk: Sdk,
  params: types.UpdateParams<
    NonNullable<client.GetOneCompanyValuationQuery["companyValuation"]>
  >
): types.UpdateOut<any> {
  const id = Number(params.id);
  const { data } = params;
  const input: client.UpdateCompanyValuationMutationVariables["input"] = {
    companyId: types.required(data, "companyId"),
    valuation: data.valuation,
    asOfDate: types.required(data, "asOfDate"),
  };

  await sdk.updateCompanyValuation({
    id,
    input,
  });

  return await getOne(sdk, { id: `${id}` });
}

/*********************************************************************/
/*********************************************************************/
async function del(sdk: Sdk, params: types.DeleteParams): types.DeleteOut<any> {
  const id = Number(params.id);
  await sdk.deleteCompanyValuation({ id });
  return { data: { id } };
}

export const companyValuation = types.dataProvider({
  getList,
  getOne,
  getMany,
  getManyReference,
  create,
  update,
  delete: del,
});
