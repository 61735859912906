import Grid from "@mui/material/Grid";
import * as client from "_graphql-types";
import {
  Button,
  Link,
  TextInput,
  required,
  useRecordContext,
} from "react-admin";
import { CustomCreate } from "../CustomCreate";
import { EntityInput } from "../UI/EntityInput";

type Record = NonNullable<client.GetOneFamilyQuery["family"]>;

const validateRequired = [required()];

const ViewFirmButton = () => {
  const record = useRecordContext<Record>();
  return !record?.firm?.id ? null : (
    <Button
      component={Link}
      to={{
        pathname: `/firm/${record.firm.id}`,
      }}
      label="Go to Firm"
    />
  );
};

export const FamilyCreate = () => {
  return (
    <CustomCreate<Record>>
      <>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <ViewFirmButton />
          </Grid>
          <Grid item xs={6}></Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextInput
              label="Family Name"
              source="name"
              validate={validateRequired}
            />
          </Grid>
          <Grid item xs={6}>
            <Grid item xs={6}>
              <EntityInput<NonNullable<Record>>
                resource="firm"
                recordKey="firm"
                label="Firm"
                isRequired={true}
              />
            </Grid>
          </Grid>
        </Grid>
      </>
    </CustomCreate>
  );
};
