import React from "react";
import Grid from "@mui/material/Grid";
import { useWatch } from "react-hook-form";
import { BooleanInput, ReferenceInput, SelectInput } from "react-admin";
import { EntityInput } from "../UI/EntityInput";
import * as client from "_graphql-types";

type Record = NonNullable<client.GetOneInvestmentQuery["investment"]>;

export const AdvisorInvestment = ({
  canEditField,
}: {
  canEditField: (field: keyof client.InvestmentInput) => boolean;
}) => {
  const isSubAdvised = useWatch({ name: "isSubAdvised" });

  return (
    <Grid container spacing={2}>
      <Grid item xs={2}>
        <BooleanInput
          readOnly={!canEditField("isSubAdvised")}
          label="Is Sub-Advised"
          source="isSubAdvised"
        />
      </Grid>
      {isSubAdvised && (
        <>
          <Grid item xs={12} sm={5} md={3}>
            <ReferenceInput
              source="advisorTypeEnumId"
              reference="advisorTypeEnum"
            >
              <SelectInput
                disabled={!canEditField("advisorTypeEnumId")}
                optionText="name"
                label="Advisor Type"
              />
            </ReferenceInput>
          </Grid>
          <Grid item xs={12} sm={5} md={3}>
            <ReferenceInput
              source="accountTypeEnumId"
              reference="accountTypeEnum"
            >
              <SelectInput
                disabled={!canEditField("accountTypeEnumId")}
                optionText="name"
                label="Account Type"
              />
            </ReferenceInput>
          </Grid>
          <Grid item xs={12} md={4}>
            <EntityInput<NonNullable<Record>>
              resource="firm"
              recordKey="advisor"
              sourceKey="advisorId"
              label="Advisor"
              allowChange={canEditField("advisorId")}
            />
          </Grid>
        </>
      )}
    </Grid>
  );
};
