import * as client from "_graphql-types";

import { DateInput, ReferenceInput, required, SelectInput } from "react-admin";
import { CustomCreate } from "../CustomCreate";
import Grid from "@mui/material/Grid";
import { EntityInput } from "../UI/EntityInput";

type Record = NonNullable<client.GetOneFirmDeiQuery["firmDEI"]>;

const validateRequired = [required()];

export const FirmDEICreate = () => {
  return (
    <CustomCreate<Record> title={record => "Create Firm DEI"}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <EntityInput<NonNullable<Record>>
            resource="firm"
            recordKey="firm"
            label="Firm"
            isRequired={true}
            allowChange
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <DateInput
            label="As Of"
            source="asOfDate"
            validate={validateRequired}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <ReferenceInput
            reference="geographyEnum"
            sort={{ field: "name", order: "ASC" }}
            perPage={1000}
            source="geographyEnumId"
          >
            <SelectInput
              optionText="description"
              helperText="Geography of Team Members Indicated (Offices)"
              label="Geography"
              validate={validateRequired}
            />
          </ReferenceInput>
        </Grid>
      </Grid>
    </CustomCreate>
  );
};
