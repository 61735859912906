import { Grid } from "@mui/material";
import { GetListFirmEmploymentQuery } from "_graphql-types";
import { useEffect, useState } from "react";
import {
  ArrayInput,
  AutocompleteInput,
  BooleanInput,
  DateInput,
  FormDataConsumer,
  ReferenceInput,
  SimpleFormIterator,
  useDataProvider,
} from "react-admin";
import { useSearchParams } from "react-router-dom";
import {
  Option,
  Record,
} from "../../dataProvider/resources/investmentFirmEmploymentBulk";
import { CustomCreate } from "../CustomCreate";
import { withScopedForm } from "../react-admin-fixes";

export function InvestmentFirmEmploymentBulkCreate() {
  const [search] = useSearchParams();
  const [record] = useState<Record>(JSON.parse(search.get("source")!));

  return (
    <CustomCreate
      title={() => "Create Investment Assignments"}
      redirect={false}
      customFormProps={{
        customToolbarProps: {
          allowSave: "always",
          redirectToPathOnSave: `/investment/${record.investment.id}`,
        },
      }}
    >
      <InvestmentFirmEmploymentBulkCreateForm record={record} />
    </CustomCreate>
  );
}

type FirmEmployment =
  GetListFirmEmploymentQuery["firmEmploymentList"]["items"][number];

interface FormProps {
  record: Record;
}

/*
 * https://github.com/marmelab/react-admin/issues/7655#issuecomment-1123836850
 */

export function InvestmentFirmEmploymentBulkCreateForm({ record }: FormProps) {
  const [defaultOptions, setDefaultOptions] = useState<Option[]>();
  const dataProvider = useDataProvider();

  useEffect(() => {
    dataProvider
      .getList<FirmEmployment>("firmEmployment", {
        filter: { targetInvestmentId: record.investment.id, isCurrent: true },
        sort: { field: "personName", order: "ASC" },
        pagination: { page: 1, perPage: 100 },
      })
      .then(({ data }) => {
        const options = data.map(item => ({
          firmEmploymentId: item.id,
          personName: `${item.person.lastName}, ${
            item.person.firstName ?? ""
          } ${item.person.middleName ?? ""}`.trim(),
          checked: false,
          faceOfInvestment: false,
          employeeRoleId: null,
          startDate: null,
          leftDate: null,
        }));
        setDefaultOptions(options);
      });
  }, [record]);

  return !defaultOptions ? null : (
    <ArrayInput
      label="Available Firm Employments"
      source="options"
      defaultValue={defaultOptions}
    >
      <SimpleFormIterator
        disableAdd
        disableClear
        disableRemove
        disableReordering
        fullWidth
      >
        <FormDataConsumer>
          {withScopedForm<Option, Record>(
            ({ scopedFormData: option, formData, ...rest }) =>
              option && (
                <Grid
                  container
                  spacing={2}
                  sx={{
                    marginTop: "1px",
                    backgroundColor: option.checked ? "#efefef" : "initial",
                  }}
                >
                  <Grid item xs={1}>
                    <BooleanInput
                      {...rest}
                      label=""
                      source="checked"
                      data-cy="checked"
                    />
                  </Grid>
                  <Grid item xs={3} data-cy="personName">
                    {option.personName}
                  </Grid>
                  <Grid item xs={2}>
                    <ReferenceInput
                      {...rest}
                      label="Employee Role"
                      source="employeeRoleId"
                      reference="investmentFirmEmploymentRoleEnum"
                      sort={{ field: "name", order: "ASC" }}
                      perPage={100}
                    >
                      <AutocompleteInput
                        optionText="name"
                        label="Employee Role"
                        disabled={!option.checked}
                        data-cy="employeeRoleId"
                      />
                    </ReferenceInput>
                  </Grid>
                  <Grid item xs={2}>
                    <DateInput
                      {...rest}
                      label="Start Date"
                      source="startDate"
                      disabled={!option.checked}
                      data-cy="startDate"
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <DateInput
                      {...rest}
                      label="End Date"
                      source="leftDate"
                      disabled={!option.checked}
                      data-cy="leftDate"
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <BooleanInput
                      {...rest}
                      label="Face of Investment"
                      source="faceOfInvestment"
                      disabled={
                        !option.checked ||
                        // at most one can be selected
                        (!option.faceOfInvestment &&
                          formData.options?.some(x => x.faceOfInvestment))
                      }
                      data-cy="faceOfInvestment"
                    />
                  </Grid>
                </Grid>
              )
          )}
        </FormDataConsumer>
      </SimpleFormIterator>
    </ArrayInput>
  );
}
