import { Datagrid, EditButton, List, TextField } from "react-admin";
import { CompanyFilter } from "./Filter";
import { ListActions } from "./ListActions";

export const CompanyList = (props: any) => {
  return (
    <List
      sx={{
        ".RaFilter-form": {
          flex: "0 1 50%",
        },
      }}
      filters={<CompanyFilter />}
      sort={{ field: "nameSearchRank", order: "ASC" }}
      actions={<ListActions />}
      {...props}
    >
      <Datagrid
        data-cy="company__list"
        rowClick="edit"
        bulkActionButtons={false}
      >
        <TextField label="ID" source="id" />
        <TextField data-cy="company__name" label="Name" source="name" />
        <EditButton />
      </Datagrid>
    </List>
  );
};
