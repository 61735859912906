import {
  Datagrid,
  EditButton,
  List,
  TextField,
  TopToolbar,
  CreateButton,
  ExportButton,
  SortButton,
  BooleanField,
} from "react-admin";
import React from "react";
import { ServiceProviderFirmFilter } from "./Filter";

export const ServiceProviderFirmList = () => {
  return (
    <List
      sx={{
        ".RaFilter-form": {
          flex: "0 1 50%",
        },
      }}
      filters={<ServiceProviderFirmFilter />}
      sort={{ field: "nameSearchRank", order: "ASC" }}
      actions={
        <TopToolbar>
          <SortButton fields={["nameSearchRank", "name"]} />
          <CreateButton />
          <ExportButton maxResults={250}/>
        </TopToolbar>
      }
    >
      <Datagrid rowClick="edit" bulkActionButtons={false}>
        <TextField label="Name" source="name" />
        <BooleanField
          label="Evaluating"
          source="isEvaluating"
          sortable={false}
        />
        <EditButton />
      </Datagrid>
    </List>
  );
};
