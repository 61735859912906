import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
  Grid,
  MenuItem,
  Switch,
  FormControlLabel,
  TextField,
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import {
  LayerConfiguration,
  AxisConfiguration,
  updateYAxisConfig,
  getLabelFormatString,
} from "./helpers";
import { getDataColumnOptions } from "./AxisConfig";

interface LayerConfigProps {
  headers: string[];
  rows: (string | number | null)[][];
  setLayerConfiguration: Dispatch<SetStateAction<LayerConfiguration>>;
  layerConfiguration: LayerConfiguration;
  axisOptions: AxisConfiguration;
  setChartOptions: Dispatch<SetStateAction<any>>;
  chartOptions: any;
}

export function LayerConfig({
  headers,
  rows,
  setLayerConfiguration,
  layerConfiguration,
  axisOptions,
  setChartOptions,
  chartOptions,
}: LayerConfigProps) {
  const [labelFormat, setLabelFormat] = useState<{
    prefix?: string;
    suffix?: string;
  }>({});

  useEffect(() => {
    const formatString = getLabelFormatString(labelFormat);

    updateYAxisConfig(
      { labels: { format: formatString } },
      1,
      layerConfiguration,
      chartOptions,
      setChartOptions
    );
  }, [labelFormat]);
  return (
    <>
      <Grid item xs={3}>
        <h4>Layer Options</h4>
      </Grid>
      <Grid
        data-cy="customAnalytics__layer-options"
        container
        spacing={2}
        style={{ marginTop: 5 }}
      >
        <Grid item xs={3}>
          <FormControl variant="standard" fullWidth>
            <InputLabel id="chart-type__label" variant="outlined" required>
              Chart Type
            </InputLabel>
            <Select
              required
              id="chart-type"
              labelId="chart-type__label"
              label="Chart Type"
              placeholder="Chart Type"
              variant="outlined"
              value={layerConfiguration.chartType ?? null}
              onChange={e => {
                setLayerConfiguration({
                  ...layerConfiguration,
                  chartType: e.target.value ?? "",
                });
              }}
            >
              <MenuItem value="area">Area</MenuItem>
              <MenuItem value="line">Line</MenuItem>
              <MenuItem value="scatter">Scatter</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={2}>
          <FormControlLabel
            control={
              <Switch
                id="second-y-axis"
                onChange={e => {
                  setLayerConfiguration({
                    ...layerConfiguration,
                    yAxis2: e.target.checked,
                  });
                }}
              />
            }
            label="Second Y Axis"
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} style={{ marginTop: 5 }}>
        {layerConfiguration.yAxis2 && (
          <Grid item xs={4}>
            <FormControl variant="standard" fullWidth>
              <InputLabel
                id="layer-cross-axis-type__label"
                variant="outlined"
                shrink
                required
              >
                Axis Type
              </InputLabel>
              <Select
                required
                id="layer-cross-axis-type"
                labelId="layer-cross-axis-type__label"
                label="Axis Type"
                placeholder="Axis Type"
                value={layerConfiguration.yAxis2Config?.type}
                variant="outlined"
                onChange={e => {
                  setLayerConfiguration({
                    ...layerConfiguration,
                    yAxis2Config: {
                      ...layerConfiguration.yAxis2Config,
                      type: e.target.value as Highcharts.AxisTypeValue,
                    },
                  });
                  const value = e.target.value as Highcharts.AxisTypeValue;
                  updateYAxisConfig(
                    { type: value },
                    1,
                    layerConfiguration,
                    chartOptions,
                    setChartOptions
                  );
                }}
              >
                <MenuItem value="linear">Linear</MenuItem>
                <MenuItem value="datetime">Datetime</MenuItem>
                <MenuItem value="logarithmic">Logarithmic</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        )}
        {rows.length ? (
          <Grid item xs={4} style={{ alignSelf: "center" }}>
            <FormControl variant="standard" fullWidth>
              <InputLabel
                id="layer-cross-axis-data-column__label"
                variant="outlined"
                shrink
                required
              >
                Data Column
              </InputLabel>
              <Select
                required
                id="layer-cross-axis-data-column"
                labelId="layer-cross-axis-data-column__label"
                label="Data Column"
                placeholder="Data Column"
                value={layerConfiguration?.dataColumn}
                variant="outlined"
                onChange={e => {
                  if (layerConfiguration.yAxis2) {
                    setLayerConfiguration({
                      ...layerConfiguration,
                      yAxis2Config: {
                        ...layerConfiguration.yAxis2Config,
                        dataColumn: e.target.value,
                      },
                      dataColumn: e.target.value,
                    });
                  } else {
                    setLayerConfiguration({
                      ...layerConfiguration,
                      dataColumn: e.target.value,
                    });
                  }
                }}
              >
                {getDataColumnOptions(
                  headers,
                  rows,
                  axisOptions.crossAxis.type
                ).map(header => (
                  <MenuItem key={header} value={header}>
                    {header}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        ) : null}
        <Grid item xs={3}>
          <TextField
            fullWidth
            variant="outlined"
            id="layer-cross-axis-title"
            label="Axis Title"
            onChange={e => {
              updateYAxisConfig(
                { title: { text: e.target.value } },
                1,
                layerConfiguration,
                chartOptions,
                setChartOptions
              );
            }}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} style={{ marginTop: 10 }}>
        <Grid item xs={4}>
          <TextField
            fullWidth
            variant="outlined"
            id="layer-cross-axis-datalabel-prefix"
            label="Datalabel Prefix (eg. $)"
            onChange={e =>
              setLabelFormat({ ...labelFormat, prefix: e.target.value })
            }
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            fullWidth
            variant="outlined"
            id="layer-cross-axis-datalabel-suffix"
            label="Datalabel Suffix (eg. km, b)"
            onChange={e =>
              setLabelFormat({ ...labelFormat, suffix: e.target.value })
            }
          />
        </Grid>
      </Grid>
    </>
  );
}
