function getConfig(
  key: string,
  required?: boolean
): string | (typeof required extends false ? undefined : never);
function getConfig(key: string, required = true) {
  const value: string | undefined = (window as any)[key];
  if (required && !value) {
    throw new Error(`Missing config '${key}'`);
  }
  return value;
}

export class Config {
  GRAPHQL_API() {
    return getConfig("GRAPHQL_API");
  }
  OKTA_ISSUER() {
    return getConfig("OKTA_ISSUER");
  }
  OKTA_CLIENT_ID() {
    return getConfig("OKTA_CLIENT_ID");
  }
  DEPLOYMENT_ENV() {
    return getConfig("DEPLOYMENT_ENV");
  }
  SENTRY_ENVIRONMENT() {
    return getConfig("SENTRY_ENVIRONMENT");
  }
  SENTRY_RELEASE() {
    return getConfig("SENTRY_RELEASE", false);
  }
  SENTRY_DSN() {
    return getConfig("SENTRY_DSN", false);
  }
  SENTRY_TUNNEL() {
    const url = this.GRAPHQL_API();
    const apiGateway = url.slice(0, url.lastIndexOf("/graphql"));
    return apiGateway + "/sentry";
  }
  POOL_CLIENT_ID() {
    return getConfig("POOL_CLIENT_ID");
  }
  POOL_ID() {
    return getConfig("POOL_ID");
  }
  POOL_REGION() {
    return getConfig("POOL_REGION");
  }
  PORTAL_URL() {
    return getConfig("PORTAL_URL");
  }
  RCG_SIRIUS_WS_URI() {
    return getConfig("RCG_SIRIUS_WS_URI");
  }
  SIRIUS_URL() {
    return getConfig("SIRIUS_URL");
  }
}

class ConfigWrapper extends Config {
  has(key: keyof Config) {
    return !!(window as any)[key];
  }
}

const config = new ConfigWrapper();

export default config;
