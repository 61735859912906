import {
  Confirm,
  DeleteButton,
  Identifier,
  RaRecord,
  RedirectionSideEffect,
  SaveButton,
  setSubmissionErrors,
  SimpleForm,
  SimpleFormProps,
  Toolbar,
  ToolbarProps,
  useNotify,
  useRecordContext,
  useRedirect,
  useRefresh,
  useResourceDefinition,
  useSaveContext,
} from "react-admin";
import { useFormContext, useFormState, useWatch } from "react-hook-form";

import PriorityHighIcon from "@mui/icons-material/PriorityHigh";

import { Chip, CircularProgress, IconButton, Tooltip } from "@mui/material";
import { isDefinedAndNotNull } from "frontend/src/utils/helpers";
import React, { ComponentProps, ReactNode, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { NestProps } from "./react-admin-fixes";
import { RegisterFormMeta } from "./RegisterFormMeta";

function DisplayErrors({ errors }: { errors: { [key: string]: any } }) {
  let errorMessages: string[] = [];
  const stack = [errors];

  //recurse errors object to get all error messages
  while (stack?.length > 0) {
    const currentObj = stack.pop();
    if (!currentObj) continue;
    Object.entries(currentObj).forEach(([key, value]) => {
      if (value.message) {
        errorMessages.push(value.message);
      } else if (typeof value === "object") {
        stack.push(value);
      }
    });
  }

  const uniqueErrorMessages = [...new Set(errorMessages)];

  return (
    <>
      <p>
        {errorMessages.length} error{errorMessages.length > 1 && "s"} found in
        form:
      </p>
      {uniqueErrorMessages.splice(0, 3).map(message => (
        <p>{message}</p>
      ))}
    </>
  );
}

const getToolbarStyles = (props: {
  hasOverflow: boolean;
  formHeight: number;
  formWidth: number;
}) =>
  props.hasOverflow
    ? {
        flex: 1,
        display: "flex",
        justifyContent: "space-between",
        position: "fixed",
        bottom: 0,
        width: props.formWidth,
        zIndex: 3,
      }
    : {
        flex: 1,
        display: "flex",
        justifyContent: "space-between",
      };

const ViewOnlyIndicator = ({
  canAccessMutation,
  loading,
}: {
  canAccessMutation: boolean;
  loading: boolean;
}) => {
  return loading ? (
    <CircularProgress data-cy="circularProgress_viewonlyIndicator_loading" />
  ) : !canAccessMutation ? (
    <Chip label="View Only" color="primary" />
  ) : null;
};

export type DeleteButtonRedirect<TRecord extends RaRecord> = (
  record: TRecord
) => RedirectionSideEffect;

type CustomToolbarProps<TRecord extends RaRecord> = NestProps<
  {
    /** Enables the save button */
    allowSave?: boolean | "always";
    /** Enables the delete button */
    allowDelete?: boolean;
    /** Enables the view only indicator */
    canAccessMutation?: boolean;
    /** Enables the loading indicator */
    loading?: boolean;
    /** The title of the record */
    title?: string | null;
    /** The path to redirect to on save */
    redirectToPathOnSave?: string;
    /** The redirect action on delete */
    deleteButtonRedirect?: DeleteButtonRedirect<TRecord>;
    /** Enables the confirm dialog on save */
    confirmOnSave?: boolean;
    /** Renders a custom confirmation message */
    getConfirmationMessage?: ({
      saveParams,
      touched,
    }: {
      saveParams?: any;
      touched?: TouchedState<any>;
    }) => React.ReactNode;
  },
  "toolbarProps",
  ToolbarProps
>;

export const CustomToolbar = <TRecord extends RaRecord>({
  allowSave,
  allowDelete,
  canAccessMutation,
  loading,
  title,
  redirectToPathOnSave,
  deleteButtonRedirect,
  confirmOnSave,
  getConfirmationMessage,
  toolbarProps,
}: CustomToolbarProps<TRecord>) => {
  const redirect = useRedirect();
  const navigate = useNavigate();

  const { name: resourceName } = useResourceDefinition();
  const notify = useNotify();
  const refresh = useRefresh();
  const record = useRecordContext<TRecord>();

  const formEl: Element | undefined = document.getElementById("main-content")
    ?.firstChild as Element;

  const [hasOverflow, setHasOverflow] = useState(
    formEl?.clientHeight >= document.body.clientHeight
  );

  useEffect(() => {
    setHasOverflow(formEl?.clientHeight >= document.body.clientHeight);
  }, [formEl?.clientHeight, document.body.clientHeight]);

  const [openTooltip, setOpenTooltip] = useState(false);
  const [open, setOpen] = useState(false);

  const toolbarStyles = getToolbarStyles({
    hasOverflow,
    formHeight: formEl?.clientHeight,
    formWidth: formEl?.clientWidth,
  });

  const { save } = useSaveContext();
  if (!save) {
    throw new Error("save is undefined");
  }

  const { dirtyFields, errors } = useFormState();
  const { setError, handleSubmit } = useFormContext();

  const saveParams = useWatch();

  const confirmationMessage = getConfirmationMessage?.({
    saveParams: saveParams?.[0],
    touched: dirtyFields,
  });

  useEffect(() => {
    const t1 = setTimeout(() => {
      setHasOverflow(formEl?.clientHeight >= document.body.clientHeight);
      clearTimeout(t1);
    }, 0);
  }, []);

  const mutationOptions = {
    ...(redirectToPathOnSave && {
      onSuccess: (data: any) => {
        // without the refresh it will redirect before the side effects finish
        // suggested in the documentation as part of onSuccess handler
        refresh();
        notify("ra.notification.created", {
          type: "info",
          messageArgs: { smart_count: 1 },
        });
        if (redirectToPathOnSave === "edit") {
          redirect("edit", `/${resourceName}`, data.id);
        } else {
          navigate(redirectToPathOnSave);
        }
      },
    }),
  };

  const handleConfirm = (event: any) => {
    setOpen(false);

    handleSubmit(async values => {
      // mimic saveButton behavior..
      const errors = await save(values, mutationOptions);
      if (errors) {
        setSubmissionErrors(errors, setError);
      }
    })(event);
  };

  const actuallyConfirmOnSave = confirmOnSave && !!confirmationMessage;

  return (
    <Toolbar {...toolbarProps} sx={toolbarStyles}>
      <div>
        {Object.keys(errors).length > 0 && (
          <Tooltip
            title={<DisplayErrors errors={errors} />}
            placement="top-start"
            disableFocusListener
            disableHoverListener
            disableTouchListener
            onClose={() => setOpenTooltip(false)}
            open={openTooltip}
          >
            <IconButton
              color={"error"}
              onClick={() => setOpenTooltip(!openTooltip)}
            >
              <PriorityHighIcon fontSize={"medium"} />
            </IconButton>
          </Tooltip>
        )}
        <SaveButton
          data-cy="submit"
          disabled={!allowSave}
          alwaysEnable={allowSave === "always"}
          type="button"
          color={Object.keys(errors).length > 0 ? "warning" : "primary"}
          mutationOptions={mutationOptions}
          onClick={
            actuallyConfirmOnSave
              ? e => {
                  e.preventDefault();
                  setOpen(true);
                }
              : undefined
          }
        />
      </div>

      {isDefinedAndNotNull(canAccessMutation) &&
        isDefinedAndNotNull(loading) && (
          <ViewOnlyIndicator
            canAccessMutation={canAccessMutation}
            loading={loading}
          />
        )}

      {actuallyConfirmOnSave && (
        <Confirm
          isOpen={open}
          data-cy="confirm-dialog"
          title="Confirm Before Save"
          content={confirmationMessage}
          onConfirm={handleConfirm}
          onClose={() => setOpen(false)}
          confirm="Continue"
          cancel="Cancel"
        />
      )}
      <DeleteButton<TRecord>
        disabled={!allowDelete}
        mutationMode="pessimistic"
        confirmTitle={!!title ? `Delete ${title}` : undefined}
        redirect={record && deleteButtonRedirect?.(record)}
      />
    </Toolbar>
  );
};

export type TouchedState<T> = {
  [Property in keyof T]: boolean;
};

export type CustomFormProps<TRecord extends RaRecord> = NestProps<
  {
    children: ReactNode;
    hideToolbar?: boolean;
    title?: (record: TRecord) => string | null;
    loading?: boolean;
    canEditField?: { [key: string]: boolean };
  },
  "simpleFormProps",
  SimpleFormProps
> &
  NestProps<
    {},
    "customToolbarProps",
    Omit<CustomToolbarProps<TRecord>, "title" | "loading">
  >;

export function CustomForm<TRecord extends RaRecord>(
  props: CustomFormProps<TRecord>
) {
  const [params] = useSearchParams();
  const redirect = params.get("redirect");

  const {
    hideToolbar,
    title,
    children,
    simpleFormProps,
    customToolbarProps,
    loading,
    canEditField,
  } = props;

  const {
    allowSave = true,
    allowDelete = false,
    // variant,
    redirectToPathOnSave,
    confirmOnSave = false,
    canAccessMutation,
    getConfirmationMessage,
    deleteButtonRedirect,
  } = customToolbarProps ?? {};

  const formEl: Element | undefined = document.getElementById("main-content")
    ?.firstChild as Element;

  const hasOverflow = formEl?.clientHeight >= document.body.clientHeight;

  const { save } = useSaveContext();

  if (!save) throw new Error("Save context not found");

  const record = useRecordContext<TRecord>();

  return (
    <SimpleForm
      {...simpleFormProps}
      margin="none"
      onSubmit={save as any}
      style={hasOverflow ? { paddingBottom: 50 } : void 0}
      toolbar={
        !hideToolbar && (
          <CustomToolbar<TRecord>
            {...{
              allowSave,
              allowDelete,
              canAccessMutation,
              loading,
              redirectToPathOnSave: redirect ?? redirectToPathOnSave,
              getConfirmationMessage,
              confirmOnSave,
              deleteButtonRedirect,
            }}
            title={record && title?.(record)}
          />
        )
      }
    >
      <RegisterFormMeta fieldAccess={canEditField} />
      {loading ? (
        <CircularProgress data-cy="circularProgres__loadingAccess" />
      ) : (
        children
      )}
    </SimpleForm>
  );
}
