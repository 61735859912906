import { Box, InputLabel, Tooltip, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import { EditRowButton } from "@react-admin/ra-editable-datagrid";
import * as client from "_graphql-types";
import { NO_WHITESPACE_REGEX, UUID_REGEX } from "frontend/src/utils/constants";
import React, { useEffect, useMemo, useState } from "react";
import {
  AutocompleteInput,
  BooleanField,
  BooleanInput,
  Button,
  Datagrid,
  DateField,
  DateInput,
  DeleteButton,
  EditButton,
  FunctionField,
  NumberInput,
  Pagination,
  ReferenceInput,
  ReferenceManyField,
  SelectInput,
  TextField,
  TextInput,
  regex,
  required,
  useGetList,
  useGetRecordId,
  useRecordContext,
} from "react-admin";
import { FieldValues, useFormContext, useWatch } from "react-hook-form";
import { Link } from "react-router-dom";
import { useCanAccessMutation } from "../../util/useCanAccessMutation";
import { Authorized } from "../Authorized";
import CustomBreadCrumb from "../CustomBreadCumb";
import { CustomEdit } from "../CustomEdit";
import CustomInvestmentTagsInput from "../CustomInvestmentTagsInput";
import { FaceOfEntityTooltip } from "../FaceOfEntityTooltip";
import { EntityInput } from "../UI/EntityInput";
import { FDPEditableDataGrid } from "../fdpLogin/DataGrid";
import { AdvisorInvestment } from "./AdvisorInvestment";
import { FundStructure } from "./FundStructure";
import { ImpactSelection } from "./ImpactSelect";
import InvestmentFormDecorators from "./InvestmentFormDecorator";
import { PrivatesSpecificFields } from "./PrivateMarketFields";
import { PublicMarketFields } from "./PublicMarketFields";
import {
  LLMBGColor,
  LLMBGColor__AutoComplete,
  LLMColor__Switch,
  getInvestmentConfirmationMessage,
  marketTranslation,
  removeFromAutoPopulatedFieldsOnTouch,
} from "./util";
import { RichTextInput } from "../RichTextInput";
import BuildInvestmentProfile, { TempFile } from "../LLMProfileBuilderButton";
import TrackChangesPanel, {
  TFormHistory,
} from "../LLMProfileBuilderButton/TrackChanges";

export type Record = NonNullable<client.GetOneInvestmentQuery["investment"]>;

const validateRequired = [required()];
const validateRequiredNoWhitespace = [
  required(),
  regex(NO_WHITESPACE_REGEX, "Please remove leading and trailing spaces"),
];
const validateUUid = [regex(UUID_REGEX, "Invalid UUID")];

interface Props {
  canEditField(field: string): boolean;
}

const ReportingSettings = (props: Props) => {
  return (
    <Authorized checks={["isReportingUser"]}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <hr />
          <h3>Reporting Settings</h3>
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <DateInput
            disabled={!props.canEditField("reportingDate")}
            label="Reporting Date"
            source="reportingDate"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextInput
            disabled={!props.canEditField("reportingDateReason")}
            label="Reporting Date Reason"
            source="reportingDateReason"
            fullWidth
            multiline
          />
        </Grid>
      </Grid>
    </Authorized>
  );
};

const FamilyButton = (props: any) => {
  const record = props.record as Record;
  return !record?.family?.id ? null : (
    <Button
      component={Link}
      to={{
        pathname: `/family/${record.family.id}`,
      }}
      label="Go to Family"
      data-id="family"
    />
  );
};

const CreateInvestmentAxysCodeButton = () => {
  const record = useRecordContext<Record>();

  return !record ? null : (
    <Button
      component={Link}
      variant="contained"
      to={{
        pathname: "/investmentAxysCode/create",
        search: `?source=${JSON.stringify({
          investment: {
            id: record.id,
            name: record.name ? encodeURIComponent(record.name) : "",
          },
        })}`,
      }}
      label="Add Axys Code"
      data-id="createInvestmentAxysCode"
      data-cy="createInvestmentAxysCode__button"
    />
  );
};

const CreateInvestmentFirmEmploymentBulkButton = () => {
  const record = useRecordContext<Record>();
  return !record ? null : (
    <Button
      component={Link}
      to={{
        pathname: "/investmentFirmEmploymentBulk/create",
        search: `?source=${encodeURIComponent(
          JSON.stringify({
            investment: {
              id: record.id,
              name: record.name,
            },
          })
        )}`,
      }}
      label="Add Investment Assignments"
      title="Add investment assignments from existing firm employments"
      data-cy="createInvestmentFirmEmploymentBulk"
    />
  );
};

const FDPLoginActions = ({ investmentId }: { investmentId: number }) => (
  <>
    <EditRowButton />
  </>
);

const CreateRelatedInvestmentFDPLoginButton = () => {
  const record = useRecordContext<Record>();
  return !record ? null : (
    <Button
      component={Link}
      to={{
        pathname: "/investmentFDPLogins/create",
        search: `?source=${JSON.stringify({
          investment: {
            id: record.id,
            name: record.name ? encodeURIComponent(record.name) : "",
          },
        })}`,
      }}
      label="Link Existing FDP Login"
      title="Link existing FDP Login record to this investment"
      data-id="createInvestmentFDPLogin"
    />
  );
};

const InvestmentEditForm = ({
  firmInvestments,
  setFirmId,
  fieldAccessMap,
  canEdit,
  loading,
  autoPopulatedFields,
  setAutoPopulatedFields,
  tempFiles,
  setTempFiles,
}: {
  firmInvestments?: client.Investment[];
  setFirmId: React.Dispatch<React.SetStateAction<number | undefined>>;
  fieldAccessMap: { [key: string]: boolean };
  canEdit: boolean;
  loading: boolean;
  autoPopulatedFields: string[];
  setAutoPopulatedFields: React.Dispatch<React.SetStateAction<string[]>>;
  tempFiles?: TempFile[];
  setTempFiles: React.Dispatch<React.SetStateAction<TempFile[] | undefined>>;
}) => {
  const [fieldHistory, setFieldHistory] = useState<TFormHistory | undefined>();
  const [openTrackChanges, setOpenTrackChanges] = useState<boolean>(false);

  const [
    firm,
    vehicleTypeEnumId,
    isLiquidated,
    marketEnumId,
    strategyEnumId,
    assetClassEnumId,
  ] = useWatch({
    name: [
      "firm",
      "vehicleTypeEnumId",
      "isLiquidated",
      "marketEnumId",
      "strategyEnumId",
      "assetClassEnumId",
    ],
  });

  const { trigger, formState, reset, setValue } = useFormContext();

  useEffect(() => {
    removeFromAutoPopulatedFieldsOnTouch({
      autoPopulatedFields,
      formState,
      setAutoPopulatedFields,
    });
  }, [formState]);

  const record = useRecordContext<Record>();
  if (!record) return;

  useEffect(() => {
    setFirmId(firm?.id);
  }, [firm]);

  function canEditField(field: string) {
    return canEdit && fieldAccessMap[field];
  }

  return (
    <>
      <TrackChangesPanel
        formHistory={fieldHistory}
        autoPopulatedFields={autoPopulatedFields}
        open={openTrackChanges}
        setOpen={setOpenTrackChanges}
      >
        <InvestmentFormDecorators />
        <Grid container>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <EntityInput<NonNullable<Record>>
                resource="firm"
                recordKey="firm"
                label={firm?.isCompany ? "Company" : "Firm"}
                isRequired={true}
                allowChange={canEditField("firmId")}
                filters={{ includeCompanies: true }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <BuildInvestmentProfile
                tempFiles={tempFiles}
                setTempFiles={setTempFiles}
                trigger={trigger}
                reset={reset}
                setAutoPopulatedFields={setAutoPopulatedFields}
                setValue={setValue}
                setFieldHistory={setFieldHistory}
                fieldHistory={fieldHistory}
                isUpdate
                setOpenTrackChanges={setOpenTrackChanges}
                autoPopulatedFields={autoPopulatedFields}
                openTrackChanges={openTrackChanges}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextInput
                disabled={!canEditField("name")}
                label="Investment Name"
                source="name"
                validate={validateRequiredNoWhitespace}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextInput
                disabled={!canEditField("shortName")}
                label="Short Name"
                source="shortName"
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <ReferenceInput
                source="listEnumId"
                reference="investmentListEnum"
                sort={{ field: "name", order: "ASC" }}
                filter={{ availableOnCreation: true }}
              >
                <SelectInput
                  optionText="name"
                  disabled={!canEditField("listEnumId")}
                  label="Investment List"
                  validate={validateRequired}
                />
              </ReferenceInput>
            </Grid>
            <Grid item xs={12} sm={6} lg={2}>
              <DateInput
                disabled={!canEditField("inceptionDate")}
                label="Inception Date"
                source="inceptionDate"
              />
            </Grid>
            <Grid item xs={3}>
              <NumberInput
                disabled={!canEditField("HFRIInvestmentId")}
                label="HFRI ID"
                source="HFRIInvestmentId"
              />
            </Grid>
            <Grid item xs={3}>
              <NumberInput
                disabled={!canEditField("evestmentId")}
                label="Evestment ID"
                source="evestmentId"
              />
            </Grid>
            <Grid item xs={3}>
              <TextInput
                disabled={!canEditField("burgissId")}
                label="Burgiss ID"
                source="burgissId"
                validate={validateUUid}
              />
            </Grid>
            <Grid item xs={3}>
              <TextInput
                disabled={!canEditField("RICId")}
                label="RIC ID"
                source="RICId"
              />
            </Grid>
            <Grid item xs={3}>
              <TextInput
                disabled
                label="Bloomberg Code"
                source="bloombergCode"
              />
            </Grid>
          </Grid>
          <Grid style={{ paddingBottom: "20px" }} item xs={12}>
            <FamilyButton />
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} lg={3}>
              <ReferenceInput
                source="marketEnumId"
                reference="marketEnum"
                sort={{ field: "displayOrder", order: "ASC" }}
              >
                <SelectInput
                  optionText={(record: client.AssetClassEnum) =>
                    marketTranslation(record.id)
                  }
                  label="Market"
                  validate={validateRequired}
                  sx={
                    autoPopulatedFields.includes("marketEnumId")
                      ? LLMBGColor
                      : undefined
                  }
                  disabled={!canEditField("marketEnumId")}
                />
              </ReferenceInput>
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <ReferenceInput
                source="vehicleTypeEnumId"
                reference="vehicleTypeEnum"
                sort={{ field: "vehicleType", order: "ASC" }}
              >
                <SelectInput
                  optionText="name"
                  label="Vehicle Type"
                  validate={validateRequired}
                  disabled={!canEditField("vehicleTypeEnumId")}
                  sx={
                    autoPopulatedFields.includes("vehicleTypeEnumId")
                      ? LLMBGColor
                      : undefined
                  }
                />
              </ReferenceInput>
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <ReferenceInput
                source="assetClassEnumId"
                reference="assetClassEnum"
                sort={{ field: "assetClass", order: "ASC" }}
                filter={{
                  ...(vehicleTypeEnumId && {
                    vehicleTypeId: vehicleTypeEnumId,
                  }),
                }}
              >
                <SelectInput
                  optionText="name"
                  disabled={
                    !canEditField("assetClassEnumId") ||
                    vehicleTypeEnumId === null ||
                    vehicleTypeEnumId === undefined
                  }
                  label="Asset Class"
                  validate={validateRequired}
                  sx={
                    autoPopulatedFields.includes("assetClassEnumId")
                      ? LLMBGColor
                      : undefined
                  }
                />
              </ReferenceInput>
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <ReferenceInput
                source="strategyEnumId"
                reference="strategyEnum"
                sort={{ field: "strategy", order: "ASC" }}
                filter={{
                  ...(vehicleTypeEnumId && {
                    vehicleTypeId: vehicleTypeEnumId,
                  }),
                  ...(assetClassEnumId && {
                    assetClassId: assetClassEnumId,
                  }),
                }}
              >
                <SelectInput
                  optionText="name"
                  disabled={
                    !canEditField("strategyEnumId") ||
                    assetClassEnumId === null ||
                    assetClassEnumId === undefined
                  }
                  label="Strategy"
                  validate={validateRequired}
                  sx={
                    autoPopulatedFields.includes("strategyEnumId")
                      ? LLMBGColor
                      : undefined
                  }
                />
              </ReferenceInput>
            </Grid>
            <Grid item xs={6} lg={3}>
              <ReferenceInput
                disable={!canEditField("subStrategyEnumId")}
                source="subStrategyEnumId"
                reference="subStrategyEnum"
                sort={{ field: "name", order: "ASC" }}
                filter={{
                  strategyId: strategyEnumId ?? -1,
                }}
              >
                <SelectInput
                  optionText="name"
                  disabled={
                    !canEditField("subStrategyEnumId") ||
                    assetClassEnumId === null ||
                    assetClassEnumId === undefined
                  }
                  label="Breakdown"
                />
              </ReferenceInput>
            </Grid>
            {assetClassEnumId === 2 && (
              <Grid item xs={12} sm={6} lg={3}>
                <ReferenceInput
                  disable={!canEditField("peStrategyEnumId")}
                  source="peStrategyEnumId"
                  reference="peStrategyEnum"
                  sort={{ field: "name", order: "ASC" }}
                >
                  <SelectInput
                    fullWidth={true}
                    optionText="name"
                    disabled={
                      !canEditField("peStrategyEnumId") ||
                      assetClassEnumId === null ||
                      assetClassEnumId === undefined
                    }
                    label="PE Strategy"
                  />
                </ReferenceInput>
              </Grid>
            )}
            <Grid item xs={4} sm={6} lg={3}>
              <ReferenceInput
                source="primaryAssignment"
                reference="investmentAssignmentOption"
                perPage={100}
                sort={{ field: "commonName", order: "ASC" }}
                filter={{ isPrimary: true }}
              >
                <AutocompleteInput
                  optionText="commonName"
                  disabled={!canEditField("primaryAssignment")}
                  label="Primary Assignment"
                />
              </ReferenceInput>
            </Grid>
            <Grid item xs={4} sm={6} lg={3}>
              <ReferenceInput
                source="secondaryAssignment"
                reference="investmentAssignmentOption"
                perPage={100}
                sort={{ field: "commonName", order: "ASC" }}
                filter={{ isSecondary: true }}
              >
                <AutocompleteInput
                  optionText="commonName"
                  disabled={!canEditField("secondaryAssignment")}
                  label="Secondary Assignment"
                />
              </ReferenceInput>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={4} lg={3}>
              <BooleanInput
                disabled={!canEditField("isImpact")}
                label="Is Impact"
                source="isImpact"
                sx={
                  autoPopulatedFields.includes("isImpact")
                    ? LLMColor__Switch
                    : undefined
                }
              />
            </Grid>
            <Grid item xs={4} lg={3}>
              <BooleanInput
                disabled={!canEditField("isPartner")}
                label="Is Partner"
                source="isPartner"
              />
            </Grid>
            <Grid item xs={6} lg={3}>
              <BooleanInput
                disabled={!canEditField("isWorkInProgress")}
                label="Work In Progress"
                source="isWorkInProgress"
              />
            </Grid>
            <Grid item xs={6} lg={3}>
              <BooleanInput
                disabled={!canEditField("isLiquidated")}
                label="Liquidated"
                source="isLiquidated"
              />
            </Grid>
            {isLiquidated && (
              <Grid item xs={6} sm={6} lg={2}>
                <DateInput
                  disabled={!canEditField("liquidationDate")}
                  label="Liquidation Date"
                  source="liquidationDate"
                />
              </Grid>
            )}
            {isLiquidated && (
              <Grid item xs={12} sm={12} lg={12}>
                <TextInput
                  disabled={!canEditField("liquidationNote")}
                  label="Liquidation Note"
                  source="liquidationNote"
                  multiline
                  rows={5}
                />
              </Grid>
            )}
          </Grid>
          <AdvisorInvestment canEditField={canEditField} />
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <RichTextInput
                canEdit={canEditField("summary")}
                label="Investment Summary"
                source="summary"
                backgroundColor={
                  autoPopulatedFields.includes("summary")
                    ? "rgba(201,186,232,0.5)"
                    : undefined
                }
              />
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} lg={3}>
              <EntityInput<NonNullable<Record>>
                resource="investment"
                recordKey="primaryBenchmark"
                sourceKey="primaryBenchmarkId"
                label="Primary Benchmark"
                allowChange={canEditField("primaryBenchmarkId")}
                filters={{
                  searchFilters: [
                    {
                      TYPE: {
                        values: [
                          { id: 5, label: "ETF" },
                          { id: 12, label: "Index" },
                        ],
                      },
                    },
                  ],
                }}
                sx={
                  autoPopulatedFields.includes("firm")
                    ? LLMBGColor__AutoComplete
                    : undefined
                }
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <EntityInput<NonNullable<Record>>
                resource="investment"
                recordKey="secondaryBenchmark"
                sourceKey="secondaryBenchmarkId"
                label="Secondary Benchmark"
                allowChange={canEditField("secondaryBenchmarkId")}
                filters={{
                  searchFilters: [
                    {
                      TYPE: {
                        values: [
                          { id: 5, label: "ETF" },
                          { id: 12, label: "Index" },
                        ],
                      },
                    },
                  ],
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <ReferenceInput
                source="primaryGeographicFocusId"
                reference="geographicFocusEnum"
                sort={{ field: "name", order: "ASC" }}
              >
                <SelectInput
                  disabled={!canEditField("primaryGeographicFocusId")}
                  validate={validateRequired}
                  optionText="name"
                  label="Primary Geographic Focus"
                  sx={
                    autoPopulatedFields.includes("primaryGeographicFocusId")
                      ? LLMBGColor
                      : undefined
                  }
                />
              </ReferenceInput>
            </Grid>
          </Grid>
          <ReportingSettings canEditField={canEditField} />
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <hr />
              <h3>Data Transparency</h3>
            </Grid>
            <Grid item xs={4} lg={3}>
              <BooleanInput
                disabled={
                  !canEditField("isManagerUnwillingToProvidePerformance")
                }
                label="Manager unwilling to provide performance"
                source="isManagerUnwillingToProvidePerformance"
              />
            </Grid>
            <Grid item xs={4} lg={3}>
              <BooleanInput
                disabled={!canEditField("isManagerUnwillingToProvidePositions")}
                label="Manager unwilling to provide positions"
                source="isManagerUnwillingToProvidePositions"
              />
            </Grid>
            <Grid item xs={4} lg={3}>
              <BooleanInput
                disabled={!canEditField("isManagerUnwillingToProvideRisk")}
                label="Manager unwilling to provide risk data"
                source="isManagerUnwillingToProvideRisk"
              />
            </Grid>
            <Grid item xs={4} lg={3}>
              <BooleanInput
                disabled={!canEditField("isManagerUnwillingToProvideAUM")}
                label="Manager unwilling to provide AUM"
                source="isManagerUnwillingToProvideAUM"
              />
            </Grid>
            <Grid item xs={4} lg={3}>
              <Tooltip
                title="Has RockCreek signed an NDA precluding them from sharing fund data with 3rd Parties?"
                placement="bottom"
              >
                <div>
                  <BooleanInput
                    disabled={!canEditField("ndaSigned")}
                    label="NDA Signed"
                    source="ndaSigned"
                  />
                </div>
              </Tooltip>
            </Grid>
          </Grid>
          {marketEnumId === 1 && (
            <PublicMarketFields
              canEditField={canEditField}
              autoPopulatedFields={autoPopulatedFields}
            />
          )}
          {marketEnumId === 2 && (
            <PrivatesSpecificFields
              edit
              canEditField={canEditField}
              autoPopulatedFields={autoPopulatedFields}
            />
          )}
          <Grid item xs={12}>
            <hr />
            <h3>Investment Tags</h3>
          </Grid>
          <CustomInvestmentTagsInput
            readOnly={
              !canEditField("investmentTags") &&
              !canEditField("investmentTags.id") &&
              !canEditField("investmentTags.tagClassId")
            }
            source="investmentTags"
            label="Investment Tags"
          />

          {firmInvestments && (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <hr />
                <h3>Links</h3>
              </Grid>
              <Grid item xs={12} sm={6} lg={3}>
                <SelectInput
                  disabled={!canEditField("domicileFundLinkId")}
                  label="Domicile Link"
                  source="domicileFundLinkId"
                  choices={firmInvestments}
                  resettable
                />
              </Grid>
              <Grid item xs={12} sm={6} lg={3}>
                <NumberInput
                  disabled={!canEditField("reportingLinkId")}
                  label="Reporting Link"
                  source="reportingLinkId"
                />
              </Grid>
            </Grid>
          )}
          <Grid item xs={12}>
            <hr />
            <h3>Impact Tags</h3>
          </Grid>
          <ImpactSelection
            source="impactTags"
            canEdit={canEditField("impactTags")}
          />
          <Grid item xs={12}>
            <hr />
            <h3>Fund Structure</h3>
          </Grid>
          <FundStructure canEditField={canEditField} />

          <Grid item xs={12}>
            <hr />

            <h3 style={{ marginBlockEnd: 0 }}>Registered Address</h3>
            <Typography variant="caption">
              (Edit in Service Providers Diligence)
            </Typography>
          </Grid>
          <Box>
            <Grid container spacing={2}>
              <Grid item xs={8} sm={6} lg={3}>
                <InputLabel>c/o</InputLabel>
                <TextField
                  label="c/o"
                  source="registeredAddressProvider.name"
                  emptyText="-"
                  sortable={false}
                />
              </Grid>
              <Grid item xs={8} sm={6} lg={3}>
                <InputLabel>Line 1</InputLabel>
                <TextField
                  label="Line 1"
                  source="registeredAddressProvider.address.line1"
                  emptyText="-"
                  sortable={false}
                />
              </Grid>
              <Grid item xs={8} sm={6} lg={3}>
                <InputLabel>Line 2</InputLabel>
                <TextField
                  label="Line 2"
                  source="registeredAddressProvider.address.line2"
                  emptyText="-"
                  sortable={false}
                />
              </Grid>
              <Grid item xs={8} sm={6} lg={3}>
                <InputLabel>Line 3</InputLabel>
                <TextField
                  label="Line 3"
                  source="registeredAddressProvider.address.line3"
                  emptyText="-"
                  sortable={false}
                />
              </Grid>
              <Grid item xs={8} sm={6} lg={3}>
                <InputLabel>US State</InputLabel>
                <TextField
                  label="US State"
                  source="registeredAddressProvider.address.stateEnum.name"
                  emptyText="-"
                  sortable={false}
                />
              </Grid>
              <Grid item xs={8} sm={6} lg={3}>
                <InputLabel>Province</InputLabel>
                <TextField
                  label="Province"
                  source="registeredAddressProvider.address.otherState"
                  emptyText="-"
                  sortable={false}
                />
              </Grid>
              <Grid item xs={8} sm={6} lg={3}>
                <InputLabel>Zip Code</InputLabel>
                <TextField
                  label="Zip Code"
                  source="registeredAddressProvider.address.zipCode"
                  emptyText="-"
                  sortable={false}
                />
              </Grid>
              <Grid item xs={8} sm={6} lg={3}>
                <InputLabel>Country</InputLabel>
                <TextField
                  label="Country"
                  source="registeredAddressProvider.address.countryEnum.name"
                  emptyText="-"
                />
              </Grid>
              <Grid item xs={8} sm={6} lg={3}>
                <InputLabel>Phone Number</InputLabel>
                <TextField
                  label="Phone Number"
                  source="registeredAddressProvider.address.phone"
                  emptyText="-"
                />
              </Grid>
              <Grid item xs={8} sm={6} lg={3}>
                <InputLabel>Phone Number 2</InputLabel>
                <TextField
                  label="Phone Number 2"
                  source="registeredAddressProvider.address.phone2"
                  emptyText="-"
                />
              </Grid>
              <Grid item xs={8} sm={6} lg={3}>
                <InputLabel>Fax Number</InputLabel>
                <TextField
                  label="Fax Number"
                  source="registeredAddressProvider.address.fax"
                  emptyText="-"
                />
              </Grid>
              <Grid item xs={8} sm={6} lg={3}>
                <InputLabel>Fax Number 2</InputLabel>
                <TextField
                  label="Fax Number 2"
                  source="registeredAddressProvider.address.fax2"
                  emptyText="-"
                />
              </Grid>
            </Grid>
          </Box>
          <Grid item xs={12}>
            <hr />
            <h3>Investment Assignments</h3>
          </Grid>
          <Grid item xs={12}>
            <ReferenceManyField
              label="Investment Assignments"
              reference="investmentFirmEmployment"
              target="investmentId"
              sort={{ field: "investmentDefault", order: "ASC" }}
              pagination={<Pagination />}
            >
              <Datagrid
                rowClick={canEdit ? "edit" : ""}
                bulkActionButtons={false}
                data-cy="investment-assignments"
              >
                <TextField label="ID" source="id" sortable={false} />
                <TextField
                  label="Last Name"
                  source="firmEmployment.person.lastName"
                  sortable={false}
                />
                <TextField
                  label="First Name"
                  source="firmEmployment.person.firstName"
                  sortable={false}
                />
                <TextField
                  label="Role"
                  source="employeeRole.name"
                  sortable={false}
                />
                <DateField
                  label="Start Date"
                  source="startDate"
                  options={{ timeZone: "UTC" }}
                />
                <DateField
                  label="End Date"
                  source="leftDate"
                  options={{ timeZone: "UTC" }}
                />
                <TextField
                  label="Key Personnel?"
                  source="firmEmployment.isKeyPersonnel"
                  sortable={false}
                />
                <TextField
                  label="Partner?"
                  source="firmEmployment.isFounder"
                  sortable={false}
                />
                <TextField
                  label="Contact Type"
                  source="firmEmployment.contactType.name"
                  sortable={false}
                />
                <FunctionField
                  source="faceOfInvestment"
                  label="Face Of Investment"
                  sortable={false}
                  render={(record: any, source: any) => (
                    <FaceOfEntityTooltip entity="investment">
                      <BooleanField
                        record={{
                          ...record,
                          faceOfInvestment: !!record.faceOfInvestment,
                        }}
                        source={source}
                      />
                    </FaceOfEntityTooltip>
                  )}
                />
                {canEdit && <EditButton />}
              </Datagrid>
            </ReferenceManyField>
          </Grid>
          <Grid item xs={12}>
            <CreateInvestmentFirmEmploymentBulkButton />
          </Grid>
          <Grid item xs={12}>
            <hr />
            <h3>Axys Codes</h3>
          </Grid>
          <Grid data-cy="investmentAxysCodes__grid" item xs={12}>
            <CreateInvestmentAxysCodeButton />
            <ReferenceManyField
              label="Axys Codes"
              reference="investmentAxysCode"
              target="investmentId"
              sort={{ field: "id", order: "ASC" }}
              pagination={<Pagination />}
            >
              <Datagrid data-id="investmentAxysCodes" bulkActionButtons={false}>
                <TextField label="ID" source="axysCode.id" sortable={false} />
                <TextField
                  label="Code"
                  source="axysCode.code"
                  data-cy="investmentAxysCodes__code"
                  sortable={false}
                />
                <DeleteButton
                  data-cy="deleteAxysCode__button"
                  redirect={`/investment/${record.id}`}
                />
              </Datagrid>
            </ReferenceManyField>
          </Grid>
          <Grid item xs={12}>
            <hr />
            <h3>FDP Logins</h3>
          </Grid>
          <Grid item xs={12}>
            <ReferenceManyField
              reference="fdpLogin"
              perPage={1000}
              sort={{ field: "username", order: "ASC" }}
              target="investmentId"
              filter={{ includeFirmIfNoInvestment: true }}
              pagination={<Pagination />}
            >
              <FDPEditableDataGrid
                referenceKey="investment_fdpLogins"
                bulkActionButtons={false}
                noDelete={true}
                relationships={[]}
                actions={<FDPLoginActions investmentId={record.id} />}
                defaultRowValues={{
                  investments: [{ id: record.id }],
                  firms: [{ id: record.firmId }],
                }}
                entityContext="investment"
                entity={{ id: record.id, firmId: record.firmId }}
              />
            </ReferenceManyField>
          </Grid>
          <Grid item xs={12}>
            <CreateRelatedInvestmentFDPLoginButton />
          </Grid>
        </Grid>
      </TrackChangesPanel>
    </>
  );
};

export const InvestmentEdit = () => {
  const [firmId, setFirmId] = useState<number | undefined>();
  const [tempFiles, setTempFiles] = useState<TempFile[] | undefined>();
  const [autoPopulatedFields, setAutoPopulatedFields] = useState<string[]>([]);
  const recordId = useGetRecordId();
  const { data: firmInvestments } = useGetList<client.Investment>(
    "investment",
    {
      filter: {
        firmId: firmId,
      },
      sort: { field: "name", order: "ASC" },
    },
    { enabled: !!firmId }
  );

  const mutationArgs = JSON.stringify({
    id: recordId,
    input: {
      firmId: firmId,
    },
  });

  const { canEdit, canEditField, loading } = useCanAccessMutation(
    "updateInvestment",
    mutationArgs
  );

  return (
    <CustomEdit<Record>
      actions={
        <CustomBreadCrumb<Record>
          name="investment"
          items={[
            {
              getPath: record => (record?.firm?.isCompany ? "company" : "firm"),
              getId: record =>
                record?.firm?.isCompany
                  ? record?.firm.companyId
                  : record?.firm.id,
              getName: record => record?.firm?.name,
            },
          ]}
        />
      }
      customFormProps={{
        customToolbarProps: {
          confirmOnSave: true,
          getConfirmationMessage: ({ saveParams }) =>
            getInvestmentConfirmationMessage(saveParams, firmInvestments),
          allowSave: true,
          canAccessMutation: canEdit,
        },
        loading,
        canEditField,
      }}
      title={record => record && `Investment - ${record.name}`}
      sourcedFromOdc={(record?: Record) =>
        !!record?.portalSubmitted?.migratedAt
      }
    >
      <InvestmentEditForm
        firmInvestments={firmInvestments}
        setFirmId={setFirmId}
        fieldAccessMap={canEditField}
        canEdit={canEdit}
        loading={loading}
        autoPopulatedFields={autoPopulatedFields}
        setAutoPopulatedFields={setAutoPopulatedFields}
        tempFiles={tempFiles}
        setTempFiles={setTempFiles}
      />
    </CustomEdit>
  );
};
