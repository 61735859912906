import { Grid } from "@mui/material";
import { CustomCreate } from "../CustomCreate";
import { CustomIndexInvestmentForm, NBSP, Record, validateForm } from "./Form";
import { IdInvestmentInput } from "./IdInvestmentInput";

export const CustomIndexInvestmentCreate = () => {
  return (
    <CustomCreate<Record>
      title={() => `New Custom Index`}
      customFormProps={{
        simpleFormProps: {
          mode: "all",
          validate: validateForm(true),
        },
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <IdInvestmentInput
            source={"outputInvestmentId"}
            textFieldProps={{
              size: "small",
              helperText: NBSP,
              "data-cy": "outputInvestmentId",
            }}
            label="Custom Index ID"
            isRequired
          />
        </Grid>
        <Grid item xs={8}></Grid>
      </Grid>
      <CustomIndexInvestmentForm />
    </CustomCreate>
  );
};
