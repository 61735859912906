import Grid from "@mui/material/Grid";
import * as client from "_graphql-types";
import { NO_WHITESPACE_REGEX } from "frontend/src/utils/constants";
import { uniqBy } from "lodash";
import {
  BooleanInput,
  Button,
  Datagrid,
  DateField,
  EditButton,
  FunctionField,
  ImageField,
  ImageInput,
  Pagination,
  ReferenceInput,
  ReferenceManyField,
  SelectInput,
  TextField,
  TextInput,
  regex,
  required,
  useRecordContext,
} from "react-admin";
import { useWatch } from "react-hook-form";
import { Link } from "react-router-dom";
import config from "../../config";
import { CustomEdit } from "../CustomEdit";
import { InstitutionEmploymentDatagrid } from "../institutionEmployment/List";
import { PronounSelections } from "./Create";

type Record = NonNullable<client.GetOnePersonQuery["person"]>;

const validateNoWhitespace = [
  regex(NO_WHITESPACE_REGEX, "Please remove leading and trailing spaces"),
];
const validateRequiredNoWhitespace = [
  required(),
  regex(NO_WHITESPACE_REGEX, "Please remove leading and trailing spaces"),
];

export function getPersonPath(location: { pathname: string; search: string }) {
  if (location.search) {
    const { person } = JSON.parse(
      decodeURI(location.search).split("?source=")[1] ?? "{}"
    );
    return `/person/${person.id}`;
  }
}

const CreateRelatedInstitutionEmploymentButton = () => {
  const record = useRecordContext<Record>();
  if (!record) return null;
  return (
    <>
      <Button
        label="Add Institution Employment"
        component={Link}
        variant="contained"
        to={{
          pathname: `/institutionEmployment/create`,
          search: `?personId=${record.id}&redirect=%2Fperson%2F${record.id}`,
        }}
      />
    </>
  );
};

const CreateRelatedFirmEmploymentButton = () => {
  const record = useRecordContext<Record>();

  return !record ? null : (
    <Button
      component={Link}
      variant="contained"
      to={{
        pathname: "/firmEmployment/create",
        search: `?source=${JSON.stringify({
          person: {
            id: record.id,
            lastName: record.lastName,
            firstName: record.firstName,
            middleName: record.middleName,
          },
        })}`,
      }}
      label="Add Firm Employment"
      data-id="createFirmEmployment"
    />
  );
};

const CreateRelatedWorkExperienceButton = () => {
  const record = useRecordContext<Record>();
  return !record ? null : (
    <Button
      component={Link}
      to={{
        pathname: "/personWorkExperience/create",
        search: `?source=${JSON.stringify({
          person: {
            id: record.id,
            lastName: record.lastName,
            firstName: record.firstName,
            middleName: record.middleName,
          },
        })}`,
      }}
      label="Add Work Experience"
      data-id="createWorkExperience"
    />
  );
};

const CreateRelatedEducationButton = () => {
  const record = useRecordContext<Record>();
  return !record ? null : (
    <Button
      component={Link}
      to={{
        pathname: "/personEducation/create",
        search: `?source=${JSON.stringify({
          person: {
            id: record.id,
            lastName: record.lastName,
            firstName: record.firstName,
            middleName: record.middleName,
          },
        })}`,
      }}
      label="Add Education"
      data-id="createEducation"
    />
  );
};

const PersonPhotoInput = () => {
  const photoLink = useWatch({ name: "photoLink" });

  return (
    <ImageInput
      source="photo"
      label="Person Photo"
      accept={{ "image/*": [] }}
      maxSize={15 * 1000000}
      placeholder={
        photoLink && (
          <img
            data-id="photo-display"
            alt="headshot"
            src={`${config
              .GRAPHQL_API()
              .substring(
                0,
                config.GRAPHQL_API().length - 8
              )}${photoLink}?${Date.now()}`} // hackish workaround to force image refresh on sumbit by adding bogus query param to url
            style={{ maxWidth: "50%" }}
          />
        )
      }
    >
      <ImageField source="src" />
    </ImageInput>
  );
};

function PronounSelection() {
  const record = useRecordContext<Record>();
  return (
    <SelectInput
      source="pronouns"
      label="Pronouns"
      createLabel="Other"
      onCreate={() => {
        const newCategoryName = prompt("Enter pronouns");
        if (newCategoryName) {
          const newCategory = {
            id: newCategoryName.toLowerCase(),
            name: newCategoryName,
          };
          PronounSelections.push(newCategory);
          return newCategory;
        }
        return null;
      }}
      choices={uniqBy(
        [
          ...PronounSelections,
          ...((record?.pronouns && [
            { id: record.pronouns, name: record.pronouns },
          ]) ||
            []),
        ],
        "id"
      )}
    />
  );
}

export const PersonEdit = () => (
  <CustomEdit<Record>
    title={record =>
      record &&
      `Person ${record.lastName || ""}, ${record.firstName || ""} ${
        record.middleName || ""
      }`
    }
  >
    <Grid container spacing={2}>
      <Grid item xs={3}>
        <ReferenceInput
          reference="titleEnum"
          sort={{ field: "name", order: "ASC" }}
          source="titleEnumId"
        >
          <SelectInput optionText="name" label="Salutation" />
        </ReferenceInput>
      </Grid>
      <Grid item xs={3}>
        <TextInput
          label="First Name"
          source="firstName"
          validate={validateRequiredNoWhitespace}
        />
      </Grid>
      <Grid item xs={3}>
        <TextInput
          label="Middle Name"
          source="middleName"
          validate={validateNoWhitespace}
        />
      </Grid>
      <Grid item xs={3}>
        <TextInput
          label="Last Name"
          source="lastName"
          validate={validateRequiredNoWhitespace}
        />
      </Grid>
      <Grid item xs={3}>
        <PronounSelection />
      </Grid>
      <Grid item xs={3}>
        <TextInput label="Phone" source="phone" />
      </Grid>
      <Grid item xs={3}>
        <TextInput label="Phone 2" source="phone2" />
      </Grid>
      <Grid item xs={3}>
        <TextInput label="Fax" source="fax" />
      </Grid>
      <Grid item xs={3}>
        <TextInput label="Fax 2" source="fax2" />
      </Grid>
      <Grid item xs={3}>
        <TextInput label="Email" source="email" />
      </Grid>
      <Grid item xs={3}>
        <TextInput label="Email 2" source="email2" />
      </Grid>
      <Grid item xs={3}>
        <TextInput label="LinkedIn" source="linkedin" />
      </Grid>
      <Grid item xs={3}>
        <TextInput label="Twitter" source="twitter" />
      </Grid>
      <Grid item xs={3}>
        <BooleanInput label="Is CFA?" source="isCFA" />
      </Grid>
      <Grid item xs={12}>
        <hr />
        <h3>Diversity</h3>
      </Grid>
      <Grid item xs={3}>
        <BooleanInput
          label="African American"
          source="diversity.africanAmerican"
        />
      </Grid>
      <Grid item xs={3}>
        <BooleanInput label="Asian" source="diversity.asian" />
      </Grid>
      <Grid item xs={3}>
        <BooleanInput label="Hispanic" source="diversity.hispanic" />
      </Grid>
      <Grid item xs={3}>
        <BooleanInput label="LGBTQ+" source="diversity.lgbtq" />
      </Grid>
      <Grid item xs={3}>
        <BooleanInput
          label="Native American"
          source="diversity.nativeAmerican"
        />
      </Grid>
      <Grid item xs={3}>
        <BooleanInput
          label="Hawaiian / Pacific Islander"
          source="diversity.hawaiianOrPacificIslander"
          data-cy="diversity.hawaiianOrPacificIslander"
        />
      </Grid>
      <Grid item xs={3}>
        <BooleanInput
          label="Veteran"
          source="diversity.veteran"
          data-cy="diversity.veteran"
        />
      </Grid>
      <Grid item xs={3}>
        <BooleanInput
          label="Disabled"
          source="diversity.disabled"
          data-cy="diversity.disabled"
        />
      </Grid>
      <Grid item xs={3}>
        <BooleanInput label="Woman" source="diversity.woman" />
      </Grid>
      <Grid item xs={3}>
        <BooleanInput
          label="NonBinary"
          source="diversity.nonBinary"
          data-cy="diversity.nonBinary"
        />
      </Grid>
      <Grid item xs={3}>
        <BooleanInput label="Other" source="diversity.other" />
      </Grid>
      <Grid item xs={12}>
        <hr />
        <h3>Work Experience</h3>
      </Grid>
      <Grid item xs={12}>
        <ReferenceManyField
          label="Work Experience"
          reference="personWorkExperience"
          target="personId"
          sort={{ field: "default", order: "DESC" }}
          pagination={<Pagination />}
        >
          <Datagrid
            data-cy="person__personWorkExperience-table"
            rowClick="edit"
            bulkActionButtons={false}
          >
            <TextField label="ID" source="id" sortable={false} />
            <TextField label="Company" source="company.name" sortable={false} />
            <TextField label="Title" source="title" sortable={false} />
            <TextField label="From" source="fromYear" sortable={false} />
            <TextField label="To" source="toYear" sortable={false} />
            <EditButton />
          </Datagrid>
        </ReferenceManyField>
      </Grid>
      <Grid item xs={12}>
        <CreateRelatedWorkExperienceButton />
      </Grid>
      <Grid item xs={12}>
        <hr />
        <h3>Education</h3>
      </Grid>
      <Grid item xs={12}>
        <ReferenceManyField
          label="Education"
          reference="personEducation"
          target="personId"
          sort={{ field: "graduationYear", order: "DESC" }}
          pagination={<Pagination />}
        >
          <Datagrid
            data-cy="person__personEducation-table"
            rowClick="edit"
            bulkActionButtons={false}
          >
            <TextField label="ID" source="id" sortable={false} />
            <TextField label="School" source="school.name" sortable={false} />
            <TextField label="Degree" source="degree.name" sortable={false} />
            <TextField label="Year" source="graduationYear" sortable={false} />
            <EditButton />
          </Datagrid>
        </ReferenceManyField>
      </Grid>
      <Grid item xs={12}>
        <CreateRelatedEducationButton />
      </Grid>
      <Grid item xs={12}>
        <TextInput
          label="Personal Biography"
          multiline
          rows={5}
          source="biography"
        />
      </Grid>
      <Grid item xs={12}>
        <TextInput
          label="Work Experience Summary"
          multiline
          rows={5}
          source="workExperienceSummary"
        />
      </Grid>
      <Grid item xs={12}>
        <TextInput
          label="Education Summary"
          multiline
          rows={5}
          source="educationSummary"
        />
      </Grid>
      <Grid item xs={12}>
        <TextInput label="Comments" multiline rows={5} source="comments" />
      </Grid>

      <Grid item xs={12}>
        <hr />
        <h3>Institution Employments</h3>
      </Grid>
      <Grid item xs={12}>
        <CreateRelatedInstitutionEmploymentButton />
        <ReferenceManyField
          label="Service Provider Employments"
          reference="institutionEmployment"
          target="personId"
          sort={{ field: "personDefault", order: "ASC" }}
          pagination={<Pagination />}
        >
          <InstitutionEmploymentDatagrid />
        </ReferenceManyField>
      </Grid>
      <Grid item xs={12}>
        <hr />
        <h3>Firm Employments</h3>
      </Grid>
      <Grid item xs={12}>
        <CreateRelatedFirmEmploymentButton />
        <ReferenceManyField
          label="Firm Employments"
          reference="firmEmployment"
          target="personId"
          sort={{ field: "personDefault", order: "ASC" }}
          pagination={<Pagination />}
        >
          <Datagrid
            rowClick="edit"
            data-id="firmEmployments"
            bulkActionButtons={false}
          >
            <TextField label="ID" source="id" sortable={false} />
            <TextField label="Firm" source="firm.name" sortable={false} />
            <TextField
              label="Role"
              source="employeeRole.name"
              sortable={false}
            />
            <TextField
              label="Contact Type"
              source="contactType.name"
              sortable={false}
            />
            <FunctionField
              label="Departed?"
              render={(firmEmployment: any) =>
                firmEmployment?.leftInd ? "Yes" : ""
              }
              sortable={false}
            />
            <DateField
              label="Departed on"
              source="leftDate"
              sortable={false}
              options={{ timeZone: "UTC" }}
            />
            <EditButton />
          </Datagrid>
        </ReferenceManyField>
      </Grid>

      <Grid item xs={12}>
        <PersonPhotoInput />
      </Grid>
    </Grid>
  </CustomEdit>
);
