import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepContent from "@mui/material/StepContent";
import { get, upperCase } from "lodash";
import { isDefinedAndNotNull } from "frontend/src/utils/helpers";
import { FieldValues, useFormContext } from "react-hook-form";
import { styled } from "@mui/material/styles";
import ReactQuill from "react-quill";
import { isHTML } from "frontend/src/components/Template/Static/TextStatic";
import {
  Button,
  Divider,
  Drawer,
  StepButton,
  Typography,
  useScrollTrigger,
} from "@mui/material";
import { Box } from "@mui/system";
import { useMemo, useRef, useState, memo, useEffect } from "react";
import { ProfileBuilderQuery } from "frontend/src/graphql-types/graphql";

export type TFormHistory = Omit<
  NonNullable<
    ProfileBuilderQuery["investmentProfileBuilder"]["investmentProfile"]
  >,
  "__typename" | "name" | "firmId" | "firmName"
>;
type TSteps = {
  source: string;
  sourceKey?: string;
  previousValue: TFormHistory[keyof TFormHistory];
  displayValue: TFormHistory[keyof TFormHistory];
}[];

interface TrackChangesProps {
  formHistory: TFormHistory | undefined;
  autoPopulatedFields: string[];
  open: boolean;
  setOpen: (b: boolean) => void;
}

const getStep = (source: keyof TFormHistory, formHistory: TFormHistory) => {
  let sourceKey: string | undefined;
  if (source.includes("EnumId")) {
    sourceKey = `${source.replace("EnumId", "")}.name`;
  } else if (source.includes("Id")) {
    sourceKey = `${source.replace("Id", "")}.name`;
  }
  const previousValue = formHistory[source];
  const displayValue = get(formHistory, sourceKey ?? source);
  return {
    source,
    previousValue,
    displayValue,
    ...(sourceKey && { sourceKey }),
  };
};

const maxDrawerWidth = 320;

const FieldHistoryItem = memo(
  ({ value }: { value: FieldValues[keyof FieldValues] }) => {
    if (isHTML(value)) {
      return (
        <div>
          <ReactQuill
            theme={"bubble"}
            value={value}
            readOnly={true}
            style={{
              overflow: "auto",
              lineHeight: "1.6em",
              minHeight: "3.2em",
              backgroundColor: "white",
            }}
            className="profileBuilder__field-history-item"
          ></ReactQuill>
        </div>
      );
    }

    return value;
  }
);

function TrackChanges({
  formHistory,
  autoPopulatedFields,
  setOpen,
  open,
}: TrackChangesProps) {
  const { setValue } = useFormContext();
  const [activeStep, setActiveStep] = useState<number>(0);
  const [completedSteps, setCompletedSteps] = useState<{
    [k: number]: boolean;
  }>({});

  const steps = useMemo(() => {
    if (!formHistory) return [];
    return Object.keys(formHistory).reduce((acc, source) => {
      const isAutoPopulated = autoPopulatedFields?.includes(source);
      const hasPreviousValue =
        formHistory &&
        isDefinedAndNotNull(formHistory[source as keyof TFormHistory]);

      if (isAutoPopulated && hasPreviousValue) {
        acc.push(getStep(source as keyof TFormHistory, formHistory));
      }

      return acc;
    }, [] as TSteps);
  }, [formHistory, autoPopulatedFields]);

  const isLastStep = () => {
    return activeStep === steps.length - 1;
  };

  const completedStepLength = () => {
    return Object.keys(completedSteps).length;
  };

  const allStepsCompleted = () => {
    return completedStepLength() === steps.length;
  };

  const handleNext = () => {
    if (allStepsCompleted()) {
      setActiveStep(0);
      setCompletedSteps({});
      setOpen(false);
    } else if (isLastStep()) {
      const newActiveStep = steps.findIndex(
        (_step, i) => !(i in completedSteps)
      );
      setActiveStep(newActiveStep);
    } else {
      setActiveStep(activeStep + 1);
    }
  };

  const handleStep = (step: number) => () => {
    setActiveStep(step);
  };

  const handleComplete = () => {
    const newCompleted = completedSteps;
    newCompleted[activeStep] = true;
    setCompletedSteps(newCompleted);
    handleNext();
  };

  function handleRejectChange(
    source: string,
    previousValue: FieldValues[keyof FieldValues]
  ) {
    setValue(source, previousValue, { shouldTouch: true });
    handleComplete();
  }

  if (!open || !formHistory || !steps.length) return null;
  console.log({ steps, activeStep });

  return (
    <Box sx={{ maxWidth: maxDrawerWidth, minWidth: 200 }}>
      <Typography variant="h6" sx={{ mb: 1, pl: 1 }}>
        Previous Values
      </Typography>
      <Divider />
      <Stepper nonLinear activeStep={activeStep} orientation="vertical">
        {steps.map(({ source, previousValue, displayValue }, index) => (
          <Step key={source} data-cy={`step__${source}`}>
            <StepButton onClick={handleStep(index)}>
              {upperCase(source)}
            </StepButton>
            <StepContent>
              <FieldHistoryItem value={displayValue} />
              <Box sx={{ mb: 2 }}>
                <div>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleRejectChange(source, previousValue)}
                    sx={{ mt: 1, mr: 1 }}
                    data-cy={`reject-change__${source}`}
                  >
                    Reject Change
                  </Button>
                  <Button
                    variant="contained"
                    onClick={handleComplete}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    {index === steps.length - 1 ? "Finish" : "Continue"}
                  </Button>
                </div>
              </Box>
            </StepContent>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
}

const Main = styled("main", {
  shouldForwardProp: prop => prop !== "open" && prop !== "drawerWidth",
})<{
  open?: boolean;
  drawerWidth: number;
}>(({ theme, open, drawerWidth }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginRight: 0,
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  }),
  /**
   * This is necessary to enable the selection of content. In the DOM, the stacking order is determined
   * by the order of appearance. Following this rule, elements appearing later in the markup will overlay
   * those that appear earlier. Since the Drawer comes after the Main content, this adjustment ensures
   * proper interaction with the underlying content.
   */
  position: "relative",
}));

function TrackChangesPanel({
  children,
  formHistory,
  autoPopulatedFields,
  open,
  setOpen,
}: {
  children: React.ReactNode;
  formHistory: TFormHistory | undefined;
  autoPopulatedFields: string[];
  open: boolean;
  setOpen: (b: boolean) => void;
}) {
  const drawerRef = useRef<HTMLDivElement>(null);
  const scrollTrigger = useScrollTrigger();

  const [drawerWidth, setDrawerWidth] = useState<number>(maxDrawerWidth);

  useEffect(() => {
    const observer = new ResizeObserver(entries => {
      setDrawerWidth(entries[0].contentRect.width);
    });
    observer.observe(drawerRef.current as Element);
    return () => {
      if (drawerRef.current) observer.unobserve(drawerRef.current);
      return;
    };
  }, []);

  const styles = useMemo(() => {
    const baseStyle = {
      ".MuiPaper-root": {
        transitionBehavior: "normal",
        transitionDuration: "225ms",
        transitionTimingFunction: "cubic-bezier(0, 0, 0.2, 1)",
        transitionProperty: "transform",
        transform: "none",
      },
    };
    if (scrollTrigger) {
      return {
        "&.MuiDrawer-root > .MuiPaper-root": {
          top: "0px",
          position: "fixed",
        },
        ...baseStyle,
      };
    }
    return {
      "&.MuiDrawer-root > .MuiPaper-root": {
        top: "100px",
        position: "fixed",
      },
      ...baseStyle,
    };
  }, [scrollTrigger]);

  return (
    <>
      <Main data-cy="trackChanges__main" drawerWidth={drawerWidth} open={open}>
        {children}
      </Main>
      <Drawer
        id="track-changes__drawer"
        sx={styles}
        anchor="right"
        variant="persistent"
        open={open}
      >
        <Box ref={drawerRef} data-cy="track-changes__box">
          <TrackChanges
            formHistory={formHistory}
            autoPopulatedFields={autoPopulatedFields}
            setOpen={setOpen}
            open={open}
          />
        </Box>
      </Drawer>
    </>
  );
}

export default TrackChangesPanel;
