import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { IconButton, Link } from "@mui/material";
import Typography from "@mui/material/Typography";
import { Stack } from "@mui/system";
import { ensureDefined } from "frontend/src/utils/helpers";
import { RaRecord, TextFieldProps, useRecordContext } from "react-admin";
import CopyToClipboard from "react-copy-to-clipboard";

export function CopyField<TRecord extends RaRecord>(
  props: TextFieldProps<TRecord> & { source: string; disabled?: boolean }
) {
  const { source } = props;
  const record = useRecordContext<TRecord>(props);
  const value = ensureDefined(record)[source];
  return (
    <CopyToClipboard text={value}>
      <IconButton aria-label="copy" color="primary" size="small">
        <ContentCopyIcon data-cy={`copy-text__${props.resource}_${source}`} />
      </IconButton>
    </CopyToClipboard>
  );
}

export function CopyButton({
  value,
  source,
}: {
  value?: string;
  source: string;
}) {
  if (!value) return null;
  return (
    <CopyToClipboard text={value}>
      <IconButton data-cy="copy-button" aria-label="copy" color="primary">
        <ContentCopyIcon
          data-cy={`copy-text__${source}`}
          sx={{ fontSize: "1rem" }}
        />
      </IconButton>
    </CopyToClipboard>
  );
}

/**
 * For use in the render of a function field
 */
export function FCField_RenderTextWithConvenienceCopy<
  TRecord extends RaRecord
>({
  record,
  source,
  maxChars,
  width,
  isLink = false,
  linkText,
}: {
  record?: TRecord;
  source: string;
  maxChars?: number;
  width?: number;
  isLink?: boolean;
  linkText?: string;
}) {
  const value = record?.[source];
  let showValue = value;
  if (maxChars && value && value.length > maxChars) {
    showValue = value.slice(0, maxChars) + "...";
  }

  return (
    <Stack
      width={width}
      direction={{ xs: "row" }}
      spacing={{ xs: 1 }}
      alignItems="baseline"
    >
      {isLink ? (
        <Link
          href={value.startsWith("http") ? value : `https://${value}`}
          target="_blank"
          rel="noreferrer"
          variant="body2"
        >
          {linkText || showValue}
        </Link>
      ) : (
        <Typography variant="body2">{showValue || ""}</Typography>
      )}
      <CopyButton value={value} source={source} />
    </Stack>
  );
}
