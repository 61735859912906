import * as client from "_graphql-types";
import * as types from "./types";

import { filterSortPage } from "../DataProvider";

type Sdk = ReturnType<typeof client.getSdk>;

/*********************************************************************/
// Data Provider Functions
/*********************************************************************/

/*********************************************************************/
async function getList(
  sdk: Sdk,
  params: types.GetListParams<client.InvestmentStructureEnumSortEnum>
) {
  const { pagination, sort } = params;

  const {
    investmentStructureEnumList: { items: data, total },
  } = await sdk.getListInvestmentStructureEnum(
    filterSortPage({
      sort,
      pagination,
    })
  );

  return {
    data,
    total,
  };
}

/*********************************************************************/
async function getOne(sdk: Sdk, params: types.GetOneParams) {
  const id = Number(params.id);
  const { investmentStructureEnum: data } =
    await sdk.getOneInvestmentStructureEnum({
      id,
    });
  return {
    data,
  };
}

/*********************************************************************/
async function getMany(sdk: Sdk, params: types.GetManyParams) {
  const ids = params.ids.map(id => Number(id));

  const { investmentStructureEnumMany: data } =
    await sdk.getManyInvestmentStructureEnum({
      ids,
    });

  return {
    data,
  };
}

/*********************************************************************/
export const investmentStructureEnum = types.dataProvider({
  getList,
  getOne,
  getMany,
  ttl: types.defaultEnumTtl,
});
