import * as client from "_graphql-types";
import * as types from "./types";

type Sdk = ReturnType<typeof client.getSdk>;

/*********************************************************************/
// Data Provider Functions
/*********************************************************************/

async function getList(
  sdk: Sdk,
  params: {
    filter: {
      investmentFilter: client.InvestmentFilterInput;
      performancePrivateFilter: client.PerformancePrivateFilter;
    };
  }
) {
  const { performancePrivateFilter, investmentFilter } = params.filter;
  const {
    investmentList: { items: data, total },
  } = await sdk.getListInvestmentPerformancePrivate({
    investmentFilter,
    performancePrivateFilter,
  });
  return { data, total };
}

/*********************************************************************/

async function getMany(
  sdk: Sdk,
  params: types.GetManyParams & { filter?: client.PerformancePrivateFilter }
) {
  const ids: number[] = params.ids.map(id => Number(id));
  const { investmentMany: data } =
    await sdk.getManyInvestmentPerformancePrivate({
      ids,
      filter: params.filter,
    });
  return { data };
}

/*********************************************************************/

async function getOne(sdk: Sdk, params: types.GetOneParams) {
  const id = Number(params.id);
  const { investment: data } = await sdk.getOneInvestmentPerformancePrivate({
    investmentId: id,
  });
  return { data };
}

async function update(sdk: Sdk, params: any) {
  const id = Number(params.id);
  const prevQuarters = params.previousData.performancePrivate;
  const newQuarters = params.data.performancePrivate;
  const changeQuarters = newQuarters
    .filter((newQuarter: any) => {
      const previousQuarter = prevQuarters.find(
        (pQuarter: any) =>
          pQuarter.returnYear === newQuarter.returnYear &&
          pQuarter.returnQuarter === newQuarter.returnQuarter
      );

      return !(
        previousQuarter.netIRR === newQuarter.netIRR &&
        previousQuarter.tvpi === newQuarter.tvpi &&
        previousQuarter.dpi === newQuarter.dpi
      );
    })
    .map(
      ({
        returnYear,
        returnQuarter,
        netIRR,
        tvpi,
        dpi,
      }: client.PerformancePrivateShort) => ({
        returnYear,
        returnQuarter,
        netIRR,
        tvpi,
        dpi,
      })
    );
  await sdk.addOrUpdatePerformancePrivate({
    input: {
      investmentId: id,
      quarters: changeQuarters,
    },
  });
  return await getOne(sdk, { id: String(id) });
}

async function create(sdk: Sdk, params: any) {
  const { id, returnQuarter, returnYear } = params.data;
  const netIRR = params.data.netIRR !== false ? params.data.netIRR : undefined;
  const tvpi = params.data.tvpi !== false ? params.data.tvpi : undefined;
  const dpi = params.data.dpi !== false ? params.data.dpi : undefined;
  await sdk.addOrUpdatePerformancePrivate({
    input: {
      investmentId: id,
      quarters: [{ returnQuarter, returnYear, netIRR, tvpi, dpi }],
    },
  });
  return await getOne(sdk, { id });
}

export const performancePrivate = types.dataProvider({
  getOne,
  getMany,
  getList,
  update,
  create,
});
