import * as client from "_graphql-types";
import { filterSortPage } from "../DataProvider";
import * as types from "./types";

type Sdk = ReturnType<typeof client.getSdk>;

/*********************************************************************/
// Data Provider Functions
/*********************************************************************/

/*********************************************************************/
async function getList(
  sdk: Sdk,
  params: types.GetListParams<client.FirmEmploymentSortEnum>
) {
  const { pagination, sort, filter } = params;

  const {
    firmEmploymentList: { items: data, total },
  } = await sdk.getListFirmEmployment(
    filterSortPage({
      filter,
      sort,
      pagination,
    })
  );

  return {
    data,
    total,
  };
}

/*********************************************************************/
async function getOne(sdk: Sdk, params: types.GetOneParams) {
  const id = Number(params.id);
  const { firmEmployment: data } = await sdk.getOneFirmEmployment({
    id,
  });
  return {
    data,
  };
}

/*********************************************************************/
async function getMany(sdk: Sdk, params: types.GetManyParams) {
  const ids = params.ids.map(id => Number(id));

  const { firmEmploymentMany: data } = await sdk.getManyFirmEmployment({
    ids,
  });

  return {
    data,
  };
}

/*********************************************************************/
async function getManyReference(
  sdk: Sdk,
  params: types.GetManyReferenceParams<client.FirmEmploymentSortEnum>
) {
  const id = Number(params.id);
  const {
    pagination: { page, perPage },
    sort: { field, order },
    filter,
    target,
  } = params;

  const {
    firmEmploymentList: { items: data, total },
  } = await sdk.getManyReferenceFirmEmployment({
    page: {
      offset: (page - 1) * perPage,
      limit: perPage,
    },
    sort: [{ field, order }],
    filter: { ...filter, [target]: id },
  });

  return {
    data,
    total,
  };
}

/*********************************************************************/
async function create(
  sdk: Sdk,
  params: types.CreateParams<
    NonNullable<client.GetOneFirmEmploymentQuery["firmEmployment"]>
  >
) {
  const { data } = params;
  const input: client.CreateFirmEmploymentMutationVariables["input"] = {
    firmId: types.required(data, "firm").id,
    personId: types.required(data, "person").id,
    employeeRoleEnumId: data.employeeRoleEnumId,
    contactTypeEnumId: data.contactTypeEnumId,
    leftInd: data.leftInd,
    leftDate: data.leftDate || undefined,
    leftReason: data.leftReason,
    authToTrade: data.authToTrade,
    outsideAffil: data.outsideAffil,
    startDate: data.startDate || undefined,
    jobDesc: data.jobDesc,
    focusStrategyEnumId: data.focusStrategyEnumId,
    dataContact: data.dataContact,
    isFounder: data.isFounder,
    isKeyPersonnel: data.isKeyPersonnel,
    auditContactTypeEnumId: data.auditContactTypeEnumId,
    isDataEntryContact: data.isDataEntryContact,
    displayOrder: data.displayOrder,
    comments: data.comments,
    faceOfFirm: data.faceOfFirm,
  };

  const {
    addFirmEmployment: { id },
  } = await sdk.createFirmEmployment({
    input,
  });

  return await getOne(sdk, { id: id! });
}

/*********************************************************************/
async function update(
  sdk: Sdk,
  params: types.UpdateParams<
    NonNullable<client.GetOneFirmEmploymentQuery["firmEmployment"]>
  >
): types.UpdateOut<any> {
  const id = Number(params.id);
  const { data } = params;
  const input: client.UpdateFirmEmploymentMutationVariables["input"] = {
    employeeRoleEnumId: data.employeeRoleEnumId,
    contactTypeEnumId: data.contactTypeEnumId,
    leftInd: data.leftInd,
    leftDate: data.leftDate,
    leftReason: data.leftReason,
    authToTrade: data.authToTrade,
    outsideAffil: data.outsideAffil,
    startDate: data.startDate,
    jobDesc: data.jobDesc,
    focusStrategyEnumId: data.focusStrategyEnumId,
    dataContact: data.dataContact,
    isFounder: data.isFounder,
    isKeyPersonnel: data.isKeyPersonnel,
    isAdminContact: data.isAdminContact,
    auditContactTypeEnumId: data.auditContactTypeEnumId,
    isDataEntryContact: data.isDataEntryContact,
    displayOrder: data.displayOrder,
    comments: data.comments,
    faceOfFirm: data.faceOfFirm,
  };

  await sdk.updateFirmEmployment({
    id,
    input,
  });

  return await getOne(sdk, { id });
}

/*********************************************************************/
async function del(sdk: Sdk, params: types.DeleteParams): types.DeleteOut<any> {
  const id = Number(params.id);

  await sdk.deleteFirmEmployment({ id });

  return { data: { id } };
}

/*********************************************************************/
export const firmEmployment = types.dataProvider({
  getList,
  getOne,
  getMany,
  getManyReference,
  create,
  update,
  delete: del,
});
