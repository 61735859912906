import * as client from "_graphql-types";
import * as types from "./types";

type Sdk = ReturnType<typeof client.getSdk>;

/*********************************************************************/
// Data Provider Functions
/*********************************************************************/

/*********************************************************************/
async function getList(
  sdk: Sdk,
  params: types.GetListParams<client.SoftLockupSortEnum>
) {
  const {
    pagination: { page, perPage },
    sort: { field, order },
  } = params;

  const {
    softLockupList: { items: data, total },
  } = await sdk.getListSoftLockup({
    page: {
      offset: (page - 1) * perPage,
      limit: perPage,
    },
    sort: [{ field, order }],
  });

  return {
    data,
    total,
  };
}

/*********************************************************************/

async function getManyReference(
  sdk: Sdk,
  params: types.GetManyReferenceParams<
    client.SoftLockupSortEnum,
    client.SoftLockupFilter
  >
) {
  const {
    pagination: { page, perPage },
    sort: { field, order },
    filter,
    target,
    id,
  } = params;

  const {
    softLockupList: { items: data, total },
  } = await sdk.getListSoftLockup({
    page: {
      offset: (page - 1) * perPage,
      limit: perPage,
    },
    sort: [{ field, order }],
    filter: {
      ...filter,
      [target]: id,
    },
  });

  return {
    data,
    total,
  };
}

/*********************************************************************/
async function getOne(sdk: Sdk, params: types.GetOneParams) {
  const id = Number(params.id);
  const { softLockup: data } = await sdk.getOneSoftLockup({
    id,
  });
  return {
    data,
  };
}

/*********************************************************************/
async function getMany(sdk: Sdk, params: types.GetManyParams) {
  const ids = params.ids.map(Number);

  const { softLockupMany: data } = await sdk.getManySoftLockup({
    ids,
  });

  return {
    data,
  };
}

/*********************************************************************/
export const softLockups = types.dataProvider({
  getList,
  getOne,
  getMany,
  getManyReference,
});
