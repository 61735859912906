import { FieldValues, FormState } from "react-hook-form";

type TValue =
  | unknown
  | unknown[]
  | { [key: string]: unknown | unknown[] }
  | { [key: string]: unknown | unknown[] }[];
function isEqual(data1: TValue, data2: TValue): boolean {
  if (typeof data1 !== typeof data2) {
    return false;
  }

  if (typeof data1 === "object" && typeof data2 === "object") {
    if (data1 === null || data2 === null) {
      return data1 === data2;
    }

    if (Array.isArray(data1) && Array.isArray(data2)) {
      return (
        data1.length === data2.length &&
        data1.every((v, i) => isEqual(v, data2[i]))
      );
    }
    return (
      Object.keys(data1).length === Object.keys(data2).length &&
      Object.keys(data1).every(k =>
        isEqual(data1[k as keyof typeof data1], data2[k as keyof typeof data2])
      )
    );
  }
  return data1 === data2;
}

/**
 * Will return the fields that have changed between the submittedValues and the previousData.
 * Only looks at the first level of the object,
 * for nested objects and lists it will compare the entire object/list and
 * return the entire object/list if any part of it has changed or omit it if it hasn't.
 * @param submittedValues submit payload from within the data provider
 * @param previousData previous data provided by the data provider
 * @returns submittedValues with only the fields that have changed
 */
export function getChangedFields<T extends Record<string, any>>(
  submittedValues: T,
  previousData?: T | null,
  meta?: {
    disabledFields?: string[];
    fieldAccess?: { [key: string]: boolean };
  }
): Partial<T> {
  return Object.keys(submittedValues).reduce((acc, key: string & keyof T) => {
    if (
      (previousData === undefined ||
        previousData === null ||
        !isEqual(submittedValues[key], previousData[key])) &&
      !meta?.disabledFields?.includes(key)
    ) {
      acc[key] = submittedValues[key];
    }
    return acc;
  }, {} as Partial<T>);
}

/**
 * Will return fields from the submit payload that are marked as dirty.
 * Looks for the existence of the top levels keys on the dirtyFields object.
 * @param submittedValues submit payload from within the data provider
 * @param dirtyFields from react-hook-form
 * @returns submittedValues with only the fields that have changed
 */
export function getChangedFieldsFromDirty<T extends Record<string, any>>(
  submittedValues: T,
  meta?: { dirtyFields: FormState<FieldValues>["dirtyFields"] }
): Partial<T> {
  const defaultValue = {} as T;
  if (!meta) {
    console.warn("meta is undefined, returning submittedValues");
    return submittedValues;
  }
  const { dirtyFields } = meta;

  if (Object.keys(dirtyFields).length === 0) {
    console.log("no dirty fields");
    return defaultValue;
  }

  return Object.keys(submittedValues).reduce((acc, key: string & keyof T) => {
    console.log("dirtyFields[key]", dirtyFields[key]);
    if (dirtyFields[key]) {
      console.log("has field: ", key);
      acc[key] = submittedValues[key];
    }
    return acc;
  }, defaultValue);
}
