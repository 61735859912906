import { alpha, Button } from "@mui/material";

import AddIcon from "@mui/icons-material/AddCircleOutline";
import ActionDelete from "@mui/icons-material/Delete";
import { useTheme } from "@mui/material/styles";
import classnames from "classnames";
import { useSimpleFormIterator, useSimpleFormIteratorItem } from "react-admin";

export const CustomAddButton = (
  allProps: Record<string, unknown> & { customButtonText: string }
) => {
  const { customButtonText, ...props } = allProps;
  const theme = useTheme();
  const { add } = useSimpleFormIterator();

  return (
    <Button size="small" {...props} onClick={() => add()}>
      <AddIcon sx={{ marginRight: theme.spacing(1) }} />
      {customButtonText}
    </Button>
  );
};

export const CustomRemoveButton = (props: any) => {
  const { remove } = useSimpleFormIteratorItem();
  return (
    <Button
      className={(classnames("ra-delete-button"), props.className)}
      sx={{
        color: "#f44336",
        "&:hover": {
          backgroundColor: alpha("#f44336", 0.12),
          // Reset on mouse devices
          "@media (hover: none)": {
            backgroundColor: "transparent",
          },
        },
      }}
      key="button"
      size="small"
      {...props}
      onClick={() => remove()}
    >
      <ActionDelete /> REMOVE
    </Button>
  );
};
