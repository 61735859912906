import { CustomEdit } from "../CustomEdit";
import * as client from "_graphql-types";
import { useCallback, useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { useGetRecordId, useSaveContext } from "react-admin";
import { useCanAccessMutation } from "../../util/useCanAccessMutation";
import { Grid } from "@mui/material";
import { useWatch } from "react-hook-form";
import { PortfolioConfigForm, TileImageInput } from "./Form";
import config from "../../config";

type IRecord = NonNullable<
  client.GetOnePortfolioConfigQuery["portfolioConfig"]
>;

const SCALE_FACTOR = 0.7;

const PreviewIFrame = styled("iframe")(({ theme }) => ({
  transform: `scale(${SCALE_FACTOR})`,
  transformOrigin: "0 0",
  width: `calc(100% / ${SCALE_FACTOR})`,
  height: `calc(100% / ${SCALE_FACTOR})`,
  zoom: 60,
}));

const PreviewIframeContainer = styled("div")(({ theme }) => ({
  width: "100%",
  height: "400px",
}));

const PreviewWindow = () => {
  const { save } = useSaveContext();
  const [increment, setIncrement] = useState(0);
  const [portfolioId] = useWatch({
    name: ["portfolioId"],
  });
  useEffect(() => {
    setIncrement(increment + 1);
  }, [save]);
  return (
    portfolioId && (
      <PreviewIframeContainer>
        <PreviewIFrame
          key={increment}
          src={`${config.SIRIUS_URL()}/investments/${portfolioId}?clientView=true`}
        />
      </PreviewIframeContainer>
    )
  );
};

export const PortfolioConfigEdit = () => {
  const recordId = useGetRecordId();
  const mutationArgs = JSON.stringify({ id: recordId });

  const {
    canEdit,
    canEditField: fieldAccessMap,
    loading,
  } = useCanAccessMutation("updatePortfolioConfig", mutationArgs);

  const canEditField = useCallback(
    (field: string) => {
      return canEdit && fieldAccessMap[field];
    },
    [canEdit]
  );

  return (
    <CustomEdit<IRecord>
      title={record =>
        record && `Settings ${" - " + record.portfolio.name || ""}`
      }
      customFormProps={{
        customToolbarProps: { canAccessMutation: canEdit },
        loading,
        canEditField: fieldAccessMap,
      }}
    >
      <PortfolioConfigForm canEditField={canEditField} />
      <Grid item xs={12}>
        <TileImageInput />
      </Grid>

      <PreviewWindow />
    </CustomEdit>
  );
};
