import { CustomEdit } from "../CustomEdit";
import * as client from "_graphql-types";
import { Grid } from "@mui/material";
import {
  TextInput,
  useGetRecordId,
  required,
  SimpleFormIterator,
  ReferenceManyField,
  Pagination,
  useRecordContext,
  regex,
} from "react-admin";

import { useCanAccessMutation } from "../../util/useCanAccessMutation";
import { ReferenceManyInput } from "@react-admin/ra-relationships";
import { FDPEditableDataGrid } from "../fdpLogin/DataGrid";
import { EditRowButton } from "@react-admin/ra-editable-datagrid";
import { ManageRelationshipsButton } from "../fdpLogin/DataGridActions";
import { VALID_WEBSITE_REGEX } from "frontend/src/utils/constants";
const validateRequired = [required()];
type Record = NonNullable<
  client.GetOneFinancialDataProviderQuery["financialDataProvider"]
>;

const FDPLoginGrid = () => {
  const record = useRecordContext<Record>();

  const CustomActions = () => (
    <>
      <EditRowButton />
      <ManageRelationshipsButton />
    </>
  );

  return (
    <ReferenceManyField
      reference="fdpLogin"
      target="financialDataProviderId"
      label="Logins"
      perPage={50}
      pagination={<Pagination />}
      sort={{ field: "username", order: "ASC" }}
    >
      <FDPEditableDataGrid
        mutationMode="undoable"
        referenceKey="fdp_fdpLogins"
        bulkActionButtons={false}
        noDelete={true}
        actions={<CustomActions />}
        allowFDPChange={false}
        defaultRowValues={{
          financialDataProvider: {
            ...record,
          },
        }}
      />
    </ReferenceManyField>
  );
};

const validateRequiredValidWebsite = [
  required(),
  regex(VALID_WEBSITE_REGEX, "Please enter a valid URL"),
];

export const FinancialDataProviderEdit = () => {
  const recordId = useGetRecordId();
  const mutationArgs = JSON.stringify({ id: recordId });

  const { canEdit, canEditField, loading } = useCanAccessMutation(
    "updateFinancialDataProvider",
    mutationArgs
  );

  function _canEditField(key: string) {
    return !!(canEdit && canEditField[key]);
  }

  return (
    <CustomEdit<Record>
      title={record => record && `FDP ${record.name || ""}`}
      customFormProps={{
        customToolbarProps: { canAccessMutation: canEdit },
        loading,
        canEditField,
      }}
    >
      <>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextInput
              source="name"
              validate={validateRequired}
              disabled={!_canEditField("name")}
            />
          </Grid>

          <Grid item xs={12}>
            <ReferenceManyInput
              reference="fdpWebsites"
              target="financialDataProviderId"
              label="Websites"
            >
              <SimpleFormIterator
                fullWidth
                inline
                sx={{
                  "& .RaSimpleFormIterator-line": {
                    borderBottom: "none",
                  },
                }}
              >
                <TextInput
                  label=""
                  source="website"
                  validate={validateRequiredValidWebsite}
                  disabled={!_canEditField("fdpWebsites")}
                />
              </SimpleFormIterator>
            </ReferenceManyInput>
          </Grid>

          <Grid item xs={12}>
            <FDPLoginGrid />
          </Grid>
        </Grid>
      </>
    </CustomEdit>
  );
};
