import * as client from "_graphql-types";
import { Button } from "ra-ui-materialui";
import {
  Pagination,
  ReferenceManyField,
  Show,
  useRecordContext,
} from "react-admin";
import { Link } from "react-router-dom";
import CustomBreadCrumb from "../CustomBreadCumb";
import { FirmInstitutionsListDataGrid } from "../firmInstitutions/List";

type Record = NonNullable<client.GetOneFirmQuery["firm"]>;

const Title = () => {
  const record = useRecordContext<Record>();
  return (
    <span data-id="firm-service-provider-title">
      Firm Service Providers - {record ? `${record.name}   ` : "   "}
    </span>
  );
};

const CreateButton = () => {
  const record = useRecordContext<Record>();
  if (!record) return null;
  return (
    <>
      <Button
        label="Create Link"
        component={Link}
        variant="contained"
        to={{
          pathname: `/firmInstitutions/create`,
          search: `?firmId=${record.id}&redirect=%2FfirmServiceProviderLinks%2F${record.id}%2Fshow`,
        }}
      />
    </>
  );
};

export const FirmServiceProviderShow = () => {
  return (
    <Show
      title={<Title />}
      resource={"firm"}
      actions={
        <CustomBreadCrumb<Record>
          name="firmServiceProviderLinks"
          items={[
            {
              path: "firm",
              getId: record => record?.id,
              getName: record => record?.name,
            },
          ]}
        />
      }
    >
      <CreateButton />
      <ReferenceManyField
        label="Firm Service Provider Links"
        reference="firmInstitutions"
        target="firmId"
        perPage={10}
        pagination={<Pagination />}
      >
        <FirmInstitutionsListDataGrid />
      </ReferenceManyField>
    </Show>
  );
};
