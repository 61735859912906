import { useEffect, useState } from "react";
import { Amplify, Auth } from "aws-amplify";
import { Button, InputLabel, TextField } from "@mui/material";
import Alert from "@mui/material/Alert";
import config from "../../config";
import { CognitoUser } from "@aws-amplify/auth";

export enum AuthState {
  SignedIn = "SignedIn",
  CustomConfirmSignIn = "CustomConfirmSignIn",
  SignIn = "SignIn",
}

export function CognitoSignIn({
  setAuthState,
  setCustomLoginCognitoUser,
}: {
  setAuthState: (arg0: AuthState) => void;
  setCustomLoginCognitoUser: (arg0: CognitoUser | undefined) => void;
}) {
  const [password, setPassword] = useState<string>();
  const [codeLogin, setCodeLogin] = useState<boolean>(true);
  const [email, setEmail] = useState<string>();
  const [error, setError] = useState<string>();

  const cognitoLogin = async () => {
    setError(undefined);
    if (!email) {
      setError("Please provide email.");
      return;
    }
    if (password) {
      try {
        await Auth.signIn({ username: email, password });
        setAuthState(AuthState.SignedIn);
      } catch (e: any) {
        setError("Invalid Credentials");
        throw e;
      }
    } else {
      try {
        const user = await Auth.signIn(email);
        setCustomLoginCognitoUser(user);
        setAuthState(AuthState.CustomConfirmSignIn);
      } catch (e: any) {
        setError("Invalid Credentials");
        throw e;
      }
    }
  };

  useEffect(() => {
    setPassword(undefined);
  }, [codeLogin]);

  return (
    <div>
      <div>
        <InputLabel>Enter your email</InputLabel>
        <TextField
          onChange={e => setEmail(e.target.value)}
          type="text"
          margin="normal"
          variant="outlined"
          data-cy="username"
          value={email}
          fullWidth
        />

        {!codeLogin && (
          <>
            <InputLabel>Enter your password</InputLabel>
            <TextField
              onChange={e => setPassword(e.target.value)}
              data-cy="password"
              type="password"
              variant="outlined"
              value={password}
              margin="normal"
              fullWidth
            />
          </>
        )}
      </div>
      <div style={{ marginTop: "1em" }}>
        {error && <Alert severity="error">{error}</Alert>}
      </div>
      <div
        style={{
          marginTop: "1em",
          marginBottom: "1em",
        }}
      >
        <Button
          onClick={cognitoLogin}
          variant="contained"
          color="primary"
          data-cy="login-button"
          fullWidth
        >
          Log In
        </Button>

        <div>
          <Button
            onClick={() => {
              setCodeLogin(!codeLogin);
              const userPoolWebClientId = config.POOL_CLIENT_ID();

              Amplify.configure({
                userPoolWebClientId,
              });
              setAuthState(AuthState.SignIn);
              setCustomLoginCognitoUser(undefined);
            }}
            data-cy="switch-login"
            color={"primary"}
            variant="text"
            fullWidth
          >
            {codeLogin && "Use Password login"}
            {!codeLogin && "Use Code login"}
          </Button>
        </div>
      </div>
    </div>
  );
}

export function CognitoConfirmSignIn({
  setAuthState,
  cognitoUser,
}: {
  setAuthState: (arg0: AuthState) => void;
  cognitoUser: CognitoUser;
}) {
  const [code, setCode] = useState<string>();
  const [error, setError] = useState<string | null>(null);
  const cognitoConfirm = async () => {
    setError(null);
    if (!code) return;
    try {
      await Auth.sendCustomChallengeAnswer(cognitoUser, code);
    } catch (e: any) {
      setError(e.message);
      throw e;
    }
    setAuthState(AuthState.SignedIn);
  };

  return (
    <div>
      <div>
        <InputLabel>Enter Code</InputLabel>
        <p>
          A secret code has been sent to your provided email: at{" "}
          {cognitoUser.getUsername()} <br /> please enter it below:
        </p>
        <TextField
          margin="normal"
          onChange={e => setCode(e.target.value)}
          type="text"
          value={code}
          variant="outlined"
          data-cy="secret-code"
          fullWidth
        />
      </div>
      {error && <Alert severity="error">{error}</Alert>}
      <div
        style={{
          marginTop: "20px",
        }}
      >
        <Button
          onClick={cognitoConfirm}
          variant="contained"
          color="primary"
          data-cy="secret-code-submit"
          fullWidth
        >
          Confirm
        </Button>
      </div>
    </div>
  );
}
