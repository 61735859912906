import * as client from "_graphql-types";
import * as types from "./types";

type Sdk = ReturnType<typeof client.getSdk>;

export interface Option {
  firmEmploymentId: number;
  checked: boolean;
  personName: string;
  employeeRoleId: number | null;
  startDate: string | null;
  leftDate: string | null;
  faceOfInvestment: boolean;
}

export interface Record {
  investment: {
    name: string;
    id: number;
  };
  options?: Option[];
}

/*********************************************************************/
// Data Provider Functions
/*********************************************************************/

/*********************************************************************/
async function create(sdk: Sdk, params: types.CreateParams<Record>) {
  const { data } = params;
  const { id } = types.required(data, "investment");
  const { options } = data;
  const input: client.CreateInvestmentFirmEmploymentBulkMutationVariables["input"] =
    options
      ?.filter(option => option.checked)
      .map(option => {
        return {
          investmentId: id,
          firmEmploymentId: option.firmEmploymentId,
          employeeRoleEnumId: option.employeeRoleId,
          faceOfInvestment: option.faceOfInvestment,
          startDate: option.startDate || undefined,
          leftDate: option.leftDate || undefined,
        } as client.InvestmentFirmEmploymentInput;
      }) ?? [];

  if (input.length) {
    const { addInvestmentFirmEmploymentRecords } =
      await sdk.createInvestmentFirmEmploymentBulk({
        input,
      });
  }

  return { data: { id } };
}

/*********************************************************************/
export const investmentFirmEmploymentBulk = types.dataProvider({
  create,
});
