import { Grid } from "@mui/material";
import * as client from "_graphql-types";
import { TextInput } from "react-admin";
import CustomBreadCrumb from "../CustomBreadCumb";
import { CustomEdit } from "../CustomEdit";
import { EntityInput } from "../UI/EntityInput";

type Record = NonNullable<
  client.GetOneEmailDomainXOrganizationQuery["emailDomainXOrganization"]
>;

export const EmailDomainXOrganizationEdit = () => {
  return (
    <CustomEdit<Record>
      title={record =>
        record && `Email Domain Mapping ${record.organization.name || ""}`
      }
      actions={
        <CustomBreadCrumb<Record>
          name="emailDomainXOrganization"
          items={[
            {
              path: "organization",
              getId: record => record?.organization?.id,
              getName: record => record?.organization?.name,
            },
          ]}
        />
      }
    >
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <EntityInput<NonNullable<Record>>
            resource="organization"
            recordKey="organization"
            label="Organization"
            style={{ minWidth: "300px" }}
            allowChange
            isRequired={true}
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            label="Email Domain"
            source="emailDomain"
            isRequired={true}
          />
        </Grid>
      </Grid>
    </CustomEdit>
  );
};
