import * as client from "_graphql-types";
import * as types from "./types";

type Sdk = ReturnType<typeof client.getSdk>;

/*********************************************************************/
// Data Provider Functions
/*********************************************************************/

/*********************************************************************/
async function getOne(sdk: Sdk, params: types.GetOneParams) {
  const id = String(params.id);
  const { asyncTaskStatus: data } = await sdk.getOneAsyncTaskStatus({
    id,
  });
  // workaround for type-graphql emitting id as nullable
  return { data: data && { ...data, id: data.id! } };
}

/*********************************************************************/
export const asyncTaskStatus = types.dataProvider({
  getOne,
});
