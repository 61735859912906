import * as client from "_graphql-types";
import * as types from "./types";

import { filterSortPage } from "../DataProvider";

type Sdk = ReturnType<typeof client.getSdk>;

/*********************************************************************/
// Data Provider Functions
/*********************************************************************/

/*********************************************************************/
async function getList(
  sdk: Sdk,
  params: types.GetListParams<client.NavClassificationEnumSortEnum>
) {
  const { pagination, sort } = params;

  const {
    navClassificationEnumList: { items: data, total },
  } = await sdk.getListNavClassificationEnum(
    filterSortPage({ sort, pagination })
  );

  return {
    data,
    total,
  };
}

/*********************************************************************/
async function getOne(sdk: Sdk, params: types.GetOneParams) {
  const id = Number(params.id);
  const { navClassificationEnum: data } = await sdk.getOneNavClassificationEnum(
    {
      id,
    }
  );
  return {
    data,
  };
}

/*********************************************************************/
async function getMany(sdk: Sdk, params: types.GetManyParams) {
  const ids = params.ids.map(id => Number(id));

  const { navClassificationEnumMany: data } =
    await sdk.getManyNavClassificationEnum({
      ids,
    });

  return {
    data,
  };
}

/*********************************************************************/
export const navClassificationEnum = types.dataProvider({
  getList,
  getOne,
  getMany,
  ttl: types.defaultEnumTtl,
});
