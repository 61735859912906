import { get } from "lodash";
import * as client from "_graphql-types";

export const tagClassMapping = {
  marketFocus: 2,
  regionalFocus: 3,
  countryFocus: 4,
  styleBias: 5,
  marketCap: 6,
  sectorFocus: 7,
  realEstateFocus: 19,
  durationFocus: 8,
  creditQualityFocus: 9,
  creditSectorFocus: 10,
  currency: 11,
  decisionMakingProcess: 15,
  exposureBias: 16,
};

// on investment edit, data.investmentTags can be a mix of tags and investment tags
// this checks which type it is and handles it accordingly
export function formatInvestmentTagsUpdateInput(investmentTags: any) {
  const formatted = investmentTags.map((tag: any) => ({
    id: tag.tagId ?? tag.id,
    tagClassId: tag.tagClassId,
  }));
  return formatted;
}

// on investment creation, data.investmentTags is an array of tags
export function formatInvestmentTagsCreateInput(investmentTags: any) {
  return investmentTags.map((tag: client.Tag) => ({
    id: tag.id,
    tagClassId: tag.tagClassId,
  }));
}
