import { TopToolbar } from "react-admin";
import { Breadcrumb, BreadcrumbItem } from "@react-admin/ra-navigation";

export default function CustomBreadCrumb<RecordType>({
  name,
  items,
  resource,
}: {
  name: string;
  items: {
    path?: string;
    getPath?: (record: RecordType) => string;
    getId: (record: RecordType) => number | null | undefined;
    getName: (record: RecordType) => string | null | undefined;
  }[];
  resource?: string;
}) {
  return (
    <TopToolbar sx={{ justifyContent: "flex-start" }}>
      <Breadcrumb>
        {items.map(({ getId, getName, path, getPath }, key) => (
          <BreadcrumbItem
            key={key}
            name={name}
            data-cy={path ? `breadcrumb-${path}` : `breadcrumb`}
            label={
              (({ record }: { record: RecordType }) => {
                return getName(record) ? `${getName(record)}` : "";
              }) as any
            }
            //TODO types here are a little irrational. Research why react-admin calls context: Record<string, unknown> when documentation suggests record is always available
            to={
              (({ record }: { record: RecordType }) =>
                record &&
                `/${path || (getPath && getPath(record))}/${getId(
                  record
                )}`) as any
            }
          />
        ))}
      </Breadcrumb>
    </TopToolbar>
  );
}
