import * as client from "_graphql-types";
import * as types from "./types";
import { EnsureAllKeys } from "../helpers/types";
type Sdk = ReturnType<typeof client.getSdk>;

/*********************************************************************/
// Data Provider Functions
/*********************************************************************/

/*********************************************************************/
async function getList(
  sdk: Sdk,
  params: types.GetListParams<client.AdEntriesXApplicationFlagsSortEnum>
) {
  const {
    pagination: { page, perPage },
    sort: { field, order },
    filter,
  } = params;

  const {
    adEntriesXApplicationFlagsList: { items: data, total },
  } = await sdk.getListAdEntriesXApplicationFlags({
    page: {
      offset: (page - 1) * perPage,
      limit: perPage,
    },
    sort: [{ field, order }],
    filter,
  });

  return {
    data,
    total,
  };
}

/*********************************************************************/
async function getOne(sdk: Sdk, params: types.GetOneParams) {
  const id = params.id as number;
  const { adEntriesXApplicationFlags: data } =
    await sdk.getOneAdEntriesXApplicationFlags({
      id,
    });
  return {
    data,
  };
}

/*********************************************************************/
async function getMany(sdk: Sdk, params: types.GetManyParams) {
  const ids = params.ids as number[];

  const { adEntriesXApplicationFlagsMany: data } =
    await sdk.getManyAdEntriesXApplicationFlags({
      ids,
    });

  return {
    data,
  };
}

/*********************************************************************/
async function getManyReference(
  sdk: Sdk,
  params: types.GetManyReferenceParams<client.AdEntriesXApplicationFlagsSortEnum>
) {
  const id = params.id as number;
  const {
    pagination: { page, perPage },
    sort: { field, order },
    filter,
    target,
  } = params;

  const {
    adEntriesXApplicationFlagsList: { items: data, total },
  } = await sdk.getListAdEntriesXApplicationFlags({
    page: {
      offset: (page - 1) * perPage,
      limit: perPage,
    },
    sort: [{ field, order }],
    filter: { ...filter, [target]: id },
  });

  return {
    data,
    total,
  };
}

/*********************************************************************/
async function create(
  sdk: Sdk,
  params: types.CreateParams<
    NonNullable<
      client.GetOneAdEntriesXApplicationFlagsQuery["adEntriesXApplicationFlags"]
    >
  >
) {
  const { data } = params;
  const input: EnsureAllKeys<
    client.CreateAdEntriesXApplicationFlagsMutationVariables["input"]
  > = {
    userId: data.userId,
    applicationFlagId: data.applicationFlagId,
  };

  const {
    addAdEntriesXApplicationFlags: { id },
  } = await sdk.createAdEntriesXApplicationFlags({
    input,
  });
  return await getOne(sdk, { id: id! });
}

/*********************************************************************/
async function update(
  sdk: Sdk,
  params: types.UpdateParams<
    NonNullable<
      client.GetOneAdEntriesXApplicationFlagsQuery["adEntriesXApplicationFlags"]
    >
  >
) {
  const id = params.id as number;
  const { data } = params;

  const input: EnsureAllKeys<
    client.UpdateAdEntriesXApplicationFlagsMutationVariables["input"]
  > = {
    userId: data.userId,
    applicationFlagId: data.applicationFlagId,
  };

  await sdk.updateAdEntriesXApplicationFlags({
    id,
    input,
  });
  return await getOne(sdk, { id });
}

async function deleteOne(sdk: Sdk, params: types.DeleteParams) {
  const id = Number(params.id);
  await sdk.deleteAdEntriesXApplicationFlags({
    id,
  });
  return { data: { id } };
}

/*********************************************************************/
export const adEntriesXApplicationFlags = types.dataProvider({
  getList,
  getOne,
  getMany,
  getManyReference,
  delete: deleteOne,
  create,
  update,
});
