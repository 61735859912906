import { useState } from "react";
import { NO_WHITESPACE_REGEX } from "frontend/src/utils/constants";
import {
  useDataProvider,
  useInput,
  DataProvider,
  regex,
  required,
} from "react-admin";
import { Paper, TextField, Autocomplete } from "@mui/material";
import { useDebouncedCallback } from "use-debounce";

interface IdName {
  id: number;
  name: string;
}

const validateRequiredNoWhitespace = [
  required(),
  regex(NO_WHITESPACE_REGEX, "Please remove leading and trailing spaces"),
];

export const fetchFirmOptions =
  (dataProvider: DataProvider, handler: (options: IdName[]) => void) =>
  (q: string) => {
    dataProvider
      .getList("firm", {
        filter: { q, includeCompanies: true },
        sort: {
          field: "name",
          order: "ASC",
        },
        pagination: {
          page: 1,
          perPage: 10,
        },
      })
      .then(({ data }) => {
        handler(data.map(({ id, name }) => ({ id: Number(id), name })));
      });
  };

export const FirmNameInput = ({
  isRequired,
  isDisabled,
}: {
  isRequired: boolean;
  isDisabled: boolean;
}) => {
  const dataProvider = useDataProvider();
  const [firmOptions, setFirmOptions] = useState<IdName[]>([]);
  const setFirmOptionsDebounced = useDebouncedCallback(
    fetchFirmOptions(dataProvider, setFirmOptions),
    300
  );

  const {
    field: { onChange },
    fieldState: { isTouched, error },
  } = useInput({ source: "name", validate: validateRequiredNoWhitespace });

  return (
    <Autocomplete
      options={firmOptions.map(({ name }) => name)}
      freeSolo
      getOptionDisabled={() => true}
      renderInput={props => {
        return (
          <TextField
            {...props}
            onChange={onChange}
            error={!!(isTouched && error)}
            helperText={error?.message}
            required={isRequired}
            disabled={isDisabled}
            variant="outlined"
            label="Firm Name"
            name="name"
          />
        );
      }}
      onInputChange={(event, value, reason) => {
        switch (reason) {
          case "input":
            setFirmOptionsDebounced(value);
            break;
          case "clear":
            setFirmOptionsDebounced("");
            break;
        }
      }}
      PaperComponent={({ children, ...props }) => {
        console.log("papercomponent rendering", firmOptions);

        return !firmOptions.length ? null : (
          <Paper {...props}>
            <div>
              &emsp;Please review existing firms to ensure you are not creating
              a duplicate firm:
            </div>
            {children}
          </Paper>
        );
      }}
    />
  );
};
