import * as client from "_graphql-types";
import {
  List,
  TopToolbar,
  FilterButton,
  CreateButton,
  ExportButton,
} from "react-admin";
import { FdpLoginFilter, filters } from "./Filter";
import { FDPEditableDataGrid } from "./DataGrid";
import {
  DeleteRowButton,
  EditRowButton,
} from "@react-admin/ra-editable-datagrid";
import { ManageRelationshipsButton } from "./DataGridActions";

type Record = NonNullable<client.GetOneFdpLoginQuery["fdpLogin"]>;

const FDPLoginForm = () => {
  const CustomActions = () => (
    <>
      <EditRowButton />
      <DeleteRowButton />
      <ManageRelationshipsButton />
    </>
  );

  return (
    <>
      <FDPEditableDataGrid
        referenceKey="fdpLoginList"
        noDelete={false}
        bulkActionButtons={false}
        actions={<CustomActions />}
      />
    </>
  );
};

export const FdpLoginList = () => {
  return (
    <>
      <List
        // TODO - disabled should use canEdit after useCanAccessMutation is fixed
        actions={
          <TopToolbar>
            <FilterButton filters={filters} />
            <CreateButton disabled={false} />
            <ExportButton />
          </TopToolbar>
        }
        disableSyncWithLocation
        empty={false}
        filters={<FdpLoginFilter />}
        sort={{ field: "username", order: "ASC" }}
        sx={{
          ".RaFilter-form": {
            marginBottom: "1rem",
          },
        }}
      >
        <FDPLoginForm />
      </List>
    </>
  );
};
