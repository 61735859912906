import { Datagrid, EditButton, List, TextField } from "react-admin";

import { FirmFilter } from "./Filter";
import { ListActions } from "./ListActions";

export const FirmList = (props: any) => {
  return (
    <List
      sx={{
        ".RaFilter-form": {
          flex: "0 1 50%",
        },
      }}
      {...props}
      filters={<FirmFilter />}
      sort={{ field: "nameSearchRank", order: "ASC" }}
      actions={<ListActions />}
    >
      <Datagrid rowClick="edit" bulkActionButtons={false}>
        <TextField label="ID" source="id" />
        <TextField label="Name" source="name" />
        <EditButton data-cy="firm-edit" />
      </Datagrid>
    </List>
  );
};
