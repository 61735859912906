import * as client from "_graphql-types";
import * as types from "./types";
import { getChangedFields } from "../helpers/diff";

type Sdk = ReturnType<typeof client.getSdk>;

/*********************************************************************/
// Data Provider Functions
/*********************************************************************/

/*********************************************************************/
async function getList(
  sdk: Sdk,
  params: types.GetListParams<client.CustomIndexInvestmentSortEnum>
) {
  const {
    pagination: { page, perPage },
    sort: { field, order },
    filter,
  } = params;

  const {
    customIndexInvestmentList: { items: data, total },
  } = await sdk.getListCustomIndexInvestment({
    page: {
      offset: (page - 1) * perPage,
      limit: perPage,
    },
    sort: [{ field, order }],
    filter,
  });

  return {
    data,
    total,
  };
}

/*********************************************************************/
async function getOne(sdk: Sdk, params: types.GetOneParams) {
  const id = Number(params.id);
  const { customIndexInvestment: data } = await sdk.getOneCustomIndexInvestment(
    {
      id,
    }
  );
  return {
    data,
  };
}

/*********************************************************************/
async function getMany(sdk: Sdk, params: types.GetManyParams) {
  const ids = params.ids.map(Number);

  const { customIndexInvestmentMany: data } =
    await sdk.getManyCustomIndexInvestment({
      ids,
    });

  return {
    data,
  };
}

/*********************************************************************/
async function getManyReference(
  sdk: Sdk,
  params: types.GetManyReferenceParams<client.CustomIndexInvestmentSortEnum>
) {
  const id = params.id;
  const {
    pagination: { page, perPage },
    sort: { field, order },
    filter,
    target,
  } = params;

  const {
    customIndexInvestmentList: { items: data, total },
  } = await sdk.getListCustomIndexInvestment({
    page: {
      offset: (page - 1) * perPage,
      limit: perPage,
    },
    sort: [{ field, order }],
    filter: { ...filter, [target]: id },
  });

  return {
    data,
    total,
  };
}

/*********************************************************************/
async function update(
  sdk: Sdk,
  params: types.UpdateParams<
    NonNullable<
      client.GetOneCustomIndexInvestmentQuery["customIndexInvestment"]
    >
  >
) {
  const id = Number(params.id);
  const { data } = params;

  const groups = types
    .required(data, "groups")
    .items.map(({ terms, id, geReturnDate, leReturnDate }) => ({
      id,
      geReturnDate,
      leReturnDate,
      terms: terms.items.map(
        ({
          additionFactor,
          additionFactorAfter,
          id,
          inputInvestmentId,
          laggedMonths,
          multiplierFactor,
        }) => ({
          additionFactor,
          additionFactorAfter,
          id,
          inputInvestmentId,
          laggedMonths,
          multiplierFactor,
        })
      ),
    }));

  const input: client.UpdateCustomIndexInvestmentMutationVariables["input"] = {
    groups,
  };

  await sdk.updateCustomIndexInvestment({
    id,
    input,
  });
  return await getOne(sdk, { id });
}

/*********************************************************************/
async function create(
  sdk: Sdk,
  params: types.CreateParams<
    NonNullable<
      client.GetOneCustomIndexInvestmentQuery["customIndexInvestment"]
    > & { outputInvestmentId?: number }
  >
) {
  const { data } = params;

  const { outputInvestmentId } = data;
  const groups = types
    .required(data, "groups")
    .items.map(({ terms, id, geReturnDate, leReturnDate }) => ({
      id,
      geReturnDate,
      leReturnDate,
      terms: terms.items.map(
        ({
          additionFactor,
          additionFactorAfter,
          id,
          inputInvestmentId,
          laggedMonths,
          multiplierFactor,
        }) => ({
          additionFactor,
          additionFactorAfter,
          id,
          inputInvestmentId,
          laggedMonths,
          multiplierFactor,
        })
      ),
    }));

  const input: client.CreateCustomIndexInvestmentMutationVariables["input"] = {
    outputInvestmentId,
    groups,
  };

  const {
    addCustomIndexInvestment: { id },
  } = await sdk.createCustomIndexInvestment({
    input,
  });
  return await getOne(sdk, { id });
}

/*********************************************************************/
export const customIndexInvestment = types.dataProvider({
  getList,
  getOne,
  getMany,
  getManyReference,
  create,
  update,
});
