import {
  Datagrid,
  DeleteButton,
  EditButton,
  List,
  TextField,
  useResourceDefinition,
} from "react-admin";
import { PortalOrganizationFilter } from "./Filter";
import { ListActions } from "./ListActions";

export const PortalOrganizationList = () => {
  const { name } = useResourceDefinition();
  return (
    <List
      sx={{
        ".RaFilter-form": {
          flex: "0 1 50%",
        },
      }}
      resource={name}
      filters={<PortalOrganizationFilter />}
      sort={{ field: "nameSearchRank", order: "ASC" }}
      actions={<ListActions />}
      data-cy="portalOrganization__list"
    >
      <Datagrid rowClick="edit" bulkActionButtons={false}>
        <TextField
          data-cy="portalOrganization__list_ID"
          label="ID"
          source="id"
        />
        <TextField
          data-cy="portalOrganization__list_Name"
          label="Name"
          source="name"
        />
        <EditButton />
        <DeleteButton />
      </Datagrid>
    </List>
  );
};
