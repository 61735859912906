import {
  Filter,
  TextInput,
  AutocompleteInput,
  BooleanInput,
  ReferenceInput,
} from "react-admin";

export const InvestmentFilter = (props: any) => (
  <Filter {...props}>
    <TextInput
      label="Name Search"
      source="q"
      alwaysOn
      style={{ width: 400 }}
      resettable
    />
    <ReferenceInput
      label="Firm"
      source="firmId"
      reference="firm"
      sort={{ field: "name", order: "ASC" }}
      allowEmpty
      alwaysOn
    >
      <AutocompleteInput optionText="name" style={{ width: 250 }} />
    </ReferenceInput>
    <BooleanInput
      label="Include View Only Entries"
      source="allowRead"
      alwaysOn
    />
    <BooleanInput label="Active" source="active" alwaysOn />
  </Filter>
);
