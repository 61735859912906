import { ExportButton, SortButton, TopToolbar } from "react-admin";

export const ListActions = () => (
  <TopToolbar>
    <SortButton
      fields={[
        "nameSearchRank",
        "email",
        "firstName",
        "lastName",
        "organizationName",
      ]}
    />
    <ExportButton maxResults={250}/>
  </TopToolbar>
);
