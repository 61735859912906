import React, { useEffect } from "react";
import { ReferenceInput, SelectInput, useInput } from "react-admin";
import { useWatch } from "react-hook-form";
import * as client from "_graphql-types";

const US_COUNTRY_ID = 226;

export function DomicileLocationInput({
  canEditField,
}: {
  canEditField: (field: keyof client.InvestmentInput) => boolean;
}) {
  const domicileCountryId = useWatch({ name: "domicileCountryId" });

  const {
    field: { onChange },
  } = useInput({
    source: "domicileUSStateId",
  });

  useEffect(() => {
    if (domicileCountryId !== US_COUNTRY_ID) {
      onChange(null);
    }
  }, [domicileCountryId]);

  return (
    <>
      <ReferenceInput
        source="domicileCountryId"
        reference="countryEnum"
        perPage={1000}
        sort={{ field: "name", order: "ASC" }}
      >
        <SelectInput
          disabled={!canEditField("domicileCountryId")}
          optionText="name"
          label="Domicile Country"
        />
      </ReferenceInput>

      {domicileCountryId === US_COUNTRY_ID && (
        <ReferenceInput
          source="domicileUSStateId"
          reference="stateEnum"
          perPage={1000}
          sort={{ field: "name", order: "ASC" }}
        >
          <SelectInput
            disabled={!canEditField("domicileUSStateId")}
            optionText="name"
            label="Domicile State"
          />
        </ReferenceInput>
      )}
    </>
  );
}
