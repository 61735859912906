import * as client from "_graphql-types";
import * as types from "./types";

type Sdk = ReturnType<typeof client.getSdk>;

/*********************************************************************/
// Data Provider Functions
/*********************************************************************/

/*********************************************************************/
async function getList(
  sdk: Sdk,
  params: types.GetListParams<client.InvestmentInstitutionsSortEnum>
) {
  const {
    pagination: { page, perPage },
    sort: { field, order },
    filter,
  } = params;

  const {
    investmentInstitutionsList: { items: data, total },
  } = await sdk.getListInvestmentInstitutions({
    page: {
      offset: (page - 1) * perPage,
      limit: perPage,
    },
    sort: [{ field, order }],
    filter,
  });

  return {
    data,
    total,
  };
}

/*********************************************************************/
async function getOne(sdk: Sdk, params: types.GetOneParams) {
  const id = params.id;
  const { investmentInstitutions: data } =
    await sdk.getOneInvestmentInstitutions({
      id: Number(id),
    });
  return {
    data,
  };
}

/*********************************************************************/
async function getMany(sdk: Sdk, params: types.GetManyParams) {
  const ids = params.ids;

  const { investmentInstitutionsMany: data } =
    await sdk.getManyInvestmentInstitutions({
      ids: ids.map(Number),
    });

  return {
    data,
  };
}

/*********************************************************************/
async function getManyReference(
  sdk: Sdk,
  params: types.GetManyReferenceParams<client.InvestmentInstitutionsSortEnum>
) {
  const id = params.id;
  const {
    pagination: { page, perPage },
    sort: { field, order },
    filter,
    target,
  } = params;

  const {
    investmentInstitutionsList: { items: data, total },
  } = await sdk.getListInvestmentInstitutions({
    page: {
      offset: (page - 1) * perPage,
      limit: perPage,
    },
    sort: [{ field, order }],
    filter: { ...filter, [target]: id },
  });

  return {
    data,
    total,
  };
}

/*********************************************************************/
async function create(
  sdk: Sdk,
  params: types.CreateParams<
    client.CreateInvestmentInstitutionsMutationVariables["input"]
  >
) {
  const { data } = params;
  const input: client.CreateInvestmentInstitutionsMutationVariables["input"] = {
    ...data,
  };

  const {
    addInvestmentInstitutions: { id },
  } = await sdk.createInvestmentInstitutions({
    input,
  });
  return await getOne(sdk, { id: String(id) });
}

/*********************************************************************/
async function update(
  sdk: Sdk,
  params: types.UpdateParams<
    client.UpdateInvestmentInstitutionsMutationVariables["input"] &
      client.GetOneInvestmentInstitutionsQuery["investmentInstitutions"]
  >
) {
  const id = params.id;
  const { data } = params;
  const {
    id: _,
    officeLocationServiceType,
    investment,
    institution,
    ...inputData
  } = data;

  const input: client.UpdateInvestmentInstitutionsMutationVariables["input"] = {
    ...inputData,
  };

  await sdk.updateInvestmentInstitutions({
    id: Number(id),
    input,
  });
  return await getOne(sdk, { id: String(id) });
}

/*********************************************************************/

async function del(sdk: Sdk, params: types.DeleteParams) {
  const id = Number(params.id);
  await sdk.deleteInvestmentInstitutions({ id });
  return { data: { id } };
}

/*********************************************************************/

export const investmentInstitutions = types.dataProvider({
  getList,
  getOne,
  getMany,
  getManyReference,
  create,
  update,
  delete: del,
});
