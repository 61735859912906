import { CustomEdit } from "../CustomEdit";
import { Form, Record } from "./Form";

export const InvestmentInstitutionsEdit = () => {
  return (
    <CustomEdit<Record>
      customFormProps={{
        customToolbarProps: {
          allowDelete: true,
        },
      }}
      title={record =>
        record && `Investment Service Provider Link ${record.id || ""}`
      }
    >
      <Form />
    </CustomEdit>
  );
};
