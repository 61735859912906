import * as client from "_graphql-types";
import * as types from "./types";

import { filterSortPage } from "../DataProvider";

type Sdk = ReturnType<typeof client.getSdk>;

/*********************************************************************/
// Data Provider Functions
/*********************************************************************/

/*********************************************************************/
async function getList(
  sdk: Sdk,
  params: types.GetListParams<client.AgencySortEnum>
) {
  const { pagination, sort } = params;

  const {
    agencyList: { items: data, total },
  } = await sdk.getListAgency(
    filterSortPage({
      pagination,
      sort,
      nameField: client.AgencySortEnum.Name,
    })
  );

  return {
    data,
    total,
  };
}

/*********************************************************************/
async function getOne(sdk: Sdk, params: types.GetOneParams) {
  const id = Number(params.id);
  const { agency: data } = await sdk.getOneAgency({
    id,
  });
  return {
    data,
  };
}

/*********************************************************************/
async function getMany(sdk: Sdk, params: types.GetManyParams) {
  const ids = params.ids.map(Number);

  const { agencyMany: data } = await sdk.getManyAgency({
    ids,
  });

  return {
    data,
  };
}

/*********************************************************************/
export const agency = types.dataProvider({
  getList,
  getOne,
  getMany,
});
