import * as client from "_graphql-types";
import * as types from "./types";
import { EnsureAllKeys } from "../helpers/types";

type Sdk = ReturnType<typeof client.getSdk>;

type ShareClassInput = EnsureAllKeys<client.MutationAddShareClassArgs["input"]>;
type SoftLockupInput = NonNullable<
  NonNullable<ShareClassInput["softLockups"]>
>[number];

/*********************************************************************/
// Data Provider Functions
/*********************************************************************/

/*********************************************************************/
async function getList(
  sdk: Sdk,
  params: types.GetListParams<client.ShareClassSortEnum>
) {
  const {
    pagination: { page, perPage },
    sort: { field, order },
    filter,
  } = params;

  const {
    shareClassList: { items: data, total },
  } = await sdk.getListShareClass({
    page: {
      offset: (page - 1) * perPage,
      limit: perPage,
    },
    sort: [{ field, order }],
    filter,
  });

  return {
    data,
    total,
  };
}

/*********************************************************************/
async function getOne(sdk: Sdk, params: types.GetOneParams) {
  const id = Number(params.id);
  const { shareClass: data } = await sdk.getOneShareClass({
    id,
  });
  return {
    data,
  };
}

/*********************************************************************/
async function getMany(sdk: Sdk, params: types.GetManyParams) {
  const ids = params.ids.map(Number);

  const { shareClassMany: data } = await sdk.getManyShareClass({
    ids,
  });

  return {
    data,
  };
}

/*********************************************************************/
async function getManyReference(
  sdk: Sdk,
  params: types.GetManyReferenceParams<client.ShareClassSortEnum>
) {
  const id = params.id;
  const {
    pagination: { page, perPage },
    sort: { field, order },
    filter,
    target,
  } = params;

  const {
    shareClassList: { items: data, total },
  } = await sdk.getListShareClass({
    page: {
      offset: (page - 1) * perPage,
      limit: perPage,
    },
    sort: [{ field, order }],
    filter: { ...filter, [target]: id },
  });

  return {
    data,
    total,
  };
}

function getInputFromData(
  data: Partial<NonNullable<client.GetOneShareClassQuery["shareClass"]>>
): EnsureAllKeys<client.MutationAddShareClassArgs["input"]> {
  const input: EnsureAllKeys<client.MutationAddShareClassArgs["input"]> = {
    investmentId: data.investment?.id || data.investmentId,
    name: data.name,
    otherFees: data.otherFees,
    restricted: data.restricted,
    FAS157: data.FAS157 || null,
    status: data.status,
    isRcgNegotiated: data.isRcgNegotiated,
    investmentFees: data.investmentFees
      ? {
          mgmtFee: data.investmentFees?.mgmtFee,
          mgmtFeeCalcEnumId: data.investmentFees?.mgmtFeeCalcEnumId,
          mgmtFeeFreqEnumId: data.investmentFees?.mgmtFeeFreqEnumId,
          incentiveFee: data.investmentFees?.incentiveFee,
          incentiveFeeCalcEnumId: data.investmentFees?.incentiveFeeCalcEnumId,
          incentiveFeeFreqEnumId: data.investmentFees?.incentiveFeeFreqEnumId,
          otherFee: data.investmentFees?.otherFee,
          otherFeeCalcEnumId: data.investmentFees?.otherFeeCalcEnumId,
          otherFeeFreqEnumId: data.investmentFees?.otherFeeFreqEnumId,
          highWaterMark: data.investmentFees?.highWaterMark,
          hurdle: data.investmentFees?.hurdle,
          hurdleBenchmark: data.investmentFees?.hurdleBenchmark,
          annualReset: data.investmentFees?.annualReset,
          peMgmtFeeCalcEnumId: data.investmentFees?.peMgmtFeeCalcEnumId,
          peIncentiveFeeCalcEnumId:
            data.investmentFees?.peIncentiveFeeCalcEnumId,
          feeStructureChanged: data.investmentFees?.feeStructureChanged,
          feeStructureChangedNotes:
            data.investmentFees?.feeStructureChangedNotes,
          standardShareClassRates: data.investmentFees?.standardShareClassRates,
          abnormalFees: data.investmentFees?.abnormalFees,
          abnormalFeesNotes: data.investmentFees?.abnormalFeesNotes,
          hasMgmtFeeStepDown: data.investmentFees?.hasMgmtFeeStepDown,
          peMgmtFeeStepDownPercent:
            data.investmentFees?.peMgmtFeeStepDownPercent,
          peMgmtFeeStepDownCalcEnumId:
            data.investmentFees?.peMgmtFeeStepDownCalcEnumId,
          peMgmtFeeDuringHarvest: data.investmentFees?.peMgmtFeeDuringHarvest,
          peMgmtFeeDuringHarvestCalcEnumId:
            data.investmentFees?.peMgmtFeeDuringHarvestCalcEnumId,
          peMgmtFeeDuringCommitment:
            data.investmentFees?.peMgmtFeeDuringCommitment,
          peMgmtFeeDuringCommitmentCalcEnumId:
            data.investmentFees?.peMgmtFeeDuringCommitmentCalcEnumId,
        }
      : undefined,

    peLiquidity: data.peLiquidity
      ? {
          preferredReturn: data.peLiquidity?.preferredReturn,
          isCompounded: data.peLiquidity?.isCompounded,
          compoundingPeriodId: data.peLiquidity?.compoundingPeriodId,
          hasCatchupProvision: data.peLiquidity?.hasCatchupProvision,
          catchupPercent: data.peLiquidity?.catchupPercent,
          optionOfExtensionId: data.peLiquidity?.optionOfExtensionId,
        }
      : undefined,
    liquidity: data.liquidity
      ? {
          hasGate: data.liquidity?.hasGate,
          gatePercentNav: data.liquidity?.gatePercentNav,
          gatePercentCapital: data.liquidity?.gatePercentCapital,
          exceptions: data.liquidity?.exceptions,
          hasLockup: data.liquidity?.hasLockup,
          lockupValue: data.liquidity?.lockupValue,
          lockupEndValueId: data.liquidity?.lockupEndValueId,
          lockupEndBasisDate: data.liquidity?.lockupEndBasisDate,
          lockupEndBizDays: data.liquidity?.lockupEndBizDays,
          secondLockupValue: data.liquidity?.secondLockupValue,
          secondLockupUnitId: data.liquidity?.secondLockupUnitId,
          secondLockupEndValueId: data.liquidity?.secondLockupEndValueId,
          secondLockupEndBasisDate: data.liquidity?.secondLockupEndBasisDate,
          secondLockupEndBizDays: data.liquidity?.secondLockupEndBizDays,
          liquidityValue: data.liquidity?.liquidityValue,
          liquidityUnitId: data.liquidity?.liquidityUnitId,
          noticeValue: data.liquidity?.noticeValue,
          noticeBizDays: data.liquidity?.noticeBizDays,
          subscriptionValue: data.liquidity?.subscriptionValue,
          subscriptionUnitId: data.liquidity?.subscriptionUnitId,
          cashAvailableDays: data.liquidity?.cashAvailableDays,
          hasHoldback: data.liquidity?.hasHoldback,
          holdbackPercent: data.liquidity?.holdbackPercent,
          workoutId: data.liquidity?.workoutId,
          masterLevelFundGate: data.liquidity?.masterLevelFundGate,
          lockupMonthlyValue: data.liquidity?.lockupMonthlyValue,
          liquidityMonthlyValue: data.liquidity?.liquidityMonthlyValue,
          modelingNotes: data.liquidity?.modelingNotes,
        }
      : undefined,
    softLockups: data.softLockups?.map(
      (softLockup): SoftLockupInput => ({
        id: softLockup.id,
        termBeginMonth: softLockup.termBeginMonth,
        termEndMonth: softLockup.termEndMonth,
        redemptionFee: softLockup.redemptionFee,
        lockupEndRedemptionOnId: softLockup.lockupEndRedemptionOnId,
        secondLockupEndRedemptionOnId: softLockup.secondLockupEndRedemptionOnId,
        redemptionOnBizDays: softLockup.redemptionOnBizDays,
        liquidityValue: softLockup.liquidityValue,
        liquidityUnitId: softLockup.liquidityUnitId,
        noticeValue: softLockup.noticeValue,
        noticeBizDays: softLockup.noticeBizDays,
        cashAvailableDays: softLockup.cashAvailableDays,
        holdBackPercent: softLockup.holdBackPercent,
      })
    ),
  };

  return input;
}

/*********************************************************************/
async function create(
  sdk: Sdk,
  params: types.CreateParams<
    NonNullable<client.GetOneShareClassQuery["shareClass"]>
  >
) {
  const { data } = params;
  const input = getInputFromData(data);

  const {
    addShareClass: { id },
  } = await sdk.createShareClass({
    input,
  });
  return await getOne(sdk, { id: id! });
}

/*********************************************************************/
async function update(
  sdk: Sdk,
  params: types.UpdateParams<
    NonNullable<client.GetOneShareClassQuery["shareClass"]>
  >
) {
  const id = Number(params.id);
  const { data } = params;

  const input = getInputFromData(data);

  await sdk.updateShareClass({
    id,
    input,
  });
  return await getOne(sdk, { id });
}

/*********************************************************************/
export const shareClass = types.dataProvider({
  getList,
  getOne,
  getMany,
  getManyReference,
  create,
  update,
});
