import { Grid } from "@mui/material";
import * as client from "_graphql-types";
import { useEffect } from "react";
import {
  AutocompleteInput,
  BooleanInput,
  DateInput,
  ReferenceInput,
  required,
  useInput,
  usePermissions,
  useRecordContext,
} from "react-admin";
import { Link, useSearchParams } from "react-router-dom";
import { Permissions } from "../../components/CustomAdmin";

export type Record = NonNullable<
  client.GetOneInstitutionEmploymentQuery["institutionEmployment"]
>;

const VALIDATE_REQUIRED = [required()];

export function Form() {
  const record = useRecordContext<Record>();
  const [params] = useSearchParams();

  const institutionIdFromParams = params.get("institutionId");
  const personIdFromParams = params.get("personId");

  const {
    field: { onChange: onChangePersonId },
  } = useInput({ source: "personId" });

  const {
    field: { onChange: onChangeInstitutionId },
  } = useInput({ source: "institutionId" });

  useEffect(() => {
    if (institutionIdFromParams)
      onChangeInstitutionId(Number(institutionIdFromParams));
  }, [institutionIdFromParams]); //including onChange causes refresh issue

  useEffect(() => {
    if (personIdFromParams) onChangePersonId(Number(personIdFromParams));
  }, [personIdFromParams]); //including onChange causes refresh issue

  const { permissions } = usePermissions<Permissions>();

  return (
    <Grid container spacing={2}>
      <Grid xs={6} item>
        {record ? (
          <div style={{ marginBottom: "10px" }}>
            <Link to={`/officeLocation/${record.institutionId}/show`}>
              Go To Office Location
            </Link>
          </div>
        ) : (
          <div style={{ marginBottom: "30px" }} />
        )}
        <ReferenceInput
          reference="officeLocation"
          source={"institutionId"}
          sort={{ field: "name", order: "ASC" }}
        >
          <AutocompleteInput
            validate={VALIDATE_REQUIRED}
            optionText="name"
            label="Institution"
            fullWidth
            readOnly={!!(record || institutionIdFromParams)}
          />
        </ReferenceInput>
      </Grid>
      <Grid xs={6} item>
        {record ? (
          <div style={{ marginBottom: "10px" }}>
            <Link to={`/person/${record.personId}`}>Go To Person</Link>
          </div>
        ) : (
          <div style={{ marginBottom: "30px" }} />
        )}
        <ReferenceInput
          reference="person"
          source={"personId"}
          sort={{ field: "lastName", order: "ASC" }}
        >
          <AutocompleteInput
            optionText="fullName"
            label="Person"
            fullWidth
            readOnly={!!(record || personIdFromParams)}
            validate={VALIDATE_REQUIRED}
          />
        </ReferenceInput>
      </Grid>

      <Grid xs={6} item>
        <DateInput source="startDate" />
      </Grid>

      <Grid xs={6} item>
        <DateInput source="leftDate" />
      </Grid>

      <Grid xs={6} item>
        <BooleanInput source="isPrimaryContact" />
      </Grid>
    </Grid>
  );
}
