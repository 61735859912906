import { useCurrentUser } from "frontend/src/utils/hooks";
import { useState } from "react";
import { BusinessApplicantV1 } from "./TermsOfServiceComponents/businessApplicant";

enum Versions {
  v1 = "v1",
}

const versions = {
  businessApplicant: { [Versions.v1]: BusinessApplicantV1 },
};

const currentVersions = { businessApplicant: Versions.v1 };

const getTermKey = (userId: number, version: Versions | null) =>
  `AgreedToTermsOfServicePolicy::${userId}::${version}`;

export const TermsOfService = ({
  children,
}: {
  children: JSX.Element | JSX.Element[] | null;
}) => {
  const currentUser = useCurrentUser();

  const {
    id: userId,
    flags: { businessApplicant },
  } = currentUser;
  const version = businessApplicant
    ? currentVersions["businessApplicant"]
    : null;

  const [agreed, setAgreed] = useState(version || true);

  if (
    version &&
    businessApplicant &&
    localStorage.getItem(getTermKey(userId, version)) !== "true"
  ) {
    const setAgreedStorage = () => {
      localStorage.setItem(getTermKey(userId, version), "true");
      setAgreed(true);
    };
    const Component = versions["businessApplicant"][version];
    return (
      <div style={{ margin: "2em" }}>
        <Component accept={setAgreedStorage} />
      </div>
    );
  }

  if (!userId) {
    return <></>;
  }

  return <>{children}</>;
};
