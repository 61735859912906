import * as client from "_graphql-types";
import { CustomCreate } from "../CustomCreate";
import { Form } from "./Form";

import { useCanAccessMutation } from "../../util/useCanAccessMutation";

type Record = NonNullable<client.GetOneSetQuery["set"]>;

export const SetCreate = () => {
  const mutationArgs = JSON.stringify({});

  const { canEdit, canEditField, loading } = useCanAccessMutation(
    "addSet",
    mutationArgs
  );

  return (
    <CustomCreate
      title={() => "Create Set"}
      customFormProps={{
        customToolbarProps: {
          canAccessMutation: canEdit,
        },
        loading,
        canEditField,
      }}
    >
      <>
        <Form />
      </>
    </CustomCreate>
  );
};
