import * as client from "_graphql-types";
import * as types from "./types";

type Sdk = ReturnType<typeof client.getSdk>;

/*********************************************************************/
// Data Provider Functions
/*********************************************************************/

/*********************************************************************/
async function getList(
  sdk: Sdk,
  params: types.GetListParams<client.EmailDomainXOrganizationSortEnum>
) {
  const {
    pagination: { page, perPage },
    sort: { field, order },
    filter,
  } = params;

  const {
    emailDomainXOrganizationList: { items: data, total },
  } = await sdk.getListEmailDomainXOrganization({
    page: {
      offset: (page - 1) * perPage,
      limit: perPage,
    },
    sort: [{ field, order }],
    filter,
  });

  return {
    data,
    total,
  };
}

/*********************************************************************/
async function getOne(sdk: Sdk, params: types.GetOneParams) {
  const id = Number(params.id);
  const { emailDomainXOrganization: data } =
    await sdk.getOneEmailDomainXOrganization({
      id,
    });
  return {
    data,
  };
}

/*********************************************************************/
async function getMany(sdk: Sdk, params: types.GetManyParams) {
  const ids = params.ids.map(id => Number(id));

  const { emailDomainXOrganizationMany: data } =
    await sdk.getManyEmailDomainXOrganization({
      ids,
    });

  return {
    data,
  };
}

/*********************************************************************/
async function getManyReference(
  sdk: Sdk,
  params: types.GetManyReferenceParams<client.EmailDomainXOrganizationSortEnum>
) {
  const id = Number(params.id);
  const {
    pagination: { page, perPage },
    sort: { field, order },
    filter,
    target,
  } = params;

  const {
    emailDomainXOrganizationList: { items: data, total },
  } = await sdk.getListEmailDomainXOrganization({
    page: {
      offset: (page - 1) * perPage,
      limit: perPage,
    },
    sort: [{ field, order }],
    filter: { ...filter, [target]: id },
  });

  return {
    data,
    total,
  };
}

/*********************************************************************/
async function create(
  sdk: Sdk,
  params: types.CreateParams<
    client.GetOneEmailDomainXOrganizationQuery["emailDomainXOrganization"]
  >
) {
  const { data } = params;
  const input: client.CreateEmailDomainXOrganizationMutationVariables["input"] =
    {
      emailDomain: data?.emailDomain,
      organizationId: data?.organization && Number(data?.organization.id),
    };

  const {
    addEmailDomainXOrganization: { id },
  } = await sdk.createEmailDomainXOrganization({
    input,
  });
  return await getOne(sdk, { id: id! });
}

/*********************************************************************/
async function update(
  sdk: Sdk,
  params: types.UpdateParams<
    NonNullable<
      client.GetOneEmailDomainXOrganizationQuery["emailDomainXOrganization"]
    >
  >
) {
  const id = Number(params.id);
  const { data } = params;

  const input: client.UpdateEmailDomainXOrganizationMutationVariables["input"] =
    {
      emailDomain: data?.emailDomain,
      organizationId: data?.organization && Number(data?.organization.id),
    };

  await sdk.updateEmailDomainXOrganization({
    id,
    input,
  });
  return await getOne(sdk, { id });
}

/*********************************************************************/
async function del(sdk: Sdk, params: types.DeleteParams) {
  const id = Number(params.id);

  await sdk.deleteEmailDomainXOrganization({
    id,
  });
  return { data: { id } };
}

/*********************************************************************/
export const emailDomainXOrganization = types.dataProvider({
  getList,
  getOne,
  getMany,
  getManyReference,
  create,
  update,
  delete: del,
});
