import { Grid } from "@mui/material";
import { useEffect } from "react";
import {
  DateInput,
  ReferenceInput,
  required,
  SelectInput,
  TextInput,
  useEditContext,
  useInput,
  useRecordContext,
} from "react-admin";
import { useSearchParams } from "react-router-dom";
import * as client from "../../graphql-types";

type Record = NonNullable<
  client.GetOneServiceProviderNotesQuery["serviceProviderNotes"]
>;

const validateRequired = [required()];

function Form() {
  const record = useRecordContext<Record>();
  const editContext = useEditContext<Record>();
  const [params] = useSearchParams();
  const serviceProviderFirmIdFromParams = params.get("providerId");
  const {
    field: { onChange: onserviceProviderFirmIdFromParams, value: providerId },
  } = useInput({ source: "providerId" });
  //   const { data } = useGetOne("serviceProviderNotes", { id: providerId });
  useEffect(() => {
    if (serviceProviderFirmIdFromParams)
      onserviceProviderFirmIdFromParams(
        Number(serviceProviderFirmIdFromParams)
      );
  }, [serviceProviderFirmIdFromParams]);

  const {
    field: { onChange: updateNotes },
  } = useInput({ source: "name" });

  return (
    <Grid spacing={2}>
      <Grid container spacing={2}>
        <Grid item xs={10}>
          <ReferenceInput
            reference="serviceProviderFirm"
            sort={{ field: "name", order: "ASC" }}
            source="providerId"
            pagination={{ perPage: 50 }}
          >
            <SelectInput
              optionText="name"
              label="Service Provider Firm"
              validate={validateRequired}
              disabled={
                (!record && !!serviceProviderFirmIdFromParams) || !!record
              }
            />
          </ReferenceInput>
          <DateInput source="eventDate" validate={validateRequired} />
          <ReferenceInput
            reference="globalProviderNoteTypeEnum"
            sort={{ field: "name", order: "ASC" }}
            source="providerNoteTypeEnumId"
            pagination={{ perPage: 50 }}
          >
            <SelectInput
              optionText="name"
              label="Note Type"
              validate={validateRequired}
            />
          </ReferenceInput>
          <TextInput
            label="Notes Text"
            source="noteText"
            multiline
            rows={5}
            validate={validateRequired}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Form;
