import {
  Datagrid,
  EditButton,
  FunctionField,
  TextField,
  useRecordContext,
} from "react-admin";
import * as client from "_graphql-types";
import CustomCloneButton from "../CloneButtonWithOmit";

type IRecord = NonNullable<client.GetOneShareClassQuery["shareClass"]>;

export const ShareClassDataGrid = () => {
  const record = useRecordContext<IRecord>();
  if (!record) return;

  return (
    <Datagrid
      data-cy="shareClasses"
      rowClick="edit"
      bulkActionButtons={false}
      sx={{ width: "100%" }}
    >
      <TextField source="name" sortable={false} />
      <FunctionField
        source="investmentFees.mgmtFee"
        label="Management Fee"
        sortable={false}
        render={(record: IRecord) =>
          record.investmentFees?.mgmtFee
            ? `${record.investmentFees?.mgmtFee}%`
            : ""
        }
      />
      <FunctionField
        source="investmentFees.incentiveFee"
        label="Incentive Fee"
        sortable={false}
        render={(record: IRecord) =>
          record.investmentFees?.incentiveFee
            ? `${record.investmentFees?.incentiveFee}%`
            : ""
        }
      />
      {record.investment?.marketEnumId === 2 ? (
        <>
          <FunctionField
            label="Lockup"
            render={(record: IRecord) =>
              `${record.liquidity?.lockupValue} ${record.liquidity?.lockupUnit?.name}`
            }
          />
          <FunctionField
            label="Liquidity"
            render={(record: IRecord) =>
              `${record.liquidity?.lockupValue} ${record.liquidity?.lockupUnit?.name}`
            }
          />
        </>
      ) : null}
      <EditButton data-cy="shareClasses_edit-row" />
      <CustomCloneButton<IRecord>
        pathname="/shareClass/create"
        keysToOmit={["id", "name"]}
        dataCy="shareClasses_clone-row"
      />
    </Datagrid>
  );
};
