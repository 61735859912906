import { captureException } from "@sentry/react";
import { useContext, useEffect } from "react";
import { UserActivityInput } from "_graphql-types";
import { AuthContext } from "./Auth/AuthProvider.component";
import { useDataProvider } from "./DataProviderContext";
import { useLocation, Location } from "react-router-dom";
import config from "../config";

export const HistoryLogger = () => {
  const authContext = useContext(AuthContext);
  const { dataProvider } = useDataProvider();
  const location = useLocation();
  useEffect(() => {
    const logLocation = async ({ pathname, search, hash }: Location) => {
      if (pathname.startsWith("/login")) {
        // do not log auth navigation
        return;
      }

      const email = await authContext.auth?.getEmail();
      if (!email) {
        // do not log for unauthenticated user
        return;
      }

      const addUserActivity = (input: UserActivityInput) =>
        dataProvider.request(
          `
          mutation AddUserActivity($input: UserActivityInput!) {
            addUserActivity(input: $input) {
              id
            }
          }
          `,
          { input }
        );

      const requestUri = [pathname, search, hash].join("");
      const input: UserActivityInput = {
        email,
        applicationId: 402,
        rcgApplicationVersion: config.SENTRY_RELEASE(),
        requestUri,
        requestUtc: new Date().toISOString(),
      };
      const { errors } = await addUserActivity(input);
      if (errors) {
        captureException(errors);
      }
    };
    logLocation(location);
  }, [location]);

  return null;
};
