import * as client from "_graphql-types";

import Grid from "@mui/material/Grid";
import { TextInput } from "react-admin";
import { useSearchParams } from "react-router-dom";
import { CustomCreate } from "../CustomCreate";
import { EntityInput } from "../UI/EntityInput";

type Record = client.GetOneInvestmentAxysCodeQuery["investmentAxysCode"];

function getInvestmentPath(search: URLSearchParams) {
  const source = search.get("source");
  const { investment } = JSON.parse(source ?? "{}");
  return `/investment/${investment?.id}`;
}

export const InvestmentAxysCodeCreate = () => {
  const [search] = useSearchParams();

  return (
    <CustomCreate
      title={() => "Create Investment Axys Code"}
      customFormProps={{
        customToolbarProps: {
          redirectToPathOnSave: getInvestmentPath(search),
        },
      }}
    >
      <>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} lg={4}>
            <EntityInput<NonNullable<Record>>
              resource="investment"
              recordKey="investment"
              label="Investment"
              isRequired={false}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <TextInput source="axysCode.code" label="Code" />
          </Grid>
        </Grid>
      </>
    </CustomCreate>
  );
};
