import { useRecordContext, TextFieldProps, RaRecord } from "react-admin";
import Typography from "@mui/material/Typography";

type Props<TRecord extends Record<string, any>> = TextFieldProps<TRecord> & {
  label?: string;
  source: string;
  maxChars?: number;
};

export default function TruncatedTextField<TRecord extends RaRecord>(
  props: Props<TRecord>
) {
  const recordContext = useRecordContext<TRecord>(props);
  if (!recordContext) return;
  const { source, maxChars = 200 } = props;

  let record: string = recordContext[source];
  if (record && record.length > maxChars) {
    record = recordContext[source].slice(0, maxChars) + "...";
  }

  return <Typography variant="body2">{record}</Typography>;
}
