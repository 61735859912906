import {
  CreateButton,
  ExportButton,
  SortButton,
  TopToolbar,
} from "react-admin";

export const ListActions = () => (
  <TopToolbar>
    <SortButton
      fields={[
        "nameSearchRank",
        "lastName",
        "firstName",
        "middleName",
        "email",
        "phone",
        "id",
      ]}
    />
    <CreateButton />
    <ExportButton maxResults={250}/>
  </TopToolbar>
);
