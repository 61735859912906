import * as client from "_graphql-types";

import {
  AutocompleteInput,
  BooleanInput,
  Datagrid,
  DateField,
  DateInput,
  EditButton,
  Pagination,
  ReferenceInput,
  ReferenceManyField,
  TextField,
  TextInput,
  useRecordContext,
} from "react-admin";
import { CustomEdit } from "../CustomEdit";

import Grid from "@mui/material/Grid";
import { AuthorizedTo } from "frontend/src/utils/types";
import { Button } from "react-admin";
import { Link } from "react-router-dom";
import CustomBreadCrumb from "../CustomBreadCumb";
import { EntityInput } from "../UI/EntityInput";
import { FaceOfEntity } from "./Create";
import { PersonInput } from "./PersonInput";

type Record = AuthorizedTo<client.GetOneFirmEmploymentQuery["firmEmployment"]>;

const ViewFirmButton = () => {
  const record = useRecordContext<Record>();
  if (record && record.firm) {
    if (record.firm.isCompany) {
      return (
        <Button
          component={Link}
          to={{
            pathname: `/company/${record.firm.companyId}`,
          }}
          label="Go to Company"
        />
      );
    }
    return (
      <Button
        component={Link}
        to={{
          pathname: `/firm/${record.firm.id}`,
        }}
        label="Go to Firm"
      />
    );
  }
  return null;
};

const ViewPersonButton = () => {
  const record = useRecordContext<Record>();
  return !record?.person?.id ? null : (
    <Button
      component={Link}
      to={{
        pathname: `/person/${record.person.id}`,
      }}
      label="Go to Person"
    />
  );
};

const CreateRelatedInvestmentFirmEmploymentButton = () => {
  const record = useRecordContext<Record>();
  return !record ? null : (
    <Button
      component={Link}
      to={{
        pathname: "/investmentFirmEmployment/create",
        search: `?source=${JSON.stringify({
          firmEmployment: {
            id: record.id,
            firm: {
              id: record.firm.id,
              name: encodeURIComponent(record.firm.name),
            },
            person: {
              lastName:
                record.person.lastName &&
                encodeURIComponent(record.person.lastName),
              firstName:
                record.person.firstName &&
                encodeURIComponent(record.person.firstName),
              middleName:
                record.person.middleName &&
                encodeURIComponent(record.person.middleName),
              id: record.person.id,
            },
          },
        })}`,
      }}
      label="Add Investment Assignment"
      title="Relate this Firm Employment to an Investment"
      data-id="createInvestmentFirmEmployment"
    />
  );
};

export const FirmEmploymentEdit = () => (
  <CustomEdit<Record>
    title={record => record && `Firm Employment (${record.id})`}
    actions={
      <CustomBreadCrumb<Record>
        name="firmEmployment"
        items={[
          {
            path: "firm",
            getId: record => record?.firm?.id,
            getName: record => record?.firm?.name,
          },
          {
            path: "person",
            getId: record => record?.person.id,
            getName: record =>
              record?.person?.id
                ? `${record?.person.firstName} ${record?.person.lastName}`
                : undefined,
          },
        ]}
      />
    }
    customFormProps={{
      customToolbarProps: {
        allowDelete: true,
        deleteButtonRedirect: record => `/firm/${record.firm.id}`,
      },
    }}
  >
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <ViewFirmButton />
      </Grid>
      <Grid item xs={6}>
        <ViewPersonButton />
      </Grid>
      <Grid item xs={6}>
        <EntityInput<Record>
          resource="firm"
          recordKey="firm"
          label="Firm"
          isRequired={true}
          filters={{ includeCompanies: true }}
          sort={{ field: "nameSearchRank", order: "ASC" }}
        />
      </Grid>
      <Grid item xs={6}>
        <PersonInput />
      </Grid>
      <Grid item xs={3}>
        <ReferenceInput
          source="employeeRoleEnumId"
          reference="employeeRoleEnum"
          sort={{ field: "name", order: "ASC" }}
        >
          <AutocompleteInput optionText="name" label="Employee Role" />
        </ReferenceInput>
      </Grid>
      <Grid item xs={3}>
        <DateInput label="Start Date" source="startDate" />
      </Grid>
      <Grid item xs={3}>
        <ReferenceInput
          source="contactTypeEnumId"
          reference="contactTypeEnum"
          sort={{ field: "name", order: "ASC" }}
        >
          <AutocompleteInput optionText="name" label="Contact Type" />
        </ReferenceInput>
      </Grid>
      <Grid item xs={3}>
        <ReferenceInput
          source="auditContactTypeEnumId"
          reference="auditContactTypeEnum"
          sort={{ field: "name", order: "ASC" }}
        >
          <AutocompleteInput optionText="name" label="Audit Contact Type" />
        </ReferenceInput>
      </Grid>
      <Grid item xs={3}>
        <BooleanInput label="Is Founder?" source="isFounder" />
      </Grid>
      <Grid item xs={3}>
        <BooleanInput label="Is Key Personnel?" source="isKeyPersonnel" />
      </Grid>
      <Grid item xs={3}>
        <BooleanInput
          label="Is Data Entry Contact?"
          source="isDataEntryContact"
        />
      </Grid>
      <Grid item xs={3}>
        <BooleanInput label="Is Data Contact?" source="dataContact" />
      </Grid>
      <FaceOfEntity />
      <Grid item xs={3}>
        <ReferenceInput
          source="focusStrategyEnumId"
          reference="strategyEnum"
          sort={{ field: "strategy", order: "ASC" }}
        >
          <AutocompleteInput optionText="name" label="Focus Strategy" />
        </ReferenceInput>
      </Grid>
      <Grid item xs={3}>
        <BooleanInput label="Is Admin Contact?" source="isAdminContact" />
      </Grid>
      <Grid item xs={3}>
        <BooleanInput label="Authorized to Trade?" source="authToTrade" />
      </Grid>
      <Grid item xs={3}>
        <BooleanInput label="Has Outside Afilliations?" source="outsideAffil" />
      </Grid>
      <Grid item xs={12}>
        <TextInput
          label="Job Description"
          multiline
          rows={5}
          source="jobDesc"
        />
      </Grid>
      <Grid item xs={12}>
        <TextInput label="Comments" multiline rows={5} source="comments" />
      </Grid>

      <Grid item xs={12}>
        <hr />
        <h3>Investment Assignments</h3>
      </Grid>
      <Grid item xs={12}>
        <ReferenceManyField
          label="Investment Assignments"
          reference="investmentFirmEmployment"
          target="firmEmploymentId"
          sort={{ field: "firmEmploymentDefault", order: "ASC" }}
          pagination={<Pagination />}
        >
          <Datagrid rowClick="edit" bulkActionButtons={false}>
            <TextField label="ID" source="id" sortable={false} />
            <TextField
              label="Investment Name"
              source="investment.name"
              sortable={false}
            />
            <TextField
              label="Role"
              source="employeeRole.name"
              sortable={false}
            />
            <DateField
              label="Start Date"
              source="startDate"
              sortable={false}
              options={{ timeZone: "UTC" }}
            />
            <DateField
              label="End Date"
              source="leftDate"
              sortable={false}
              options={{ timeZone: "UTC" }}
            />
            <EditButton />
          </Datagrid>
        </ReferenceManyField>
      </Grid>
      <Grid item xs={12}>
        <CreateRelatedInvestmentFirmEmploymentButton />
      </Grid>
      <Grid item xs={12}>
        <hr />
        <h3>Firm Employee Departure</h3>
      </Grid>
      <Grid item xs={3}>
        <BooleanInput label="Employee Departed?" source="leftInd" />
      </Grid>
      <Grid item xs={3}>
        <DateInput label="Departure Date" source="leftDate" />
      </Grid>
      <Grid item xs={6}>
        <TextInput
          label="Departure Reason"
          multiline
          rows={5}
          source="leftReason"
        />
      </Grid>
    </Grid>
  </CustomEdit>
);
