import React from "react";
import { usePermissions } from "react-admin";
import * as types from "_graphql-types";
import { isAuthorized } from "./authHelpers";

function Authorized({
  children,
  checks,
}: {
  children: React.ReactNode;
  checks: Array<keyof types.GetOneCurrentUserQuery["currentUser"]["flags"]>;
}): JSX.Element | null {
  const { permissions, isLoading } = usePermissions();
  if (isLoading) return null;

  const isAuthed = isAuthorized({ permissions, checks });

  if (isAuthed) return <>{children}</>;

  return null;
}

export default Authorized;
