import * as client from "_graphql-types";
import { filterSortPage } from "../DataProvider";
import * as types from "./types";

type Sdk = ReturnType<typeof client.getSdk>;

/*********************************************************************/
// Data Provider Functions
/*********************************************************************/

/*********************************************************************/
async function getList(
  sdk: Sdk,
  params: types.GetListParams<client.LinksSortEnum>
) {
  const { pagination, sort, filter } = params;

  const {
    linksList: { items: data, total },
  } = await sdk.getListLinks(filterSortPage({ filter, sort, pagination }));

  return {
    data,
    total,
  };
}

/*********************************************************************/
async function getOne(sdk: Sdk, params: types.GetOneParams) {
  const id = Number(params.id);
  const { links: data } = await sdk.getOneLink({
    id,
  });
  return {
    data,
  };
}

/*********************************************************************/
async function getMany(sdk: Sdk, params: types.GetManyParams) {
  const ids = params.ids.map(id => Number(id));
  const { linksMany: data } = await sdk.getManyLinks({
    ids,
  });

  return {
    data,
  };
}

/*********************************************************************/
async function getManyReference(
  sdk: Sdk,
  params: types.GetManyReferenceParams<client.LinksSortEnum>
) {
  const id = Number(params.id);
  const {
    pagination: { page, perPage },
    sort: { field, order },
    filter,
    target,
  } = params;

  const {
    linksList: { items: data, total },
  } = await sdk.getListLinks({
    page: {
      offset: (page - 1) * perPage,
      limit: perPage,
    },
    sort: [{ field, order }],
    filter: { ...filter, [target]: id },
  });

  return {
    data,
    total,
  };
}

/*********************************************************************/
async function create(
  sdk: Sdk,
  params: types.CreateParams<NonNullable<client.GetOneLinkQuery["links"]>>
) {
  const { data } = params;
  const input: client.CreateLinksMutationVariables["input"] = {
    name: data.name,
    url: data.url,
    iconEnumId: data.iconEnumId ? Number(data.iconEnumId) : undefined,
    orgLinks: data.orgLinks
      ?.filter(ol => !!(ol.expirationDate || ol.organizationId))
      .map(orgLink => ({
        organizationId: orgLink.organizationId,
        expirationDate: orgLink.expirationDate,
      })),
  };
  console.log({ input });
  const {
    addLinks: { id },
  } = await sdk.createLinks({
    input,
  });
  return await getOne(sdk, { id: id! });
}

/*********************************************************************/
async function update(
  sdk: Sdk,
  params: types.UpdateParams<NonNullable<client.GetOneLinkQuery["links"]>>
): types.UpdateOut<any> {
  const id = Number(params.id);
  const { data } = params;
  const input: client.UpdateLinksMutationVariables["input"] = {
    name: data.name,
    url: data.url,
    orgLinks: data.orgLinks?.map(({ organization, id, ...input }) => ({
      ...input,
    })),
    ...(data.iconEnumId ? { iconEnumId: Number(data.iconEnumId) } : {}),
  };

  await sdk.updateLinks({
    id,
    input,
  });

  return await getOne(sdk, { id });
}

/*********************************************************************/
/*********************************************************************/
async function del(sdk: Sdk, params: types.DeleteParams): types.DeleteOut<any> {
  const id = Number(params.id);

  await sdk.deleteLinks({ id });

  return { data: { id } };
}

export const links = types.dataProvider({
  getList,
  getOne,
  getMany,
  getManyReference,
  create,
  update,
  delete: del,
});
