import * as client from "_graphql-types";
import * as types from "./types";

type Sdk = ReturnType<typeof client.getSdk>;

type PerformanceBackfillArgs = {
  id: "performanceBackfill";
  data: client.PerformanceBackfillMutationVariables;
};

async function update(sdk: Sdk, params: PerformanceBackfillArgs) {
  switch (params.id) {
    case "performanceBackfill":
      const { performanceBackfill: data } = await sdk.performanceBackfill(
        params.data
      );
      // workaround for type-graphql emitting id as nullable
      return { data: { ...data, id: data.id! } };
    // https://github.com/microsoft/TypeScript/issues/23572
    // default:
    //   throw unhandledCase(params);
  }
}

export const performanceBackfill = types.dataProvider({
  update,
});
