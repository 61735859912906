import * as client from "_graphql-types";
import * as types from "./types";

import { filterSortPage } from "../DataProvider";
import {
  COMPANY_BUSINESS_OBJECT_ENUM,
  DEAL_BUSINESS_OBJECT_ENUM,
  INVESTMENT_BUSINESS_OBJECT_ENUM,
} from "./businessObjectEnum";

type Sdk = ReturnType<typeof client.getSdk>;

/*********************************************************************/
// Data Provider Functions
/*********************************************************************/

/*********************************************************************/
async function getList(
  sdk: Sdk,
  params: types.GetListParams<client.NotesSortEnum>
) {
  const { pagination, sort, filter } = params;

  const {
    notesList: { items: data, total },
  } = await sdk.getListNotes(filterSortPage({ filter, sort, pagination }));

  return {
    data,
    total,
  };
}

/*********************************************************************/
async function getOne(sdk: Sdk, params: types.GetOneParams) {
  const id = Number(params.id);
  const { notes: data } = await sdk.getOneNotes({
    id,
  });
  return {
    data,
  };
}

/*********************************************************************/
async function getMany(sdk: Sdk, params: types.GetManyParams) {
  const ids = params.ids.map(id => Number(id));

  const { notesMany: data } = await sdk.getManyNotes({
    ids,
  });

  return {
    data,
  };
}

/****************************************************************** */
async function create(
  sdk: Sdk,
  params: types.CreateParams<
    client.MutationAddNoteArgs["input"] & {
      businessObjectEnumId: number;
    } & Pick<client.Notes, "investment" | "company" | "deal">
  >
) {
  const { data } = params;

  const input: client.MutationAddNoteArgs["input"] = {
    noteMetaId: types.required(data, "noteMetaId"),
    noteDate: data.noteDate,
    text: data.text,
    accessLevelId: data.accessLevelId ?? 2,
    investmentId:
      data.businessObjectEnumId === INVESTMENT_BUSINESS_OBJECT_ENUM
        ? Number(data.investment?.id ?? data.investmentId)
        : undefined,
    companyId:
      data.businessObjectEnumId === COMPANY_BUSINESS_OBJECT_ENUM
        ? Number(data.company?.id ?? data.companyId)
        : undefined,
    dealId:
      data.businessObjectEnumId === DEAL_BUSINESS_OBJECT_ENUM
        ? Number(data.deal?.id ?? data.dealId)
        : undefined,
    notifyTeam: data.notifyTeam,
  };
  const {
    addNote: { id },
  } = await sdk.addNote({
    input,
  });
  return await getOne(sdk, { id: id! });
}

/****************************************************************** */
async function update(
  sdk: Sdk,
  params: types.UpdateParams<
    client.MutationAddNoteArgs["input"] & {
      businessObjectEnumId: number;
    } & Pick<client.Notes, "investment" | "company" | "deal">
  >
) {
  const id = Number(params.id);
  const { data } = params;
  const input: client.UpdateNoteMutationVariables["input"] = {
    noteMetaId: types.required(data, "noteMetaId"),
    noteDate: data.noteDate,
    text: data.text,
    accessLevelId: data.accessLevelId ?? 2,
    investmentId:
      data.businessObjectEnumId === INVESTMENT_BUSINESS_OBJECT_ENUM
        ? Number(data.investment?.id ?? data.investmentId)
        : undefined,
    companyId:
      data.businessObjectEnumId === COMPANY_BUSINESS_OBJECT_ENUM
        ? Number(data.company?.id ?? data.companyId)
        : undefined,
    dealId:
      data.businessObjectEnumId === DEAL_BUSINESS_OBJECT_ENUM
        ? Number(data.deal?.id ?? data.dealId)
        : undefined,
  };
  await sdk.updateNote({ id, input });

  return await getOne(sdk, { id });
}
/****************************************************************** */
async function getManyReference(
  sdk: Sdk,
  params: types.GetManyReferenceParams<client.NotesSortEnum>
) {
  const id = Number(params.id);
  const {
    pagination: { page, perPage },
    sort: { field, order },
    filter,
    target,
  } = params;

  const {
    notesList: { items: data, total },
  } = await sdk.getListNotes({
    filter: { ...filter, [target]: id },
    page: {
      offset: (page - 1) * perPage,
      limit: perPage,
    },
    sort: [{ field, order }],
  });

  return {
    data,
    total,
  };
}

export const notes = types.dataProvider({
  getList,
  getOne,
  getMany,
  create,
  update,
  getManyReference,
});
