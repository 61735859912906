import { Filter, ReferenceInput, SelectInput } from "react-admin";

const CustomAnalyticsFilter = (props: any) => {
  return (
    <Filter {...props} variant="outlined">
      <ReferenceInput reference="investment" source="investmentId" alwaysOn>
        <SelectInput optionText="name" label="Investment" />
      </ReferenceInput>
    </Filter>
  );
};

export default CustomAnalyticsFilter;
