import * as client from "_graphql-types";
import * as types from "./types";
import uuid from "frontend/src/utils/uuid";

type Sdk = ReturnType<typeof client.getSdk>;

const create = async (
  sdk: Sdk,
  param: types.CreateParams<{ files: File[] }>
) => {
  console.log("create:", param);

  if (!param.data.files) {
    throw new Error("No file to upload");
  }

  const files = param.data.files.map(file => ({ file }));

  const names = files.map(file => file.file.name);

  const data = await sdk.addDraftDocumentSignedURI({
    names,
  });

  return {
    data: {
      id: "bulk" + uuid(),
      drafts: data.addDraftDocumentSignedURI.map((d, i) => ({
        ...d,
        file: files[i].file,
      })),
    },
  };
};

export const draftDocument = types.dataProvider({
  create,
});
