import { OktaAuth, AccessToken } from "@okta/okta-auth-js";
import { Auth as CognitoAuth } from "@aws-amplify/auth";
import { Amplify } from "@aws-amplify/core";
import { Auth, AuthProviderType } from "./AuthProvider.component";
import config from "../../config";

Amplify.configure({
  userPoolWebClientId: config.POOL_CLIENT_ID(),
  region: config.POOL_REGION(),
  userPoolId: config.POOL_ID(),
});

export function buildOktaAuth(): OktaAuth {
  return new OktaAuth({
    issuer: config.OKTA_ISSUER(),
    clientId: config.OKTA_CLIENT_ID(),
    pkce: true,
    redirectUri: `${window.location.origin}/login/okta/callback`,
  });
}

export async function getOktaAuth(): Promise<Auth | undefined> {
  const oktaAuth = buildOktaAuth();
  if (!(await oktaAuth.isAuthenticated())) return;

  const getIdToken = async () => {
    let token: AccessToken = (await oktaAuth.tokenManager.get(
      "accessToken"
    )) as AccessToken;

    if (!token) {
      token = (await oktaAuth.tokenManager.get("accessToken")) as AccessToken;
    }

    return token?.accessToken;
  };
  const signOut = async () => {
    await oktaAuth.revokeAccessToken();
    await oktaAuth.closeSession();
  };
  const getEmail = async () => {
    const token = await getIdToken();
    return oktaAuth.token.decode(token).payload.sub;
  };

  const refreshSession = async () => {
    const token = await oktaAuth.tokenManager.get("accessToken");
    if (!token || !token.authorizeUrl) {
      console.log("Token cannot be renewed missing or missing authorizeURL");
      console.log("This should only occurr in tests..");
      console.log(token);
      return;
    }
    //https://github.com/okta/okta-auth-js/issues/559
    return oktaAuth.tokenManager.renew("accessToken");
  };

  return {
    getIdToken,
    signOut,
    getEmail,
    refreshSession,
  };
}

export async function getCognitoAuth(): Promise<Auth | undefined> {
  let user;
  try {
    user = await CognitoAuth.currentAuthenticatedUser();
  } catch (e) {}

  if (!user) return;

  const getIdToken = async () => {
    const session = await CognitoAuth.currentSession();
    return session.getIdToken().getJwtToken();
  };
  const signOut = () => CognitoAuth.signOut();
  const getEmail = async () => {
    const session = await CognitoAuth.currentSession();
    return session.getIdToken().payload.email;
  };

  const refreshSession = async () => {
    const currentUser = await CognitoAuth.currentAuthenticatedUser();
    const currentSession = await CognitoAuth.currentSession();
    return new Promise((res, rej) =>
      currentUser.refreshSession(
        currentSession.getRefreshToken(),
        (err: any, session: any) => {
          if (err) {
            console.log(err);
            rej(err);
          }
          res(session);
          console.log("Refreshed congito session");
        }
      )
    );
  };

  return {
    getIdToken,
    signOut,
    authProvider: AuthProviderType.Cognito,
    getEmail,
    refreshSession,
  };
}
