import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { Grid } from "@mui/material";
import * as client from "_graphql-types";
import { FunctionField, TextInput, required, regex } from "react-admin";
import { CustomEdit } from "../CustomEdit";
import { ServiceProviderDocuments } from "./Form";
import { NO_WHITESPACE_REGEX } from "frontend/src/utils/constants";

type Record = NonNullable<
  client.GetOneServiceProviderDocumentsQuery["serviceProviderDocuments"]
>;

const validateName = [
  required(),
  regex(NO_WHITESPACE_REGEX, "Please remove leading and trailing spaces"),
];

export const ServiceProviderDocumentEdit = () => {
  return (
    <CustomEdit<Record>
      title={record =>
        record && `Service Provider Document ${record.name || ""}`
      }
    >
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextInput label="Name" source="name" validate={validateName} />
        </Grid>
        <ServiceProviderDocuments />
        <Grid item xs={6}>
          <FunctionField
            label="Download"
            render={(rec: any) =>
              rec && (
                <a href={`${rec.url}`} download={rec.name}>
                  <CloudDownloadIcon />
                </a>
              )
            }
            sortable={false}
          />
        </Grid>
      </Grid>
    </CustomEdit>
  );
};
