import { useEffect, useState, useContext } from "react";
import { AuthContext } from "./AuthProvider.component";
import { getCognitoAuth } from "./Auth";
import { Button, Grid, InputLabel, TextField } from "@mui/material";
import { CognitoUser } from "@aws-amplify/auth";
import { AuthState, CognitoConfirmSignIn } from "./CognitoSignIn";
import { useDataProvider } from "../DataProviderContext";
import Alert from "@mui/material/Alert";
import { customUserSignUp } from "./signUpUtils";
import config from "../../config";

interface Attributes {
  email: string;
  given_name: string;
  family_name: string;
}

export function ExternalManagerSignUp({
  changeShowSignup,
}: {
  changeShowSignup: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const { dataProvider } = useDataProvider();
  const [email, setEmail] = useState<string>("");
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setlastName] = useState<string>("");
  const [error, setError] = useState<string | null>(null);

  const { configure } = useContext(AuthContext);
  const [authState, setAuthState] = useState<AuthState>(AuthState.SignIn);

  useEffect(() => {
    const configureAuth = async () => {
      const auth = await getCognitoAuth();
      if (auth) configure(auth);
    };

    configureAuth();
  }, [authState, configure]);

  const [cognitoUser, setCustomLoginCognitoUser] = useState<CognitoUser>();

  function validate(attributes: Attributes): { validationErrors: string[] } {
    let validationErrors = [];

    if (attributes.email.length === 0) {
      validationErrors.push("Email needs to be entered.");
    }

    if (attributes.given_name.length === 0) {
      validationErrors.push("First name needs to be entered.");
    }
    if (attributes.family_name.length === 0) {
      validationErrors.push("Last name needs to be entered.");
    }
    return { validationErrors };
  }

  const activateUser = async (attributes: Attributes) => {
    return await (
      await fetch(
        config.GRAPHQL_API().substring(0, config.GRAPHQL_API().length - 8) +
          "/api/users/activate-external-user",
        {
          method: "POST",
          body: JSON.stringify(attributes),
          headers: { "Content-Type": "application/json" },
        }
      )
    ).json();
  };

  const signUp = async () => {
    const attributes = {
      email,
      given_name: firstName,
      family_name: lastName,
    };
    await customUserSignUp<Attributes>({
      setError,
      attributes,
      activateUser,
      setCustomLoginCognitoUser,
      validate,
    });
  };

  return (
    <Grid container spacing={1}>
      {!cognitoUser && (
        <>
          <Grid item xs={12}>
            <InputLabel>Enter your email</InputLabel>
            <TextField
              onChange={e => setEmail(e.target.value)}
              type="text"
              margin="normal"
              variant="outlined"
              data-cy="username"
              value={email}
              fullWidth
              required
            />
          </Grid>
          <Grid container item>
            <Grid item xs={12}>
              <InputLabel>Enter your name</InputLabel>
            </Grid>
            <Grid container item spacing={1}>
              <Grid item xs={6}>
                <TextField
                  onChange={e => setFirstName(e.target.value)}
                  type="text"
                  margin="normal"
                  variant="outlined"
                  data-cy="firstName"
                  placeholder="First Name"
                  value={firstName}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  onChange={e => setlastName(e.target.value)}
                  type="text"
                  margin="normal"
                  variant="outlined"
                  data-cy="givenName"
                  placeholder="Last Name"
                  value={lastName}
                  fullWidth
                  required
                />
              </Grid>
            </Grid>
          </Grid>

          {error && (
            <Grid item xs={12}>
              {error && <Alert severity="error">{error}</Alert>}
            </Grid>
          )}
          <Grid item xs={12}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Button
                onClick={signUp}
                variant="contained"
                color="primary"
                data-cy="sign-up-button"
              >
                Sign Up
              </Button>

              <Button
                onClick={() => changeShowSignup(false)}
                variant="text"
                color="primary"
              >
                Back To Applicant Log In
              </Button>
            </div>
          </Grid>
        </>
      )}
      {cognitoUser && (
        <Grid item xs={12}>
          <CognitoConfirmSignIn
            setAuthState={setAuthState}
            cognitoUser={cognitoUser as CognitoUser}
          />
        </Grid>
      )}
    </Grid>
  );
}
