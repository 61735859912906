import * as client from "_graphql-types";
import * as types from "./types";

type Sdk = ReturnType<typeof client.getSdk>;

/*********************************************************************/
// Data Provider Functions
/*********************************************************************/

async function create(
  sdk: Sdk,
  { data: { text } }: { data: { text: string } }
) {
  // Maybe a premature optimization, but we can get the api response and generate a hash at the
  // same time and make app more responsive.
  const [res, id] = await Promise.all([
    await sdk.documentTypeSuggestion({ text }),
    (async () => {
      const inputBytes = new TextEncoder().encode(text);
      const digest = await window.crypto.subtle.digest("SHA-512", inputBytes);
      return Array.from(new Uint8Array(digest))
        .map(b => b.toString(16).padStart(2, "0"))
        .join("");
    })(),
  ]);

  return {
    data: { id, ...res },
  };
}

/*********************************************************************/
export const documentTypeSuggestions = types.dataProvider({
  create,
});
