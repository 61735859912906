import { Datagrid, FunctionField, TextField } from "react-admin";
import * as client from "_graphql-types";

type Record = client.GetOneCustomAnalyticsQuery["customAnalytics"];

export const InvestmentCustomAnalyticsDataGrid = (props: any) => {
  return (
    <Datagrid
      data-cy="investmentCustomAnalytics__list"
      rowClick="edit"
      bulkActionButtons={false}
    >
      <FunctionField
        {...props}
        data-cy="customAnalytics__name"
        label="Title"
        source="value"
        render={(record?: Record) => record?.value?.title?.text}
      />
      <FunctionField
        {...props}
        label="As Of Date"
        source="asOfDate"
        render={(record: Record) => record.asOfDate?.substring(0, 10)}
      />
      <TextField {...props} label="Modify User" source="modifyUser" />
      <FunctionField
        {...props}
        label="Modify Date"
        source="modifyDate"
        render={(record: Record) => record.modifyDate?.substring(0, 10)}
      />
    </Datagrid>
  );
};
