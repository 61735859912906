import * as client from "_graphql-types";
import * as types from "./types";

type Sdk = ReturnType<typeof client.getSdk>;

/*********************************************************************/
// Data Provider Functions
/*********************************************************************/

/*********************************************************************/
async function getList(
  sdk: Sdk,
  params: types.GetListParams<client.FdpWebsitesSortEnum>
) {
  const {
    pagination: { page, perPage },
    sort: { field, order },
    filter,
  } = params;

  const {
    fdpWebsitesList: { items: data, total },
  } = await sdk.getListFdpWebsites({
    page: {
      offset: (page - 1) * perPage,
      limit: perPage,
    },
    sort: [{ field, order }],
    filter,
  });

  return {
    data,
    total,
  };
}

/*********************************************************************/
async function getOne(sdk: Sdk, params: types.GetOneParams) {
  const id = Number(params.id);
  const { fdpWebsites: data } = await sdk.getOneFdpWebsites({
    id,
  });
  return {
    data,
  };
}

/*********************************************************************/
async function getMany(sdk: Sdk, params: types.GetManyParams) {
  const ids = params.ids.map(id => Number(id));

  const { fdpWebsitesMany: data } = await sdk.getManyFdpWebsites({
    ids,
  });

  return {
    data,
  };
}

/*********************************************************************/
async function getManyReference(
  sdk: Sdk,
  params: types.GetManyReferenceParams<client.FdpWebsitesSortEnum>
) {
  const id = Number(params.id);
  const {
    pagination: { page, perPage },
    sort: { field, order },
    filter,
    target,
  } = params;

  const {
    fdpWebsitesList: { items: data, total },
  } = await sdk.getListFdpWebsites({
    page: {
      offset: (page - 1) * perPage,
      limit: perPage,
    },
    sort: [{ field, order }],
    filter: { ...filter, [target]: id },
  });

  return {
    data,
    total,
  };
}

/*********************************************************************/
async function create(
  sdk: Sdk,
  params: types.CreateParams<
    NonNullable<client.GetOneFdpWebsitesQuery["fdpWebsites"]>
  >
) {
  const { data } = params;
  const input: client.CreateFdpWebsitesMutationVariables["input"] = {
    financialDataProviderId: data.financialDataProviderId,
    website: data.website,
  };

  const {
    addFdpWebsites: { id },
  } = await sdk.createFdpWebsites({
    input,
  });
  return await getOne(sdk, { id: id! });
}

/*********************************************************************/
async function update(
  sdk: Sdk,
  params: types.UpdateParams<
    NonNullable<client.GetOneFdpWebsitesQuery["fdpWebsites"]>
  >
) {
  const id = Number(params.id);
  const { data } = params;

  const input: client.UpdateFdpWebsitesMutationVariables["input"] = {
    financialDataProviderId: data.financialDataProviderId,
    website: data.website,
  };

  await sdk.updateFdpWebsites({
    id,
    input,
  });
  return await getOne(sdk, { id });
}

/*********************************************************************/

async function deleteOne(sdk: Sdk, params: types.DeleteParams) {
  const id = Number(params.id);
  await sdk.deleteFdpWebsites({
    id,
  });
  return { data: { id } };
}

/*********************************************************************/

async function deleteMany(sdk: Sdk, params: types.DeleteManyParams) {
  const ids = params.ids.map(id => Number(id));
  await Promise.all(ids.map(id => sdk.deleteFdpWebsites({ id })));

  return { data: ids };
}

/*********************************************************************/
export const fdpWebsites = types.dataProvider({
  getList,
  getOne,
  getMany,
  getManyReference,
  create,
  update,
  delete: deleteOne,
  deleteMany,
});
