import * as client from "_graphql-types";
import * as types from "./types";

import { filterSortPage } from "../DataProvider";

type Sdk = ReturnType<typeof client.getSdk>;

/*********************************************************************/
// Data Provider Functions
/*********************************************************************/

/*********************************************************************/
async function getList(
  sdk: Sdk,
  params: types.GetListParams<client.DealStructureEnumSortEnum>
) {
  const { pagination, sort } = params;

  const {
    dealStructureEnumList: { items: data, total },
  } = await sdk.getListDealStructureEnum(filterSortPage({ sort, pagination }));

  return {
    data,
    total,
  };
}

/*********************************************************************/
async function getOne(sdk: Sdk, params: types.GetOneParams) {
  const id = Number(params.id);
  const { dealStructureEnum: data } = await sdk.getOneDealStructureEnum({
    id,
  });
  return {
    data,
  };
}

/*********************************************************************/
async function getMany(sdk: Sdk, params: types.GetManyParams) {
  const ids = params.ids.map(id => Number(id));

  const { dealStructureEnumMany: data } = await sdk.getManyDealStructureEnum({
    ids,
  });

  return {
    data,
  };
}

/*********************************************************************/
export const dealStructureEnum = types.dataProvider({
  getList,
  getOne,
  getMany,
  ttl: types.defaultEnumTtl,
});
