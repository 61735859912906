import * as client from "_graphql-types";
import * as types from "./types";

import { filterSortPage } from "../DataProvider";
import { numberUnlessNaN } from "../helpers/numbers";

type Sdk = ReturnType<typeof client.getSdk>;

type InvestorInput = {
  firm: { id: string | number };
};
type DealInput = client.GetOneDealQuery["deal"] & InvestorInput;

const inputParams = (
  data: Partial<DealInput>
): client.UpdateDealMutationVariables["input"] => ({
  companyId: numberUnlessNaN(data?.company?.id),
  firmId: numberUnlessNaN(data?.firm?.id),
  amount: numberUnlessNaN(data?.amount),
  fundingRoundEnumId: data?.fundingRoundEnumId,
  isLeadInvestor: data?.isLeadInvestor,
  name: data?.name,
  stageEnumId: data?.stageEnumId,
  resultEnumId: data?.resultEnumId,
  dateReceived: data?.dateReceived,
  expectedCloseDate: data?.expectedCloseDate,
  preMoneyValuation: numberUnlessNaN(data?.preMoneyValuation),
  roundSize: numberUnlessNaN(data?.roundSize),
  passedNote: data?.passedNote,
  summary: data?.summary,
  source: data?.source,
  rcSource: data?.rcSource,
  isImpact: data?.isImpact,
  headquartersEnumId: data?.headquartersEnumId,
  assetClassEnumId: data?.assetClassEnumId,
  strategyEnumId: data?.strategyEnumId,
  subStrategyEnumId: data?.subStrategyEnumId,
  dealMarketingEnumId: data?.dealMarketingEnumId,
  dealStructureEnumId: data?.dealStructureEnumId,
  coInvestors: data?.coInvestors,
  ...(!data?.company?.id && {
    dealTags: data?.dealTags?.map(dealTag => ({
      tagId: dealTag.tagId,
    })),
  }),
});

/*********************************************************************/
// Data Provider Functions
/*********************************************************************/

/*********************************************************************/
export async function getList(
  sdk: Sdk,
  params: types.GetListParams<client.DealSortEnum>
) {
  const { pagination, sort, filter } = params;

  const {
    dealList: { items: data, total },
  } = await sdk.getListDeal(
    filterSortPage({
      filter,
      sort,
      nameField: client.DealSortEnum.Name,
      pagination,
    })
  );

  return {
    data,
    total,
  };
}

/*********************************************************************/
async function getOne(sdk: Sdk, params: types.GetOneParams) {
  const id = Number(params.id);
  const { deal: data } = await sdk.getOneDeal({
    id,
  });
  return {
    data,
  };
}

/*********************************************************************/
async function getMany(sdk: Sdk, params: types.GetManyParams) {
  const ids = params.ids.map(id => Number(id));

  const { dealMany: data } = await sdk.getManyDeal({
    ids,
  });

  return {
    data,
  };
}

/*********************************************************************/
async function getManyReference(
  sdk: Sdk,
  params: types.GetManyReferenceParams<client.DealSortEnum>
) {
  const id = Number(params.id);
  const {
    pagination: { page, perPage },
    sort: { field, order },
    filter,
    target,
  } = params;

  const {
    dealList: { items: data, total },
  } = await sdk.getListDeal({
    page: {
      offset: (page - 1) * perPage,
      limit: perPage,
    },
    sort: [{ field, order }],
    filter: { ...filter, [target]: id },
  });

  return {
    data,
    total,
  };
}

/*********************************************************************/
async function create(sdk: Sdk, params: types.CreateParams<DealInput>) {
  const { data } = params;

  const input: client.CreateDealMutationVariables["input"] = inputParams(data);

  const {
    addDeal: { id },
  } = await sdk.createDeal({
    input,
  });
  return await getOne(sdk, { id: id! });
}

/*********************************************************************/
async function update(sdk: Sdk, params: types.UpdateParams<DealInput>) {
  const id = Number(params.id);
  const { data } = params;
  const input: client.UpdateDealMutationVariables["input"] = inputParams(data);

  await sdk.updateDeal({
    id,
    input,
  });
  return await getOne(sdk, { id });
}

/*********************************************************************/
export const deal = types.dataProvider({
  getList,
  getOne,
  getMany,
  getManyReference,
  create,
  update,
});
