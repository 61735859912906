import * as client from "_graphql-types";
import { filterSortPage } from "../DataProvider";
import * as types from "./types";

// import {addDays} from "date-fns";

type Sdk = ReturnType<typeof client.getSdk>;

/*********************************************************************/
// Data Provider Functions
/*********************************************************************/

/*********************************************************************/
export async function getList(
  sdk: Sdk,
  params: types.GetListParams<client.CompanyEnumSortEnum>
) {
  const { pagination, sort, filter } = params;

  const {
    companyEnumList: { items: data, total },
  } = await sdk.getListCompanyEnum(
    filterSortPage({ filter, sort, pagination })
  );

  return {
    data,
    total,
  };
}

/*********************************************************************/
async function getOne(sdk: Sdk, params: types.GetOneParams) {
  const id = Number(params.id);
  const { companyEnum: data } = await sdk.getOneCompanyEnum({
    id,
  });
  return {
    data,
  };
}

/*********************************************************************/
async function getMany(sdk: Sdk, params: types.GetManyParams) {
  const ids = params.ids.map(id => Number(id));

  const { companyEnumMany: data } = await sdk.getManyCompanyEnum({
    ids,
  });

  return {
    data,
  };
}

/*********************************************************************/
export const companyEnum = types.dataProvider({
  getList,
  getOne,
  getMany,
});
