import { Filter, TextInput } from "react-admin";

export const PersonFilter = () => (
  <Filter>
    <TextInput
      label="Name Search"
      source="q"
      alwaysOn
      style={{ width: 500 }}
      resettable
    />
  </Filter>
);
