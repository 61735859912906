import * as client from "_graphql-types";
import * as types from "./types";

type Sdk = ReturnType<typeof client.getSdk>;

/*********************************************************************/
// Data Provider Functions
/*********************************************************************/

/****************************************************************** */

async function create(
  sdk: Sdk,
  params: types.CreateParams<
    NonNullable<client.GetOneFamilyQuery["family"]>["investmentsFamily"][0]
  >
) {
  const { data } = params;

  const input: client.MutationAddInvestmentsFamilyArgs["input"] = {
    investmentId: types.required(data, "investment")?.id as number,
    familyId: types.required(data, "familyId"),
  };

  if (!input.investmentId) {
    throw new Error("Investment ID is required, this should never happen");
  }

  const {
    addInvestmentsFamily: { id },
  } = await sdk.createInvestmentsFamily({
    input,
  });
  return { data: { id } };
}

/*********************************************************************/
/*********************************************************************/
async function del(sdk: Sdk, params: types.DeleteParams): types.DeleteOut<any> {
  const id = Number(params.id);

  await sdk.deleteInvestmentsFamily({ id });

  return { data: { id } };
}

/*********************************************************************/
export const investmentsFamily = types.dataProvider({
  create,
  delete: del,
});
