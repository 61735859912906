import { CustomEdit } from "../CustomEdit";
import * as client from "_graphql-types";
import { useGetRecordId } from "react-admin";
import { Form } from "./Form";
import { useCanAccessMutation } from "../../util/useCanAccessMutation";

type Record = NonNullable<client.GetOneSetQuery["set"]>;

export const SetEdit = () => {
  const recordId = useGetRecordId();
  const mutationArgs = JSON.stringify({ id: recordId });

  const { canEdit, canEditField, loading } = useCanAccessMutation(
    "updateSet",
    mutationArgs
  );

  return (
    <CustomEdit<Record>
      title={record => record && `Set ${record.name || ""}`}
      customFormProps={{
        customToolbarProps: { canAccessMutation: canEdit },
        loading,
        canEditField,
      }}
    >
      <Form />
    </CustomEdit>
  );
};
