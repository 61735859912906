import * as client from "_graphql-types";
import * as types from "./types";
import { EnsureAllKeys } from "../helpers/types";
type Sdk = ReturnType<typeof client.getSdk>;

/*********************************************************************/
// Data Provider Functions
/*********************************************************************/

/*********************************************************************/
async function getList(
  sdk: Sdk,
  params: types.GetListParams<client.AdEntriesXApplicationsXSetsSortEnum>
) {
  const {
    pagination: { page, perPage },
    sort: { field, order },
    filter,
  } = params;

  const {
    adEntriesXApplicationsXSetsList: { items: data, total },
  } = await sdk.getListAdEntriesXApplicationsXSets({
    page: {
      offset: (page - 1) * perPage,
      limit: perPage,
    },
    sort: [{ field, order }],
    filter,
  });

  return {
    data,
    total,
  };
}

/*********************************************************************/
async function getOne(sdk: Sdk, params: types.GetOneParams) {
  const id = params.id as number;
  const { adEntriesXApplicationsXSets: data } =
    await sdk.getOneAdEntriesXApplicationsXSets({
      id,
    });
  return {
    data,
  };
}

/*********************************************************************/
async function getMany(sdk: Sdk, params: types.GetManyParams) {
  const ids = params.ids as number[];

  const { adEntriesXApplicationsXSetsMany: data } =
    await sdk.getManyAdEntriesXApplicationsXSets({
      ids,
    });

  return {
    data,
  };
}

/*********************************************************************/
async function getManyReference(
  sdk: Sdk,
  params: types.GetManyReferenceParams<client.AdEntriesXApplicationsXSetsSortEnum>
) {
  const id = params.id as number;
  const {
    pagination: { page, perPage },
    sort: { field, order },
    filter,
    target,
  } = params;

  const {
    adEntriesXApplicationsXSetsList: { items: data, total },
  } = await sdk.getListAdEntriesXApplicationsXSets({
    page: {
      offset: (page - 1) * perPage,
      limit: perPage,
    },
    sort: [{ field, order }],
    filter: { ...filter, [target]: id },
  });

  return {
    data,
    total,
  };
}

/*********************************************************************/
async function create(
  sdk: Sdk,
  params: types.CreateParams<
    NonNullable<
      client.GetOneAdEntriesXApplicationsXSetsQuery["adEntriesXApplicationsXSets"]
    >
  >
) {
  const { data } = params;
  const input: EnsureAllKeys<
    client.CreateAdEntriesXApplicationsXSetsMutationVariables["input"]
  > = {
    entryId: data.entryId,
    applicationId: data.applicationId,
    setId: data.setId,
  };

  const {
    addAdEntriesXApplicationsXSets: { id },
  } = await sdk.createAdEntriesXApplicationsXSets({
    input,
  });
  return await getOne(sdk, { id: id! });
}

/*********************************************************************/
async function update(
  sdk: Sdk,
  params: types.UpdateParams<
    NonNullable<
      client.GetOneAdEntriesXApplicationsXSetsQuery["adEntriesXApplicationsXSets"]
    >
  >
) {
  const id = params.id as number;
  const { data } = params;

  const input: EnsureAllKeys<
    client.UpdateAdEntriesXApplicationsXSetsMutationVariables["input"]
  > = {
    entryId: data.entryId,
    applicationId: data.applicationId,
    setId: data.setId,
  };

  await sdk.updateAdEntriesXApplicationsXSets({
    id,
    input,
  });
  return await getOne(sdk, { id });
}

async function deleteOne(sdk: Sdk, params: types.DeleteParams) {
  const id = Number(params.id);
  await sdk.deleteAdEntriesXApplicationsXSets({
    id,
  });
  return { data: { id } };
}

/*********************************************************************/
export const adEntriesXApplicationsXSets = types.dataProvider({
  getList,
  getOne,
  getMany,
  getManyReference,
  create,
  update,
  delete: deleteOne,
});
