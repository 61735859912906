import * as client from "_graphql-types";

import { Grid } from "@mui/material";
import {
  DateInput,
  ReferenceInput,
  SelectInput,
  TextInput,
  required,
} from "react-admin";
import { useWatch } from "react-hook-form";
import {
  COMPANY_BUSINESS_OBJECT_ENUM,
  DEAL_BUSINESS_OBJECT_ENUM,
  INVESTMENT_BUSINESS_OBJECT_ENUM,
} from "../../dataProvider/resources/businessObjectEnum";
import { CustomEdit } from "../CustomEdit";
import { EntityInput } from "../UI/EntityInput";

import CustomBreadCrumb from "../CustomBreadCumb";
import { businessObjectToRoute } from "../_helpers";

type Record = NonNullable<client.GetOneNotesQuery["notes"]>;

const validateRequired = [required()];

function NoteEditForm() {
  const formData = useWatch();
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <ReferenceInput
            source="businessObjectEnumId"
            reference="businessObjectEnum"
            filter={{ ids: [1, 3, 4] }}
          >
            <SelectInput
              optionText="name"
              label="Business Object"
              validate={validateRequired}
            />
          </ReferenceInput>
        </Grid>
        <Grid item xs={8}>
          {formData?.businessObjectEnumId ===
            INVESTMENT_BUSINESS_OBJECT_ENUM && (
            <EntityInput<NonNullable<Record>>
              resource="investment"
              recordKey="investment"
              label={"Investment"}
              isRequired={true}
              allowChange
            />
          )}
          {formData?.businessObjectEnumId === COMPANY_BUSINESS_OBJECT_ENUM && (
            <EntityInput<NonNullable<Record>>
              resource="company"
              recordKey="company"
              label={"Company"}
              isRequired={true}
              allowChange
            />
          )}
          {formData?.businessObjectEnumId === DEAL_BUSINESS_OBJECT_ENUM && (
            <EntityInput<NonNullable<Record>>
              resource="deal"
              recordKey="deal"
              label={"Deal"}
              isRequired={true}
              allowChange={true}
            />
          )}
        </Grid>
        <Grid item xs={12}>
          <TextInput source="text" fullWidth multiline rows={8} />
        </Grid>
        <Grid item xs={4}>
          <ReferenceInput
            source="noteMetaId"
            reference="noteMetaEnum"
            filter={{ noteCategoryEnumId: 2 }}
          >
            <SelectInput
              optionText="label"
              label="Type"
              validate={validateRequired}
            />
          </ReferenceInput>
        </Grid>
        <Grid item xs={4}>
          <DateInput source="noteDate" />
        </Grid>
        <Grid item xs={4}>
          <SelectInput
            source="accessLevelId"
            optionValue="id"
            choices={[
              { id: 1, name: "Public", value: 1 },
              { id: 2, name: "Organization", value: 2 },
            ]}
          />
        </Grid>
      </Grid>
    </>
  );
}

export const NoteEdit = () => {
  return (
    <CustomEdit
      title={record => record && `Note ${record.id}`}
      actions={
        <CustomBreadCrumb<Record>
          name="notes"
          items={[
            {
              getPath: record =>
                businessObjectToRoute(record?.businessObject.name),
              getId: record =>
                record?.investment?.id ||
                record?.company?.id ||
                record?.deal?.id,
              getName: record =>
                record?.investment?.name ||
                record?.company?.name ||
                record?.deal?.name,
            },
          ]}
        />
      }
    >
      <NoteEditForm />
    </CustomEdit>
  );
};
