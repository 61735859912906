import * as client from "_graphql-types";
import { filterSortPage } from "../DataProvider";
import * as types from "./types";

type Sdk = ReturnType<typeof client.getSdk>;

interface FirmUpdateParams extends client.FirmInput {
  fundLinksSerialized: Record<string, number>;
}

/*********************************************************************/
// Data Provider Functions
/*********************************************************************/

/*********************************************************************/
export async function getList(
  sdk: Sdk,
  params: types.GetListParams<client.FirmSortEnum>
) {
  const { pagination, sort, filter } = params;

  const {
    firmList: { items: data, total },
  } = await sdk.getListFirm({
    ...filterSortPage({
      filter,
      sort,
      nameField: client.FirmSortEnum.Name,
      pagination,
    }),
    searchFilters: [{ ACCESS: { values: [client.Prud.Update] } }],
  });

  return {
    data,
    total,
  };
}

/*********************************************************************/
async function getOne(sdk: Sdk, params: types.GetOneParams) {
  const id = Number(params.id);
  const { firm: data } = await sdk.getOneFirm({
    id,
  });
  return { data };
}

/*********************************************************************/
async function getMany(
  sdk: Sdk,
  params: types.GetManyParams
): types.GetManyOut<any> {
  const ids = params.ids.map(id => Number(id));
  const { firmMany: data } = await sdk.getManyFirm({
    ids,
  });
  return { data };
}

/*********************************************************************/
async function create(
  sdk: Sdk,
  params: types.CreateParams<client.GetOneFirmQuery["firm"]>
) {
  const { data } = params;
  const input: client.CreateFirmMutationVariables["input"] = {
    administrativeCount: data ? data.administrativeCount : undefined,
    analystCount: data ? data.analystCount : undefined,
    cik: data ? data.cik : undefined,
    comments: data ? data.comments : undefined,
    isStaffMWDBE: data ? data.isStaffMWDBE : undefined,
    crd: data ? data.crd : undefined,
    employeeTotal: data ? data.employeeTotal : undefined,
    firmIrEmail: data ? data.firmIrEmail : undefined,
    investmentCount: data ? data.investmentCount : undefined,
    isPartner: false,
    lei: data ? data.lei : undefined,
    name: data ? data.name : undefined,
    operationsCount: data ? data.operationsCount : undefined,
    otherAddresses: data ? data.otherAddresses : undefined,
    portfolioManagerCount: data ? data.portfolioManagerCount : undefined,
    primaryAddress: data ? data.primaryAddress : undefined,
    registeredWithIds: data ? data.registeredWithIds : undefined,
    registeredWithOther: data ? data.registeredWithOther : undefined,
    traderCount: data ? data.traderCount : undefined,
    ukHfsbSignatory: data ? data.ukHfsbSignatory : undefined,
    yearFounded: data ? data.yearFounded : undefined,
    investorRelationsCount: data ? data.investorRelationsCount : undefined,
    legalCount: data ? data.legalCount : undefined,
  };

  const {
    addFirm: { id },
  } = await sdk.createFirm({
    input,
  });
  return await getOne(sdk, { id: id! });
}

/*********************************************************************/
async function update(sdk: Sdk, params: types.UpdateParams<FirmUpdateParams>) {
  const id = Number(params.id);
  const { data } = params;

  const fundLinks = data.fundLinksSerialized
    ? Object.entries(data.fundLinksSerialized).map(([key, linkId]) => ({
        investmentId: Number(key.slice(1)),
        linkId,
      }))
    : [];

  const input: client.UpdateFirmMutationVariables["input"] = {
    administrativeCount: data.administrativeCount,
    analystCount: data.analystCount,
    cik: data.cik,
    isCompany: data ? data.isCompany : undefined,
    comments: data.comments,
    crd: data.crd,
    employeeTotal: data.employeeTotal,
    firmIrEmail: data.firmIrEmail,
    investmentCount: data.investmentCount,
    isStaffMWDBE: data.isStaffMWDBE,
    isPartner: data.isPartner,
    lei: data.lei,
    name: data.name,
    operationsCount: data.operationsCount,
    otherAddresses: data.otherAddresses,
    portfolioManagerCount: data.portfolioManagerCount,
    primaryAddress: data.primaryAddress,
    registeredWithIds: data.registeredWithIds,
    registeredWithOther: data.registeredWithOther,
    traderCount: data.traderCount,
    ukHfsbSignatory: data.ukHfsbSignatory,
    yearFounded: data.yearFounded,
    investorRelationsCount: data.investorRelationsCount,
    legalCount: data.legalCount,
    dataOrganizationId: data.dataOrganizationId,
    fundLinks,
  };

  //data.fundsLink
  await sdk.updateFirm({
    id,
    input,
  });
  return await getOne(sdk, { id });
}

async function softDelete(sdk: Sdk, params: types.DeleteParams) {
  const id = String(params.id);
  await sdk.deleteFirm({ id });
  return {
    data: { id },
  };
}

/*********************************************************************/
export const firm = types.dataProvider({
  getList,
  getOne,
  getMany,
  create,
  update,
  delete: softDelete,
});
