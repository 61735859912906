import * as client from "_graphql-types";

import Grid from "@mui/material/Grid";
import { NO_WHITESPACE_REGEX } from "frontend/src/utils/constants";
import {
  BooleanInput,
  DateInput,
  ReferenceInput,
  SelectInput,
  TextInput,
  regex,
  required,
} from "react-admin";
import { useWatch } from "react-hook-form";
import { CustomCreate } from "../CustomCreate";
import {
  CommaFormattedNumber,
  FormattedNumberInput,
} from "../CustomNumberInput";
import { EntityInput } from "../UI/EntityInput";

type Record = client.GetOneDealQuery["deal"];

function PassedNoteInput() {
  const resultEnumId = useWatch({ name: "resultEnumId" });
  if (resultEnumId && Number(resultEnumId) === 1) {
    return (
      <Grid item xs={12}>
        <TextInput
          label="Passed Comment"
          source="passedNote"
          multiline
          rows={5}
        />
      </Grid>
    );
  }
  return null;
}

function SubStrategyInput() {
  const strategyEnumId = useWatch({ name: "strategyEnumId" });
  return (
    <ReferenceInput
      source="subStrategyEnumId"
      reference="subStrategyEnum"
      sort={{ field: "name", order: "ASC" }}
      filter={{
        ...(strategyEnumId && {
          strategyId: strategyEnumId,
        }),
      }}
    >
      <SelectInput
        fullWidth={true}
        optionText="name"
        label="Sub Strategy"
        disabled={strategyEnumId === null || strategyEnumId === undefined}
      />
    </ReferenceInput>
  );
}

function StrategyInput() {
  const assetClassEnumId = useWatch({ name: "assetClassEnumId" });
  return (
    <ReferenceInput
      source="strategyEnumId"
      reference="strategyEnum"
      sort={{ field: "strategy", order: "ASC" }}
      filter={{
        ...(assetClassEnumId && {
          assetClassId: assetClassEnumId,
        }),
      }}
    >
      <SelectInput
        fullWidth={true}
        optionText="name"
        label="Strategy"
        disabled={assetClassEnumId === null || assetClassEnumId === undefined}
      />
    </ReferenceInput>
  );
}

const validateRequiredNoWhitespace = [
  required(),
  regex(NO_WHITESPACE_REGEX, "Please remove leading and trailing spaces"),
];

export const DealCreate = () => (
  <CustomCreate title={() => "Create Deal"}>
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <EntityInput<NonNullable<Record>>
          resource="company"
          recordKey="company"
          label="Company"
          isRequired={false}
          allowChange
        />
      </Grid>
      <Grid item xs={6}>
        <EntityInput<NonNullable<Record>>
          resource="firm"
          recordKey="firm"
          label="Firm"
          isRequired={true}
          allowChange
        />
      </Grid>
      <Grid item xs={6}>
        <TextInput
          label="Project Name"
          source="name"
          validate={validateRequiredNoWhitespace}
        />
      </Grid>
      <Grid item xs={3}>
        <BooleanInput source="isLeadInvestor" label="Lead Investor" />
      </Grid>
      <Grid item xs={3}>
        <BooleanInput source="isImpact" label="Is Impact" />
      </Grid>
      <Grid item xs={6}>
        <ReferenceInput
          source="rcSource"
          reference="investmentAssignmentOption"
          sort={{ field: "commonName", order: "ASC" }}
          filter={{ isPrimary: true }}
          perPage={500}
        >
          <SelectInput
            fullWidth={true}
            optionText="commonName"
            label="RC Source"
          />
        </ReferenceInput>
      </Grid>
      <Grid item xs={6}>
        <TextInput label="Source" source="source" />
      </Grid>
      <Grid item xs={6}>
        <ReferenceInput
          source="fundingRoundEnumId"
          reference="fundingTypeEnum"
          sort={{ field: "name", order: "ASC" }}
        >
          <SelectInput
            fullWidth={true}
            optionText="name"
            label="Funding Round"
          />
        </ReferenceInput>
      </Grid>
      <Grid item xs={6}>
        <DateInput label="Date Received" source="dateReceived" />
      </Grid>
      <Grid item xs={6}>
        <DateInput label="Expected Close Date" source="expectedCloseDate" />
      </Grid>

      <Grid item xs={6}>
        <ReferenceInput
          source="dealStructureEnumId"
          reference="dealStructureEnum"
          sort={{ field: "name", order: "ASC" }}
        >
          <SelectInput
            fullWidth={true}
            optionText="name"
            label="Deal Structure"
          />
        </ReferenceInput>
      </Grid>
      <Grid item xs={6}>
        <ReferenceInput
          source="stageEnumId"
          reference="stageEnum"
          sort={{ field: "displayOrder", order: "ASC" }}
        >
          <SelectInput fullWidth={true} optionText="name" label="Stage" />
        </ReferenceInput>
      </Grid>
      <Grid item xs={6}>
        <ReferenceInput
          source="resultEnumId"
          reference="resultEnum"
          sort={{ field: "name", order: "ASC" }}
        >
          <SelectInput fullWidth={true} optionText="name" label="Result" />
        </ReferenceInput>
      </Grid>
      <PassedNoteInput />

      <Grid item xs={6}>
        <FormattedNumberInput
          source="amount"
          variant="outlined"
          label="Amount"
          fullWidth
          FormattedComponent={CommaFormattedNumber}
        />
      </Grid>
      <Grid item xs={6}>
        <FormattedNumberInput
          source="roundSize"
          variant="outlined"
          label="Total Round Size"
          fullWidth
          FormattedComponent={CommaFormattedNumber}
        />
      </Grid>
      <Grid item xs={6}>
        <FormattedNumberInput
          source="preMoneyValuation"
          variant="outlined"
          label="Pre-money Valuation"
          fullWidth
          FormattedComponent={CommaFormattedNumber}
        />
      </Grid>
      <Grid item xs={6}>
        <ReferenceInput
          source="headquartersEnumId"
          reference="countryEnum"
          perPage={1000}
          sort={{ field: "name", order: "ASC" }}
        >
          <SelectInput
            fullWidth={true}
            optionText="name"
            label="Headquarters"
          />
        </ReferenceInput>
      </Grid>
      <Grid item xs={6}>
        <ReferenceInput
          source="assetClassEnumId"
          reference="assetClassEnum"
          sort={{ field: "assetClass", order: "ASC" }}
        >
          <SelectInput fullWidth={true} optionText="name" label="Asset Class" />
        </ReferenceInput>
      </Grid>
      <Grid item xs={6}>
        <StrategyInput />
      </Grid>
      <Grid item xs={6}>
        <SubStrategyInput />
      </Grid>

      <Grid item xs={6}>
        <ReferenceInput
          source="dealMarketingEnumId"
          reference="dealMarketingEnum"
          sort={{ field: "displayOrder", order: "ASC" }}
        >
          <SelectInput
            fullWidth={true}
            optionText="name"
            label="Deal Marketing"
          />
        </ReferenceInput>
      </Grid>

      <Grid item xs={12}>
        <TextInput label="Deal Summary" source="summary" multiline rows={5} />
      </Grid>
      <Grid item xs={12}>
        <TextInput
          label="Co-Investors"
          source="coInvestors"
          multiline
          rows={3}
        />
      </Grid>
    </Grid>
  </CustomCreate>
);
