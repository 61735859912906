import SaveIcon from "@mui/icons-material/Save";
import {
  Button,
  Card,
  CardContent,
  FormControl,
  InputLabel,
  Menu,
  Switch,
  Toolbar,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import * as client from "_graphql-types";
import { serializePerformanceId } from "_resources/performance";
import { parseISO } from "date-fns";
import React, { useEffect, useState } from "react";
import {
  Error,
  Identifier,
  Loading,
  useCreate,
  useGetList,
  useGetOne,
  useGetRecordId,
  useNotify,
  useRefresh,
  useUpdate,
} from "react-admin";
import { INVESTMENT_BUSINESS_OBJECT_ENUM } from "../../dataProvider/resources/businessObjectEnum";
import { useCanAccessMutation } from "../../util/useCanAccessMutation";
import CustomBreadCrumb from "../CustomBreadCumb";
import { CustomEdit } from "../CustomEdit";
import { Navigation } from "../Navigator";
import Comments from "./comments";
import { FileUploadModal } from "./fileUploadModal";
import { getDefaultAsOf, onlyDistinctRecords } from "./helpers";
import MonthDetail, { MtdRecDetail } from "./monthDetail";
import { Monthly } from "./mtd";
import PerformanceContact from "./performanceContact";
import { Quarterly } from "./quarterly";

type InvestmentRecord = client.GetOneInvestmentQuery["investment"];

export type MtdRec = NonNullable<
  NonNullable<
    client.GetOneInvestmentPerformanceMtdQuery["investment"]
  >["performanceMTD"]
>[0];
export type QtdRec = NonNullable<
  NonNullable<
    client.GetOneInvestmentPerformanceQuarterlyQuery["investment"]
  >["performanceQuarterly"]
>[0];

export type LatestNote = NonNullable<
  client.GetOneInvestmentPerformanceMtdQuery["investment"]
>["latestNote"];

type PerformanceQuarterly = Omit<
  client.GetOneInvestmentPerformanceQuarterlyQuery["investment"],
  "id"
> & {
  id: Identifier;
};

type PerformanceMTD = Omit<
  client.GetOneInvestmentPerformanceMtdQuery["investment"],
  "id"
> & {
  id: Identifier;
};

function getCurrency(performanceData: PerformanceMTD) {
  if (performanceData.performanceMTD?.length) {
    const lastRecord = performanceData.performanceMTD.slice(-1);
    return lastRecord[0].currencyId.toString();
  }

  return performanceData.currencyDenominationEnumId?.toString() ?? "";
}

/**
 * Gets a value from the first object that has the given key; otherwise undefined.
 * Useful to include `undefined` as a possible return type, to handle it properly.
 * @param key
 * @param objs
 * @returns
 */
function get<K extends string | number, V>(key: K, ...objs: Record<K, V>[]) {
  for (const obj of objs) {
    if (key in obj) return obj[key];
  }
}

export function range(min: number, max: number) {
  const arr: number[] = [];
  for (let i = max; i >= min; --i) arr.push(i);
  return arr;
}
export interface YearRange {
  oldMin: number;
  oldMax: number;
  newMin?: number;
  newMax?: number;
}
type PerformanceData =
  | {
      period: "MTD";
      // key is String([returnYear, returnMonth])
      oldRecords: Record<string, MtdRec>;
      newRecords: Record<string, MtdRec>;
    }
  | {
      period: "Quarterly";
      // key is String([returnYear, returnQuarter])
      oldRecords: Record<string, QtdRec>;
      newRecords: Record<string, QtdRec>;
    };

export const PerformanceEdit = (props: any) => {
  const [returnType, setReturnType] = useState<client.ReturnTypes>(
    client.ReturnTypes.Net
  );

  const [yearRange, setYearRange] = useState<YearRange>({
    oldMin: new Date().getFullYear(),
    oldMax: new Date().getFullYear(),
  });
  // key is [returnYear, returnMonth]
  const [monthDetailKey, setMonthDetailKey] = useState<[number, number]>();
  const [period, setPeriod] = useState<"MTD" | "Quarterly">("MTD");
  const [comment, setComment] = useState<{ text?: string; dirty: boolean }>();
  const [performanceData, setPerformanceData] = useState<PerformanceData>({
    period,
    oldRecords: {},
    newRecords: {},
  });
  const [uploadModal, setUploadModal] = useState(false);
  const id = useGetRecordId();
  const refresh = useRefresh();
  const notify = useNotify();

  const mutationArgs = JSON.stringify({
    input: {
      investmentId: id,
    },
  });

  // mutations addOrUpdatePerformanceMTD, addOrUpdatePerformanceQuarterly
  // & updatePerformanceCommentary all use the same api decorator
  // ("INVESTMENT_UPDATE_DATA_MANAGER_ARGS_input_investmentId")\
  const {
    canEdit,
    canEditField,
    loading: accessLoading,
  } = useCanAccessMutation("addOrUpdatePerformanceMTD", mutationArgs);

  const {
    data: mtdData,
    error: mtdError,
    isLoading: mtdLoading,
  } = useGetOne<PerformanceMTD>(
    "performance",
    {
      id: serializePerformanceId({
        investmentId: id,
        returnType,
        period,
      }),
    },
    { enabled: period === "MTD" }
  );

  const {
    data: qtdData,
    error: qtdError,
    isLoading: qtdLoading,
  } = useGetOne<PerformanceQuarterly>(
    "performance",
    {
      id: serializePerformanceId({
        investmentId: id,
        returnType,
        period,
      }),
    },
    { enabled: period === "Quarterly" }
  );

  const {
    data: currencyDenominationEnumData,
    error: currencyDenominationEnumError,
    isLoading: currencyDenominationEnumLoading,
  }: {
    data?: client.GetListCurrencyDenominationEnumQuery["currencyDenominationEnumList"]["items"];
    error?: any;
    isLoading: boolean;
  } = useGetList("currencyDenominationEnum", {
    pagination: { page: 1, perPage: 999 },
    sort: { field: "name", order: "ASC" },
    filter: { isPerformanceCurrency: true },
  });

  const [currencyId, setCurrencyId] = useState<string>("");
  const error: React.ComponentProps<typeof Error>["error"] =
    mtdError ?? qtdError ?? currencyDenominationEnumError;
  const loading = mtdLoading || qtdLoading || currencyDenominationEnumLoading;

  useEffect(() => {
    if (period === "MTD" && mtdData) {
      const years = (mtdData.performanceMTD || []).map(p => p.returnYear);
      if (years.length) {
        setYearRange({
          oldMin: Math.min(...years),
          oldMax: Math.max(...years),
        });
      }

      setPerformanceData({
        period,
        oldRecords: Object.fromEntries(
          (mtdData.performanceMTD || []).map(rec => [
            String([rec.returnYear, rec.returnMonth]),
            rec,
          ])
        ),
        newRecords: {},
      });
      setCurrencyId(getCurrency(mtdData));
      setComment({
        text: mtdData.latestNote?.text ?? undefined,
        dirty: false,
      });
    } else if (period === "Quarterly" && qtdData) {
      const years = (qtdData.performanceQuarterly || []).map(p => p.returnYear);
      if (years.length) {
        setYearRange({
          oldMin: Math.min(...years),
          oldMax: Math.max(...years),
        });
      }

      setPerformanceData({
        period,
        oldRecords: Object.fromEntries(
          (qtdData.performanceQuarterly || []).map(rec => [
            String([rec.returnYear, rec.returnQuarter]),
            rec,
          ])
        ),
        newRecords: {},
      });
      setComment({
        text: qtdData.latestNote?.text ?? undefined,
        dirty: false,
      });
    } else {
      setPerformanceData({
        period,
        oldRecords: {},
        newRecords: {},
      });
    }
  }, [period, mtdData, qtdData]);

  // Passing arguments on callback; otherwise comment text will always be passed in as initial value of comment state (i.e. undefined)
  const [addComment] = useCreate();

  const [updatePerformance] = useUpdate(
    "performance",
    {
      id: `${id}::${period}::${returnType}`,
      data: {
        investmentId: id,
        returnType,
        currencyId: Number(currencyId),
        period,
        newRecords: Object.values(performanceData?.newRecords),
      },
    },
    {
      onSuccess: () => {
        notify("Performance Saved", { type: "success" });
        setPerformanceData(
          performanceData.period === "MTD"
            ? {
                ...performanceData,
                oldRecords: {
                  ...performanceData.oldRecords,
                  ...performanceData.newRecords,
                },
                newRecords: {},
              }
            : {
                ...performanceData,
                oldRecords: {
                  ...performanceData.oldRecords,
                  ...performanceData.newRecords,
                },
                newRecords: {},
              }
        );
        setMonthDetailKey(undefined);
        refresh();
      },
      onError: (e: any) => {
        notify(`Failed to write: ${e}`, { type: "error" });
      },
    }
  );

  const addChangedRecord = (
    count: number,
    year: number,
    returnValue: string | null
  ) => {
    const key = String([year, count]);
    if (performanceData.period === "MTD") {
      const mtdRecord = get(
        key,
        performanceData.newRecords,
        performanceData.oldRecords
      );
      const addRecord: MtdRec = {
        returnMonth: count,
        returnYear: year,
        return: returnValue ? Number(returnValue) : null,
        currencyId: Number(currencyId),
        asOfDate: getDefaultAsOf(new Date(year, count - 1)),
        createUser: mtdRecord?.createUser ?? "",
        createDate: mtdRecord?.createDate ?? new Date().toISOString(),
      };
      setPerformanceData({
        ...performanceData,
        newRecords: {
          ...performanceData.newRecords,
          [key]: addRecord,
        },
      });
    } else {
      const addRecord: QtdRec = {
        returnQuarter: count,
        returnYear: year,
        return: returnValue ? Number(returnValue) : null,
        returnType: returnType as client.ReturnTypes,
      };
      setPerformanceData({
        ...performanceData,
        newRecords: {
          ...performanceData.newRecords,
          [key]: addRecord,
        },
      });
    }
  };

  const addChangedRecordDetail = (
    returnMonth: number,
    returnYear: number,
    detailType: "asOfDate" | "performanceCommentary" | "returnValue",
    value: string,
    monthDetail: MtdRecDetail
  ) => {
    if (performanceData.period === "MTD") {
      const key = String([returnYear, returnMonth]);
      const { asOfDateUpdated, ...addRecord } = monthDetail;

      switch (detailType) {
        case "asOfDate":
          if (!value) break; //don't update asOfDate if empty (prevent clear)
          addRecord.asOfDate = parseISO(value).toISOString();
          break;
        case "performanceCommentary":
          addRecord.performanceCommentary = value;
          addRecord.asOfDate = getDefaultAsOf(
            new Date(returnYear, returnMonth - 1)
          );
          break;
        case "returnValue":
          addRecord.return = value ? Number(value) : null;
          addRecord.asOfDate = getDefaultAsOf(
            new Date(returnYear, returnMonth - 1)
          );
          break;
      }

      setPerformanceData({
        ...performanceData,
        newRecords: {
          ...performanceData.newRecords,
          [key]: addRecord,
        },
      });
    }
  };

  if (error) return <Error error={error} resetErrorBoundary={() => {}} />;
  const name = mtdData?.name ?? qtdData?.name;
  const firmId = mtdData?.firmId ?? qtdData?.firmId;
  const performanceSource = (period === "MTD" ? mtdData : qtdData)
    ?.performanceSource;

  if (!name || !firmId) return null;

  const hasChanges = !!(
    //there is a new comment
    //there are new records
    (
      comment?.text !== mtdData?.latestNote?.text ||
      Object.keys(performanceData.newRecords).length
    )
  );

  return (
    <CustomEdit
      title={() => "Performance - " + name}
      actions={
        <CustomBreadCrumb<InvestmentRecord>
          name="performance"
          items={[
            {
              path: "firm",
              getId: record => record?.firm?.id,
              getName: record => record?.firm?.name || "Firm",
            },
            {
              path: "investment",
              getId: record => record?.id,
              getName: record => record?.name || "Investment",
            },
          ]}
        />
      }
      sourcedFromOdc={() =>
        !!(period === "MTD" ? mtdData : qtdData)?.portalSubmitted?.migratedAt
      }
      customFormProps={{
        customToolbarProps: {
          allowSave: false,
          canAccessMutation: canEdit,
        },
        loading: accessLoading,
        canEditField,
      }}
    >
      <CardContent key={id}>
        <Grid container>
          <Navigation pathName={"performance"} hasChanges={hasChanges} />
          <PerformanceContact />
          <Grid item xs={12}>
            <hr />
          </Grid>
        </Grid>
        <Grid container>
          {uploadModal && (
            <FileUploadModal
              open={uploadModal}
              onClose={() => setUploadModal(false)}
              bulkAddNewRecords={newRecords => {
                if (performanceData.period === "MTD") {
                  const years = Object.values(newRecords).map(
                    record => record.returnYear
                  );
                  setYearRange({
                    ...yearRange,
                    newMin: Math.min(yearRange.oldMin, ...years),
                    newMax: Math.max(yearRange.oldMax, ...years),
                  });
                  const distinctRecords = onlyDistinctRecords(
                    newRecords,
                    performanceData.oldRecords
                  );
                  setPerformanceData({
                    ...performanceData,
                    newRecords: distinctRecords,
                  });
                  setUploadModal(false);
                }
              }}
              oldRecords={
                performanceData.period === "MTD"
                  ? performanceData.oldRecords
                  : {}
              }
              currencyId={Number(currencyId)}
            />
          )}
          <Grid item xs={12}>
            <Grid container alignItems="center" style={{ padding: "1ex 0" }}>
              <Grid item xs={2}>
                <Grid container component="label" spacing={1}>
                  <Grid item>Gross</Grid>
                  <Grid item>
                    <Switch
                      size="small"
                      checked={returnType === client.ReturnTypes.Net}
                      onChange={() => {
                        setPerformanceData({
                          ...performanceData,
                          newRecords: {},
                        });
                        setYearRange({
                          ...yearRange,
                          newMin: undefined,
                          newMax: undefined,
                        });
                        setReturnType(
                          returnType === client.ReturnTypes.Net
                            ? client.ReturnTypes.Gross
                            : client.ReturnTypes.Net
                        );
                      }}
                    />
                  </Grid>
                  <Grid item>Net</Grid>
                </Grid>
              </Grid>
              <Grid item xs={2}>
                <Grid container component="label" spacing={1}>
                  <Grid item>QTD</Grid>
                  <Grid item>
                    <Switch
                      size="small"
                      checked={period === "MTD"}
                      onChange={() => {
                        setPerformanceData({
                          ...performanceData,
                          newRecords: {},
                        });
                        setYearRange({
                          ...yearRange,
                          newMin: undefined,
                          newMax: undefined,
                        });
                        setMonthDetailKey(undefined);
                        period === "MTD"
                          ? setPeriod("Quarterly")
                          : setPeriod("MTD");
                      }}
                      data-id="periodSwitch"
                    />
                  </Grid>
                  <Grid item>MTD</Grid>
                </Grid>
              </Grid>
              <Grid item xs={2}>
                {period === "MTD" && (
                  <FormControl required variant="outlined" size="small">
                    <InputLabel>Currency</InputLabel>
                    <Select
                      variant="standard"
                      data-id="currencyId-input"
                      value={currencyId}
                      onChange={e => {
                        setCurrencyId(e.target.value as string);
                      }}
                      disabled={!canEdit}
                      label="Currency"
                      style={{ minWidth: "10em" }}
                    >
                      {currencyDenominationEnumData?.map(({ id, name }) => (
                        <MenuItem
                          value={id}
                          key={id}
                          style={{
                            fontWeight:
                              id === (mtdData?.currencyDenominationEnumId ?? 1)
                                ? "bold"
                                : "normal",
                          }}
                        >
                          {name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              </Grid>
              <Grid item xs={2}>
                {canEdit && period === "MTD" && (
                  <Button
                    data-id="bulkUploadModal"
                    onClick={() => setUploadModal(true)}
                    color="primary"
                    variant="contained"
                  >
                    Bulk Upload
                  </Button>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={period === "Quarterly" ? 12 : 9}>
            {performanceData.period === "MTD" && (
              <Monthly
                data={Object.values({
                  ...performanceData.oldRecords,
                  ...performanceData.newRecords,
                })}
                loading={loading}
                yearRange={yearRange}
                setYearRange={setYearRange}
                addChangedRecord={addChangedRecord}
                selectMonthDetail={(month, year) => {
                  setMonthDetailKey([year, month]);
                }}
                canEdit={canEdit}
              />
            )}
            {performanceData.period === "Quarterly" && (
              <Quarterly
                data={Object.values(performanceData.oldRecords)}
                loading={loading}
                yearRange={yearRange}
                setYearRange={setYearRange}
                addChangedRecord={addChangedRecord}
                canEdit={canEdit}
              />
            )}
            {loading && <Loading />}

            <Toolbar>
              {canEdit && (
                <Button
                  color="primary"
                  variant="contained"
                  onClick={async () => {
                    notify("Saving...", { type: "info" });
                    // we always submit because they may change the currency
                    await updatePerformance();
                    if (comment?.dirty) {
                      await addComment(
                        "notes",
                        {
                          data: {
                            businessObjectEnumId:
                              INVESTMENT_BUSINESS_OBJECT_ENUM,
                            investmentId: Number(id),
                            noteMetaId: 9,
                            text: comment.text,
                          },
                        },
                        {
                          onSuccess: () => {
                            notify("Comment Saved", { type: "success" });
                            refresh();
                          },
                          onError: () => {
                            notify("Failed to save comment", {
                              type: "error",
                            });
                          },
                        }
                      );
                    }
                  }}
                  disabled={period === "MTD" && !currencyId}
                  style={{ margin: "12px" }}
                  data-id="performanceSave"
                >
                  <SaveIcon />
                  Save
                </Button>
              )}
              {!currencyId && <span>Choose a currency to save</span>}
            </Toolbar>
          </Grid>
          <Grid item xs={3}>
            <Card style={{ margin: "3%" }}>
              <CardContent>
                {performanceData.period === "MTD" && !!monthDetailKey && (
                  <MonthDetail
                    monthDetail={
                      [monthDetailKey].map(([returnYear, returnMonth]) => {
                        const key = String(monthDetailKey);
                        const newRecord = get(key, performanceData.newRecords);
                        let existingRecord = get(
                          key,
                          performanceData.newRecords,
                          performanceData.oldRecords
                        );

                        return {
                          return: existingRecord?.return ?? undefined,
                          currencyId:
                            existingRecord?.currencyId ?? Number(currencyId),
                          returnYear,
                          returnMonth,
                          asOfDate: existingRecord
                            ? existingRecord.asOfDate
                            : getDefaultAsOf(
                                new Date(returnYear, returnMonth - 1)
                              ),
                          asOfDateUpdated: !!newRecord?.asOfDate,
                          createUser: existingRecord?.createUser ?? "",
                          createDate:
                            existingRecord?.createDate ??
                            new Date().toISOString(),
                          modifyUser: existingRecord?.modifyUser ?? undefined,
                          modifyDate: existingRecord?.modifyDate ?? undefined,
                          performanceCommentary:
                            existingRecord?.performanceCommentary ?? undefined,
                        };
                      })[0]
                    }
                    addChangedRecordDetail={addChangedRecordDetail}
                    isReturnEntered={
                      !!get(
                        String(monthDetailKey),
                        performanceData.newRecords,
                        performanceData.oldRecords
                      )
                    }
                    canEdit={canEdit}
                  />
                )}
                <Comments
                  comment={comment?.text}
                  setComment={text => setComment({ text, dirty: true })}
                  latestNoteRecord={
                    mtdData?.latestNote ?? qtdData?.latestNote ?? null
                  }
                  canEdit={canEdit}
                />
                {canEdit && !!monthDetailKey && (
                  <Button
                    variant="contained"
                    onClick={() =>
                      addChangedRecord(
                        monthDetailKey[1],
                        monthDetailKey[0],
                        null
                      )
                    }
                  >
                    Delete Return
                  </Button>
                )}
              </CardContent>
            </Card>
            <div style={{ margin: "1ex" }}>
              <Typography>Source: {performanceSource ?? ""}</Typography>
            </div>
          </Grid>
        </Grid>
      </CardContent>
    </CustomEdit>
  );
};
