import React, { useState } from "react";
import { Button, Chip, Collapse, List, ListItemText } from "@mui/material";
import { RaRecord } from "ra-core";
import { camelCase } from "lodash";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

interface DropdownProps<T extends RaRecord> {
  label: string;
  dataList: T[];
  renderListItem: (item: T) => JSX.Element;
}

export function SingleLevelDropdown<T extends RaRecord>({
  label,
  dataList,
  renderListItem,
  open,
}: DropdownProps<T> & { open: boolean }) {
  return (
    <Collapse in={open}>
      <List>
        {dataList.map((item, index) => (
          <ListItemText key={`${camelCase(label)}__${item.id}-${index}`}>
            {renderListItem(item)}
          </ListItemText>
        ))}
      </List>
    </Collapse>
  );
}

export function ButtonDropdown<T extends RaRecord>({
  label,
  dataList,
  renderListItem,
}: DropdownProps<T>) {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Button
        aria-controls={open ? "dropdown" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        endIcon={!!open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        onClick={() => setOpen(!open)}
      >
        {label}
      </Button>
      <SingleLevelDropdown
        open={open}
        label={label}
        dataList={dataList}
        renderListItem={renderListItem}
      />
    </>
  );
}

export function ChipDropdown<T extends RaRecord>({
  label,
  dataList,
  renderListItem,
}: DropdownProps<T>) {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Chip
        clickable
        icon={!!open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        label={label}
        onClick={() => setOpen(!open)}
      />
      <SingleLevelDropdown
        open={open}
        label={label}
        dataList={dataList}
        renderListItem={renderListItem}
      />
    </>
  );
}

/**
 * UI Component for displaying a single-level dropdown menu with a button or chip trigger
 * @param triggerType - "button" or "chip"
 * @param label - The label for the dropdown
 * @param dataList - The list of records (gets passed to renderListItem)
 * @param renderListItem - A function that takes an item from dataList and returns a JSX element to display in the dropdown
 */
export function SimpleDropdownDisplay<T extends RaRecord>({
  triggerType,
  ...dropDownProps
}: DropdownProps<T> & { triggerType: "button" | "chip" }) {
  if (triggerType === "button") {
    return <ButtonDropdown {...dropDownProps} />;
  }
  return <ChipDropdown {...dropDownProps} />;
}
