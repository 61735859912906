import * as client from "_graphql-types";
import {
  TextInput,
  SelectInput,
  BooleanInput,
  useRecordContext,
  regex,
  required,
  ArrayInput,
  Button,
} from "react-admin";
import { Grid, Tooltip, Typography } from "@mui/material";
import { PEInvestmentFees } from "./PEInvestmentFees";
import { InvestmentFees } from "./InvestmentFees";
import { NO_WHITESPACE_REGEX } from "frontend/src/utils/constants";
import { Liquidity } from "./Liquidity";
import {
  ReferenceManyInput,
  ReferenceOneInput,
} from "@react-admin/ra-relationships";
import { Box, Stack } from "@mui/system";
import { SoftLockupForm } from "./SoftLockup";
import { Link } from "react-router-dom";
import LiquidityHelp from "./LiquidityHelp";

export type IRecord = NonNullable<client.GetOneShareClassQuery["shareClass"]>;

const validateRequiredNoWhitespace = [
  required(),
  regex(NO_WHITESPACE_REGEX, "Please remove leading and trailing spaces"),
];

export const FeeStructure = ({
  canEditField,
}: {
  canEditField: (key: string) => boolean;
}) => {
  const record = useRecordContext<IRecord>();
  if (!record || !record?.investment?.marketEnumId) return null;

  return record.investment.marketEnumId === 2 ? (
    <PEInvestmentFees canEditField={canEditField} />
  ) : (
    <InvestmentFees canEditField={canEditField} />
  );
};

function PublicFields({
  canEditField,
}: {
  canEditField: (key: string) => boolean;
}) {
  const record = useRecordContext<IRecord>();
  if (record?.investment?.marketEnumId === 2) return null;

  return (
    <>
      <Stack direction="row" spacing={1} alignItems="center">
        <Typography variant="h6">Liquidity</Typography>
        <LiquidityHelp />
      </Stack>
      <Liquidity canEditField={canEditField} />

      <Typography marginBottom="1em" variant="h6">
        Soft Lockup
      </Typography>

      <SoftLockupForm canEditField={canEditField} />
    </>
  );
}

export function ShareClassForm({
  canEditField,
}: {
  canEditField: (key: string) => boolean;
}) {
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={6} lg={4}>
          <SelectInput
            source="status"
            choices={[
              { id: "Open", name: "Open" },
              { id: "Closed", name: "Closed" },
            ]}
            disabled={!canEditField("status")}
          />
        </Grid>
        <Grid item xs={6} lg={4}>
          <TextInput
            source="name"
            disabled={!canEditField("name")}
            validate={validateRequiredNoWhitespace}
          />
        </Grid>
        <Grid item xs={6} lg={2}>
          <BooleanInput
            source="isRcgNegotiated"
            label="Is RockCreek Negotiated"
            disabled={!canEditField("isRcgNegotiated")}
          />
        </Grid>
        <Grid item xs={6} lg={2}>
          <BooleanInput
            source="restricted"
            label="Restricted"
            disabled={!canEditField("restricted")}
          />
        </Grid>
      </Grid>
      <Typography marginBottom="1em" variant="h6">
        Fee Structure
      </Typography>
      <FeeStructure canEditField={canEditField} />
      <Grid container spacing={2}>
        <Grid item xs={12} lg={12}>
          <TextInput
            multiline
            source="otherFees"
            label="Other Fees"
            rows={5}
            disabled={!canEditField("otherFees")}
          />
        </Grid>
      </Grid>
      <PublicFields canEditField={canEditField} />
    </>
  );
}
