import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { PerformanceMultiInvestment } from "./PerformanceMultiInvestment";

export const PerformanceMultiInvestmentView = () => (
  <Grid container spacing={2}>
    <Grid item xs={12}>
      <Paper elevation={2} style={{ padding: "1ex 1em", marginTop: "1em" }}>
        <PerformanceMultiInvestment />
      </Paper>
    </Grid>
  </Grid>
);
