import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  Tab,
  Table,
  TableCell,
  TableRow,
} from "@mui/material";
import { EditableDatagrid, RowForm } from "@react-admin/ra-editable-datagrid";
import * as client from "_graphql-types";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  ArrayField,
  AutocompleteInput,
  BooleanField,
  Button,
  ChipField,
  Datagrid,
  DateField,
  FunctionField,
  ListBase,
  NumberField,
  Pagination,
  ReferenceInput,
  ReferenceManyField,
  SingleFieldList,
  TextField,
  TextInput,
  regex,
  required,
  useRecordContext,
  useUpdate,
} from "react-admin";
import { Link } from "react-router-dom";
import { CustomEdit } from "../CustomEdit";
type Record = client.GetOneUserQuery["user"];

const validateCommonName = [required(), regex(/^\S/)];
const SORT_BY_NAME = { field: "name", order: "ASC" } as const;

function ActivateUserInOktaButton() {
  const record = useRecordContext<Record>();
  if (!record) return;

  const [update, { isLoading, error }] = useUpdate();
  const disableButton = record.flags.isRockCreek || isLoading;

  const handleActivate = useCallback(() => {
    update("user", {
      id: record.id,
      data: { lifecycle: "ACTIVATE" },
    });
  }, [update, record]);

  return (
    <>
      <Button
        label={"Activate in Okta"}
        variant="contained"
        disabled={disableButton}
        onClick={handleActivate}
      />
      {record.flags.isRockCreek && (
        <div>
          We do not allow UI activation of RockCreek Users, this should happen
          through the active record integration.
        </div>
      )}
      {error && (
        <>{(error as { message: string }).message}.. Perhaps Refresh.</>
      )}
    </>
  );
}

function DeactivateUserInOktaButton() {
  const record = useRecordContext<Record>();
  if (!record) return;

  const [update, { isLoading, error }] = useUpdate();

  const handleDeactivate = useCallback(() => {
    update("user", {
      id: record.id,
      data: { lifecycle: "DEACTIVATE" },
    });
  }, [update, record]);

  const disableButton = record.flags.isRockCreek || isLoading;

  return (
    <>
      <Button
        label={"Deactivate in Okta"}
        variant="contained"
        disabled={disableButton}
        onClick={handleDeactivate}
      />
      {record.flags.isRockCreek && (
        <div>
          We do not allow UI deactivation of RockCreek Users or Admin, as it can
          be destructive
        </div>
      )}
      {error && <>{(error as { message: string }).message}</>}
    </>
  );
}

const CARD_SX = { maxWidth: 600, marginBottom: "1em" } as const;
const HEADER_SX = { fontWeight: "bold" } as const;
function UserAndGroupsEditForm() {
  const record = useRecordContext<Record>();
  if (!record) return;

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Card sx={CARD_SX}>
            <CardHeader title={record.isUser ? "User" : "Group"} />
            <CardContent>
              <Table>
                <TableRow>
                  <TableCell sx={HEADER_SX}>Id</TableCell>
                  <TableCell>
                    <TextField label="Id" source="id" />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={HEADER_SX}>Email</TableCell>
                  <TableCell>
                    <TextField label="Email" source="email" />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={HEADER_SX}>Organization</TableCell>
                  <TableCell>
                    <TextField
                      label="Organization"
                      source="organization.name"
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={HEADER_SX}>Organization Id</TableCell>
                  <TableCell>
                    <TextField label="Organization" source="organization.id" />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={HEADER_SX}>Common Name</TableCell>
                  <TableCell>
                    <TextField label="Common Name" source="commonName" />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={HEADER_SX}>User Name</TableCell>
                  <TableCell>
                    <TextField label="User Name" source="userName" />
                  </TableCell>
                </TableRow>
              </Table>
            </CardContent>
          </Card>
          <TextInput
            label="Common Name"
            source="commonName"
            validate={validateCommonName}
            sx={CARD_SX}
          />
        </Grid>
        <Grid item xs={6}>
          {record && record.isUser && !record.oktaUser && (
            <ActivateUserInOktaButton />
          )}
          {record && record.isUser && record.oktaUser && (
            <DeactivateUserInOktaButton />
          )}
          {record && record.oktaUser && (
            <Card sx={CARD_SX}>
              <CardHeader title={"Okta User"} />
              <CardContent>
                <Table>
                  <TableRow>
                    <TableCell sx={HEADER_SX}>Id</TableCell>
                    <TableCell>
                      <TextField label="id" source="oktaUser.id" />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={HEADER_SX}>Created</TableCell>
                    <TableCell>
                      <DateField label="Created" source="oktaUser.created" />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={HEADER_SX}>Activated</TableCell>
                    <TableCell>
                      <DateField
                        label="Activated"
                        source="oktaUser.activated"
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={HEADER_SX}>Password Changed</TableCell>
                    <TableCell>
                      <DateField
                        label="Password Changed"
                        source="oktaUser.passwordChanged"
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={HEADER_SX}>Last Login</TableCell>
                    <TableCell>
                      <DateField
                        label="Last Login"
                        source="oktaUser.lastLogin"
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={HEADER_SX}>Status</TableCell>
                    <TableCell>
                      <TextField label="Status" source="oktaUser.status" />
                    </TableCell>
                  </TableRow>
                </Table>
              </CardContent>
            </Card>
          )}
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={6}></Grid>
      </Grid>
    </>
  );
}

export function UserFlags() {
  return (
    <Card sx={CARD_SX}>
      <CardHeader title={"Flags"} />
      <CardContent>
        <Table>
          <TableRow>
            <TableCell sx={HEADER_SX}>DCAdmin</TableCell>
            <TableCell>
              <BooleanField source={"flags.DCAdmin"} />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={HEADER_SX}>isRockCreek</TableCell>
            <TableCell>
              <BooleanField source={"flags.isRockCreek"} />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={HEADER_SX}>isRiskUser</TableCell>
            <TableCell>
              <BooleanField source={"flags.isRiskUser"} />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={HEADER_SX}>isReportingUser</TableCell>
            <TableCell>
              <BooleanField source={"flags.isReportingUser"} />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={HEADER_SX}>isServiceProviderManager</TableCell>
            <TableCell>
              <BooleanField source={"flags.isServiceProviderManager"} />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={HEADER_SX}>isDataManager</TableCell>
            <TableCell>
              <BooleanField source={"flags.isDataManager"} />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={HEADER_SX}>isClientUser</TableCell>
            <TableCell>
              <BooleanField source={"flags.isClientUser"} />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={HEADER_SX}>isPortalUser</TableCell>
            <TableCell>
              <BooleanField source={"flags.isPortalUser"} />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={HEADER_SX}>businessApplicant</TableCell>
            <TableCell>
              <BooleanField source={"flags.businessApplicant"} />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={HEADER_SX}>isRockCreekAdmin</TableCell>
            <TableCell>
              <BooleanField source={"flags.isRockCreekAdmin"} />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={HEADER_SX}>canAssumeUser</TableCell>
            <TableCell>
              <BooleanField source={"flags.canAssumeUser"} />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={HEADER_SX}>RECFReviewer</TableCell>
            <TableCell>
              <BooleanField source={"flags.RECFReviewer"} />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={HEADER_SX}>investmentPageAccess</TableCell>
            <TableCell>
              <BooleanField source={"flags.investmentPageAccess"} />
            </TableCell>
          </TableRow>
        </Table>
      </CardContent>
    </Card>
  );
}

const CREATE_DATE_SORT = { field: "createDate", order: "DESC" } as const;

function UserActivity() {
  const record = useRecordContext();
  if (!record) return;

  const filter = useMemo(() => ({ userId: record.id }), [record.id]);

  return (
    <ListBase
      resource="userActivity"
      filter={filter}
      sort={CREATE_DATE_SORT}
      perPage={30}
      disableSyncWithLocation={true}
    >
      <Datagrid rowClick="edit" bulkActionButtons={false}>
        <TextField label="ID" source="id" sortable={false} />
        <DateField label="createDate" source="createDate" sortable={false} />
        <TextField label="email" source="email" sortable={false} />
        <TextField label="adEntryId" source="adEntryId" sortable={false} />
        <TextField
          label="Application Id"
          source="applicationId"
          sortable={false}
        />
        <TextField
          label="Application Version"
          source="rcgApplicationVersion"
          sortable={false}
        />
        <TextField label="requestUri" source="requestUri" sortable={false} />
        <DateField
          label="requestUtc"
          source="requestUtc"
          sortable={false}
          showTime={true}
        />
      </Datagrid>
      <Pagination />
    </ListBase>
  );
}

function SetsEdit() {
  const record = useRecordContext();
  if (!record) return;

  const DataGridForm = useMemo<JSX.Element>(() => {
    return (
      <RowForm>
        <TextInput
          readOnly
          source="entryId"
          defaultValue={record.id}
          label={"Group Id"}
        />

        <ReferenceInput source="setId" reference="set">
          <AutocompleteInput label="Set Name" optionText="name" />
        </ReferenceInput>

        <ReferenceInput source="applicationId" reference="application">
          <AutocompleteInput label="Application Name" optionText="name" />
        </ReferenceInput>
      </RowForm>
    );
  }, [record.id]);

  return (
    <ReferenceManyField
      reference="adEntriesXApplicationsXSets"
      target="entryId"
      perPage={25}
    >
      <EditableDatagrid
        createForm={DataGridForm}
        editForm={DataGridForm}
        bulkActionButtons={false}
      >
        <TextField source="entry.commonName" label="Entry Name" />
        <FunctionField
          label="Set Name"
          sortable={false}
          render={(record: any) => (
            <Link to={`/set/${record.setId}`}>
              <TextField label="Set Name" source="set.name" sortable={false} />
            </Link>
          )}
        />
        <TextField source="application.name" />
      </EditableDatagrid>
      <Pagination />
    </ReferenceManyField>
  );
}

function RolesEdit() {
  const record = useRecordContext();
  if (!record) return;

  const DataGridForm = useMemo<JSX.Element>(() => {
    return (
      <RowForm>
        <TextInput
          readOnly
          source="userId"
          defaultValue={record.id}
          label={"Group Id"}
        />

        <ReferenceInput
          source="applicationFlagId"
          reference="applicationFlag"
          alwaysOn
          allowEmpty
        >
          <AutocompleteInput
            label="Flag Name"
            optionText={record => `${record.name} - ${record.description}`}
          />
        </ReferenceInput>
      </RowForm>
    );
  }, [record.id]);

  return (
    <ReferenceManyField
      reference="adEntriesXApplicationFlags"
      perPage={25}
      target={"userId"}
    >
      <EditableDatagrid
        createForm={DataGridForm}
        editForm={DataGridForm}
        bulkActionButtons={false}
      >
        <NumberField label="Id" source="id" />
        <TextField label="Flag Name" source="applicationFlag.name" />
        <TextField
          label="Flag Description"
          source="applicationFlag.description"
        />
      </EditableDatagrid>
      <Pagination />
    </ReferenceManyField>
  );
}

function GroupMembersEdit() {
  const record = useRecordContext();
  if (!record) return;

  const USERS_FILTER = useMemo(() => {
    return { isUser: true };
  }, []);

  const DataGridForm = useMemo<JSX.Element>(() => {
    return (
      <RowForm>
        <TextInput readOnly source="groupId" defaultValue={record.id} />

        <ReferenceInput
          source="userId"
          reference="user"
          filter={USERS_FILTER}
          sort={SORT_BY_NAME}
          alwaysOn
        >
          <AutocompleteInput
            label="User"
            optionText={record => {
              return `${record.email} (${record.commonName})`;
            }}
          />
        </ReferenceInput>
      </RowForm>
    );
  }, [record.id]);

  return (
    <ReferenceManyField
      reference="usersXGroups"
      target="groupId"
      label="Group"
      perPage={25}
    >
      <EditableDatagrid
        createForm={DataGridForm}
        editForm={DataGridForm}
        bulkActionButtons={false}
      >
        <TextField source="id" sortable={false} />
        <FunctionField
          label="User Email"
          sortable={false}
          render={(record: any) => (
            <Link to={`/user/${record.userId}`}>
              <TextField
                label="User Email"
                source="user.email"
                sortable={false}
              />
            </Link>
          )}
        />

        <FunctionField
          label="User Name"
          sortable={false}
          render={(record: any) => (
            <Link to={`/user/${record.userId}`}>
              <TextField
                label="User Name"
                source="user.commonName"
                sortable={false}
              />
            </Link>
          )}
        />
        <FunctionField
          label="User Organization"
          sortable={false}
          render={(record: any) =>
            !!record.user.organization ? (
              <Link to={`/organization/${record.user.organization.id}`}>
                <TextField
                  label="User Organization"
                  source="user.organization.name"
                  sortable={false}
                />
              </Link>
            ) : (
              <TextField
                label="User Organization"
                source="user.organization.name"
                sortable={false}
              />
            )
          }
        />
      </EditableDatagrid>
      <Pagination />
    </ReferenceManyField>
  );
}

function GroupsEdit() {
  const GROUPS_FILTER = useMemo(() => {
    return { isGroup: true };
  }, []);

  const record = useRecordContext();
  if (!record) return;

  const DataGridForm = useMemo<JSX.Element>(() => {
    return (
      <RowForm>
        <TextInput readOnly source="userId" defaultValue={record.id} />

        <ReferenceInput
          source="groupId"
          reference="user"
          filter={GROUPS_FILTER}
          sort={SORT_BY_NAME}
          alwaysOn
          allowEmpty
        >
          <AutocompleteInput label="Group Name" optionText="commonName" />
        </ReferenceInput>
      </RowForm>
    );
  }, [record.id]);

  return (
    <ReferenceManyField
      reference="usersXGroups"
      target="userId"
      label="Group"
      perPage={25}
    >
      <EditableDatagrid
        createForm={DataGridForm}
        editForm={DataGridForm}
        bulkActionButtons={false}
      >
        <TextField source="id" sortable={false} />

        <FunctionField
          label="Group Name"
          sortable={false}
          render={(record: any) => (
            <Link to={`/user/${record.groupId}`}>
              <TextField
                label="Group Name"
                source="group.commonName"
                sortable={false}
              />
            </Link>
          )}
        />
        <FunctionField
          label="Group Organization"
          sortable={false}
          render={(record: any) =>
            !!record.group.organization ? (
              <Link to={`/organization/${record.group.organization.id}`}>
                <TextField
                  label="Group Organization"
                  source="group.organization.name"
                  sortable={false}
                />
              </Link>
            ) : (
              <TextField
                label="Group Organization"
                source="group.organization.name"
                sortable={false}
              />
            )
          }
        />

        <ArrayField label="Group Flags" source="group.roles" sortable={false}>
          <SingleFieldList>
            <ChipField source="name" size="small" />
          </SingleFieldList>
        </ArrayField>

        <ArrayField label="Group Sets" source="group.sets" sortable={false}>
          <SingleFieldList>
            <ChipField source="name" size="small" />
          </SingleFieldList>
        </ArrayField>
      </EditableDatagrid>
      <Pagination />
    </ReferenceManyField>
  );
}

const FULL_WIDTH = { width: "100%" };
type FormTabOption = "overview" | "activity" | "flags" | "groupMembers";

function FormTabs() {
  const record = useRecordContext();
  if (!record) return;

  const [tabValue, setTabValue] = useState<FormTabOption>("overview");
  const updateTabValue = useCallback(
    (_: any, newTabValue: FormTabOption) => {
      setTabValue(newTabValue);
    },
    [setTabValue]
  );

  useEffect(() => {
    setTabValue("overview");
  }, [record.id]);

  if (!record.flags) return null; //Handle List shape
  return (
    <TabContext value={tabValue}>
      <TabList onChange={updateTabValue}>
        <Tab label="Overview" value={"overview"} />

        {record.isUser && <Tab label="Groups" value={"groups"} />}
        {record.isUser && <Tab label="Activity" value={"activity"} />}
        {record.isUser && <Tab label="Flags" value={"flags"} />}
        {record.isGroup && <Tab label="Group Sets" value={"sets"} />}
        {record.isGroup && <Tab label="Group Flags" value={"roles"} />}
        {record.isGroup && <Tab label="Group Members" value={"users"} />}
      </TabList>
      <TabPanel value={"overview"} sx={FULL_WIDTH}>
        <UserAndGroupsEditForm />
      </TabPanel>
      {record.isGroup && (
        <>
          <TabPanel value={"roles"} sx={FULL_WIDTH}>
            <RolesEdit />
          </TabPanel>
          <TabPanel value={"sets"} sx={FULL_WIDTH}>
            <SetsEdit />
          </TabPanel>
          <TabPanel value={"users"} sx={FULL_WIDTH}>
            <GroupMembersEdit />
          </TabPanel>
        </>
      )}
      {record.isUser && (
        <>
          <TabPanel value={"groups"} sx={FULL_WIDTH}>
            <GroupsEdit />
          </TabPanel>
          <TabPanel value={"activity"} sx={FULL_WIDTH}>
            <UserActivity />
          </TabPanel>
          <TabPanel value={"flags"} sx={FULL_WIDTH}>
            <UserFlags />
          </TabPanel>
        </>
      )}
    </TabContext>
  );
}

export function UserAndGroupsEdit() {
  return (
    <CustomEdit
      resource="user"
      title={(record: Record) => record && `${record.commonName || ""}`}
    >
      <FormTabs />
    </CustomEdit>
  );
}
