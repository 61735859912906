import { CustomEdit } from "../CustomEdit";
import { CustomIndexInvestmentForm, Record, validateForm } from "./Form";

export const CustomIndexInvestmentEdit = () => {
  return (
    <CustomEdit<Record>
      title={record =>
        record && `Custom Index - ${record.outputInvestment.name || ""}`
      }
      customFormProps={{
        simpleFormProps: {
          mode: "all",
          validate: validateForm(false),
        },
      }}
    >
      <CustomIndexInvestmentForm />
    </CustomEdit>
  );
};
