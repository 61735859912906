import * as client from "_graphql-types";

import { DateInput, NumberInput, Button } from "react-admin";
import { CustomCreate } from "../CustomCreate";
import Grid from "@mui/material/Grid";
import React from "react";

type Record = client.GetOneCompanyValuationQuery["companyValuation"];

export const CompanyValuationCreate = () => (
  <CustomCreate title={() => "Create companyValuation"}>
    <Grid container spacing={2}>
      <NumberInput label="Valuation" source="valuation" />
      <DateInput label="AsOfDate" source="asOfDate" />
    </Grid>
  </CustomCreate>
);
