import * as Sentry from "@sentry/react";
import { v4 as uuid } from "uuid";
import * as client from "_graphql-types";
import { filterSortPage } from "../DataProvider";
import {
  formatInvestmentTagsCreateInput,
  formatInvestmentTagsUpdateInput,
} from "../helpers/tagClasses";
import * as types from "./types";
import { numberUnlessNaN } from "../helpers/numbers";

type Sdk = ReturnType<typeof client.getSdk>;

/*********************************************************************/
// Data Provider Functions
/*********************************************************************/

/*********************************************************************/
export async function getList(
  sdk: Sdk,
  params: types.GetListParams<
    client.InvestmentSortEnum,
    {
      id: number;
      active: boolean;
      q: string;
      allowRead: boolean;
      searchFilters: client.InvestmentSearchFilters[];
    }
  >
) {
  const { pagination, sort, filter } = params;

  const { allowRead, searchFilters, ...rest } = filter;

  const {
    investmentList: { items: data, total },
  } = await sdk.getListInvestment({
    ...filterSortPage({
      filter: rest,
      sort,
      nameField: client.InvestmentSortEnum.Name,
      pagination: pagination ?? { offset: 0, limit: 25 },
    }),
    searchFilters: [
      ...(searchFilters || []),
      {
        ACCESS: { values: [allowRead ? client.Prud.Read : client.Prud.Update] },
      },
    ],
  });

  return {
    data,
    total,
  };
}

/*********************************************************************/
async function getOne(sdk: Sdk, params: types.GetOneParams) {
  const id = Number(params.id);
  const { investment: data } = await sdk.getOneInvestment({
    id,
  });

  return { data };
}

/*********************************************************************/
async function getMany(sdk: Sdk, params: types.GetManyParams) {
  const ids = params.ids.filter(Number).map(Number);
  const { investmentMany: data } = await sdk.getManyInvestment({
    ids,
  });
  return { data };
}

/*********************************************************************/
async function getManyReference(
  sdk: Sdk,
  params: types.GetManyReferenceParams<client.InvestmentSortEnum>
) {
  const id = Number(params.id);
  const {
    pagination: { page, perPage },
    sort: { field, order },
    filter,
    target,
  } = params;

  const {
    investmentList: { items: data, total },
  } = await sdk.getManyReferenceInvestment({
    page: {
      offset: (page - 1) * perPage,
      limit: perPage,
    },
    sort: [{ field, order }],
    filter: { ...filter, [target]: id },
  });

  return {
    data,
    total,
  };
}

/*********************************************************************/
async function create(
  sdk: Sdk,
  params: types.CreateParams<
    client.InvestmentInput & client.GetOneInvestmentQuery["investment"]
  >
) {
  const { data } = params;

  const investmentTags =
    data?.investmentTags &&
    formatInvestmentTagsCreateInput(data?.investmentTags);
  if (
    investmentTags &&
    investmentTags.some(
      (tag: client.TagsInput) => tag.tagClassId === 1 || tag.tagClassId === 18
    )
  ) {
    Sentry.withScope(scope => {
      scope.setExtra("params", data);
      Sentry.captureMessage("deprecated tagClassId used", "warning");
    });
  }

  const input: client.CreateInvestmentMutationVariables["input"] = {
    assetClassEnumId: data?.assetClassEnumId,
    atlasInvestmentId: uuid(),
    targetRaise: numberUnlessNaN(data?.targetRaise),
    hardCap: numberUnlessNaN(data?.hardCap),
    committedCapital: numberUnlessNaN(data?.committedCapital),
    committedCapitalCurrencyId: data?.committedCapitalCurrencyId,
    createDate: data?.createDate,
    createUser: data?.createUser,
    firmId: data?.firm?.id,
    gpCommitted: numberUnlessNaN(data?.gpCommitted),
    gpCommittedCurrencyId: data?.gpCommittedCurrencyId,
    inceptionDate: data?.inceptionDate || undefined,
    investmentTags,
    impactTags: data?.impactTags,
    isImpact: data?.isImpact,
    primaryAssignment: data?.primaryAssignment,
    secondaryAssignment: data?.secondaryAssignment,
    isPartner: data?.isPartner,
    isSubAdvised: data?.isSubAdvised,
    accountTypeEnumId: data?.accountTypeEnumId,
    advisorTypeEnumId: data?.advisorTypeEnumId,
    advisorId: data?.advisorId,
    listEnumId: data?.listEnumId,
    shortName: data?.shortName,
    marketEnumId: data?.marketEnumId,
    maxExpectedVolatility: data?.maxExpectedVolatility,
    maxGrossExposure: data?.maxGrossExposure,
    maxNetExposure: data?.maxNetExposure,
    maxTargetNetIRR: data?.maxTargetNetIRR,
    maxTargetNetMOIC: data?.maxTargetNetMOIC,
    minExpectedVolatility: data?.minExpectedVolatility,
    minGrossExposure: data?.minGrossExposure,
    minNetExposure: data?.minNetExposure,
    minTargetNetIRR: data?.minTargetNetIRR,
    minTargetNetMOIC: data?.minTargetNetMOIC,
    modifyDate: data?.modifyDate,
    modifyUser: data?.modifyUser,
    name: data?.name,
    peLegalLifeYrs: data?.peLegalLifeYrs,
    peStrategyEnumId: data?.peStrategyEnumId,
    primaryBenchmarkId: data?.primaryBenchmarkId,
    primaryGeographicFocusId: data?.primaryGeographicFocusId,
    secondaryBenchmarkId: data?.secondaryBenchmarkId,
    strategyEnumId: data?.strategyEnumId,
    domicileUSStateId: data?.domicileUSStateId,
    subStrategyEnumId: data?.subStrategyEnumId,
    summary: data?.summary,
    vehicleTypeEnumId: data?.vehicleTypeEnumId,
    vintageYear: data?.vintageYear,
    isWorkInProgress: data?.isWorkInProgress,
    legalStructureEnumId: numberUnlessNaN(data?.legalStructureEnumId),
    investmentStructureEnumId: numberUnlessNaN(data?.investmentStructureEnumId),
    isRegisteredInvestmentCompany: data?.isRegisteredInvestmentCompany,
    organizationStructureEnumId: numberUnlessNaN(
      data?.organizationStructureEnumId
    ),
    NAVClassificationEnumId: numberUnlessNaN(data?.NAVClassificationEnumId),
    pricingFrequencyEnumId: numberUnlessNaN(data?.pricingFrequencyEnumId),
    domicileFundLinkId: numberUnlessNaN(data?.domicileFundLinkId),
    reportingLinkId: data?.reportingLinkId,
    peFundNumberEnumId: data?.peFundNumberEnumId,
    isManagerUnwillingToProvidePerformance:
      data?.isManagerUnwillingToProvidePerformance,
    isManagerUnwillingToProvidePositions:
      data?.isManagerUnwillingToProvidePositions,
    isManagerUnwillingToProvideRisk: data?.isManagerUnwillingToProvideRisk,
    isManagerUnwillingToProvideAUM: data?.isManagerUnwillingToProvideAUM,
    ndaSigned: data?.ndaSigned,
    HFRIInvestmentId: data?.HFRIInvestmentId,
    evestmentId: data?.evestmentId,
    burgissId: data?.burgissId,
    RICId: data?.RICId,
    isOffshore: data?.isOffshore,
    domicileCountryId: data?.domicileCountryId,
    currencyDenominationEnumId: data?.currencyDenominationEnumId,
    reportingDate: data?.reportingDate,
    reportingDateReason: data?.reportingDateReason,
    documents: data?.documents,
  };

  const {
    addInvestment: { id },
  } = await sdk.createInvestment({
    input,
  });
  return await getOne(sdk, { id: id! });
}

/*********************************************************************/
async function update(
  sdk: Sdk,
  params: types.UpdateParams<
    client.InvestmentInput & client.GetOneInvestmentQuery["investment"]
  >
) {
  const id = Number(params.id);

  const { data } = params;
  const investmentTags =
    data?.investmentTags &&
    formatInvestmentTagsUpdateInput(data?.investmentTags);

  if (
    investmentTags &&
    investmentTags.some(
      (tag: client.TagsInput) => tag.tagClassId === 1 || tag.tagClassId === 18
    )
  ) {
    Sentry.withScope(scope => {
      scope.setExtra("params", data);
      Sentry.captureMessage("deprecated tagClassId used", "warning");
    });
  }

  const input: client.UpdateInvestmentMutationVariables["input"] = {
    assetClassEnumId: data?.assetClassEnumId,
    atlasInvestmentId: data?.atlasInvestmentId,
    targetRaise: numberUnlessNaN(data?.targetRaise),
    hardCap: numberUnlessNaN(data?.hardCap),
    committedCapital: numberUnlessNaN(data?.committedCapital),
    committedCapitalCurrencyId: data?.committedCapitalCurrencyId,
    createDate: data?.createDate,
    createUser: data?.createUser,
    firmId: data?.firm?.id,
    gpCommitted: numberUnlessNaN(data?.gpCommitted),
    gpCommittedCurrencyId: data?.gpCommittedCurrencyId,
    inceptionDate: data?.inceptionDate,
    investmentTags,
    impactTags: data?.impactTags,
    isImpact: data?.isImpact,
    isPartner: data?.isPartner,
    isSubAdvised: data?.isSubAdvised,
    accountTypeEnumId: data?.accountTypeEnumId,
    advisorTypeEnumId: data?.advisorTypeEnumId,
    advisorId: data?.advisorId,
    listEnumId: data?.listEnumId,
    shortName: data?.shortName,
    marketEnumId: data?.marketEnumId,
    maxExpectedVolatility: data?.maxExpectedVolatility,
    maxGrossExposure: data?.maxGrossExposure,
    maxNetExposure: data?.maxNetExposure,
    maxTargetNetIRR: data?.maxTargetNetIRR,
    maxTargetNetMOIC: data?.maxTargetNetMOIC,
    minExpectedVolatility: data?.minExpectedVolatility,
    minGrossExposure: data?.minGrossExposure,
    minNetExposure: data?.minNetExposure,
    minTargetNetIRR: data?.minTargetNetIRR,
    minTargetNetMOIC: data?.minTargetNetMOIC,
    modifyDate: data?.modifyDate,
    modifyUser: data?.modifyUser,
    name: data?.name,
    peLegalLifeYrs: data?.peLegalLifeYrs,
    peStrategyEnumId: data?.peStrategyEnumId,
    primaryAssignment: data?.primaryAssignment,
    secondaryAssignment: data?.secondaryAssignment,
    primaryBenchmarkId: data?.primaryBenchmarkId,
    primaryGeographicFocusId: data?.primaryGeographicFocusId,
    secondaryBenchmarkId: data?.secondaryBenchmarkId,
    strategyEnumId: data?.strategyEnumId,
    subStrategyEnumId: data?.subStrategyEnumId,
    summary: data?.summary,
    vehicleTypeEnumId: data?.vehicleTypeEnumId,
    vintageYear: data?.vintageYear,
    isWorkInProgress: data?.isWorkInProgress,
    domicileUSStateId: data?.domicileUSStateId,
    legalStructureEnumId: numberUnlessNaN(data?.legalStructureEnumId),
    investmentStructureEnumId: numberUnlessNaN(data?.investmentStructureEnumId),
    isRegisteredInvestmentCompany: data?.isRegisteredInvestmentCompany,
    organizationStructureEnumId: numberUnlessNaN(
      data?.organizationStructureEnumId
    ),
    NAVClassificationEnumId: numberUnlessNaN(data?.NAVClassificationEnumId),
    pricingFrequencyEnumId: numberUnlessNaN(data?.pricingFrequencyEnumId),
    domicileFundLinkId: numberUnlessNaN(data?.domicileFundLinkId),
    reportingLinkId: data?.reportingLinkId,
    isLiquidated: data?.isLiquidated,
    liquidationNote: data?.liquidationNote,
    liquidationDate: data?.liquidationDate,
    peFundNumberEnumId: data?.peFundNumberEnumId,
    isManagerUnwillingToProvidePerformance:
      data?.isManagerUnwillingToProvidePerformance,
    isManagerUnwillingToProvidePositions:
      data?.isManagerUnwillingToProvidePositions,
    isManagerUnwillingToProvideRisk: data?.isManagerUnwillingToProvideRisk,
    isManagerUnwillingToProvideAUM: data?.isManagerUnwillingToProvideAUM,
    ndaSigned: data?.ndaSigned,
    HFRIInvestmentId: data?.HFRIInvestmentId,
    evestmentId: data?.evestmentId,
    burgissId: data?.burgissId,
    RICId: data?.RICId,
    isOffshore: data?.isOffshore,
    domicileCountryId: data?.domicileCountryId,
    currencyDenominationEnumId: data?.currencyDenominationEnumId,
    reportingDate: data?.reportingDate,
    reportingDateReason: data?.reportingDateReason,
    bloombergCode: data.bloombergCode,
    bloombergField: data.bloombergField,
    indexCalcTypeEnumId: numberUnlessNaN(data.indexCalcTypeEnumId),
    performanceSourceEnumId: numberUnlessNaN(data.performanceSourceEnumId),
    documents: data?.documents,
  };

  await sdk.updateInvestment({
    id,
    input,
  });

  return await getOne(sdk, { id });
}

/*********************************************************************/
export const investment = types.dataProvider({
  getList,
  getOne,
  getMany,
  getManyReference,
  create,
  update,
});
