import * as client from "_graphql-types";
import { Button } from "ra-ui-materialui";
import { useState } from "react";
import {
  Edit,
  Pagination,
  ReferenceManyField,
  useRecordContext,
} from "react-admin";
import { Link } from "react-router-dom";
import { INVESTMENT_BUSINESS_OBJECT_ENUM } from "../../dataProvider/resources/document";
import { useCanAccessMutation } from "../../util/useCanAccessMutation";
import CustomBreadCrumb from "../CustomBreadCumb";
import {
  CustomDocumentFilterToolbar,
  DocumentToolbarFilters,
} from "../document/CustomDocumentFilterToolbar";
import { DocumentEditableDataGrid } from "../document/EditableDataGrid";

type Record = client.GetOneInvestmentQuery["investment"];

const Title = () => {
  const record = useRecordContext<Record>();

  const mutationArgs = JSON.stringify({
    input: {
      investmentId: record?.id,
    },
  });

  const { canEdit } = useCanAccessMutation("addDocument", mutationArgs);

  return (
    <span data-id="investment-documents-title">
      Investment Documents - {record ? `${record.name}   ` : "   "}
      {record && canEdit && (
        <>
          <Button
            label="Create Documents"
            component={Link}
            variant="contained"
            data-cy="createDocumentsMulti"
            to={{
              pathname: `/documentMulti/create`,
              search: `?source=${JSON.stringify({
                businessObjectEnumId: INVESTMENT_BUSINESS_OBJECT_ENUM,
                ...(record && {
                  investment: {
                    id: record.id,
                    name: encodeURIComponent(record.name),
                  },
                }),
              })}`,
            }}
          />
        </>
      )}
    </span>
  );
};

const InvestmentDocumentDataGrid = () => {
  const record = useRecordContext<Record>();
  if (!record) return;

  const [filters, setFilters] = useState<DocumentToolbarFilters>({
    isApproved: null,
    documentTypeEnumId: null,
    accessLevel: null,
    minDate: null,
  });

  return (
    <ReferenceManyField
      label="Investment Documents"
      reference="document"
      target="investmentId"
      pagination={<Pagination />}
      perPage={10}
      sort={{ field: "date", order: "DESC" }}
      filter={filters}
    >
      <CustomDocumentFilterToolbar filters={filters} setFilters={setFilters} />
      <DocumentEditableDataGrid
        referenceKey="investment_documents"
        noDelete={false}
        bulkActionButtons={false}
        defaultRowValues={{
          businessObjectEnumId: INVESTMENT_BUSINESS_OBJECT_ENUM,
          investmentId: record.id,
        }}
        entityOwner={{ investmentId: record.id }}
      />
    </ReferenceManyField>
  );
};

export const InvestmentDocumentShow = () => {
  return (
    <Edit
      title={<Title />}
      actions={
        <CustomBreadCrumb<Record>
          name="investmentDocument"
          items={[
            {
              path: "firm",
              getId: record => record?.firm?.id,
              getName: record => record?.firm?.name,
            },
            {
              path: "investment",
              getId: record => record?.id,
              getName: record => record?.name,
            },
          ]}
        />
      }
    >
      <InvestmentDocumentDataGrid />
    </Edit>
  );
};
