import {
  ReferenceInput,
  SelectInput,
  BooleanInput,
  required,
  NullableBooleanInput,
  TextInput,
} from "react-admin";
import { Grid } from "@mui/material";
import NumberInputWithSymbol from "../NumberWithInputAdornment";
import { useWatch } from "react-hook-form";

const validateRequired = [required()];

export function InvestmentFees({
  canEditField,
}: {
  canEditField: (key: string) => boolean;
}) {
  const [hurdle, feeStructureChanged, abnormalFees] = useWatch({
    name: [
      "investmentFees.hurdle",
      "investmentFees.feeStructureChanged",
      "investmentFees.abnormalFees",
    ],
  });

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={6} md={6} lg={2}>
          <NumberInputWithSymbol
            source="investmentFees.mgmtFee"
            label="Management Fee"
            symbol="%"
            disabled={!canEditField("investmentFees.mgmtFee")}
            validate={validateRequired}
          />
        </Grid>
        <Grid item xs={6} md={6} lg={2}>
          <ReferenceInput
            source="investmentFees.mgmtFeeCalcEnumId"
            reference="feeCalcEnum"
            sort={{ field: "id", order: "ASC" }}
          >
            <SelectInput
              label="Fee Base"
              optionText="name"
              disabled={!canEditField("investmentFees.mgmtFeeCalcEnumId")}
              validate={validateRequired}
            />
          </ReferenceInput>
        </Grid>
        <Grid item xs={6} md={6} lg={2}>
          <ReferenceInput
            source="investmentFees.mgmtFeeFreqEnumId"
            reference="feeFreqEnum"
            sort={{ field: "name", order: "ASC" }}
          >
            <SelectInput
              label="Payable Frequency"
              optionText="name"
              disabled={!canEditField("investmentFees.mgmtFeeFreqEnumId")}
            />
          </ReferenceInput>
        </Grid>
        <Grid item xs={6} md={6} lg={2}>
          <NumberInputWithSymbol
            source="investmentFees.incentiveFee"
            label="Incentive Fee"
            symbol="%"
            disabled={!canEditField("investmentFees.incentiveFee")}
            validate={validateRequired}
          />
        </Grid>
        <Grid item xs={6} md={6} lg={2}>
          <ReferenceInput
            source="investmentFees.incentiveFeeCalcEnumId"
            reference="feeCalcEnum"
            sort={{ field: "id", order: "ASC" }}
          >
            <SelectInput
              label="Fee Base"
              optionText="name"
              disabled={!canEditField("investmentFees.incentiveFeeCalcEnumId")}
              validate={validateRequired}
            />
          </ReferenceInput>
        </Grid>
        <Grid item xs={6} md={6} lg={2}>
          <ReferenceInput
            source="investmentFees.incentiveFeeFreqEnumId"
            reference="feeFreqEnum"
            sort={{ field: "name", order: "ASC" }}
          >
            <SelectInput
              label="Payable Frequency"
              optionText="name"
              disabled={!canEditField("investmentFees.incentiveFeeFreqEnumId")}
            />
          </ReferenceInput>
        </Grid>

        <Grid item xs={6} md={6} lg={2}>
          <NumberInputWithSymbol
            source="investmentFees.otherFee"
            label="Other Fees & Expenses"
            symbol="%"
            disabled={!canEditField("investmentFees.otherFee")}
            validate={validateRequired}
          />
        </Grid>
        <Grid item xs={6} md={6} lg={2}>
          <ReferenceInput
            source="investmentFees.otherFeeCalcEnumId"
            reference="feeCalcEnum"
            sort={{ field: "id", order: "ASC" }}
          >
            <SelectInput
              label="Fee Base"
              optionText="name"
              disabled={!canEditField("investmentFees.otherFeeCalcEnumId")}
              validate={validateRequired}
            />
          </ReferenceInput>
        </Grid>
        <Grid item xs={6} md={6} lg={2}>
          <ReferenceInput
            source="investmentFees.otherFeeFreqEnumId"
            reference="feeFreqEnum"
            sort={{ field: "name", order: "ASC" }}
          >
            <SelectInput
              label="Payable Frequency"
              optionText="name"
              disabled={!canEditField("investmentFees.otherFeeFreqEnumId")}
            />
          </ReferenceInput>
        </Grid>

        <Grid item xs={6} md={6} lg={3}>
          <NullableBooleanInput
            source="investmentFees.highWaterMark"
            label="High Water Mark?"
            disabled={!canEditField("investmentFees.highWaterMark")}
            fullWidth
          />
        </Grid>
        <Grid item xs={6} md={6} lg={3}>
          <NullableBooleanInput
            source="investmentFees.annualReset"
            label="Annual Reset?"
            disabled={!canEditField("investmentFees.annualReset")}
            fullWidth
          />
        </Grid>
        <Grid item xs={6} md={6} lg={3}>
          <NullableBooleanInput
            source="investmentFees.hurdle"
            label="Hurdle?"
            disabled={!canEditField("investmentFees.hurdle")}
            fullWidth
          />
        </Grid>
        <Grid item xs={6} md={6} lg={6}>
          <TextInput
            source="investmentFees.hurdleBenchmark"
            label="Hurdle Benchmark"
            disabled={
              !canEditField("investmentFees.hurdleBenchmark") || !hurdle
            }
            helperText="(Example: LIBOR + 100bp)"
          />
        </Grid>
        <Grid item xs={6} md={6} lg={3}>
          <BooleanInput
            source="investmentFees.standardShareClassRates"
            label="Standard Share Class Rates"
            disabled={!canEditField("investmentFees.standardShareClassRates")}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={2}>
          <BooleanInput
            source="investmentFees.feeStructureChanged"
            label="Fee Structure Changed"
            disabled={!canEditField("investmentFees.feeStructureChanged")}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={10}>
          <TextInput
            multiline
            rows={5}
            source="investmentFees.feeStructureChangedNotes"
            label="Describe the change in Fee Structure"
            disabled={
              !canEditField("investmentFees.feeStructureChanged") ||
              !feeStructureChanged
            }
          />
        </Grid>
        <Grid item xs={12} md={6} lg={2}>
          <BooleanInput
            source="investmentFees.abnormalFees"
            label="Abnormal Fees"
            disabled={!canEditField("investmentFees.abnormalFees")}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={10}>
          <TextInput
            multiline
            rows={5}
            source="investmentFees.abnormalFeesNotes"
            label="Describe the abnormal fees"
            disabled={
              !canEditField("investmentFees.abnormalFees") || !abnormalFees
            }
          />
        </Grid>
      </Grid>
    </>
  );
}
