import * as client from "_graphql-types";
import { validateYearFounded } from "./Edit";

import {
  ArrayInput,
  BooleanInput,
  CheckboxGroupInput,
  FormDataConsumer,
  NumberField,
  NumberInput,
  ReferenceArrayInput,
  ReferenceInput,
  SelectInput,
  SimpleFormIterator,
  TextInput,
  required,
} from "react-admin";
import { CustomCreate } from "../CustomCreate";
import { FirmNameInput } from "./FirmNameInput";

import Grid from "@mui/material/Grid";
import { AuthorizedTo } from "frontend/src/utils/types";
import { withScopedForm } from "../react-admin-fixes";

type Record = AuthorizedTo<NonNullable<client.GetOneFirmQuery["firm"]>, "id">;

const PrimaryAddressInput = () => (
  <Grid container spacing={1}>
    <Grid item xs={12}>
      <TextInput label="Line 1" source="primaryAddress.line1" />
    </Grid>
    <Grid item xs={12}>
      <TextInput label="Line 2" source="primaryAddress.line2" />
    </Grid>
    <Grid item xs={12}>
      <TextInput label="Line 3" source="primaryAddress.line3" />
    </Grid>
    <Grid item xs={4}>
      <TextInput label="City" source="primaryAddress.city" />
    </Grid>
    <Grid item xs={4}>
      <ReferenceInput
        reference="stateEnum"
        sort={{ field: "name", order: "ASC" }}
        perPage={100}
        source="primaryAddress.stateEnumId"
      >
        <SelectInput optionText="name" label="US State" />
      </ReferenceInput>
    </Grid>
    <Grid item xs={4}>
      <TextInput label="Province" source="primaryAddress.otherState" />
    </Grid>
    <Grid item xs={4}>
      <TextInput label="Zip Code" source="primaryAddress.zipCode" />
    </Grid>
    <Grid item xs={4}>
      <ReferenceInput
        reference="countryEnum"
        sort={{ field: "name", order: "ASC" }}
        perPage={1000}
        source="primaryAddress.countryEnumId"
      >
        <SelectInput optionText="name" label="Country" />
      </ReferenceInput>
    </Grid>
    <Grid item xs={4}></Grid>
    <Grid item xs={4}>
      <TextInput label="Phone Number" source="primaryAddress.phone" />
    </Grid>
    <Grid item xs={4}>
      <TextInput label="Phone Number 2" source="primaryAddress.phone2" />
    </Grid>
    <Grid item xs={4}></Grid>
    <Grid item xs={4}>
      <TextInput label="Fax Number" source="primaryAddress.fax" />
    </Grid>
    <Grid item xs={4}>
      <TextInput label="Fax Number 2" source="primaryAddress.fax2" />
    </Grid>
    <Grid item xs={4}></Grid>
  </Grid>
);

const OtherAddressesInput = () => (
  <ArrayInput source="otherAddresses" data-cy="otherAddresses">
    <SimpleFormIterator>
      <FormDataConsumer>
        {withScopedForm<NonNullable<Record["otherAddresses"]>[number]>(
          ({ scopedFormData }) => {
            return (
              <>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <TextInput label="Line 1" source="line1" />
                  </Grid>
                  <Grid item xs={12}>
                    <TextInput label="Line 2" source="line2" />
                  </Grid>
                  <Grid item xs={12}>
                    <TextInput label="Line 3" source="line3" />
                  </Grid>
                  <Grid item xs={4}>
                    <TextInput label="City" source="city" />
                  </Grid>
                  <Grid item xs={4}>
                    <ReferenceInput
                      reference="stateEnum"
                      sort={{ field: "name", order: "ASC" }}
                      perPage={100}
                      source="stateEnumId"
                    >
                      <SelectInput optionText="name" label="US State" />
                    </ReferenceInput>
                  </Grid>
                  <Grid item xs={4}>
                    <TextInput label="Province" source="otherState" />
                  </Grid>
                  <Grid item xs={4}>
                    <TextInput label="Zip Code" source="zipCode" />
                  </Grid>
                  <Grid item xs={4}>
                    <ReferenceInput
                      reference="countryEnum"
                      sort={{ field: "name", order: "ASC" }}
                      perPage={1000}
                      record={scopedFormData}
                      source="countryEnumId"
                    >
                      <SelectInput optionText="name" label="Country" />
                    </ReferenceInput>
                  </Grid>
                  <Grid item xs={4}></Grid>
                  <Grid item xs={4}>
                    <TextInput label="Phone Number" source="phone" />
                  </Grid>
                  <Grid item xs={4}>
                    <TextInput label="Phone Number 2" source="phone2" />
                  </Grid>
                  <Grid item xs={4}></Grid>
                  <Grid item xs={4}>
                    <TextInput label="Fax Number" source="fax" />
                  </Grid>
                  <Grid item xs={4}>
                    <TextInput label="Fax Number 2" source="fax2" />
                  </Grid>
                  <Grid item xs={4}></Grid>
                </Grid>
              </>
            );
          }
        )}
      </FormDataConsumer>
    </SimpleFormIterator>
  </ArrayInput>
);

const validateYearFoundedInput = [validateYearFounded, required()];

export const FirmCreate = () => {
  return (
    <CustomCreate<Record>>
      <Grid container spacing={1}>
        <Grid item xs={10}>
          <FirmNameInput isRequired={true} isDisabled={false} />
        </Grid>
        <Grid item xs={2}>
          <NumberInput
            label="Year Founded"
            source="yearFounded"
            validate={validateYearFoundedInput}
          />
        </Grid>

        <Grid item xs={6}>
          <NumberField
            label="Firm AUM"
            source="latestAum"
            options={{ style: "currency", currency: "USD" }}
          />
        </Grid>
        <Grid data-cy="registeredWithIds" item xs={10}>
          <ReferenceArrayInput
            label="Registered with"
            reference="agency"
            sort={{ field: "shortName", order: "ASC" }}
            perPage={1000}
            source="registeredWithIds"
          >
            <CheckboxGroupInput optionText="shortName" />
          </ReferenceArrayInput>
        </Grid>
        <Grid item xs={2}>
          <TextInput label="Other Registration" source="registeredWithOther" />
        </Grid>
        <Grid item xs={3}>
          <TextInput label="SEC CIK" source="cik" />
        </Grid>
        <Grid item xs={3}>
          <TextInput label="LEI" source="lei" />
        </Grid>
        <Grid item xs={3}>
          <TextInput label="CRD" source="crd" />
        </Grid>
        <Grid item xs={3}>
          <BooleanInput label="SBAI Signatory" source="ukHfsbSignatory" />
        </Grid>
        <Grid item xs={12}>
          <hr />
          <h3>Primary Address</h3>
        </Grid>
        <Grid item xs={12}>
          <PrimaryAddressInput />
        </Grid>
        <Grid item xs={12}>
          <hr />
          <h3>Other Addresses</h3>
        </Grid>
        <Grid item xs={12}>
          <OtherAddressesInput />
        </Grid>

        <Grid item xs={12}>
          <hr />
          <h3>Firm Employee Summary</h3>
        </Grid>
        <Grid item xs={1}>
          <NumberInput label="Total" source="employeeTotal" />
        </Grid>
        <Grid item xs={1}>
          <NumberInput label="Portfolio Mgr" source="portfolioManagerCount" />
        </Grid>
        <Grid item xs={1}>
          <NumberInput label="Investment" source="investmentCount" />
        </Grid>
        <Grid item xs={1}>
          <NumberInput label="Analyst" source="analystCount" />
        </Grid>
        <Grid item xs={1}>
          <NumberInput label="Trader" source="traderCount" />
        </Grid>
        <Grid item xs={1}>
          <NumberInput label="Operations" source="operationsCount" />
        </Grid>
        <Grid item xs={1}>
          <NumberInput label="Administrative" source="administrativeCount" />
        </Grid>
        <Grid item xs={1}>
          <NumberInput
            label="Investor Relations"
            source="investorRelationsCount"
          />
        </Grid>
        <Grid item xs={1}>
          <NumberInput label="Legal" source="legalCount" />
        </Grid>
        <Grid item xs={3}></Grid>

        <Grid item xs={12}>
          <hr />
          <h3>Website</h3>
        </Grid>
        <Grid item xs={6}>
          <TextInput label="URL" source="primaryAddress.website" />
        </Grid>
        <Grid item xs={3}>
          <TextInput label="User ID" source="primaryAddress.webUserId" />
        </Grid>
        <Grid item xs={3}>
          <TextInput label="Password" source="primaryAddress.webPassword" />
        </Grid>
        <Grid item xs={6}>
          <TextInput label="Firm Group IR Email" source="firmIrEmail" />
        </Grid>
        <Grid item xs={6}></Grid>
        <Grid item xs={12}>
          <TextInput
            label="Other Comments"
            multiline
            rows={5}
            source="comments"
          />
        </Grid>
      </Grid>
    </CustomCreate>
  );
};
