import TextField from "@mui/material/TextField";
import React from "react";
import { LatestNote } from "./Edit";
import { Grid, Typography, InputLabel } from "@mui/material";
import { format } from "date-fns";
import { useState } from "react";
import ReactQuill from "react-quill";

export default function Comments({
  comment,
  setComment,
  latestNoteRecord,
  canEdit,
}: {
  comment?: string;
  setComment: (text?: string) => void;
  latestNoteRecord: LatestNote | null;
  canEdit: boolean;
}) {
  const [commentaryFocus, changeCommentaryFocus] = useState(false);
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <InputLabel>Comments</InputLabel>
        <div
          style={{
            border: "1px solid grey",
            display: "flex",
            flexDirection: "column",
            justifyItems: "stretch",
            backgroundColor: "white",
            width: "100%",
          }}
          data-cy="comments"
          onFocus={e => changeCommentaryFocus(true)}
          onBlur={e => changeCommentaryFocus(false)}
        >
          {canEdit && (
            <div
              id={"toolbar"}
              style={{ border: "none", borderBottom: "1px solid grey" }}
            >
              <span className="ql-formats">
                <select className="ql-header">
                  <option value="1"></option>
                  <option value="2"></option>
                  <option selected></option>
                </select>
              </span>
              <span className="ql-formats">
                <button className="ql-bold"></button>
                <button className="ql-italic"></button>
                <button className="ql-underline"></button>
              </span>
              <span className="ql-formats">
                <select className="ql-color"></select>
                <select className="ql-background"></select>
              </span>
              <span className="ql-formats">
                <button className="ql-list" value="ordered"></button>
                <button className="ql-list" value="bullet"></button>
                <button className="ql-indent" value="-1"></button>
                <button className="ql-indent" value="+1"></button>
              </span>
              <span className="ql-formats">
                <button className="ql-clean"></button>
              </span>
            </div>
          )}
          <ReactQuill
            theme={canEdit ? "snow" : "bubble"}
            modules={
              canEdit
                ? {
                    toolbar: "#toolbar",
                  }
                : {}
            }
            value={comment ?? ""}
            readOnly={!canEdit}
            onChange={e => {
              setComment(e);
            }}
            style={{
              overflow: "auto",
              lineHeight: "1.6em",
              minHeight: "3.2em",
              maxHeight: commentaryFocus ? "16em" : "8em",
            }}
          />
        </div>
      </Grid>
      {!!latestNoteRecord && (
        <Grid item xs={12}>
          <Typography variant="caption" color="textSecondary">
            <span>
              Modified by {latestNoteRecord.createUser} on{" "}
              {latestNoteRecord.createDate &&
                format(new Date(latestNoteRecord.createDate), "MM/dd/yyyy")}
            </span>
          </Typography>
        </Grid>
      )}
    </Grid>
  );
}
