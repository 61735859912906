import { CustomEdit } from "../CustomEdit";
import { Form, Record } from "./Form";

export const InstitutionEmploymentEdit = () => {
  return (
    <CustomEdit<Record> title={() => `Institution Employment`}>
      <Form />
    </CustomEdit>
  );
};
