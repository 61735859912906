import { Grid } from "@mui/material";
import * as client from "_graphql-types";
import { DateInput, NumberInput } from "react-admin";
import { CustomEdit } from "../CustomEdit";

type Record = NonNullable<
  client.GetOneCompanyValuationQuery["companyValuation"]
>;

export const CompanyValuationEdit = () => {
  return (
    <CustomEdit<Record>
      title={record => record && `companyValuation ${record.id || ""}`}
      customFormProps={{
        customToolbarProps: {
          allowDelete: true,
        },
      }}
    >
      <Grid container spacing={2}>
        <NumberInput label="Valuation" source="valuation" />
        <DateInput label="As Of Date" source="asOfDate" />
      </Grid>
    </CustomEdit>
  );
};
