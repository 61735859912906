import * as client from "_graphql-types";
import { filterSortPage } from "../DataProvider";
import * as types from "./types";

type Sdk = ReturnType<typeof client.getSdk>;

export async function getList(
  sdk: Sdk,
  params: types.GetListParams<client.PortalSubmittedSortEnum>
) {
  const {
    pagination,
    sort,
    filter: { shouldUpdate, ...restOfFilter },
  } = params;
  const {
    portalSubmittedList: { items: data, total },
  } = await sdk.getListPortalSubmitted(
    filterSortPage({
      filter: {
        ...(shouldUpdate && { shouldUpdate: true }),
        ...restOfFilter,
      },
      sort,
      pagination,
    })
  );

  return {
    data,
    total,
  };
}

async function getOne(sdk: Sdk, params: types.GetOneParams) {
  const id = String(params.id);
  const { portalSubmitted: data } = await sdk.getOnePortalSubmitted({
    id,
  });
  return {
    data,
  };
}

async function update(
  sdk: Sdk,
  params: types.UpdateParams<
    NonNullable<client.GetOnePortalSubmittedQuery["portalSubmitted"]>
  >
): types.UpdateOut<any> {
  const id = String(params.id);
  const { data } = params;
  const input: client.PortalSubmittedInput = {
    // shouldUpdate is false if no rcgFundId selected
    shouldUpdate: data.shouldUpdate,
    rcgFundId: data.rcgFundId,
    firmId: data.firmId || data.firm?.id,
  };

  await sdk.updatePortalSubmitted({
    id,
    input,
  });

  return await getOne(sdk, { id });
}

async function softDelete(sdk: Sdk, params: types.DeleteParams) {
  const id = String(params.id);

  await sdk.deletePortalSubmitted({ id });

  return {
    data: { id },
  };
}

export const portalSubmitted = types.dataProvider({
  getOne,
  getList,
  update,
  delete: softDelete,
});
