import { Filter, ReferenceInput, AutocompleteInput } from "react-admin";

import React from "react";

export const InstitutionEmploymentFilter = () => (
  <Filter>
    <ReferenceInput
      source="personId"
      alwaysOn
      reference="person"
      sort={{ field: "nameSearchRank", order: "ASC" }}
    >
      <AutocompleteInput optionText="fullName" label="Person" fullWidth />
    </ReferenceInput>
    <ReferenceInput
      source="institutionId"
      reference="officeLocation"
      alwaysOn
      sort={{ field: "nameSearchRank", order: "ASC" }}
    >
      <AutocompleteInput optionText="name" label="Office Location" fullWidth />
    </ReferenceInput>
  </Filter>
);
