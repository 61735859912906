import * as client from "_graphql-types";

import { TextInput, SimpleFormIterator, required, regex } from "react-admin";
import { CustomCreate } from "../CustomCreate";
import Grid from "@mui/material/Grid";

import { useCanAccessMutation } from "../../util/useCanAccessMutation";
import { ReferenceManyInput } from "@react-admin/ra-relationships";
import { VALID_WEBSITE_REGEX } from "frontend/src/utils/constants";

type Record = NonNullable<
  client.GetOneFinancialDataProviderQuery["financialDataProvider"]
>;

const validateRequired = [required()];
const validateRequiredValidWebsite = [
  required(),
  regex(VALID_WEBSITE_REGEX, "Please enter a valid URL"),
];

export const FinancialDataProviderCreate = () => {
  const mutationArgs = JSON.stringify({});

  const { canEdit, canEditField, loading } = useCanAccessMutation(
    "addFinancialDataProvider",
    mutationArgs
  );

  function _canEditField(key: string) {
    return !!(canEdit && canEditField[key]);
  }

  return (
    <CustomCreate
      title={() => "Create FDP"}
      customFormProps={{
        customToolbarProps: {
          canAccessMutation: canEdit,
        },
        loading,
        canEditField,
      }}
    >
      <>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextInput
              source="name"
              validate={validateRequired}
              disabled={!_canEditField("name")}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <ReferenceManyInput
              reference="fdpWebsites"
              target="financialDataProviderId"
              label="Websites"
              defaultValue={[{ website: "" }]}
            >
              <SimpleFormIterator
                fullWidth
                inline
                sx={{
                  "& .RaSimpleFormIterator-line": {
                    borderBottom: "none",
                  },
                }}
              >
                <TextInput
                  label=""
                  source="website"
                  validate={validateRequiredValidWebsite}
                  disabled={!_canEditField("fdpWebsites")}
                />
              </SimpleFormIterator>
            </ReferenceManyInput>
          </Grid>
        </Grid>
      </>
    </CustomCreate>
  );
};
