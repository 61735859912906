import * as client from "_graphql-types";
import {
  BooleanInput,
  NumberInput,
  SimpleFormIterator,
  FormDataConsumer,
  required,
  ArrayInput,
  ReferenceInput,
  SelectInput,
  NullableBooleanInput,
} from "react-admin";
import { Grid, Button as MUIButton } from "@mui/material";
import { withScopedForm } from "../react-admin-fixes";
import { validateRequiredArrayFields } from "../investment/util";

type IRecord = NonNullable<
  NonNullable<client.GetOneShareClassQuery["shareClass"]>["softLockups"]
>[number];

const validateRequired = [required(), validateRequiredArrayFields<IRecord>];

const fixStyling = {
  "& .RaSimpleFormIterator-list>li": {
    marginTop: "1em",
  },
  "& .RaSimpleFormIterator-list>li:first-of-type": {
    marginTop: 0,
  },
  "& .RaSimpleFormIterator-buttons": {
    marginBottom: "3em",
  },
};

export function SoftLockupForm({
  canEditField: _canEditField,
}: {
  canEditField: (key: string) => boolean;
}) {
  function canEditField(field: string) {
    return _canEditField(`softLockups.${field}`);
  }

  return (
    <ArrayInput source="softLockups" label={false} sx={{ marginBottom: "1em" }}>
      <SimpleFormIterator
        disableReordering
        disableClear
        addButton={<MUIButton>Add SoftLockup</MUIButton>}
        fullWidth
        sx={fixStyling}
      >
        <FormDataConsumer>
          {withScopedForm<IRecord>(() => (
            <Grid container spacing={1}>
              <Grid item xs={6} sm={6} lg={2}>
                <NumberInput
                  source="termBeginMonth"
                  label="Term Begin (Month)"
                  fullWidth
                  disabled={!canEditField("termBeginMonth")}
                  validate={validateRequired}
                />
              </Grid>
              <Grid item xs={6} sm={6} lg={2}>
                <NumberInput
                  source="termEndMonth"
                  label="Term End (Month)"
                  fullWidth
                  disabled={!canEditField("termEndMonth")}
                  validate={validateRequired}
                />
              </Grid>

              <Grid item xs={6} sm={6} lg={2}>
                <NumberInput
                  source="redemptionFee"
                  label="Fee"
                  fullWidth
                  disabled={!canEditField("redemptionFee")}
                  validate={validateRequired}
                />
              </Grid>
              <Grid item xs={6} sm={6} lg={2}>
                <ReferenceInput
                  source="lockupEndRedemptionOnId"
                  reference="financialPeriodEnum"
                >
                  <SelectInput
                    label="First Redemption On"
                    fullWidth
                    optionText="name"
                    disabled={!canEditField("lockupEndRedemptionOnId")}
                  />
                </ReferenceInput>
              </Grid>
              <Grid item xs={6} sm={6} lg={2}>
                <ReferenceInput
                  source="secondLockupEndRedemptionOnId"
                  reference="financialPeriodEnum"
                >
                  <SelectInput
                    label="Subsequent Redemption On"
                    fullWidth
                    optionText="name"
                    disabled={!canEditField("secondLockupEndRedemptionOnId")}
                  />
                </ReferenceInput>
              </Grid>
              <Grid item xs={6} sm={6} lg={2}>
                <BooleanInput
                  source="redemptionOnBizDays"
                  label="Last Business Day"
                  fullWidth
                  disabled={!canEditField("redemptionOnBizDays")}
                />
              </Grid>
              <Grid item xs={6} sm={6} lg={2}>
                <NumberInput
                  source="liquidityValue"
                  label="Liquidity Value"
                  fullWidth
                  disabled={!canEditField("liquidityValue")}
                />
              </Grid>
              <Grid item xs={6} sm={6} lg={2}>
                <NumberInput
                  source="liquidityUnitId"
                  label="Liquidity Unit"
                  fullWidth
                  disabled={!canEditField("liquidityUnitId")}
                />
              </Grid>
              <Grid item xs={6} sm={6} lg={2}>
                <NumberInput
                  source="noticeValue"
                  label="Notice (Days)"
                  fullWidth
                  disabled={!canEditField("noticeValue")}
                />
              </Grid>
              <Grid item xs={6} sm={6} lg={2}>
                <NullableBooleanInput
                  source="noticeBizDays"
                  label="Business Days (Notice)"
                  fullWidth
                  disabled={!canEditField("noticeBizDays")}
                  trueLabel="Business Days"
                  falseLabel="Calendar Days"
                />
              </Grid>
              <Grid item xs={6} sm={6} lg={2}>
                <NumberInput
                  source="cashAvailableDays"
                  label="Cash Availibility (Days)"
                  fullWidth
                  disabled={!canEditField("cashAvailableDays")}
                />
              </Grid>
              <Grid item xs={6} sm={6} lg={2}>
                <NumberInput
                  source="holdBackPercent"
                  label="HoldBack (%)"
                  fullWidth
                  disabled={!canEditField("holdBackPercent")}
                />
              </Grid>
            </Grid>
          ))}
        </FormDataConsumer>
      </SimpleFormIterator>
    </ArrayInput>
  );
}
