import {
  addDays,
  endOfMonth,
  format,
  getMonth,
  getYear,
  isFriday,
  lastDayOfMonth,
  parse,
  startOfMonth,
  subDays,
} from "date-fns";
import XLSX from "xlsx";
import { MtdRec, QtdRec } from "./Edit";
const maxReturnListLength = 500;

export type Rec = MtdRec | QtdRec;

const firstFridayOfMonth = (date: Date): string => {
  const start = startOfMonth(date);
  let i = 0;
  while (true) {
    if (isFriday(addDays(start, i))) {
      return format(addDays(start, i), "yyyy-MM-dd");
    }
    i++;
  }
};

const mostRecentFriday = (date: Date): string => {
  const current = new Date(Date.now());
  let i = 0;
  while (true && getMonth(current) === getMonth(subDays(current, i))) {
    if (isFriday(subDays(current, i))) {
      return format(subDays(current, i), "yyyy-MM-dd");
    }
    i++;
  }
  return format(current, "yyyy-MM-dd");
};

export const getDefaultAsOf = (date: Date): string => {
  if (new Date(Date.now()) < date) {
    return firstFridayOfMonth(date);
  }
  if (
    getMonth(new Date(Date.now())) === getMonth(date) &&
    getYear(new Date(Date.now())) === getYear(date)
  ) {
    return mostRecentFriday(date);
  }
  if (new Date(Date.now()) > date) {
    return format(lastDayOfMonth(date), "yyyy-MM-dd");
  }
  return "";
};

export const yearToDate = (fundReturns: number[]) =>
  (fundReturns.reduce((acc, fundReturn) => acc * (1 + fundReturn / 100), 1) -
    1) *
  100;

export const findReturnLabel = (data: any[]): { i: number; j: number } => {
  for (let i = 0; i < data.length; i++) {
    for (let j = 0; j < data.length; j++) {
      if (
        typeof data[i][j] === "string" &&
        ["returns", "month"].includes(data[i][j].toLowerCase())
      ) {
        console.log(data[i][j]);
        return { i, j };
      }
    }
  }

  throw Error("No Return Label Found");
};

const parseReturnColumn = (
  i: number,
  j: number,
  data: any[][],
  currencyId: number
) => {
  const newRecords: Record<string, MtdRec> = {};
  for (let k = 1; k <= maxReturnListLength; k++) {
    if (!data[i + k] || !data[i + k][j] || k === maxReturnListLength) {
      return newRecords;
    }

    // requested that spreadsheet upload uses end of month dates. RCS-5377

    const asOfDate = endOfMonth(new Date(data[i + k][j]));
    const returnYear = asOfDate.getFullYear(),
      returnMonth = asOfDate.getMonth() + 1;
    const key = String([returnYear, returnMonth]);
    if (key in newRecords) {
      throw new Error(
        `Multiple rows with same date ${format(asOfDate, "MMM yyyy")}`
      );
    }

    newRecords[key] = {
      returnYear,
      returnMonth,
      return: Math.round(data[i + k][j + 1] * 1000000) / 1000000,
      currencyId,
      asOfDate: format(asOfDate, "yyyy-MM-dd"),
      createUser: "",
      createDate: new Date().toISOString(),
    };
  }
  return newRecords;
};

export const parseSpreadsheet = (
  file: File,
  currencyId: number
): Promise<Record<string, MtdRec>> => {
  return new Promise((res, rej) => {
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;
    reader.onload = e => {
      try {
        const bstr = e.target!.result;
        const wb = XLSX.read(bstr, {
          type: rABS ? "binary" : "array",
          cellDates: true,
        });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];

        const data: any[] = XLSX.utils.sheet_to_json(ws, {
          header: 1,
        });

        const { i, j } = findReturnLabel(data);

        const formattedData = parseReturnColumn(i, j, data, currencyId);

        res(formattedData);
      } catch (e) {
        rej(e);
      }
    };
    if (rABS) reader.readAsBinaryString(file);
    else reader.readAsArrayBuffer(file);
  });
};

export const onlyDistinctRecords = <T extends Rec>(
  newRecords: Record<string, T>,
  oldRecords: Record<string, T>
) =>
  Object.fromEntries(
    Object.entries(newRecords).filter(([key, newRecord]) => {
      const oldRecord = oldRecords[key];
      return !(
        oldRecord &&
        oldRecord.return === newRecord.return &&
        "asOfDate" in oldRecord &&
        "asOfDate" in newRecord &&
        oldRecord.asOfDate?.split("T", 2)[0] === newRecord.asOfDate
      );
    })
  );

export const parsePasteArea = (
  text: string,
  currencyId: number
): Record<string, MtdRec> => {
  const recs = text
    .split("\n")
    .map(row => row.toLowerCase())
    .filter(row => !row.includes("return")) //exclude rows that have the word return
    .filter(row => row.replace(/\s/g, "")) // just ignore rows that are just whitespace
    .map(row => row.match(/\S+/g)) // split each row by whitespace, return array []
    .map(row => {
      if (!row || row.length < 2) {
        throw Error("Incorrect Formatting");
      }
      let returnValue = row[1].replace("%", "");

      // if surrounded by parenthesis, it's a negative number
      if (returnValue[0] === "(" && returnValue[returnValue.length - 1] === ")")
        returnValue = returnValue.replace("(", "-").replace(")", "");

      //handle multiple formats of dates

      let asOfDate = new Date(row[0]);

      const record: MtdRec = {
        returnYear: asOfDate.getFullYear(),
        returnMonth: asOfDate.getMonth() + 1,
        asOfDate: format(asOfDate, "yyyy-MM-dd"),
        return:
          returnValue === "blank"
            ? null
            : Math.round(Number(returnValue) * 1000000) / 1000000,
        currencyId,
        createUser: "",
        createDate: new Date().toISOString(),
      };
      if (
        !record.returnYear ||
        !record.returnMonth ||
        !record.asOfDate ||
        !(
          record.return === null ||
          (typeof record.return === "number" && isFinite(record.return))
        )
      ) {
        throw Error(`Could not parse record ${row}`);
      }
      return record;
    });
  const newRecords: Record<string, MtdRec> = {};
  for (const rec of recs) {
    const key = String([rec.returnYear, rec.returnMonth]);
    if (key in newRecords) {
      throw Error(
        `Multiple rows with same date ${format(
          new Date(rec.returnYear, rec.returnMonth! - 1),
          "MMM yyyy"
        )}`
      );
    }
    newRecords[key] = rec;
  }
  return newRecords;
};
