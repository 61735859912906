import { TextField, Autocomplete } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { invert, startCase } from "lodash";
import { useEffect, useState } from "react";
import { useDataProvider, useInput, useRecordContext } from "react-admin";
import { useWatch } from "react-hook-form";
import { GetOneInvestmentQuery } from "_graphql-types";
import { tagClassMapping } from "../dataProvider/helpers/tagClasses";

const mapping = invert(tagClassMapping);

export type Record = NonNullable<GetOneInvestmentQuery["investment"]>;
type Tag = NonNullable<Record["investmentTags"]>[number]["tag"];

function formatSelections(selectedTags?: Record["investmentTags"]): Tag[] {
  if (!selectedTags) {
    return [];
  }
  return selectedTags.map(investmentTag => investmentTag.tag);
}

interface Props {
  record?: any;
  reference?: string;
  resource?: string;
  sort?: {
    field: string;
    order: string;
  };
  readOnly?: boolean;
  source: string;
  label: string;
}

const CustomInvestmentTagsInput = (props: Props) => {
  const record = useRecordContext<Record>();
  const formData = useWatch();

  const tagClassList = Object.values(tagClassMapping).filter(
    id => id !== tagClassMapping.realEstateFocus
  );

  const dataProvider = useDataProvider();
  const [selectedTags, setSelectedTags] = useState<Tag[]>(
    formatSelections(record?.investmentTags ? record.investmentTags : undefined)
  );

  const [choices, setChoices] = useState<Tag[]>();

  const input = useInput({ source: props.source });
  const {
    field: { onChange },
  } = input;

  useEffect(() => {
    async function fetchChoices() {
      const choices = await dataProvider.getList("tag", {
        filter: {
          tagClassIds: Object.values(tagClassMapping),
        },
        sort: {
          field: "name",
          order: "ASC",
        },
        pagination: {
          page: 1,
          perPage: 25,
        },
      });
      setChoices(choices.data as Tag[]);
    }

    fetchChoices();
  }, [dataProvider]);

  function handleChangeMultiple(value: Tag[]) {
    if (!value || !value.length) return;
    const otherClassTags = selectedTags.filter(
      tag => tag.tagClassId !== value[0].tagClassId
    );
    const newTags = [...otherClassTags, ...value];
    setSelectedTags(newTags);
    onChange(newTags);
  }

  function handleChangeSingle(value: Tag) {
    if (!value) return;

    const existingTagClassIndex = selectedTags.findIndex(
      tag => tag.tagClassId === value.tagClassId
    );
    if (existingTagClassIndex >= 0) {
      const newTags = selectedTags;
      newTags[existingTagClassIndex] = value;
      setSelectedTags(newTags);
      onChange(newTags);
    } else {
      const newTags = [...selectedTags, value];
      setSelectedTags(newTags);
      onChange(newTags);
    }
  }

  // typescript overloads list for handleChange function
  function handleChange(
    value: Tag[],
    reason: string,
    tagClassId?: number
  ): void;
  function handleChange(value: Tag, reason: string, tagClassId?: number): void;
  function handleChange(value: any, reason: string, tagClassId?: number) {
    if (reason === "clear") {
      const otherClassTags = selectedTags.filter(
        tag => tag.tagClassId !== tagClassId
      );
      setSelectedTags(otherClassTags);
      onChange(otherClassTags);
    } else {
      if (value.hasOwnProperty("length")) {
        handleChangeMultiple(value);
      } else {
        handleChangeSingle(value);
      }
    }
  }

  return (
    <Box style={{ marginBottom: 20 }}>
      <Grid container spacing={2}>
        {choices &&
          tagClassList.map(tagClassId => {
            return (
              <Grid key={tagClassId} item xs={8} sm={6} lg={3}>
                <Autocomplete
                  fullWidth
                  multiple
                  id={`field${tagClassId}`}
                  readOnly={props.readOnly}
                  options={choices?.filter(
                    choice => choice.tagClassId === tagClassId
                  )}
                  getOptionLabel={option =>
                    option.name ?? "(missing option.name)"
                  }
                  value={selectedTags.filter(
                    tag => tag.tagClassId === tagClassId
                  )}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  filterSelectedOptions
                  autoComplete={false}
                  renderInput={params => (
                    <TextField
                      {...params}
                      name={mapping[tagClassId]}
                      label={startCase(mapping[tagClassId])}
                      variant="outlined"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new",
                        readOnly: props.readOnly,
                      }}
                    />
                  )}
                  onChange={(event: any, value: any, reason: any) => {
                    handleChange(value, reason, tagClassId);
                  }}
                />
              </Grid>
            );
          })}
        {choices &&
          formData.assetClassEnumId === 4 &&
          [19].map(tagClassId => {
            return (
              <Grid key={tagClassId} item xs={8} sm={6} lg={3}>
                <Autocomplete
                  multiple
                  id={`field${tagClassId}`}
                  options={choices?.filter(
                    choice => choice.tagClassId === tagClassId
                  )}
                  getOptionLabel={option =>
                    option.name ?? "(missing option.name)"
                  }
                  value={selectedTags.filter(
                    tag => tag.tagClassId === tagClassId
                  )}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  filterSelectedOptions
                  autoComplete={false}
                  renderInput={params => (
                    <TextField
                      {...params}
                      name={mapping[tagClassId]}
                      label={startCase(mapping[tagClassId])}
                      variant="outlined"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new",
                      }}
                    />
                  )}
                  onChange={(event: any, value: any, reason: any) => {
                    handleChange(value, reason, tagClassId);
                  }}
                />
              </Grid>
            );
          })}
      </Grid>
    </Box>
  );
};

export default CustomInvestmentTagsInput;
