import * as client from "_graphql-types";
import { filterSortPage } from "../DataProvider";
import * as types from "./types";

type Sdk = ReturnType<typeof client.getSdk>;

/*********************************************************************/
// Data Provider Functions
/*********************************************************************/

/*********************************************************************/
export async function getList(
  sdk: Sdk,
  params: types.GetListParams<client.PortalUserSortEnum>
) {
  const { pagination, sort, filter } = params;

  const {
    portalUserList: { items: data, total },
  } = await sdk.getListPortalUser(filterSortPage({ filter, sort, pagination }));

  return {
    data,
    total,
  };
}

/*********************************************************************/
async function getManyReference(
  sdk: Sdk,
  params: types.GetManyReferenceParams<client.PortalUserSortEnum>
) {
  const id = String(params.id);
  const {
    pagination: { page, perPage },
    sort: { field, order },
    filter,
    target,
  } = params;

  const {
    portalUserList: { items: data, total },
  } = await sdk.getListPortalUser({
    page: {
      offset: (page - 1) * perPage,
      limit: perPage,
    },
    sort: [{ field, order }],
    filter: { ...filter, [target]: id },
  });

  return {
    data,
    total,
  };
}

/*********************************************************************/
async function getOne(sdk: Sdk, params: types.GetOneParams) {
  const id = String(params.id);
  const { portalUser: data } = await sdk.getOnePortalUser({
    id,
  });
  return {
    data,
  };
}

/*********************************************************************/
async function getMany(sdk: Sdk, params: types.GetManyParams) {
  const ids = params.ids.map(id => String(id));

  const { portalUserMany: data } = await sdk.getManyPortalUser({
    ids,
  });

  return {
    data,
  };
}

/*********************************************************************/
async function update(
  sdk: Sdk,
  params: types.UpdateParams<
    NonNullable<client.GetOnePortalUserQuery["portalUser"]>
  >
): types.UpdateOut<any> {
  const id = String(params.id);

  const { data } = params;
  const input: client.UpdatePortalUserMutationVariables["input"] = {
    organizationId: data.organizationId,
  };

  await sdk.updatePortalUser({
    id,
    input,
  });

  return await getOne(sdk, { id });
}

/*********************************************************************/
async function softDelete(
  sdk: Sdk,
  params: types.DeleteParams
): types.DeleteOut<any> {
  const id = String(params.id);

  await sdk.deletePortalUser({ id });

  return { data: { id } };
}

/*********************************************************************/
export const portalUser = types.dataProvider({
  getList,
  getOne,
  getMany,
  getManyReference,
  update,
  delete: softDelete,
});
