import * as client from "_graphql-types";
import {
  BooleanField,
  CreateButton,
  Datagrid,
  ExportButton,
  FunctionField,
  List,
  SimpleShowLayout,
  SortButton,
  TextField,
  TopToolbar,
} from "react-admin";

import { Chip, Tooltip, Typography } from "@mui/material";
import TruncatedTextField from "../TruncatedTextField";
import { DealFilter } from "./Filter";
import { DealFilterSidebar } from "./FilterSidebar";

type Record = NonNullable<client.GetOneDealQuery["deal"]>;

const SummaryPanel = (props: any) => (
  <SimpleShowLayout {...props} sx={{ padding: 0 }}>
    <Typography variant="overline">Summary</Typography>
    <TruncatedTextField<Record> source="summary" maxChars={290} />
  </SimpleShowLayout>
);

export const DealList = () => (
  <List
    sx={{
      ".RaFilter-form": {
        flex: "0 1 50%",
      },
    }}
    filters={<DealFilter />}
    aside={<DealFilterSidebar />}
    sort={{ field: "expectedCloseDate", order: "DESC" }}
    filterDefaultValues={{ isActive: true, isBusinessApplication: false }}
    actions={
      <TopToolbar>
        <SortButton
          fields={[
            "nameSearchRank",
            "name",
            "companyName",
            "assetClassEnumId",
            "fundingRoundEnumId",
            "rcSource",
            "dealStructureEnumId",
            "source",
            "headquartersEnumId",
            "stageEnumDefault",
            "dealMarketingEnumId",
            "isImpact",
          ]}
        />
        <CreateButton />
        <ExportButton maxResults={250} />
      </TopToolbar>
    }
  >
    <Datagrid
      rowClick="edit"
      data-id="deals"
      size="small"
      bulkActionButtons={false}
      expand={<SummaryPanel />}
      isRowExpandable={row => row && row.summary}
    >
      <TextField label="Close Date" source="expectedCloseDate" />
      <TextField label="Name" source="name" />
      <TextField label="Company" source="company.name" sortBy="companyName" />
      <FunctionField
        label="Industry"
        source="company.firmAttributes.firmTags"
        sortable={false}
        render={(record: Record) => {
          const industryTags = (
            record as Record
          )?.company?.firmAttributes.firmTags?.filter(
            firmTag => firmTag && firmTag.tag.tagClassId === 22
          );
          if (!industryTags || !industryTags.length) return "";

          let tags = industryTags
            .slice(0, 2)
            .map(firmTag => (
              <Chip
                key={firmTag.tagId ?? "more"}
                size="small"
                label={firmTag.tag.name}
                sx={{ margin: "1px" }}
              />
            ));

          if (industryTags.length > 2) {
            tags.push(
              <Tooltip
                placement="right"
                title={industryTags
                  .slice(2, industryTags.length)
                  .map(firmTag => firmTag.tag.name)
                  .join(", ")}
              >
                <Chip
                  key="more"
                  size="small"
                  label="..."
                  sx={{ margin: "1px" }}
                />
              </Tooltip>
            );
          }

          return tags;
        }}
      />
      <TextField
        label="Asset Class"
        source="assetClass.name"
        sortBy="assetClassEnumId"
      />
      <TextField
        label="Funding Round"
        source="fundingRound.name"
        sortBy="fundingRoundEnumId"
      />
      <TextField
        label="RC Source"
        source="rcSourceUser.commonName"
        sortBy="rcSource"
      />
      <TextField
        label="Deal Structure"
        source="dealStructure.name"
        sortBy="dealStructureEnumId"
      />
      <TextField label="Source" source="source" />
      <TextField
        label="Headquarters"
        source="headquarters.name"
        sortBy="headquartersEnumId"
      />
      <TextField label="Co-Investors" source="coInvestors" sortable={false} />
      <TextField label="Stage" source="stage.name" sortBy="stageEnumDefault" />
      <TextField label="Result" source="result.name" sortBy="resultEnumId" />
      <TextField
        label="Deal Marketing"
        source="dealMarketing.name"
        sortBy="dealMarketingEnumId"
      />
      <BooleanField label="Impact" source="isImpact" />
    </Datagrid>
  </List>
);
