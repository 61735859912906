import HistoryIcon from "@mui/icons-material/History";
import { Button } from "@mui/material";
import { ProfileBuilderQuery } from "frontend/src/graphql-types/graphql";
import { ensureDefined } from "frontend/src/utils/helpers";
import { useEffect, useMemo, useState } from "react";
import {
  FieldValues,
  UseFormReset,
  UseFormSetValue,
  UseFormTrigger,
} from "react-hook-form";
import { ProfileBuilderButton, TempFile } from "./ProfileBuilderButton";
import { TFormHistory } from "./TrackChanges";
import { mapToFieldValues } from "./utils";

interface BuildInvestmentProfileProps {
  setTempFiles: React.Dispatch<React.SetStateAction<TempFile[] | undefined>>;
  tempFiles: TempFile[] | undefined;
  reset: UseFormReset<FieldValues>;
  trigger: UseFormTrigger<FieldValues>;
  setAutoPopulatedFields: React.Dispatch<React.SetStateAction<string[]>>;
  setValue: UseFormSetValue<FieldValues>;
  setFieldHistory?: (values: TFormHistory) => void;
  fieldHistory?: FieldValues;
  isUpdate?: boolean;
  setOpenTrackChanges?: (b: boolean) => void;
  openTrackChanges?: boolean;
  autoPopulatedFields?: string[];
}

const sleep = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

export function BuildInvestmentProfile({
  tempFiles,
  setTempFiles,
  reset,
  trigger,
  setValue,
  setAutoPopulatedFields,
  setFieldHistory,
  fieldHistory,
  isUpdate,
  setOpenTrackChanges,
  openTrackChanges,
  autoPopulatedFields,
}: BuildInvestmentProfileProps) {
  const [profileBuilderResponse, setProfileBuilderResponse] = useState<
    ProfileBuilderQuery["investmentProfileBuilder"] | undefined
  >();

  useEffect(() => {
    async function populateFormFields() {
      if (profileBuilderResponse?.investmentProfile) {
        const valuesForReset = await mapToFieldValues({
          draftInvestment: profileBuilderResponse.investmentProfile,
          ...(isUpdate && {
            skipList: ["firm", "name"],
            fieldHistory,
          }),
        });
        console.log(">>> valuesForReset", valuesForReset);
        const autoPopulatedFields = Object.keys(valuesForReset);
        console.log(">>> setAutoPopulatedFields", autoPopulatedFields);
        setAutoPopulatedFields(autoPopulatedFields);
        console.log(">>> reset", valuesForReset);
        reset(valuesForReset);
        const { vehicleTypeEnumId, assetClassEnumId, strategyEnumId } =
          valuesForReset;
        /* dependent fields not behaving consistently with reset (assetclass and strategy often don't get set) */
        /* should still be faster than using setValue for everything but needs improvement  */

        if (vehicleTypeEnumId) {
          console.log(">>> setValue vehicleTypeEnumId", vehicleTypeEnumId);
          setValue("vehicleTypeEnumId", vehicleTypeEnumId);
          console.log(">>> trigger vehicleTypeEnumId");
          await trigger("vehicleTypeEnumId");

          // The problem is that we need to wait for the dependent values to load!
          await sleep(2_000);

          if (assetClassEnumId) {
            console.log(">>> setValue assetClassEnumId", assetClassEnumId);
            setValue("assetClassEnumId", assetClassEnumId);
            console.log(">>> trigger assetClassEnumId");
            await trigger("assetClassEnumId");

            // The problem is that we need to wait for the dependent values to load!
            await sleep(2_000);

            if (strategyEnumId) {
              console.log(">>> setValue strategyEnumId", strategyEnumId);
              setValue("strategyEnumId", strategyEnumId);
            }
          }
        }
      }
    }
    console.log(">>> populateFormFields");
    populateFormFields();
  }, [profileBuilderResponse, fieldHistory, isUpdate]);

  const canViewChanges = useMemo(() => {
    if (
      setOpenTrackChanges !== undefined &&
      autoPopulatedFields?.length &&
      isUpdate
    )
      return true;

    return false;
  }, [isUpdate, setOpenTrackChanges, autoPopulatedFields]);

  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      <ProfileBuilderButton
        setTempFiles={setTempFiles}
        setResponse={setProfileBuilderResponse}
        tempFiles={tempFiles}
        setFieldHistory={setFieldHistory}
      />
      {canViewChanges ? (
        <Button
          id={`fieldHistory__button`}
          data-cy="fieldHistory__button"
          aria-label="track changes"
          onClick={() => ensureDefined(setOpenTrackChanges)(true)}
          color="primary"
          variant="outlined"
          startIcon={<HistoryIcon />}
          disabled={openTrackChanges}
          sx={{ ml: 1 }}
        >
          Review Changes
        </Button>
      ) : null}
    </div>
  );
}
