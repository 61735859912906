import { Tooltip } from "@mui/material";
import Grid from "@mui/material/Grid";
import * as client from "_graphql-types";
import { NO_WHITESPACE_REGEX, UUID_REGEX } from "frontend/src/utils/constants";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  AutocompleteInput,
  BooleanInput,
  Button,
  DateInput,
  Link,
  NumberInput,
  ReferenceInput,
  SelectInput,
  TextInput,
  regex,
  required,
  useGetList,
  useRecordContext,
} from "react-admin";
import { useFormContext, useWatch } from "react-hook-form";
import { useCanAccessMutation } from "../../util/useCanAccessMutation";
import { CustomCreate } from "../CustomCreate";
import CustomInvestmentTagsInput from "../CustomInvestmentTagsInput";
import BuildInvestmentProfile, { TempFile } from "../LLMProfileBuilderButton";
import { getDocumentParams } from "../LLMProfileBuilderButton/utils";
import { RichTextInput } from "../RichTextInput";
import { EntityInput } from "../UI/EntityInput";
import { AdvisorInvestment } from "./AdvisorInvestment";
import { FundStructure } from "./FundStructure";
import { ImpactSelection } from "./ImpactSelect";
import InvestmentFormDecorators from "./InvestmentFormDecorator";
import { PrivatesSpecificFields } from "./PrivateMarketFields";
import { PublicMarketFields } from "./PublicMarketFields";
import {
  LLMBGColor,
  LLMBGColor__AutoComplete,
  getInvestmentConfirmationMessage,
  marketTranslation,
  removeFromAutoPopulatedFieldsOnTouch,
} from "./util";

type IRecord = NonNullable<client.GetOneInvestmentQuery["investment"]>;

const validateRequired = [required()];
const validateRequiredNoWhitespace = [
  required(),
  regex(NO_WHITESPACE_REGEX, "Please remove leading and trailing spaces"),
];
const validateUUid = [regex(UUID_REGEX, "Invalid UUID")];

const ViewFirmButton = () => {
  const record = useRecordContext<IRecord>();
  if (!record?.firm?.id) return null;

  return record?.firm?.isCompany ? (
    <Button
      component={Link}
      to={{ pathname: `/company/${record.firm.companyId}` }}
      label="Go to Company"
    />
  ) : (
    <Button
      component={Link}
      to={{
        pathname: `/firm/${record.firm.id}`,
      }}
      label="Go to Firm"
    />
  );
};

const CreateFirmButton = () => {
  return (
    <Button
      component={Link}
      to={{
        pathname: "/firm/create",
      }}
      label="Create New Firm"
    />
  );
};

const InvestmentCreateForm = React.memo(function InvestmentCreateForm({
  firmInvestments,
  setFirmId,
  fieldAccessMap,
  canEdit,
  autoPopulatedFields,
  setAutoPopulatedFields,
  tempFiles,
  setTempFiles,
}: {
  firmInvestments?: client.Investment[];
  setFirmId: React.Dispatch<React.SetStateAction<number | undefined>>;
  fieldAccessMap: { [key: string]: boolean };
  canEdit: boolean;
  autoPopulatedFields: string[];
  setAutoPopulatedFields: React.Dispatch<React.SetStateAction<string[]>>;
  tempFiles?: TempFile[];
  setTempFiles: React.Dispatch<React.SetStateAction<TempFile[] | undefined>>;
}) {
  const record = useRecordContext<IRecord>();
  const { trigger, formState, reset, setValue } = useFormContext();

  const [
    firm,
    vehicleTypeEnumId,
    marketEnumId,
    assetClassEnumId,
    strategyEnumId,
  ] = useWatch({
    name: [
      "firm",
      "vehicleTypeEnumId",
      "marketEnumId",
      "assetClassEnumId",
      "strategyEnumId",
    ],
  });

  useEffect(() => {
    removeFromAutoPopulatedFieldsOnTouch({
      autoPopulatedFields,
      formState,
      setAutoPopulatedFields,
    });
  }, [formState]);

  useEffect(() => {
    console.log("USE EFFECT FIRM: ", firm);
    setTimeout(() => {
      if (firm) setFirmId(firm.id);
    });
  }, [firm, setFirmId]);

  function canEditField(field: string) {
    return canEdit && fieldAccessMap[field];
  }

  return (
    <>
      <InvestmentFormDecorators />
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <ViewFirmButton />
        </Grid>
        <Grid item xs={6}></Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextInput
            label="Name"
            source="name"
            validate={validateRequiredNoWhitespace}
            sx={autoPopulatedFields.includes("name") ? LLMBGColor : undefined}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <BuildInvestmentProfile
            tempFiles={tempFiles}
            setTempFiles={setTempFiles}
            trigger={trigger}
            reset={reset}
            setAutoPopulatedFields={setAutoPopulatedFields}
            setValue={setValue}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextInput label="Short Name" source="shortName" />
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <ReferenceInput
            source="listEnumId"
            reference="investmentListEnum"
            sort={{ field: "name", order: "ASC" }}
          >
            <SelectInput
              optionText="name"
              label="Investment List"
              validate={validateRequired}
            />
          </ReferenceInput>
        </Grid>
      </Grid>
      <Grid container spacing={2} alignItems="flex-end">
        <Grid item xs={12} sm={6}>
          {!record?.firm && <CreateFirmButton />}
          <EntityInput<NonNullable<IRecord>>
            resource="firm"
            recordKey="firm"
            label={firm?.isCompany ? "Company" : "Firm"}
            isRequired={true}
            filters={{ includeCompanies: true }}
            sx={
              autoPopulatedFields.includes("firm")
                ? LLMBGColor__AutoComplete
                : undefined
            }
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={2}>
          <DateInput
            label="Inception Date"
            source="inceptionDate"
            sx={
              autoPopulatedFields.includes("inceptionDate")
                ? LLMBGColor
                : undefined
            }
          />
        </Grid>
        <Grid item xs={3}>
          <NumberInput label="HFRI ID" source="HFRIInvestmentId" />
        </Grid>
        <Grid item xs={3}>
          <NumberInput label="Evestment ID" source="evestmentId" />
        </Grid>
        <Grid item xs={3}>
          <TextInput
            label="Burgiss ID"
            source="burgissId"
            validate={validateUUid}
          />
        </Grid>
        <Grid item xs={3}>
          <TextInput label="RIC ID" source="RICId" />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} lg={3}>
          <ReferenceInput
            source="marketEnumId"
            reference="marketEnum"
            sort={{ field: "displayOrder", order: "ASC" }}
          >
            <SelectInput
              optionText={(record: client.AssetClassEnum) =>
                marketTranslation(record.id)
              }
              label="Market"
              validate={validateRequired}
              sx={
                autoPopulatedFields.includes("marketEnumId")
                  ? LLMBGColor
                  : undefined
              }
              readOnly={!canEditField("marketEnumId")}
            />
          </ReferenceInput>
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <ReferenceInput
            source="vehicleTypeEnumId"
            reference="vehicleTypeEnum"
            sort={{ field: "vehicleType", order: "ASC" }}
          >
            <SelectInput
              optionText="name"
              label="Vehicle Type"
              validate={validateRequired}
              readOnly={!canEditField("vehicleTypeEnumId")}
              sx={
                autoPopulatedFields.includes("vehicleTypeEnumId")
                  ? LLMBGColor
                  : undefined
              }
            />
          </ReferenceInput>
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <ReferenceInput
            source="assetClassEnumId"
            reference="assetClassEnum"
            sort={{ field: "assetClass", order: "ASC" }}
            filter={{
              ...(vehicleTypeEnumId && {
                vehicleTypeId: vehicleTypeEnumId,
              }),
            }}
          >
            <SelectInput
              optionText="name"
              readOnly={
                !canEditField("assetClassEnumId") ||
                vehicleTypeEnumId === null ||
                vehicleTypeEnumId === undefined
              }
              label="Asset Class"
              validate={validateRequired}
              sx={
                autoPopulatedFields.includes("assetClassEnumId")
                  ? LLMBGColor
                  : undefined
              }
            />
          </ReferenceInput>
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <ReferenceInput
            source="strategyEnumId"
            reference="strategyEnum"
            sort={{ field: "strategy", order: "ASC" }}
            filter={{
              ...(vehicleTypeEnumId && {
                vehicleTypeId: vehicleTypeEnumId,
              }),
              ...(assetClassEnumId && {
                assetClassId: assetClassEnumId,
              }),
            }}
          >
            <SelectInput
              optionText="name"
              readOnly={
                !canEditField("strategyEnumId") ||
                assetClassEnumId === null ||
                assetClassEnumId === undefined
              }
              label="Strategy"
              validate={validateRequired}
              sx={
                autoPopulatedFields.includes("strategyEnumId")
                  ? LLMBGColor
                  : undefined
              }
            />
          </ReferenceInput>
        </Grid>
        {assetClassEnumId === 2 && (
          <Grid item xs={12} sm={6} lg={3}>
            <ReferenceInput
              source="peStrategyEnumId"
              reference="peStrategyEnum"
              sort={{ field: "name", order: "ASC" }}
            >
              <SelectInput
                fullWidth={true}
                optionText="name"
                readOnly={
                  assetClassEnumId === null || assetClassEnumId === undefined
                }
                label="PE Strategy"
              />
            </ReferenceInput>
          </Grid>
        )}
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6} lg={3}>
          <ReferenceInput
            source="subStrategyEnumId"
            reference="subStrategyEnum"
            sort={{ field: "name", order: "ASC" }}
            filter={{
              strategyId: strategyEnumId ?? -1,
            }}
          >
            <SelectInput
              optionText="name"
              readOnly={
                assetClassEnumId === undefined || assetClassEnumId === null
              }
              label="Breakdown"
            />
          </ReferenceInput>
        </Grid>
        <Grid item xs={4} sm={6} lg={3}>
          <ReferenceInput
            source="primaryAssignment"
            reference="investmentAssignmentOption"
            sort={{ field: "commonName", order: "ASC" }}
            perPage={100}
            filter={{ isPrimary: true }}
          >
            <AutocompleteInput
              optionText="commonName"
              label="Primary Assignment"
            />
          </ReferenceInput>
        </Grid>
        <Grid item xs={4} sm={6} lg={3}>
          <ReferenceInput
            source="secondaryAssignment"
            reference="investmentAssignmentOption"
            sort={{ field: "commonName", order: "ASC" }}
            perPage={100}
            filter={{ isSecondary: true }}
          >
            <AutocompleteInput
              optionText="commonName"
              label="Secondary Assignment"
            />
          </ReferenceInput>
        </Grid>
        <Grid item xs={6} lg={3}>
          <BooleanInput label="Is Impact" source="isImpact" />
        </Grid>
        <Grid item xs={6} lg={3}>
          <BooleanInput label="Is Partner" source="isPartner" />
        </Grid>
      </Grid>
      <AdvisorInvestment canEditField={canEditField} />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <RichTextInput
            canEdit={canEditField("summary")}
            label="Investment Summary"
            source="summary"
            backgroundColor={
              autoPopulatedFields.includes("summary")
                ? "rgba(201,186,232,0.5)"
                : undefined
            }
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} lg={3}>
          <ReferenceInput
            source="primaryBenchmarkId"
            reference="investment"
            sort={{ field: "name", order: "ASC" }}
            filter={{
              searchFilters: [
                {
                  TYPE: {
                    values: [
                      { id: 5, label: "ETF" },
                      { id: 12, label: "Index" },
                    ],
                  },
                },
              ],
            }}
          >
            <AutocompleteInput
              optionText="name"
              label="Primary Benchmark"
              sx={
                autoPopulatedFields.includes("primaryBenchmarkId")
                  ? LLMBGColor__AutoComplete
                  : undefined
              }
            />
          </ReferenceInput>
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <ReferenceInput
            source="secondaryBenchmarkId"
            reference="investment"
            sort={{ field: "name", order: "ASC" }}
            filter={{
              searchFilters: [
                {
                  TYPE: {
                    values: [
                      { id: 5, label: "ETF" },
                      { id: 12, label: "Index" },
                    ],
                  },
                },
              ],
            }}
          >
            <AutocompleteInput
              optionText="name"
              label="Secondary Benchmark"
              sx={
                autoPopulatedFields.includes("secondaryBenchmarkId")
                  ? LLMBGColor__AutoComplete
                  : undefined
              }
            />
          </ReferenceInput>
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <ReferenceInput
            source="primaryGeographicFocusId"
            reference="geographicFocusEnum"
            sort={{ field: "name", order: "ASC" }}
          >
            <SelectInput
              validate={validateRequired}
              optionText="name"
              label="Primary Geographic Focus"
              sx={
                autoPopulatedFields.includes("primaryGeographicFocusId")
                  ? LLMBGColor
                  : undefined
              }
            />
          </ReferenceInput>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <hr />
          <h3>Data Transparency</h3>
        </Grid>
        <Grid item xs={4} lg={3}>
          <BooleanInput
            label="Manager unwilling to provide performance"
            source="isManagerUnwillingToProvidePerformance"
          />
        </Grid>
        <Grid item xs={4} lg={3}>
          <BooleanInput
            label="Manager unwilling to provide positions"
            source="isManagerUnwillingToProvidePositions"
          />
        </Grid>
        <Grid item xs={4} lg={3}>
          <BooleanInput
            label="Manager unwilling to provide risk data"
            source="isManagerUnwillingToProvideRisk"
          />
        </Grid>
        <Grid item xs={4} lg={3}>
          <BooleanInput
            label="Manager unwilling to provide AUM"
            source="isManagerUnwillingToProvideAUM"
          />
        </Grid>
        <Grid item xs={4} lg={3}>
          <Tooltip
            title="Has RockCreek signed an NDA precluding them from sharing fund data with 3rd Parties?"
            placement="bottom"
          >
            <div>
              <BooleanInput label="NDA Signed" source="ndaSigned" />
            </div>
          </Tooltip>
        </Grid>
      </Grid>
      {marketEnumId === 1 && (
        <PublicMarketFields
          canEditField={canEditField}
          autoPopulatedFields={autoPopulatedFields}
        />
      )}
      {marketEnumId === 2 && (
        <PrivatesSpecificFields
          canEditField={canEditField}
          autoPopulatedFields={autoPopulatedFields}
        />
      )}
      <Grid item xs={12}>
        <hr />
        <h3>Investment Tags</h3>
      </Grid>
      <CustomInvestmentTagsInput
        source="investmentTags"
        label="Investment Tags"
        readOnly={
          !canEditField("investmentTags") &&
          !canEditField("investmentTags.id") &&
          !canEditField("investmentTags.tagClassId")
        }
      />
      {firmInvestments && (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <hr />
            <h3>Links</h3>
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <SelectInput
              label="Domicile Link"
              source="domicileFundLinkId"
              choices={firmInvestments}
              readOnly={!firmInvestments}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <NumberInput
              label="Reporting Link"
              source="reportingLinkId"
              readOnly={!firmInvestments}
            />
          </Grid>
        </Grid>
      )}
      <Grid item xs={12}>
        <hr />
        <h3>Impact Tags</h3>
      </Grid>
      <ImpactSelection
        source="impactTags"
        canEdit={canEditField("impactTags")}
      />
      <Grid item xs={12}>
        <hr />
        <h3>Fund Structure</h3>
      </Grid>
      <FundStructure canEditField={canEditField} />
    </>
  );
});

export const InvestmentCreate = () => {
  const [firmId, setFirmId] = useState<number | undefined>();
  const [tempFiles, setTempFiles] = useState<TempFile[] | undefined>();

  const { data: firmInvestments } = useGetList<client.Investment>(
    "investment",
    {
      filter: {
        firmId: firmId,
      },
      sort: { field: "name", order: "ASC" },
    },
    { enabled: !!firmId }
  );

  const { canEdit, canEditField, loading } = useCanAccessMutation(
    "addInvestment",
    JSON.stringify({ input: { firmId } })
  );

  const [autoPopulatedFields, setAutoPopulatedFields] = useState<string[]>([]);

  const transformInvestment = useCallback(
    (data: IRecord) => {
      const documentInput = getDocumentParams({ tempFiles });
      return { ...data, documents: documentInput };
    },
    [tempFiles]
  );

  const customFormProps = useMemo<
    React.ComponentProps<typeof CustomCreate<IRecord>>["customFormProps"]
  >(
    () => ({
      customToolbarProps: {
        confirmOnSave: true,
        getConfirmationMessage: ({
          saveParams,
        }: {
          saveParams?: client.InvestmentInput;
        }) => getInvestmentConfirmationMessage(saveParams, firmInvestments),
      },
      loading,
    }),
    [loading, firmInvestments, getInvestmentConfirmationMessage]
  );

  return (
    <CustomCreate<IRecord>
      customFormProps={customFormProps}
      transform={transformInvestment}
    >
      <InvestmentCreateForm
        firmInvestments={firmInvestments}
        setFirmId={setFirmId}
        fieldAccessMap={canEditField}
        canEdit={canEdit}
        autoPopulatedFields={autoPopulatedFields}
        setAutoPopulatedFields={setAutoPopulatedFields}
        tempFiles={tempFiles}
        setTempFiles={setTempFiles}
      />
    </CustomCreate>
  );
};
