import { ReactNode, useCallback, useEffect, useMemo, useState } from "react";

import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import * as client from "_graphql-types";
import { lastDayOfQuarter, startOfQuarter } from "date-fns";
import { useGetList, useGetOne, useInput, useRecordContext } from "react-admin";
import { EntityAutoComplete } from "../UI/EntityAutocomplete";
import type { IdName, OnPeersChange, Record } from "./Edit";

type PrivatePerformanceData =
  client.GetListInvestmentPerformancePrivateQuery["investmentList"]["items"][number];
type PublicPerformanceData = NonNullable<
  NonNullable<client.GetOnePerformanceStatisticsQuery["investment"]>
>;
type PrivatePerformanceState = NonNullable<
  PrivatePerformanceData["performancePrivate"]
>[number];
type PublicPerformanceState = {
  id: number;
  compoundReturn1m: number | null | undefined;
  compoundReturn3m: number | null | undefined;
  compoundReturn1y: number | null | undefined;
  compoundReturn3y: number | null | undefined;
  compoundReturn5y: number | null | undefined;
  compoundReturnYtd: number | null | undefined;
  compoundReturnItdAnnualized: number | null | undefined;
  standardDeviation3y: number | null | undefined;
  sharpeRatio3y: number | null | undefined;
  maxDrawDown3y: number | null | undefined;
  percentProfitablePeriods3y: number | null | undefined;
  startDate: string | null | undefined;
};

type Numeric<T> = {
  [K in keyof T as number extends T[K] ? K : never]: T[K];
};

const currentDate = new Date();

export const PeersInput = (props: {
  subscribePeersChange: (handler: OnPeersChange) => void;
  canEdit: boolean;
}) => {
  const { subscribePeersChange, canEdit } = props;

  const investment = useRecordContext<Record>();
  if (!investment) return;

  const {
    field: { onChange, value: fields },
  }: {
    field: {
      onChange: (value: IdName[]) => void;
      value: IdName[];
    };
  } = useInput({
    source: "peersList.items",
  });

  const isPrivate: boolean = useMemo(
    () => investment.market?.name === "Private Equity",
    [investment]
  );

  const addFields = useCallback(
    (values: IdName[]) => {
      const ids = new Set(fields.map(x => x.id));
      onChange([...fields, ...values.filter(x => !ids.has(x.id))]);
    },
    [fields, investment.id] //onChange is firing on every render, probably bug in useInput
  );

  const removeFields = useCallback(
    (values: IdName[]) => {
      const deletes = new Set(values.map(x => x.id));
      onChange(fields.filter(x => !deletes.has(x.id)));
    },
    [fields] //onChange is firing on every render, probably bug in useInput
  );

  useEffect(() => {
    subscribePeersChange(
      () =>
        ((event, values) => {
          switch (event) {
            case "clear":
              onChange([]);
              break;
            case "add":
              addFields(values);
              break;
            case "remove":
              removeFields(values);
              break;
          }
        }) as OnPeersChange
    );
  }, [addFields, removeFields, subscribePeersChange]); //onChange is firing on every render, probably bug in useInput

  const { data: performancePrivateData } = useGetList<PrivatePerformanceData>(
    "performancePrivate",
    {
      filter: {
        investmentFilter: {
          ids: fields
            .map(({ id }) => Number(id))
            .concat([Number(investment.id)]),
        },
        performancePrivateFilter: {
          asOfDateRange: {
            start: startOfQuarter(currentDate),
            end: lastDayOfQuarter(currentDate),
          },
        },
      },
      sort: {
        field: "asOfDate",
        order: "DESC",
      },
      pagination: {
        page: 1,
        perPage: 25,
      },
    },
    { enabled: isPrivate }
  );

  const { data: performancePublicData } = useGetOne<PublicPerformanceData>(
    "performanceStatistics",
    {
      id: Number(investment.id),
      meta: {
        dependentIds: fields.map(({ id }) => Number(id)),
        asOfDate: currentDate.toISOString().slice(0, 10),
      },
    },
    { enabled: !isPrivate }
  );

  const peersStats = useMemo(() => {
    const rawStats = performancePublicData?.groupStats.stats;
    const stats = performancePublicData?.groupStats.items.map(
      ({ id }, iinvestment) => {
        return {
          id,
          compoundReturn1m: rawStats?.compoundReturn1m?.[iinvestment],
          compoundReturn3m: rawStats?.compoundReturn3m?.[iinvestment],
          compoundReturn1y: rawStats?.compoundReturn1y?.[iinvestment],
          compoundReturn3y: rawStats?.compoundReturn3y?.[iinvestment],
          compoundReturn5y: rawStats?.compoundReturn5y?.[iinvestment],
          compoundReturnYtd: rawStats?.compoundReturnYtd?.[iinvestment],
          compoundReturnItdAnnualized:
            rawStats?.compoundReturnItdAnnualized?.[iinvestment],
          standardDeviation3y: rawStats?.standardDeviation3y?.[iinvestment],
          sharpeRatio3y: rawStats?.sharpeRatio3y?.[iinvestment],
          maxDrawDown3y: rawStats?.maxDrawDown3y?.[iinvestment],
          percentProfitablePeriods3y:
            rawStats?.percentProfitablePeriods3y?.[iinvestment],
          startDate: rawStats?.startDate[iinvestment],
        };
      }
    );
    return stats;
  }, [performancePublicData]);

  const [stats, setStats] = useState<
    | ["private", Map<number, PrivatePerformanceState>]
    | ["public", Map<number, PublicPerformanceState>]
  >();

  useEffect(() => {
    if (performancePrivateData) {
      setStats([
        "private",
        new Map(
          performancePrivateData.flatMap(stat =>
            stat.performancePrivate
              ? [[stat.id, stat.performancePrivate[0]]]
              : []
          )
        ),
      ]);
    } else if (peersStats) {
      setStats(["public", new Map(peersStats.map(stat => [stat.id, stat]))]);
    } else setStats(undefined);
  }, [peersStats, performancePrivateData]);

  function peerCells({ id, name }: IdName) {
    function statRedBlack(
      key: string,
      value: number | null | undefined,
      scale: number = 100
    ) {
      return (
        <TableCell
          align="right"
          style={{ color: (value ?? 0) < 0 ? "red" : "black" }}
          data-id={key}
        >
          {typeof value === "number" ? (value * scale).toFixed(2) : null}
        </TableCell>
      );
    }

    function statStartDate(value: ReactNode) {
      return (
        <TableCell
          align="right"
          data-id={isPrivate ? "vintageYear" : "startDate"}
        >
          {value}
        </TableCell>
      );
    }

    function renderCells() {
      if (!stats) return null;
      else if (stats[0] === "private") {
        const stat = stats[1].get(id);
        // race condition between `fields` and `stats`, stats may be pending after selection
        if (!stat) return null;
        const cell = (key: keyof Numeric<typeof stat>) =>
          statRedBlack(key, stat[key]);
        const startDate = stat?.investment?.vintageYear;
        return (
          <>
            {statStartDate(startDate)}
            {cell("netIRR")}
            {cell("tvpi")}
            {cell("dpi")}
          </>
        );
      } else if (stats[0] === "public") {
        const stat = stats[1].get(id);
        // race condition between `fields` and `stats`, stats may be pending after selection
        if (!stat) return null;
        const cell = (key: keyof Numeric<typeof stat>, scale = 100) =>
          statRedBlack(key, stat[key], scale);
        const startDate = stat?.startDate?.split("T", 2)[0];
        return (
          <>
            {cell("compoundReturn1m")}
            {cell("compoundReturn3m")}
            {cell("compoundReturnYtd")}
            {cell("compoundReturn1y")}
            {cell("compoundReturn3y")}
            {cell("compoundReturn5y")}
            {cell("compoundReturnItdAnnualized")}
            {cell("maxDrawDown3y")}
            {cell("standardDeviation3y")}
            {cell("percentProfitablePeriods3y")}
            {cell("sharpeRatio3y", 1)}
            {statStartDate(startDate)}
          </>
        );
      }
    }

    return (
      <>
        <TableCell data-id="peers-name">
          {name} [{id}]
        </TableCell>
        {renderCells()}
      </>
    );
  }

  return !fields ? null : (
    <TableContainer component={Paper}>
      <Table size="small" data-id="peers">
        <TableHead>
          <TableRow>
            <TableCell style={{ width: 24 }}></TableCell>
            <TableCell style={{ width: 24 }}></TableCell>
            <TableCell>Investment</TableCell>
            {isPrivate ? (
              <>
                <TableCell>Vintage Year</TableCell>
                <TableCell>Net IRR</TableCell>
                <TableCell>TVPI</TableCell>
                <TableCell>DPI</TableCell>
              </>
            ) : (
              <>
                <TableCell>1M</TableCell>
                <TableCell>3M</TableCell>
                <TableCell>YTD</TableCell>
                <TableCell>1Y</TableCell>
                <TableCell>3Y</TableCell>
                <TableCell>5Y</TableCell>
                <TableCell>ITD</TableCell>
                <TableCell>Max DD 3Y</TableCell>
                <TableCell>Std Dev 3Y</TableCell>
                <TableCell>% Profit 3Y</TableCell>
                <TableCell>Sharpe Ratio 3Y</TableCell>
                <TableCell>Start Date</TableCell>
              </>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow data-id="peers-investment">
            <TableCell align="right"></TableCell>
            <TableCell></TableCell>
            {peerCells(investment)}
          </TableRow>
          {fields.map((field, ifield) => {
            const { id, name } = field;
            return (
              <TableRow key={id} data-id={`peers-${ifield}`}>
                <TableCell>
                  {canEdit && (
                    <IconButton
                      size="small"
                      onClick={() => removeFields([field])}
                    >
                      <DeleteIcon color="error" />
                    </IconButton>
                  )}
                </TableCell>
                <TableCell align="right">{ifield + 1}.</TableCell>
                {peerCells({ id, name })}
              </TableRow>
            );
          })}
          {canEdit && (
            <TableRow>
              <TableCell colSpan={2}>Add:</TableCell>
              <TableCell colSpan={12}>
                <EntityAutoComplete
                  resource="investment"
                  label="Investment"
                  sourceKey="id"
                  recordKey="investment"
                  filters={
                    isPrivate
                      ? {
                          marketId: 2,
                        }
                      : undefined
                  }
                  clearSearchOnSelect
                  onChange={value => {
                    if (value) {
                      const { id, name } = value as IdName;
                      addFields([{ id, name }]);
                    }
                  }}
                />
              </TableCell>
              <TableCell>
                <Button
                  onClick={() => onChange([])}
                  title="Clear the peers list"
                  data-id="peers-bulk-clear"
                  color="error"
                  startIcon={<DeleteIcon />}
                >
                  Clear&nbsp;List
                </Button>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
