import { Grid } from "@mui/material";
import { CustomEdit } from "../CustomEdit";
import { RiskInvestmentListInput } from "./RiskInvestmentListInput";

export const RiskRefreshListEdit = () => {
  return (
    <CustomEdit
      title={() => `Refresh List`}
      customFormProps={{
        customToolbarProps: { allowDelete: true },
      }}
    >
      <h3>Daily Refresh List</h3>
      <br />
      <Grid container>
        <Grid container spacing={2}>
          <RiskInvestmentListInput source="items" />
        </Grid>
      </Grid>
    </CustomEdit>
  );
};
