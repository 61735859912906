import * as client from "_graphql-types";

import {
  ArrayInput,
  DateInput,
  FormDataConsumer,
  required,
  SimpleFormIterator,
  TextInput,
} from "react-admin";

import { CustomEdit } from "../CustomEdit";

import Grid from "@mui/material/Grid";
import { useCanAccessMutation } from "../../util/useCanAccessMutation";

type Record = client.GetOneInvestmentCloseDatesQuery["investment"];

const VALIDATE_REQUIRED = [required()];

export const CloseDateEdit = () => {
  const {
    canEdit,
    canEditField: fieldAccessMap,
    loading,
  } = useCanAccessMutation("addOrUpdateCloseDateSize", JSON.stringify({}));

  function canEditField(field: string) {
    return canEdit && fieldAccessMap[field];
  }

  return (
    <CustomEdit<Record>
      title={record => record && `${record.name} Close Dates`}
      customFormProps={{
        loading,
        customToolbarProps: { canAccessMutation: canEdit },
        canEditField: fieldAccessMap,
      }}
    >
      <Grid container>
        <ArrayInput
          disabled={!canEditField("closeDates")}
          source="closeDateFundSize"
          label="Close Dates"
        >
          <SimpleFormIterator disableRemove>
            <FormDataConsumer>
              {row => {
                return (
                  <>
                    <Grid item>
                      <DateInput
                        label="date"
                        source="closeDate"
                        disabled={!canEditField("closeDates.closeDate")}
                        data-id="closeDate"
                        validate={
                          row.scopedFormData?.fundSize
                            ? VALIDATE_REQUIRED
                            : void 0
                        }
                      />
                    </Grid>
                    <Grid item>
                      <TextInput
                        label="size"
                        source="fundSize"
                        data-id="fundSize"
                        disabled={!canEditField("closeDates.fundSize")}
                        validate={
                          row.scopedFormData?.closeDate
                            ? VALIDATE_REQUIRED
                            : void 0
                        }
                      />
                    </Grid>
                  </>
                );
              }}
            </FormDataConsumer>
          </SimpleFormIterator>
        </ArrayInput>
      </Grid>
    </CustomEdit>
  );
};
