import * as client from "_graphql-types";

import { Grid } from "@mui/material";
import {
  NumberInput,
  RadioButtonGroupInput,
  required,
  TextInput,
} from "react-admin";
import { useWatch } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { CustomCreate } from "../CustomCreate";
import { getPersonPath } from "../person/Edit";
import { EntityAutoComplete } from "../UI/EntityAutocomplete";

type Record = client.GetOnePersonWorkExperienceQuery["personWorkExperience"] & {
  companyAction: "create" | "select";
};

const validateRequired = [required()];

function validateYear(value: any) {
  if (value) {
    value = Number(value);
    if (!(Number.isInteger(value) && 1900 < value && value < 3000)) {
      return "Invalid year";
    }
  }
}

function validateForm(form: Partial<Record>) {
  const errors: any = {};
  if (form?.fromYear && form?.toYear && form?.toYear < form?.fromYear) {
    errors.toYear = ["To Year cannot precede From Year"];
  }
  return errors;
}

export const CompanyEnumEntry = () => {
  const companyAction = useWatch({ name: "companyAction" });
  if (companyAction === "create") {
    return (
      <TextInput
        label="Create Company"
        source="company.name"
        validate={validateRequired}
      />
    );
  }

  return (
    <EntityAutoComplete
      sourceKey="company.name"
      optionValue="name"
      resource="companyEnum"
      recordKey="companyEnum"
      label="Company"
      name="company.name"
      isRequired
    />
  );
};

export const PersonWorkExperienceCreate = () => {
  const location = useLocation();
  return (
    <CustomCreate<Record>
      title={record =>
        record?.person &&
        `Create Work Experience for ${record.person.lastName || ""}, ${
          record.person.firstName || ""
        } ${record.person.middleName || ""}`
      }
      customFormProps={{
        customToolbarProps: {
          redirectToPathOnSave: getPersonPath(location),
        },
        simpleFormProps: {
          defaultValues: { companyAction: "select" },
          validate: validateForm,
        },
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <RadioButtonGroupInput
            label=""
            source="companyAction"
            defaultValue="select"
            choices={[
              { id: "select", name: "Select existing company" },
              { id: "create", name: "Missing company" },
            ]}
            row={true}
          />
        </Grid>
        <Grid item xs={9}>
          <CompanyEnumEntry />
        </Grid>
        <Grid item xs={3}></Grid>
        <Grid item xs={5}>
          {/* company$, title, from year, to year */}
          <TextInput label="Job Title" source="title" />
        </Grid>
        <Grid item xs={2}>
          <NumberInput
            label="From Year"
            source="fromYear"
            validate={validateYear}
          />
        </Grid>
        <Grid item xs={2}>
          <NumberInput
            label="To Year"
            source="toYear"
            validate={validateYear}
          />
        </Grid>
        <Grid item xs={3}></Grid>
        <Grid item xs={3}></Grid>
      </Grid>
    </CustomCreate>
  );
};
