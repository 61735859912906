import * as client from "_graphql-types";
import { CustomEdit } from "../CustomEdit";
import Form from "./Form";

type Record = NonNullable<
  client.GetOneServiceProviderNotesQuery["serviceProviderNotes"]
>;

export const ServiceProviderNotesEdit = () => {
  return (
    <CustomEdit<Record>
      title={record => record && `ServiceProviderNotes ${record.id || ""}`}
    >
      <Form />
    </CustomEdit>
  );
};
