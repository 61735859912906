import { camelCase } from "lodash";
import Paper, { PaperProps } from "@mui/material/Paper";
import { styled } from "@mui/system";
import {
  TableCell,
  TableContainer,
  InputAdornment,
  TableRow,
  TableHead,
  TableBody,
  Table,
} from "@mui/material";
import { TextField } from "@mui/material";
import { RaRecord } from "react-admin";
type FirmDEIRecord = {
  measureEnumId: number;
  deiCategory1EnumId: number;
  deiCategory2EnumId: number;
  value: number | null;
};

const GENDER_NOT_APPLICABLE = 5;
const ETHNICITY_NOT_APPLICABLE = 26;

export const findValue = (
  record: FirmDEIRecord,
  measureEnumId: number,
  category1: number,
  category2: number
) =>
  record &&
  record.measureEnumId === measureEnumId &&
  record.deiCategory1EnumId === category1 &&
  record.deiCategory2EnumId === category2;

const PaperContainer = (props: PaperProps) => (
  <Paper variant="outlined" {...props} />
);
const Root = styled("div")(
  ({ theme }) => `
  table {
    font-family: IBM Plex Sans, sans-serif;
    font-size: 0.875rem;
    border-collapse: collapse;
    width: 100%;
  }
  td,
  th {
    border: 1px solid ${theme.palette.mode === "dark" ? "#2D3843" : "#E0E3E7"};
    text-align: center;
    padding: 6px;
  }
  `
);

type Category = { id: number; description: string };

export function calculateTotal({
  records,
  measureEnumId,
  categoryIds,
  categoryKey = "deiCategory1EnumId",
}: {
  records: FirmDEIRecord[];
  measureEnumId: number;
  categoryIds?: number[];
  categoryKey?: keyof NonNullable<FirmDEIRecord>;
}) {
  const sum = records
    .filter(
      (record: FirmDEIRecord): record is NonNullable<FirmDEIRecord> =>
        !!record &&
        (!categoryIds ||
          !categoryIds.length ||
          categoryIds.includes(record[categoryKey] as number)) &&
        record?.measureEnumId === measureEnumId
    )
    .reduce((accu: number, curr) => {
      if (!curr.value) return accu;

      accu += curr.value;
      return accu;
    }, 0 as number);

  return Number.isInteger(sum) ? sum : Number(sum.toFixed(1));
}

function handleChange({
  localStateRecords = [],
  setLocalStateRecords,
  event,
  measureEnumId,
  category1,
  category2,
}: {
  event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>;
  measureEnumId: number;
  category1: number;
  category2: number;
  localStateRecords: FirmDEIRecord[];
  setLocalStateRecords: React.Dispatch<React.SetStateAction<FirmDEIRecord[]>>;
}) {
  const { value } = event.target;
  const newValue = {
    measureEnumId,
    deiCategory1EnumId: category1,
    deiCategory2EnumId: category2,
    value: value ? Number(value) : null,
  };

  const iExistingRecord = localStateRecords.findIndex((record: FirmDEIRecord) =>
    findValue(record, measureEnumId, category1, category2)
  );

  if (iExistingRecord === -1) {
    const records = localStateRecords.concat([newValue]);

    setLocalStateRecords(records);
  } else {
    const records = [...localStateRecords];
    records[iExistingRecord] = { ...records[iExistingRecord], ...newValue };
    setLocalStateRecords(records);
  }
}

interface MainCategoryTableProps {
  category1: Category[];
  category2: Category[];
  measureEnumId: number;
  recordId: RaRecord["id"];
  firmDEIRecords: FirmDEIRecord[];
  setLocalStateRecords: React.Dispatch<React.SetStateAction<FirmDEIRecord[]>>;
  localStateRecords: FirmDEIRecord[];
  isPercent: boolean;
  raOnChange: (event: any) => void;
  isRestOfWorld: boolean;
  MAIN_CATEGORIES: number[];
}

type TableKeys =
  | "category2"
  | "category1"
  | "measureEnumId"
  | "recordId"
  | "localStateRecords"
  | "setLocalStateRecords"
  | "isPercent"
  | "MAIN_CATEGORIES";

type MCTableProps = Pick<MainCategoryTableProps, TableKeys> & {
  handleBlur: () => void;
};

export function MainCategoryTableGenderOnly({
  category2,
  measureEnumId,
  recordId,
  handleBlur,
  localStateRecords,
  setLocalStateRecords,
  isPercent,
  MAIN_CATEGORIES,
}: MCTableProps) {
  return (
    <Table size="small" data-cy={`table::${measureEnumId}`} key={measureEnumId}>
      <TableHead>
        <TableRow>
          {category2.map(c2 => (
            <TableCell
              data-cy={`${measureEnumId}::${camelCase(c2.description)}`}
              key={`${measureEnumId}::${camelCase(c2.description)}`}
            >
              {c2.description === "Not Available"
                ? "No Information Available (Gender)"
                : c2.description}
            </TableCell>
          ))}
          <TableCell style={{ paddingLeft: "1em", paddingRight: "1em" }}>
            Total
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <>
            {category2.map(c2Row => (
              <TableCell
                key={`${recordId}::${measureEnumId}::${camelCase(
                  c2Row.description
                )}::${camelCase(c2Row.description)}`}
              >
                <TextField
                  type="number"
                  variant="outlined"
                  size="small"
                  name={`${recordId}::${measureEnumId}::${camelCase(
                    c2Row.description
                  )}::${camelCase(c2Row.description)}`}
                  value={
                    localStateRecords.find(r =>
                      findValue(
                        r,
                        measureEnumId,
                        ETHNICITY_NOT_APPLICABLE,
                        c2Row.id
                      )
                    )?.value ?? ""
                  }
                  onChange={e =>
                    handleChange({
                      event: e,
                      measureEnumId: measureEnumId,
                      category1: ETHNICITY_NOT_APPLICABLE,
                      category2: c2Row.id,
                      localStateRecords,
                      setLocalStateRecords,
                    })
                  }
                  onBlur={handleBlur}
                  InputProps={{
                    ...(isPercent && {
                      endAdornment: (
                        <InputAdornment position="end">%</InputAdornment>
                      ),
                    }),
                    inputProps: {
                      style: { textAlign: "center" },
                      min: 0,
                    },
                  }}
                />
              </TableCell>
            ))}
            <TableCell
              data-cy={`${measureEnumId}::total`}
              key={`${measureEnumId}::total`}
            >
              {calculateTotal({
                categoryIds: category2.map(c => c.id),
                categoryKey: "deiCategory2EnumId",
                measureEnumId,
                records: localStateRecords,
              })}
              {isPercent ? " %" : ""}
            </TableCell>
          </>
        </TableRow>
      </TableBody>
    </Table>
  );
}

export function MainCategoryTableDefault({
  category1,
  category2,
  measureEnumId,
  recordId,
  localStateRecords,
  setLocalStateRecords,
  isPercent,
  MAIN_CATEGORIES,
  handleBlur,
}: MCTableProps) {
  return (
    <Table size="small" data-cy={`table::${measureEnumId}`} key={measureEnumId}>
      <TableHead>
        <TableRow>
          <TableCell />
          {category1.map(c1 => (
            <TableCell
              data-cy={`${measureEnumId}::${camelCase(c1.description)}`}
              key={`${measureEnumId}::${camelCase(c1.description)}`}
            >
              {c1.description === "Not Disclosed"
                ? "No Information Available (Race/Ethnicity)"
                : c1.description}
            </TableCell>
          ))}
          <TableCell style={{ paddingLeft: "1em", paddingRight: "1em" }}>
            Total
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {category2.map(c2 => (
          <TableRow
            data-cy={`${measureEnumId}::${camelCase(c2.description)}`}
            key={`${measureEnumId}::${camelCase(c2.description)}`}
          >
            <TableCell style={{ textAlign: "right" }}>
              {c2.description === "Not Available"
                ? "No Information Available (Gender)"
                : c2.description}
            </TableCell>
            {category1.map(c1Row => (
              <TableCell
                key={`${recordId}::${measureEnumId}::${camelCase(
                  c1Row.description
                )}::${camelCase(c2.description)}`}
              >
                <TextField
                  type="number"
                  variant="outlined"
                  size="small"
                  name={`${recordId}::${measureEnumId}::${camelCase(
                    c1Row.description
                  )}::${camelCase(c2.description)}`}
                  value={
                    localStateRecords.find(r =>
                      findValue(r, measureEnumId, c1Row.id, c2.id)
                    )?.value ?? ""
                  }
                  onChange={e =>
                    handleChange({
                      event: e,
                      measureEnumId: measureEnumId,
                      category1: c1Row.id,
                      category2: c2.id,
                      localStateRecords,
                      setLocalStateRecords,
                    })
                  }
                  onBlur={handleBlur}
                  InputProps={{
                    ...(isPercent && {
                      endAdornment: (
                        <InputAdornment position="end">%</InputAdornment>
                      ),
                    }),
                    inputProps: {
                      style: { textAlign: "center" },
                      min: 0,
                    },
                  }}
                />
              </TableCell>
            ))}
            <TableCell
              style={{ backgroundColor: "#E7EBF0" }}
              data-cy={`${measureEnumId}::${camelCase(c2.description)}::total`}
            >
              {calculateTotal({
                records: localStateRecords,
                measureEnumId,
                categoryIds: [c2.id],
                categoryKey: "deiCategory2EnumId",
              })}
              {isPercent ? " %" : ""}
            </TableCell>
          </TableRow>
        ))}
        <TableRow style={{ backgroundColor: "#E7EBF0" }}>
          <TableCell style={{ textAlign: "right" }}>Total</TableCell>
          {category1.map(c1 => (
            <TableCell
              data-cy={`${measureEnumId}::${camelCase(c1.description)}::total`}
              key={`${measureEnumId}::${camelCase(c1.description)}::total`}
            >
              {calculateTotal({
                categoryIds: [c1.id],
                categoryKey: "deiCategory1EnumId",
                measureEnumId,
                records: localStateRecords,
              })}
              {isPercent ? " %" : ""}
            </TableCell>
          ))}
          <TableCell data-cy={`${measureEnumId}::total`}>
            {calculateTotal({
              records: localStateRecords,
              measureEnumId,
              categoryIds: MAIN_CATEGORIES,
            })}
            {isPercent ? " %" : ""}
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
}

export function MainCategoryTable({
  category1,
  category2,
  measureEnumId,
  recordId,
  firmDEIRecords,
  localStateRecords,
  setLocalStateRecords,
  isPercent,
  raOnChange,
  isRestOfWorld,
  MAIN_CATEGORIES,
}: MainCategoryTableProps) {
  function handleBlur() {
    console.log("handleBlur records", firmDEIRecords);
    console.log("handleBlur state", localStateRecords);
    raOnChange(localStateRecords);
  }
  const cat2Applicable: Category[] = category2.filter(
    category => category.id !== GENDER_NOT_APPLICABLE
  );

  return (
    <Root>
      <TableContainer component={PaperContainer}>
        {isRestOfWorld ? (
          <MainCategoryTableGenderOnly
            category2={cat2Applicable}
            {...{
              category1,
              measureEnumId,
              recordId,
              localStateRecords,
              setLocalStateRecords,
              isPercent,
              MAIN_CATEGORIES,
              handleBlur,
            }}
          />
        ) : (
          <MainCategoryTableDefault
            category2={cat2Applicable}
            {...{
              category1,
              measureEnumId,
              recordId,
              localStateRecords,
              setLocalStateRecords,
              isPercent,
              MAIN_CATEGORIES,
              handleBlur,
            }}
          />
        )}
      </TableContainer>
    </Root>
  );
}

export function AdditionalCategoriesTable({
  headerCategories,
  measureEnumId,
  recordId,
  firmDEIRecords,
  isPercent,
  raOnChange,
  localStateRecords,
  setLocalStateRecords,
}: {
  headerCategories: Category[];
  measureEnumId: number;
  recordId: RaRecord["id"];
  firmDEIRecords: FirmDEIRecord[];
  isPercent: boolean;
  raOnChange: (event: any) => void;
  setLocalStateRecords: React.Dispatch<React.SetStateAction<FirmDEIRecord[]>>;
  localStateRecords: FirmDEIRecord[];
}) {
  function handleBlur() {
    raOnChange(localStateRecords);
  }

  return (
    <Root>
      <TableContainer component={PaperContainer}>
        <Table
          size="small"
          data-cy={`table::${measureEnumId}_additionalCategories`}
          key={`${measureEnumId}_additionalCategories`}
        >
          <TableHead>
            <TableRow>
              {headerCategories.map(c1 => (
                <TableCell
                  data-cy={`${measureEnumId}::${camelCase(c1.description)}`}
                  key={`${measureEnumId}::${camelCase(c1.description)}`}
                >
                  {c1.description}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              {headerCategories.map(c1Row => (
                <TableCell
                  data-cy={`${measureEnumId}::${camelCase(c1Row.description)}`}
                  key={`${measureEnumId}::${camelCase(c1Row.description)}`}
                >
                  <TextField
                    type="number"
                    variant="outlined"
                    size="small"
                    sx={{ textAlign: "center" }}
                    name={`${recordId}::${measureEnumId}::${camelCase(
                      c1Row.description
                    )}::additionalCategories`}
                    value={
                      localStateRecords.find(r =>
                        findValue(
                          r,
                          measureEnumId,
                          c1Row.id,
                          GENDER_NOT_APPLICABLE
                        )
                      )?.value ?? ""
                    }
                    onChange={e =>
                      handleChange({
                        event: e,
                        measureEnumId,
                        category1: c1Row.id,
                        category2: GENDER_NOT_APPLICABLE,
                        localStateRecords,
                        setLocalStateRecords,
                      })
                    }
                    onBlur={handleBlur}
                    InputProps={{
                      ...(isPercent && {
                        endAdornment: (
                          <InputAdornment position="end">%</InputAdornment>
                        ),
                      }),
                      inputProps: {
                        style: { textAlign: "center" },
                        min: 0,
                      },
                    }}
                  />
                </TableCell>
              ))}
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Root>
  );
}
