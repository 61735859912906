import { Grid } from "@mui/material";
import { TextInput, SelectInput, BooleanInput, required } from "react-admin";
import { useWatch } from "react-hook-form";
const SetTypeChoices = [
  { id: 1, name: "list of investments" },
  { id: 2, name: "list of sets" },
];

const SubscriptionLevelChoices = [
  {
    id: 1,
    name: "Auto Subscribe - automatically add list to associated users curated lists in Sirius",
  },
  {
    id: 2,
    name: "Subscribeable - allow users to subscribe to this set via the list view in Sirius",
  },
  {
    id: 3,
    name: "Not Subscribeable - sets access only, does not allow users to subscribe to set",
  },
];

const validateRequired = [required()];

export function Form() {
  const setType = useWatch({ name: "setType" });
  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <TextInput source="name" />
      </Grid>
      <Grid item xs={6}>
        <SelectInput
          label="Set Type"
          source="setType"
          choices={SetTypeChoices}
          validate={validateRequired}
        />
      </Grid>
      <Grid item xs={12}>
        <TextInput source="description" fullWidth />
      </Grid>
      <Grid item xs={12}>
        <TextInput source="expression" fullWidth />
      </Grid>

      {setType === 1 && (
        <>
          <Grid item xs={6}>
            <BooleanInput
              source="givesAccess"
              label="Give associated users access to results of expression"
            />
          </Grid>

          <Grid item xs={6}>
            <SelectInput
              label="Subscription Level"
              source="subscriptionLevelEnumId"
              choices={SubscriptionLevelChoices}
              validate={validateRequired}
            />
          </Grid>
        </>
      )}
    </Grid>
  );
}
