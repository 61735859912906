import * as client from "_graphql-types";
import { filterSortPage } from "../DataProvider";
import * as types from "./types";

type Sdk = ReturnType<typeof client.getSdk>;

/*********************************************************************/
// Data Provider Functions
/*********************************************************************/

/*********************************************************************/
async function getList(
  sdk: Sdk,
  params: types.GetListParams<client.InvestmentAssignmentOptionSortEnum>
) {
  const { pagination, sort, filter } = params;
  const {
    investmentAssignmentOptionList: { items: data, total },
  } = await sdk.getListInvestmentAssignmentOption(
    filterSortPage({
      filter,
      sort,
      pagination,
    })
  );

  return {
    data,
    total,
  };
}

/*********************************************************************/
async function getMany(sdk: Sdk, params: types.GetManyParams) {
  const ids = params.ids.map(String);

  const { investmentAssignmentOptionMany: data } =
    await sdk.getManyInvestmentAssignmentOption({ ids });

  return {
    data,
  };
}

export const investmentAssignmentOption = types.dataProvider({
  getList,
  getMany,
});
