import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import React from "react";
import { DataProvider } from "react-admin";
import { CopyRiskMeasures } from "./CopyRiskMeasures";
import { DeleteRiskMeasures } from "./DeleteRiskMeasures";
import { RefreshInvestmentRisk } from "./RefreshInvestmentRisk";

export interface IdName {
  id: number | string;
  name: string;
}

export const setInvestmentOptions =
  (dataProvider: DataProvider, handler: (options: IdName[]) => void) =>
  (q: string) => {
    dataProvider
      .getList("investmentRiskInvestment", {
        filter: { q },
        sort: {
          field: "nameSearchRank",
          order: "ASC",
        },
        pagination: {
          page: 1,
          perPage: 20,
        },
      })
      .then(({ data }: { data: { id: string; name: string }[] }) => {
        handler(
          data.map(({ id, name }) => ({
            id: Number(id),
            name,
          }))
        );
      });
  };

export const RiskDataView = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Paper
          elevation={2}
          style={{
            padding: 1,
            paddingLeft: "1em",
            paddingRight: "1em",
            marginTop: "1em",
          }}
        >
          <RefreshInvestmentRisk />
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper
          elevation={2}
          style={{ padding: 1, paddingLeft: "1em", paddingRight: "1em" }}
        >
          <CopyRiskMeasures />
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper
          elevation={2}
          style={{ padding: 1, paddingLeft: "1em", paddingRight: "1em" }}
        >
          <DeleteRiskMeasures />
        </Paper>
      </Grid>
    </Grid>
  );
};
