import * as client from "_graphql-types";
import * as types from "./types";

type Sdk = ReturnType<typeof client.getSdk>;

/*********************************************************************/
// Data Provider Functions
/*********************************************************************/

/*********************************************************************/
async function getList(
  sdk: Sdk,
  params: types.GetListParams<client.FirmFdpLoginsSortEnum>
) {
  const {
    pagination: { page, perPage },
    sort: { field, order },
    filter,
  } = params;

  const {
    firmFDPLoginsList: { items: data, total },
  } = await sdk.getListFirmFDPLogins({
    page: {
      offset: (page - 1) * perPage,
      limit: perPage,
    },
    sort: [{ field, order }],
    filter,
  });

  return {
    data,
    total,
  };
}

/*********************************************************************/
async function getOne(sdk: Sdk, params: types.GetOneParams) {
  const id = Number(params.id);
  const { firmFDPLogins: data } = await sdk.getOneFirmFDPLogins({
    id,
  });
  return {
    data,
  };
}

/*********************************************************************/
async function getMany(sdk: Sdk, params: types.GetManyParams) {
  const ids = params.ids.map(id => Number(id));

  const { firmFDPLoginsMany: data } = await sdk.getManyFirmFDPLogins({
    ids,
  });

  return {
    data,
  };
}

/*********************************************************************/
async function getManyReference(
  sdk: Sdk,
  params: types.GetManyReferenceParams<client.FirmFdpLoginsSortEnum>
) {
  const id = params.id;
  const {
    pagination: { page, perPage },
    sort: { field, order },
    filter,
    target,
  } = params;

  const {
    firmFDPLoginsList: { items: data, total },
  } = await sdk.getListFirmFDPLogins({
    page: {
      offset: (page - 1) * perPage,
      limit: perPage,
    },
    sort: [{ field, order }],
    filter: { ...filter, [target]: id },
  });

  return {
    data,
    total,
  };
}

/*********************************************************************/
async function create(
  sdk: Sdk,
  params: types.CreateParams<
    NonNullable<client.GetOneFirmFdpLoginsQuery["firmFDPLogins"]>
  >
) {
  const { data } = params;

  const input: client.CreateFirmFdpLoginsMutationVariables["input"] = {
    firmId: data.firmId ?? data.firm?.id,
    fdpLoginId: data.fdpLoginId,
    fdpWebsiteId: data.fdpWebsiteId,
  };

  const {
    addFirmFDPLogins: { id },
  } = await sdk.createFirmFDPLogins({
    input,
  });
  return await getOne(sdk, { id: id! });
}

/*********************************************************************/
async function update(
  sdk: Sdk,
  params: types.UpdateParams<
    NonNullable<client.GetOneFirmFdpLoginsQuery["firmFDPLogins"]>
  >
) {
  const id = Number(params.id);
  const { data } = params;

  const input: client.UpdateFirmFdpLoginsMutationVariables["input"] = {
    firmId: data.firmId ?? data.firm?.id,
    fdpLoginId: data.fdpLoginId,
    fdpWebsiteId: data.fdpWebsiteId,
  };

  await sdk.updateFirmFDPLogins({
    id,
    input,
  });
  return await getOne(sdk, { id });
}

/*********************************************************************/
export const firmFDPLogins = types.dataProvider({
  getList,
  getOne,
  getMany,
  getManyReference,
  create,
  update,
});
