import {
  ReferenceInput,
  SelectInput,
  BooleanInput,
  required,
} from "react-admin";
import { Grid } from "@mui/material";
import NumberInputWithSymbol from "../NumberWithInputAdornment";
import { useWatch } from "react-hook-form";

const validateRequired = [required()];
const DEFAULT_UNKNOWN_PE_MGMT_CALC = 4;
const DEFAULT_UNKNOWN_PE_INCT_CALC = 3;

export function PEInvestmentFees({
  canEditField,
}: {
  canEditField: (key: string) => boolean;
}) {
  const [hasCatchupProvision, hasMgmtFeeStepDown, isCompounded] = useWatch({
    name: [
      "peLiquidity.hasCatchupProvision",
      "investmentFees.hasMgmtFeeStepDown",
      "peLiquidity.isCompounded",
    ],
  });
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={6} md={6} lg={2}>
          <NumberInputWithSymbol
            source="investmentFees.mgmtFee"
            label="Management Fee"
            symbol="%"
            symbolAlignment="end"
            disabled={!canEditField("investmentFees.mgmtFee")}
            validate={validateRequired}
          />
        </Grid>
        <Grid item xs={6} md={6} lg={2}>
          <ReferenceInput
            source="investmentFees.peMgmtFeeCalcEnumId"
            reference="peMgmtFeeCalcEnum"
            sort={{ field: "id", order: "ASC" }}
          >
            <SelectInput
              label="Fee Base"
              optionText="name"
              disabled={!canEditField("investmentFees.peMgmtFeeCalcEnumId")}
              defaultValue={DEFAULT_UNKNOWN_PE_MGMT_CALC}
              validate={validateRequired}
            />
          </ReferenceInput>
        </Grid>
        <Grid item xs={6} md={6} lg={2}>
          <ReferenceInput
            source="investmentFees.mgmtFeeFreqEnumId"
            reference="feeFreqEnum"
            sort={{ field: "name", order: "ASC" }}
          >
            <SelectInput
              label="Fee Frequency"
              optionText="name"
              disabled={!canEditField("investmentFees.mgmtFeeFreqEnumId")}
            />
          </ReferenceInput>
        </Grid>

        <Grid item xs={6} md={6} lg={2}>
          <NumberInputWithSymbol
            source="investmentFees.incentiveFee"
            label="Incentive Fee"
            symbol="%"
            symbolAlignment="end"
            disabled={!canEditField("investmentFees.incentiveFee")}
            validate={validateRequired}
          />
        </Grid>
        <Grid item xs={6} md={6} lg={2}>
          <ReferenceInput
            source="investmentFees.peIncentiveFeeCalcEnumId"
            reference="peInctFeeCalcEnum"
            sort={{ field: "id", order: "ASC" }}
          >
            <SelectInput
              label="Fee Base"
              optionText="name"
              disabled={
                !canEditField("investmentFees.peIncentiveFeeCalcEnumId")
              }
              defaultValue={DEFAULT_UNKNOWN_PE_INCT_CALC}
              validate={validateRequired}
            />
          </ReferenceInput>
        </Grid>
        <Grid item xs={6} md={6} lg={2}>
          <ReferenceInput
            source="investmentFees.incentiveFeeFreqEnumId"
            reference="feeFreqEnum"
            sort={{ field: "name", order: "ASC" }}
          >
            <SelectInput
              label="Fee Frequency"
              optionText="name"
              disabled={!canEditField("investmentFees.incentiveFeeFreqEnumId")}
            />
          </ReferenceInput>
        </Grid>

        <Grid item xs={6} md={3}>
          <NumberInputWithSymbol
            source="investmentFees.peMgmtFeeDuringCommitment"
            label="Management Fee During Commitment"
            symbol="%"
            symbolAlignment="end"
            disabled={!canEditField("investmentFees.peMgmtFeeDuringCommitment")}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <ReferenceInput
            source="investmentFees.peMgmtFeeDuringCommitmentCalcEnumId"
            reference="peMgmtFeeCalcEnum"
            label="Fee Base"
            sort={{ field: "id", order: "ASC" }}
          >
            <SelectInput
              label="Fee Base"
              optionText="name"
              disabled={
                !canEditField(
                  "investmentFees.peMgmtFeeDuringCommitmentCalcEnumId"
                )
              }
            />
          </ReferenceInput>
        </Grid>
        <Grid item xs={6} md={3}>
          <NumberInputWithSymbol
            source="investmentFees.peMgmtFeeDuringHarvest"
            label="Management Fee During Harvest"
            symbol="%"
            symbolAlignment="end"
            disabled={!canEditField("investmentFees.peMgmtFeeDuringHarvest")}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <ReferenceInput
            source="investmentFees.peMgmtFeeDuringHarvestCalcEnumId"
            reference="peMgmtFeeCalcEnum"
            label="Fee Base"
            sort={{ field: "id", order: "ASC" }}
          >
            <SelectInput
              label="Fee Base"
              optionText="name"
              disabled={
                !canEditField("investmentFees.peMgmtFeeDuringHarvestCalcEnumId")
              }
            />
          </ReferenceInput>
        </Grid>
        <Grid item xs={6} md={6} lg={2}>
          <BooleanInput
            source="investmentFees.hasMgmtFeeStepDown"
            label="Has Management Fee Step Down?"
            disabled={!canEditField("investmentFees.hasMgmtFeeStepDown")}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <NumberInputWithSymbol
            source="investmentFees.peMgmtFeeStepDownPercent"
            label="Step Down Percentage"
            symbol="%"
            symbolAlignment="end"
            validate={!!hasMgmtFeeStepDown ? validateRequired : undefined}
            disabled={
              !hasMgmtFeeStepDown ||
              !canEditField("investmentFees.peMgmtFeeStepDownPercent")
            }
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <ReferenceInput
            source="investmentFees.peMgmtFeeStepDownCalcEnumId"
            reference="peMgmtFeeCalcEnum"
            label="Fee Base"
            sort={{ field: "id", order: "ASC" }}
          >
            <SelectInput
              disabled={
                !hasMgmtFeeStepDown ||
                !canEditField("investmentFees.peMgmtFeeStepDownCalcEnumId")
              }
              label="Fee Base"
              optionText="name"
            />
          </ReferenceInput>
        </Grid>
        <Grid item xs={6} md={6} lg={2}>
          <ReferenceInput
            source="peLiquidity.optionOfExtensionId"
            reference="rightOfExtensionEnum"
            label="Right of Extension"
          >
            <SelectInput
              label="Right Of Extension"
              optionText="name"
              disabled={!canEditField("peLiquidity.optionOfExtensionId")}
            />
          </ReferenceInput>
        </Grid>
        <Grid item xs={6} md={6} lg={2}>
          <NumberInputWithSymbol
            source="peLiquidity.preferredReturn"
            label="Preferred Return"
            symbol="%"
            symbolAlignment="end"
            disabled={!canEditField("peLiquidity.preferredReturn")}
          />
        </Grid>

        <Grid item xs={6} md={6} lg={2}>
          <BooleanInput
            source="peLiquidity.isCompounded"
            label="Is Compounded"
            disabled={!canEditField("peLiquidity.isCompounded")}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <ReferenceInput
            source="peLiquidity.compoundingPeriodId"
            reference="compoundingPeriodEnum"
            sort={{ field: "name", order: "ASC" }}
          >
            <SelectInput
              disabled={
                !isCompounded ||
                !canEditField("peLiquidity.compoundingPeriodId")
              }
              label="Compounding Period"
              optionText="name"
            />
          </ReferenceInput>
        </Grid>
        <Grid item xs={6} md={6} lg={2}>
          <BooleanInput
            source="peLiquidity.hasCatchupProvision"
            label="Has Catch-up Provision?"
            disabled={!canEditField("peLiquidity.hasCatchupProvision")}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <NumberInputWithSymbol
            source="peLiquidity.catchupPercent"
            label="Catch-up Percentage"
            symbol="%"
            symbolAlignment="end"
            validate={!!hasCatchupProvision ? validateRequired : undefined}
            disabled={
              !hasCatchupProvision ||
              !canEditField("peLiquidity.catchupPercent")
            }
          />
        </Grid>
      </Grid>
    </>
  );
}
