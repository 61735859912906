import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import { RiskRefreshListQuery } from "_graphql-types";
import { useRef, useState } from "react";
import { useInput } from "../react-admin-fixes";
import { IdInvestmentAutocomplete } from "./IdInvestmentAutocomplete";

type Record = NonNullable<
  RiskRefreshListQuery["riskRefreshList"]
>["items"][number];

type RiskInvestment = Record & {
  action?: "add" | "remove";
};

export const RiskInvestmentListInput = ({ source }: { source: string }) => {
  const {
    field: { name, onChange, value },
  } = useInput<RiskInvestment[]>({ source });

  const [bottomScrollCheck, changeBottomScrollCheck] = useState(false);

  const tableEl = useRef<HTMLTableElement>(null);
  if (bottomScrollCheck) {
    if (tableEl.current)
      setTimeout(() => {
        if (tableEl.current)
          tableEl.current.scroll(0, tableEl.current.scrollHeight + 800);
      }, 0);
    changeBottomScrollCheck(false);
  }

  return (
    <Paper sx={{ width: "100%" }}>
      <TableContainer ref={tableEl} sx={{ maxHeight: 440 }}>
        <Table size="small">
          <TableBody>
            {[...value.values()].map((item, i) => {
              console.log("ITEM ->", i, JSON.stringify(item, null, 2));
              return (
                <TableRow
                  key={item.id}
                  style={{
                    ...(item.action === "add"
                      ? { backgroundColor: "lightgreen" }
                      : item.action === "remove"
                      ? { backgroundColor: "lightsalmon" }
                      : {}),
                  }}
                >
                  <TableCell>
                    <IconButton
                      data-cy={`${i}-delete`}
                      size="small"
                      onClick={() => {
                        if (item.action === "remove") return;
                        onChange([
                          ...value.slice(0, i),
                          ...(item.action === "add"
                            ? []
                            : [{ ...item, action: "remove" as const }]),
                          ...value.slice(i + 1),
                        ]);
                      }}
                    >
                      <DeleteIcon color="error" />
                    </IconButton>
                  </TableCell>
                  <TableCell>{item.id}</TableCell>
                  <TableCell>{item.investment?.name}</TableCell>
                  <TableCell>{item.investment?.firm.name}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <IdInvestmentAutocomplete
        onSelect={investment => {
          onChange([
            ...value,
            {
              id: investment.id,
              investment,
              action: "add",
            },
          ]);
          changeBottomScrollCheck(true);
        }}
        clearAfterSelect
      />
    </Paper>
  );
};
