import Grid from "@mui/material/Grid";
import * as client from "_graphql-types";
import {
  Button,
  Datagrid,
  NumberInput,
  Pagination,
  ReferenceInput,
  ReferenceManyField,
  SelectInput,
  TextField,
  required,
  useRecordContext,
} from "react-admin";
import { Link } from "react-router-dom";
import {
  CommaFormattedNumber,
  FormattedNumberInput,
} from "../CustomNumberInput";
import NumberInputWithSymbol from "../NumberWithInputAdornment";
import {
  validateWholeNumber,
  LLMBGColor,
  LLMBGColor__AutoComplete,
} from "./util";

type Record = client.GetOneInvestmentQuery["investment"];

const validateRequired = [required()];

export const PrivatesSpecificFields = ({
  edit = false,
  canEditField,
  autoPopulatedFields = [],
}: {
  edit?: boolean;
  canEditField: (field: keyof client.InvestmentInput) => boolean;
  autoPopulatedFields?: string[];
}) => {
  const record = useRecordContext<Record>();
  return (
    <>
      <Grid item xs={12}>
        <hr />
        <h3>Private Market Attributes</h3>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6} md={3}>
          <ReferenceInput
            source="peFundNumberEnumId"
            reference="peFundNumberEnum"
            sort={{ field: "displayOrder", order: "ASC" }}
          >
            <SelectInput
              fullWidth={true}
              optionText="name"
              disabled={!canEditField("peFundNumberEnumId")}
              label="Fund Number"
              helperText="Firm Level"
              validate={validateRequired}
            />
          </ReferenceInput>
        </Grid>
      </Grid>

      <Grid container spacing={2} style={{ marginTop: 10 }}>
        <Grid item xs={6} md={3}>
          <NumberInput
            disabled={!canEditField("vintageYear")}
            label="Vintage Year"
            source="vintageYear"
            isRequired
            validate={validateWholeNumber}
            sx={
              autoPopulatedFields.includes("vintageYear")
                ? LLMBGColor
                : undefined
            }
          />
        </Grid>
        <Grid item xs={6} md={2}>
          <NumberInputWithSymbol
            disabled={!canEditField("maxTargetNetIRR")}
            label="Max Target Net IRR"
            source="maxTargetNetIRR"
            symbol="%"
            //TODO Format and parse don't exist in destination component... Bug?
            sx={
              autoPopulatedFields.includes("maxTargetNetIRR")
                ? LLMBGColor__AutoComplete
                : undefined
            }
          />
        </Grid>
        <Grid item xs={6} md={2}>
          <NumberInputWithSymbol
            disabled={!canEditField("maxTargetNetMOIC")}
            label="Max Target Net MOIC"
            source="maxTargetNetMOIC"
            symbol="x"
            sx={
              autoPopulatedFields.includes("maxTargetNetMOIC")
                ? LLMBGColor__AutoComplete
                : undefined
            }
          />
        </Grid>
        <Grid item xs={6} md={2}>
          <FormattedNumberInput
            disabled={!canEditField("targetRaise")}
            source="targetRaise"
            variant="outlined"
            label="Target Size"
            fullWidth={false}
            FormattedComponent={CommaFormattedNumber}
            sx={
              autoPopulatedFields.includes("targetRaise")
                ? LLMBGColor
                : undefined
            }
          />

          <FormattedNumberInput
            disabled={!canEditField("hardCap")}
            source="hardCap"
            variant="outlined"
            label="Fund Hard Cap"
            fullWidth={false}
            FormattedComponent={CommaFormattedNumber}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <FormattedNumberInput
            disabled={!canEditField("committedCapital")}
            source="committedCapital"
            variant="outlined"
            label="Current Fund Size"
            fullWidth={false}
            FormattedComponent={CommaFormattedNumber}
            sx={
              autoPopulatedFields.includes("committedCapital")
                ? LLMBGColor
                : undefined
            }
          />

          <ReferenceInput
            source="committedCapitalCurrencyId"
            reference="currencyDenominationEnum"
            sort={{ field: "id", order: "ASC" }}
            perPage={500}
            fullWidth={false}
            defaultValue={1}
            helperText=""
          >
            <SelectInput
              disabled={!canEditField("committedCapitalCurrencyId")}
              optionText="name"
              helperText=""
              label=""
              sx={
                autoPopulatedFields.includes("committedCapitalCurrencyId")
                  ? LLMBGColor
                  : undefined
              }
            />
          </ReferenceInput>
        </Grid>

        <Grid item xs={6} md={3}>
          <NumberInputWithSymbol
            disabled={!canEditField("peLegalLifeYrs")}
            label="Legal Life"
            source="peLegalLifeYrs"
            symbol="years"
            validate={validateWholeNumber}
            sx={
              autoPopulatedFields.includes("peLegalLifeYrs")
                ? LLMBGColor__AutoComplete
                : undefined
            }
          />
        </Grid>
        <Grid item xs={6} md={2}>
          <NumberInputWithSymbol
            disabled={!canEditField("minTargetNetIRR")}
            label="Min Target Net IRR"
            source="minTargetNetIRR"
            symbol="%"
            //TODO Format and parse don't exist in destination component... Bug?
            sx={
              autoPopulatedFields.includes("minTargetNetIRR")
                ? LLMBGColor__AutoComplete
                : undefined
            }
          />
        </Grid>
        <Grid item xs={6} md={2}>
          <NumberInputWithSymbol
            disabled={!canEditField("minTargetNetMOIC")}
            label="Min Target Net MOIC"
            source="minTargetNetMOIC"
            symbol="x"
            sx={
              autoPopulatedFields.includes("minTargetNetMOIC")
                ? LLMBGColor__AutoComplete
                : undefined
            }
          />
        </Grid>
        <Grid item xs={6} md={5} alignItems="center">
          <FormattedNumberInput
            disabled={!canEditField("gpCommitted")}
            label="GP Committed"
            source="gpCommitted"
            fullWidth={false}
            variant="outlined"
            FormattedComponent={CommaFormattedNumber}
            sx={
              autoPopulatedFields.includes("gpCommitted")
                ? LLMBGColor
                : undefined
            }
          />
          <ReferenceInput
            source="gpCommittedCurrencyId"
            reference="currencyDenominationEnum"
            sort={{ field: "id", order: "ASC" }}
            perPage={500}
            fullWidth={false}
            defaultValue={1}
            helperText=""
          >
            <SelectInput
              disabled={!canEditField("gpCommittedCurrencyId")}
              optionText="name"
              helperText=""
              label=""
              sx={
                autoPopulatedFields.includes("gpCommittedCurrencyId")
                  ? LLMBGColor
                  : undefined
              }
            />
          </ReferenceInput>
        </Grid>
      </Grid>
      {edit && (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <hr />
            <h4>Close Dates</h4>
          </Grid>
          <Grid></Grid>

          {record && (
            <Grid>
              <ReferenceManyField
                label="Close Dates"
                reference="closeDate"
                target="investmentId"
                pagination={<Pagination />}
              >
                <Datagrid bulkActionButtons={false}>
                  <TextField label="Close Date" source="closeDate" />
                  <TextField label="Size" source="fundSize" />
                </Datagrid>
              </ReferenceManyField>
              <Button
                component={Link}
                to={{
                  pathname: `/closedate/${record.id}`,
                }}
                label="Go to Close Date"
                data-id="editCloseDate"
              />
            </Grid>
          )}
        </Grid>
      )}
    </>
  );
};
