import * as client from "_graphql-types";
import * as types from "./types";

import { filterSortPage } from "../DataProvider";

type Sdk = ReturnType<typeof client.getSdk>;

/*********************************************************************/
// Data Provider Functions
/*********************************************************************/

/*********************************************************************/
async function getList(
  sdk: Sdk,
  params: types.GetListParams<client.GeographyEnumSortEnum>
) {
  const { pagination, sort, filter } = params;

  const {
    geographyEnumList: { items: data, total },
  } = await sdk.getListGeographyEnum(
    filterSortPage({
      filter,
      sort,
      pagination,
    })
  );

  return {
    data,
    total,
  };
}

/*********************************************************************/
async function getOne(sdk: Sdk, params: types.GetOneParams) {
  const id = Number(params.id);
  const { geographyEnum: data } = await sdk.getOneGeographyEnum({
    id,
  });
  return {
    data,
  };
}

/*********************************************************************/
async function getMany(sdk: Sdk, params: types.GetManyParams) {
  const ids = params.ids.map(id => Number(id));

  const { geographyEnumMany: data } = await sdk.getManyGeographyEnum({
    ids,
  });

  return {
    data,
  };
}

/*********************************************************************/
export const geographyEnum = types.dataProvider({
  getList,
  getOne,
  getMany,
  ttl: types.defaultEnumTtl,
});
