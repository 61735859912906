import * as client from "_graphql-types";
import * as types from "./types";

import { filterSortPage } from "../DataProvider";
type Sdk = ReturnType<typeof client.getSdk>;

/*********************************************************************/
// Data Provider Functions
/*********************************************************************/

async function create(sdk: Sdk, params: types.CreateParams<any>) {
  const { data } = params;
  const input: client.MutationAddPortalServiceProviderFirmArgs["input"] = {
    organizationId: data.organization.id,
    firmId: data.firmId,
  };
  const {
    addPortalServiceProviderFirm: { id },
  } = await sdk.addPortalServiceProviderFirm({
    input,
  });
  return await getOne(sdk, { id });
}

/*********************************************************************/
async function getOne(sdk: Sdk, params: types.GetOneParams) {
  const { id } = params;

  const { portalServiceProviderFirm: data } =
    await sdk.getOnePortalServiceProviderFirm({
      id: Number(id),
    });
  return {
    data,
  };
}

/*********************************************************************/
async function getList(
  sdk: Sdk,
  params: types.GetListParams<client.PortalServiceProviderFirmSortEnum>
) {
  const { pagination, sort, filter } = params;

  const {
    portalServiceProviderFirmsList: { items: data, total },
  } = await sdk.getListPortalServiceProviderFirm(
    filterSortPage({ filter, sort, pagination })
  );

  return {
    data,
    total,
  };
}

/*********************************************************************/
async function getManyReference(
  sdk: Sdk,
  params: types.GetManyReferenceParams<client.PortalServiceProviderFirmSortEnum>
) {
  const id = String(params.id);
  const {
    pagination: { page, perPage },
    sort: { field, order },
    filter,
    target,
  } = params;

  const {
    portalServiceProviderFirmsList: { items: data, total },
  } = await sdk.getListPortalServiceProviderFirm({
    page: {
      offset: (page - 1) * perPage,
      limit: perPage,
    },
    sort: [{ field, order }],
    filter: { ...filter, [target]: id },
  });

  return {
    data,
    total,
  };
}

/*********************************************************************/
async function deleteServiceProvider(sdk: Sdk, params: { id: string }) {
  const id = Number(params.id);

  await sdk.deletePortalServiceProviderFirm({
    id,
  });

  return { data: { id } };
}

/*********************************************************************/
export const portalServiceProviderFirms = types.dataProvider({
  create,
  getOne,
  getList,
  getManyReference,
  delete: deleteServiceProvider,
});
