import { Box } from "@mui/material";
import Grid from "@mui/material/Grid";
import React from "react";
import { NullableBooleanInput, ReferenceInput, SelectInput } from "react-admin";
import { Record } from "./Edit";
import { DomicileLocationInput } from "./Form/DomicileLinkInput";
import * as client from "_graphql-types";

export interface RAProps {
  className: string;
  fullWidth: boolean;
  id?: string;
  resource: string;
  variant: "filled" | "standard" | "outlined";
  margin: "none" | "dense" | "normal";
  record: Record;
}

export function FundStructure({
  canEditField,
}: {
  canEditField: (field: keyof client.InvestmentInput) => boolean;
}) {
  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={8} sm={6} lg={3}>
          <ReferenceInput
            source="legalStructureEnumId"
            reference="legalStructureEnum"
          >
            <SelectInput
              readOnly={!canEditField("legalStructureEnumId")}
              optionText="name"
              label="Legal Structure"
            />
          </ReferenceInput>
        </Grid>
        <Grid item xs={8} sm={6} lg={3}>
          <NullableBooleanInput
            readOnly={!canEditField("isOffshore")}
            label="Legal Domicile"
            source="isOffshore"
            falseLabel="U.S"
            trueLabel="Offshore"
            fullWidth
          />
        </Grid>
        <Grid item xs={8} sm={6} lg={3}>
          <DomicileLocationInput canEditField={canEditField} />
        </Grid>
        <Grid item xs={8} sm={6} lg={3}>
          <ReferenceInput
            source="organizationStructureEnumId"
            reference="organizationStructureEnum"
          >
            <SelectInput
              readOnly={!canEditField("organizationStructureEnumId")}
              optionText="name"
              label="Organization Structure"
            />
          </ReferenceInput>
        </Grid>
        <Grid item xs={8} sm={6} lg={3}>
          <ReferenceInput
            source="currencyDenominationEnumId"
            reference="currencyDenominationEnum"
            sort={{ field: "id", order: "ASC" }}
            perPage={500}
          >
            <SelectInput
              readOnly={!canEditField("currencyDenominationEnumId")}
              optionText="name"
              label="Currency Denomination"
            />
          </ReferenceInput>
        </Grid>
        <Grid item xs={8} sm={6} lg={3}>
          <ReferenceInput
            source="investmentStructureEnumId"
            reference="investmentStructureEnum"
          >
            <SelectInput
              readOnly={!canEditField("investmentStructureEnumId")}
              optionText="name"
              label="Investment Structure"
            />
          </ReferenceInput>
        </Grid>
        <Grid item xs={8} sm={6} lg={3}>
          <ReferenceInput
            source="NAVClassificationEnumId"
            reference="navClassificationEnum"
          >
            <SelectInput
              readOnly={!canEditField("NAVClassificationEnumId")}
              optionText="name"
              label="NAV Classification"
            />
          </ReferenceInput>
        </Grid>
        <Grid item xs={8} sm={6} lg={3}>
          <ReferenceInput
            source="pricingFrequencyEnumId"
            reference="pricingFrequencyEnum"
          >
            <SelectInput
              readOnly={!canEditField("pricingFrequencyEnumId")}
              optionText="name"
              label="Pricing Frequency"
            />
          </ReferenceInput>
        </Grid>
        <Grid item xs={10} sm={8} lg={4}>
          <NullableBooleanInput
            readOnly={!canEditField("isRegisteredInvestmentCompany")}
            label="Registered Investment Company (40 Act)"
            source="isRegisteredInvestmentCompany"
            falseLabel="No"
            trueLabel="Yes"
            fullWidth
          />
        </Grid>
      </Grid>
    </Box>
  );
}
