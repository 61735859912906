import { PerformanceMultiInvestmentEntry } from "_resources/performanceMultiInvestment";
import { isDefinedAndNotNull } from "frontend/src/utils/helpers";
import React from "react";
import { yearToDate } from "../performance/helpers";

interface YTDColumnProps {
  performanceData: Map<number, PerformanceMultiInvestmentEntry>;
  values: Map<number, string>;
  investmentId: number;
  placeholder?: string;
}

function _yearToDate({
  performanceFromDb,
  valueFromForm,
}: {
  performanceFromDb: PerformanceMultiInvestmentEntry;
  valueFromForm?: string;
}): number | undefined {
  const valueFromDb = performanceFromDb.returnValue;
  const periodYearReturns = performanceFromDb.periodYearReturns ?? [];

  if (isDefinedAndNotNull(valueFromDb) && !valueFromForm) {
    return yearToDate(periodYearReturns ?? []);
  }

  if (isDefinedAndNotNull(valueFromDb) && valueFromForm) {
    const [_periodReturn, ...restOfYearReturns] = periodYearReturns;
    return yearToDate([...restOfYearReturns, Number(valueFromForm)]);
  }

  if (!isDefinedAndNotNull(valueFromDb) && valueFromForm) {
    return yearToDate([...periodYearReturns, Number(valueFromForm)]);
  }

  return;
}

export default function YTDColumn({
  performanceData,
  values,
  investmentId,
  placeholder = "-",
}: YTDColumnProps) {
  const performanceFromDb = performanceData.get(investmentId);
  const valueFromForm = values.get(investmentId);

  if (!performanceFromDb) {
    return (
      <p>{valueFromForm ? Number(valueFromForm).toFixed(2) : placeholder}</p>
    );
  }

  const ytd = _yearToDate({ performanceFromDb, valueFromForm });
  return <p>{ytd ? ytd.toFixed(2) : placeholder}</p>;
}
