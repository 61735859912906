import { Chip } from "@mui/material";
import * as _client from "_graphql-types";
import { startCase } from "lodash";
import {
  Datagrid,
  EditButton,
  FunctionField,
  List,
  TextField,
} from "react-admin";
import Filter from "./Filter";
type Record = _client.GetOneCustomAnalyticsQuery["customAnalytics"];

export const CustomAnalyticsList = (props: any) => {
  return (
    <List
      sx={{
        ".RaFilter-form": {
          flex: "0 1 50%",
        },
      }}
      filters={<Filter />}
      sort={{ field: "asOfDate", order: "ASC" }}
      {...props}
    >
      <Datagrid
        data-cy="customAnalytics__list"
        rowClick="edit"
        bulkActionButtons={false}
      >
        <TextField {...props} label="Owner ID" source="investment.id" />
        <TextField {...props} label="Owner" source="investment.name" />
        <FunctionField
          {...props}
          label="Owner Type"
          source="ownerType"
          render={(record?: Record) => (
            <Chip label={startCase(record?.ownerType)} />
          )}
        />
        <FunctionField<Record>
          {...props}
          label="As Of Date"
          source="asOfDate"
          render={record => record?.asOfDate?.substring(0, 10)}
        />
        <FunctionField<Record>
          {...props}
          data-cy="customAnalytics__name"
          label="Title"
          source="value"
          render={record => record?.value?.title?.text}
        />
        <EditButton />
      </Datagrid>
    </List>
  );
};
