import * as client from "_graphql-types";
import * as types from "./types";

type Sdk = ReturnType<typeof client.getSdk>;

/*********************************************************************/
// Data Provider Functions
/*********************************************************************/

/*********************************************************************/
async function getOne(sdk: Sdk, params: types.GetOneParams) {
  const id = Number(params.id);
  const { investment: data } = await sdk.getOneDataLinks({
    id,
  });
  return {
    data,
  };
}

/*********************************************************************/
async function update(
  sdk: Sdk,
  params: types.UpdateParams<{
    cogencyLink: client.CogencyLinkInput;
    // add input params for bloomberg ticker updates
  }>
) {
  const id = Number(params.id);
  const { data } = params;

  const cogencyLink = types.required(data, "cogencyLink");
  cogencyLink.cogFinal = true;
  cogencyLink.cogFirstMonthAdjust = false;

  // add code to update bloomberg ticker here

  console.log("cogencyLink", cogencyLink);

  await sdk.addOrUpdateCogencyLink({
    id,
    input: cogencyLink,
  });
  return await getOne(sdk, { id });
}

/*********************************************************************/
export const dataLinks = types.dataProvider({
  getOne,
  update,
});
