import * as client from "_graphql-types";
import * as types from "./types";

type Sdk = ReturnType<typeof client.getSdk>;

/*********************************************************************/
// Data Provider Functions
/*********************************************************************/
/*********************************************************************/

async function getList(
  sdk: Sdk,
  params: types.GetListParams<client.RiskRefreshListSortEnum>
) {
  // a dummy so it is displayed in the navigation
  return { data: [], total: 0 };
}

/*********************************************************************/
async function getOne(sdk: Sdk, params: types.GetOneParams) {
  const id = params.id;

  const { riskRefreshList: data } = await sdk.riskRefreshList();

  return {
    data: { ...data, id: "RockCreek" },
  };
}

/*********************************************************************/
async function update(sdk: Sdk, params: any) {
  await sdk.updateRiskRefreshList({
    add: params.data.items
      .filter((i: any) => i.action === "add")
      .map(({ id }: any) => Number(id)),
    remove: params.data.items
      .filter((i: any) => i.action === "remove")
      .map(({ id }: any) => Number(id)),
  });

  const data = {
    ...params.data,
    items: params.data.items.map((x: any) => ({ ...x, action: undefined })),
    id: "RockCreek",
  };

  setTimeout(() => window.location.reload(), 1000);
  return { data };
}

/*********************************************************************/
export const riskRefreshList = types.dataProvider({
  getList,
  getOne,
  update,
});
