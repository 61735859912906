import * as client from "_graphql-types";
import * as types from "./types";

type Sdk = ReturnType<typeof client.getSdk>;

/*********************************************************************/
// Data Provider Functions
/*********************************************************************/

/*********************************************************************/
async function getList(
  sdk: Sdk,
  params: types.GetListParams<client.PortfolioFdpLoginsSortEnum>
) {
  const {
    pagination: { page, perPage },
    sort: { field, order },
    filter,
  } = params;

  const {
    portfolioFDPLoginsList: { items: data, total },
  } = await sdk.getListPortfolioFDPLogins({
    page: {
      offset: (page - 1) * perPage,
      limit: perPage,
    },
    sort: [{ field, order }],
    filter,
  });

  return {
    data,
    total,
  };
}

/*********************************************************************/
async function getOne(sdk: Sdk, params: types.GetOneParams) {
  const id = Number(params.id);
  const { portfolioFDPLogins: data } = await sdk.getOnePortfolioFDPLogins({
    id,
  });
  return {
    data,
  };
}

/*********************************************************************/
async function getMany(sdk: Sdk, params: types.GetManyParams) {
  const ids = params.ids.map(id => Number(id));

  const { portfolioFDPLoginsMany: data } = await sdk.getManyPortfolioFDPLogins({
    ids,
  });

  return {
    data,
  };
}

/*********************************************************************/
async function getManyReference(
  sdk: Sdk,
  params: types.GetManyReferenceParams<client.PortfolioFdpLoginsSortEnum>
) {
  const id = params.id;
  const {
    pagination: { page, perPage },
    sort: { field, order },
    filter,
    target,
  } = params;

  const {
    portfolioFDPLoginsList: { items: data, total },
  } = await sdk.getListPortfolioFDPLogins({
    page: {
      offset: (page - 1) * perPage,
      limit: perPage,
    },
    sort: [{ field, order }],
    filter: { ...filter, [target]: id },
  });

  return {
    data,
    total,
  };
}

/*********************************************************************/
async function create(
  sdk: Sdk,
  params: types.CreateParams<
    NonNullable<client.GetOnePortfolioFdpLoginsQuery["portfolioFDPLogins"]>
  >
) {
  const { data } = params;

  const input: client.CreatePortfolioFdpLoginsMutationVariables["input"] = {
    portfolioId: data.portfolioId ?? data.portfolio?.id,
    fdpLoginId: data.fdpLoginId,
    fdpWebsiteId: data.fdpWebsiteId,
  };

  const {
    addPortfolioFDPLogins: { id },
  } = await sdk.createPortfolioFDPLogins({
    input,
  });
  return await getOne(sdk, { id: id! });
}

/*********************************************************************/
async function update(
  sdk: Sdk,
  params: types.UpdateParams<
    NonNullable<client.GetOnePortfolioFdpLoginsQuery["portfolioFDPLogins"]>
  >
) {
  const id = Number(params.id);
  const { data } = params;

  const input: client.UpdatePortfolioFdpLoginsMutationVariables["input"] = {
    portfolioId: data.portfolioId ?? data.portfolio?.id,
    fdpLoginId: data.fdpLoginId,
    fdpWebsiteId: data.fdpWebsiteId,
  };

  await sdk.updatePortfolioFDPLogins({
    id,
    input,
  });
  return await getOne(sdk, { id });
}

/*********************************************************************/
export const portfolioFDPLogins = types.dataProvider({
  getList,
  getOne,
  getMany,
  getManyReference,
  create,
  update,
});
