import * as client from "_graphql-types";
import * as types from "./types";

import { filterSortPage } from "../DataProvider";

type Sdk = ReturnType<typeof client.getSdk>;

/*********************************************************************/
// Data Provider Functions
/*********************************************************************/

/*********************************************************************/
export async function getList(
  sdk: Sdk,
  params: types.GetListParams<client.PortalOrganizationSortEnum>
) {
  const { pagination, sort, filter } = params;

  const {
    portalOrganizationList: { items: data, total },
  } = await sdk.getListPortalOrganization(
    filterSortPage({ filter, sort, pagination })
  );

  return {
    data,
    total,
  };
}

/*********************************************************************/
async function getOne(sdk: Sdk, params: types.GetOneParams) {
  const { portalOrganization: data } = await sdk.getOnePortalOrganization({
    id: String(params.id),
  });
  return {
    data,
  };
}

/*********************************************************************/
async function getMany(sdk: Sdk, params: types.GetManyParams) {
  const ids = params.ids.map(id => String(id));

  const { portalOrganizationMany: data } = await sdk.getManyPortalOrganization({
    ids,
  });

  return {
    data,
  };
}

/*********************************************************************/
async function getManyReference(
  sdk: Sdk,
  params: types.GetManyReferenceParams<client.PortalOrganizationSortEnum>
) {
  const id = String(params.id);
  const {
    pagination: { page, perPage },
    sort: { field, order },
    filter,
    target,
  } = params;

  const {
    portalOrganizationList: { items: data, total },
  } = await sdk.getListPortalOrganization({
    page: {
      offset: (page - 1) * perPage,
      limit: perPage,
    },
    sort: [{ field, order }],
    filter: { ...filter, [target]: id },
  });

  return {
    data,
    total,
  };
}

/*********************************************************************/
async function update(
  sdk: Sdk,
  params: types.UpdateParams<
    NonNullable<client.GetOnePortalOrganizationQuery["portalOrganization"]>
  >
): types.UpdateOut<any> {
  const id = String(params.id);
  const { data } = params;

  const input: client.UpdatePortalOrganizationMutationVariables["input"] = {
    name: data.name,
    emailDomainMappings: data.emailDomainMappings
      ? data.emailDomainMappings.map(({ emailDomain }) => ({
          emailDomain,
          organizationId: id,
        }))
      : [],
    portalServiceProviderFirms: data.portalServiceProviderFirms
      ? data.portalServiceProviderFirms.map(({ firmId, organizationId }) => ({
          firmId,
          organizationId,
        }))
      : [],
    allowMultiFirm: data.allowMultiFirm,
  };

  await sdk.updatePortalOrganization({
    id,
    input,
  });

  return await getOne(sdk, { id });
}

async function softDelete(sdk: Sdk, params: types.DeleteParams) {
  const id = String(params.id);

  await sdk.deletePortalOrganization({ id });

  return {
    data: { id },
  };
}

/*********************************************************************/
async function create(
  sdk: Sdk,
  { data }: types.CreateParams<client.PortalOrganizationCreateInput>
) {
  const input: client.AddPortalOrganizationMutationVariables["input"] = {
    name: types.required(data, "name"),
  };

  const {
    addPortalOrganization: { id },
  } = await sdk.addPortalOrganization({
    input,
  });
  return await getOne(sdk, { id: id! });
}

/*********************************************************************/
export const portalOrganization = types.dataProvider({
  getList,
  getOne,
  getMany,
  getManyReference,
  update,
  create,
  delete: softDelete,
});
