import {
  CreateButton,
  Datagrid,
  DateField,
  EditButton,
  ExportButton,
  List,
  SortButton,
  TextField,
  TopToolbar,
} from "react-admin";
import { InvestmentInstitutionsFilter } from "./Filter";

export const InvestmentInstitutionsListDataGrid = () => {
  return (
    <Datagrid rowClick="edit" bulkActionButtons={false}>
      <TextField label="Id" source="id" sortable={false} />
      <TextField
        label="Investment Name"
        source="investment.name"
        sortable={false}
      />
      <TextField
        label="SP Firm Name"
        source="institution.globalProviderInstitution.provider.name"
        sortable={false}
      />
      <TextField
        label="Office Location Name"
        source="institution.name"
        sortable={false}
      />
      <DateField label="Start Date" source="startDate" sortable={false} />
      <DateField label="End Date" source="endDate" sortable={false} />
      <TextField
        label="Service Type"
        sortable={false}
        source="officeLocationServiceType.institutionTypeEnum.name"
      />
      <EditButton />
    </Datagrid>
  );
};

export const InvestmentInstitutionsList = () => {
  return (
    <List
      sx={{
        ".RaFilter-form": {
          flex: "0 1 50%",
        },
      }}
      filters={<InvestmentInstitutionsFilter />}
      actions={
        <TopToolbar>
          <SortButton fields={["name"]} />
          <CreateButton />
          <ExportButton maxResults={250}/>
        </TopToolbar>
      }
      sort={{ field: "name", order: "ASC" }}
    >
      <InvestmentInstitutionsListDataGrid />
    </List>
  );
};
