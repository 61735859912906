import * as client from "_graphql-types";
import { filterSortPage } from "../DataProvider";
import * as types from "./types";

type Sdk = ReturnType<typeof client.getSdk>;

/*********************************************************************/
// Data Provider Functions
/*********************************************************************/

/*********************************************************************/
export async function getList(
  sdk: Sdk,
  params: types.GetListParams<client.InvestmentSortEnum>
) {
  const { sort, filter } = params;

  const { investmentBulk: data } = await sdk.getBulkInvestment(
    filterSortPage({
      filter,
      sort,
    })
  );

  return {
    data,
    total: data.length,
  };
}

/*********************************************************************/
export const investmentBulk = types.dataProvider({
  getList,
});
