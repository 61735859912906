import * as client from "_graphql-types";
import { filterSortPage } from "../DataProvider";
import * as types from "./types";

// import {addDays} from "date-fns";

type Sdk = ReturnType<typeof client.getSdk>;

/*********************************************************************/
// Data Provider Functions
/*********************************************************************/

/*********************************************************************/
export async function getList(
  sdk: Sdk,
  params: types.GetListParams<client.SchoolEnumSortEnum>
) {
  const { pagination, sort, filter } = params;

  const {
    schoolEnumList: { items: data, total },
  } = await sdk.getListSchoolEnum(filterSortPage({ filter, sort, pagination }));

  return {
    data,
    total,
  };
}

/*********************************************************************/
async function getOne(sdk: Sdk, params: types.GetOneParams) {
  const id = Number(params.id);
  const { schoolEnum: data } = await sdk.getOneSchoolEnum({
    id,
  });
  return {
    data,
  };
}

/*********************************************************************/
async function getMany(sdk: Sdk, params: types.GetManyParams) {
  const ids = params.ids.map(id => Number(id));

  const { schoolEnumMany: data } = await sdk.getManySchoolEnum({
    ids,
  });

  return {
    data,
  };
}

/*********************************************************************/
export const schoolEnum = types.dataProvider({
  getList,
  getOne,
});
