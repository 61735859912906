import * as client from "_graphql-types";

import Grid from "@mui/material/Grid";
import { Button, Link, useRecordContext } from "react-admin";
import { CustomCreate } from "../CustomCreate";

import { useSearchParams } from "react-router-dom";
import { useCanAccessMutation } from "../../util/useCanAccessMutation";
import { EntityInput } from "../UI/EntityInput";

import { FDPLoginInput } from "../firmFDPLogins/FDPLoginInput";

type Record = NonNullable<
  client.GetOneInvestmentFdpLoginsQuery["investmentFDPLogins"]
>;

function getInvestmentId(search: URLSearchParams) {
  const source = search.get("source");
  const { investment } = JSON.parse(source ?? "{}");
  return investment?.id;
}

const ViewInvestmentButton = () => {
  const record = useRecordContext<Record>();
  if (record?.investment) {
    return (
      <Button
        component={Link}
        to={{
          pathname: `/investment/${record.investment.id}`,
        }}
        label="Go to Investment"
      />
    );
  }
  return null;
};

export const InvestmentFdpLoginsCreate = () => {
  const mutationArgs = JSON.stringify({});
  const [search] = useSearchParams();

  const { canEdit, canEditField, loading } = useCanAccessMutation(
    "addInvestmentFDPLogins",
    mutationArgs
  );

  const investmentId = getInvestmentId(search);

  return (
    <CustomCreate
      title={() => "Link FDPLogin to Investment"}
      customFormProps={{
        customToolbarProps: {
          canAccessMutation: canEdit,
          redirectToPathOnSave: `/investment/${investmentId}`,
        },
        loading,
        canEditField,
      }}
    >
      <>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <ViewInvestmentButton />
          </Grid>

          <Grid item xs={6}>
            <EntityInput<NonNullable<Record>>
              resource="investment"
              recordKey="investment"
              label="Investment"
              isRequired={true}
              sort={{ field: "nameSearchRank", order: "ASC" }}
            />
          </Grid>

          <Grid item xs={6}>
            <FDPLoginInput isRequired={true} />
          </Grid>
        </Grid>
      </>
    </CustomCreate>
  );
};
