import { Datagrid, EditButton, List, TextField } from "react-admin";
import React from "react";
import { OrganizationFilter } from "./Filter";
import { ListActions } from "./ListActions";

export const OrganizationList = (props: any) => {
  return (
    <List
      sx={{
        ".RaFilter-form": {
          flex: "0 1 50%",
        },
      }}
      {...props}
      filters={<OrganizationFilter />}
      sort={{ field: "nameSearchRank", order: "ASC" }}
      actions={<ListActions />}
      data-cy="organization__list"
    >
      <Datagrid rowClick="edit" bulkActionButtons={false}>
        <TextField data-cy="organization__list_ID" label="ID" source="id" />
        <TextField
          data-cy="organization__list_Name"
          label="Name"
          source="name"
        />
        <EditButton />
      </Datagrid>
    </List>
  );
};
