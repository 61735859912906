import * as client from "_graphql-types";
import {
  EditableDatagridProps,
  RowFormConfigurableProps,
  RowFormProps,
  RowForm,
  EditableDatagrid,
} from "@react-admin/ra-editable-datagrid";
import {
  TextField,
  FunctionField,
  required,
  TextInput,
  PasswordInput,
  Labeled,
  WrapperField,
  SelectInput,
  ReferenceInput,
  ReferenceField,
  ImageInput,
  ImageField,
  useRecordContext,
  DateField,
} from "react-admin";
import { FCField_RenderTextWithConvenienceCopy } from "../UI/CopyField";
import { PasswordField } from "../UI/PasswordField";
import { Stack } from "@mui/system";
import { useCanAccessMutation } from "../../util/useCanAccessMutation";
import config from "../../config";
import { startCase } from "lodash";
import { FDPEditCell, FDPShowCell } from "./FDPDataGridColumns";
import { Relationships } from "./EntityRelationshipColumns";

type Record = NonNullable<client.GetOneFdpLoginQuery["fdpLogin"]>;

const validateRequired = [required()];

const AuthenticatorImageCell = ({ isEdit }: { isEdit: boolean }) => {
  const record = useRecordContext<Record>();

  return (
    <>
      {isEdit && (
        <ImageInput
          source="authenticatorImage"
          label="Auth Image"
          accept={{ "image/*": [] }}
          maxSize={5000000}
        >
          <ImageField source="src" />
        </ImageInput>
      )}
      {record?.imageLink && (
        <img
          data-id="auth-image-display"
          src={`${config
            .GRAPHQL_API()
            .substring(0, config.GRAPHQL_API().length - 8)}${
            record?.imageLink
          }?${Date.now()}`} // hackish workaround to force image refresh on sumbit by adding bogus query param to url
          style={{ maxWidth: "40%" }}
        />
      )}
    </>
  );
};

/**
 * Edit and Create RowForms for FDP Logins
 * For use within the EditableDatagridConfigurable
 */
const FDPLoginForm = ({
  canEditField,
  referenceKey,
  defaultValues,
  relationships,
  relationColLabel,
  allowFDPChange = true,
}: {
  canEditField: (key: string) => boolean;
  referenceKey: string;
  defaultValues?: RowFormConfigurableProps["defaultValues"];
  relationships: string[];
  relationColLabel: string;
  allowFDPChange?: boolean;
}) => {
  return (
    <RowForm
      defaultValues={defaultValues}
      data-cy={`fdpLogin-rowForm__${referenceKey}`}
    >
      <WrapperField source="username_password" label="Username / Password">
        <Stack direction="column" spacing={2}>
          <TextInput
            source="username"
            disabled={!canEditField("username")}
            validate={validateRequired}
            size="small"
          />
          <PasswordInput
            source="password"
            disabled={!canEditField("password")}
            validate={validateRequired}
            size="small"
          />
        </Stack>
      </WrapperField>

      <WrapperField source="email_mfaUser" label="Email / 2FA User">
        <Stack direction="column" spacing={2}>
          <TextInput
            source="email"
            disabled={!canEditField("email")}
            size="small"
          />
          <TextInput
            label="2FA User"
            source="mfaUser"
            disabled={!canEditField("mfaUser")}
            size="small"
          />
        </Stack>
      </WrapperField>
      <WrapperField source="fdpName_fdpWebsite" label="Financial Data Provider">
        <FDPEditCell allowFDPChange={allowFDPChange} />
      </WrapperField>
      <WrapperField
        source="authenticatorEnumId_authenticatorImage"
        label="Authenticator / Auth Image"
      >
        <Stack direction="column" spacing={2}>
          <ReferenceInput
            reference="fdpLoginAuthenticatorEnum"
            sort={{ field: "id", order: "ASC" }}
            source="authenticatorEnumId"
          >
            <SelectInput
              optionText="name"
              label="Authenticator"
              disabled={!canEditField("authenticatorEnumId")}
            />
          </ReferenceInput>
          <AuthenticatorImageCell isEdit={canEditField("authenticatorImage")} />
        </Stack>
      </WrapperField>
      <WrapperField
        source="securityQuestion_notes"
        label="Security Question / Notes"
      >
        <Stack direction="column" spacing={2}>
          <TextInput
            multiline
            source="notes"
            disabled={!canEditField("notes")}
            sx={{ width: 300 }}
          />

          <TextInput
            multiline
            source="securityQuestion"
            disabled={!canEditField("securityQuestion")}
          />
        </Stack>
      </WrapperField>
      <WrapperField source="auditLogs" label="Modifed By / Date">
        <Stack direction="column" spacing={2}>
          <Labeled label="Modified By">
            <TextField source="modifyUser" />
          </Labeled>
          <Labeled label="Modified Date">
            <DateField source="modifyDate" />
          </Labeled>
        </Stack>
      </WrapperField>
      <WrapperField source="relationships" label={relationColLabel}>
        <Relationships includedCols={relationships} />
      </WrapperField>
    </RowForm>
  );
};

/**
 * Editable data grid for FDP Logins, used on the FDP Login list page as well as the Firm and Investment edit pages
 * Handles the add, edit, and delete of FDP Logins and calls useCanAccessMutation accordingly
 * When used within a ReferenceManyField use the defaultRowValues prop
 * (eg. defaultRowValues={{ firms: [{ id: Number(recordId) }] }})
 * so the firm or investment is prepopulated on in-line create
 * and the entityContext/entityId props to determine which fdp website to show
 */
export const FDPEditableDataGrid = ({
  referenceKey,
  size = "small",
  bulkActionButtons,
  rowClick,
  noDelete = true,
  defaultRowValues,
  mutationMode = "pessimistic",
  actions,
  relationships = ["firms", "investments", "portfolios"],
  allowFDPChange = true,
  entityContext,
  entity,
}: {
  referenceKey: string;
  defaultRowValues?: RowFormProps["defaultValues"];
  relationships?: string[];
  allowFDPChange?: boolean;
  entityContext?: string;
  entity?: { id: number; firmId?: number | null };
} & Pick<
  EditableDatagridProps,
  | "size"
  | "bulkActionButtons"
  | "rowClick"
  | "noDelete"
  | "mutationMode"
  | "actions"
>) => {
  const mutationArgs = JSON.stringify({});

  const {
    canEdit: canEdit_add,
    canEditField: canEditField_add,
    loading: loading_add,
  } = useCanAccessMutation("addFdpLogin", mutationArgs);

  const {
    canEdit: canEdit_update,
    canEditField: canEditField_update,
    loading: loading_update,
  } = useCanAccessMutation("updateFdpLogin", mutationArgs);

  function _canEditField_Edit(key: string) {
    return !!(canEdit_update && canEditField_update[key]);
  }
  function _canEditField_Create(key: string) {
    return !!(canEdit_add && canEditField_add[key]);
  }

  const relationshipsCol_label = relationships
    .map(col => startCase(col))
    .join(" / ");

  return (
    <EditableDatagrid
      actions={actions}
      mutationMode={mutationMode}
      data-cy={referenceKey}
      size={size}
      editForm={
        <FDPLoginForm
          canEditField={_canEditField_Edit}
          referenceKey={referenceKey}
          defaultValues={defaultRowValues}
          relationships={relationships}
          relationColLabel={relationshipsCol_label}
          allowFDPChange={allowFDPChange}
        />
      }
      noDelete={noDelete}
      isLoading={!!(loading_add && loading_update)}
      bulkActionButtons={bulkActionButtons}
      createForm={
        <FDPLoginForm
          canEditField={_canEditField_Create}
          referenceKey={referenceKey}
          defaultValues={defaultRowValues}
          relationships={relationships}
          relationColLabel={relationshipsCol_label}
          allowFDPChange={allowFDPChange}
        />
      }
      rowClick={rowClick}
      sx={{
        "& .MuiTableRow-root": {
          verticalAlign: "top",
        },
        "& .RaDatagrid-rowCell": {
          width: "fit-content",
        },
        "& .MuiStack-root": {
          width: "fit-content",
        },
      }}
    >
      <WrapperField
        source="username_password"
        label="Username / Password"
        sortBy="username"
      >
        <Stack direction="column" spacing={2}>
          <Labeled label="Username">
            <FunctionField
              sortBy="username"
              source="username"
              render={(record: any) => (
                <FCField_RenderTextWithConvenienceCopy
                  record={record as Record}
                  source="username"
                />
              )}
            />
          </Labeled>
          <Labeled label="Password">
            <FunctionField
              sortable={false}
              source="password"
              render={(record: any) => (
                <PasswordField
                  record={record as Record}
                  source="password"
                  allowCopy={true}
                />
              )}
            />
          </Labeled>
        </Stack>
      </WrapperField>
      <WrapperField
        source="email_mfaUser"
        label="Email / 2FA User"
        sortBy="email"
      >
        <Stack direction="column" spacing={2}>
          <Labeled label="Email">
            <TextField source="email" />
          </Labeled>
          <Labeled label="2FA User">
            <TextField source="mfaUser" />
          </Labeled>
        </Stack>
      </WrapperField>
      <WrapperField
        source="fdpName_fdpWebsite"
        label="Financial Data Provider"
        sortBy="fdpName"
      >
        <FDPShowCell entityContext={entityContext} entity={entity} />
      </WrapperField>
      <WrapperField
        source="authenticatorEnumId_authenticatorImage"
        label="Authenticator / Auth Image"
        sortable={false}
      >
        <Stack direction="column" spacing={2}>
          <Labeled label="Authenticator">
            <ReferenceField
              source="authenticatorEnumId"
              reference="fdpLoginAuthenticatorEnum"
            >
              <TextField source="name" />
            </ReferenceField>
          </Labeled>
          <Labeled label="Auth Image">
            <AuthenticatorImageCell isEdit={false} />
          </Labeled>
        </Stack>
      </WrapperField>
      <WrapperField
        source="securityQuestion_notes"
        label="Security Question / Notes"
        sortable={false}
      >
        <Stack direction="column" spacing={2}>
          <Labeled label="Security Question">
            <TextField source="securityQuestion" sortable={false} />
          </Labeled>
          <Labeled label="Notes">
            <TextField source="notes" sortable={false} />
          </Labeled>
        </Stack>
      </WrapperField>
      <WrapperField
        sortable={false}
        source="auditLogs"
        label="Modifed By / Date"
      >
        <Stack direction="column" spacing={2}>
          <Labeled label="Modified By">
            <TextField source="modifyUser" />
          </Labeled>
          <Labeled label="Modified Date">
            <DateField source="modifyDate" />
          </Labeled>
        </Stack>
      </WrapperField>
      <WrapperField
        source="relationships"
        label={relationshipsCol_label}
        sortable={false}
      >
        <Relationships isShow includedCols={relationships} />
      </WrapperField>
    </EditableDatagrid>
  );
};
