import * as client from "_graphql-types";
import { FunctionField, Labeled } from "react-admin";
import { EntityInputMany } from "../UI/EntityInputMany";
import { Stack } from "@mui/system";

import { ChipList } from "../UI/ChipList";

import { useWatch } from "react-hook-form";

type Record = NonNullable<client.GetOneFdpLoginQuery["fdpLogin"]>;

const InvestmentsCellEdit = () => {
  const firms: Record["firms"] = useWatch({
    name: "firms",
  });
  const portfolios: Record["portfolios"] = useWatch({
    name: "portfolios",
  });
  return (
    <EntityInputMany<Record>
      label="Investments"
      resource="investment"
      recordKey="investments"
      name="investments"
      style={{ width: 250 }}
      filters={{
        firmIds: firms?.length
          ? firms.map(({ id }: { id: number }) => id)
          : undefined,
        portfolioFilters: {
          isPortfolio: false,
        },
      }}
      hidden={(!firms || !firms.length) && (!portfolios || !portfolios.length)}
      allowChange
    />
  );
};

const PortfolioCellEdit = () => {
  return (
    <EntityInputMany<Record>
      label="Portfolios"
      resource="investment"
      inputId="portfolio-entity-input-many"
      recordKey="portfolios"
      name="portfolios"
      style={{ width: 250 }}
      filters={{
        portfolioFilters: {
          isPortfolio: true,
        },
      }}
      allowChange
    />
  );
};

export const Relationships = ({
  includedCols,
  isShow = false,
}: {
  includedCols: string[];
  isShow?: boolean;
}) => {
  const firmField = isShow ? (
    <Labeled label="Firms">
      <FunctionField
        label="Firms"
        sortable={false}
        source="firms"
        render={(record: any) => (
          <ChipList record={(record as Record).firms || []} />
        )}
      />
    </Labeled>
  ) : (
    <EntityInputMany<Record>
      label="Firms"
      resource="firm"
      recordKey="firms"
      name="firms"
      style={{ width: 250 }}
      filters={{ includeCompanies: true }}
      allowChange
    />
  );
  const investmentField = isShow ? (
    <Labeled label="Investments">
      <FunctionField
        label="Investments"
        sortable={false}
        source="investments"
        render={(record: any) => (
          <ChipList record={(record as Record).investments || []} />
        )}
      />
    </Labeled>
  ) : (
    <InvestmentsCellEdit />
  );

  const portfolioField = isShow ? (
    <Labeled label="Portfolios">
      <FunctionField
        label="Portfolios"
        sortable={false}
        source="portfolios"
        render={(record: any) => (
          <ChipList record={(record as Record).portfolios || []} />
        )}
      />
    </Labeled>
  ) : (
    <PortfolioCellEdit />
  );

  const firmCol = includedCols.includes("firms") ? firmField : null;
  const investmentCol = includedCols.includes("investments")
    ? investmentField
    : null;
  const portfolioCol = includedCols.includes("portfolios")
    ? portfolioField
    : null;

  if (includedCols.length > 1) {
    return (
      <Stack direction="column" spacing={2}>
        {firmCol}
        {investmentCol}
        {portfolioCol}
      </Stack>
    );
  }
  return <>{firmCol || investmentCol || portfolioCol}</>;
};
