import * as client from "_graphql-types";
import * as types from "./types";

type Sdk = ReturnType<typeof client.getSdk>;

/*********************************************************************/
// Data Provider Functions
/*********************************************************************/

/*********************************************************************/
async function getList(
  sdk: Sdk,
  params: types.GetListParams<client.PeMgmtFeeCalcEnumSortEnum>
) {
  const {
    pagination: { page, perPage },
    sort: { field, order },
  } = params;

  const {
    peMgmtFeeCalcEnumList: { items: data, total },
  } = await sdk.getListPeMgmtFeeCalcEnum({
    page: {
      offset: (page - 1) * perPage,
      limit: perPage,
    },
    sort: [{ field, order }],
  });

  return {
    data,
    total,
  };
}

/*********************************************************************/
async function getOne(sdk: Sdk, params: types.GetOneParams) {
  const id = Number(params.id);
  const { peMgmtFeeCalcEnum: data } = await sdk.getOnePeMgmtFeeCalcEnum({
    id,
  });
  return {
    data,
  };
}

/*********************************************************************/
async function getMany(sdk: Sdk, params: types.GetManyParams) {
  const ids = params.ids.map(Number);

  const { peMgmtFeeCalcEnumMany: data } = await sdk.getManyPeMgmtFeeCalcEnum({
    ids,
  });

  return {
    data,
  };
}

/*********************************************************************/
export const peMgmtFeeCalcEnum = types.dataProvider({
  getList,
  getOne,
  getMany,
});
