import * as client from "_graphql-types";
import * as types from "./types";

import { addDays } from "date-fns";

type Sdk = ReturnType<typeof client.getSdk>;

/*********************************************************************/
// Data Provider Functions
/*********************************************************************/

/*********************************************************************/
async function getList(
  sdk: Sdk,
  params: types.GetListParams<client.ServiceProviderDocumentsSortEnum>
) {
  const {
    pagination: { page, perPage },
    sort: { field, order },
    filter,
  } = params;

  const {
    serviceProviderDocumentsList: { items: data, total },
  } = await sdk.getListServiceProviderDocuments({
    page: {
      offset: (page - 1) * perPage,
      limit: perPage,
    },
    sort: [{ field, order }],
    filter,
  });

  return {
    data,
    total,
    // one day from now
    validUntil: addDays(new Date(), 1),
  };
}

/*********************************************************************/
async function getOne(sdk: Sdk, params: types.GetOneParams) {
  const id = params.id;

  const { serviceProviderDocuments: data } =
    await sdk.getOneServiceProviderDocuments({
      id: Number(id),
    });

  return {
    data,
    // one day from now
    validUntil: addDays(new Date(), 1),
  };
}

/*********************************************************************/
async function getMany(sdk: Sdk, params: types.GetManyParams) {
  const ids = params.ids.map(Number);

  const { serviceProviderDocumentsMany: data } =
    await sdk.getManyServiceProviderDocuments({
      ids,
    });

  return {
    data,
    // one day from now
    validUntil: addDays(new Date(), 1),
  };
}

/*********************************************************************/
async function getManyReference(
  sdk: Sdk,
  params: types.GetManyReferenceParams<client.ServiceProviderDocumentsSortEnum>
) {
  const id = params.id;
  const {
    pagination: { page, perPage },
    sort: { field, order },
    filter,
    target,
  } = params;

  const {
    serviceProviderDocumentsList: { items: data, total },
  } = await sdk.getListServiceProviderDocuments({
    page: {
      offset: (page - 1) * perPage,
      limit: perPage,
    },
    sort: [{ field, order }],
    filter: { ...filter, [target]: id },
  });

  return {
    data,
    total,
    // one day from now
    validUntil: addDays(new Date(), 1),
  };
}

/*********************************************************************/
async function create(
  sdk: Sdk,
  params: types.CreateParams<
    client.ServiceProviderDocumentsInput & {
      serviceProviderFirm: { id: number; name: string };
    }
  >
) {
  const { data } = params;
  const input: client.CreateServiceProviderDocumentMutationVariables["input"] =
    {
      serviceProviderFirmId: data.serviceProviderFirm?.id,
      file: data.file?.rawFile,
      documentTypeId: data.documentTypeId,
      documentDate: data.documentDate,
    };

  const {
    addServiceProviderDocuments: { id },
  } = await sdk.createServiceProviderDocument({
    input,
  });
  return await getOne(sdk, { id: String(id) });
}

/*********************************************************************/
async function update(
  sdk: Sdk,
  params: types.UpdateParams<
    client.ServiceProviderDocumentsInput & {
      id: string | number;
      serviceProviderFirm: { id: number; name: string };
    }
  >
) {
  const { data, id } = params;
  const { name, documentTypeId, documentDate, serviceProviderFirm } = data;

  const input: client.UpdateServiceProviderDocumentMutationVariables["input"] =
    {
      name,
      documentTypeId,
      documentDate,
      serviceProviderFirmId: serviceProviderFirm?.id,
    };

  await sdk.updateServiceProviderDocument({
    id: Number(id),
    input,
  });
  return await getOne(sdk, { id: String(id) });
}

/*********************************************************************/
export const serviceProviderDocument = types.dataProvider({
  getList,
  getOne,
  getMany,
  getManyReference,
  create,
  update,
});
