import * as client from "_graphql-types";
import * as types from "./types";

import { useNavigate } from "react-router-dom";
import { getChangedFields } from "../helpers/diff";

type Sdk = ReturnType<typeof client.getSdk>;

/*********************************************************************/
// Data Provider Functions
/*********************************************************************/

/*********************************************************************/
export async function getOne(sdk: Sdk) {
  const { currentUser: data } = await sdk.getOneCurrentUser();
  const id = data.currentUserId;

  return {
    data: { ...data, id },
  };
}

/*********************************************************************/
async function update(
  sdk: Sdk,
  params: types.UpdateParams<client.AssumeUserInput>
) {
  const { data } = params;
  const { assumedUserId } = data;
  const input: client.UpdateAssumeUserMutationVariables["input"] | undefined = {
    assumedUserId: assumedUserId,
  };
  if (input) {
    await sdk.updateAssumeUser({
      input,
    });
  }

  return await getOne(sdk);
}

async function deleteOne(sdk: Sdk) {
  const { deleteAssumeUser: id } = await sdk.deleteAssumeUser();
  return { data: typeof id === "number" ? { id } : undefined };
}

/*********************************************************************/
export const currentUser = types.dataProvider({
  getOne,
  update,
  delete: deleteOne,
});
