import * as client from "_graphql-types";
import * as types from "./types";

type Sdk = ReturnType<typeof client.getSdk>;

/*********************************************************************/
// Data Provider Functions
/*********************************************************************/

/*********************************************************************/
async function getList(
  sdk: Sdk,
  params: types.GetListParams<client.DocumentTypeEnumSortEnum>
) {
  const {
    sort: { field, order },
    filter,
  } = params;

  const {
    documentTypeEnumList: { items: data, total },
  } = await sdk.getDocumentTypeEnumList({
    sort: [{ field, order }],
    filter,
  });

  return { data, total };
}

async function getMany(sdk: Sdk, params: types.GetManyParams) {
  const ids = params.ids.map(id => Number(id));
  const data = (
    await Promise.all(ids.map(id => sdk.getDocumentTypeEnum({ id })))
  ).map(({ documentTypeEnum }) => documentTypeEnum);
  return { data };
}

export const documentTypeEnum = types.dataProvider({
  getList,
  getMany,
  ttl: types.defaultEnumTtl,
});
