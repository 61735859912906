import {
  Button,
  Grid,
  Modal,
  ModalProps,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { AumEntry } from "_graphql-types";
import { useState } from "react";
import { useNotify } from "react-admin";
import { parsePasteArea } from "./helpers";
import { styled } from "@mui/material/styles";

const pasteArea = {
  overflow: "auto",
  height: 300,
  maxHeight: 600,
  width: "100%",
};

const DivPaper = styled("div")(({ theme }) => ({
  position: "absolute",
  width: 800,
  backgroundColor: theme.palette.background.paper,
  border: "2px solid #000",
  boxShadow: theme.shadows[5],
  padding: theme.spacing(2, 4, 3),
  top: "50%",
  left: "50%",
  transform: `translate(-50%,-50%)`,
  outline: "none",
}));

export const AumEntryModal = ({
  change,
  ...props
}: Omit<
  {
    change: (
      updatefn: (existingAssets: Map<string, AumEntry>) => Map<string, AumEntry>
    ) => void;
  } & ModalProps,
  "children"
>) => {
  const [notifyTimeout, setNotifyTimeout] = useState(false);
  const [newAssets, changeNewAssets] = useState<AumEntry[]>([]);
  const notify = useNotify();
  return (
    <Modal
      {...props}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <DivPaper>
        <h2 id="simple-modal-title">Add AUM data</h2>
        <Grid container>
          <Grid item xs={6}>
            <Grid item xs={12}>
              <TextField
                variant="standard"
                data-id="pasteArea"
                label="Paste AUM"
                sx={pasteArea}
                onChange={(e: any) => {
                  try {
                    changeNewAssets(parsePasteArea(e.target.value));
                  } catch (e) {
                    changeNewAssets([]);
                    console.error(e);
                    if (!notifyTimeout) {
                      setNotifyTimeout(true);
                      setTimeout(() => setNotifyTimeout(false), 10000);
                      notify(`error parsing your text : ${e}`, {
                        type: "error",
                      });
                    }
                    return;
                  }
                }}
                minRows={4}
                multiline
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <TableContainer sx={pasteArea}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>As of Date</TableCell>
                    <TableCell>Investment AUM </TableCell>
                    <TableCell>Strategy AUM</TableCell>
                    <TableCell>Firm AUM</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {newAssets.map(a => (
                    <TableRow>
                      <TableCell>
                        {new Date(a.asOfDate)
                          .toLocaleString("en-US", {
                            timeZone: "UTC",
                            month: "2-digit",
                            day: "2-digit",
                            year: "numeric",
                          })
                          .slice(0, 10)}
                      </TableCell>
                      <TableCell>{a.investmentAUM}</TableCell>
                      <TableCell>{a.strategyAUM}</TableCell>
                      <TableCell>{a.firmAUM}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item xs={12}>
            <Button
              data-id="bulkAddRecordsButton"
              color="primary"
              variant="contained"
              disabled={!newAssets.length}
              onClick={() => {
                change((existingRecords: Map<string, AumEntry>) => {
                  const newSet = new Map(existingRecords);

                  newAssets.forEach(record => {
                    if (newSet.get(record.asOfDate)) {
                      const existingRecord = newSet.get(record.asOfDate);
                      newSet.set(record.asOfDate, {
                        asOfDate: record.asOfDate,
                        investmentAUM:
                          record.investmentAUM ?? existingRecord?.investmentAUM,
                        firmAUM: record.firmAUM ?? existingRecord?.firmAUM,
                        strategyAUM:
                          record.strategyAUM ?? existingRecord?.strategyAUM,
                      });
                    }
                  });

                  return newSet;
                });

                changeNewAssets([]);
                props.onClose && props.onClose({}, "escapeKeyDown");
              }}
            >
              Load These Records
            </Button>
          </Grid>
        </Grid>
      </DivPaper>
    </Modal>
  );
};
