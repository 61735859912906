import { useState, useEffect } from "react";
import { Navigate } from "react-router-dom";
import { useDataProvider } from "react-admin";
import { Provided } from "../../../dataProvider/DataProvider";
import { getList } from "../../../dataProvider/resources/company";

export const BusinessApplicationRedirect = (props: any) => {
  const [id, setId] = useState<number | null>(null);
  const dataProvider = useDataProvider();
  useEffect(() => {
    dataProvider
      .getList<Provided<typeof getList>[0]>("company", {
        pagination: {
          page: 1,
          perPage: 1,
        },
        sort: {
          field: "name",
          order: "ASC",
        },
        filter: {},
      })
      .then(({ data }) => {
        setId(data[0].id);
      });
  }, []);

  if (id === null) return null;

  return <Navigate to={`/company/${id}`}></Navigate>;
};
