import { useState } from "react";
import { CustomEdit } from "../CustomEdit";
import { useCurrentUser } from "frontend/src/utils/hooks";
import * as client from "_graphql-types";
import { Grid, Tooltip } from "@mui/material";
import Chip from "@mui/material/Chip";
import {
  ReferenceInput,
  TextInput,
  SelectInput,
  ReferenceManyField,
  Pagination,
  Datagrid,
  TextField,
  BooleanField,
  EditButton,
  Button,
  NumberInput,
  FunctionField,
  DateField,
  BooleanInput,
  AutocompleteInput,
  useRecordContext,
  useGetRecordId,
} from "react-admin";
import { Link } from "react-router-dom";
import Template from "frontend/src/components/Template";
import { PrimaryAddressInput } from "./Create";
import { CustomTagsInput } from "../CustomTagsInput";
import { ImpactSelection } from "./ImpactSelect";
import { getListColors, validateYearFounded } from "../firm/Edit";
import { FaceOfEntityTooltip } from "../FaceOfEntityTooltip";
import { useCanAccessMutation } from "../../util/useCanAccessMutation";

export type Record = NonNullable<client.GetOneCompanyQuery["company"]>;

enum Tabs {
  main,
  application,
  affiliation,
}

const DocumentsButton = () => {
  const record = useRecordContext<Record>();
  if (!record) return;
  return (
    <Button
      component={Link}
      to={{
        pathname: `/companyDocument/${record.id}`,
      }}
      label="Go to Documents"
      data-id="documents"
    />
  );
};

const RECFApplicationButton = () => {
  const record = useRecordContext<Record>();
  if (!record) return;
  return (
    <Button
      component={Link}
      to={{
        pathname: `/company/${record.id}`,
      }}
    />
  );
};

const CreateRelatedFirmEmploymentButton = () => {
  const record = useRecordContext<Record>();
  if (!record) return;
  return (
    <Button
      component={Link}
      to={{
        pathname: "/firmEmployment/create",
        search: `?source=${JSON.stringify({
          firm: {
            id: record.firmAttributes.id,
            name: record.name ? encodeURIComponent(record.name) : "",
            isCompany: true,
            companyId: record.id,
          },
        })}`,
      }}
      label="Relate to Existing Person"
      title="Add a company employment for an existing person"
      data-id="createCompanyEmployment"
    />
  );
};

const CreateRelatedPersonButton = () => {
  const record = useRecordContext<Record>();
  if (!record) return;
  return (
    <Button
      component={Link}
      to={{
        pathname: "/person/create",
        search: `?source=${JSON.stringify({
          firm: {
            id: record.firmAttributes.id,
            name: record.name ? encodeURIComponent(record.name) : "",
            isCompany: true,
            companyId: record.id,
          },
        })}`,
      }}
      label="Relate to New Person"
      title="Add a company employment and create a new person"
      data-id="createPerson"
    />
  );
};

const AddDealButton = () => {
  const record = useRecordContext<Record>();
  if (!record) return;
  return (
    <Button
      data-cy="add_deal"
      component={Link}
      label="Add Deal"
      to={{
        pathname: "/deal/create",
        search: `?source=${JSON.stringify({
          company: {
            id: record.id,
            name: record.name ? encodeURIComponent(record.name) : "",
          },
        })}`,
      }}
    />
  );
};

const CreateRelatedValuationButton = () => {
  const record = useRecordContext<Record>();
  if (!record) return;
  return (
    <Button
      data-cy="add_valuation"
      component={Link}
      label="Add Valuation"
      to={{
        pathname: "/companyValuation/create",
        search: `?source=${JSON.stringify({
          company: {
            id: record.id,
          },
        })}`,
      }}
    />
  );
};

const DEIButton = () => {
  const record = useRecordContext<Record>();
  if (!record) return;
  return (
    <Button
      component={Link}
      to={{
        pathname: `/firmFirmDEI/${record.firmAttributes.id}`,
      }}
      label="Go to DEI Entry"
      data-id="firmFirmDEI"
    />
  );
};

const CreateRelatedInvestmentButton = () => {
  const record = useRecordContext<Record>();
  if (!record) return;
  return (
    <Button
      component={Link}
      to={{
        pathname: "/investment/create",
        search: `?source=${JSON.stringify({
          firm: {
            id: record.firmAttributes.id,
            name: record.name ? encodeURIComponent(record.name) : "",
            isCompany: record.firmAttributes.isCompany,
            companyId: record.id,
          },
        })}`,
      }}
      label="Add New Investment"
      title="Add a new investment to this company"
      data-id="createInvestment"
    />
  );
};

// This needs to be a constant variable, to prevent POSTs on every rerender.
const COMPANY_TAG_CLASS_IDS = [22];

const CompanyEditForm = ({ tab }: { tab: Tabs }) => {
  const record = useRecordContext<Record>();
  if (!record) return;

  if (tab === Tabs.application)
    return (
      <Template
        editMode={false}
        ownerType={client.FieldOwnerType.Company}
        ownerId={Number(record.id)}
        name={client.FieldSetType.Application}
        simplified={false}
      />
    );
  if (tab === Tabs.affiliation)
    return (
      <Template
        editMode={false}
        ownerType={client.FieldOwnerType.Company}
        ownerId={Number(record.id)}
        name={client.FieldSetType.Affiliations}
        simplified={false}
      />
    );

  return (
    <>
      <Grid data-cy="company__edit" container spacing={2}>
        <Grid item xs={6}>
          <DocumentsButton />
        </Grid>
        {record?.isBusinessApplication && (
          <Grid>
            <RECFApplicationButton />
          </Grid>
        )}
        <Grid item xs={6}>
          <TextInput label="Legal Name" source="name" />
        </Grid>
        <Grid item xs={6}>
          <NumberInput
            label="Year Founded"
            source="firmAttributes.yearFounded"
            validate={validateYearFounded}
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput label="Stock Symbol" source="firmAttributes.stockSymbol" />
        </Grid>
        <Grid item xs={6}>
          <ReferenceInput
            source="lastFundingTypeEnumId"
            perPage={100}
            reference="fundingTypeEnum"
            sort={{ field: "name", order: "ASC" }}
          >
            <SelectInput
              fullWidth={true}
              optionText="name"
              label="Last Funding Type"
            />
          </ReferenceInput>
        </Grid>
        <Grid item xs={6}>
          <ReferenceInput
            source="operatingStatusEnumId"
            reference="operatingStatusEnum"
            sort={{ field: "name", order: "ASC" }}
          >
            <SelectInput
              fullWidth={true}
              optionText="name"
              label="Operating Status"
            />
          </ReferenceInput>
        </Grid>
        <Grid item xs={6}>
          <ReferenceInput
            source="companyTypeEnumId"
            reference="companyTypeEnum"
            sort={{ field: "name", order: "ASC" }}
          >
            <SelectInput
              fullWidth={true}
              optionText="name"
              label="Company Type"
            />
          </ReferenceInput>
        </Grid>
        <Grid item xs={6}>
          <TextInput source="firmAttributes.email" label="Company Email" />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextInput
            source="firmAttributes.linkedIn"
            label="Company LinkedIn"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextInput source="firmAttributes.website" label="Company Website" />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <ReferenceInput
            source="primaryAssignment"
            reference="investmentAssignmentOption"
            perPage={100}
            sort={{ field: "commonName", order: "ASC" }}
            filter={{ isPrimary: true }}
          >
            <AutocompleteInput
              optionText="commonName"
              label="Primary Assignment"
            />
          </ReferenceInput>
        </Grid>
        <Grid item xs={12}>
          <TextInput
            label="Company Summary"
            source="summary"
            multiline
            rows={5}
          />
        </Grid>

        <Grid item xs={3}>
          <BooleanInput label="Is MWDBE" source="firmAttributes.isStaffMWDBE" />
        </Grid>
        <Grid item xs={12}>
          <hr />
          <h3>Company Tags</h3>
        </Grid>
        <CustomTagsInput
          source="firmAttributes.firmTags"
          label="Company Tags"
          tagClassIdList={COMPANY_TAG_CLASS_IDS}
        />
        <Grid item xs={12}>
          <hr />
          <h3>Impact Tags</h3>
        </Grid>
        <ImpactSelection source="firmAttributes.impactTags" />
        <Grid item xs={12}>
          <hr />
          <h3>Deals</h3>
        </Grid>
        <Grid item xs={12}>
          <ReferenceManyField
            label="Deals"
            reference="deal"
            target="companyId"
            sort={{ field: "id", order: "DESC" }}
            pagination={<Pagination />}
          >
            <Datagrid
              rowClick="edit"
              data-id={"deal-list"}
              bulkActionButtons={false}
            >
              <TextField label="ID" source="id" sortable={false} />
              <TextField label="Project Name" source="name" sortable={false} />
              <TextField
                label="Funding Round"
                source="fundingRound.name"
                sortable={false}
              />
              <TextField label="Amount" source="amount" sortable={false} />
              <TextField label="Investor" source="firm.name" sortable={false} />
              <FunctionField
                label="Edit"
                render={(record: any, source: any) => (
                  <EditButton data-cy={`deal-${record.id}`} record={record} />
                )}
              />
            </Datagrid>
          </ReferenceManyField>
        </Grid>
        <Grid item xs={12}>
          <AddDealButton />
        </Grid>
        <Grid item xs={12}>
          <hr />
          <h3>Company Employee Summary</h3>
        </Grid>
        <Grid item xs={1}>
          <NumberInput label="Total" source="firmAttributes.employeeTotal" />
        </Grid>
        <Grid item xs={12}>
          <hr />
          <h3>Address</h3>
        </Grid>
        <Grid item xs={12}>
          <PrimaryAddressInput />
        </Grid>
        <Grid item xs={12}>
          <hr />
        </Grid>
        <Grid item xs={4} lg={3}>
          <Tooltip
            title="Has RockCreek signed an NDA precluding them from sharing company data with 3rd Parties?"
            placement="bottom"
          >
            <div>
              <BooleanInput label="NDA Signed" source="ndaSigned" />
            </div>
          </Tooltip>
        </Grid>
        <Grid item xs={12}>
          <hr />
          <h3>Investments</h3>
        </Grid>
        <Grid item xs={12}>
          <ReferenceManyField
            label="Investments"
            reference="investment"
            target="companyId"
            sort={{ field: "name", order: "ASC" }}
            pagination={<Pagination />}
          >
            <Datagrid rowClick="edit" bulkActionButtons={false}>
              <TextField label="ID" source="id" />
              <TextField label="Name" source="name" />
              <FunctionField
                label="List"
                render={(record: any) => {
                  if (!record?.list?.id) return "";

                  return (
                    <Chip
                      label={record.list.name}
                      style={{
                        backgroundColor: getListColors(record.list.id),
                      }}
                    >
                      {record.list.name}
                    </Chip>
                  );
                }}
              />
              <FunctionField
                label="Link"
                render={(record: any) => {
                  return (
                    <SelectInput
                      source={`fundLinksSerialized._${record.id}`}
                      label="Link"
                      defaultValue={record.fundLinkId}
                      choices={[...Array(10).keys()].map(i => ({
                        id: i + 1,
                        name: `Link ${i + 1}`,
                      }))}
                    />
                  );
                }}
              />

              <EditButton data-cy="investment-edit" />
            </Datagrid>
          </ReferenceManyField>
        </Grid>
        <Grid item xs={12}>
          <CreateRelatedInvestmentButton />
        </Grid>
        <Grid item xs={12}>
          <hr />
          <h3>Diversity</h3>
          <DEIButton />
        </Grid>
        <Grid item xs={12}>
          <hr />
          <h3>Company Employments</h3>
        </Grid>
        <Grid item xs={12}>
          <ReferenceManyField
            label="Company Employments"
            reference="firmEmployment"
            target="companyId"
            sort={{ field: "firmDefault", order: "ASC" }}
            pagination={<Pagination />}
          >
            <Datagrid
              rowClick="edit"
              data-id="firm-employment-list"
              bulkActionButtons={false}
            >
              <TextField label="ID" source="id" sortable={false} />
              <TextField
                label="Last Name"
                source="person.lastName"
                sortable={false}
              />
              <TextField
                label="First Name"
                source="person.firstName"
                sortable={false}
              />
              <TextField
                label="Role"
                source="employeeRole.name"
                sortable={false}
              />
              <TextField
                label="Contact Type"
                source="contactType.name"
                sortable={false}
              />
              <BooleanField
                label="Founder"
                source="isFounder"
                sortable={false}
              />
              <FunctionField
                label="Departed?"
                render={(firmEmployment: any) =>
                  firmEmployment?.leftInd ? "Yes" : ""
                }
                sortable={false}
              />
              <DateField
                label="Departed on"
                source="leftDate"
                sortable={false}
                options={{ timeZone: "UTC" }}
              />
              <FunctionField
                source="faceOfFirm"
                label="Face Of Company"
                sortable={false}
                render={(record: any, source: any) => (
                  <FaceOfEntityTooltip entity="company">
                    <BooleanField
                      record={{
                        ...record,
                        faceOfFirm: !!record.faceOfFirm,
                      }}
                      source={source}
                    />
                  </FaceOfEntityTooltip>
                )}
              />
              <EditButton />
            </Datagrid>
          </ReferenceManyField>
        </Grid>
        <Grid id="companyEmploymentList" item xs={12}>
          <CreateRelatedFirmEmploymentButton />
          <CreateRelatedPersonButton />
        </Grid>
        <Grid item xs={12}>
          <hr />
          <h3>Company Valuation</h3>
        </Grid>
        <Grid item xs={12}>
          <ReferenceManyField
            label="Company Valuation"
            reference="companyValuation"
            target="companyId"
            sort={{ field: "asOfDate", order: "DESC" }}
            pagination={<Pagination />}
          >
            <Datagrid rowClick="edit" bulkActionButtons={false}>
              <TextField label="ID" source="id" sortable={false} />
              <TextField
                label="As Of Date"
                source="asOfDate"
                sortable={false}
              />
              <TextField
                label="Valuation"
                source="valuation"
                sortable={false}
              />
              <EditButton />
            </Datagrid>
          </ReferenceManyField>
        </Grid>
        <Grid item xs={12}>
          <CreateRelatedValuationButton />
        </Grid>
      </Grid>
    </>
  );
};

export const CompanyEdit = () => {
  const [tab, changeTab] = useState<Tabs>(Tabs.main);
  const currentUser = useCurrentUser();
  const recordId = useGetRecordId();

  const { canEdit, canEditField, loading } = useCanAccessMutation(
    "updateCompany",
    JSON.stringify({ id: recordId })
  );

  return (
    <CustomEdit<Record>
      title={record => record && `Company - ${record.name || ""}`}
      customFormProps={{
        hideToolbar: tab !== Tabs.main,
        loading,
        customToolbarProps: {
          canAccessMutation: canEdit,
        },
        canEditField,
      }}
      customTabs={(record?: Record) => {
        if (!currentUser.flags.businessApplicant) return [];

        return (
          currentUser.flags.businessApplicant && [
            { name: "Main", onClick: () => changeTab(Tabs.main) },
            { name: "Application", onClick: () => changeTab(Tabs.application) },
            {
              name: "Affiliations",
              onClick: () => changeTab(Tabs.affiliation),
            },
          ]
        );
      }}
    >
      <CompanyEditForm tab={tab} />
    </CustomEdit>
  );
};
