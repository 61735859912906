import { useQuery } from "@apollo/client";
import { Grid } from "@mui/material";
import * as types from "_graphql-types";
import Template from "frontend/src/components/Template";
import { useEffect, useState } from "react";
import { useBlocker, useNavigate } from "react-router-dom";
import CompletionConfirmation from "./CompletionConfirmation";
import { GET_APPLICATION_COMPANY } from "./graphql";

const AffiliateQuestionaire = () => {
  const { data, error } = useQuery<types.GetApplicantCompanyQuery>(
    GET_APPLICATION_COMPANY
  );
  const navigate = useNavigate();
  const [complete, setComplete] = useState(false);
  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      !complete && currentLocation.pathname !== nextLocation.pathname
  );

  useEffect(() => {
    if (blocker.state === "blocked") {
      const exit = window.confirm(
        `Your Application is not complete and submitted. Are you sure you want to leave?`
      );
      if (exit) {
        blocker.proceed();
      } else {
        blocker.reset();
      }
    }
  }, [blocker.state]);

  const ownerId = data?.companyList.items[0].id;
  if (error) return <>An error occurred, please contact support</>;
  if (!data) return null;
  if (!ownerId && typeof ownerId !== "number")
    return <>Business application unavailable, please contact support</>;

  return (
    <Grid>
      <CompletionConfirmation />
      <Grid item xs={12}>
        Fill out all fields of the Affiliation Questionnaire. If you answer
        “yes” to any question, please provide a detailed explanation within the
        allotted space.
      </Grid>
      <Grid item xs={12}>
        <Template
          editMode={true}
          ownerType={types.FieldOwnerType.Company}
          ownerId={ownerId}
          name={types.FieldSetType.Affiliations}
          simplified={true}
          onPublish={() => {
            navigate("/businessApplicationDocuments");
          }}
          onIsCompleted={() => {
            console.log("complete");
            setComplete(true);
          }}
        />
      </Grid>
    </Grid>
  );
};

export default AffiliateQuestionaire;
