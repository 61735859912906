import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput, { OutlinedInputProps } from "@mui/material/OutlinedInput";
import { useInput } from "react-admin";

interface NumberWithInputAdornmentProps {
  symbol: string;
  symbolAlignment?: string;
  validate?: Parameters<typeof useInput>[0]["validate"];
  source: string;
  /**
   * @description only used if you need both a start and end symbol
   * (otherwise just use symbol/symbolAlignment)
   */
  startSymbol?: string;
}

const NumberWithInputAdornment = (
  props: OutlinedInputProps & NumberWithInputAdornmentProps
) => {
  const {
    sx,
    symbol,
    label,
    symbolAlignment = "end",
    source,
    disabled,
    startSymbol,
  } = props;
  const {
    field: { name, onChange, value, ...rest },
    fieldState: { error },
    isRequired,
  } = useInput({ source, validate: props.validate });

  const hasStartSymbol = symbolAlignment === "start" || !!startSymbol;
  const _startSymbol = symbolAlignment === "start" ? symbol : startSymbol;

  return (
    <FormControl variant="outlined" style={{ width: "100%" }} sx={sx}>
      <InputLabel error={!!error} htmlFor={`${label}::label`}>
        {label}
      </InputLabel>
      <OutlinedInput
        id={`${label}::label`}
        readOnly={disabled}
        label={label}
        required={isRequired}
        type="number"
        name={name}
        value={String(value)}
        error={!!error}
        onChange={({ target }) => {
          if (target.value !== undefined) onChange(Number(target.value));
        }}
        startAdornment={
          hasStartSymbol && (
            <InputAdornment position="start">{_startSymbol}</InputAdornment>
          )
        }
        endAdornment={
          symbolAlignment === "end" && (
            <InputAdornment position="end">{symbol}</InputAdornment>
          )
        }
        {...rest}
      />
      {error && (
        <FormHelperText error={!!error}>{error.message}</FormHelperText>
      )}
    </FormControl>
  );
};

export default NumberWithInputAdornment;
