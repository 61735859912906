import { ReactNode } from "react";
import { InputProps, useInput as raUseInput } from "react-admin";

import {
  BrowserNativeObject,
  NestedValue,
  NonUndefined,
  RefCallBack,
} from "react-hook-form";

/**
 * 😞 RA doesn't type things properly. They use `any` too much.
 * This will work for non-formatted data.
 */
export function useInput<TValue>(props: InputProps<TValue>) {
  const input = raUseInput(props);
  const field = input.field as {
    onChange: (...event: TValue[]) => void;
    onBlur: (...event: TValue[]) => void;
    value: TValue;
    name: string;
    ref: RefCallBack;
  };
  return { ...input, field };
}

export interface FormData<TForm, TScopedForm> {
  formData: TForm;
  scopedFormData?: TScopedForm;
}

/*
 * For use in FormDataConsumer, because the inputs to the child component
 * are poorly typed.
 *
 * Separate HOCs for formData and scopedFormData because you usually use one or
 * the other and only wish to provide one of the types.
 *
 * Example:
 *
 * ```
 * <FormDataConsumer>
 *  withScopedForm<Record["todos"][number]>(({ scopedFormData }) => (
 *    // ... yay! `scopedFormData` is typed! no?.more?.guessing?.at?.types!
 *  ))
 * </FormDataConsumer>
 */

export function withForm<TForm = unknown, TScopedForm = unknown>(
  child: (form: FormData<TForm, TScopedForm>) => ReactNode
) {
  // we have to cast because react admin's types are broken
  return child as (form: FormData<any, TScopedForm>) => ReactNode;
}

export function withScopedForm<TScopedForm = unknown, TForm = unknown>(
  child: (form: FormData<TForm, TScopedForm>) => ReactNode
) {
  // we have to cast because react admin's types are broken
  return child as (form: FormData<any, TScopedForm>) => ReactNode;
}

/**
 * Used for building a (global) form validation error object. The form being
 * validated would be typed `DeepPartial<Record>` (from react-hook-form), and
 * the errors object would be typed `ValidationOf<Record>`.
 *
 * Example:
 *
 * ```
 * const validateForm = (form: DeepPartial<Record>) => {
 *    const errors: ValidationOf<Record> = {};
 *    if (!form.firstName) errors.firstName = "First name is required";
 *    // ...
 * }
 * ```
 */
export type ValidationOf<T> = unknown extends T
  ? unknown
  : T extends undefined
  ? never
  : T extends BrowserNativeObject | NestedValue
  ? string
  : T extends any[]
  ? string | ValidationOf<NonUndefined<T[number]>>[]
  : T extends object
  ? {
      [K in keyof T]+?: ValidationOf<NonUndefined<T[K]>>;
    }
  : string;

/**
 * Recursively prunes objects with empty errors and arrays of undefined values.
 * This shouldn't be necessary, as based on their own examples, form validation
 * should only look for leaf keys with string values, but once again they didn't
 * do it right.
 *
 * For example, this should pass, but it doesn't:
 * {
 *  "groups": {
 *    "items": [
 *      {
 *        "terms": {
 *          "items": [
 *            {}
 *          ]
 *        }
 *      },
 *      {
 *        "terms": {
 *          "items": [
 *            {}
 *          ]
 *        }
 *      }
 *    ]
 *  }
 * }
 */
export const pruneErrors = <T>(
  errors: ValidationOf<T>
): ValidationOf<T> | {} => {
  const pruneErrors = <T>(
    errors: ValidationOf<T>
  ): ValidationOf<T> | undefined => {
    if (Array.isArray(errors)) {
      const newErrors = errors.map(pruneErrors);
      if (newErrors.some(x => x))
        return newErrors.map(x => x ?? {}) as ValidationOf<T>;
    } else if (typeof errors === "object") {
      const newErrors = Object.entries(errors ?? {})
        .map(
          ([k, v]) => [k, pruneErrors(v as ValidationOf<T[keyof T]>)] as const
        )
        .filter(([, v]) => v);
      if (newErrors.length)
        return Object.fromEntries(newErrors) as ValidationOf<T>;
    } else return errors;
  };
  return pruneErrors(errors) ?? {};
};

// helper
export type AllOptional<T> = keyof {
  [K in keyof T as undefined extends T[K] ? never : K]: null;
} extends never
  ? true
  : never;

/**
 * Allows easily nesting an existing component under a custom component
 */
export type NestProps<
  TParent,
  TUnderKey extends string,
  TChild,
  TPromoteKeys extends keyof TChild = never
> = Omit<TParent, TPromoteKeys> &
  Pick<TChild, TPromoteKeys> &
  (AllOptional<Omit<TChild, TPromoteKeys>> extends true
    ? { [K in TUnderKey]+?: Omit<TChild, TPromoteKeys | "children"> }
    : { [K in TUnderKey]-?: Omit<TChild, TPromoteKeys | "children"> });

export type NoExtraKeys<
  TExpected,
  TActual extends TExpected
> = TExpected extends TActual ? TActual : never;
