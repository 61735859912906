import { InputProps, useInput } from "react-admin";
import React, { useMemo, useState, useCallback } from "react";
import ReactQuill from "react-quill";
import { CustomToolbar } from "frontend/src/components/RCGReactQuillInput";
import { InputLabel } from "@mui/material";

interface RichTextInputProps {
  canEdit: boolean;
  backgroundColor?: string;
}

export function RichTextInput({
  canEdit,
  backgroundColor,
  ...inputProps
}: InputProps & RichTextInputProps) {
  const [isFocused, setIsFocused] = useState(false);

  const {
    fieldState: { error },
    field: { onChange, value, name, onBlur },
  } = useInput(inputProps);

  const toolbarStyle = useMemo(() => {
    return error
      ? {
          borderColor: "red",
          borderWidth: "2px",
          borderStyle: "solid",
        }
      : {};
  }, [error]);

  const quillOnChange: NonNullable<
    React.ComponentProps<typeof ReactQuill>["onChange"]
  > = useCallback(
    (newValue, delta, source) => {
      if (source === "user") {
        onChange(newValue);
      }
    },
    [onChange]
  );

  function handleBlur() {
    setIsFocused(false);
    onBlur();
  }
  return (
    <>
      <InputLabel>{inputProps.label}</InputLabel>
      <div
        data-cy={`richTextInput__${name}`}
        style={{
          display: "flex",
          flexDirection: "column",
          justifyItems: "stretch",
          backgroundColor: "white",
          width: "100%",
          marginBottom: "2em",
          border: isFocused ? "2px solid #1890ff" : "none",
          borderRadius: "4.5px",
          marginTop: "0.5em",
        }}
      >
        {canEdit && (
          <CustomToolbar id={`toolbar-${name}`} style={toolbarStyle} />
        )}
        <ReactQuill
          theme={canEdit ? "snow" : "bubble"}
          modules={{ ...(canEdit && { toolbar: `#toolbar-toolbar-${name}` }) }}
          value={value}
          readOnly={!canEdit}
          onChange={quillOnChange}
          onBlur={() => handleBlur()}
          onFocus={() => setIsFocused(true)}
          style={{
            overflow: "auto",
            lineHeight: "1.6em",
            minHeight: "3.2em",
            backgroundColor: backgroundColor ?? "white",
          }}
        ></ReactQuill>
      </div>
    </>
  );
}
