import * as client from "_graphql-types";
import * as types from "./types";

import { addDays } from "date-fns";

type Sdk = ReturnType<typeof client.getSdk>;

/*********************************************************************/
// Data Provider Functions
/*********************************************************************/

/*********************************************************************/
async function getList(
  sdk: Sdk,
  params: types.GetListParams<client.InstitutionSelectionStatusEnumSortEnum>
) {
  const {
    pagination: { page, perPage },
    sort: { field, order },
    filter,
  } = params;

  const {
    institutionSelectionStatusEnumList: { items: data, total },
  } = await sdk.getListInstitutionSelectionStatusEnum({
    page: {
      offset: (page - 1) * perPage,
      limit: perPage,
    },
    sort: [{ field, order }],
    filter,
  });

  return {
    data,
    total,
    // one day from now
    validUntil: addDays(new Date(), 1),
  };
}

/*********************************************************************/
async function getOne(sdk: Sdk, params: types.GetOneParams) {
  const id = Number(params.id);
  const { institutionSelectionStatusEnum: data } =
    await sdk.getOneInstitutionSelectionStatusEnum({
      id,
    });
  return {
    data,
    // one day from now
    validUntil: addDays(new Date(), 1),
  };
}

/*********************************************************************/
async function getMany(sdk: Sdk, params: types.GetManyParams) {
  const ids = params.ids.map(id => Number(id));

  const { institutionSelectionStatusEnumMany: data } =
    await sdk.getManyInstitutionSelectionStatusEnum({
      ids,
    });

  return {
    data,
    // one day from now
    validUntil: addDays(new Date(), 1),
  };
}

/*********************************************************************/
export const institutionSelectionStatusEnum = types.dataProvider({
  getList,
  getOne,
  getMany,
  ttl: types.defaultEnumTtl,
});
