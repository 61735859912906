import * as client from "_graphql-types";

import {
  TextField,
  FunctionField,
  Labeled,
  List,
  TopToolbar,
  SortButton,
  CreateButton,
  Datagrid,
  EditButton,
  WrapperField,
} from "react-admin";
import { FCField_RenderTextWithConvenienceCopy } from "../UI/CopyField";
import { LinksFilter } from "./Filter";
import { ChipList } from "../UI/ChipList";
import { ensureDefined } from "frontend/src/utils/helpers";

type Record = NonNullable<client.GetOneLinkQuery["links"]>;

export const LinksList = () => {
  return (
    <List
      sx={{
        ".RaFilter-form": {
          flex: "0 1 50%",
        },
      }}
      filters={<LinksFilter />}
      sort={{ field: "name", order: "ASC" }}
      actions={
        <TopToolbar>
          <CreateButton />
        </TopToolbar>
      }
    >
      <Datagrid rowClick="edit" bulkActionButtons={false}>
        <TextField sortable={true} source="name" />
        <WrapperField label="Url" sortBy="url">
          <FunctionField
            source="url"
            render={(record: any) => (
              <FCField_RenderTextWithConvenienceCopy
                record={record as Record}
                source="url"
              />
            )}
          />
        </WrapperField>
        <TextField label="Icon" sortable={false} source="iconEnum.name" />
        <FunctionField
          label="Organizations"
          source="orgLinks"
          sortable={false}
          render={(record: Record) => (
            <ChipList
              record={
                record.orgLinks?.map(orgLink => ({
                  id: Number(orgLink.id),
                  name: ensureDefined(orgLink.organization?.name),
                })) ?? []
              }
            />
          )}
        />
        <EditButton data-cy="link-edit" />
      </Datagrid>
    </List>
  );
};
