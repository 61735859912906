import * as client from "_graphql-types";
import * as types from "./types";
import { getChangedFields } from "../helpers/diff";

type Sdk = ReturnType<typeof client.getSdk>;

/*********************************************************************/
// Data Provider Functions
/*********************************************************************/

/*********************************************************************/
async function getList(
  sdk: Sdk,
  params: types.GetListParams<client.ServiceProviderFirmSortEnum>
) {
  const {
    pagination: { page, perPage },
    sort: { field, order },
    filter,
  } = params;

  const {
    serviceProviderFirmList: { items: data, total },
  } = await sdk.getListServiceProviderFirm({
    page: {
      offset: (page - 1) * perPage,
      limit: perPage,
    },
    sort: [{ field, order }],
    filter: {
      ...filter,
      isEvaluating: filter.isEvaluating || null,
    },
  });

  return {
    data,
    total,
  };
}

/*********************************************************************/
async function getOne(sdk: Sdk, params: types.GetOneParams) {
  const id = Number(params.id);
  const { serviceProviderFirm: data } = await sdk.getOneServiceProviderFirm({
    id: id,
  });
  return {
    data,
  };
}

/*********************************************************************/
async function getMany(sdk: Sdk, params: types.GetManyParams) {
  const ids = params.ids.map(Number);

  const { serviceProviderFirmMany: data } =
    await sdk.getManyServiceProviderFirm({
      ids,
    });

  return {
    data,
  };
}

/*********************************************************************/
async function getManyReference(
  sdk: Sdk,
  params: types.GetManyReferenceParams<client.ServiceProviderFirmSortEnum>
) {
  const id = params.id;
  const {
    pagination: { page, perPage },
    sort: { field, order },
    filter,
    target,
  } = params;

  const {
    serviceProviderFirmList: { items: data, total },
  } = await sdk.getListServiceProviderFirm({
    page: {
      offset: (page - 1) * perPage,
      limit: perPage,
    },
    sort: [{ field, order }],
    filter: { ...filter, [target]: id },
  });

  return {
    data,
    total,
  };
}

/*********************************************************************/
async function create(
  sdk: Sdk,
  params: types.CreateParams<client.ServiceProviderFirmInput>
) {
  const { data } = params;

  const input = data;

  const {
    addServiceProviderFirm: { id },
  } = await sdk.createServiceProviderFirm({
    input,
  });
  return await getOne(sdk, { id: String(id) });
}

/*********************************************************************/
async function update(
  sdk: Sdk,
  params: types.UpdateParams<
    NonNullable<client.GetOneServiceProviderFirmQuery["serviceProviderFirm"]>
  >
) {
  const { data, id } = params;
  const {
    // extract fetched properties that are not written.
    officeLocations,
    ...entityInputData
  } = data;

  const input: client.UpdateServiceProviderFirmMutationVariables["input"] = {
    ...entityInputData,
  };

  await sdk.updateServiceProviderFirm({
    id: Number(id),
    input,
  });
  return await getOne(sdk, { id: String(id) });
}

/*********************************************************************/
export const serviceProviderFirm = types.dataProvider({
  getList,
  getOne,
  getMany,
  getManyReference,
  create,
  update,
});
