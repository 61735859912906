import * as client from "_graphql-types";
import * as types from "./types";

import { filterSortPage } from "../DataProvider";

type Sdk = ReturnType<typeof client.getSdk>;

/*********************************************************************/
// Data Provider Functions
/*********************************************************************/

/*********************************************************************/
async function getList(
  sdk: Sdk,
  params: types.GetListParams<client.FamilySortEnum>
) {
  const { pagination, sort } = params;

  const {
    familyList: { items: data, total },
  } = await sdk.getListFamily(filterSortPage({ sort, pagination }));

  return {
    data,
    total,
  };
}

/*********************************************************************/
async function getOne(sdk: Sdk, params: types.GetOneParams) {
  const id = Number(params.id);
  const { family: data } = await sdk.getOneFamily({
    id,
  });
  return {
    data,
  };
}

/*********************************************************************/
async function getMany(sdk: Sdk, params: types.GetManyParams) {
  const ids = params.ids.map(id => Number(id));

  const { familyMany: data } = await sdk.getManyFamily({
    ids,
  });

  return {
    data,
  };
}

async function getManyReference(
  sdk: Sdk,
  params: types.GetManyReferenceParams<client.FamilySortEnum>
) {
  const id = Number(params.id);
  const {
    pagination: { page, perPage },
    sort: { field, order },
    filter,
    target,
  } = params;

  const {
    familyList: { items: data, total },
  } = await sdk.getManyReferenceFamily({
    page: {
      offset: (page - 1) * perPage,
      limit: perPage,
    },
    sort: [{ field, order }],
    filter: { ...filter, [target]: id },
  });

  return {
    data,
    total,
  };
}

async function create(
  sdk: Sdk,
  params: types.CreateParams<client.GetOneFamilyQuery["family"]>
) {
  const { data } = params;
  const input: client.MutationAddFamilyArgs["input"] = {
    name: data?.name,
    firmId: data?.firm?.id,
  };
  const {
    addFamily: { id },
  } = await sdk.createFamily({
    input,
  });
  return await getOne(sdk, { id: id! });
}

async function update(
  sdk: Sdk,
  params: types.UpdateParams<NonNullable<client.GetOneFamilyQuery["family"]>>
) {
  const id = Number(params.id);
  const { data } = params;
  let investmentFamilyInput;
  if (data?.investmentsFamily) {
    // the reference input can return undefined if no scoped data returns
    investmentFamilyInput = data.investmentsFamily
      .filter(Boolean)
      .map(record => ({
        id: record.investmentId,
      }))
      .filter(({ id }) => id);
  }
  const input: client.MutationUpdateFamilyArgs["input"] = {
    name: data?.name,
    firmId: data?.firm?.id,
    investmentsFamily: investmentFamilyInput,
  };
  await sdk.updateFamily({ id, input });
  return await getOne(sdk, { id });
}

/*********************************************************************/
export const family = types.dataProvider({
  getList,
  getOne,
  getMany,
  create,
  update,
  getManyReference,
});
