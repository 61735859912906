import * as client from "_graphql-types";
import * as types from "./types";

type Sdk = ReturnType<typeof client.getSdk>;

/*********************************************************************/
// Data Provider Functions
/*********************************************************************/

/*********************************************************************/
async function getList(
  sdk: Sdk,
  params: types.GetListParams<client.FinancialDataProviderSortEnum>
) {
  const {
    pagination: { page, perPage },
    sort: { field, order },
    filter,
  } = params;

  const {
    financialDataProviderList: { items: data, total },
  } = await sdk.getListFinancialDataProvider({
    page: {
      offset: (page - 1) * perPage,
      limit: perPage,
    },
    sort: [{ field, order }],
    filter,
  });

  return {
    data,
    total,
  };
}

/*********************************************************************/
async function getOne(sdk: Sdk, params: types.GetOneParams) {
  const id = Number(params.id);
  const { financialDataProvider: data } = await sdk.getOneFinancialDataProvider(
    {
      id,
    }
  );
  return {
    data,
  };
}

/*********************************************************************/
async function getMany(sdk: Sdk, params: types.GetManyParams) {
  const ids = params.ids.map(id => Number(id));

  const { financialDataProviderMany: data } =
    await sdk.getManyFinancialDataProvider({
      ids,
    });

  return {
    data,
  };
}

/*********************************************************************/
async function getManyReference(
  sdk: Sdk,
  params: types.GetManyReferenceParams<client.FinancialDataProviderSortEnum>
) {
  const id = Number(params.id);
  const {
    pagination: { page, perPage },
    sort: { field, order },
    filter,
    target,
  } = params;

  const {
    financialDataProviderList: { items: data, total },
  } = await sdk.getListFinancialDataProvider({
    page: {
      offset: (page - 1) * perPage,
      limit: perPage,
    },
    sort: [{ field, order }],
    filter: { ...filter, [target]: id },
  });

  return {
    data,
    total,
  };
}

/*********************************************************************/
async function create(
  sdk: Sdk,
  params: types.CreateParams<
    NonNullable<
      client.GetOneFinancialDataProviderQuery["financialDataProvider"]
    >
  >
) {
  const { data } = params;
  const input: client.CreateFinancialDataProviderMutationVariables["input"] = {
    name: data.name,
  };

  const {
    addFinancialDataProvider: { id },
  } = await sdk.createFinancialDataProvider({
    input,
  });
  return await getOne(sdk, { id: id! });
}

/*********************************************************************/
async function update(
  sdk: Sdk,
  params: types.UpdateParams<
    NonNullable<
      client.GetOneFinancialDataProviderQuery["financialDataProvider"]
    >
  >
) {
  const id = Number(params.id);
  const { data } = params;

  const input: client.UpdateFinancialDataProviderMutationVariables["input"] = {
    name: data.name,
  };

  await sdk.updateFinancialDataProvider({
    id,
    input,
  });
  return await getOne(sdk, { id });
}

/*********************************************************************/
export const financialDataProvider = types.dataProvider({
  getList,
  getOne,
  getMany,
  getManyReference,
  create,
  update,
});
