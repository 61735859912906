import {
  Autocomplete,
  Box,
  FormControlLabel,
  Switch,
  TextField,
} from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Dispatch, SetStateAction } from "react";
import { useGetList } from "react-admin";
import { capitalizedName } from "./helpers";

export type DocumentToolbarFilters = {
  isApproved?: boolean | null;
  documentTypeEnumId?: number | null;
  accessLevel?: number | null;
  minDate?: Date | null;
  maxDate?: Date | null;
};

type DocumentToolbarFilterProps = keyof DocumentToolbarFilters;

interface CustomFilterToolbarProps {
  filters: DocumentToolbarFilters;
  setFilters: Dispatch<SetStateAction<DocumentToolbarFilters>>;
}

/**
 * Custom filter toolbar for the document editable datagrid inside of the entity documents pages (e.g. investmentDocument).
 *
 * If we use in the `DocumentFilter` component from `Filter.tsx` inside of the `ReferenceManyField` component in the entity docs pages,
 * applying any filters will cause the page to freeze.
 * However, if we replace the `ReferenceManyField` with a component like `List` or `ListBase`, the editable datagrid will not render a create button,
 * and using a custom create button will redirect to the standalone create page instead of rendering the create form in the datagrid.
 *
 * This custom component is a workaround to the above issue.
 * We can probably replace it with `DocumentFilter` if we move to a standalone document list page in DC as part of associating docs to multiple entities
 * (https://rockcreek.atlassian.net/browse/RDT-105).
 *
 * @param filters: the filters to be applied to the document datagrid
 * @param setFilters: the function to update the filters, which will refetch the document list
 * @returns
 */

export function CustomDocumentFilterToolbar({
  filters,
  setFilters,
}: CustomFilterToolbarProps) {
  const { data: documentTypeEnumList } = useGetList("documentTypeEnum", {
    sort: { field: "name", order: "ASC" },
    filter: {
      documentCategoryEnumIds: filters.accessLevel === 4 ? [4] : [1, 2, 3],
    },
  });
  const { data: documentAccessLevelList } = useGetList("documentAccessLevel", {
    sort: { field: "id", order: "ASC" },
  });

  const handleChange = (key: DocumentToolbarFilterProps, value: any) => {
    if (!value) {
      setFilters({ ...filters, [key]: undefined });
    }
    setFilters({ ...filters, [key]: value });
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Box display="flex" justifyContent="space-evenly" marginTop={3}>
        <FormControlLabel
          control={
            <Switch
              checked={!!filters.isApproved}
              size="small"
              onChange={e => handleChange("isApproved", e.target.checked)}
            />
          }
          label="Show Approved"
          sx={{ marginLeft: 2 }}
        />

        <Autocomplete
          options={documentAccessLevelList || []}
          getOptionLabel={option => capitalizedName(option)}
          value={documentAccessLevelList?.find(
            documentAccessLevel =>
              documentAccessLevel.id === filters.accessLevel
          )}
          onChange={(event, value, reason) => {
            if (reason === "clear") {
              handleChange("accessLevel", null);
            }
            handleChange("accessLevel", value?.id);
          }}
          renderInput={params => (
            <TextField {...params} label={"Access Level"} />
          )}
          sx={{ width: "20%" }}
        />
        <Autocomplete
          options={documentTypeEnumList || []}
          getOptionLabel={option => option.name}
          value={documentTypeEnumList?.find(
            documentTypeEnum =>
              documentTypeEnum.id === filters.documentTypeEnumId
          )}
          onChange={(event, value, reason) => {
            if (reason === "clear") {
              handleChange("documentTypeEnumId", null);
            }
            handleChange("documentTypeEnumId", value?.id);
          }}
          renderInput={params => <TextField {...params} label={"Type"} />}
          sx={{ width: "25%" }}
        />
        <DatePicker
          label="Date - From"
          format={"MM/dd/yyyy"}
          views={["day", "month", "year"]}
          value={filters.minDate}
          onChange={date => handleChange("minDate", date)}
          slotProps={{
            actionBar: {
              actions: ["today", "clear"],
            },
            clearIcon: {
              sx: { width: "16%" },
            },
          }}
          // ignoreInvalidInputs
        />
        <DatePicker
          label="Date - To"
          format={"MM/dd/yyyy"}
          views={["day", "month", "year"]}
          value={filters.maxDate}
          onChange={date => handleChange("maxDate", date)}
          slotProps={{
            actionBar: {
              actions: ["today", "clear"],
            },
            clearIcon: {
              sx: { width: "16%" },
            },
          }}
          // ignoreInvalidInputs
        />
      </Box>
    </LocalizationProvider>
  );
}
