import * as client from "_graphql-types";
import * as types from "./types";

type Sdk = ReturnType<typeof client.getSdk>;

/*********************************************************************/
// Data Provider Functions
/*********************************************************************/

/*********************************************************************/
async function getList(
  sdk: Sdk,
  params: types.GetListParams<client.LiquiditySortEnum>
) {
  const {
    pagination: { page, perPage },
    sort: { field, order },
  } = params;

  const {
    liquidityList: { items: data, total },
  } = await sdk.getListLiquidity({
    page: {
      offset: (page - 1) * perPage,
      limit: perPage,
    },
    sort: [{ field, order }],
  });

  return {
    data,
    total,
  };
}

/*********************************************************************/

async function getManyReference(
  sdk: Sdk,
  params: types.GetManyReferenceParams<
    client.LiquiditySortEnum,
    client.LiquidityFilter
  >
) {
  const {
    pagination: { page, perPage },
    sort: { field, order },
    target,
    filter,
  } = params;

  const {
    liquidityList: { items: data, total },
  } = await sdk.getListLiquidity({
    page: {
      offset: (page - 1) * perPage,
      limit: perPage,
    },
    sort: [{ field, order }],
    filter: {
      ...filter,
      [target]: params.id,
    },
  });

  return {
    data,
    total,
  };
}
/*********************************************************************/
async function getOne(sdk: Sdk, params: types.GetOneParams) {
  const id = Number(params.id);
  const { liquidity: data } = await sdk.getOneLiquidity({
    id,
  });
  return {
    data,
  };
}

/*********************************************************************/
async function getMany(sdk: Sdk, params: types.GetManyParams) {
  const ids = params.ids.map(Number);

  const { liquidityMany: data } = await sdk.getManyLiquidity({
    ids,
  });

  return {
    data,
  };
}

/*********************************************************************/
export const liquidity = types.dataProvider({
  getList,
  getOne,
  getMany,
  getManyReference,
});
