import { Autocomplete, TextField } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { camelCase, get } from "lodash";
import { useEffect, useState } from "react";
import {
  useDataProvider,
  useInput,
  useRecordContext,
  useResourceDefinition,
} from "react-admin";
import { useWatch } from "react-hook-form";
import { Record as CompanyRecord } from "./company/Edit";
import { Record as DealRecord } from "./deal/Edit";

type Record =
  | NonNullable<CompanyRecord["firmAttributes"]["firmTags"]>[number]
  | DealRecord["dealTags"][number]
  | NonNullable<
      NonNullable<DealRecord["company"]>["firmAttributes"]["firmTags"]
    >[number];
type Tag = Record["tag"];
type TagClass = Tag["tagClass"];

function formatSelections(selectedTags?: Record[]): Tag[] {
  if (!selectedTags) {
    return [];
  }
  return selectedTags.map(entityTag => entityTag.tag);
}

interface Props {
  source: string;
  tagClassIdList: number[];
  readOnly?: boolean;
  label?: string; // seems unused
}

export const CustomTagsInput = (props: Props) => {
  const { tagClassIdList, readOnly = false, source } = props;

  // This needs to be memoized, to prevent POSTs on every rerender.
  const [tagClassIds, setTagClassIds] = useState<number[]>([]);
  useEffect(() => {
    if (
      tagClassIdList.length === tagClassIds.length &&
      tagClassIdList.every((v, i) => v === tagClassIds[i])
    )
      return;
    setTagClassIds(tagClassIdList);
  }, [tagClassIdList]);

  const record = useRecordContext<Record>();
  const { name: resourceName } = useResourceDefinition();
  const formData = useWatch();

  const dataProvider = useDataProvider();

  const [selectedTags, setSelectedTags] = useState<Tag[]>(
    formatSelections(get(record, source) as Record[])
  );
  console.log("resourcename: ", resourceName);
  console.log("source: ", source);
  console.log("record: ", JSON.stringify(record));
  console.log("get(record, source): ", JSON.stringify(get(record, source)));
  console.log("initial selectedTags: ", JSON.stringify(selectedTags));

  useEffect(() => {
    let fData = { ...formData };
    let setTags = setSelectedTags;
    async function fetchReferencedData() {
      const company = await dataProvider.getOne("company", {
        id: formData?.company?.id,
      });
      fData = { ...formData, company: company.data };
      setTags([...formatSelections(get(fData, source))]);
    }
    if (
      resourceName === "deal" &&
      source.split(".")[0] === "company" &&
      !!formData?.company?.id
    ) {
      fetchReferencedData();
    } else {
      setTags([...formatSelections(get(fData, source))]);
    }

    return () => {
      /* race condition where setSelectedTags is called 
      with old source if component rerenders during fetchReferencedData */
      setTags = () => {};
    };
  }, [source, resourceName, dataProvider, formData]);

  const [choices, setChoices] = useState<Tag[]>();
  const [tagClasses, setTagClasses] = useState<TagClass[]>();

  const {
    field: { onChange },
  } = useInput({ source });

  useEffect(() => {
    async function fetchChoices() {
      const choices = await dataProvider.getList("tag", {
        filter: {
          tagClassIds: tagClassIds,
        },
        sort: {
          field: "name",
          order: "ASC",
        },
        pagination: {
          page: 1,
          perPage: 25,
        },
      });
      setChoices(choices.data as Tag[]);
    }

    fetchChoices();
  }, [dataProvider, tagClassIds]);

  useEffect(() => {
    async function fetchTagClasses() {
      const choices = await dataProvider.getMany("tagClasses", {
        ids: tagClassIds,
      });
      setTagClasses(choices.data as TagClass[]);
    }

    fetchTagClasses();
  }, [dataProvider, tagClassIds]);

  function handleChangeMultiple(value: Tag[]) {
    if (!value || !value.length) return;
    const otherClassTags = selectedTags.filter(
      tag => tag.tagClassId !== value[0].tagClassId
    );
    const newTags = [...otherClassTags, ...value];
    setSelectedTags(newTags);
    onChange(newTags.map(tag => ({ tag, tagId: tag.id })));
  }

  function handleChange(value: Tag[], reason: string, tagClassId?: number) {
    if (reason === "clear" || (reason === "removeOption" && !value?.length)) {
      const otherClassTags = selectedTags.filter(
        tag => tag.tagClassId !== tagClassId
      );
      setSelectedTags(otherClassTags);
      onChange(otherClassTags.map(tag => ({ tag, tagId: tag.id })));
    } else {
      handleChangeMultiple(value);
    }
  }

  return (
    <Box sx={{ marginLeft: 10, marginRight: 10, marginBottom: 20 }}>
      <Grid container spacing={2}>
        {choices &&
          tagClasses &&
          tagClasses.map(({ id: tagClassId, name: tagClassName }) => {
            return (
              <Grid key={tagClassId} item xs={8} md={6}>
                <Autocomplete
                  multiple
                  readOnly={readOnly}
                  id={`field${tagClassId}`}
                  options={choices?.filter(
                    choice => choice.tagClassId === tagClassId
                  )}
                  getOptionLabel={option =>
                    option.name ?? "(missing option.name)"
                  }
                  style={{ width: 300 }}
                  value={selectedTags.filter(
                    tag => tag.tagClassId === tagClassId
                  )}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  filterSelectedOptions
                  autoComplete={false}
                  renderInput={params => (
                    <TextField
                      {...params}
                      name={camelCase(tagClassName)}
                      label={tagClassName}
                      variant="outlined"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new",
                      }}
                    />
                  )}
                  onChange={(event: any, value: any, reason: any) => {
                    handleChange(value, reason, tagClassId);
                  }}
                />
              </Grid>
            );
          })}
      </Grid>
    </Box>
  );
};
