import {
  Datagrid,
  EditButton,
  List,
  TextField,
  FunctionField,
  useResourceDefinition,
} from "react-admin";
import { PortalFirmFilter } from "./Filter";
import CustomFlag from "../CustomFlag";
import CustomDeleteButton from "../CustomDeleteButton";
import { ListActions } from "./ListActions";

export const PortalFirmList = () => {
  const { name } = useResourceDefinition();
  return (
    <List
      sx={{
        ".RaFilter-form": {
          flex: "0 1 50%",
        },
      }}
      filters={<PortalFirmFilter />}
      sort={{ field: "nameSearchRank", order: "ASC" }}
      actions={<ListActions />}
      resource={name}
    >
      <Datagrid rowClick="edit" bulkActionButtons={false}>
        <TextField label="Name" source="name" />

        <TextField
          label="Organization"
          source="organization.name"
          sortable={false}
        />
        <FunctionField
          label="Deleted?"
          render={(investment: any) =>
            investment.deleteDate ? <CustomFlag label="Deleted" /> : null
          }
          sortable={false}
        />
        <EditButton />
        <CustomDeleteButton />
      </Datagrid>
    </List>
  );
};
