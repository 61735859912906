import { VersionChecker } from "./ApplicationVersionCheck";
import { CustomAdmin } from "./CustomAdmin";
import { DataProviderContextProvider } from "./DataProviderContext";
import { DisplayThemeContextProvider } from "./DisplayThemeContext";

export const Home = () => {
  console.log("HOME");

  return (
    <DisplayThemeContextProvider>
      <DataProviderContextProvider>
        <VersionChecker />
        <CustomAdmin />
      </DataProviderContextProvider>
    </DisplayThemeContextProvider>
  );
};
