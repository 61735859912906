import * as client from "_graphql-types";
import * as types from "./types";

import { filterSortPage } from "../DataProvider";

type Sdk = ReturnType<typeof client.getSdk>;

/*********************************************************************/
// Data Provider Functions
/*********************************************************************/

/*********************************************************************/
async function getList(
  sdk: Sdk,
  params: types.GetListParams<client.DeiCategory1EnumSortEnum>
) {
  const { pagination, sort } = params;

  const {
    deiCategory1EnumList: { items: data, total },
  } = await sdk.getListDeiCategory1Enum(filterSortPage({ sort, pagination }));

  return {
    data,
    total,
  };
}

/*********************************************************************/
async function getOne(sdk: Sdk, params: types.GetOneParams) {
  const id = Number(params.id);
  const { deiCategory1Enum: data } = await sdk.getOneDeiCategory1Enum({
    id,
  });
  return {
    data,
  };
}

/*********************************************************************/
async function getMany(sdk: Sdk, params: types.GetManyParams) {
  const ids = params.ids.map(id => Number(id));

  const { deiCategory1EnumMany: data } = await sdk.getManyDeiCategory1Enum({
    ids,
  });

  return {
    data,
  };
}

/*********************************************************************/
export const deiCategory1Enum = types.dataProvider({
  getList,
  getOne,
  getMany,
  ttl: types.defaultEnumTtl,
});
