import { SxProps, TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { Theme } from "@mui/system";
import React, { useEffect, useState } from "react";
import { SortPayload, required, useGetList } from "react-admin";
import { useWatch } from "react-hook-form";
import { useInput } from "../react-admin-fixes";

export interface IdName {
  id: number | string;
  name: string;
}

export function EntityAutoComplete({
  resource,
  sourceKey,
  hidden,
  label,
  recordKey,
  style,
  isRequired,
  filters,
  formatOption,
  onChange: onChangeProp,
  sort,
  optionValue = "id",
  clearSearchOnSelect,
  name,
  disabled = false,
  allowMultiple = false,
  inputId = allowMultiple
    ? `${resource}-entity-input-many`
    : `${resource}-entity-input`,
  renderOption,
  sx,
}: {
  isRequired?: boolean;
  hidden?: boolean;
  label: string;
  recordKey: string;
  style?: React.CSSProperties;
  resource: string;
  sourceKey?: string;
  sort?: SortPayload;
  filters?: { [key: string]: any };
  formatOption?: Parameters<typeof Autocomplete<IdName>>[0]["getOptionLabel"];
  onChange?: (record: IdName | IdName[] | null) => void;
  optionValue?: keyof IdName;
  clearSearchOnSelect?: boolean;
  name?: string;
  disabled?: boolean;
  allowMultiple?: boolean;
  inputId?: string;
  renderOption?: Parameters<
    typeof Autocomplete<IdName, boolean>
  >[0]["renderOption"];
  sx?: SxProps<Theme>;
}) {
  const [entity] = useWatch({
    name: [recordKey],
  });

  const [searchValue, setSearchValue] = useState("");
  const [selectedOption, setSelectedOption] = useState<
    IdName[] | IdName | null
  >(entity);

  useEffect(() => {
    console.log("selectedOption", selectedOption);
  }, [selectedOption]);

  const { data, isLoading } = useGetList<IdName>(resource, {
    pagination: {
      page: 1,
      perPage: 15,
    },
    sort: sort
      ? sort
      : {
          field: "name",
          order: "ASC",
        },
    filter: {
      q: searchValue,
      ...filters,
    },
  });

  const input = useInput({
    source: sourceKey || recordKey,
    ...(isRequired && !hidden && { validate: [required()] }),
  });
  const {
    field: { onChange, value },
    fieldState: { isTouched, error },
  } = input;

  return (
    <Autocomplete<IdName, boolean>
      multiple={allowMultiple}
      id={inputId}
      data-cy={inputId}
      style={style}
      sx={sx}
      autoSelect={true}
      value={selectedOption}
      className={hidden ? "hidden" : ""}
      options={data || []}
      loading={isLoading}
      getOptionLabel={option =>
        formatOption ? formatOption(option) : option.name
      }
      noOptionsText="No matches"
      filterOptions={options => options}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      renderInput={params => (
        <TextField
          name={name}
          {...params}
          label={label}
          variant="outlined"
          error={!!error}
          helperText={error ? "Required" : "Type to display matches"}
          required={isRequired}
        />
      )}
      inputValue={searchValue}
      onInputChange={async (event, value, reason) => {
        if (clearSearchOnSelect && reason === "reset") {
          setSearchValue("");
          return;
        } else {
          setSearchValue(value);
        }
      }}
      onChange={(event, value, reason) => {
        setSelectedOption(value);
        onChange(
          !sourceKey
            ? value
            : Array.isArray(value)
            ? value.map(v => v[optionValue])
            : value && value[optionValue]
        );
        onChangeProp?.(value);
      }}
      onClose={(event, reason) => {
        if (["blur", "escape", "toggleInput"].includes(reason)) {
          const value = selectedOption;
          setSelectedOption(value);
          onChange(
            !sourceKey
              ? value
              : Array.isArray(value)
              ? value.map(v => v[optionValue])
              : value && value[optionValue]
          );
          onChangeProp?.(value);
        }
      }}
      readOnly={disabled}
      renderOption={renderOption}
    />
  );
}
