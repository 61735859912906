import * as client from "_graphql-types";
import { CustomEdit } from "../CustomEdit";
import Form from "./Form";

type Record = NonNullable<client.GetOneInstitutionQuery["institution"]>;

export const OfficeLocationEdit = () => {
  return (
    <CustomEdit<Record>
      title={record => record && `Office Location : ${record.name || ""}`}
    >
      <Form />
    </CustomEdit>
  );
};
