import { BooleanInput, Filter, TextInput } from "react-admin";

export const PortalFirmFilter = () => (
  <Filter>
    <TextInput
      label="Name Search"
      source="q"
      alwaysOn
      style={{ width: 500 }}
      resettable
    />
    <BooleanInput label="Should Update" source="shouldUpdate" alwaysOn />
    <BooleanInput label="Include Deleted" source="includeDeleted" alwaysOn />
  </Filter>
);
