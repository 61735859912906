import CheckCircle from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Pending from "@mui/icons-material/Pending";
import CardHeader from "@mui/material/CardHeader";
import CircularProgress from "@mui/material/CircularProgress";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import * as client from "_graphql-types";
import { useEffect, useMemo, useRef, useState } from "react";

import { Grid } from "@mui/material";
import {
  ArrayInput,
  BooleanInput,
  DateInput,
  FileInput,
  FormDataConsumer,
  FormDataConsumerRenderParams,
  ReferenceInput,
  SelectInput,
  SimpleFormIterator,
  required,
  useCreate,
  useNotify,
} from "react-admin";
import { useFormContext, useWatch } from "react-hook-form";
import { CustomCreate } from "../CustomCreate";
import { EntityInput } from "../UI/EntityInput";

import {
  COMPANY_BUSINESS_OBJECT_ENUM,
  DEAL_BUSINESS_OBJECT_ENUM,
  FIRM_BUSINESS_OBJECT_ENUM,
  INVESTMENT_BUSINESS_OBJECT_ENUM,
} from "_resources/document";
import { format, parse } from "date-fns";
import {
  CLIENT_DOC_CATEGORIES,
  NON_CLIENT_DOC_CATEGORIES,
  capitalizedName,
  findDate,
  getDocumentRoute,
} from "./helpers";

const PARSE_PAGES = 2;

function getDateWarning(strDate?: string) {
  if (strDate) {
    const date = parse(strDate, "yyyy-MM-dd", new Date());
    const monthDiff =
      (date.getTime() - Date.now()) / (31 * 24 * 60 * 60 * 1000);
    if (monthDiff < -3) return "More than 3 months in the past";
    if (3 < monthDiff) return "More than 3 months in the future";
  }
}

type Record = NonNullable<client.GetOneDocumentQuery["document"]>;

export const DocumentEntityInput = ({
  allowChange,
}: {
  allowChange: boolean;
}) => {
  const businessObjectEnumId: number | undefined = Number(
    useWatch({
      name: "businessObjectEnumId",
    })
  );
  return (
    <>
      <EntityInput
        resource="firm"
        recordKey="firm"
        label="Firm"
        isRequired={true}
        allowChange={allowChange}
        hidden={businessObjectEnumId !== FIRM_BUSINESS_OBJECT_ENUM}
      />
      <EntityInput
        resource="investment"
        recordKey="investment"
        label="Investment"
        isRequired={true}
        hidden={businessObjectEnumId !== INVESTMENT_BUSINESS_OBJECT_ENUM}
        allowChange={allowChange}
      />
      <EntityInput
        resource="company"
        recordKey="company"
        label="Company"
        isRequired={true}
        hidden={businessObjectEnumId !== COMPANY_BUSINESS_OBJECT_ENUM}
        allowChange={allowChange}
      />
      <EntityInput
        resource="deal"
        recordKey="deal"
        label="Deal"
        isRequired={true}
        hidden={businessObjectEnumId !== DEAL_BUSINESS_OBJECT_ENUM}
        allowChange={allowChange}
      />
    </>
  );
};

const validateRequired = [required()];

const DocumentRowForm = ({ scopedFormData }: FormDataConsumerRenderParams) => {
  const [getSuggestions] = useCreate("documentTypeSuggestions");
  const [createDraft, { isLoading, isSuccess, isError }] = useCreate();
  const { setValue, formState, setError } = useFormContext();
  const [suggestedDocumentType, setSuggestedDocumentType] = useState<
    number | null
  >(null);

  const text: string = useWatch({ name: "text" });
  const suggestedDate: string = useWatch({
    name: "suggestedDate",
  });
  const file: File = useWatch({ name: "file" });
  const draftId: number = useWatch({ name: "draftId" });
  const overrideDate: number = useWatch({ name: "overrideDate" });
  const overrideDocumentTypeEnumId: number = useWatch({
    name: "overrideDocumentTypeEnumId",
  });
  const overrideAccessLevel: number = useWatch({ name: "overrideAccessLevel" });

  const accessLevel = useWatch({ name: "accessLevel" });

  const documentTypeFilter = useMemo(() => {
    return {
      documentCategoryEnumIds:
        accessLevel === 4 ? CLIENT_DOC_CATEGORIES : NON_CLIENT_DOC_CATEGORIES,
    };
  }, [accessLevel]);

  useEffect(() => {
    // weird react admin design decision - https://github.com/marmelab/react-admin/issues/5022
    // we have to manully remove invalid selected items from lists when the filter changes
    setValue("documentTypeEnumId", null);
  }, [String(documentTypeFilter.documentCategoryEnumIds)]);

  const previousTextRef = useRef<string>();

  // Getting the suggested doc tpye when text changes, also triggers when drafts change
  useEffect(() => {
    if (!text || suggestedDocumentType || overrideDocumentTypeEnumId) return;

    getSuggestions(
      "documentTypeSuggestions",
      { data: { text } },
      {
        onSettled: (data, error) => {
          setValue("documentTypeEnumId", data.documentTypeSuggestion);
          setSuggestedDocumentType(data.documentTypeSuggestion);
        },
      }
    );

    previousTextRef.current = text;
  }, [text, suggestedDocumentType, overrideDocumentTypeEnumId]);

  useEffect(() => {
    if (!overrideDate) return;
    setValue("date", overrideDate);
  }, [overrideDate]);

  useEffect(() => {
    if (!overrideDocumentTypeEnumId) return;
    setValue("documentTypeEnumId", overrideDocumentTypeEnumId);
  }, [overrideDocumentTypeEnumId]);

  useEffect(() => {
    if (!overrideAccessLevel) return;
    setValue("accessLevel", overrideAccessLevel);
  }, [overrideAccessLevel]);

  const [date, documentTypeEnumId]: [string?, number?] = useWatch({
    name: ["date", "documentTypeEnumId"],
  });

  const dateWarning = getDateWarning(date);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {isSuccess || draftId ? (
          <CheckCircle color="success" style={{ verticalAlign: "text-top" }} />
        ) : isLoading ? (
          <CircularProgress style={{ verticalAlign: "text-top" }} />
        ) : isError || scopedFormData?.error ? (
          <ErrorIcon style={{ verticalAlign: "text-top" }} />
        ) : (
          <Pending style={{ verticalAlign: "text-top" }} />
        )}{" "}
        {scopedFormData?.title}
      </Grid>
      <Grid item xs={4}>
        <DateInput
          source="date"
          validate={validateRequired}
          helperText={[
            suggestedDate &&
              date === suggestedDate &&
              "Suggested date based on document content",
            dateWarning,
          ]
            .filter(Boolean)
            .join(", ")}
          readOnly={!!overrideDate}
          InputProps={{
            style: {
              backgroundColor: dateWarning ? "yellow" : undefined,
            },
          }}
        />
      </Grid>
      <Grid item xs={4}>
        <ReferenceInput
          source="accessLevel"
          reference="documentAccessLevel"
          sort={{ field: "id", order: "ASC" }}
        >
          <SelectInput
            optionText={capitalizedName}
            label="Access Level"
            validate={validateRequired}
            readOnly={!!overrideAccessLevel}
          />
        </ReferenceInput>
      </Grid>
      {accessLevel === 4 ? (
        <>
          <Grid item xs={2}>
            <BooleanInput source="isApproved" label="Approved" />
          </Grid>
          <Grid item xs={2}>
            <BooleanInput source="notifyUsers" label="Notify Users" />
          </Grid>
        </>
      ) : (
        <Grid item xs={4}></Grid>
      )}
      <Grid item xs={6}>
        <ReferenceInput
          source="documentTypeEnumId"
          reference="documentTypeEnum"
          sort={{ field: "name", order: "ASC" }}
          filter={documentTypeFilter}
        >
          <SelectInput
            optionText="name"
            label="Type"
            validate={validateRequired}
            defaultValue={suggestedDocumentType}
            helperText={
              suggestedDocumentType &&
              documentTypeEnumId === suggestedDocumentType
                ? "Suggested type based on document content"
                : ""
            }
            readOnly={!!overrideDocumentTypeEnumId}
          />
        </ReferenceInput>
      </Grid>
      <Grid item xs={3}>
        <BooleanInput source="isGallery" label="Show in image gallery" />
      </Grid>
      <Grid item xs={3}>
        {/* To be implemented */}
        <BooleanInput source="allowDuplicateFile" readOnly />
      </Grid>
    </Grid>
  );
};

const FileHandler = ({}: {}) => {
  const { setValue } = useFormContext();
  const [createDraft, { isLoading, isSuccess, isError }] = useCreate();
  const [processDraft] = useCreate();
  const [uploadStartTime, setUploadStartTime] = useState<Date | null>(null);
  const notify = useNotify();

  const drafts = useWatch({ name: "drafts" });
  const overrideDate: number = useWatch({ name: "override-date" });
  const overrideDocumentTypeEnumId: number = useWatch({
    name: "overrideDocumentTypeEnumId",
  });
  useEffect(() => {
    if ((drafts || []).every((d: any) => d.draftId)) {
      console.log("all drafts processed");
      if (uploadStartTime) {
        const executionTime = new Date().getTime() - uploadStartTime.getTime();
        notify(
          `${drafts.length} drafts processed in ${
            executionTime / 1000
          } seconds`,
          {
            type: "info",
            messageArgs: { smart_count: 1 },
          }
        );
      }
      setUploadStartTime(null);
    }
  }, [drafts]);

  return (
    <>
      <ArrayInput source="drafts" label="Drafts">
        <SimpleFormIterator fullWidth disableReordering disableAdd>
          <FormDataConsumer>
            {row => (
              <DocumentRowForm key={row.scopedFormData?.title} {...row} />
            )}
          </FormDataConsumer>
        </SimpleFormIterator>
      </ArrayInput>
      <Grid item xs={10}>
        <FileInput
          source={"_file"}
          label="File"
          validate={validateRequired}
          multiple
          onChange={async e => {
            console.log("e", e);
            console.log("drafts", drafts);
            setUploadStartTime(new Date());

            if (!e || e.type === "change") return;
            const changedFiles = [e].flat();

            // Do not process raw files, these are previously uploaded files that
            // are now irrelevant
            let files = changedFiles.filter(file => !file.rawFile);
            console.log("files", files);

            const startI = (drafts || []).length;

            setValue(
              "drafts",
              !drafts
                ? [...files.map(() => ({}))]
                : [...drafts, ...files.map(() => ({}))]
            );

            createDraft(
              "draftDocument",
              { data: { files } },
              {
                onSettled: async (
                  data: {
                    drafts: {
                      url: string;
                      draftId: number;
                      key: string;
                      bucket: string;
                      file: File;
                    }[];
                  },
                  error
                ) => {
                  if (error) {
                    throw Error(`error creating draft`);
                  }
                  console.log("Draft data:", data);

                  data.drafts.forEach(async (d, i) => {
                    const file = files[i];

                    await fetch(d.url, {
                      method: "PUT",
                      body: file,
                      headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                      },
                    })
                      .then(async response => {
                        if (response.ok) {
                          console.log("file uploaded:", file.name);
                          return processDraft(
                            "draftDocumentProcessing",
                            {
                              data: {
                                draftId: d.draftId,
                              },
                            },
                            {
                              onError: e => {
                                throw e;
                              },
                            }
                          );
                        } else {
                          console.error("file upload failed:", file.name);
                          setValue(
                            `drafts.${startI + i}.error`,
                            response.statusText
                          );
                        }
                      })
                      .then(() => {
                        setValue(`drafts.${startI + i}.draftId`, d.draftId);
                      });
                  });
                },
              }
            );

            for (let i = 0; i < files.length; i++) {
              let file = files[i];
              setValue(`drafts.${(drafts || []).length + i}.file`, file);
              setValue(`drafts.${(drafts || []).length + i}.title`, file.path);

              //only parse text if it's a pdf and no overrideDocumentTypeEnumId
              if (
                file.name.split(".").at(-1) === "pdf" &&
                !overrideDocumentTypeEnumId &&
                !overrideDate
              ) {
                const pdfjsLib = await import("pdfjs-dist");
                const _buffer = await file.arrayBuffer();

                pdfjsLib.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.js`;

                const doc = await pdfjsLib.getDocument(_buffer).promise;

                const content = await Promise.all(
                  Array.from({
                    length: Math.min(PARSE_PAGES, doc.numPages),
                  }).map(async (_, i) => {
                    const page = await doc.getPage(i + 1);
                    return await page.getTextContent();
                  })
                );

                const text = content
                  .map(pageContent =>
                    pageContent.items
                      .map(item => ("str" in item ? item.str : item))
                      .join(" ")
                  )
                  .flat()
                  .join(" ");

                setValue(`drafts.${(drafts || []).length + i}.text`, text);
                if (text.length > 0 && !overrideDate) {
                  const _suggestedDate = findDate(e.name + " " + text);

                  if (_suggestedDate) {
                    try {
                      setValue(
                        `drafts.${(drafts || []).length + i}.date`,
                        format(_suggestedDate, "yyyy-MM-dd")
                      );
                      setValue(
                        `drafts.${(drafts || []).length + i}.suggestedDate`,
                        format(_suggestedDate, "yyyy-MM-dd")
                      );
                    } catch (e) {
                      //recover from date parsing error
                      console.error(e);
                    }
                  }
                }
              }
            }
          }}
        ></FileInput>
      </Grid>
    </>
  );
};

const RedirectPathGetter = ({
  changeRedirectPath,
}: {
  changeRedirectPath: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const [businessObjectEnumId, investment, firm, company, deal] = useWatch({
    name: [
      "businessObjectEnumId",
      "investment.id",
      "firm.id",
      "company.id",
      "deal.id",
    ],
  });

  const route = getDocumentRoute(
    businessObjectEnumId,
    entityName => ({ company, deal, firm, investment }[entityName])
  );
  if (route) changeRedirectPath(route);

  return null;
};

export const DocumentCreate = () => {
  const [redirectPath, changeRedirectPath] = useState("/");
  const [overrideOpen, setOverrideOpen] = useState(false);

  return (
    <CustomCreate<Record>
      customFormProps={{
        customToolbarProps: {
          redirectToPathOnSave: redirectPath,
          allowSave: true,
        },
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <ReferenceInput
            source="businessObjectEnumId"
            reference="businessObjectEnum"
          >
            <SelectInput
              optionText="name"
              label="Business Object"
              validate={validateRequired}
            />
          </ReferenceInput>
        </Grid>
        <Grid item xs={5}>
          <DocumentEntityInput allowChange />
        </Grid>
        <Grid item xs={4}>
          <CardHeader
            title={<p style={{ fontSize: 15 }}>Set For All Docs</p>}
            action={
              <IconButton
                onClick={() => setOverrideOpen(!overrideOpen)}
                aria-label="expand"
                size="small"
              >
                {overrideOpen ? (
                  <KeyboardArrowUpIcon />
                ) : (
                  <KeyboardArrowDownIcon />
                )}
              </IconButton>
            }
          />
          <Collapse in={overrideOpen}>
            <Grid container spacing={2}>
              {" "}
              <Grid item xs={4}>
                <DateInput source={"overrideDate"} />
              </Grid>
              <Grid item xs={4}>
                <ReferenceInput
                  source={"overrideDocumentTypeEnumId"}
                  reference="documentTypeEnum"
                  sort={{ field: "name", order: "ASC" }}
                >
                  <SelectInput optionText="name" label="Type" />
                </ReferenceInput>
              </Grid>
              <Grid item xs={4}>
                <ReferenceInput
                  source={"overrideAccessLevel"}
                  reference="documentAccessLevel"
                  sort={{ field: "id", order: "ASC" }}
                >
                  <SelectInput
                    optionText={capitalizedName}
                    label="Access Level"
                  />
                </ReferenceInput>
              </Grid>
            </Grid>
          </Collapse>
        </Grid>
      </Grid>
      <FileHandler />
      <RedirectPathGetter changeRedirectPath={changeRedirectPath} />
    </CustomCreate>
  );
};
