import { WarningOutlined } from "@ant-design/icons";
import ClearIcon from "@mui/icons-material/Clear";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  FormControl,
  InputAdornment,
  Modal,
  OutlinedInput,
  Stack,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { DesktopDatePicker as DatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { captureException } from "@sentry/react";
import {
  GetPerformanceBackfillInvestmentListQuery,
  ReturnTypes,
} from "_graphql-types";
import {
  PerformanceMultiInvestmentEntry,
  UpdateArgs,
} from "_resources/performanceMultiInvestment";
import { format } from "date-fns";
import { Fragment, useMemo, useState } from "react";
import { useDataProvider, useGetMany, useNotify } from "react-admin";
import { useDebouncedCallback } from "use-debounce";
import XLSX from "xlsx";
import { findReturnLabel } from "../performance/helpers";
import YTDColumn from "./YTDColumn";

type Investment =
  GetPerformanceBackfillInvestmentListQuery["investmentList"]["items"][number];

function parseReturnValue(value?: string) {
  value = value?.trim();
  if (!value) return null;
  if (value.endsWith("%")) value = value.slice(0, value.length - 1);
  let returnValue = Number(value);
  if (isFinite(returnValue)) return returnValue;
}

function parseInt(value?: string) {
  const fid = value ? Number(value) : undefined;
  const id = fid ? Math.trunc(fid) : undefined;
  return id === fid ? id : undefined;
}

function parseDate(value?: string) {
  const parts = value?.split("/", 3);
  const [m, d, y] = parts?.map(parseInt) ?? [];
  if (!m || m < 1 || 12 < m) return;
  if (!d || d < 1 || 31 < d) return;
  if (y === void 0 || y < 0 || y > new Date().getFullYear()) return;
  const date = new Date(y, m - 1, d);
  if (date > new Date()) return undefined;
  return date;
}

type BulkRecord = [
  { error: boolean; raw?: string; parsed?: number },
  { error: boolean; raw?: string; parsed?: Date },
  { error: boolean; raw?: string; parsed?: number | null }
];

type BulkParsed = { error: boolean; parsed: BulkRecord[] };

function parseBulk(value: string): BulkParsed {
  const lines = value
    .split("\n")
    .map(line => line.trim())
    .filter(line => line);
  const lineWords = lines.map(
    line =>
      line.split(/\s/g, 3).map(word => word.trim()) as (string | undefined)[]
  );
  const parsed = lineWords.map(([sid, sdate, svalue]) => {
    const id = parseInt(sid);
    const date = parseDate(sdate);
    const value = parseReturnValue(svalue);
    return [
      { error: id === void 0 || id < 0, raw: sid, parsed: id },
      { error: date === void 0, raw: sdate, parsed: date },
      { error: value === void 0, raw: svalue, parsed: value },
    ] as BulkRecord;
  });
  return { error: parsed.some(rec => rec.some(col => col.error)), parsed };
}

function parseSpreadsheet(file: File) {
  return new Promise<BulkParsed>((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = e => {
      try {
        const bstr = e.target!.result;
        const wb = XLSX.read(bstr, {
          type: !!reader.readAsBinaryString ? "binary" : "array",
          cellDates: true,
        });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];

        const data: any[] = XLSX.utils.sheet_to_json(ws, { header: 1 });
        const { i, j } = findReturnLabel(data);

        const parsed = parseReturnCells(i, j, data);
        resolve(parsed);
      } catch (e) {
        reject(e);
      }
    };
    return !!reader.readAsBinaryString
      ? reader.readAsBinaryString(file)
      : reader.readAsArrayBuffer(file);
  });
}

const MAX_RETURNS_COUNT = 500;

function parseReturnCells(i: number, j: number, data: any[][]): BulkParsed {
  const parsed: BulkRecord[] = [];
  for (++i; j <= MAX_RETURNS_COUNT; ++i) {
    if (!data[i]?.[j]) break;
    const id = parseInt(data[i][j]);
    const date = new Date(data[i][j + 1]);
    const rawValue = data[i][j + 2];
    const value =
      rawValue === undefined ||
      rawValue === null ||
      (typeof rawValue === "string" && !rawValue.trim().length)
        ? null
        : Math.round(rawValue * 100 * 10000) / 10000;
    parsed.push([
      {
        error: typeof id === "undefined",
        raw: String(data[i][j]),
        parsed: id!,
      },
      {
        error: !isFinite(date.getTime()),
        raw: String(data[i][j + 1]),
        parsed: date!,
      },
      { error: !isFinite(value ?? 0), raw: String(rawValue), parsed: value! },
    ]);
  }
  return { error: parsed.some(cols => cols.some(col => col.error)), parsed };
}

const ModalBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  backgroundColor: theme.palette.background.paper,
  border: "2px solid #000",
  boxShadow: theme.shadows[5],
  padding: theme.spacing(2, 4, 3),
}));

interface Message {
  value?: string;
  error?: string;
}

export function PerformanceMultiInvestment() {
  const date = new Date();
  const numQuarter = Math.trunc(
    (date.getFullYear() * 12 + date.getMonth()) / 3
  );
  const numMonth = date.getFullYear() * 12 + date.getMonth();

  const dataProvider = useDataProvider();
  const [isPrivate, setIsPrivate] = useState(false);
  const [isGross, setIsGross] = useState(false);
  const [isQtd, setIsQtd] = useState(false);
  const [asOfDate, setAsOfDate] = useState<Date | null>(
    isPrivate || isQtd
      ? new Date(Math.trunc(numQuarter / 4), (numQuarter % 4) * 3, 0)
      : new Date(Math.trunc(numMonth / 12), numMonth % 12, 0)
  );
  const [investmentInput, setInvestmentInput] = useState("");
  const [investmentOptions, setInvestmentOptions] = useState<Investment[]>([]);
  const setInvestmentOptionsDebounced = useDebouncedCallback(
    async (q: string) => {
      const { data } = await dataProvider.getList("investment", {
        filter: { q },
        sort: {
          field: "nameSearchRank",
          order: "ASC",
        },
        pagination: {
          page: 1,
          perPage: 20,
        },
      });
      setInvestmentOptions(data as Investment[]);
    },
    300
  );
  const [investmentPicks, setInvestmentPicks] = useState(
    new Map<number, Investment>()
  );
  const [values, setValues] = useState(new Map<number, string>());
  const [working, setWorking] = useState(false);
  const [message, setMessage] = useState<Message | undefined>(undefined);
  const [bulkModalOpen, setBulkModalOpen] = useState(false);
  const [bulkRaw, setBulkRaw] = useState("");
  const [bulkParsed, setBulkParsed] = useState<BulkParsed>({
    error: false,
    parsed: [],
  });
  const [bulkModalMessage, setBulkModalMessage] = useState<string>();
  const notify = useNotify();

  const {
    data,
    refetch,
  }: {
    data?: PerformanceMultiInvestmentEntry[];
    isLoading: boolean;
    error?: any;
    refetch: () => void;
  } = useGetMany("performanceMultiInvestment", {
    ids: [...investmentPicks.keys()],
    meta: {
      isPrivate,
      isGross,
      isQtd,
      asOfDate,
    },
  });

  const performance = useMemo(() => {
    const map = new Map<number, PerformanceMultiInvestmentEntry>();
    data?.forEach(entry => {
      console.log(entry.id, entry);
      map.set(entry.id, entry);
    });
    return map;
  }, [data]);

  const showYTD = !isPrivate && !isQtd;

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <h3>Multi-Investment Performance</h3>
      <Grid container spacing={5} alignItems="center">
        <Grid item>
          <Grid container component="label" spacing={1}>
            <Grid
              item
              style={{ textDecoration: !isPrivate ? "underline" : "none" }}
            >
              TWRR
            </Grid>
            <Grid item>
              <Switch
                size="small"
                checked={isPrivate}
                color="default"
                onChange={() => {
                  setIsPrivate(!isPrivate);
                }}
                data-cy="isPrivate"
              />
            </Grid>
            <Grid
              item
              style={{ textDecoration: isPrivate ? "underline" : "none" }}
            >
              IRR
            </Grid>
          </Grid>
        </Grid>
        {isPrivate || (
          <>
            <Grid item>
              <Grid container component="label" spacing={1}>
                <Grid
                  item
                  style={{ textDecoration: !isGross ? "underline" : "none" }}
                >
                  Net
                </Grid>
                <Grid item>
                  <Switch
                    size="small"
                    checked={isGross}
                    color="default"
                    onChange={() => {
                      setIsGross(!isGross);
                    }}
                    data-cy="isGross"
                  />
                </Grid>
                <Grid
                  item
                  style={{ textDecoration: isGross ? "underline" : "none" }}
                >
                  Gross
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container component="label" spacing={1}>
                <Grid
                  item
                  style={{ textDecoration: !isQtd ? "underline" : "none" }}
                >
                  MTD
                </Grid>
                <Grid item>
                  <Switch
                    size="small"
                    checked={isQtd}
                    color="default"
                    onChange={() => {
                      setIsQtd(!isQtd);
                    }}
                    data-cy="isQtd"
                  />
                </Grid>
                <Grid
                  item
                  style={{ textDecoration: isQtd ? "underline" : "none" }}
                >
                  QTD
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
        <Grid item>
          <Button
            data-cy="bulk-upload"
            variant="contained"
            onClick={() => setBulkModalOpen(true)}
          >
            Bulk Upload
          </Button>
          <Modal
            open={bulkModalOpen}
            onClose={() => {
              setBulkModalOpen(false);
              setBulkModalMessage("");
              setBulkRaw("");
              setBulkParsed({ error: false, parsed: [] });
            }}
          >
            <ModalBox>
              <h2>Bulk Performance</h2>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <TextField
                    data-cy="paste-performance"
                    label="Paste Performance"
                    variant="outlined"
                    multiline
                    minRows={11}
                    maxRows={11}
                    placeholder={[
                      "Format:",
                      "[investment ID] [date] [return]",
                      "",
                      "Example:",
                      "123 1/31/22 1.01%",
                      "123 2/28/22 2.02%",
                      "456 1/31/22 3.03%",
                    ].join("\n")}
                    fullWidth
                    style={{ height: "170px" }}
                    value={bulkRaw}
                    onChange={e => {
                      setBulkRaw(e.currentTarget.value);
                      const bulkParsed = parseBulk(e.currentTarget.value);
                      setBulkParsed(bulkParsed);
                      setBulkModalMessage(
                        bulkParsed.error
                          ? "The data contain errors. Please review."
                          : ""
                      );
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TableContainer style={{ height: "16em" }}>
                    <Table size="small" stickyHeader>
                      <TableHead>
                        <TableRow>
                          <TableCell>ID</TableCell>
                          <TableCell>Date</TableCell>
                          <TableCell>Return</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {bulkParsed.parsed.map(([id, date, value], i) => (
                          <TableRow key={i}>
                            <TableCell
                              style={{
                                backgroundColor: id.error
                                  ? "lightsalmon"
                                  : "initial",
                              }}
                            >
                              {id.error ? id.raw : id.parsed!}
                            </TableCell>
                            <TableCell
                              style={{
                                backgroundColor: date.error
                                  ? "lightsalmon"
                                  : "initial",
                              }}
                            >
                              {date.error || !date.parsed
                                ? date.raw
                                : format(date.parsed!, "MM/dd/yyyy")}
                            </TableCell>
                            <TableCell
                              style={{
                                textAlign: "right",
                                backgroundColor: value.error
                                  ? "lightsalmon"
                                  : "initial",
                              }}
                            >
                              {value.error
                                ? value.raw
                                : typeof value.parsed === "number"
                                ? String(value.parsed) + " %"
                                : ""}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
              <br />
              <Button variant="contained">
                <label>
                  Import from Spreadsheet
                  <input
                    data-id="performanceUpload"
                    type="file"
                    accept=".csv,.xlsx"
                    style={{ display: "none" }}
                    onChange={async e => {
                      const file = e.target.files?.[0];
                      if (file) {
                        try {
                          const parsed = await parseSpreadsheet(file);
                          setBulkRaw("");
                          setBulkParsed(parsed);
                          if (!parsed.error) {
                            notify(
                              `${parsed.parsed.length} new records parsed`,
                              { type: "success" }
                            );
                          }
                        } catch (error) {
                          setBulkParsed({ error: false, parsed: [] });
                          notify(`Error parsing spreadsheet: ${error}`, {
                            type: "error",
                          });
                        }
                      }
                      e.target.value = "";
                    }}
                  />
                </label>
              </Button>
              <br />
              <Button
                data-cy="save-bulk"
                variant="contained"
                color="primary"
                disabled={bulkParsed.error || !bulkParsed.parsed.length}
                onClick={async () => {
                  try {
                    if (bulkParsed.error || !bulkParsed.parsed.length) return;
                    const args: UpdateArgs = {
                      id: 0,
                      data: {
                        type: isPrivate ? "private" : isQtd ? "qtd" : "mtd",
                        returnType: isGross
                          ? ReturnTypes.Gross
                          : ReturnTypes.Net,
                        data: {
                          type: "bulk",
                          data: bulkParsed.parsed.map(([id, date, value]) => ({
                            investmentId: id.parsed!,
                            asOfDate: date.parsed!,
                            returnValue: value.parsed,
                          })),
                        },
                      },
                    };
                    await dataProvider.update("performanceMultiInvestment", {
                      ...args,
                      previousData: { id: 0 },
                    });
                    refetch();
                    setBulkParsed({ error: false, parsed: [] });
                    notify("Performance was saved successfully", {
                      type: "success",
                    });
                  } catch (error) {
                    captureException(error);
                    setBulkModalMessage(`An error occurred: ${error}`);
                    notify("An error occurred", { type: "error" });
                  }
                }}
              >
                Load These Records
              </Button>
              &emsp;
              <span style={{ color: "red" }}>{bulkModalMessage}</span>
            </ModalBox>
          </Modal>
        </Grid>
      </Grid>
      <Grid container spacing={5} alignItems="center">
        <Grid item>
          <DatePicker
            label="As-of Date"
            maxDate={new Date()}
            format="MM/dd/yyyy"
            value={asOfDate}
            onChange={setAsOfDate}
            slotProps={{
              textField: {
                variant: "standard",
                inputProps: {
                  "data-cy": "asOfDate",
                },
              },
            }}
            // ignoreInvalidInputs
          />
        </Grid>
        <Grid item>
          Period:{" "}
          {!asOfDate || !Number.isFinite(asOfDate.getTime())
            ? ""
            : format(asOfDate, isPrivate || isQtd ? "QQQ yyyy" : "MMM yyyy")}
        </Grid>
        <Grid item xs={12}></Grid>
      </Grid>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Grid container spacing={1} alignItems="center">
            <Grid item xs={1}></Grid>
            <Grid item xs={showYTD ? 5 : 7}>
              <b>Investment</b>
            </Grid>
            <Grid item xs={2}>
              <b>Currency</b>
            </Grid>
            <Grid item xs={2}>
              <b>Return</b>
            </Grid>
            {showYTD && (
              <Grid item xs={2}>
                <b>YTD</b>
              </Grid>
            )}
            {[...investmentPicks.entries()].map(([id, investment], ipick) => (
              <Fragment key={id}>
                <Grid
                  item
                  xs={12}
                  style={{ borderTop: "dashed 1px lightgray" }}
                ></Grid>
                <Grid item xs={1}>
                  <Grid container alignItems="center">
                    <Grid xs={6}>
                      <IconButton
                        onClick={() => {
                          const newPicks = new Map(investmentPicks);
                          newPicks.delete(id);
                          const newValues = new Map(values);
                          newValues.delete(id);
                          setInvestmentPicks(newPicks);
                          setValues(newValues);
                        }}
                        data-cy="remove"
                        size="large"
                      >
                        <ClearIcon />
                      </IconButton>
                    </Grid>
                    <Grid xs={6} style={{ textAlign: "right" }}>
                      {ipick + 1}.
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={showYTD ? 5 : 7} data-cy="investment-name">
                  {investment.name}
                </Grid>
                <Grid item xs={2} data-cy="currency-name">
                  {performance?.get(investment.id)?.currency?.name ?? ""}
                </Grid>
                <Grid item xs={2}>
                  <FormControl variant="outlined" size="small">
                    <OutlinedInput
                      endAdornment={
                        <InputAdornment position="end">%</InputAdornment>
                      }
                      inputProps={{
                        style: { textAlign: "right" },
                      }}
                      value={values.get(investment.id) ?? ""}
                      onChange={e => {
                        const newValues = new Map(values);
                        newValues.set(investment.id, e.currentTarget.value);
                        setValues(newValues);
                      }}
                      placeholder={
                        performance
                          ?.get(investment.id)
                          ?.returnValue?.toFixed(2) ?? ""
                      }
                      data-cy="returnValue"
                    ></OutlinedInput>
                  </FormControl>
                </Grid>
                {showYTD && (
                  <Grid item xs={2} data-cy="investment-ytd">
                    <YTDColumn
                      performanceData={performance}
                      investmentId={investment.id}
                      values={values}
                    />
                  </Grid>
                )}
              </Fragment>
            ))}

            <Grid item xs={12}>
              <Autocomplete
                filterOptions={options => options}
                options={investmentOptions}
                renderInput={params => (
                  <TextField
                    {...params}
                    label="Select investment"
                    variant="outlined"
                  />
                )}
                onInputChange={(event, value, reason) => {
                  switch (reason) {
                    case "input":
                      setInvestmentOptionsDebounced(value);
                      setInvestmentInput(value);
                      break;
                    case "clear":
                      setInvestmentOptions([]);
                      setInvestmentInput("");
                      break;
                  }
                }}
                onChange={(event, value, reason) => {
                  switch (reason) {
                    case "selectOption":
                      if (value) {
                        const val = value as Investment;
                        const newPicks = new Map(investmentPicks);
                        newPicks.set(val.id, val);
                        setInvestmentPicks(newPicks);
                        setInvestmentInput("");
                        setInvestmentOptions([]);
                      }
                      break;
                  }
                }}
                disableClearable
                inputValue={investmentInput}
                getOptionLabel={option => option.name ?? ""}
                data-cy="investment-select"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Stack spacing={2} marginY={2} style={{ alignItems: "start" }}>
        <LoadingButton
          variant="contained"
          color="primary"
          disabled={!asOfDate || !investmentPicks.size}
          onClick={async () => {
            setMessage(undefined);
            setWorking(true);
            try {
              if (!performance || !asOfDate) return;
              if (values.size === 0) {
                notify("Cannot submit empty form", { type: "error" });
                return;
              }
              const args: UpdateArgs = {
                id: 0,
                data: {
                  type: isPrivate ? "private" : isQtd ? "qtd" : "mtd",
                  returnType: isGross ? ReturnTypes.Gross : ReturnTypes.Net,
                  data: {
                    type: "manual",
                    data: {
                      asOfDate,
                      newValues: new Map(
                        [...values].map(([key, val]) => [
                          key,
                          parseReturnValue(val),
                        ])
                      ),
                      oldValues: performance,
                    },
                  },
                },
              };
              await dataProvider.update("performanceMultiInvestment", {
                ...args,
                previousData: { id: 0 },
              });
              refetch();
              setValues(new Map());
            } catch (error) {
              captureException(error);
              setMessage({
                error:
                  typeof error === "string"
                    ? error
                    : error instanceof Error
                    ? error.message
                    : JSON.stringify(error, null, 2),
              });
            } finally {
              setWorking(false);
            }
          }}
          loading={working}
          data-cy="submit"
        >
          Save
        </LoadingButton>
        {working ? (
          <div>Working...</div>
        ) : message?.value ? (
          <div data-cy="message-value">{message.value}</div>
        ) : message?.error ? (
          <Accordion>
            <AccordionSummary
              data-cy="message-error"
              expandIcon={<ExpandMoreIcon />}
              style={{ flexDirection: "row-reverse" }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <WarningOutlined
                  style={{
                    fontSize: "24pt",
                    color: "red",
                    margin: "0 10px",
                  }}
                />
                <div>An error occurred.</div>
              </div>
            </AccordionSummary>
            <AccordionDetails>{message.error}</AccordionDetails>
          </Accordion>
        ) : null}
      </Stack>
    </LocalizationProvider>
  );
}
