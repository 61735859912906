import blue from "@mui/material/colors/blue";
import {
  Datagrid,
  EditButton,
  EmailField,
  List,
  TextField,
  useResourceDefinition,
} from "react-admin";
import { PersonFilter } from "./Filter";
import { ListActions } from "./ListActions";

export const PersonList = () => {
  const resource = useResourceDefinition();
  return (
    <List
      sx={{
        ".RaFilter-form": {
          flex: "0 1 50%",
        },
      }}
      filters={<PersonFilter />}
      sort={{ field: "nameSearchRank", order: "ASC" }}
      actions={<ListActions />}
      {...resource}
    >
      <Datagrid rowClick="edit" bulkActionButtons={false}>
        <TextField label="ID" source="id" />
        <TextField label="Last Name" source="lastName" />
        <TextField label="First Name" source="firstName" />
        <TextField label="Middle Name" source="middleName" />
        <TextField
          label="Current Firm"
          source="firmEmployment[0].firm.name"
          sortable={false}
        />
        <EmailField
          label="Email"
          source="email"
          sx={{ "& a": { color: blue[500] } }}
        />
        <TextField label="Phone" source="phone" />
        <EditButton />
      </Datagrid>
    </List>
  );
};
