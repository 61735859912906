import { useCurrentUser } from "frontend/src/utils/hooks";
import { Filter, TextInput, BooleanInput } from "react-admin";

export const CompanyFilter = (props: any) => {
  const {
    flags: { RECFReviewer },
  } = useCurrentUser();
  console.log(props);

  return (
    <Filter {...props}>
      <TextInput
        label="Name Search"
        source="q"
        alwaysOn
        style={{ width: 500 }}
        resettable
      />
      {RECFReviewer && (
        <BooleanInput
          label="RECF App Completed"
          source="applicationCompleted"
          alwaysOn
        />
      )}
    </Filter>
  );
};
