import CloseIcon from "@mui/icons-material/Close";
import LoadingButton from "@mui/lab/LoadingButton";
import { Stack } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { DesktopDatePicker as DatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useState } from "react";
import { useDataProvider } from "react-admin";
import { useDebouncedCallback } from "use-debounce";
import {
  AsyncTaskStatus,
  GetPerformanceBackfillInvestmentListQuery,
} from "_graphql-types";

type Investment =
  GetPerformanceBackfillInvestmentListQuery["investmentList"]["items"][number];

export function PerformanceBackfill() {
  const dataProvider = useDataProvider();
  const [startDate, setStartDate] = useState<Date | null>(new Date(1970, 0));
  const [endDate, setEndDate] = useState<Date | null>(new Date());
  const [investmentInput, setInvestmentInput] = useState("");
  const [investmentOptions, setInvestmentOptions] = useState<Investment[]>([]);
  const setInvestmentOptionsDebounced = useDebouncedCallback(
    async (q: string) => {
      const { data } = await dataProvider.getList(
        "performanceBackfillInvestment",
        {
          filter: { q },
          sort: {
            field: "nameSearchRank",
            order: "ASC",
          },
          pagination: {
            page: 1,
            perPage: 20,
          },
        }
      );
      setInvestmentOptions(data as Investment[]);
    },
    300
  );
  const [investmentPicks, setInvestmentPicks] = useState(
    new Map<number, Investment>()
  );
  const [working, setWorking] = useState(false);
  const [message, setMessage] = useState("");

  return (
    <>
      <h3>Performance Backfill</h3>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Stack direction="row" spacing={2} alignItems="center">
              <DatePicker
                label="Start Date *"
                minDate={new Date("1850-01-01")}
                maxDate={endDate ?? new Date()}
                format="MM/dd/yyyy"
                value={startDate}
                onChange={setStartDate}
                slotProps={{
                  textField: { variant: "standard" },
                }}
                data-cy="performance-backfill-start-date"
              />
              <DatePicker
                label="End Date *"
                minDate={startDate ?? new Date("1850-01-01")}
                maxDate={new Date()}
                format="MM/dd/yyyy"
                value={endDate}
                onChange={setEndDate}
                slotProps={{
                  textField: { variant: "standard" },
                }}
                data-cy="performance-backfill-start-date"
              />
            </Stack>
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12}>
          <Autocomplete
            filterOptions={options => options}
            options={investmentOptions}
            renderInput={params => (
              <TextField
                variant="standard"
                {...params}
                label={
                  <span>
                    Select investments &mdash;{" "}
                    <i>
                      disabled options do not have Bloomberg set as the
                      performance provider
                    </i>
                  </span>
                }
              />
            )}
            onInputChange={(event, value, reason) => {
              switch (reason) {
                case "input":
                  setInvestmentOptionsDebounced(value);
                  setInvestmentInput(value);
                  break;
                case "clear":
                  setInvestmentOptions([]);
                  setInvestmentInput("");
                  break;
              }
            }}
            onChange={(event, value, reason) => {
              switch (reason) {
                case "selectOption":
                  if (value) {
                    const val = value as Investment;
                    const newPicks = new Map(investmentPicks);
                    newPicks.set(val.id, val);
                    setInvestmentPicks(newPicks);
                    setInvestmentInput("");
                    setInvestmentOptions([]);
                  }
                  break;
              }
            }}
            disableClearable
            inputValue={investmentInput}
            getOptionLabel={option =>
              `${option.name} - ${option.id} - ${option.bloombergCode ?? ""}`
            }
            getOptionDisabled={option => option.performanceSourceEnumId !== 1}
            data-cy="performance-backfill-select"
          />
        </Grid>
        {!!investmentPicks.size && (
          <>
            <Grid item xs={10}>
              <List dense data-cy="performance-packfill-picks">
                {[...investmentPicks.values()].map((pick, i) => (
                  <ListItem
                    key={pick.id}
                    style={{ backgroundColor: i % 2 ? "#eee" : "initial" }}
                    secondaryAction={
                      <IconButton
                        edge="end"
                        onClick={() => {
                          const newPicks = new Map(investmentPicks);
                          newPicks.delete(pick.id);
                          setInvestmentPicks(newPicks);
                        }}
                        size="large"
                      >
                        <CloseIcon />
                      </IconButton>
                    }
                    disablePadding
                    data-cy="performance-backfill-pick"
                  >
                    <ListItemText
                      primary={pick.name}
                      secondary={`${pick.id} - ${pick.bloombergCode ?? ""}`}
                    />
                  </ListItem>
                ))}
              </List>
            </Grid>
            <Grid item xs={2}>
              <Button
                onClick={() => setInvestmentPicks(new Map())}
                data-cy="backfill-remove-all"
              >
                Remove All
              </Button>
            </Grid>
          </>
        )}
      </Grid>
      <br />
      <LoadingButton
        variant="contained"
        color="primary"
        disabled={!startDate || !endDate || !investmentPicks.size}
        onClick={async () => {
          setMessage("");
          setWorking(true);
          try {
            const { id } = (
              await dataProvider.update("performanceBackfill", {
                id: "performanceBackfill",
                data: {
                  startDate,
                  endDate,
                  investmentIds: [...investmentPicks.values()].map(
                    ({ id }) => id
                  ),
                },
                previousData: { id: "" },
              })
            ).data as AsyncTaskStatus;
            console.log("ID ->", id);
            if (!id) throw Error("No ID");
            let timeout = 0;
            let interval = 0;
            interval = window.setInterval(async () => {
              const { endDate, error } = (
                await dataProvider.getOne("asyncTaskStatus", {
                  id,
                })
              ).data as AsyncTaskStatus;
              if (error) console.log(error);
              if (endDate) {
                clearInterval(interval);
                clearTimeout(timeout);
                if (error) setMessage("An error occurred");
                else setMessage("Backfill completed successfully");
                setWorking(false);
              }
            }, 1000 * 5);
            timeout = window.setTimeout(async () => {
              clearInterval(interval);
              setMessage("An error occurred (timeout)");
              setWorking(false);
            }, 1000 * 60 * 30);
          } catch (error) {
            setMessage("An error occurred");
            setWorking(false);
            throw error;
          }
        }}
        loading={working}
        data-cy="performance-backfill-submit"
      >
        Backfill
      </LoadingButton>
      {working ? <p>Working...</p> : message ? <p>{message}</p> : null}
    </>
  );
}
