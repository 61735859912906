import Grid from "@mui/material/Grid";
import * as client from "_graphql-types";
import {
  ArrayInput,
  AutocompleteInput,
  Button,
  Link,
  ReferenceInput,
  SimpleFormIterator,
  TextInput,
  required,
  useRecordContext,
} from "react-admin";
import CustomBreadCrumb from "../CustomBreadCumb";
import { CustomEdit } from "../CustomEdit";
import { EntityInput } from "../UI/EntityInput";

type Record = NonNullable<client.GetOneFamilyQuery["family"]>;

const validateRequired = [required()];

const ViewFirmButton = (props: any) => {
  const {
    record,
  }: {
    record: Record;
  } = props;
  return !record?.firm?.id ? null : (
    <Button
      component={Link}
      to={{
        pathname: `/firm/${record.firm.id}`,
      }}
      label="Go to Firm"
    />
  );
};

const FamilyInvestmentsInput = () => {
  const record = useRecordContext<Record>();
  if (!record) return;

  return (
    <ArrayInput label="" source="investmentsFamily" data-cy="investmentsFamily">
      <SimpleFormIterator fullWidth>
        <ReferenceInput
          reference="investment"
          source={"investmentId"}
          sort={{ field: "name", order: "ASC" }}
          filter={{ firmId: record.firm.id }}
        >
          <AutocompleteInput fullWidth optionText="name" label="Investment" />
        </ReferenceInput>
      </SimpleFormIterator>
    </ArrayInput>
  );
};

export const FamilyEdit = () => {
  return (
    <CustomEdit
      actions={
        <CustomBreadCrumb<Record>
          name="performancePrivate"
          items={[
            {
              path: "firm",
              getId: record => record?.firm?.id,
              getName: record => record?.firm?.name || "Firm",
            },
            {
              path: "investment",
              getId: record => record?.id,
              getName: record => record?.name || "Investment",
            },
          ]}
        />
      }
    >
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <ViewFirmButton />
        </Grid>
        <Grid item xs={6}></Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextInput
            label="Family Name"
            source="name"
            validate={validateRequired}
          />
        </Grid>
        <Grid item xs={6}>
          <Grid item xs={6}>
            <EntityInput<NonNullable<Record>>
              resource="firm"
              recordKey="firm"
              label="Firm"
              isRequired={true}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <hr />
          <h3>Family Investments</h3>
        </Grid>
        <Grid item xs={12}>
          <FamilyInvestmentsInput />
        </Grid>
      </Grid>
    </CustomEdit>
  );
};
