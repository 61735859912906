import * as client from "_graphql-types";

import React from "react";
import { PersonSortEnum, SortInput } from "_graphql-types";
import { EntityInput } from "../UI/EntityInput";

type Record = client.GetOnePersonQuery["person"];

export const PersonInput = ({ isRequired }: { isRequired?: boolean }) => {
  return (
    <EntityInput<NonNullable<Record>>
      label="Person"
      recordKey="person"
      resource="person"
      sourceKey="person.id"
      getChoice={person => {
        return {
          id: person.id,
          name: [
            person.lastName,
            ", ",
            person.firstName,
            " ",
            person.middleName,
            " - ",
            person.email,
          ].join(""),
        };
      }}
      isRequired={isRequired}
      sort={{ field: PersonSortEnum.NameSearchRank, order: SortInput.Asc }}
      allowChange
    />
  );
};

//comment
