import * as client from "_graphql-types";
import { filterSortPage } from "../DataProvider";
import * as types from "./types";

type Sdk = ReturnType<typeof client.getSdk>;

/*********************************************************************/
// Data Provider Functions
/*********************************************************************/

/*********************************************************************/
async function getList(
  sdk: Sdk,
  params: types.GetListParams<client.InvestmentRiskCategorySortEnum>
) {
  const { pagination, sort, filter } = params;

  const {
    investmentRiskCategoryList: { items: data, total },
  } = await sdk.getInvestmentRiskCategoryList(
    filterSortPage({
      filter,
      sort,
      pagination,
    })
  );

  return {
    data,
    total,
  };
}

/*********************************************************************/
export const investmentRiskCategory = types.dataProvider({
  getList,
});
