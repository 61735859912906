import { Filter, NumberInput, TextInput } from "react-admin";

export const CustomIndexInvestmentFilter = (props: any) => {
  return (
    <Filter {...props}>
      <NumberInput label="Output Investment ID" source="id" alwaysOn />
      <TextInput
        label="Output Investment Name"
        source="name"
        alwaysOn
        style={{ width: 500 }}
        resettable
      />
    </Filter>
  );
};
