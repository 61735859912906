import { ReactNode, useEffect } from "react";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
  Routes,
  useLocation,
} from "react-router-dom";
import AuthProvider from "./components/Auth/AuthProvider.component";
import Login, { LoginContextEnum } from "./components/Auth/Login";
import LoginCallback from "./components/Auth/OktaLoginCallback";
import SecureRoute from "./components/Auth/SecureRoute.component";
import { Home } from "./components/Home";

const SecureApp = () => {
  const location = useLocation();

  useEffect(() => {
    const timer1 = setTimeout(() => {
      // make the page scroll to the top after navigating
      window.scrollTo(0, 0);
    }, 0);
    const timer2 = setTimeout(() => {
      // a little trick to set the page title that shows on browser tab
      // also shows up nicely in browser history
      document.title = `RockCreek Data Collection - ${
        document.getElementById("react-admin-title")?.innerText ?? ""
      }`;

      const cleanup = () => {
        clearTimeout(timer2);
        clearTimeout(timer1);
      };
      return cleanup;
    }, 1000);
  }, [location.pathname]);

  return (
    <AuthProvider>
      <Routes>
        <Route path="/login/okta/callback" element={<LoginCallback />} />
        <Route
          path="/login/businessApplication"
          element={<Login loginContext={LoginContextEnum.businessApplicant} />}
        />
        <Route
          path="/login/external"
          element={<Login loginContext={LoginContextEnum.externalManager} />}
        />
        <Route
          path="/login/internal"
          element={<Login loginContext={LoginContextEnum.rcManager} />}
        />
        <Route
          path="/login"
          element={<Login loginContext={LoginContextEnum.main} />}
        />
        <Route
          path="*"
          element={
            <SecureRoute>
              <Home />
            </SecureRoute>
          }
        />
      </Routes>
    </AuthProvider>
  );
};

const routes = createRoutesFromElements(
  <Route path="*" element={<SecureApp />} />
);
const router = createBrowserRouter(routes);

const App = (): ReactNode => {
  return <RouterProvider router={router} />;
};

export default App;
